import * as actions from './annexeAction'
import { HANDLE_QUERY_LISTE_ANNEXES, RESET_QUERY_LISTE_ANNEXES } from './annexeAction'
import { combineReducers } from 'redux'

const annexeInit = {}
export const annexe = (state = annexeInit, action) => {
	switch (action.type) {
		case actions.GET_ANNEXE_SUCCESS:
		case actions.PUT_ANNEXE_SUCCESS:
			return action.annexe
		case actions.GET_ANNEXE_ERROR:
		case actions.CLEAR_ANNEXE:
			return annexeInit
		default:
			return state
	}
}


const initialRecherche = {
	search: ''
}

const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_LISTE_ANNEXES:
			return {
				search: action.value
			}
		case RESET_QUERY_LISTE_ANNEXES:
			return {
				search: ''
			}
		default:
			return state
	}
}

export const annexes = combineReducers({
	annexe,
	recherche
})