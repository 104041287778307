import PropTypes from 'prop-types'
import React from 'react'
import { Input, Popup, Button } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { createParagraphe } from '../service/ligneProgrammeAction'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouveauParagraphePopup = ({
	                                handleSubmit,
	                                open,
	                                reset,
	                                createParagraphe,
	                                snackSuccess,
	                                refreshTable,
	                                closePopupCreation,
	                                idMatiere,
	                                idTopic
                                }) => {

	const submitCreateParagraphe = (values) => (
		createParagraphe(values, idMatiere, idTopic)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopupCreation()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	)

	const closePopup = () => {
		reset()
		closePopupCreation()
	}

	const popupContent = () => (
		<form
			id="ParagrapheForm"
		>
			<Field
				id="code"
				name="code"
				label="Code *"
				component={Input}
			/>
			<Field
				id="libelle"
				name="libelle"
				label="Libellé *"
				component={Input}
			/>
		</form>
	)

	const actions = [
		<Button key="close" color="primary" onClick={() => closePopup()}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateParagraphe)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title='Nouveau paragraphe'
			content={popupContent()}
			onExited={closePopup}
		/>
	)
}

NouveauParagraphePopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	createParagraphe: PropTypes.func,
	idMatiere: PropTypes.string
}

const actions = {
	createParagraphe,
	refreshTable: () => dataTableActions.refresh('listeParagraphes')
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'ParagrapheForm',
		initialValues: {
			libelle: '',
			code: ''
		},
		validate
	}),
	injectSnackActions
)(NouveauParagraphePopup)
