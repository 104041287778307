import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Actions, Button, ResponsiveForm, Select, formatQuery } from '@oceane/ui'
import MenuItem from '@material-ui/core/MenuItem'
import { TYPE_EDITION } from './services/editionValues'
import EditionInscriptionPapier from './components/EditionInscriptionPapier'
import EditionEtatReglement from './components/EditionEtatReglement'
import EditionBordereau from './components/EditionBordereau'
import { download } from 'isotope-client/components/download/IsotopeLink'

const EDITION_FORM_NAME = 'EditionForm'

const validate = (values) => {
	const errors = {}

	switch (values.typeEdition) {
		case TYPE_EDITION.INSCRIPTION:
			if (!values.inscriptionPapier.sessionId) {
				errors.inscriptionPapier = { sessionId: 'Champs obligatoire' }
			}
			break
		case TYPE_EDITION.REGLEMENTS:
			const reglement = values.etatReglement
			errors.etatReglement = {}
			if (!reglement.dateDebut) {
				errors.etatReglement.dateDebut = 'Champs obligatoire'
			}
			if (!reglement.dateFin) {
				errors.etatReglement.dateFin = 'Champs obligatoire'
			}
			if (!reglement.idExamenType) {
				errors.etatReglement.idExamenType = 'Champs obligatoire'
			}

			if (reglement.dateDebut && reglement.dateFin && reglement.dateFin < reglement.dateDebut) {
				errors.etatReglement.dateFin = 'La date de fin de période doit être égale ou postérieure à la date de début'
			}

			// RG : si une exonération est sélectionnée, la sélection d'un motif
			// d'exonération est obligatoire. KO = MSG35
			if (reglement.typeReglement === '3' && !reglement.idMotifExoneration) {
				errors.etatReglement.idMotifExoneration = 'La sélection d\'un motif d\'exonération est obligatoire'
			}

			if (reglement.typeReglement === '2' && !reglement.idCompteClientDCS) {
				errors.etatReglement.idCompteClientDCS = 'La sélection d\'un compte client est obligatoire'
			}

			break
		default:
			break
	}
	return errors
}

const EditionPage = ({ typeEdition, handleSubmit }) => {
	const submitEdition = (values) => {
		const root = '/files/editionCandidat'
		// Edition bordereau géré directement dans la dataTable
		switch (typeEdition) {
			case TYPE_EDITION.INSCRIPTION:
				return download(`${root}/inscriptionsPapier?${formatQuery(values.inscriptionPapier)}`)
			case TYPE_EDITION.REGLEMENTS:
				return download(`${root}/etatReglements?${formatQuery(values.etatReglement)}`)
			case TYPE_EDITION.RECAPITULATIF:
				return download(`${root}/recapExamensCoordonnateur`)
			default:
				return
		}
	}

	const renderFilter = () => {
		switch (typeEdition) {
			case TYPE_EDITION.INSCRIPTION:
				return <EditionInscriptionPapier />
			case TYPE_EDITION.REGLEMENTS:
				return <EditionEtatReglement />
			case TYPE_EDITION.BORDEREAU:
				return <EditionBordereau />
			default:
				return <React.Fragment />
		}
	}

	return (<ResponsiveForm>
		<Field
			id="typeEdition"
			name="typeEdition"
			label="Type d'édition"
			component={Select}
		>
			{Object.values(TYPE_EDITION).map(edition =>
				<MenuItem key={edition} value={edition}>
					{edition}
				</MenuItem>
			)}
		</Field>
		{renderFilter()}
		{typeEdition !== TYPE_EDITION.BORDEREAU && <Actions>
			<Button
				color="primary"
				variant="contained"
				disabled={!typeEdition}
				onClick={handleSubmit(submitEdition)}
			>
				Afficher
			</Button>
		</Actions>}
	</ResponsiveForm>)
}

const mapStateToProps = state => ({ typeEdition: formValueSelector(EDITION_FORM_NAME)(state, 'typeEdition') })

EditionPage.propTypes = {
	typeEdition: PropTypes.string
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: EDITION_FORM_NAME,
		validate,
		enableReinitialize: true,
		initialValues: {
			typeEdition: TYPE_EDITION.INSCRIPTION,
			inscriptionPapier: {
				sessionId: '',
				tri: '1'
			},
			etatReglement: {
				dateDebut: '',
				dateFin: '',
				idExamenType: '',
				typeReglement: '1',
				idMotifExoneration: '',
				idCompteClientDCS: ''
			}
		}
	})
)(EditionPage)
