import {
	DETAIL_MATIERE_SUCCESS,
	DETAIL_TOPIC_SUCCESS,
	DETAIL_PARAGRAPHE_SUCCESS,
	DETAIL_SOUS_PARAGRAPHE_SUCCESS,
	DETAIL_LIGNE_SUCCESS
} from './ligneProgrammeAction'
import { combineReducers } from 'redux'

const detailMatiere = (state= {}, action) => {
	switch (action.type) {
		case DETAIL_MATIERE_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const detailTopic = (state= {}, action) => {
	switch (action.type) {
		case DETAIL_TOPIC_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const detailParagraphe = (state= {}, action) => {
	switch (action.type) {
		case DETAIL_PARAGRAPHE_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const detailSousParagraphe = (state= {}, action) => {
	switch (action.type) {
		case DETAIL_SOUS_PARAGRAPHE_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const detailLigne = (state= {}, action) => {
	switch (action.type) {
		case DETAIL_LIGNE_SUCCESS:
			return action.payload
		default:
			return state
	}
}

export const ligneProgramme = combineReducers({
	detailMatiere,
	detailTopic,
	detailParagraphe,
	detailSousParagraphe,
	detailLigne
})
