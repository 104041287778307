import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { FilAriane, getStyles, StaticDataTable } from 'isotope-client'
import { Button, EmptyResult, Popup, StyledTypography } from '@oceane/ui'
import { BNQ_ID_ORIGINE_QUESTION } from '../../../../config/enums'
import { FormattedMessage } from 'react-intl'
import SearchIcon from '@material-ui/icons/Search'
import * as selectors from '../services/banqueQuestionsSelectors'
import MuiToolbar from '@material-ui/core/Toolbar'
import { getStatistiquesAffectation } from '../services/banqueQuestionsActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import moment from 'moment'
import * as shapes from '../../../../config/shapes'


const styles = () => getStyles({
	ariane: {
		backgroundColor: 'white',
		padding: '0 46px'
	},
	tableContainer: {
		padding: '0 46px'
	},
	table: {
		width: '100%'
	},
	popupContent: {
		padding: 0
	}
})

const StatistiquesExamenPopup = ({ question, affectation, clearAffectation, getStatistiquesAffectation, snackError, classes }) => {

	const [open, setOpen] = React.useState(false)
	const [statistiques, setStatistiques] = React.useState([])

	React.useEffect(() => {
		if (affectation !== null) {
			getStatistiquesAffectation(question.idOceane, affectation.idExamenType)
				.then(result => {
					setStatistiques(result)
					setOpen(true)
				})
				.catch(() => {
					snackError()
					clearAffectation()
				})
		} else {
			setStatistiques([])
		}
	}, [affectation])

	return (
		<Popup
			open={open}
			onExited={clearAffectation}
			contentStyleName={classes.popupContent}
			title="Statistiques par examen"
			actions={[
				<Button
					key="fermer"
					variant="contained"
					onClick={() => setOpen(false)}
				>
					Fermer
				</Button>
			]}
			content={
				<>
					<MuiToolbar className={classes.ariane}>
						<FilAriane
							separator={() => '>'}
							entries={[
								{
									render: () => <>
										<StyledTypography>Question : </StyledTypography>
										<StyledTypography>{question.idOceane}</StyledTypography>
										{question.origine === BNQ_ID_ORIGINE_QUESTION.EUROPEENNE &&
										<StyledTypography> / {question.numeroEuropeen}</StyledTypography>}
										<StyledTypography primary><FormattedMessage
											id={`enums.bnqIdEtatQuestion.${question.etat}`} /></StyledTypography>
										{question.aexpertiser && <SearchIcon />}
									</>
								}, {
									render: () => <>
										<StyledTypography bold>Examen : {affectation.libelleExamen}</StyledTypography>
									</>
								}
							]}
						/>
					</MuiToolbar>
					<div className={classes.tableContainer}>
						<StaticDataTable
							data={statistiques}
							tableProps={{
								className: classes.table
							}}
							noResultFragment={<EmptyResult />}
							headers={[
								{
									key: 'dateExploitationVersionBanque',
									name: 'Version banque',
									sortable: true,
									type: 'date',
									render: row => moment(row.dateExploitationVersionBanque).format('DD/MM/YYYY'),
								}, {
									key: 'occurenceVersionBanque',
									name: 'Occurence',
									sortable: true
								}, {
									key: 'pourcentageReponseJuste',
									name: 'RJ%',
									sortable: true,
									render: value => value.pourcentageReponseJuste.toFixed(1)
								}, {
									key: 'pourcentageReponseFausse1',
									name: 'F1%',
									sortable: true,
									render: value => value.pourcentageReponseFausse1.toFixed(1)

								}, {
									key: 'pourcentageReponseFausse2',
									name: 'F2%',
									sortable: true,
									render: value => value.pourcentageReponseFausse2.toFixed(1)

								}, {
									key: 'pourcentageReponseFausse3',
									name: 'F3%',
									sortable: true,
									render: value => value.pourcentageReponseFausse3.toFixed(1)
								}
							]}
							noPagination
						/>
					</div>
				</>
			}
		/>
	)
}

StatistiquesExamenPopup.propTypes = {
	question: PropTypes.object,
	affectation: shapes.questionAffectationShape,
	clearAffectation: PropTypes.func,
	getStatistiquesAffectation: PropTypes.func,
	snackError: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	question: selectors.getDonneesGenerales(state)
})

const actions = {
	getStatistiquesAffectation
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	injectSnackActions
)(StatistiquesExamenPopup)
