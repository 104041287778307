export default class DateUtils {
	static parseDate(dateString) {
		const dateSplit = typeof dateString === 'string' ? dateString.split(/\//) : []
		const [dayPart, monthPart, yearPart] = dateSplit
		const day = +dayPart
		const month = +monthPart - 1
		const year = +yearPart
		const date = new Date(year, month, day)
		if (isNaN(date.getTime())) {
			return date
		}
		if (date.getMonth() !== month || date.getDate() !== day) {
			return new Date(NaN)
		}
		return date
	}

	static isValidTime(value) {
		return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value)
	}

}