import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { dataTableActions, injectToolbarData, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ModificationSousParagraphePopup from './ModificationSousParagraphePopup'
import NouveauSousParagraphePopup from './NouveauSousParagraphePopup'
import { getFilArianeSousParagraphe } from '../service/filArianeProfil'
import { injectDetailMatiereWithIdMatiere } from '../service/injectDetailMatiereWithIdMatiere'
import { injectDetailParagrapheWithIdParagraphe } from '../service/injectDetailParagrapheWithIdParagraphe'
import { injectDetailTopicWithIdTopic } from '../service/injectDetailTopicWithIdTopic'
import { getDetailsSousParagraphe, suppressionSousParagraphe } from '../service/ligneProgrammeAction'
import SupprimerSousParagraphePopup from './SupprimerSousParagraphePopup'

const ListeSousParagraphePage = ({ classes, refreshTable, match, suppressionSousParagraphe, goToLignePage, getDetailsSousParagraphe }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [openModifier, setOpenModifier] = React.useState(false)
	const [openSupprimer, setOpenSupprimer] = React.useState(false)
	const [idSousParagraphe, setIdSousParagraphe] = React.useState(null)
	const idMatiere = match.params.idMatiere
	const idTopic = match.params.idTopic
	const idParagraphe = match.params.idParagraphe

	const clickModifier = (id) => {
		getDetailsSousParagraphe(idMatiere, idTopic, idParagraphe, id)
			.then(() => setOpenModifier(true))
	}

	const clickSupprimer = (id) => {
		setIdSousParagraphe(id)
		setOpenSupprimer(true)
	}

	const deleteAction = () => {
		return suppressionSousParagraphe(idMatiere, idTopic, idParagraphe, idSousParagraphe)
			.then(() => {
				refreshTable()
				setOpenSupprimer(false)
			})
	}

	return (
		<React.Fragment>
			<SpringDataTable
				apiUrl={`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes`}
				headers={[
					{
						key: 'code',
						name: 'Code',
						sortable: true
					},
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: true
					}
				]}
				nom="listeSousParagraphes"
				noResultFragment={<EmptyResult />}
				makeActions={row => [
					{ label: 'Modifier', action: () => clickModifier(row.id) },
					{
						label: 'Gérer les lignes',
						action: () => goToLignePage(idMatiere, idTopic, idParagraphe, row.id)
					},
					{ label: 'Supprimer', action: () => clickSupprimer(row.id) }
				]}
				showActions
			/>
			<FloatingButton onClick={() => setOpenCreation(true)} />
			<NouveauSousParagraphePopup
				idMatiere={idMatiere}
				idTopic={idTopic}
				idParagraphe={idParagraphe}
				closePopupCreation={() => setOpenCreation(false)}
				open={openCreation}
			/>
			<ModificationSousParagraphePopup
				idMatiere={idMatiere}
				idTopic={idTopic}
				idParagraphe={idParagraphe}
				closePopupModification={() => setOpenModifier(false)}
				open={openModifier}
			/>
			<SupprimerSousParagraphePopup
				open={openSupprimer}
				closePopupSuppression={() => setOpenSupprimer(false)}
				onSubmit={deleteAction}
			/>
		</React.Fragment>
	)
}

const actions = {
	suppressionSousParagraphe,
	refreshTable: () => dataTableActions.refresh('listeSousParagraphes'),
	getDetailsSousParagraphe,
	goToLignePage: (idMatiere, idTopic, idParagraphe, idSousParagraphe) => dispatch => dispatch(push(`/ligne-programme/${idMatiere}/liste-topics/${idTopic}/liste-paragraphes/${idParagraphe}/liste-sous-paragraphes/${idSousParagraphe}/liste-ligne`))
}

ListeSousParagraphePage.propTypes = {
	refreshTable: PropTypes.func,
	detailMatiere: PropTypes.object,
	detailsTopic: PropTypes.object,
	detailsParagraphe: PropTypes.object,
	suppressionSousParagraphe: PropTypes.func,
	getDetailsSousParagraphe: PropTypes.func
}

export default compose(
	injectDetailMatiereWithIdMatiere,
	injectDetailTopicWithIdTopic,
	injectDetailParagrapheWithIdParagraphe,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
	),
	connect(null, actions),
	injectSession,
	injectToolbarData(({ detailsMatiere, detailsTopic, detailsParagraphe }) => ({
		...getFilArianeSousParagraphe(
			detailsMatiere,
			detailsTopic,
			detailsParagraphe.code,
			true
		),
		title: 'Liste des sous paragraphes'
	}))
)(ListeSousParagraphePage)
