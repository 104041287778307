import { Button, Input, Popup, injectFormSnackbar } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { modificationParagraphe } from '../service/ligneProgrammeAction'
import * as selectors from '../service/ligneProgrammeSelectors'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const ModificationParagraphePopup = ({ modificationParagraphe, refreshTable, closePopupModification, idMatiere, idTopic, reset, handleSubmit, open }) => {

	const submitModificationParagraphe = (values) => {
		return modificationParagraphe(values, idMatiere, idTopic)
			.then(() => {
				refreshTable()
				closePopupModification()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const closePopup = () => {
		reset()
		closePopupModification()
	}

	const popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="modificationParagrapheForm"
				>
					<Field
						id="code"
						name="code"
						label="Code *"
						component={Input}
					/>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={Input}
					/>
				</form>
			</React.Fragment>
		)
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitModificationParagraphe)}
		>
			Enregistrer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Modification du paragraphe"
			content={popupContent()}
			onExited={closePopup}
		/>
	)
}

ModificationParagraphePopup.propTypes = {
	open: PropTypes.bool,
	closePopupModification: PropTypes.func,
	modificationParagraphe: PropTypes.func,
	idMatiere: PropTypes.string
}

const mapStateToProps = state => ({
	initialValues: selectors.getDetailParagraphe(state)
})

const actions = {
	modificationParagraphe,
	refreshTable: () => dataTableActions.refresh('listeParagraphes')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modificationParagrapheForm',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar()
)(ModificationParagraphePopup)
