import { Input, Popup, Button } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { createLigne } from '../service/ligneProgrammeAction'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouvelleLignePopup = ({
								handleSubmit,
								open,
								reset,
								createLigne,
								snackSuccess,
								refreshTable,
								closePopupCreation,
								idMatiere,
								idTopic,
								idParagraphe,
								idSousParagraphe
							}) => {

	const submitCreateLigne = (values) => {
		return createLigne(values, idMatiere, idTopic, idParagraphe, idSousParagraphe)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopupCreation()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const closePopup = () => {
		reset()
		closePopupCreation()
	}

	const popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="LigneForm"
				>
					<Field
						id="code"
						name="code"
						label="Code *"
						component={Input}
					/>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={Input}
					/>
				</form>
			</React.Fragment>
		)
	}

	const actions = [
		<Button key="close" color="primary" onClick={() => closePopup()}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateLigne)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouvelle ligne"
			content={popupContent()}
			onExited={closePopup}
		/>
	)
}

NouvelleLignePopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	createLigne: PropTypes.func
}

const actions = {
	createLigne,
	refreshTable: () => dataTableActions.refresh('listeLignes')
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'LigneForm',
		initialValues: {
			libelle: '',
			code: ''
		},
		validate
	}),
	injectSnackActions
)(NouvelleLignePopup)
