import React from 'react'
import PropTypes from 'prop-types'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStyles, injectToolbarData, getToolbarData, setToolbarData, resetToolbarData } from 'isotope-client'
import { checkAuthorities, injectSession, PROFIL_UTILISATEUR, StyledTypography, Tabs } from '@oceane/ui'
import { cleanSessionPapier } from '../services/sessionsPapierActions'
import * as selectors from '../services/sessionsPapierSelectors'
import { injectDonneesGenerales } from '../services/sessionsPapierInjectors'
import { FormattedMessage } from 'react-intl'
import OngletGeneral from './components/OngletGeneral'
import OngletEditionSujets from './components/OngletEditionSujets'
import OngletEpreuves from './components/OngletEpreuves'
import OngletEditionResultats from './components/OngletEditionResultats'
import OngletEditionCandidats from './components/OngletEditionCandidats'
import OngletPVSalle from './components/OngletPVSalle'
import OngletSurveillance from './components/OngletSurveillance'
import OngletAffectationsSession from './components/OngletAffectationsSession'
import { replace } from 'connected-react-router'

const styles = theme => getStyles({
	tab: {
		[theme.breakpoints.up('xs')]: {
			minWidth: 932 / 7
		}
	},
	tabRespLog: {
		[theme.breakpoints.up('xs')]: {
			minWidth: 932 / 8
		}
	}
})

const filAriane = (sessionPapier) => (sessionPapier && {
	title: 'Détail session',
	subheader: {
		separator: () => '|',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography bold>Session : {sessionPapier.libelleSession}</StyledTypography>
				{sessionPapier.etat && <StyledTypography primary bold><FormattedMessage
					id={`references.sessions.etatPapier.${sessionPapier.etat}`} /></StyledTypography>}
			</>
		}]
	}
})

const TABS = {
	'general': 0,
	'epreuves': 1,
	'affectations': 2,
	'candidats': 3,
	'surveillance': 4,
	'pv-salle': 5,
	'resultat': 6
}

const TABS_RESPLOG = {
	'general': 0,
	'epreuves': 1,
	'affectations': 2,
	'candidats': 3,
	'sujets': 4,
	'surveillance': 5,
	'pv-salle': 6,
	'resultat': 7
}

const SessionPapierPage = ({ switchTab, tab, tablist, sessionPapier, toolbarData, changeToolbarData, cleanToolbarData, cleanSessionPapier, classes, profil }) => {

	React.useEffect(() => {
		changeToolbarData(toolbarData.id, filAriane(sessionPapier))
		return () => {
			cleanToolbarData(toolbarData.id)
		}
	}, [sessionPapier.etat])

	const handleChangeTab = (selectedTab) => {
		const tabString = Object.keys(tablist).find(tab => tablist[tab] === selectedTab) || ''
		switchTab(sessionPapier.id, tabString)
	}

	React.useEffect(() => cleanSessionPapier, [])

	const tabs_resplog = [
		<Tab key="general" label="General" className={classes.tabRespLog} />,
		<Tab key="epreuves" label="Epreuves" className={classes.tabRespLog} />,
		<Tab key="affectations" label="Affectations" className={classes.tabRespLog} />,
		<Tab key="candidats" label="Candidats" className={classes.tabRespLog} />,
		<Tab key="sujets" label="Sujets" className={classes.tabRespLog} />,
		<Tab key="surveillance" label="Surveillance" className={classes.tabRespLog} />,
		<Tab key="pv" label="PV de salles" className={classes.tabRespLog} />,
		<Tab key="resultats" label="Résultats" className={classes.tabRespLog} />
	]

	const tabViews_respLog = [
		<OngletGeneral />,
		<OngletEpreuves />,
		<OngletAffectationsSession />,
		<OngletEditionCandidats />,
		<OngletEditionSujets />,
		<OngletSurveillance />,
		<OngletPVSalle />,
		<OngletEditionResultats />
	]

	const tabs = [
		<Tab key="general" label="General" className={classes.tab} />,
		<Tab key="epreuves" label="Epreuves" className={classes.tab} />,
		<Tab key="affectations" label="Affectations" className={classes.tab} />,
		<Tab key="candidats" label="Candidats" className={classes.tab} />,
		<Tab key="surveillance" label="Surveillance" className={classes.tab} />,
		<Tab key="pv" label="PV de salles" className={classes.tab} />,
		<Tab key="resultats" label="Résultats" className={classes.tab} />
	]

	const tabViews = [
		<OngletGeneral />,
		<OngletEpreuves />,
		<OngletAffectationsSession />,
		<OngletEditionCandidats />,
		<OngletSurveillance />,
		<OngletPVSalle />,
		<OngletEditionResultats />
	]

	return (
		<Tabs
			initialTab={tab}
			tabs={profil === PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE.id ? tabs_resplog : tabs}
			tabViews={profil === PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE.id ? tabViews_respLog : tabViews}
			onChangeTab={handleChangeTab}
		/>
	)
}

const mapStateToProps = (state, ownProps) => {
	const tablist = ownProps.profil === PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE.id ? TABS_RESPLOG : TABS
	return ({
		tab: tablist[ownProps.match.params.tab] || 0,
		tablist,
		sessionPapier: selectors.getDonneesGenerales(state),
		toolbarData: getToolbarData(state)
	})
}

const actions = {
	cleanSessionPapier,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	switchTab: (idSession, tab) => dispatch => dispatch(replace(`/sessions-papier/${idSession}/${tab}`))
}

SessionPapierPage.propTypes = {
	classes: PropTypes.object,
	sessionPapier: PropTypes.object,
	cleanSessionPapier: PropTypes.func,
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	cleanToolbarData: PropTypes.func
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	withStyles(styles),
	injectDonneesGenerales,
	injectSession,
	connect(mapStateToProps, actions),
	injectToolbarData(({ sessionPapier }) => filAriane(sessionPapier))
)(SessionPapierPage)
