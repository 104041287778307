import { getExamenCandidatById } from '@oceane/ui'
import * as api from './formationEquivalencesApi'

export const GET_EPREUVES_EXAMEN_CANDIDAT_REQUEST = 'GET_EPREUVES_EXAMEN_CANDIDAT_REQUEST'
export const GET_EPREUVES_EXAMEN_CANDIDAT_SUCCESS = 'GET_EPREUVES_EXAMEN_CANDIDAT_SUCCESS'
export const GET_EPREUVES_EXAMEN_CANDIDAT_ERROR = 'GET_EPREUVES_EXAMEN_CANDIDAT_ERROR'

export const getEpreuvesExamenCandidat = (idCandidat, idExamenType) => dispatch => {
	dispatch({ type: GET_EPREUVES_EXAMEN_CANDIDAT_REQUEST })
	return api.getEpreuvesExamenCandidat(idCandidat, idExamenType)
		.then(listeEpreuves => dispatch({
			type: GET_EPREUVES_EXAMEN_CANDIDAT_SUCCESS,
			listeEpreuves
		}))
		.catch(e => {
			dispatch({
				type: GET_EPREUVES_EXAMEN_CANDIDAT_ERROR,
				e
			})
			throw e
		})
}

export const TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_REQUEST = 'TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_REQUEST'
export const TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_SUCCESS = 'TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_SUCCESS'
export const TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_ERROR = 'TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_ERROR'

export const toggleEquivalence = (idExamenCandidat, idEpreuve, checked, idCandidat) => dispatch => {
	dispatch({ type: TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_REQUEST })
	return api.toggleEquivalenceEpreuve(idExamenCandidat, idEpreuve, checked, idCandidat)
		.then(() => {
			return getExamenCandidatById(idCandidat, idExamenCandidat)
				.then(({ etat }) => dispatch({
						type: TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_SUCCESS,
						nouvelEtatExamen: etat
					})
				)
		})
		.catch(e => {
			dispatch({
				type: TOGGLE_EQUIVALENCE_EPREUVE_EXAMEN_CANDIDAT_ERROR,
				e
			})
			throw e
		})
}

export const REFUSER_EPREUVE_EXAMEN_CANDIDAT_REQUEST = 'REFUSER_EPREUVE_EXAMEN_CANDIDAT_REQUEST'
export const REFUSER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS = 'REFUSER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS'
export const REFUSER_EPREUVE_EXAMEN_CANDIDAT_ERROR = 'REFUSER_EPREUVE_EXAMEN_CANDIDAT_ERROR'

export const refuserEpreuveExamenCandidat = (idExamenCandidat, idEpreuve, idCandidat) => dispatch => {
	dispatch({ type: REFUSER_EPREUVE_EXAMEN_CANDIDAT_REQUEST })
	return api.refuserEpreuveExamenCandidat(idExamenCandidat, idEpreuve, idCandidat)
		.then(epreuves => dispatch({
			type: REFUSER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS,
			epreuves
		}))
		.catch(e => {
			dispatch({
				type: REFUSER_EPREUVE_EXAMEN_CANDIDAT_ERROR,
				e
			})
			throw e
		})
}

export const AUTORISER_EPREUVE_EXAMEN_CANDIDAT_REQUEST = 'AUTORISER_EPREUVE_EXAMEN_CANDIDAT_REQUEST'
export const AUTORISER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS = 'AUTORISER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS'
export const AUTORISER_EPREUVE_EXAMEN_CANDIDAT_ERROR = 'AUTORISER_EPREUVE_EXAMEN_CANDIDAT_ERROR'

export const autoriserEpreuveExamenCandidat = (idExamenCandidat, idEpreuve, idCandidat) => dispatch => {
	dispatch({ type: AUTORISER_EPREUVE_EXAMEN_CANDIDAT_REQUEST })
	return api.autoriserEpreuveExamenCandidat(idExamenCandidat, idEpreuve, idCandidat)
		.then(epreuves => dispatch({
			type: AUTORISER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS,
			epreuves
		}))
		.catch(e => {
			dispatch({
				type: AUTORISER_EPREUVE_EXAMEN_CANDIDAT_ERROR,
				e
			})
			throw e
		})
}

export const GET_ORGANISMES_ACTIVES_REQUEST = 'GET_ORGANISMES_ACTIVES_REQUEST'
export const GET_ORGANISMES_ACTIVES_SUCCESS = 'GET_ORGANISMES_ACTIVES_SUCCESS'
export const GET_ORGANISMES_ACTIVES_ERROR = 'GET_ORGANISMES_ACTIVES_ERROR'

export const getListeDesOrganismesDeFormationActives = () => dispatch => {
	dispatch({ type: GET_ORGANISMES_ACTIVES_REQUEST })
	return api.getListeDesOrganismesDeFormationActives()
		.then(organismes => dispatch({
			type: GET_ORGANISMES_ACTIVES_SUCCESS,
			organismes
		}))
		.catch(e => {
			dispatch({
				type: GET_ORGANISMES_ACTIVES_ERROR,
				e
			})
			throw e
		})
}

export const MODIFIER_EPREUVE_REQUEST = 'MODIFIER_EPREUVE_REQUEST'
export const MODIFIER_EPREUVE_SUCCESS = 'MODIFIER_EPREUVE_SUCCESS'
export const MODIFIER_EPREUVE_ERROR = 'MODIFIER_EPREUVE_ERROR'

export const modifierEpreuvesExamenCandidat = (idExamenCandidat, idCandidat, values) => dispatch => {
	dispatch({ type: MODIFIER_EPREUVE_REQUEST })
	return api.modifierEpreuveExamenCandidat(idExamenCandidat, idCandidat, values)
		.then(listeEpreuves => dispatch({
			type: MODIFIER_EPREUVE_SUCCESS,
			listeEpreuves
		}))
		.catch(e => {
			dispatch({
				type: MODIFIER_EPREUVE_ERROR,
				e
			})
			throw e
		})
}


export const GET_COMPTES_CLIENTS_REQUEST = 'GET_COMPTES_CLIENTS_REQUEST'
export const GET_COMPTES_CLIENTS_SUCCESS = 'GET_COMPTES_CLIENTS_SUCCESS'
export const GET_COMPTES_CLIENTS_ERROR = 'GET_COMPTES_CLIENTS_ERROR'

export const getComptesClients = () => dispatch => {
	dispatch({ type: GET_COMPTES_CLIENTS_REQUEST })
	return api.getComptesClients()
		.then(comptesClients => dispatch({
			type: GET_COMPTES_CLIENTS_SUCCESS,
			comptesClients
		}))
		.catch(e => {
			dispatch({
				type: GET_COMPTES_CLIENTS_ERROR,
				e
			})
			throw e
		})
}


export const MODIFIER_COMPTE_CLIENT_REQUEST = 'MODIFIER_COMPTE_CLIENT_REQUEST'
export const MODIFIER_COMPTE_CLIENT_SUCCESS = 'MODIFIER_COMPTE_CLIENT_SUCCESS'
export const MODIFIER_COMPTE_CLIENT_ERROR = 'MODIFIER_COMPTE_CLIENT_ERROR'

export const modifierCompteClient = (idExamenCandidat, idCandidat, idCompteClient) => dispatch => {
	dispatch({ type: MODIFIER_COMPTE_CLIENT_REQUEST })
	return api.modifierCompteClient(idExamenCandidat, idCandidat, idCompteClient)
		.then(comptesClients => dispatch({
			type: MODIFIER_COMPTE_CLIENT_SUCCESS,
			comptesClients
		}))
		.catch(e => {
			dispatch({
				type: MODIFIER_COMPTE_CLIENT_ERROR,
				e
			})
			throw e
		})
}

export const GET_ORGANISMES_EXAMEN_TYPE_REQUEST = 'GET_ORGANISMES_EXAMEN_TYPE_REQUEST'
export const GET_ORGANISMES_EXAMEN_TYPE_SUCCESS = 'GET_ORGANISMES_EXAMEN_TYPE_SUCCESS'
export const GET_ORGANISMES_EXAMEN_TYPE_ERROR = 'GET_ORGANISMES_EXAMEN_TYPE_ERROR'

export const getOrganismesExamenType = (idExamenType) => dispatch => {
	dispatch({ type: GET_ORGANISMES_EXAMEN_TYPE_REQUEST })
	return api.getOrganismesExamenType(idExamenType)
		.then(organismes => dispatch({
			type: GET_ORGANISMES_EXAMEN_TYPE_SUCCESS,
			organismes
		}))
		.catch(e => {
			dispatch({
				type: GET_ORGANISMES_EXAMEN_TYPE_ERROR,
				e
			})
			throw e
		})
}

export const GET_TYPES_FORMATIONS_REQUEST = 'GET_TYPES_FORMATIONS_REQUEST'
export const GET_TYPES_FORMATIONS_SUCCESS = 'GET_TYPES_FORMATIONS_SUCCESS'
export const GET_TYPES_FORMATIONS_ERROR = 'GET_TYPES_FORMATIONS_ERROR'

export const getTypesFormations = (idOrganisme, idExamenType) => dispatch => {
	dispatch({ type: GET_TYPES_FORMATIONS_REQUEST })
	return api.getTypesFormations(idOrganisme, idExamenType)
		.then(typesFormations => dispatch({
			type: GET_TYPES_FORMATIONS_SUCCESS,
			typesFormations
		}))
		.catch(e => {
			dispatch({
				type: GET_TYPES_FORMATIONS_ERROR,
				e
			})
			throw e
		})
}

export const RESET_TYPES_FORMATIONS = 'RESET_TYPES_FORMATIONS'

export const resetTypesFormations = () => dispatch => {
	dispatch({ type: RESET_TYPES_FORMATIONS })
}

export const RESET_EPREUVES_EXAMEN_CANDIDAT = 'RESET_EPREUVES_EXAMEN_CANDIDAT'

export const resetEpreuvesExamenCandidat = () => dispatch => {
	dispatch({ type: RESET_EPREUVES_EXAMEN_CANDIDAT })
}
