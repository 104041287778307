import React from 'react'
import { compose } from 'redux'
import ParametrageForm from './ParametrageForm'
import { injectScript } from './services/injectScript'
import { injectExamenType } from '../examensType/services/examenTypeInjector'
import { injectEpreuveType } from '../epreuveType/services/epreuveTypeInjector'
import { injectToolbarData } from 'isotope-client'
import { getFilArianeScript } from './services/filAriane'
import ListeMatieres from './ListeMatieres'
import { checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'

const GeneriqueScriptPage = () => (
	<React.Fragment>
		<ParametrageForm />
		<ListeMatieres />
	</React.Fragment>
)

const filArianeGenerique = ({ examenType, epreuveType, script }) => getFilArianeScript(
	examenType.libelleCourt || '',
	examenType.etat || 0,
	epreuveType.libelle || '',
	epreuveType.etat || 0,
	script.etat || 0
)

export const ScriptPage = compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectExamenType,
	injectEpreuveType,
	injectScript(false),
	injectToolbarData(filArianeGenerique)
)(GeneriqueScriptPage)

export const ScriptExploitationPage = compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectExamenType,
	injectEpreuveType,
	injectScript(true),
	injectToolbarData(filArianeGenerique)
)(GeneriqueScriptPage)
