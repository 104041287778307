import Tab from '@material-ui/core/Tab'
import { checkAuthorities, FloatingButton, hasAccess, injectSession, PROFIL_UTILISATEUR, Tabs } from '@oceane/ui'
import { injectToolbarData } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { openPopupCreation } from './services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'
import { getOrganisme } from './services/popup/nouvelOrganismePopupActions'
import * as selectors from './services/popup/nouvelOrganismePopupSelectors'
import DetailOrganismeOnglet from './DetailOrganismeOnglet'
import ListeFormationsOrganismeOnglet from './ListeFormationsOrganismeOnglet'
import ListeFormationTheoriqueOrganismeOnglet from './ListeFormationTheoriqueOrganismeOnglet'
import { openPopupCreation as openPopupCreationTheorique } from './services/examenTheoriqueOrganisme/examenTheoriqueOrganismeAction'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

class OrganismeOngletPage extends Component {
	state = {
		tab: 0,
		organisme: {}
	};

	componentDidMount() {
		const { getOrganisme, match } = this.props

		getOrganisme(match.params.idOrganisme)
	}

	handleChangeTab = (value) => {
		this.setState({
			tab: value,
		});
	};

	render() {
		const {
			profil,
			organismeDetail,
			openPopupCreation,
			openPopupCreationTheorique,
			match
		} = this.props

		const {
			tab
		} = this.state

		const tabs = [<Tab key="detail" label="Détails de l'organisme" />]
		const tabViews = [<DetailOrganismeOnglet />]
		const floatingButtons = [<></>]
		if (organismeDetail.examenPratique) {
			tabs.push(<Tab key="examens-pratiques" label="Examens pratiques" />)
			tabViews.push(<ListeFormationsOrganismeOnglet />)
			floatingButtons.push(<FloatingButton onClick={openPopupCreation} />)
		}
		if (organismeDetail.examenTheorique) {
			tabs.push(<Tab key="examens-theoriques" label="Examens théoriques" />)
			tabViews.push(<ListeFormationTheoriqueOrganismeOnglet match={match}/>)
			floatingButtons.push(<FloatingButton onClick={openPopupCreationTheorique} />)
		}

		return (
			<React.Fragment>
				{hasAccess(PROFILS_LOGISTIQUE, profil) &&
					<React.Fragment>
						<Tabs
							initialTab={tab}
							tabs={tabs}
							tabViews={tabViews}
							onChangeTab={this.handleChangeTab}
						/>
						{floatingButtons[tab]}
					</React.Fragment>
				}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	organismeActif: selectors.getOrganismeActif(state),
	organismeDetail: selectors.getOrganismeDetails(state)
})

const actions = {
	getOrganisme,
	openPopupCreation,
	openPopupCreationTheorique
}

OrganismeOngletPage.propTypes = {
	profil: PropTypes.number,
	getOrganisme: PropTypes.func,
	organismeActif: PropTypes.object,
	organismeDetail: PropTypes.object,
	openPopupCreation: PropTypes.func,
	openPopupCreationTheorique: PropTypes.func
}

export default compose(
	injectToolbarData({ title: 'Détails de l\'organisme' }),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	injectSession
)(OrganismeOngletPage)
