import withStyles from '@material-ui/core/styles/withStyles'
import {
	Actions,
	Button,
	checkAuthorities,
	Error,
	injectSession,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import { getStyles, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { loadExamensTypes } from './services/injectSessions'
import { validerRapide } from './services/epreuveSujetAction'


const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const CreationSessionRapideOnglet = ({ examensTypes, submitting, error, handleSubmit, validerRapide, changeTab }) => {

	const validationRapide = (values) => {
		return validerRapide(values.examensType)
			.then(({ result }) => {
				changeTab(0, result)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	return (
		<>
			<Title value="Génération rapide de sujets" />
			<Error error={error} />
			<ResponsiveForm>
				<Field
					id="examensType"
					name="examensType"
					component={Select}
					label="Examen type *"
					displayEmpty
				>
					<MenuItem value="" />
					{examensTypes.map(s =>
						<MenuItem key={s.id} value={s.id}>
							{s.libelleCourt}
						</MenuItem>
					)}
				</Field>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(validationRapide)}
					>
						Créer session rapide
					</Button>
				</Actions>
			</ResponsiveForm>
		</>
	)
}

const actions = {
	validerRapide
}

CreationSessionRapideOnglet.propTypes = {
	validerRapide: PropTypes.func
}

export default compose(
	loadExamensTypes,
	connect(null, actions),
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	reduxForm({
		form: 'sessionsRapideForm',
		enableReinitialize: true
	}),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(CreationSessionRapideOnglet)
