import * as actions from './familleAction'
import { HANDLE_QUERY_LISTE_FAMILLES, RESET_QUERY_LISTE_FAMILLES } from './familleAction'
import { combineReducers } from 'redux'

const familleInit = {}

const initialRecherche = {
	search: ''
}

const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_LISTE_FAMILLES:
			return {
				search: action.value
			}
		case RESET_QUERY_LISTE_FAMILLES:
			return {
				search: ''
			}
		default:
			return state
	}
}

export const famille = (state = familleInit, action) => {
	switch (action.type) {
		case actions.GET_FAMILLE_SUCCESS:
		case actions.PUT_FAMILLE_SUCCESS:
			return action.famille
		case actions.GET_FAMILLE_ERROR:
		case actions.CLEAR_FAMILLE:
			return familleInit
		default:
			return state
	}
}

export const familles = combineReducers({
	famille,
	recherche
})