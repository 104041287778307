import { fetchFactory } from 'isotope-client'
import { formatQuery } from '@oceane/ui'

export const rechercher = (values) => fetchFactory(`/sessions/recherche?${formatQuery(values)}`)

export const postSessionPapier = (values) => fetchFactory(`/sessions`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getSessionPapierDonneesGenerales = (id) => fetchFactory(`/sessions/${id}/donnees-generales`)

export const putSessionPapierDonneesGenerales = (id, values) => fetchFactory(`/sessions/${id}/donnees-generales`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const modifierEpreuveSession = (idSession, idEpreuveSession, form) => fetchFactory(`/sessions/${idSession}/epreuves-session/${idEpreuveSession}`, {
	method: 'PUT',
	body: JSON.stringify(form)
})

export const deleteSessionPapier = (id) => fetchFactory(`/sessions/${id}`, { method: 'DELETE' })

export const modifierAffectationEpreuveSession = (idSession, idEpreuveSession, affectee) => fetchFactory(
	`/sessions/${idSession}/epreuves-session/${idEpreuveSession}/modifier-affectation?affectee=${affectee}`, {
	method: 'PUT'
})

export const getSujet = (idSession) => fetchFactory(`/sessions/sujet/${idSession}`)

export const getCandidat = (idSession) => fetchFactory(`/sessions/candidat/${idSession}`)

export const getListeSitesSession = (idSession) => fetchFactory(`/sessions/${idSession}/sites`)

export const getSiteSession = (idSession, idSite) => fetchFactory(`/sessions/site/${idSession}/${idSite}`)

export const getSurveillance = (idSession) => fetchFactory(`/sessions/${idSession}/surveillance`)

export const getEpreuveSession = (idSession, idSite, idEpreuve) => fetchFactory(`/sessions/${idSession}/${idSite}/epreuve/${idEpreuve}`)

export const getListeEpreuvesSurveillance = (idSession, idSite) => fetchFactory(`/sessions/${idSession}/epreuves/${idSite}`)

export const mettreCandidatAbsent = (idSession, idSite, idEpreuve, idCandidat) => fetchFactory(`/sessions/${idSession}/${idSite}/epreuve/${idEpreuve}/absent/${idCandidat}`, {
	method: 'put'
})

export const mettreCandidatPresent = (idSession, idSite, idEpreuve, idCandidat) => fetchFactory(`/sessions/${idSession}/${idSite}/epreuve/${idEpreuve}/present/${idCandidat}`, {
	method: 'put'
})

export const checkPlanningExist = (idSession, idSite) => fetchFactory(`/sessions/${idSession}/check-planning`, {
	method: 'post',
	body: idSite
})

export const affecterSurveillant = (values, idSession, idSite) => fetchFactory(`/sessions/${idSession}/surveillant/${idSite}`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getSurveillantsEpreuve = (idSession, idSite, idEpreuve) => fetchFactory(`/sessions/${idSession}/surveillant/${idSite}/epreuve/${idEpreuve}`)

export const deleteSurveillant = (idSession, idSite, idEpreuve, idSurveillant) => fetchFactory(`/sessions/${idSession}/${idSite}/epreuve/${idEpreuve}/surveillant/${idSurveillant}`, {
	method: 'delete'
})

export const getListeAffectations = (idSession) => fetchFactory(`/sessions/${idSession}/affectations`)

export const fermerCentreSession = (idSession, idCentre) => fetchFactory(`/sessions/${idSession}/fermer/${idCentre}`, {
	method: 'post'
})

export const ouvrirCentreSession = (idSession, idCentre) => fetchFactory(`/sessions/${idSession}/ouvrir/${idCentre}`, {
	method: 'post'
})

export const ouvrirSiteSession = (idSession, idCentre, idSite) => fetchFactory(`/sessions/${idSession}/ouvrir/${idCentre}/site/${idSite}`, {
	method: 'post'
})

export const fermerSiteSession = (idSession, idCentre, idSite) => fetchFactory(`/sessions/${idSession}/fermer/${idCentre}/site/${idSite}`, {
	method: 'post'
})

export const getCentrePapierSession = (idSession, idCentre) => fetchFactory(`/sessions/${idSession}/centre/${idCentre}`)