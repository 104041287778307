import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = createSelector(
	getReferences,
	references => references.epreuvesTypes
)

export const getEpreuveType = createSelector(
	getLocalState,
	state => state.details
)

export const getIdEpreuveType = createSelector(
	getEpreuveType,
	epreuveType => epreuveType.id
)

export const getLibelleEpreuveType = createSelector(
	getEpreuveType,
	epreuveType => epreuveType.libelle
)

export const getEtatEpreuveType = createSelector(
	getEpreuveType,
	epreuveType => epreuveType.etat
)

