import * as api from './sitesPhysiquesApi'

export const GET_SITES_PHYSIQUE_REQUEST = 'GET_SITES_PHYSIQUE_REQUEST'
export const GET_SITES_PHYSIQUE_SUCCESS = 'GET_SITES_PHYSIQUE_SUCCESS'
export const GET_SITES_PHYSIQUE_ERROR = 'GET_SITES_PHYSIQUE_ERROR'
export const getSitesPhysiques = (profil) => dispatch => {
	dispatch({ type: GET_SITES_PHYSIQUE_REQUEST })

	return api.getSitesPhysiques(profil)
		.then((sites) => dispatch({ type: GET_SITES_PHYSIQUE_SUCCESS, sites }))
		.catch(() => dispatch({ type: GET_SITES_PHYSIQUE_ERROR }))
}

export const CREATE_SITES_PHYSIQUE_REQUEST = 'CREATE_SITES_PHYSIQUE_REQUEST'
export const CREATE_SITES_PHYSIQUE_SUCCESS = 'CREATE_SITES_PHYSIQUE_SUCCESS'
export const CREATE_SITES_PHYSIQUE_ERROR = 'CREATE_SITES_PHYSIQUE_ERROR'
export const postLocalisationSite = (values) => dispatch => {
	dispatch({ type: CREATE_SITES_PHYSIQUE_REQUEST })

	return api.postLocalisationSite(values)
		.then((site) => dispatch({ type: CREATE_SITES_PHYSIQUE_SUCCESS, site }))
		.catch(e => {
			dispatch({ type: CREATE_SITES_PHYSIQUE_ERROR })
			throw e
		})
}

export const GET_SITE_PHYSIQUE_REQUEST = 'GET_SITE_PHYSIQUE_REQUEST'
export const GET_SITE_PHYSIQUE_SUCCESS = 'GET_SITE_PHYSIQUE_SUCCESS'
export const GET_SITE_PHYSIQUE_ERROR = 'GET_SITE_PHYSIQUE_ERROR'
export const getSitePhysique = (id) => dispatch => {
	dispatch({ type: GET_SITE_PHYSIQUE_REQUEST })

	return api.getSitePhysique(id)
		.then((site) => dispatch({ type: GET_SITE_PHYSIQUE_SUCCESS, site }))
		.catch(e => {
			dispatch({ type: GET_SITE_PHYSIQUE_ERROR })
			throw e
		})
}

export const MODIFICATION_SITE_PHYSIQUE_REQUEST = 'MODIFICATION_SITE_PHYSIQUE_REQUEST'
export const MODIFICATION_SITE_PHYSIQUE_SUCCESS = 'MODIFICATION_SITE_PHYSIQUE_SUCCESS'
export const MODIFICATION_SITE_PHYSIQUE_ERROR = 'MODIFICATION_SITE_PHYSIQUE_ERROR'
export const modificationLocalisationSite = (values, profil) => dispatch => {
	dispatch({ type: MODIFICATION_SITE_PHYSIQUE_REQUEST })

	return api.modificationLocalisationSite(values, profil)
		.then((site) => dispatch({ type: MODIFICATION_SITE_PHYSIQUE_SUCCESS, site }))
		.catch(e => {
			dispatch({ type: MODIFICATION_SITE_PHYSIQUE_ERROR })
			throw e
		})
}

export const CHECK_DOWNLOAD_REQUEST = 'CHECK_DOWNLOAD_REQUEST'
export const CHECK_DOWNLOAD_SUCCESS = 'CHECK_DOWNLOAD_SUCCESS'
export const CHECK_DOWNLOAD_ERROR = 'CHECK_DOWNLOAD_ERROR'
export const checkFileExist = (id, nomFichier) => dispatch => {
	dispatch({ type: CHECK_DOWNLOAD_REQUEST })

	return api.checkFileExist(id, nomFichier)
		.then(() => dispatch({ type: CHECK_DOWNLOAD_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHECK_DOWNLOAD_ERROR })
			throw e
		})
}

export const GET_DECALAGES_HORAIRES_REQUEST = 'GET_DECALAGES_HORAIRES_REQUEST'
export const GET_DECALAGES_HORAIRES_SUCCESS = 'GET_DECALAGES_HORAIRES_SUCCESS'
export const GET_DECALAGES_HORAIRES_ERROR = 'GET_DECALAGES_HORAIRES_ERROR'
export const getDecalageHoraire = () => dispatch => {
	dispatch({ type: GET_DECALAGES_HORAIRES_REQUEST })

	return api.getDecalageHoraire()
		.then((decalagesHoraires) => dispatch({ type: GET_DECALAGES_HORAIRES_SUCCESS, decalagesHoraires }))
		.catch(e => {
			dispatch({ type: GET_DECALAGES_HORAIRES_ERROR })
			throw e
		})
}
