import {
	SET_DETAIL_ORGANISME_ERROR,
	SET_DETAIL_ORGANISME_REQUEST,
	SET_DETAIL_ORGANISME_SUCCESS
} from '../popup/nouvelOrganismePopupActions'
import * as api from './detailOrganismeApi'

export const GET_COMTPES_CLIENTS_REQUEST = 'GET_COMTPES_CLIENTS_REQUEST'
export const GET_COMTPES_CLIENTS_SUCCESS = 'GET_COMTPES_CLIENTS_SUCCESS'
export const GET_COMTPES_CLIENTS_ERROR = 'GET_COMTPES_CLIENTS_ERROR'
export const getComptesClients = () => dispatch => {
	dispatch({ type: GET_COMTPES_CLIENTS_REQUEST })
	return api.getComptesClients()
		.then((comptesClients) => {
			dispatch({
				type: GET_COMTPES_CLIENTS_SUCCESS,
				payload: comptesClients
			})
			return comptesClients
		})
		.catch((e) => {
			dispatch({
				type: GET_COMTPES_CLIENTS_ERROR,
				e
			})
			throw e
		})
}

export const enregistrerOrganisme = (organisme, active) => dispatch => {
	dispatch({ type: SET_DETAIL_ORGANISME_REQUEST })
	return api.enregistrerOrganisme(organisme, active)
		.then((result) => {
			dispatch({
				type: SET_DETAIL_ORGANISME_SUCCESS,
				payload: result
			})
		})
		.catch((e) => {
			dispatch({
				type: SET_DETAIL_ORGANISME_ERROR,
				e
			})
			throw e
		})
}
