import { createSelector } from 'reselect'

const getLocalState = state => state.candidat.formationEquivalences

export const getEpreuvesExamenCandidat = createSelector(
	getLocalState,
	state => state.listeEpreuves
)

export const getOrganismesActives = createSelector(
	getLocalState,
	state => state.listeOrganismesActives
)

export const getComptesClients = createSelector(
	getLocalState,
	state => state.listeComptesClients
)

export const getOrganismesExamenType = createSelector(
	getLocalState,
	state => state.listeOrganismesExamenType
)

export const getTypesFormations = createSelector(
	getLocalState,
	state => state.listeTypesFormations
)