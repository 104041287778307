import Tab from '@material-ui/core/Tab'
import { checkAuthorities, Loader, PROFIL_UTILISATEUR, Tabs } from '@oceane/ui'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import InformationsForm from './components/InformationsForm'
import { getInformation } from './services/administrationActions'

const TAB = ['CANDIDAT', 'ATO']

const InformationsPage = ({ getInformation }) => {

	const [tab, setTab] = React.useState(0)
	const [loading, setLoading] = React.useState(false)
	const [data, setData] = React.useState(null)

	useEffect(() => {
		getInformation(TAB[tab]).then(data => setData(data))
	}, [])

	const switchTab = (value) => {
		setLoading(true)
		setTab(value)
		getInformation(TAB[value]).then(data => {
			setData(data)
			setLoading(false)
		})
	}

	const tabViews = [
		<React.Fragment>
			{loading && <Loader minHeight={false} absolute />}
			{!loading && <InformationsForm data={data} />}
		</React.Fragment>,
		<React.Fragment>
			{loading && <Loader minHeight={false} absolute />}
			{!loading && <InformationsForm data={data} />}
		</React.Fragment>
	]

	return (data != null && <Tabs
		initialTab={0}
		tabs={[
			<Tab key="candidats" label="Candidats" />,
			<Tab key="ATO" label="ATO" />
		]}
		tabViews={tabViews}
		onChangeTab={switchTab}
		tabValue={tab}
	/>)

}

const actions = {
	getInformation
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.ADMINISTRATEUR),
	connect(null, actions)
)(InformationsPage)
