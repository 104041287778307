import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { ActionsBottomContext, getStyles, injectToolbarData, StaticDataTable } from 'isotope-client'
import { getFilArianePlanningQuotidien } from './services/filArianeSiteEcran'
import {
	Button,
	colors,
	EmptyResult,
	Filtres,
	Input,
	INS_ETAT_EPREUVE_INSCRIPTION,
	Link,
	Loader,
	OPM_CODE_RETOUR,
	ResponsiveForm
} from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import { cleanPlanning, getPlanningJournee, reouvrirConnexions, validerServeurLocal } from './services/siteEcranAction'
import * as selectors from './services/siteEcranSelectors'
import { FormattedMessage } from 'react-intl'
import { loadSiteEcran } from './services/injectSiteEcran'
import PopupAskForAction, { TYPE_ACTION } from './popup/PopupAskForAction'
import PopupConfirmerOuvertureAcces from './popup/PopupConfirmerOuvertureAcces'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { TYPE_SITE_ECRAN_ID } from '../../../config/enums'
import { PlanningQuotidienLightBean } from '../../../customTypings/PlanningQuotidienLightBean'
import { BaseReduxAction } from '../../../utils/redux'
import { SiteEcranBean } from '../../../customTypings/SiteEcranBean'
import { EXAMEN_CANDIDAT_STATUT_DOSSIER, EXAMEN_SPECIFIQUE } from '../../../utils/consts'

const styles = (theme: any) => getStyles({
	container: {
		position: 'fixed',
		top: 108
	},
	input: {
		marginBottom: 0
	},
	table: {
		marginTop: 51
	}
})

export const PLANNING_QUOTIDIEN_FILTRE_FORM_NAME = 'planningQuotidienFiltre'

type Props = {
	site: SiteEcranBean,
	dateJour: string,
	planning: PlanningQuotidienLightBean,
	getPlanningJournee: (idSite: number, dateJour: string) => any,
	validerServeurLocal: (idSite: number) => Promise<number>,
	reouvrirConnexions: (idSite: number, dateJour: string) => any,
	cleanPlanning: () => (dispatch: React.Dispatch<BaseReduxAction>) => Promise<any>,
	classes: any
}

type PopupError = {
	title: string,
	typeAction: { message: string, annuler: string, ignorer: string } | null,
	callback: (args: any) => any,
}

const PlanningQuotidienSiteEcran = (props: Props) => {
	const {
		site,
		dateJour,
		planning,
		getPlanningJournee,
		validerServeurLocal,
		reouvrirConnexions,
		cleanPlanning,
		classes
	} = props

	const { setActions }: any = React.useContext(ActionsBottomContext)

	const [isLoading, setLoading] = React.useState(false)

	// state popins
	const [openPopupError, setOpenPopupError] = React.useState(false)
	const [popupError, setPopupError] = React.useState<PopupError>({
		title: '',
		typeAction: null,
		callback: () => {
		}
	})

	const [openPopupAcces, setOpenPopupAcces] = React.useState(false)

	const getPlanningJourneeWithLoader = (idSite: number, dateJour: string) => {
		setLoading(true)
		return getPlanningJournee(idSite, dateJour)
			.finally(() => setLoading(false))
	}

	const handleOpenPopup = (title: string,
	                         typeAction: { message: string, annuler: string, ignorer: string },
	                         callback: (args: any) => any) => {
		setOpenPopupError(true)
		setPopupError({
			title,
			typeAction,
			callback
		})
	}

	const clearPopupContent = () => {
		setPopupError({
			title: '',
			typeAction: null,
			callback: () => {
			}
		})
	}

	const handleReouvrirConnexions = (idSite: number, dateJour: string) => reouvrirConnexions(idSite, dateJour)
		.then(() => getPlanningJourneeWithLoader(idSite, dateJour))


	const handleValiderServeurLocalEtReouvrirConnexions = () => validerServeurLocal(site.id)
		.then((result) => {
			switch (result) {
				case OPM_CODE_RETOUR.OK:
					return handleReouvrirConnexions(site.id, dateJour)
				case OPM_CODE_RETOUR.CONNECTION_REFUSED:
					return handleOpenPopup('Re-ouvrir connexions', TYPE_ACTION.ACTION_1, () => handleReouvrirConnexions(site.id, dateJour))
				default:
					return handleOpenPopup('Re-ouvrir connexions', TYPE_ACTION.ACTION_2, () => handleReouvrirConnexions(site.id, dateJour))
			}
		})

	const ouvrirAcces = () => download(`/files/sitesEcran/${site.id}/ouvertureAcces?date=${dateJour}`)

	const handleOuvrirAcces = () => {
		return validerServeurLocal(site.id)
			.then(result => {
				switch (result) {
					case OPM_CODE_RETOUR.OK:
						return ouvrirAcces()
					case OPM_CODE_RETOUR.CONNECTION_REFUSED:
						return handleOpenPopup('Accès journée', TYPE_ACTION.ACTION_1, () => ouvrirAcces())
					default:
						return handleOpenPopup('Accès journée', TYPE_ACTION.ACTION_2, () => ouvrirAcces())
				}
			})
	}

	React.useEffect(() => {
		getPlanningJourneeWithLoader(site.id, dateJour)
		setActions([
			<Button onClick={handleValiderServeurLocalEtReouvrirConnexions}>
				RE-OUVRIR CONNEXIONS
			</Button>,
			<Button onClick={() => setOpenPopupAcces(true)}>
				ACCES JOURNEE
			</Button>,
			<Button
				variant="contained"
				onClick={() => getPlanningJourneeWithLoader(site.id, dateJour)}
			>
				RAFRAICHIR
			</Button>
		])
		return () => {
			cleanPlanning()
			setActions(null)
		}
	}, [])

	const headers = [
		{
			key: 'horairesPrevus',
			name: 'Horaires prévus',
			sortable: false
		}, {
			key: 'numeroInscription',
			name: 'N° d\'inscription',
			sortable: false,
			render: (row: PlanningQuotidienLightBean) => (
				<Link to={`/examens/sites-ecran/${site.id}/epreuves/${dateJour}/${row.idEpreuveInscriptionEcran}`}>
					{row.numeroInscription}
				</Link>
			)
		}, {
			key: 'nomCandidat',
			name: 'Nom',
			sortable: false
		}, {
			key: 'prenomCandidat',
			name: 'Prénom',
			sortable: false
		}, {
			key: 'dateNaissanceCandidat',
			name: 'Date de naissance',
			render: (row: PlanningQuotidienLightBean) => {
				const year = row.dateNaissanceCandidat.substring(0, 4)
				const month = row.dateNaissanceCandidat.substring(4, 6)
				const day = row.dateNaissanceCandidat.substring(6, 10)
				return `${day}/${month}/${year}`
			},
			sortable: false
		}, {
			key: 'libelleExamen',
			name: 'Examen',
			sortable: false
		}, {
			key: 'libelleEpreuve',
			name: 'Epreuve',
			sortable: false
		}, {
			key: 'etat',
			name: 'Etat',
			render: (row: PlanningQuotidienLightBean) => {
				// RG4120/63626
				const etat = (
					row.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS
					&& row.examenCandidatStatutDossier !== EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
				) ? 'catsNonValide' : row.etat
				return (
					<FormattedMessage id={`references.sitesEcran.insEtatEpreuveInscription.${etat}`} />
				)
			},
			sortable: false
		}
	]
	if (site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT || site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME) {
		headers.push({
			key: 'horairesReels',
			name: 'Horaires réels',
			sortable: false
		})
	}

	return (
		<React.Fragment>
			<ResponsiveForm className={classes.container}>
				<Filtres>
					<Field
						name="filtre"
						placeholder="N° d'inscription, nom du candidat ou épreuve"
						component={Input}
						inputStyle={classes.input}
						normalize={(value: string) => value.toLowerCase()}
					/>
				</Filtres>
			</ResponsiveForm>
			<div className={classes.table}>
				{
					isLoading ?
						<Loader />
						:
						<StaticDataTable
							data={planning}
							noResultFragment={<EmptyResult />}
							noPagination
							headers={headers}
							applyRowStyle={(row: PlanningQuotidienLightBean) => {
								let backgroundColor = 'unset'

								// RG4120/63626
								if (
									row.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS
									&& row.examenCandidatStatutDossier !== EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
								) {
									return { backgroundColor: colors.rejected }
								}

								switch (row.etat) {
									case INS_ETAT_EPREUVE_INSCRIPTION.PANNE:
										backgroundColor = '#EBEBEB'
										break
									case INS_ETAT_EPREUVE_INSCRIPTION.PRESENCE:
										backgroundColor = '#C9E3F4'
										break
									case INS_ETAT_EPREUVE_INSCRIPTION.REUSSIE:
										backgroundColor = colors.validated
										break
									case INS_ETAT_EPREUVE_INSCRIPTION.INITIALISEE:
									case INS_ETAT_EPREUVE_INSCRIPTION.ECHOUEE:
									case INS_ETAT_EPREUVE_INSCRIPTION.REFUSEE:
									case INS_ETAT_EPREUVE_INSCRIPTION.FRAUDE:
										backgroundColor = colors.rejected
										break
									default:
										break
								}
								return {
									backgroundColor
								}
							}}
						/>
				}
			</div>
			<PopupAskForAction open={openPopupError}
			                   closePopup={() => setOpenPopupError(false)}
			                   clearPopupContent={clearPopupContent}
			                   {...popupError}
			/>
			<PopupConfirmerOuvertureAcces open={openPopupAcces}
			                              closePopup={() => setOpenPopupAcces(false)}
			                              actionValider={handleOuvrirAcces}
			/>
		</React.Fragment>
	)
}

PlanningQuotidienSiteEcran.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = (state: any, ownProps: any) => ({
	dateJour: ownProps.match.params.date,
	planning: selectors.getPlanningFiltre(state)
})

const actions = {
	getPlanningJournee,
	cleanPlanning,
	validerServeurLocal,
	reouvrirConnexions
}

export default compose(
	withStyles(styles),
	loadSiteEcran,
	connect(mapStateToProps, actions),
	reduxForm({
		form: PLANNING_QUOTIDIEN_FILTRE_FORM_NAME,
		destroyOnUnmount: false
	}),
	injectToolbarData(getFilArianePlanningQuotidien)
)(PlanningQuotidienSiteEcran)
