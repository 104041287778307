import { fetchFactory } from 'isotope-client'

export const rechercherDansLdap = (idUtilisateur, profil) =>
	fetchFactory(`/utilisateurs/${idUtilisateur}/ldap?profil=${profil}`)

export const getUtilisateur = (idUtilisateur, profil) => fetchFactory(`/utilisateurs/${idUtilisateur}?profil=${profil}`)

export const putUtilisateur = (idUtilisateur, values) => fetchFactory(`/utilisateurs/${idUtilisateur}`, {
	method: 'PUT',
	body: JSON.stringify(values)
})

export const synchroniserLdap = () => fetchFactory('/utilisateurs/ldap')

export const getInformation = (type) => fetchFactory(`/information/${type}`)

export const postInformation = (values) => fetchFactory(`/information`, {
	method: 'POST',
	body: JSON.stringify(values)
})
