import React from 'react'
import { StyledTypography } from '@oceane/ui'

export const getFilArianeEpreuveSession = (title, session, epreuve, canGoBack = true, addTitle = true) => ({
	title: addTitle ? title : undefined,
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			text: `Session : ${session}`
		}, {
			text: `Epreuve : ${epreuve}`,
			render: text => <StyledTypography bold>{text}</StyledTypography>
		}],
		canGoBack
	}
})