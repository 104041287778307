import Typography from '@material-ui/core/Typography'
import { Popup, Button } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'


const ConfirmationSuppressionSurveillantPopup = ({ open, supprimer, onClose }) => {

    const popupContent = () => (
            <Typography variant="body1">
                Confirmez-vous la suppression du surveillant ?
            </Typography>
    )

    const actions = [
        <Button
            key="annuler"
            color="primary"
            onClick={onClose}
        >
            Annuler
        </Button>,
        <Button
            key="supprimer"
            color="primary"
            variant="contained"
            onClick={supprimer}
        >
            Supprimer
        </Button>
    ]

    return (
        <Popup
            open={open}
            title="Suppression d'un surveillant"
            content={popupContent()}
            actions={actions}
        />
    )
}

ConfirmationSuppressionSurveillantPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    supprimer: PropTypes.func
}

export default compose(
    connect(null)
)(ConfirmationSuppressionSurveillantPopup)
