import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as banqueQuestionsActions from './banqueQuestionsActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { Loader } from '@oceane/ui'
import { createMatchSelector } from 'connected-react-router'
import * as selectors from './banqueQuestionsSelectors'

export const setExploitationQuestion = isExploitation => WrappedComponent => (props) => <WrappedComponent isExploitation={isExploitation} {...props} />

const injectMatchQuestion = (WrappedComponent) => {

	const mapStateToProps = (state, { isExploitation }) => {
		const match = createMatchSelector({ path: `/${isExploitation ? 'questionsExploitation' : 'questions'}/:idQuestion` })(state)
		return {
			idQuestion: match ? Number(match.params.idQuestion) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)

}

export const injectDonneesGenerales = (WrappedComponent) => {
	const QuestionInjector = ({ idQuestion, getQuestionDonneesGenerales, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			if (idQuestion != null && props.donneesGenerales.idQuestion !== idQuestion) {
				setLoading(true)
				getQuestionDonneesGenerales(idQuestion, props.isExploitation)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	QuestionInjector.propTypes = {
		getQuestionDonneesGenerales: PropTypes.func,
		cleanQuestion: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getQuestionDonneesGenerales: banqueQuestionsActions.getQuestionDonneesGenerales,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(QuestionInjector)
}

export const injectEnonces = (WrappedComponent) => {
	const EnoncesInjector = ({ idQuestion, getEnonces, ...props }) => {

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (idQuestion != null && props.enonces.idQuestion !== idQuestion) {
				setLoading(true)
				getEnonces(idQuestion, props.isExploitation)
					.then(() => setLoading(false))
					.catch(() => setLoading(false))
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent {...props} />
	}

	EnoncesInjector.propTypes = {
		idQuestion: PropTypes.number,
		getEnonces: PropTypes.func,
	}

	const mapStateToProps = state => ({
		enonces: selectors.getEnonces(state)
	})

	const actions = {
		getEnonces: banqueQuestionsActions.getEnonces,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
	)(EnoncesInjector)
}


export const injectAffectations = (WrappedComponent) => {
	const AffectationsInjector = ({ idQuestion, currentId, getQuestionAffectations, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			getQuestionAffectations(idQuestion, props.isExploitation)
				.then(() => setLoading(false))
				.catch(() => {
					setLoading(false)
					setError(true)
					snackError()
				})

		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	AffectationsInjector.propTypes = {
		idQuestion: PropTypes.number,
		currentId: PropTypes.number,
		getQuestionAffectations: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		currentId: selectors.getIdQuestion(state)
	})

	const actions = {
		getQuestionAffectations: banqueQuestionsActions.getQuestionAffectations,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(AffectationsInjector)
}

export const injectAnnexes = (WrappedComponent) => {
	const AnnexesInjector = ({ idQuestion, currentId, getQuestionAnnexes, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			getQuestionAnnexes(idQuestion, props.isExploitation)
				.then(() => setLoading(false))
				.catch(() => {
					setLoading(false)
					setError(true)
					snackError()
				})

		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	AnnexesInjector.propTypes = {
		idQuestion: PropTypes.string,
		currentId: PropTypes.number,
		annexesDisponibles: PropTypes.array,
		annexesAffectees: PropTypes.array,
		getQuestionAnnexes: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		currentId: selectors.getIdQuestion(state),
		annexesDisponibles: selectors.getAnnexesDisponibles(state),
		annexesAffectees: selectors.getAnnexesAffectees(state)
	})

	const actions = {
		getQuestionAnnexes: banqueQuestionsActions.getQuestionAnnexes,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(AnnexesInjector)
}

export const injectExpertise = (WrappedComponent) => {
	const ExpertiseInjector = ({ idQuestion, expertise, getQuestionExpertise, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			if (idQuestion != null && expertise.idQuestion !== idQuestion) {
				setLoading(true)
				getQuestionExpertise(idQuestion, props.isExploitation)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	ExpertiseInjector.propTypes = {
		idQuestion: PropTypes.number,
		expertise: PropTypes.object,
		getQuestionExpertise: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		expertise: selectors.getQuestionExpertise(state)
	})

	const actions = {
		getQuestionExpertise: banqueQuestionsActions.getQuestionExpertise,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(ExpertiseInjector)
}

export const injectEtatGlobal = WrappedComponent => {
	const EtatGlobalInjector = ({ idQuestion, etatGlobal, getQuestionEtatGlobal, affectations, snackError, ...props }) => {
		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			if (idQuestion != null && etatGlobal.idQuestion !== idQuestion) {
				setLoading(true)
				getQuestionEtatGlobal(idQuestion, props.isExploitation)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [])


		// Reload etat global lors du changement d'affectations
		// Utile pour mettre à jour le champ uniquement helicoptère
		React.useEffect(() => {
			getQuestionEtatGlobal(idQuestion, props.isExploitation)
		}, [affectations])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />

	}

	EtatGlobalInjector.propTypes = {
		idQuestion: PropTypes.number,
		etatGlobal: PropTypes.object,
		getQuestionEtatGlobal: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		etatGlobal: selectors.getEtatGlobal(state),
		affectations: selectors.getAffectations(state)
	})

	const actions = {
		getQuestionEtatGlobal: banqueQuestionsActions.getQuestionEtatGlobal,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(EtatGlobalInjector)
}

export const injectComparatifImport = (WrappedComponent) => {
	const ComparatifImportInjector = ({ idQuestion, comparatifImport, getQuestionComparatifImport, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			if (idQuestion != null && comparatifImport.idQuestion !== idQuestion) {
				setLoading(true)
				getQuestionComparatifImport(idQuestion)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	ComparatifImportInjector.propTypes = {
		idQuestion: PropTypes.number,
		comparatifImport: PropTypes.object,
		getQuestionComparatifImport: PropTypes.func,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		comparatifImport: selectors.getQuestionComparatifImport(state)
	})

	const actions = {
		getQuestionComparatifImport: banqueQuestionsActions.getQuestionComparatifImport,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchQuestion,
		injectSnackActions
	)(ComparatifImportInjector)
}
