import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getSitePapier } from './centrePapierActions'

export const loadSitePapier = (WrappedComponent) => {
	const SitePapierInjector = (props) => {
		const { getSitePapier, ...otherProps } = props

		const match = otherProps.match

		const [sitePapier, setSitePapier] = React.useState({})
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			if (match && match.params) {
				setLoading(true)
				getSitePapier(match.params.id, match.params.idSite)
					.then(({ site }) => {
						setLoading(false)
						setSitePapier(site)
					})
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent site={sitePapier} {...otherProps} />
	}

	SitePapierInjector.propTypes = {
		getSitePapier: PropTypes.func,
		match: PropTypes.object,
		snackError: PropTypes.func
	}

	const actions = {
		getSitePapier
	}

	return compose(
		connect(null, actions),
		injectSnackActions
	)(SitePapierInjector)
}