import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { Actions, injectFormSnackbar, LargeInput } from '@oceane/ui'
import { FormCheckbox, FormInput, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { modifierInformation } from '../services/administrationActions'

const style = () => getStyles({
	form: {
		marginTop: 20,
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const InformationsForm = ({ classes, handleSubmit, modifierInformation }) => {

	const submit = (values) => {
		return modifierInformation(values)
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	return (
		<form
			className={classes.form}
			id="informationsForm"
		>
			<Field
				id="dateDebut"
				name="dateDebut"
				label="Début d'affichage *"
				component={LargeInput}
				type="datetime-local"
			/>
			<Field
				id="dateFin"
				name="dateFin"
				label="Fin d'affichage *"
				component={LargeInput}
				type="datetime-local"
			/>
			<Field
				id="bloquant"
				name="bloquant"
				label="Message bloquant *"
				component={FormCheckbox}
			/>
			<Field
				id="titre"
				name="titre"
				label="Titre du message *"
				component={LargeInput}
			/>
			<Field
				id="contenu"
				name="contenu"
				label="Contenu du message *"
				multiline
				fullWidth
				rows={10}
				rowsMax={10}
				component={FormInput}
				inputProps={{
					maxLength: 800
				}}
			/>
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					Enregistrer
				</Button>
			</Actions>
		</form>
	)
}

InformationsForm.propTypes = {
	type: PropTypes.string
}

const mapStateToProps = (state, { data }) => ({
	initialValues: data.payload
})

const actions = {
	modifierInformation
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'informationsForm',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar(),
	withStyles(style)
)(InformationsForm)