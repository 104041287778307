import { createSelector } from 'reselect'

const getLocalState = state => state.correctionCopies

const getEpreuves = createSelector(
	getLocalState,
	state => state.epreuves
)

const getEpreuvesIds = createSelector(
	getEpreuves,
	state => state.ids
)

const getEpreuvesById = createSelector(
	getEpreuves,
	state => state.byId
)

export const getListeEpreuves = createSelector(
	getEpreuvesIds,
	getEpreuvesById,
	(ids, byId) => ids.map(id => byId[id])
)

export const getEpreuveSession = createSelector(
	getEpreuves,
	state => state.epreuveSession
)

export const getResultatControleEtCorrection = createSelector(
	getEpreuves,
	state => state.resultatControleEtCorrection
)