import * as api from './epreuveTypeApi'

export const GET_EPREUVE_TYPE_REQUEST = 'REFERENCES/GET_EPREUVE_TYPE_REQUEST'
export const GET_EPREUVE_TYPE_SUCCESS = 'REFERENCES/GET_EPREUVE_TYPE_SUCCESS'
export const GET_EPREUVE_TYPE_ERROR	  = 'REFERENCES/GET_EPREUVE_TYPE_ERROR'
export const getEpreuveType = (idExamenType, idEpreuveType) => dispatch => {
	dispatch({ type: GET_EPREUVE_TYPE_REQUEST })
	return api.getEpreuveType(idExamenType, idEpreuveType)
		.then(epreuve => dispatch({
			type: GET_EPREUVE_TYPE_SUCCESS,
			epreuve
		}))
		.catch(e => {
			dispatch({ type: GET_EPREUVE_TYPE_ERROR })
			throw e
		})
}

export const CLEAR_EPREUVE_TYPE = 'REFERENCES/CLEAR_EPREUVE_TYPE'
export const clearEpreuveType = () => dispatch => dispatch({ type: CLEAR_EPREUVE_TYPE })

export const MODIFIER_EPREUVE_TYPE_REQUEST = 'REFERENCES/MODIFIER_EPREUVE_TYPE_REQUEST'
export const MODIFIER_EPREUVE_TYPE_SUCCESS = 'REFERENCES/MODIFIER_EPREUVE_TYPE_SUCCESS'
export const MODIFIER_EPREUVE_TYPE_ERROR   = 'REFERENCES/MODIFIER_EPREUVE_TYPE_ERROR'
export const modifierEpreuveType = (idEpreuveType, values) => dispatch => {
	dispatch({ type: MODIFIER_EPREUVE_TYPE_REQUEST })
	return api.modifierEpreuveType(idEpreuveType, values)
		.then(epreuve => dispatch({
			type: MODIFIER_EPREUVE_TYPE_SUCCESS,
			epreuve
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_EPREUVE_TYPE_ERROR })
			throw e
		})
}

export const INITIALISER_EPREUVE_TYPE_REQUEST = 'REFERENCES/INITIALISER_EPREUVE_TYPE_REQUEST'
export const INITIALISER_EPREUVE_TYPE_SUCCESS = 'REFERENCES/INITIALISER_EPREUVE_TYPE_SUCCESS'
export const INITIALISER_EPREUVE_TYPE_ERROR   = 'REFERENCES/INITIALISER_EPREUVE_TYPE_ERROR'
export const initialiserEpreuveType = (idEpreuveType, values) => dispatch => {
	dispatch({ type: INITIALISER_EPREUVE_TYPE_REQUEST })
	return api.initialiserEpreuveType(idEpreuveType, values)
		.then(epreuve => dispatch({
			type: INITIALISER_EPREUVE_TYPE_SUCCESS,
			epreuve
		}))
		.catch(e => {
			dispatch({ type: INITIALISER_EPREUVE_TYPE_ERROR })
			throw e
		})
}

export const SUPPRIMER_EPREUVE_TYPE_REQUEST = 'REFERENCES/SUPPRIMER_EPREUVE_TYPE_REQUEST'
export const SUPPRIMER_EPREUVE_TYPE_SUCCESS = 'REFERENCES/SUPPRIMER_EPREUVE_TYPE_SUCCESS'
export const SUPPRIMER_EPREUVE_TYPE_ERROR   = 'REFERENCES/SUPPRIMER_EPREUVE_TYPE_ERROR'
export const supprimerEpreuveType = (idEpreuveType) => dispatch => {
	dispatch({ type: SUPPRIMER_EPREUVE_TYPE_REQUEST })
	return api.supprimerEpreuveType(idEpreuveType)
		.then(() => dispatch({
			type: SUPPRIMER_EPREUVE_TYPE_SUCCESS
		}))
		.catch(e => {
			dispatch({ type: SUPPRIMER_EPREUVE_TYPE_ERROR })
			throw e
		})
}