import { getReferences } from '../../referencesSelectors'
import { createSelector } from 'reselect'

const getLocalState = createSelector(
	getReferences,
	references => references.organismes
)

const organismeIds = createSelector(
	getLocalState,
	organismes => organismes.ids
)

const organismeById = createSelector(
	getLocalState,
	organismes => organismes.byId
)

export const getListeOrganismes = createSelector(
	organismeIds,
	organismeById,
	(ids, byId) => ids.map(id => byId[id])
)

const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)

export const getSwitchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.switch
)