import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Actions, Button, checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'
import { formValueSelector } from 'redux-form'
import { injectListeMatieres } from '../../references/matieres/services/injectListeMatieres'
import QuestionsNonPayeesActions from './components/QuestionsNonPayeesActions'
import PaiementForm from './components/PaiementForm'
import QuestionsNonPayeesDataTable from './components/QuestionsNonPayeesDataTable'
import { getListePaiements, updateEtatPaiement } from './services/paiementActions'
import { ETAT_PAIEMENT, ORIGINE_PAIEMENT } from '../../../config/enums'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as paiementSelectors from './services/paiementSelectors'
import QuestionsPayees from './components/QuestionsPayees'
import { Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'

const PAIEMENT_FORM_NAME = 'paiementForm'

const PaiementPage = ({ resultSize, selectedMatiere, origine, updateEtatPaiement, getListePaiements, snackSuccess, snackError }) => {
	// Ref pour la datatable
	// Permet de clear la sélection via cette ref
	const dataTableRef = React.useRef();

	const payer = () => {
		updateEtatPaiement({
			etatDePaiement: ETAT_PAIEMENT.PAYEE,
			idMatiere: selectedMatiere,
			origine
		})
			.then(() => {
				getListePaiements(selectedMatiere, origine)
				snackSuccess('Les paiements ont été enregistrés')
			})
			.catch(() => snackError())
	}

	if (!selectedMatiere) {
		return <PaiementForm origine={origine} />
	}

	return (
		<>
			<PaiementForm origine={origine} />
			<Title value="Questions non payées" />
			<QuestionsNonPayeesActions origine={origine} selectedMatiere={selectedMatiere} dataTableRef={dataTableRef} />
			<QuestionsNonPayeesDataTable origine={origine} selectedMatiere={selectedMatiere} dataTableRef={dataTableRef} />
			{resultSize > 0 && <Actions>
				{origine === ORIGINE_PAIEMENT.EUROPEENNE &&
				<Button
					color="primary"
					onClick={() => download(`/files/questions/paiements/etat-global-questions-non-payees?idMatiere=${selectedMatiere}&origine=${origine}`)}
				>
					Etat global
				</Button>
				}
				<Button
					color="primary"
					variant="contained"
					onClick={payer}
				>
					Payer
				</Button>
			</Actions>
			}
			<QuestionsPayees selectedMatiere={selectedMatiere} origine={origine} />
		</>
	)
}

PaiementPage.propTypes = {
	origine: PropTypes.number,
	selectedMatiere: PropTypes.number,
	resultSize: PropTypes.number,
	reset: PropTypes.func,
	getListePaiements: PropTypes.func,
	updateEtatPaiement: PropTypes.func
}

const mapStateToProps = (state) => ({
	selectedMatiere: formValueSelector(PAIEMENT_FORM_NAME)(state, 'matiere'),
	resultSize: paiementSelectors.getListePaiements(state).length
})

const actions = {
	getListePaiements,
	updateEtatPaiement
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectListeMatieres,
	connect(mapStateToProps, actions),
	injectSnackActions
)(PaiementPage)
