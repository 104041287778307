import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { EmptyResult } from '@oceane/ui'
import { dataTableActions, StaticDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
	closePopup,
	deleteFormationTheoriqueOrganisme, getExamensTypeTheorique,
	openPopup
} from './services/examenTheoriqueOrganisme/examenTheoriqueOrganismeAction'
import * as selectors from './services/popup/nouvelOrganismePopupSelectors'
import NouvelleFormationTheoriqueOrganismePopup from './NouvelleFormationTheoriqueOrganismePopup'
import SuppressionFormationTheoriqueOrganismePopup from './SuppressionFormationTheoriqueOrganismePopup'
import { getListeFormationsTheoriques } from './services/organismeActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const ListeFormationTheoriqueOrganismeOnglet = ({ organisme, match, openPopup, getExamensTypeTheorique, getListeFormationsTheoriques, deleteFormationTheoriqueOrganisme, closePopup, snackError }) => {
	const [idDelete, setIdDelete] = React.useState(null)
	const [listeFormationsTheoriques, setListeFormationsTheoriques] = React.useState([])
	const [loadData, setLoadData] = React.useState(true)
	const refreshTable = () => setLoadData(true)

	const openPopupDelete = (id) => {
		setIdDelete(id)
		openPopup()
	}

	const deleteAction = () => deleteFormationTheoriqueOrganisme(idDelete)
		.then(() => {
			refreshTable()
			closePopup()
		})

	React.useEffect(() => {
		if (loadData && organisme) {
			getExamensTypeTheorique(organisme.id)
			getListeFormationsTheoriques(organisme.id)
				.then(result => {
					setListeFormationsTheoriques(result)
					setLoadData(false)
				})
				.catch(() => {
					setListeFormationsTheoriques([])
					snackError()
				})
		}
	}, [loadData, organisme])

	React.useEffect(() => () => setListeFormationsTheoriques([]), [])

	return (
		<React.Fragment>
			<StaticDataTable
				data={listeFormationsTheoriques}
				headers={[
					{
						key: 'libelle',
						type: 'string',
						name: <FormattedMessage id='references.organismes.onglets.theorique.examen' />,
						sortable: true
					},
					{
						key: 'supprimer',
						sortable: false,
						render: data => <IconButton onClick={() => openPopupDelete(data.id)}><DeleteIcon /></IconButton>
					}
				]}
				noResultFragment={<EmptyResult message="Aucun examen théorique rattaché"/>}
			/>
			<SuppressionFormationTheoriqueOrganismePopup onSubmit={deleteAction} />
			<NouvelleFormationTheoriqueOrganismePopup refreshTable={refreshTable} match={match} />
		</React.Fragment>
	)
}

ListeFormationTheoriqueOrganismeOnglet.propTypes = {
	organisme: PropTypes.object,
	refreshTable: PropTypes.func,
	openPopup: PropTypes.func
}

const mapStateToProps = state => ({
	organisme: selectors.getOrganismeDetails(state)
})

const actions = {
	refreshTable: () => dataTableActions.refresh('organismesListeFormationsTheorique'),
	openPopup,
	closePopup,
	getListeFormationsTheoriques,
	getExamensTypeTheorique,
	deleteFormationTheoriqueOrganisme
}

export default compose(
	connect(mapStateToProps, actions),
	injectSnackActions
)(ListeFormationTheoriqueOrganismeOnglet)
