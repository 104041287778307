import {
	checkAuthorities,
	injectSession,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	Actions,
	Button,
	InputRadio,
	BandeauInfo,
	injectFormSnackbar
} from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { Title, getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { download } from 'isotope-client/components/download/IsotopeLink'
import * as selectors from '../../services/sessionsPapierSelectors'
import { injectEditionCandidats } from '../services/SessionPapierInjector'
import Grid from '@material-ui/core/Grid'
import { injectMatchSession } from '../../services/sessionsPapierInjectors'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const ETAT_creee = 1
const ETAT_initialisee = 2
const ETAT_inscriptions_closes = 3
const ETAT_planifiee = 4
const ETAT_convocations_envoyees = 5
const ETAT_PV_saisis = 6
const ETAT_corrigee = 7
const ETAT_cloturee = 8

const OngletEditionSujets = ({ sessionPapier, idSession, candidat, tri, epreuve, site }) => {
	const [reprographyError, setReprographyError] = React.useState(false)
	const [siteError, setSiteError] = React.useState(false)
	const [brinksError, setBrinksError] = React.useState(false)
	const [convocationError, setConvocationError] = React.useState(false)
	const etat = sessionPapier.etat

	React.useEffect(() => {
		if (etat === ETAT_creee || etat === ETAT_initialisee || etat === ETAT_PV_saisis || etat === ETAT_corrigee || etat === ETAT_cloturee) {
			setReprographyError(true)
		} else {
			setReprographyError(false)
		}

		if (etat === ETAT_creee || etat === ETAT_initialisee || etat === ETAT_inscriptions_closes) {
			setSiteError(true)
		} else {
			setSiteError(false)
		}

		if (etat !== ETAT_planifiee && etat !== ETAT_convocations_envoyees) {
			setBrinksError(true)
		} else {
			setBrinksError(false)
		}

		if (etat !== ETAT_planifiee) {
			setConvocationError(true)
		} else {
			setConvocationError(false)
		}
	}, [sessionPapier])

	return (
		<>
			<Title value="Reprographie" />
			{reprographyError &&
			<BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{(etat === ETAT_inscriptions_closes || etat === ETAT_planifiee || etat === ETAT_convocations_envoyees) && <>
				<Actions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => download(`/files/candidat/reprographie/${idSession}`)}
					>
						Afficher
					</Button>
				</Actions>
			</>}
			<Title value="Candidats d'un site" />
			{siteError && <BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{(etat === ETAT_planifiee || etat === ETAT_convocations_envoyees || etat === ETAT_PV_saisis || etat === ETAT_corrigee || etat === ETAT_cloturee) && <>
				<ResponsiveForm>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Field
								id="site"
								name="site"
								label="Site *"
								component={Select}
							>
								<MenuItem key={-1} value={-1}>
									Tous les sites
								</MenuItem>
								{candidat.listeSites && candidat.listeSites.length !== 0 && candidat.listeSites.map((site) =>
									<MenuItem key={site.id} value={site.id}>
										{site.libelle}
									</MenuItem>
								)}
							</Field>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Field
								id="epreuve"
								name="epreuve"
								label="Epreuve *"
								component={Select}
							>
								<MenuItem key={-1} value={-1}>
									Toutes les épreuves
								</MenuItem>
								{candidat.listeEpreuves && candidat.listeEpreuves.length !== 0 && candidat.listeEpreuves.map((epreuve) =>
									<MenuItem key={epreuve.id} value={epreuve.id}>
										{epreuve.libelle}
									</MenuItem>
								)}
							</Field>
						</Grid>
					</Grid>
					<Field
						name="tri"
						label="Tri"
						component={InputRadio}
						choices={[
							{
								value: 1,
								label: 'N° inscription'
							},
							{
								value: 2,
								label: 'Alphabétique'
							}
						]}
						normalize={Number}
					/>
					<Actions>
						<Button
							color="primary"
							variant="contained"
							onClick={() => download(`/files/candidat/siteAfficher/${idSession}?tri=${tri}&site=${site}&epreuve=${epreuve}`)}
						>
							Afficher
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => download(`/files/candidat/siteExporter/${idSession}?tri=${tri}&site=${site}&epreuve=${epreuve}`)}
						>
							Exporter
						</Button>
					</Actions>
				</ResponsiveForm>
			</>}
			<Title value="Enveloppes BRINKS" />
			{brinksError &&
			<BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{(etat === ETAT_planifiee || etat === ETAT_convocations_envoyees) && <>
				<Actions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => download(`/files/candidat/pagesDeGarde/${idSession}`)}
					>
						Pages de garde
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => download(`/files/candidat/quantiteCentre/${idSession}`)}
					>
						Quantité par centre
					</Button>
				</Actions>
			</>}
			<Title value="Convocations candidats" />
			{convocationError &&
			<BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{etat === ETAT_planifiee && <Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={() => download(`/files/candidat/convocation/${idSession}`)}
				>
					Exporter
				</Button>
			</Actions>}
		</>
	)
}

const actions = {}

OngletEditionSujets.propTypes = {
	sessionPapier: PropTypes.func,
	candidat: PropTypes.object,
	tri: PropTypes.number,
	epreuve: PropTypes.number,
	site: PropTypes.number,
	idSession: PropTypes.number
}

const mapStateToProps = (state) => {
	const { tri, epreuve, site } = formValueSelector('editionCandidatForm')(state, 'tri', 'epreuve', 'site')

	return ({
		sessionPapier: selectors.getDonneesGenerales(state),
		tri,
		epreuve,
		site
	})
}

export default compose(
	injectMatchSession,
	injectEditionCandidats,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'editionCandidatForm',
		initialValues: {
			site: -1,
			epreuve: -1,
			tri: 1
		},
	}),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(OngletEditionSujets)