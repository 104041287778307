import * as api from './piecesJustificativesApi'

export const DELETE_PIECE_JUSTIFICATIVE_REQUEST = 'PIECE_JUSTIFICATIVE/DELETE_PIECE_JUSTIFICATIVE_REQUEST'
export const DELETE_PIECE_JUSTIFICATIVE_SUCCESS = 'PIECE_JUSTIFICATIVE/DELETE_PIECE_JUSTIFICATIVE_SUCCESS'
export const DELETE_PIECE_JUSTIFICATIVE_ERROR = 'PIECE_JUSTIFICATIVE/DELETE_PIECE_JUSTIFICATIVE_ERROR'
export const deletePieceJustificative = (id) => dispatch => {
	dispatch({ type: DELETE_PIECE_JUSTIFICATIVE_REQUEST })
	return api.deletePieceJustificative(id)
		.then((result) => {
			dispatch({
				type: DELETE_PIECE_JUSTIFICATIVE_SUCCESS,
				payload: result
			})
		})
		.catch((e) => {
			dispatch({
				type: DELETE_PIECE_JUSTIFICATIVE_ERROR,
				e
			})
			throw e
		})
}

export const OPEN_POPUP = 'PIECE_JUSTIFICATIVE/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'PIECE_JUSTIFICATIVE/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const OPEN_POPUP_CREATION = 'PIECE_JUSTIFICATIVE/OPEN_POPUP_CREATION'
export const openPopupCreation = () => dispatch => dispatch({
	type: OPEN_POPUP_CREATION
})

export const CLOSE_POPUP_CREATION = 'PIECE_JUSTIFICATIVE/CLOSE_POPUP_CREATION'
export const closePopupCreation = () => dispatch => dispatch({
	type: CLOSE_POPUP_CREATION
})

export const CREATE_PIECE_JUSTIFICATIVE_REQUEST = 'PIECE_JUSTIFICATIVE/CREATE_PIECE_JUSTIFICATIVE_REQUEST'
export const CREATE_PIECE_JUSTIFICATIVE_SUCCESS = 'PIECE_JUSTIFICATIVE/CREATE_PIECE_JUSTIFICATIVE_SUCCESS'
export const CREATE_PIECE_JUSTIFICATIVE_ERROR = 'PIECE_JUSTIFICATIVE/CREATE_PIECE_JUSTIFICATIVE_ERROR'
export const createPieceJustificative = (values) => dispatch => {
	dispatch({ type: CREATE_PIECE_JUSTIFICATIVE_REQUEST })
	return api.createPieceJustificative(values)
		.then((result) => {
			dispatch({
				type: CREATE_PIECE_JUSTIFICATIVE_SUCCESS,
				payload: result
			})
			return result
		})
		.catch(e => {
			dispatch({
				type: CREATE_PIECE_JUSTIFICATIVE_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_PIECE_JUSTIFICATIVE_REQUEST = 'PIECE_JUSTIFICATIVE/DETAIL_PIECE_JUSTIFICATIVE_REQUEST'
export const DETAIL_PIECE_JUSTIFICATIVE_SUCCESS = 'PIECE_JUSTIFICATIVE/DETAIL_PIECE_JUSTIFICATIVE_SUCCESS'
export const DETAIL_PIECE_JUSTIFICATIVE_ERROR = 'PIECE_JUSTIFICATIVE/DETAIL_PIECE_JUSTIFICATIVE_ERROR'
export const getDetailPieceJustificative = (id) => dispatch => {
	dispatch({ type: DETAIL_PIECE_JUSTIFICATIVE_REQUEST })
	return api.getDetailPieceJustificative(id)
		.then((result) => {
			dispatch({
				type: DETAIL_PIECE_JUSTIFICATIVE_SUCCESS,
				payload: result
			})
		})
		.catch(e => {
			dispatch({
				type: DETAIL_PIECE_JUSTIFICATIVE_ERROR,
				e
			})
		})
}

export const MODIFICATION_PIECE_JUSTIFICATIVE_REQUEST = 'PIECE_JUSTIFICATIVE/MODIFICATION_PIECE_JUSTIFICATIVE_REQUEST'
export const MODIFICATION_PIECE_JUSTIFICATIVE_SUCCESS = 'PIECE_JUSTIFICATIVE/MODIFICATION_PIECE_JUSTIFICATIVE_SUCCESS'
export const MODIFICATION_PIECE_JUSTIFICATIVE_ERROR = 'PIECE_JUSTIFICATIVE/MODIFICATION_PIECE_JUSTIFICATIVE_ERROR'
export const modificationPieceJustificative = (values) => dispatch => {
	dispatch({ type: MODIFICATION_PIECE_JUSTIFICATIVE_REQUEST })
	return api.modificationPieceJustificative(values)
		.then((result) => {
			dispatch({
				type: MODIFICATION_PIECE_JUSTIFICATIVE_SUCCESS,
				payload: result
			})
		})
		.catch(e => {
			dispatch({
				type: MODIFICATION_PIECE_JUSTIFICATIVE_ERROR,
				e
			})
			throw e
		})
}

