import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { getCandidat, getSujet } from '../../services/sessionsPapierActions'
import { injectMatchSession } from '../../services/sessionsPapierInjectors'

export const loadSujet = (WrappedComponent) => {
	const SessionPapierInjector = (props) => {
		const { getSujet, idSession, ...otherProps } = props

		const [sessionPapier, setSessionPapier] = React.useState({})
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			getSujet(idSession)
				.then(({ result }) => {
					setLoading(false)
					setSessionPapier(result)
				})
				.catch(e => {
					setLoading(false)
					throw e
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent sessionPapier={sessionPapier} {...otherProps} />
	}

	SessionPapierInjector.propTypes = {
		idSession: PropTypes.number,
		getSujet: PropTypes.func,
		match: PropTypes.object
	}

	const actions = {
		getSujet
	}

	return compose(
		injectMatchSession,
		connect(null, actions)
	)(SessionPapierInjector)
}

export const injectEditionCandidats = (WrappedComponent) => {
	const EditionCandidatInjector = (props) => {
		const { getCandidat, ...otherProps } = props


		const [candidat, setCandidat] = React.useState({})
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (otherProps.idSession) {
				setLoading(true)
				getCandidat(otherProps.idSession)
					.then(({ result }) => {
						setLoading(false)
						setCandidat(result)
					})
					.catch(e => {
						setLoading(false)
						throw e
					})
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent candidat={candidat} {...otherProps} />
	}

	EditionCandidatInjector.propTypes = {
		getCandidat: PropTypes.func
	}

	const actions = {
		getCandidat
	}

	return compose(
		connect(null, actions)
	)(EditionCandidatInjector)
}