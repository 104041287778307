import * as api from '../detailExamenApi'

export const OPEN_POPUP = 'NOUVELLE_INSCRIPTION/OPEN_POPUP'
/**
 * Ouvre la popup de création d'une nouvelle inscription
 */
export const openPopupNouvelleInscription = (type) => dispatch => {
	dispatch({ type: OPEN_POPUP, typeInscription: type })
}

export const CLOSE_POPUP = 'NOUVELLE_INSCRIPTION/CLOSE_POPUP'
/**
 * Ferme la popup de création d'une nouvelle inscription
 */
export const closePopupNouvelleInscription = () => dispatch => {
	dispatch({ type: CLOSE_POPUP })
}

export const CLEAR_POPUP_CONTENT = 'NOUVEL_EXAMEN/CLEAR_POPUP_CONTENT'
/**
 * Vide la popup
 */
export const clearPopupContent = () => dispatch => dispatch({
	type: CLEAR_POPUP_CONTENT
})

export const GET_SESSIONS_REQUEST = 'GET_SESSIONS_REQUEST'
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS'
export const GET_SESSIONS_ERROR = 'GET_SESSIONS_ERROR'
/**
 * Récupère la liste des sessions initialisées pour un examen type donné
 * @param idExamenType ID de l'examen type
 */
export const getSessionsInitialisees = (idExamenType) => dispatch => {
	dispatch({ type: GET_SESSIONS_REQUEST })
	api.getSessionsInitialisees(idExamenType)
		.then(result => dispatch({
			type: GET_SESSIONS_SUCCESS,
			listeSessions: result
		}))
		.catch(e => {
			dispatch({
				type: GET_SESSIONS_ERROR,
				e
			})
			throw e
		})
}
