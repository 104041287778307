import { fetchFactory, postMultipart } from 'isotope-client'

export const getListeCentresPapier = () => fetchFactory('/centresPapier')

export const createCentrePapier = (values) => fetchFactory('/centresPapier', {
	method: 'post',
	body: JSON.stringify(values)
})

export const getCentrePapier = (id) => fetchFactory(`/centresPapier/${id}`)

export const enregistrerCentre = (values) => fetchFactory(`/centresPapier/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getSites = (id) => fetchFactory(`/centresPapier/${id}/sites`)

export const createSitePapier = (values, id) => fetchFactory(`/centresPapier/${id}/sites`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getSitePapier = (id, idSitePapier) => fetchFactory(`/centresPapier/${id}/sites/${idSitePapier}`)

export const checkFileExist = (id, idSitePapier, nomFichier) => fetchFactory(`/centresPapier/${id}/sites/${idSitePapier}/file`, {
	method: 'post',
	body: nomFichier
})

export const modificationSitePapier = (values, id) => postMultipart(`/centresPapier/${id}/sites/${values.id}`, {
	body: values
})