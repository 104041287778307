import LoginPage from '../modules/login/OceaneLoginPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './App'
import { hot } from 'react-hot-loader'

const Root = () => (
	<Switch>
		<Route path='/login' component={LoginPage} />
		<Route path='/logout' component={LoginPage} />
		<Route path='/' component={App} />
		<Route path='*' component={App} />
	</Switch>
)

export default process.env.NODE_ENV === "development" ? hot(module)(Root) : Root
