import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { getStyles, StaticDataTable } from 'isotope-client'
import classNames from 'classnames'
import { EmptyResult } from '@oceane/ui'

const styles = () => getStyles({
	highlight: {
		color: 'red'
	},
	table: {
		width: '100%',
	}
})

const ImportExamensTable = ({ data, classes }) => (
	<StaticDataTable
		noResultFragment={<EmptyResult />}
		data={data}
		tableProps={{
			className: classes.table
		}}
		headers={[{
			key: 'libelleExamen',
			name: 'Nom',
			sortable: false
		}, {
			key: 'dureeExamen',
			name: 'Durée',
			sortable: false,
			render: row => <Typography className={classNames({ [classes.highlight]: row.mettreDureeEnEvidence })}>
				{row.dureeExamen}
			</Typography>
		}, {
			key: 'score',
			name: 'Score',
			sortable: false,
			render: row => <Typography className={classNames({ [classes.highlight]: row.mettreScoreEnEvidence })}>
				{row.score}
			</Typography>
		}]}
		noPagination
	/>
)

ImportExamensTable.propTypes = {
	classes: PropTypes.object
}

export default compose(
	withStyles(styles)
)(ImportExamensTable)
