import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { checkAuthorities, EmptyResult, FloatingButton, injectSession, loadCentres, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import NouveauSurveillantPopup from './components/NouveauSurveillantPopup'
import { rechercher } from './services/surveillantsActions'
import RechercheSurveillantsForm from './components/RechercheSurveillantsForm'
import { FormattedMessage } from 'react-intl'

const style = () => getStyles({
	input: {
		marginBottom: 10
	},
	inputSelect: {
		marginBottom: 10,
		paddingTop: 2
	},
	rechercher: {
		marginTop: 15
	},
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer'
		}
	}
})

const RechercheSurveillants = ({ submitting, handleSubmit, classes, centres, rechercher, goToEtatCivil }) => {
	const [resultats, setResultats] = React.useState([])
	const [openCreation, setOpenCreation] = React.useState(false)
	const [showResult, setShowResult] = React.useState(false)

	const recherche = (values) => {
		const val = {
			nom: values.nom ? values.nom : null,
			centre: values.centre ? values.centre : null
		}

		return rechercher(val)
			.then(({ response }) => {
				setResultats(response)
				setShowResult(true)
			})
	}

	return (<>
		<Title value="Critères" />
		<RechercheSurveillantsForm onSubmit={recherche} centres={centres} />
		{showResult && <>
			<Title value="Résultat de recherche" />
			<StaticDataTable
				data={resultats}
				headers={[{
					key: 'nom',
					name: 'Nom',
					sortable: true,
					type: 'string',
					render: data => <Typography className={classes.libelleColumn} onClick={() => goToEtatCivil(data)}>{data.nom}</Typography>
				}, {
					key: 'prenom',
					name: 'Prénom',
					sortable: true,
					type: 'string'
				}, {
					key: 'civilite',
					name: 'Civilité',
					sortable: true,
					type: 'string',
					render: row => <FormattedMessage id={`global.civilite.${row.civilite}`} />
				}, {
					key: 'codePostal',
					name: 'Code Postal',
					sortable: true,
					type: 'string'
				}, {
					key: 'ville',
					name: 'Ville',
					sortable: true,
					type: 'string'
				}]}
				noResultFragment={<EmptyResult />}
			/>
		</>
		}
		<FloatingButton onClick={() => setOpenCreation(true)} />
		<NouveauSurveillantPopup open={openCreation} closePopupCreation={() => setOpenCreation(false)} />
	</>)
}

const actions = {
	rechercher,
	goToEtatCivil: (surveillant) => dispatch => dispatch(push({
		pathname: `/surveillants/${surveillant.id}`,
		state: { nom: surveillant.nom, civilite: surveillant.civilite, prenom: surveillant.prenom }
	}))
}

RechercheSurveillants.propTypes = {
	rechercher: PropTypes.func,
	goToEtatCivil: PropTypes.func
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	loadCentres,
	connect(null, actions),
	injectSession,
	withStyles(style)
)(RechercheSurveillants)
