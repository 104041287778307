import { checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { SubmissionError } from 'redux-form'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { checkFormValid } from '../statistiquesCandidats/services/statistiquesCandidatsActions'
import StatistiquesCompetencesLinguistiquesForm from './components/StatistiquesCompetencesLinguistiquesForm'

const ConsulterStatistiquesCompetencesLinguistiques = ({
	                                                       submitting,
	                                                       handleSubmit,
	                                                       checkFormValid,
	                                                       error,
	                                                       snackError
                                                       }) => {

	const exporter = (values) => {
		return checkFormValid(values)
			.then(() =>
				download(`/files/statistiques/competencesLinguistiques/${values.type}/${values.debutPeriode}/${values.finPeriode}`)
			)
			.catch(e => {
				snackError('Le formulaire comporte des erreurs')
				throw new SubmissionError(e)
			})
	}

	return (<StatistiquesCompetencesLinguistiquesForm onSubmit={exporter} />)
}

ConsulterStatistiquesCompetencesLinguistiques.propTypes = {
	checkFormValid: PropTypes.func
}

const actions = {
	checkFormValid
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE
	),
	connect(null, actions),
	injectSnackbarActions
)(ConsulterStatistiquesCompetencesLinguistiques)
