import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { Field, reduxForm } from 'redux-form'
import { Input, Button, formatQuery } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import { download } from 'isotope-client/components/download/IsotopeLink'
import * as selectors from '../services/statistiquesQuestionsSelectors'

const styles = () => getStyles({
	saveButton: {
		marginTop: 17,
		marginLeft: 10
	}
})

const validate = values => {
	const errors = {}
	if (!values.reussiteMax) {
		errors.reussiteMax = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.reussiteMax)) {
		errors.reussiteMax = { id: 'global.erreurs.numeric' }
	}
	return errors
}

const ImprimerStatistiquesQuestions = ({ handleSubmit, filtres, classes }) => {

	const downloadStatistiques = values => {
		const parameters = {
			...filtres,
			...values
		}
		return download(`/files/statistiques/questions?${formatQuery(parameters)}`)
	}

	return (
		<Grid container direction="row" justify="flex-end">
			<Grid item container justify="space-around" xs={6} alignItems="flex-start" >
				<Grid item>
				<Field
					name="reussiteMax"
					label="Réussite en % <= *"
					component={Input}
					inputProps={{
						maxLength: 3
					}}
				/>
				</Grid>
				<Grid item>
					<Button
						onClick={handleSubmit(downloadStatistiques)}
						className={classes.saveButton}
					>
						Afficher
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}


ImprimerStatistiquesQuestions.propTypes = {
	handleSubmit: PropTypes.func,
	filtres: PropTypes.shape({
		matiere: PropTypes.number,
		examenType: PropTypes.number,
		debutPeriode: PropTypes.string,
		finPeriode: PropTypes.string
	}).isRequired,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	filtres: selectors.getFiltres(state)
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
	reduxForm({
		form: 'ImprimerStatistiquesQuestions',
		validate
	})
)(ImprimerStatistiquesQuestions)
