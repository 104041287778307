import * as api from './ligneProgrammeApi'

export const CLOSE_POPUP_MODIFICATION = 'MATIERE/CLOSE_POPUP_MODIFICATION'
export const closePopupModification = () => dispatch => dispatch({
	type: CLOSE_POPUP_MODIFICATION
})

export const CREATE_MATIERE_REQUEST = 'MATIERE/CREATE_MATIERE_REQUEST'
export const CREATE_MATIERE_SUCCESS = 'MATIERE/CREATE_MATIERE_SUCCESS'
export const CREATE_MATIERE_ERROR = 'MATIERE/CREATE_MATIERE_ERROR'
export const createMatiere = (values) => dispatch => {
	dispatch({ type: CREATE_MATIERE_REQUEST })
	return api.createMatiere(values)
		.then(() => dispatch({
			type: CREATE_MATIERE_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: CREATE_MATIERE_ERROR,
				e
			})
			throw e
		})
}

export const MODIFICATION_MATIERE_REQUEST = 'MATIERE/MODIFICATION_MATIERE_REQUEST'
export const MODIFICATION_MATIERE_SUCCESS = 'MATIERE/MODIFICATION_MATIERE_SUCCESS'
export const MODIFICATION_MATIERE_ERROR = 'MATIERE/MODIFICATION_MATIERE_ERROR'
export const modificationMatiere = (values) => dispatch => {
	dispatch({ type: MODIFICATION_MATIERE_REQUEST })
	return api.modificationMatiere(values)
		.then((result) => dispatch({
			type: MODIFICATION_MATIERE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: MODIFICATION_MATIERE_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_MATIERE_REQUEST = 'MATIERE/DETAIL_MATIERE_REQUEST'
export const DETAIL_MATIERE_SUCCESS = 'MATIERE/DETAIL_MATIERE_SUCCESS'
export const DETAIL_MATIERE_ERROR = 'MATIERE/DETAIL_MATIERE_ERROR'
export const getDetailsMatiere = (id) => dispatch => {
	dispatch({ type: DETAIL_MATIERE_REQUEST })
	return api.getDetailsMatiere(id)
		.then((result) => dispatch({
			type: DETAIL_MATIERE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: DETAIL_MATIERE_ERROR,
				e
			})
		})
}

export const SUPPRESSION_MATIERE_REQUEST = 'MATIERE/SUPPRESSION_MATIERE_REQUEST'
export const SUPPRESSION_MATIERE_SUCCESS = 'MATIERE/SUPPRESSION_MATIERE_SUCCESS'
export const SUPPRESSION_MATIERE_ERROR = 'MATIERE/SUPPRESSION_MATIERE_ERROR'
export const suppressionMatiere = (id) => dispatch => {
	dispatch({ type: SUPPRESSION_MATIERE_REQUEST })
	return api.suppressionMatiere(id)
		.then((result) => dispatch({
			type: SUPPRESSION_MATIERE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: SUPPRESSION_MATIERE_ERROR,
				e
			})
			throw e
		})
}

// TOPICS
export const CREATE_TOPIC_REQUEST = 'MATIERE/CREATE_TOPIC_REQUEST'
export const CREATE_TOPIC_SUCCESS = 'MATIERE/CREATE_TOPIC_SUCCESS'
export const CREATE_TOPIC_ERROR = 'MATIERE/CREATE_TOPIC_ERROR'
export const createTopic = (values, idMatiere) => dispatch => {
	dispatch({ type: CREATE_TOPIC_REQUEST })
	return api.createTopic(values, idMatiere)
		.then(() => dispatch({
			type: CREATE_TOPIC_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: CREATE_TOPIC_ERROR,
				e
			})
			throw e
		})
}

export const SUPPRESSION_TOPIC_REQUEST = 'MATIERE/SUPPRESSION_TOPIC_REQUEST'
export const SUPPRESSION_TOPIC_SUCCESS = 'MATIERE/SUPPRESSION_TOPIC_SUCCESS'
export const SUPPRESSION_TOPIC_ERROR = 'MATIERE/SUPPRESSION_TOPIC_ERROR'
export const suppressionTopic = (id, idMatiere) => dispatch => {
	dispatch({ type: SUPPRESSION_TOPIC_REQUEST })
	return api.suppressionTopic(id, idMatiere)
		.then((result) => dispatch({
			type: SUPPRESSION_TOPIC_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: SUPPRESSION_TOPIC_ERROR,
				e
			})
			throw e
		})
}

export const MODIFICATION_TOPIC_REQUEST = 'MATIERE/MODIFICATION_TOPIC_REQUEST'
export const MODIFICATION_TOPIC_SUCCESS = 'MATIERE/MODIFICATION_TOPIC_SUCCESS'
export const MODIFICATION_TOPIC_ERROR = 'MATIERE/MODIFICATION_TOPIC_ERROR'
export const modificationTopic = (values, idMatiere) => dispatch => {
	dispatch({ type: MODIFICATION_TOPIC_REQUEST })
	return api.modificationTopic(values, idMatiere)
		.then((result) => dispatch({
			type: MODIFICATION_TOPIC_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: MODIFICATION_TOPIC_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_TOPIC_REQUEST = 'MATIERE/DETAIL_TOPIC_REQUEST'
export const DETAIL_TOPIC_SUCCESS = 'MATIERE/DETAIL_TOPIC_SUCCESS'
export const DETAIL_TOPIC_ERROR = 'MATIERE/DETAIL_TOPIC_ERROR'
export const getDetailsTopic = (id, idMatiere) => dispatch => {
	dispatch({ type: DETAIL_TOPIC_REQUEST })
	return api.getDetailsTopic(id, idMatiere)
		.then((result) => dispatch({
			type: DETAIL_TOPIC_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: DETAIL_TOPIC_ERROR,
				e
			})
		})
}

//PARAGRAPHE

export const CREATE_PARAGRAPHE_REQUEST = 'MATIERE/CREATE_PARAGRAPHE_REQUEST'
export const CREATE_PARAGRAPHE_SUCCESS = 'MATIERE/CREATE_PARAGRAPHE_SUCCESS'
export const CREATE_PARAGRAPHE_ERROR = 'MATIERE/CREATE_PARAGRAPHE_ERROR'
export const createParagraphe = (values, idMatiere, idTopic) => dispatch => {
	dispatch({ type: CREATE_PARAGRAPHE_REQUEST })
	return api.createParagraphe(values, idMatiere, idTopic)
		.then(() => dispatch({
			type: CREATE_PARAGRAPHE_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: CREATE_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

export const SUPPRESSION_PARAGRAPHE_REQUEST = 'MATIERE/SUPPRESSION_PARAGRAPHE_REQUEST'
export const SUPPRESSION_PARAGRAPHE_SUCCESS = 'MATIERE/SUPPRESSION_PARAGRAPHE_SUCCESS'
export const SUPPRESSION_PARAGRAPHE_ERROR = 'MATIERE/SUPPRESSION_PARAGRAPHE_ERROR'
export const suppressionParagraphe = (idMatiere, idTopic, idParagraphe) => dispatch => {
	dispatch({ type: SUPPRESSION_PARAGRAPHE_REQUEST })
	return api.suppressionParagraphe(idMatiere, idTopic, idParagraphe)
		.then((result) => dispatch({
			type: SUPPRESSION_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: SUPPRESSION_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

export const MODIFICATION_PARAGRAPHE_REQUEST = 'MATIERE/MODIFICATION_PARAGRAPHE_REQUEST'
export const MODIFICATION_PARAGRAPHE_SUCCESS = 'MATIERE/MODIFICATION_PARAGRAPHE_SUCCESS'
export const MODIFICATION_PARAGRAPHE_ERROR = 'MATIERE/MODIFICATION_PARAGRAPHE_ERROR'
export const modificationParagraphe = (values, idMatiere, idTopic) => dispatch => {
	dispatch({ type: MODIFICATION_PARAGRAPHE_REQUEST })
	return api.modificationParagraphe(values, idMatiere, idTopic)
		.then((result) => dispatch({
			type: MODIFICATION_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: MODIFICATION_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_PARAGRAPHE_REQUEST = 'MATIERE/DETAIL_PARAGRAPHE_REQUEST'
export const DETAIL_PARAGRAPHE_SUCCESS = 'MATIERE/DETAIL_PARAGRAPHE_SUCCESS'
export const DETAIL_PARAGRAPHE_ERROR = 'MATIERE/DETAIL_PARAGRAPHE_ERROR'
export const getDetailsParagraphe = (idMatiere, idTopic, idParagraphe) => dispatch => {
	dispatch({ type: DETAIL_PARAGRAPHE_REQUEST })
	return api.getDetailsParagraphe(idMatiere, idTopic, idParagraphe)
		.then((result) => dispatch({
			type: DETAIL_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: DETAIL_PARAGRAPHE_ERROR,
				e
			})
		})
}

// SOUS PARAGRAPHE

export const CREATE_SOUS_PARAGRAPHE_REQUEST = 'MATIERE/CREATE_SOUS_PARAGRAPHE_REQUEST'
export const CREATE_SOUS_PARAGRAPHE_SUCCESS = 'MATIERE/CREATE_SOUS_PARAGRAPHE_SUCCESS'
export const CREATE_SOUS_PARAGRAPHE_ERROR = 'MATIERE/CREATE_SOUS_PARAGRAPHE_ERROR'
export const createSousParagraphe = (values, idMatiere, idTopic, idParagraphe) => dispatch => {
	dispatch({ type: CREATE_SOUS_PARAGRAPHE_REQUEST })
	return api.createSousParagraphe(values, idMatiere, idTopic, idParagraphe)
		.then(() => dispatch({
			type: CREATE_SOUS_PARAGRAPHE_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: CREATE_SOUS_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_SOUS_PARAGRAPHE_REQUEST = 'MATIERE/DETAIL_SOUS_PARAGRAPHE_REQUEST'
export const DETAIL_SOUS_PARAGRAPHE_SUCCESS = 'MATIERE/DETAIL_SOUS_PARAGRAPHE_SUCCESS'
export const DETAIL_SOUS_PARAGRAPHE_ERROR = 'MATIERE/DETAIL_SOUS_PARAGRAPHE_ERROR'
export const getDetailsSousParagraphe = (idMatiere, idTopic, idParagraphe, idSousParagraphe) => dispatch => {
	dispatch({ type: DETAIL_SOUS_PARAGRAPHE_REQUEST })
	return api.getDetailsSousParagraphe(idMatiere, idTopic, idParagraphe, idSousParagraphe)
		.then((result) => dispatch({
			type: DETAIL_SOUS_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: DETAIL_SOUS_PARAGRAPHE_ERROR,
				e
			})
		})
}

export const SUPPRESSION_SOUS_PARAGRAPHE_REQUEST = 'MATIERE/SUPPRESSION_SOUS_PARAGRAPHE_REQUEST'
export const SUPPRESSION_SOUS_PARAGRAPHE_SUCCESS = 'MATIERE/SUPPRESSION_SOUS_PARAGRAPHE_SUCCESS'
export const SUPPRESSION_SOUS_PARAGRAPHE_ERROR = 'MATIERE/SUPPRESSION_SOUS_PARAGRAPHE_ERROR'
export const suppressionSousParagraphe = (idMatiere, idTopic, idParagraphe, idSousParagraphe) => dispatch => {
	dispatch({ type: SUPPRESSION_SOUS_PARAGRAPHE_REQUEST })
	return api.suppressionSousParagraphe(idMatiere, idTopic, idParagraphe, idSousParagraphe)
		.then((result) => dispatch({
			type: SUPPRESSION_SOUS_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: SUPPRESSION_SOUS_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

export const MODIFICATION_SOUS_PARAGRAPHE_REQUEST = 'MATIERE/MODIFICATION_SOUS_PARAGRAPHE_REQUEST'
export const MODIFICATION_SOUS_PARAGRAPHE_SUCCESS = 'MATIERE/MODIFICATION_SOUS_PARAGRAPHE_SUCCESS'
export const MODIFICATION_SOUS_PARAGRAPHE_ERROR = 'MATIERE/MODIFICATION_SOUS_PARAGRAPHE_ERROR'
export const modificationSousParagraphe = (values, idMatiere, idTopic, idParagraphe) => dispatch => {
	dispatch({ type: MODIFICATION_SOUS_PARAGRAPHE_REQUEST })
	return api.modificationSousParagraphe(values, idMatiere, idTopic, idParagraphe)
		.then((result) => dispatch({
			type: MODIFICATION_SOUS_PARAGRAPHE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: MODIFICATION_SOUS_PARAGRAPHE_ERROR,
				e
			})
			throw e
		})
}

//LIGNE

export const CREATE_LIGNE_REQUEST = 'MATIERE/CREATE_LIGNE_REQUEST'
export const CREATE_LIGNE_SUCCESS = 'MATIERE/CREATE_LIGNE_SUCCESS'
export const CREATE_LIGNE_ERROR = 'MATIERE/CREATE_LIGNE_ERROR'
export const createLigne = (values, idMatiere, idTopic, idParagraphe, idSousParagraphe) => dispatch => {
	dispatch({ type: CREATE_LIGNE_REQUEST })
	return api.createLigne(values, idMatiere, idTopic, idParagraphe, idSousParagraphe)
		.then(() => dispatch({
			type: CREATE_LIGNE_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: CREATE_LIGNE_ERROR,
				e
			})
			throw e
		})
}

export const DETAIL_LIGNE_REQUEST = 'MATIERE/DETAIL_LIGNE_REQUEST'
export const DETAIL_LIGNE_SUCCESS = 'MATIERE/DETAIL_LIGNE_SUCCESS'
export const DETAIL_LIGNE_ERROR = 'MATIERE/DETAIL_LIGNE_ERROR'
export const getDetailsLigne = (idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne) => dispatch => {
	dispatch({ type: DETAIL_LIGNE_REQUEST })
	return api.getDetailsLigne(idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne)
		.then((result) => dispatch({
			type: DETAIL_LIGNE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: DETAIL_LIGNE_ERROR,
				e
			})
		})
}

export const SUPPRESSION_LIGNE_REQUEST = 'MATIERE/SUPPRESSION_LIGNE_REQUEST'
export const SUPPRESSION_LIGNE_SUCCESS = 'MATIERE/SUPPRESSION_LIGNE_SUCCESS'
export const SUPPRESSION_LIGNE_ERROR = 'MATIERE/SUPPRESSION_LIGNE_ERROR'
export const suppressionLigne = (idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne) => dispatch => {
	dispatch({ type: SUPPRESSION_LIGNE_REQUEST })
	return api.suppressionLigne(idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne)
		.then((result) => dispatch({
			type: SUPPRESSION_LIGNE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: SUPPRESSION_LIGNE_ERROR,
				e
			})
			throw e
		})
}

export const MODIFICATION_LIGNE_REQUEST = 'MATIERE/MODIFICATION_LIGNE_REQUEST'
export const MODIFICATION_LIGNE_SUCCESS = 'MATIERE/MODIFICATION_LIGNE_SUCCESS'
export const MODIFICATION_LIGNE_ERROR = 'MATIERE/MODIFICATION_LIGNE_ERROR'
export const modificationLigne = (values, idMatiere, idTopic, idParagraphe, idSousParagraphe) => dispatch => {
	dispatch({ type: MODIFICATION_LIGNE_REQUEST })
	return api.modificationLigne(values, idMatiere, idTopic, idParagraphe, idSousParagraphe)
		.then((result) => dispatch({
			type: MODIFICATION_LIGNE_SUCCESS,
			payload: result
		}))
		.catch(e => {
			dispatch({
				type: MODIFICATION_LIGNE_ERROR,
				e
			})
			throw e
		})
}
