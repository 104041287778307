import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { Popup } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'

const styles = (theme) => getStyles({
	popupForm: {
		marginTop: 20
	}
})


const ConfirmSupressionPratiquePopup = ({ open, closePopup, classes, handleSubmit }) => {
	const actions = [
		<Button
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="global.btn.annuler" />
		</Button>,
		<Button
			color="primary"
			variant="contained"
			onClick={handleSubmit}
		>
			<FormattedMessage id="global.btn.valider" />
		</Button>
	]

	return (
		<Popup
			open={open}
			contentStyleName={classes.popupContent}
			title={<FormattedMessage id='references.organismes.onglets.detail.popupTitle' />}
			content={<FormattedMessage id='references.organismes.onglets.detail.popupText' />}
			actions={actions}
		/>
	)
}

ConfirmSupressionPratiquePopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	onSubmit: PropTypes.func.isRequired
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: 'detailOrganismeForm',
	})
)(ConfirmSupressionPratiquePopup)
