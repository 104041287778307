import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Error, Button, Input, Popup, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import MuiButton from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { creerTypeFormation } from './services/typesFormationActions'
import { push } from 'connected-react-router'

const validate = values => {
	 const errors = {}
	 if (!values.libelle) {
	 	errors.libelle = { id: 'global.erreurs.obligatoire' }
	 }
	 return errors
}

const CreerTypeFormationPopup = ({ open, closePopup, creerTypeFormation, goToProprietes, handleSubmit, submitting, reset, error }) => {

	const handleClosePopup = () => {
		reset()
		closePopup()
	}

	const submitForm = values => {
		return creerTypeFormation(values)
			.then(id => {
				handleClosePopup()
				goToProprietes(id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}


	const actions = [
		<MuiButton key="close" color="primary" onClick={handleClosePopup}>
			<FormattedMessage id="global.btn.annuler" />
		</MuiButton>,
		<Button
			key="valider"
			variant="contained"
			onClick={handleSubmit(submitForm)}
			loading={submitting}
		>
			<FormattedMessage id="global.btn.creer" />
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title={<FormattedMessage id="references.typeFormation.popup.titre" />}
			content={
				<React.Fragment>
					<Error error={error} isPopinContainer />
					<form onSubmit={handleSubmit(submitForm)}>
						<Field
							name="libelle"
							label={<FormattedMessage id="references.typeFormation.form.libelle"/>}
							component={Input}
							inputProps={{
								maxLength: 50
							}}
						/>
					</form>
				</React.Fragment>
				}
		/>
	)
}


CreerTypeFormationPopup.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired
}

const actions = {
	creerTypeFormation,
	goToProprietes: id => dispatch => dispatch(push(`/types-formation/${id}`))
}

export default compose(
	connect(undefined, actions),
	reduxForm({
		form: 'creerTypeFormation',
		validate
	}),
	injectFormSnackbar()
)(CreerTypeFormationPopup)
