import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import * as selectors from './epreuveTypeSelectors'
import { clearEpreuveType, getEpreuveType } from './epreuveTypeActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

export const injectEpreuveType = (WrappedComponent) => {
	const EpreuveTypeInjector = (props) => {
		const { epreuveType, getEpreuveType, clearEpreuveType, ...otherProps } = props

		const match = props.match

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (match && match.params && match.params.idEpreuveType && match.params.idEpreuveType !== epreuveType.id) {
				setLoading(true)
				getEpreuveType(match.params.id, match.params.idEpreuveType)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return () => {
				clearEpreuveType()
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent epreuveType={epreuveType} {...otherProps} />
	}

	EpreuveTypeInjector.propTypes = {
		epreuveType: PropTypes.object,
		getEpreuveType: PropTypes.func,
		clearEpreuveType: PropTypes.func,
		match: PropTypes.object,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		epreuveType: selectors.getEpreuveType(state)
	})

	const actions = {
		getEpreuveType,
		clearEpreuveType,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectSnackActions
	)(EpreuveTypeInjector)
}