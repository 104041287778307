import * as api from './paiementApi'

export const GET_LISTE_PAIEMENTS_REQUEST = 'GET_LISTE_PAIEMENTS_REQUEST'
export const GET_LISTE_PAIEMENTS_SUCCESS = 'GET_LISTE_PAIEMENTS_SUCCESS'
export const GET_LISTE_PAIEMENTS_ERROR = 'GET_LISTE_PAIEMENTS_ERROR'
export const getListePaiements = (idMatiere, origine) => dispatch => {
	dispatch({ type: GET_LISTE_PAIEMENTS_REQUEST, idMatiere, origine })

	return api.getListePaiements(idMatiere, origine)
		.then(paiements => dispatch({ type: GET_LISTE_PAIEMENTS_SUCCESS, paiements }))
		.catch(() => dispatch({ type: GET_LISTE_PAIEMENTS_ERROR }))
}

export const CLEAN_LISTE_PAIEMENTS = 'CLEAN_LISTE_PAIEMENTS'
export const cleanListePaiements = () => dispatch => dispatch({ type: CLEAN_LISTE_PAIEMENTS })

export const SET_PAIEMENTS_SELECTED = 'SET_PAIEMENTS_SELECTED'
export const setPaiementSelected = (paiements) => dispatch => dispatch({ type: SET_PAIEMENTS_SELECTED, paiements })

const UPDATE_ETAT_PAIEMENT_REQUEST = 'UPDATE_ETAT_PAIEMENT_REQUEST'
const UPDATE_ETAT_PAIEMENT_SUCCESS = 'UPDATE_ETAT_PAIEMENT_SUCCESS'
const UPDATE_ETAT_PAIEMENT_ERROR = 'UPDATE_ETAT_PAIEMENT_ERROR'
export const updateEtatPaiement = (values) => dispatch => {
	dispatch({ type: UPDATE_ETAT_PAIEMENT_REQUEST, values })

	return api.updateEtatPaiement(values)
		.then(() => dispatch({ type: UPDATE_ETAT_PAIEMENT_SUCCESS }))
		.catch(() => dispatch({ type: UPDATE_ETAT_PAIEMENT_ERROR }))
}
