import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import HelpIcon from '@material-ui/icons/Help'
import { checkAuthorities, ImportCandidatsForm, LinkToStaticFile, PROFIL_UTILISATEUR, BandeauInfo, injectFormSnackbar } from '@oceane/ui'
import { injectToolbarData, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'

const styles = theme => ({
	icon: {
		color: theme.palette.primary.main
	},
	warning: {
		color: theme.palette.warning,
		marginBottom: 20
	}
})


const ImportCandidatsPage = ({ classes }) => (
	<React.Fragment>
		<BandeauInfo message={<FormattedMessage id="importCandidats.messageAlerte" />}/>
		<Title value={<React.Fragment>
			<FormattedMessage id="importCandidats.title" />
			<LinkToStaticFile url="/html/INS_consignes_import.html">
				<IconButton className={classes.icon} disableRipple>
					<HelpIcon />
				</IconButton>
			</LinkToStaticFile>
		</React.Fragment>}
		/>
		<ImportCandidatsForm />
	</React.Fragment>
)


ImportCandidatsPage.propTypes = {
	classes: PropTypes.object,
	profil: PropTypes.number
}

export default compose(
	injectToolbarData({ title: { id: 'toolbarTitle.import-candidats' } }),
	injectIntl,
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE),
	withStyles(styles),
	injectFormSnackbar()
)(ImportCandidatsPage)
