import React from 'react'
import { formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Input, SelectWithPlaceholder, injectFormSnackbar } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { modifierEpreuvesExamenCandidat } from '../services/formationEquivalencesActions'

const styles = theme => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '8px 20px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minHeight: 39
	},
	input: {
		marginBottom: 0
	},
	select: {
		height: 30
	}
})

const FormationSuivieActions = ({
	                                change,
	                                classes,
	                                setError,
	                                organisme,
	                                idCandidat,
	                                submitting,
	                                referenceATO,
	                                handleSubmit,
	                                idExamenCandidat,
	                                organismesActives,
	                                epreuvesSelectionnees,
	                                modifierEpreuvesExamenCandidatAction
                                }) => {
	const submit = (values) => {
		const newValues = {
			...values,
			epreuvesSelectionnees: epreuvesSelectionnees.map(epreuve => epreuve.idEpreuveType)
		}
		return modifierEpreuvesExamenCandidatAction(idExamenCandidat, idCandidat, newValues)
			.then(() => {
				setError(null)
			})
			.catch(e => {
				if (!!e.bodyError) {
					setError(e.bodyError.globalErrors[0].code)
				}
				throw e
			})
	}

	return <Paper elevation={0} square className={classes.filtres}>
		{epreuvesSelectionnees.length === 0 && <Typography>Sélectionnez les formations à mettre à jour</Typography>}
		{epreuvesSelectionnees.length !== 0 && <React.Fragment>
			<Grid container>
				<Grid item xs={5}>
					<SelectWithPlaceholder
						name="organisme"
						value={organisme}
						onChange={(event) => change('organisme', event.target.value)}
						placeholder="Nouvel organisme"
						emptyValue={0}
						id="profil"
						selectClassName={classes.select}
					>
						{organismesActives.map(organisme => <MenuItem key={organisme.id} value={organisme.id}>
								{organisme.libelleLong}
							</MenuItem>
						)}
					</SelectWithPlaceholder>
				</Grid>
				<Grid item xs={4}>
					<Input
						name="referenceATO"
						placeholder="Nouvelle référence ATO"
						meta={{}}
						inputProps={{
							maxLength: 50
						}}
						value={referenceATO}
						onChange={(event) => change('referenceATO', event.target.value)}
						inputStyle={classes.input}
					/>
				</Grid>
			</Grid>
			<Button
				loading={submitting}
				color="primary"
				onClick={handleSubmit(submit)}
			>
				Enregistrer
			</Button>
		</React.Fragment>}
	</Paper>
}

const formSelector = formValueSelector('majEpreuvesForm')

const mapStateToProps = (state) => ({
	referenceATO: formSelector(state, 'referenceATO') || '',
	organisme: formSelector(state, 'organisme') || 0
})

const actions = {
	modifierEpreuvesExamenCandidatAction: modifierEpreuvesExamenCandidat
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'majEpreuvesForm'
	}),
	withStyles(styles),
	injectFormSnackbar()
)(FormationSuivieActions)
