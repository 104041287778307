import { createSelector } from 'reselect'

const getLocalState = state => state.paiements

export const getListePaiements = createSelector(
	getLocalState,
	state => state.liste
)

export const getSelectedPaiements = createSelector(
	getLocalState,
	state => state.selected
)
