import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { clearTypeFormation, getTypeFormation } from './typesFormationActions'
import * as selectors from './typesFormationSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

export const injectTypeFormation = (WrappedComponent) => {
	const TypeFormationInjector = (props) => {
		const { match, typeFormation, getTypeFormation, clearTypeFormation, ...otherProps } = props

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (match && match.params && match.params.id !== typeFormation.id) {
				setLoading(true)
				getTypeFormation(match.params.id)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return () => {
				clearTypeFormation()
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent typeFormation={typeFormation} { ...otherProps } />
	}

	TypeFormationInjector.propTypes = {
		typeFormation: PropTypes.object,
		getTypeFormation: PropTypes.func,
		clearTypeFormation: PropTypes.func,
		goBack: PropTypes.func,
		match: PropTypes.object,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		typeFormation: selectors.getTypeFormation(state)
	})

	const actions = {
		getTypeFormation,
		clearTypeFormation,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectSnackActions
	)(TypeFormationInjector)
}


