import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { handleSession, getProfilById } from '@oceane/ui'

const styles = () => ({
	libelle: {
		textTransform: 'none',
		color: '#FFFFFF'
	},
	menu: {
		marginRight: 20
	},
	icon: {
		fill: '#FFFFFF'
	}
})

class SelecteurProfils extends Component {

	constructor(props) {
		super(props)
		this.state = {
			anchorElement: null
		}
	}

	/**
	 * Clic sur le bouton/sélecteur
	 * @param event
	 */
	handleClick = event => {
		this.setState({ anchorElement: event.currentTarget })
	}
	/**
	 * Fermeture du menu
	 * @param event
	 */
	handleClose = event => {
		if (!!event.target.value) {
			this.props.switchProfil(event.target.value)
		}
		this.setState({ anchorElement: null })
	}

	render() {
		const { classes, intl, profil, user } = this.props
		const { anchorElement } = this.state

		// Création des items du sélecteur, correspondant aux profils/rôles pouvant être endossés par l'utilisateur
		let selectValueList = []
		if (!!user.profils && !!intl && !!user.authorities) {
			// On filtre les profils via les profils LDAP autorisés
			const authorities = user.authorities.map(auth => auth.authority)
				const profilAutorises = user.profils.filter(profil => {
					const profilUser = getProfilById(profil.profil)
					if (profilUser === null) {
						return false
					}

					return authorities.includes(profilUser.nom)
				})

			// On trie la liste des profils alphabétiquement
			profilAutorises.sort((a, b) => {
				const profil1 = intl.formatMessage({ id: 'profilById.' + a.profil }).toLowerCase()
				const profil2 = intl.formatMessage({ id: 'profilById.' + b.profil }).toLowerCase()
				if (profil1 < profil2) return -1
				if (profil1 > profil2) return 1
				return 0
			})

			// On retire Organisme de formation de la liste car on ne veut pas qu'il s'affiche
			const newProfils = profilAutorises.filter(p => p.profil !== 8)
			selectValueList = newProfils.map((item, index) => (
				<MenuItem key={index}
				          value={item.profil}
				          onClick={this.handleClose.bind(this)}
				>
					{intl.formatMessage({ id: 'profilById.' + item.profil })}
				</MenuItem>
			))
		}

		const isNotUniqueProfil = selectValueList && selectValueList.length > 1

		return (
			<React.Fragment>
				<Tooltip
					disableFocusListener
					title={intl.formatMessage({ id: 'tooltip.changerProfil' })}>
					<Button
						className={classes.libelle}
						aria-owns={anchorElement ? 'selecteur' : undefined}
						aria-haspopup='true'
						disabled={!isNotUniqueProfil}
						onClick={this.handleClick.bind(this)}
					>
						<Typography variant="h6" className={classes.libelle}>
							{intl.formatMessage({ id: 'profilById.' + profil })}
						</Typography>
						{isNotUniqueProfil && <ArrowIcon className={classes.icon} />}
					</Button>
				</Tooltip>
				<Menu
					id='selecteur'
					anchorEl={anchorElement}
					open={Boolean(anchorElement)}
					onClose={this.handleClose.bind(this)}
					className={classes.menu}>
					{selectValueList}
				</Menu>
			</React.Fragment>
		)
	}
}

SelecteurProfils.propTypes = {
	intl: intlShape.isRequired,
	user: PropTypes.object,
	profil: PropTypes.number,
	switchProfil: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	intl: state.intl,
	user: state.user
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles, { withTheme: true }),
	injectIntl,
	handleSession
)(SelecteurProfils)
