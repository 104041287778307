import * as api from './importBCEApi'

export const GET_VERSION_CD_ACTUELLE_REQUEST = 'BANQUE_QUESTIONS/GET_VERSION_CD_ACTUELLE_REQUEST'
export const GET_VERSION_CD_ACTUELLE_SUCCESS = 'BANQUE_QUESTIONS/GET_VERSION_CD_ACTUELLE_SUCCESS'
export const GET_VERSION_CD_ACTUELLE_ERROR = 'BANQUE_QUESTIONS/GET_VERSION_CD_ACTUELLE_ERROR'
export const getVersionCDActuelle = () => dispatch => {
	dispatch({ type: GET_VERSION_CD_ACTUELLE_REQUEST })
	return api.getVersionCDActuelle()
		.then(result => dispatch({
			type: GET_VERSION_CD_ACTUELLE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_VERSION_CD_ACTUELLE_ERROR, e })
			throw e
		})
}

export const CHARGER_FICHIER_IMPORT_REQUEST = 'BANQUE_QUESTIONS/CHARGER_FICHIER_IMPORT_REQUEST'
export const CHARGER_FICHIER_IMPORT_SUCCESS = 'BANQUE_QUESTIONS/CHARGER_FICHIER_IMPORT_SUCCESS'
export const CHARGER_FICHIER_IMPORT_ERROR = 'BANQUE_QUESTIONS/CHARGER_FICHIER_IMPORT_ERROR'
export const chargerFichierImport = values => dispatch => {
	dispatch({ type: CHARGER_FICHIER_IMPORT_REQUEST })
	return api.chargerFichierImport(values)
		.then(result => dispatch({
			type: CHARGER_FICHIER_IMPORT_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: CHARGER_FICHIER_IMPORT_ERROR, e })
			throw e
		})
}

export const IMPORTER_CD_REQUEST = 'BANQUE_QUESTIONS/IMPORTER_CD_REQUEST'
export const IMPORTER_CD_SUCCESS = 'BANQUE_QUESTIONS/IMPORTER_CD_SUCCESS'
export const IMPORTER_CD_ERROR = 'BANQUE_QUESTIONS/IMPORTER_CD_ERROR'
export const importerCD = values => dispatch => {
	dispatch({ type: IMPORTER_CD_REQUEST })
	return api.importerCD(values)
		.then(result => dispatch({
			type: IMPORTER_CD_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: IMPORTER_CD_ERROR, e })
			throw e
		})
}

export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST = 'BANQUE_QUESTIONS/VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST'
export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS = 'BANQUE_QUESTIONS/VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS'
export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR = 'BANQUE_QUESTIONS/VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR'
export const verifierDisponibiliteCompteRendu = () => dispatch => {
	dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST })
	return api.verifierDisponibiliteCompteRendu()
		.then(() => dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS }))
		.catch(e => {
			dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR, e })
			throw e
		})
}

