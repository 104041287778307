import { combineReducers } from 'redux'
import * as sessionsPapierActions from './sessionsPapierActions'

const sessionPapierDonneesGenerales = (state = {}, action) => {
	switch (action.type) {
		case sessionsPapierActions.GET_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS:
		case sessionsPapierActions.PUT_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS:
			return action.donneesGenerales
		case sessionsPapierActions.CLEAN_SESSION_PAPIER:
			return {}
		default:
			return state
	}
}

export const sessionPapier = combineReducers({
	donneesGenerales: sessionPapierDonneesGenerales

})
