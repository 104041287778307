import MenuItem from '@material-ui/core/MenuItem'
import { Popup, Select, Button } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import {
	closePopupCreation, createFormationOrganisme,
	getExamensTypePratique,
	getFormationTypePratique
} from './services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'
import * as selectors from './services/examenPratiqueOrganisme/examenPratiqueSelector'
import * as selectorsDetail from './services/popup/nouvelOrganismePopupSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const validate =  (values, { intl }) => {
	const errors = {}

	if (!values.examen) {
		errors.examen = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
	}
	if (!values.typeFormation) {
		errors.typeFormation = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
	}

	return errors
}

class NouvelleFormationOrganismePopup extends Component {
	componentDidMount() {
		const { getExamensTypePratique } = this.props

		getExamensTypePratique()
	}

	onCritereSelected = (e) => {
		const { getFormationTypePratique, organismeDetail, change, untouch } = this.props
		getFormationTypePratique(e.target.value, organismeDetail.id)
		untouch('typeFormation')
		change('typeFormation', null)
	}

	submit = (values) => {
		const { createFormationOrganisme, closePopupCreation, refreshTable, snackError, organismeDetail } = this.props

		return createFormationOrganisme(values, organismeDetail.id)
			.then(() => {
				refreshTable()
				closePopupCreation()
			})
			.catch(e => {
				e.bodyError ? snackError(e.bodyError) : snackError()
			})
	}

	popupContent = () => {
		const { lstExamensTypePratiques, lstFormationTypePratiques } = this.props

		return (
			<React.Fragment>
				<form
					id="nouvelleFormationOrganismeForm"
				>
					<Field
						name="examen"
						label="Examen *"
						onChange={this.onCritereSelected}
						component={Select}
					>
						{lstExamensTypePratiques.map(exam =>
							<MenuItem key={exam.id} value={exam.id}>
								{exam.libelle}
							</MenuItem>
						)}
					</Field>
					<Field
						name="typeFormation"
						label="Type formation *"
						component={Select}
					>
						{lstFormationTypePratiques.map(form =>
							<MenuItem key={form.id} value={form.id}>
								{form.libelle}
							</MenuItem>
						)}
					</Field>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			closePopupCreation,
			handleSubmit,
			submitting,
			reset
		} = this.props

		const actions = [
			<Button
				key="annuler"
				color="primary"
				onClick={closePopupCreation}
			>
				Annuler
			</Button>,
			<Button
				key="ajouter"
				color="primary"
				variant="contained"
				loading={submitting}
				onClick={handleSubmit(this.submit)}
			>
				Ajouter
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Ajouter une formation"
				content={this.popupContent()}
				onExited={reset}
			/>
		)
	}
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpenCreation(state),
	lstExamensTypePratiques: selectors.getExamensTypePratique(state),
	lstFormationTypePratiques: selectors.getFormationTypePratique(state),
	organismeDetail: selectorsDetail.getOrganismeDetails(state)
})

const actions = {
	closePopupCreation,
	getExamensTypePratique,
	getFormationTypePratique,
	refreshTable: () => dataTableActions.refresh('organismesListeFormations'),
	createFormationOrganisme
}

NouvelleFormationOrganismePopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	getExamensTypePratique: PropTypes.func,
	lstExamensTypePratiques: PropTypes.array,
	lstFormationTypePratiques: PropTypes.array,
	getFormationTypePratique: PropTypes.func,
	organismeDetail: PropTypes.object,
	refreshTable: PropTypes.func,
	createFormationOrganisme: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: 'nouvelleFormationOrganismeForm',
		enableReinitialize: true,
		validate
	}),
	injectSnackActions
)(NouvelleFormationOrganismePopup)
