import { fetchFactory } from 'isotope-client'

export const deletePieceJustificative = (id) => fetchFactory(`/piecesjustificatives/${id}`, {
	method: 'delete',
})

export const createPieceJustificative = (values) => fetchFactory('/piecesjustificatives', {
	method: 'post',
	body: JSON.stringify({ libelle: values.libelle, id: -1 })
})

export const getDetailPieceJustificative = (id) => fetchFactory(`/piecesjustificatives/${id}`)

export const modificationPieceJustificative = (values) => fetchFactory(`/piecesjustificatives/${values.id}`, {
	method: 'post',
	body: JSON.stringify(values)
})