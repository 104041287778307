import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getListeOrganismes } from './organismeActions'
import * as selectors from './organismeSelectors'


export const injectListeOrganismes = (WrappedComponent) => {
	const ListOrganismesInjector = ({ listeOrganismes, getListeOrganismes, ...props }) => {

		React.useEffect(() => {
			if (!listeOrganismes || !listeOrganismes.length) {
				getListeOrganismes()
			}
		}, [])

		return <WrappedComponent listeOrganismes={listeOrganismes} {...props} />
	}

	const mapStateToProps = state => ({
		listeOrganismes: selectors.getListeOrganismes(state)
	})

	const actions = {
		getListeOrganismes
	}

	ListOrganismesInjector.propTypes = {
		getListeOrganismes: PropTypes.func,
		listeOrganismes: PropTypes.array
	}

	return connect(mapStateToProps, actions)(ListOrganismesInjector)
}
