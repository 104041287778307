import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import IconButton from '@material-ui/core/IconButton'
import Search from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import { getStyles, SpringDataTable, Title } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { Button, Actions, checkAuthorities, EmptyResult, Link, PROFIL_UTILISATEUR, BandeauInfo, Popup } from '@oceane/ui'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import UtilisateursFiltres from './components/UtilisateursFiltres'
import * as administrationSelectors from './services/administrationSelectors'
import { PROFILS, UPDATE_PROFIL_IDS } from './services/administrationValues'
import * as administrationActions from './services/administrationActions'

const style = () => getStyles({
	icon: {
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent'
		},
		color: 'inherit'
	}
})

const RechercheUtilisateurs = ({ classes, nom, identifiant, profil, snackSuccess, snackError, rechercherDansLdap, synchroniserLdap }) => {
	const [responseLdap, setResponseLdap] = React.useState(null)
	const [showConfirmLdap, setShowConfirmLdap] = React.useState(false)
	const [loadingLdap, setLoadingLdap] = React.useState(false)

	// On recherche dès qu'un filtre a été saisi
	const handleResults = !!nom.length || !!identifiant.length || profil !== -1

	const recherche = (userId, profil) => {
		rechercherDansLdap(userId, profil)
			.then(({ message }) => message.error ? snackError(message) : snackSuccess(message))
			.catch(() => snackError())
	}

	const synchroLdap = () => {
		setLoadingLdap(true)
		synchroniserLdap()
			.then(({ response }) => {
				setResponseLdap(response)
				dismissConfirmLdap()
			})
			.catch(() => {
				setResponseLdap(null)
				dismissConfirmLdap()
				snackError("La synchronisation LDAP a échoué")
			})
	}

	const dismissConfirmLdap = () => {
		setLoadingLdap(false)
		setShowConfirmLdap(false)
	}

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={() => setShowConfirmLdap(false)}
		>
			<FormattedMessage id="global.btn.annuler" />
		</Button>,
		<Button
			loading={loadingLdap}
			key="valider"
			color="primary"
			variant="contained"
			onClick={synchroLdap}
		>
			Confirmer
		</Button>
	]

	return (
		<React.Fragment>
			{responseLdap && <BandeauInfo message={<span dangerouslySetInnerHTML={{ __html: responseLdap.id }} />}/>}
			<Title value="Recherche d'utilisateurs" />
			<UtilisateursFiltres />
			{handleResults && <SpringDataTable
				apiUrl="/utilisateurs"
				headers={[
					{
						key: 'identifiant',
						name: 'Identifiant',
						sortable: true,
						render: row => UPDATE_PROFIL_IDS.includes(row.profil) ?
							<Link to={`/admin/gestion-utilisateurs/${row.userId}`} state={{ identifiant: row.identifiant, profil: row.profil }}>{row.identifiant}</Link>
							: row.identifiant
					},
					{
						key: 'nom',
						name: 'Nom',
						sortable: true
					},
					{
						key: 'prenom',
						name: 'Prénom',
						sortable: true
					},
					{
						key: 'profil',
						name: 'Profil',
						sortable: true,
						render: row => PROFILS[row.profil - 1].nom
					},
					{
						key: 'mail',
						name: 'Mail',
						sortable: true
					},
					{
						key: 'ldap',
						name: '',
						render: row => <IconButton
							disableRipple
							className={classes.icon}
							onClick={() => recherche(row.userId, row.profil)}
						>
							<Tooltip title="Rechercher dans le LDAP">
								<Search />
							</Tooltip>
						</IconButton>

					}
				]}
				filters={{
					nom,
					identifiant,
					profil: profil !== -1 ? profil : undefined
				}}
				nom="utilisateurs"
				noResultFragment={<EmptyResult />}
			/>
			}
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={() => setShowConfirmLdap(true)}
				>
					Synchroniser avec le LDAP
				</Button>
			</Actions>
			<Popup
				open={showConfirmLdap}
				actions={actions}
				title="Synchronisation LDAP"
				content={<div>Confirmez-vous la synchronisation des utilisateurs avec l'annuaire LDAP ?</div>}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	nom: administrationSelectors.getNom(state),
	identifiant: administrationSelectors.getIdentifiant(state),
	profil: administrationSelectors.getProfil(state)
})

const actions = {
	rechercherDansLdap: administrationActions.rechercherDansLdap,
	goToUtilisateurPage: (id) => dispatch => dispatch(push(`/candidats/${id}`)),
	synchroniserLdap: administrationActions.synchroniserLdap
}

RechercheUtilisateurs.propTypes = {
	nom: PropTypes.string.isRequired,
	identifiant: PropTypes.string.isRequired,
	profil: PropTypes.number.isRequired,
	goToUtilisateurPage: PropTypes.func.isRequired,
	rechercherDansLdap: PropTypes.func.isRequired,
	synchroniserLdap: PropTypes.func.isRequired
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.ADMINISTRATEUR
	),
	connect(mapStateToProps, actions),
	withTheme(),
	withStyles(style),
	injectSnackbarActions
)(RechercheUtilisateurs)
