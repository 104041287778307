import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'

const CorrectionAucunFichierConfirmPopup = ({ open, closePopup, controleEtCorrection }) => {

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={() => {
				closePopup()
				controleEtCorrection()
			}}
		>
			Forcer la correction
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		title="Confirmation"
		content={<Typography>Aucun fichier chargé, voulez-vous forcer la correction de cette
			épreuve&nbsp;?</Typography>}
	/>
}

CorrectionAucunFichierConfirmPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	controleEtCorrection: PropTypes.func.isRequired
}

export default CorrectionAucunFichierConfirmPopup
