import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { clearExamenType, getExamenType, getListeExamensTypes } from './examensTypesActions'
import * as selectors from './examensTypesSelectors'

export const injectExamenType = (WrappedComponent) => {
	const ExamenTypeInjector = (props) => {
		const { examenType, getExamenType, clearExamenType, ...otherProps } = props

		const match = props.match

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (match && match.params && match.params.id !== examenType.id) {
				setLoading(true)
				getExamenType(match.params.id)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return () => {
				clearExamenType()
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent examenType={examenType} { ...otherProps } />
	}

	ExamenTypeInjector.propTypes = {
		typeFormation: PropTypes.object,
		getExamenType: PropTypes.func,
		clearExamenType: PropTypes.func,
		goBack: PropTypes.func,
		match: PropTypes.object,
		snackError: PropTypes.func
	}

	const mapStateToProps = state => ({
		examenType: selectors.getExamenType(state)
	})

	const actions = {
		getExamenType,
		clearExamenType,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectSnackActions
	)(ExamenTypeInjector)
}

export const injectListExamensType = (isAdmin = false) => (WrappedComponent) => {
	const ListExamensTypeInjector = ({ listeExamensType, getListeExamensTypes, ...props }) => {

		React.useEffect(() => {
			getListeExamensTypes(isAdmin)
		}, [isAdmin])

		return <WrappedComponent listeExamensType={listeExamensType} {...props} />
	}

	const mapStateToProps = state => ({
		listeExamensType: selectors.getListeExamensTypes(state)
	})

	const actions = {
		getListeExamensTypes
	}

	ListExamensTypeInjector.propTypes = {
		getListeExamensTypes: PropTypes.func,
		listeExamensType: PropTypes.array
	}

	return connect(mapStateToProps, actions)(ListExamensTypeInjector)
}
