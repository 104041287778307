import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getSurveillantCentres } from './surveillantsActions'

export const loadSurveillantCentres = (WrappedComponent) => {
    const SurveillantCentresInjector = (props) => {
        const { getSurveillantCentres, ...otherProps } = props

        const match = otherProps.match

        const [surveillant, setSurveillant] = React.useState({})
        const [loading, setLoading] = React.useState(false)

        React.useEffect(() => {
            if (match && match.params) {
                setLoading(true)
                getSurveillantCentres(match.params.id)
                    .then(({ surveillant }) => {
                        setLoading(false)
                        setSurveillant(surveillant)
                    })
                    .catch(() => {
                        setLoading(false)
                        otherProps.snackError()
                    })
            }
        }, [])

        return loading ? <Loader /> : <WrappedComponent surveillant={surveillant} { ...otherProps } />
    }

    SurveillantCentresInjector.propTypes = {
        getSurveillantCentres: PropTypes.func,
        match: PropTypes.object,
        snackError: PropTypes.func
    }

    const actions = {
        getSurveillantCentres
    }

    return compose(
        connect(null, actions),
        injectSnackActions
    )(SurveillantCentresInjector)
}