import { Button, Input, Popup } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { postLocalisationSite } from '../services/sitesPhysiquesAction'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const NouvelleLocalisationPopup = ({ open, closePopup, handleSubmit, postLocalisationSite, refreshTable, goToDetailLocalisationPage }) => {

	const submitCreateLocalisationSite = (values) =>
		postLocalisationSite(values)
			.then(({ site }) => {
				closePopup()
				refreshTable()
				goToDetailLocalisationPage(site.id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateLocalisationSite)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouvelle localisation site"
			content={<form>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
				/>
			</form>}
		/>
	)
}

const actions = {
	postLocalisationSite,
	refreshTable: () => dataTableActions.refresh('listeLocalisation'),
	goToDetailLocalisationPage: (id) => dispatch => dispatch(push(`/examens-ecran/localisation-sites/${id}`))
}

NouvelleLocalisationPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	postLocalisationSite: PropTypes.func,
	refreshTable: PropTypes.func,
	goToDetailLocalisationPage: PropTypes.func
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'creationLocalisation',
		initialValues: {
			libelle: ''
		},
		validate
	})
)(NouvelleLocalisationPopup)
