import React from 'react'
import { ORIGINE_PAIEMENT } from '../../../../config/enums'
import PropTypes from 'prop-types'
import { StaticDataTable } from 'isotope-client'
import { EmptyResult } from '@oceane/ui'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as paiementSelectors from '../services/paiementSelectors'
import { getListePaiements, cleanListePaiements, setPaiementSelected } from '../services/paiementActions'

const QuestionsNonPayeesDataTable = ({ listePaiements, selectedMatiere, origine, getListePaiements, dataTableRef, cleanListePaiements, setPaiementSelected }) => {
	React.useEffect(() => {
		if (!!selectedMatiere) {
			getListePaiements(selectedMatiere, origine)
		} else {
			cleanListePaiements()
		}
		if (!!dataTableRef) {
			dataTableRef.current.clearSelection()
		}
		return () => { cleanListePaiements() }
	}, [selectedMatiere])

	const conditionnalAuteur = []
	const conditionnalPayeA = []
	if (ORIGINE_PAIEMENT.FRANCAISE === origine) {
		conditionnalAuteur.push({
			key: 'auteur',
			name: 'Auteur',
			type: 'string',
			sortable: true
		})
	} else {
		conditionnalAuteur.push({
			key: 'traducteur',
			name: 'Traducteur',
			type: 'string',
			sortable: true
		})
		conditionnalPayeA.push({
			key: 'payeA',
			name: 'Payé à',
			type: 'string',
			sortable: true
		})
	}

	return <StaticDataTable
		ref={dataTableRef}
		data={listePaiements}
		headers={[
			{
				key: 'idOceane',
				name: 'Identifiant',
				sortable: true
			},
			...conditionnalAuteur,
			{
				key: 'visaRdb',
				name: 'Visa RDB',
				type: 'string',
				sortable: true
			},
			{
				key: 'etatDePaiement',
				name: 'Etat',
				type: 'string',
				sortable: true
			},
			...conditionnalPayeA

		]}
		displaySelectAll
		selectable
		onRowSelection={rows => setPaiementSelected(rows)}
		noResultFragment={<EmptyResult />}
	/>
}

const mapStateToProps = state =>({
	listePaiements: paiementSelectors.getListePaiements(state)
})

const actions = {
	getListePaiements,
	cleanListePaiements,
	setPaiementSelected
}

QuestionsNonPayeesDataTable.propTypes = {
	dataTableRef: PropTypes.object,
	origine: PropTypes.number,
	selectedMatiere: PropTypes.number,
	listePaiements: PropTypes.array,
	selectedRows: PropTypes.array,
	getListePaiements: PropTypes.func,
	cleanListePaiements: PropTypes.func,
	setPaiementSelected: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions)
)(QuestionsNonPayeesDataTable)
