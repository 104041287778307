import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	Error,
	Input,
	InputDisabled,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	injectFormSnackbar
} from '@oceane/ui'
import FileUploadForm from './FileUploadForm'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { injectVersionCDActuelle } from './services/ImportBCEInjectors'
import { importerCD, verifierDisponibiliteCompteRendu } from './services/importBCEActions'
import { download } from 'isotope-client/components/download/IsotopeLink'

const styles = () => getStyles({
	versionActuelle: {
		marginTop: 0
	}
})

const validate = (values, { versionCD }) => {
	const errors = {}
	if (!values.numeroVersion) {
		errors.numeroVersion = { id: 'global.erreurs.obligatoire' }
	} else if (values.numeroVersion <= 0) {
		errors.numeroVersion = 'Le champ doit être un entier positif'
	} else if (values.numeroVersion <= versionCD.numeroVersionCD) {
		errors.numeroVersion = 'Le numéro du CD doit être supérieur ou égal au précédent'
	}
	return errors
}

const ImportBCEPage = ({ classes, versionCD, setVersionCD, submitting, handleSubmit, importerCD, reset, verifierDisponibiliteCompteRendu, snackError }) => {

	const [messageImport, setMessageImport] = React.useState(null)
	const [error, setError] = React.useState(null)

	const handleDownloadCompteRendu = () => verifierDisponibiliteCompteRendu()
		.then(() => download('/files/importBCE/compteRendu'))
		.catch(() => snackError('Le compte rendu d\'import n\'existe pas'))

	const handleImport = values => importerCD({ numeroVersion: values.numeroVersion })
		.then(({ result }) => {
			reset()
			setVersionCD(result)
			setMessageImport(result.aucuneAnomalie ? 'L\'import est terminé. Il ne comporte aucune anomalie' : 'L\'import est terminé. Il comporte des anomalies listées dans le compte-rendu')
		})
		.catch(e => {
			setError(e)
			throw new SubmissionError(e)
		})


	React.useEffect(() => {
		if (submitting) {
			setError(null)
			setMessageImport(null)
		}
	}, [submitting])

	React.useEffect(() => () => {
		setError(null)
		setMessageImport(null)
	}, [])

	return (
		<ResponsiveForm>
			<Error error={error} />
			{messageImport !== null && <BandeauInfo message={messageImport} />}
			<Title value="Version du CD" />
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Field
						name="numeroVersion"
						label="N° CD version importée *"
						component={Input}
					/>
				</Grid>
				<Grid item xs={6}>
					<InputDisabled
						label="N° CD version actuelle"
						className={classes.versionActuelle}
					>
						{versionCD.numeroVersionCD}
					</InputDisabled>
				</Grid>
			</Grid>
			<Title value="Fichier d'import" />
			<FileUploadForm setVersionCD={setVersionCD} nomFichier={versionCD.nomFichierImport}/>
			<Actions>
				<Button onClick={handleDownloadCompteRendu}>
					Compte-rendu
				</Button>
				<Button onClick={handleSubmit(handleImport)} variant="contained">
					Import
				</Button>
			</Actions>
		</ResponsiveForm>
	)
}

ImportBCEPage.propTypes = {
	versionCD: PropTypes.shape({
		numeroVersionCD: PropTypes.number,
		nomFichierImport: PropTypes.string,
		aucuneAnomalie: PropTypes.bool,
		compteRenduDisponible: PropTypes.bool,
	}),
	setVersionCD: PropTypes.func,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	importerCD: PropTypes.func,
	reset: PropTypes.func,
	verifierDisponibiliteCompteRendu: PropTypes.func,
	classes: PropTypes.object
}

const actions = {
	importerCD,
	verifierDisponibiliteCompteRendu
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,),
	withStyles(styles),
	injectToolbarData({ title: 'Import BCE' }),
	injectVersionCDActuelle,
	connect(undefined, actions),
	reduxForm({
		form: 'importBCE',
		validate
	}),
	injectFormSnackbar()
)(ImportBCEPage)
