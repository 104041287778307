import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getResultatEpreuve, cleanResultatEpreuve } from './gestionCopiesActions'
import * as selectors from './gestionCopiesSelectors'
import { Loader } from '@oceane/ui'

export const injectResultatEpreuve = (WrappedComponent) => {
	const ResultatEpreuveInjector = ({ resultatEpreuve, getResultatEpreuve, cleanResultatEpreuve, idEpreuveInscription, ...props }) => {

		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			getResultatEpreuve(idEpreuveInscription)
				.then(() => setLoading(false))
				.catch(() => setLoading(false))
			return () => {
				cleanResultatEpreuve()
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent resultatEpreuve={resultatEpreuve} {...props} />
	}

	const mapStateToProps = (state, ownProps) => {
		const queryParams = ownProps && ownProps.match ? ownProps.match.params : {}
		return {
			idEpreuveInscription: queryParams.idEpreuveInscription,
			resultatEpreuve: selectors.getResultatEpreuve(state)
		}
	}

	const actions = {
		getResultatEpreuve,
		cleanResultatEpreuve
	}

	ResultatEpreuveInjector.propTypes = {
		getResultatEpreuve: PropTypes.func,
		cleanResultatEpreuve: PropTypes.func,
		resultatEpreuve: PropTypes.object,
		idEpreuveInscription: PropTypes.string
	}

	return connect(mapStateToProps, actions)(ResultatEpreuveInjector)
}
