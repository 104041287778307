import { Input, Popup, Button, injectFormSnackbar } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { modificationMatiere } from '../service/ligneProgrammeAction'
import * as selectors from '../service/ligneProgrammeSelectors'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class ModificationMatierePopup extends Component {

	submitModificationMatiere = (values) => {
		const { modificationMatiere, refreshTable, onClose } = this.props

		return modificationMatiere(values)
			.then(() => {
				refreshTable()
				onClose()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	closePopup = () => {
		const {
			onClose,
			reset
		} = this.props

		reset()
		onClose()
	}

	popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="modificationMatiereForm"
				>
					<Field
						id="code"
						name="code"
						label="Code *"
						component={Input}
						inputProps={{
							maxLength: 3
						}}
					/>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={Input}
					/>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			handleSubmit,
		} = this.props

		const actions = [
			<Button key="annuler" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="enregistrer"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitModificationMatiere)}
			>
				Enregistrer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Modification de la matière"
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

ModificationMatierePopup.propTypes = {
	initialValues: PropTypes.object,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	refreshTable: PropTypes.func,
	modificationMatiere: PropTypes.func
}

const mapStateToProps = state => ({
	initialValues: selectors.getDetailMatiere(state)
})

const actions = {
	modificationMatiere,
	refreshTable: () => dataTableActions.refresh('listeMatiere')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modificationMatiereForm',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar()
)(ModificationMatierePopup)
