import { fetchFactory } from 'isotope-client'

export const getListeTypesFormation = () => fetchFactory('/typesFormation')

export const creerTypeFormation = values => fetchFactory('/typesFormation', {
	method: 'post',
	body: JSON.stringify(values)
})

export const getTypeFormation = id => fetchFactory(`/typesFormation/${id}`)

export const saveTypeFormation = (id, values) => fetchFactory(`/typesFormation/${id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const activerTypeFormation = (id) => fetchFactory(`/typesFormation/${id}/activer`, {
	method: 'put'
})

export const desactiverTypeFormation = (id) => fetchFactory(`/typesFormation/${id}/desactiver`, {
	method: 'put'
})