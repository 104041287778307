import {
	BandeauInfo,
	checkAuthorities,
	injectSession,
	PROFIL_UTILISATEUR,
	injectFormSnackbar
} from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { Title, StaticDataTable, getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import * as selectors from '../../services/sessionsPapierSelectors'
import { ETAT_SESSION } from '../../../../../utils/consts'
import { getListeSitesSession } from '../../services/sessionsPapierActions'
import { push } from 'connected-react-router'
import Typography from '@material-ui/core/Typography'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const OngletPVSalle = ({ sessionPapier, getListeSitesSession, goToEpreuves, classes }) => {
	const [sites, setSites] = React.useState([])
	let etat = sessionPapier.etat

	React.useEffect(() => {
		etat = sessionPapier.etat
		getListeSitesSession(sessionPapier.id)
			.then(({ result }) => {
				setSites(result)
			})
	}, [sessionPapier])

	return (
		<>
			{etat < ETAT_SESSION.CONVOCATIONS_ENVOYEES && <BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{etat >= ETAT_SESSION.CONVOCATIONS_ENVOYEES && <>
				<Title value="Sites ouverts pour la session" />
				<StaticDataTable
					data={sites}
					headers={[{
						key: 'libelle',
						name: 'Site',
						sortable: false,
						render: data => <Typography className={classes.libelleColumn} onClick={() => goToEpreuves(data)}>{data.libelle}</Typography>
					}]}
				/>
			</>}
		</>
	)
}

const actions = {
	getListeSitesSession,
	goToEpreuves: (data) => dispatch => dispatch(push(`/sessions-papier/${data.sessionId}/epreuves/${data.idSite}`))
}

OngletPVSalle.propTypes = {
	sessionPapier: PropTypes.object
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state)
})

export default compose(
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(OngletPVSalle)
