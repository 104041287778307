import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { StaticDataTable, Title, getStyles } from 'isotope-client'
import { Link, EmptyResult, checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'
import { injectIntl, intlShape } from 'react-intl'
import CreerTypeFormationPopup from './CreerTypeFormationPopup'
import { getListeTypesFormation } from './services/typesFormationActions'
import * as selectors from './services/typesFormationSelectors'

const styles = () => getStyles({
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})

const TypesFormationListe = ({ getListeTypesFormation, liste, classes, intl }) => {

	const [open, setOpen] = React.useState(false)
	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	React.useEffect(() => {
		getListeTypesFormation()
	}, [])

	return (
		<React.Fragment>
			<Title value={intl.formatMessage({ id: 'references.typeFormation.liste.titre' })} />
			<StaticDataTable
				data={liste}
				noResultFragment={<EmptyResult />}
				headers={[
					{
						key: 'libelle',
						name: intl.formatMessage({ id: 'references.typeFormation.headers.libelle' }),
						render: row => <Link to={`/types-formation/${row.id}`}>
							{row.libelle}
						</Link>,
						sortable: false
					},
					{
						key: 'etat',
						name: intl.formatMessage({ id: 'references.typeFormation.headers.etat' }),
						render: row => {
							if (row.active) {
								return intl.formatMessage({ id: 'references.typeFormation.etat.active' })
							}
							return intl.formatMessage({ id: 'references.typeFormation.etat.desactive' })
						}
					}
				]}
			/>
			<Fab
				color="primary"
				label="Add"
				className={classes.fab}
				onClick={handleOpenPopup}
			>
				<AddIcon />
			</Fab>
			<CreerTypeFormationPopup open={open} closePopup={handleClosePopup} />
		</React.Fragment>
	)
}


TypesFormationListe.propTypes = {
	intl: intlShape,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	liste: selectors.getListeTypesFormation(state)
})

const actions = {
	getListeTypesFormation
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(TypesFormationListe)
