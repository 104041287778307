import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { EmptyResult } from '@oceane/ui'
import { SpringDataTable, dataTableActions } from 'isotope-client'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { deleteFormationOrganisme } from './services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'
import * as selectors from './services/popup/nouvelOrganismePopupSelectors'
import NouvelleFormationOrganismePopup from './NouvelleFormationOrganismePopup'
import SuppressionFormationOrganismePopup from './SuppressionFormationOrganismePopup'
import { openPopup, closePopup } from './services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'

class ListeFormationsOrganismeOnglet extends Component {
	state = {
		idDelete: null
	}

	openPopupDelete = (id) => {
		const { openPopup } = this.props

		this.setState({idDelete: id})
		openPopup()
	}

	deleteAction = () => {
		const { deleteFormationOrganisme, refreshTable, closePopup } = this.props
		const { idDelete } = this.state

		return deleteFormationOrganisme(idDelete)
			.then(() => {
				refreshTable()
				closePopup()
			})
	}

	render() {
		const {
			organisme
		} = this.props

		return (
			<React.Fragment>
				<SpringDataTable
					apiUrl={`/organismes/${organisme.id}/listesFormations`}
					headers={[
						{
							key: 'typeFormationExamenType.examenType.libelleCourt',
							name: <FormattedMessage id='references.organismes.onglets.pratique.columns.examen'/>,
							sortable: true,
							render: data => <Typography>{data.libelleExamenType}</Typography>
						},
						{
							key: 'libelleFormationType',
							name: <FormattedMessage id='references.organismes.onglets.pratique.columns.typeFormation'/>,
							sortable: false
						},
						{
							key: 'supprimer',
							render: data => <IconButton onClick={() => this.openPopupDelete(data.id)}><DeleteIcon /></IconButton>
						}
					]}
					mode="get"
					nom="organismesListeFormations"
					noResultFragment={<EmptyResult message="Aucun examen pratique rattaché"/>}
				/>
				<SuppressionFormationOrganismePopup onSubmit={this.deleteAction}/>
				<NouvelleFormationOrganismePopup />
			</React.Fragment>
		)
	}
}

ListeFormationsOrganismeOnglet.propTypes = {
	organisme: PropTypes.object,
	deleteFormationOrganisme: PropTypes.func,
	openPopup: PropTypes.func,
	closePopup: PropTypes.func
}

const mapStateToProps = state => ({
	organisme: selectors.getOrganismeDetails(state)
})

const actions = {
	deleteFormationOrganisme,
	refreshTable: () => dataTableActions.refresh('organismesListeFormations'),
	openPopup,
	closePopup
}

export default compose(
	connect(mapStateToProps, actions)
)(ListeFormationsOrganismeOnglet)
