import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = createSelector(
	getReferences,
	references => references.sessions
)

const getIds = createSelector(
	getLocalState,
	state => state.ids
)

const getIdsByEtat = etat => createSelector(
	getIds,
	ids => ids[etat] || []
)

const getById = createSelector(
	getLocalState,
	state => state.byId
)

export const getListeParEtat = etat => createSelector(
	getIdsByEtat(etat),
	getById,
	(ids, byId) => ids.map(id => byId[id])
)