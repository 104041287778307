import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectEnonces } from './services/BanqueQuestionsInjectors'
import Grid from '@material-ui/core/Grid'
import { FormInput, Title } from 'isotope-client'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { FormattedDate } from 'react-intl'
import { Button, injectFormSnackbar } from '@oceane/ui'
import { getDonneesGenerales } from './services/banqueQuestionsSelectors'
import { BNQ_ID_ETAT_QUESTION } from '../../../config/enums'
import { saveQuestionEnonces } from './services/banqueQuestionsActions'
import { ActionsBottomContext, getStyles } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'


const FORM_NAME = 'EnoncesForm'

const styles = () => getStyles({
	traduction: {
		marginTop: -15,
		marginBottom: 5,
		fontSize: 11
	}
})

const handleRg = (donneesGenerales, enonces) => {
	let readOnlyFr = true
	let readOnlyEn = true
	let justificatifReadOnly = true
	let displayButton = false
	let displayTraduction = false

	// RG d'affichage
	if (enonces.francaise) {
		// affichage de la partie française
		if (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.CREEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.REFUSEE) {
			readOnlyFr = false
		}
		// affichage de la partie anglaise
		if (donneesGenerales.bilingue && donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.ACCEPTEE) {
			readOnlyEn = false
		}
		// affichage du justificatif

		if ([BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE, BNQ_ID_ETAT_QUESTION.APPLICABLE, BNQ_ID_ETAT_QUESTION.ACCEPTEE].includes(donneesGenerales.etat)) {
			justificatifReadOnly = false
		}
		// affichage de la partie traduction
		if (donneesGenerales.bilingue) {
			displayTraduction = true
		}
		// affichage des boutons
		if (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.CREEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.REFUSEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.ACCEPTEE 
			|| donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.APPLICABLE) {
			displayButton = true
		}
	} else {
		if (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.ACCEPTEE) {
			readOnlyFr = false
		}
		if ([BNQ_ID_ETAT_QUESTION.APPLICABLE, BNQ_ID_ETAT_QUESTION.ACCEPTEE].includes(donneesGenerales.etat)) {
			justificatifReadOnly = false
			displayButton = true
		}

		// affichage de la partie anglaise
		// toujours en Read-Only
		// affichage de la partie traduction
		displayTraduction = true
	}

	return {
		readOnlyFr,
		readOnlyEn,
		justificatifReadOnly,
		displayTraduction,
		displayButton
	}
}

const validate = (values, { donneesGenerales, enonces }) => {
	const { readOnlyFr, readOnlyEn, justificatifReadOnly } = handleRg(donneesGenerales, enonces)
	const errors = {}

	if (!readOnlyFr) {
		if (!values.texteFr) {
			errors.texteFr = { id: 'global.erreurs.obligatoire' }
		}
		if (values.reponseCorrecte && !values.reponseCorrecte.libelleFrancais) {
			errors.reponseCorrecte = { libelleFrancais: { id: 'global.erreurs.obligatoire' } }
		}
		if (values.reponseFausse1 && !values.reponseFausse1.libelleFrancais) {
			errors.reponseFausse1 = { libelleFrancais: { id: 'global.erreurs.obligatoire' } }
		}
		if (values.reponseFausse2 && !values.reponseFausse2.libelleFrancais) {
			errors.reponseFausse2 = { libelleFrancais: { id: 'global.erreurs.obligatoire' } }
		}
		if (values.reponseFausse3 && !values.reponseFausse3.libelleFrancais) {
			errors.reponseFausse3 = { libelleFrancais: { id: 'global.erreurs.obligatoire' } }
		}
	}
	if (!justificatifReadOnly && !values.justificatif) {
		errors.justificatif = { id: 'global.erreurs.obligatoire' }
	}

	if (!readOnlyEn) {
		if (!values.texteEn) {
			errors.texteEn = { id: 'global.erreurs.obligatoire' }
		}
		if (values.reponseCorrecte && !values.reponseCorrecte.libelleAnglais) {
			errors.reponseCorrecte = { libelleAnglais: { id: 'global.erreurs.obligatoire' } }
		}
		if (values.reponseFausse1 && !values.reponseFausse1.libelleAnglais) {
			errors.reponseFausse1 = { libelleAnglais: { id: 'global.erreurs.obligatoire' } }
		}
		if (values.reponseFausse2 && !values.reponseFausse2.libelleAnglais) {
			errors.reponseFausse2 = { libelleAnglais: { id: 'global.erreurs.obligatoire' } }
		}
		if (!values.reponseFausse3.libelleAnglais) {
			errors.reponseFausse3 = { libelleAnglais: { id: 'global.erreurs.obligatoire' } }
		}
	}

	return errors
}

const OngletEnonces = ({ classes, enonces, donneesGenerales, saveQuestionEnonces, handleSubmit }) => {
	const { readOnlyFr, readOnlyEn, justificatifReadOnly, displayTraduction, displayButton } = handleRg(donneesGenerales, enonces)
	const { setActions } = React.useContext(ActionsBottomContext)
	const submit = (values) => saveQuestionEnonces(enonces.idQuestion, values).catch(e => { throw new SubmissionError(e) })

	React.useEffect(() => {
		if (displayButton) {
			setActions([
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					Enregistrer
				</Button>
			])
		}
		return () => setActions(null)
	}, [])

	const renderTraduction = (enonces) => displayTraduction && enonces.nomTraducteur && <Typography className={classes.traduction} variant="body1">Traduite {enonces.dateTraduction && 'le' && <FormattedDate
		value={enonces.dateTraduction}
		year="numeric"
		month="2-digit"
		day="2-digit"
	/>} par {enonces.nomTraducteur}</Typography>

	return <>
		<Grid container alignItems="flex-start">
			<Grid item xs={6}>
				<Title value="Version française" />
				{donneesGenerales.numeroEuropeen && renderTraduction(enonces)}
			</Grid>
			<Grid item xs={6}>
				<Title value="Version anglaise" />
				{!donneesGenerales.numeroEuropeen && renderTraduction(enonces)}
			</Grid>
		</Grid>
		<Grid container alignItems="flex-start">
			<Grid item xs={6}>
				<Field
					name="texteFr"
					label={`Texte ${readOnlyFr ? '' : '*'}`}
					disabled={readOnlyFr}
					multiline
					fullWidth
					component={FormInput}
				/>
			</Grid>
			<Grid item xs={6}>
				<Field
					name="texteEn"
					label={`Texte ${readOnlyEn ? '' : '*'}`}
					disabled={readOnlyEn}
					multiline
					fullWidth
					component={FormInput}
					inputProps={{
						maxLength: 2048
					}}
				/>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Field
						name="reponseCorrecte.libelleFrancais"
						label={`Réponse juste ${readOnlyFr ? '' : '*'}`}
						disabled={readOnlyFr}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="reponseCorrecte.libelleAnglais"
						label={`Réponse juste ${readOnlyEn ? '' : '*'}`}
						disabled={readOnlyEn}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Field
						name="reponseFausse1.libelleFrancais"
						label={`Réponse fausse 1 ${readOnlyFr ? '' : '*'}`}
						disabled={readOnlyFr}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="reponseFausse1.libelleAnglais"
						label={`Réponse fausse 1 ${readOnlyEn ? '' : '*'}`}
						disabled={readOnlyEn}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Field
						name="reponseFausse2.libelleFrancais"
						label={`Réponse fausse 2 ${readOnlyFr ? '' : '*'}`}
						disabled={readOnlyFr}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="reponseFausse2.libelleAnglais"
						label={`Réponse fausse 2 ${readOnlyEn ? '' : '*'}`}
						disabled={readOnlyEn}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Field
						name="reponseFausse3.libelleFrancais"
						label={`Réponse fausse 3 ${readOnlyFr ? '' : '*'}`}
						disabled={readOnlyFr}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="reponseFausse3.libelleAnglais"
						label={`Réponse fausse 3 ${readOnlyEn ? '' : '*'}`}
						disabled={readOnlyEn}
						multiline
						fullWidth
						component={FormInput}
						inputProps={{
							maxLength: 2048
						}}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Field
					name="justificatif"
					label={`Justificatif ${readOnlyFr ? '' : '*'}`}
					disabled={justificatifReadOnly}
					multiline
					fullWidth
					component={FormInput}
					inputProps={{
						maxLength: 4500
					}}
				/>
			</Grid>
		</Grid>
	</>
}

OngletEnonces.propTypes = {
	classes: PropTypes.object,
	enonces: PropTypes.object,
	donneesGenerales: PropTypes.object,
	saveQuestionEnonces: PropTypes.func
}

const mapStateToProps = (state, { enonces }) => ({
	initialValues: enonces,
	enonces,
	donneesGenerales: getDonneesGenerales(state)
})

const actions = {
	saveQuestionEnonces
}

export default compose(
	injectEnonces,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' }),
	withStyles(styles)
)(OngletEnonces)
