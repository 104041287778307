import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = createSelector(
	getReferences,
	references => references.scripts
)

export const getParametrage = createSelector(
	getLocalState,
	state => state.parametrage
)

export const getId = createSelector(
	getParametrage,
	parametrage => parametrage.id
)

export const getEtat = createSelector(
	getParametrage,
	parametrage => parametrage.etat
)

export const isExploitation = createSelector(
	getLocalState,
	state => state.isExploitation
)

export const getMatieres = createSelector(
	getLocalState,
	state => state.matieres
)

export const getNoeudCourant = createSelector(
	getLocalState,
	state => state.noeudCourant
)