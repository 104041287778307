import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { getFamille, clearFamille } from './familleAction'
import * as selectors from './familleSelectors'

export const loadFamille = (WrappedComponent) => {
	const FamilleInjector = ({ famille, getFamille, clearFamille, ...otherProps }) => {
		const match = otherProps.match

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (match && match.params && match.params.idFamille !== famille.id) {
				setLoading(true)
				getFamille(match.params.idFamille, match.params.idMatiere)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return clearFamille
		}, [])

		return loading ? <Loader /> : <WrappedComponent famille={famille} { ...otherProps } />
	}

	FamilleInjector.propTypes = {
		famille: PropTypes.object,
		getFamille: PropTypes.func,
		clearFamille: PropTypes.func,
		match: PropTypes.object
	}

	const mapStateToProps = state => ({
		famille: selectors.getFamille(state)
	})

	const actions = {
		getFamille,
		clearFamille,
	}

	return compose(
		connect(mapStateToProps, actions),
	)(FamilleInjector)
}
