import { combineReducers } from 'redux'
import { comptesClients } from './organisme/services/detail/detailOrganismeReducers'
import { nouveauCompteClient } from './compteClient/services/nouveauCompteClientPopupReducer'
import { nouvelOrganisme } from './organisme/services/popup/nouvelOrganismePopupReducers'
import { examinateurEtranger } from './examinateurEtranger/services/examinateurEtrangerReducer'
import { examenPratique } from './organisme/services/examenPratiqueOrganisme/examenPratiqueReducers'
import { piecesJustificatives } from './piecesJustificatives/services/piecesJustificativesReducers'
import { examenTheorique } from './organisme/services/examenTheoriqueOrganisme/examenTheoriqueOrganismeReducers'
import { ligneProgramme } from './ligneProgramme/service/ligneProgrammeReducers'
import { typesFormation } from './typesFormation/services/typesFormationReducer'
import { examensTypes } from './examensType/services/examensTypesReducers'
import { familles } from './famille/services/familleReducers'
import { annexes } from './annexe/services/annexeReducers'
import { matieres } from './matieres/services/matieresReducers'
import { epreuvesTypes } from './epreuveType/services/epreuveTypeReducers'
import { typesFormationActive } from './examensType/popup/services/nouvelleAssociationFormationReducers'
import { scripts } from './scripts/services/scriptReducer'
import { organismes } from './organisme/services/organismeReducers'
import { sessions } from './sessions/services/sessionsReducers'

export const references = combineReducers({
	nouveauCompteClient,
	nouvelOrganisme,
	comptesClients,
	examinateurEtranger,
	examenPratique,
	piecesJustificatives,
	examenTheorique,
	ligneProgramme,
	typesFormation,
	typesFormationActive,
	examensTypes,
	epreuvesTypes,
	scripts,
	familles,
	matieres,
	annexes,
	organismes,
	sessions
})
