import { createSelector } from 'reselect'

const getLocalState = state => state.banqueQuestions

export const getQuestion = createSelector(
	getLocalState,
	state => state.question
)

export const getDonneesGenerales = createSelector(
	getQuestion,
	question => question.donneesGenerales
)

export const getIdQuestion = createSelector(
	getDonneesGenerales,
	donneesGenerales => donneesGenerales.idQuestion
)

export const getEtatQuestion = createSelector(
	getDonneesGenerales,
	donneesGenerales => donneesGenerales.etat
)

export const getDonneesGeneralesForm = createSelector(
	getDonneesGenerales,
	donneesGenerales => ({
		bilingue: donneesGenerales.bilingue,
		ligneProgramme: donneesGenerales.ligneProgramme,
		famille: donneesGenerales.famille,
		marquage: donneesGenerales.marquage,
		aExpertiser: donneesGenerales.aexpertiser
	})
)
export const getEnonces = createSelector(
	getQuestion,
	question => question.enonces
)

const getAffectationsState = createSelector(
	getQuestion,
	question => question.affectations
)

const getAffectationsIds = createSelector(
	getAffectationsState,
	affectations => affectations.ids
)

const getAffectationsById = createSelector(
	getAffectationsState,
	affectations => affectations.byId
)

export const getAffectations = createSelector(
	getAffectationsIds,
	getAffectationsById,
	(ids, byId) => ids.map(id => byId[id])
)

const getAnnexes = createSelector(
	getQuestion,
	question => question.annexes
)

const getAnnexesIds = createSelector(
	getAnnexes,
	annexes => annexes.ids
)

const getAnnexesMap = createSelector(
	getAnnexes,
	annexes => annexes.byId
)

export const getAnnexesDisponibles = createSelector(
	getAnnexesIds,
	getAnnexesMap,
	(ids, map) => ids.filter(id => map[id] && !map[id].affectee).map(id => map[id])
)

export const getAnnexesAffectees = createSelector(
	getAnnexesIds,
	getAnnexesMap,
	(ids, map) => ids.map(id => map[id]).filter(annexe => annexe && annexe.affectee)
)

export const getQuestionExpertise = createSelector(
	getQuestion,
	question => question.expertise
)

export const getEtatGlobal = createSelector(
	getQuestion,
	question => question.etatGlobal
)

export const getQuestionComparatifImport = createSelector(
	getQuestion,
	question => question.comparatifImport
)
