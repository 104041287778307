import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectToolbarData } from 'isotope-client'
import { Actions, getListeExamenType, Button, PROFIL_UTILISATEUR, ResponsiveForm, StyledTypography, injectFormSnackbar } from '@oceane/ui'
import { PROFILS, UTILISATEUR_FORM_NAME } from './services/administrationValues'
import { reduxForm } from 'redux-form'
import * as adminActions from './services/administrationActions'
import * as adminSelectors from './services/administrationSelectors'
import RespoBanqueForm from './components/RespoBanqueForm'
import GestionnaireBanqueForm from './components/GestionnaireBanqueForm'
import GestionnaireExamensForm from './components/GestionnaireExamensForm'
import OrganismeForm from './components/OrganismeForm'
import SuperviseurForm from './components/SuperviseurForm'

const UtilisateurPage = ({ handleSubmit, match, location, getUtilisateur, clearUtilisateur, saveUtilisateur, getListeExamenType }) => {

	React.useEffect(() => {
		if (match && match.params && match.params.idUtilisateur) {
			getUtilisateur(match.params.idUtilisateur, location.state.profil)
		}

		return clearUtilisateur
	}, [])

	const submitUtilisateur = (values) => {
		let formattedValues = {}
		switch (location.state.profil) {
			case PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id:
				formattedValues = {
					...values,
					rdbMatieres: values.rdbMatieres.map(rdb => rdb.id)
				}
				break
			case PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS.id:
				formattedValues = {
					...values,
					rdbMatieres: values.rdbMatieres.map(rdb => rdb.id),
					examensType: values.examensType.map(exam => exam.id)
				}
				break
			case PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN.id:
				formattedValues = {
					...values,
					examensType: values.examensType.map(exam => exam.id)
				}
				break
			case PROFIL_UTILISATEUR.SUPERVISEUR_SITE.id:
				formattedValues = {
					...values,
					sites: values.superviseurSites.map(site => site.id)
				}
				break
			case PROFIL_UTILISATEUR.ORGANISME_FORMATION.id:
			default:
				formattedValues = values
				break
		}

		return saveUtilisateur(match.params.idUtilisateur, {
			...formattedValues,
			profil: location.state.profil
		})
		.then( () => {
          getListeExamenType(location.state.profil)
        })
	}

	const getForm = () => {
		switch (location.state.profil) {
			case PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id:
				return <RespoBanqueForm />
			case PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS.id:
				return <GestionnaireBanqueForm />
			case PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN.id:
				return <GestionnaireExamensForm />
			case PROFIL_UTILISATEUR.ORGANISME_FORMATION.id:
				return <OrganismeForm />
			case PROFIL_UTILISATEUR.SUPERVISEUR_SITE.id:
				return <SuperviseurForm />
			default:
				return <React.Fragment />
		}

	}

	return (
		<ResponsiveForm>
			{getForm()}
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(submitUtilisateur)}
				>
					Enregistrer
				</Button>
			</Actions>
		</ResponsiveForm>
	)
}

const mapStateToProps = state => ({
	utilisateur: adminSelectors.getUtilisateur(state)
})

const actions = {
	getUtilisateur: adminActions.getUtilisateur,
	clearUtilisateur: adminActions.clearUtilisateur,
	saveUtilisateur: adminActions.saveUtilisateur,
	getListeExamenType
}

UtilisateurPage.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	handleSubmit: PropTypes.func,
	getUtilisateur: PropTypes.func,
	clearUtilisateur: PropTypes.func,
	saveUtilisateur: PropTypes.func,
	getListeExamenType: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	injectToolbarData(({ utilisateur, location }) => ({
		title: 'Modification d\'un utilisateur',
		subheader: {
			separator: () => '>',
			filArianeEntries: [{
				text: location.state && `${location.state.identifiant || ''} | ${location.state && location.state.profil && PROFILS[location.state.profil - 1] ? PROFILS[location.state.profil - 1].nom : ''}`,
				render: text => <React.Fragment>
					<StyledTypography bold>{text}</StyledTypography>
				</React.Fragment>
			}]
		}
	})),
	reduxForm({
		form: UTILISATEUR_FORM_NAME
	}),
	injectFormSnackbar()
)(UtilisateurPage)
