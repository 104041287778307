import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MuiButton from '@material-ui/core/Button'
import { Button, Popup } from '@oceane/ui'
import NouvelImportKSAForm, { IMPORT_KSA_FORM_NAME } from './NouvelImportKSAForm'
import { submit } from 'redux-form'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { creerImport } from '../service/importKSAActions'

const NouvelImportKSAPopup = ({ open, closePopup, reloadTables, submitForm, submitting, creerImport, snackError }) => {

	const [error, setError] = React.useState([])

	const submit = values => {
		return creerImport(values)
			.then(closePopup)
			.catch(e => {
				snackError()
				if (e && e.bodyError && e.bodyError.globalErrors) {
					setError(e.bodyError.globalErrors.map(error => ({ id: error.code })))
				} else {
					setError([])
				}
			})
	}

	const close = () => {
		closePopup()
		setError([])
		reloadTables()
	}

	const actions = [
		<MuiButton key="close" color="primary" onClick={closePopup}>
			Annuler
		</MuiButton>,
		<Button
			key="valider"
			variant="contained"
			onClick={submitForm}
			loading={submitting}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			onExited={close}
			title="Nouvel import des questions d'une matière"
			content={<NouvelImportKSAForm onSubmit={submit} customError={error} />}
		/>
	)
}

NouvelImportKSAForm.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	reloadTables: PropTypes.func.isRequired,
	submitForm: PropTypes.func,
	submitting: PropTypes.bool,
	creerImport: PropTypes.func,
	snackError: PropTypes.func
}

const actions = {
	creerImport,
	submitForm: () => dispatch => dispatch(submit(IMPORT_KSA_FORM_NAME))
}

export default compose(
	connect(undefined, actions),
	injectSnackActions
)(NouvelImportKSAPopup)
