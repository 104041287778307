import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@oceane/ui'
import Button from '@material-ui/core/Button'

const ConfirmEtatPopup = ({ popupOptions, onClose, onSubmit }) => (
	<Popup
		open={popupOptions.open || false}
		title={popupOptions.title}
		actions={[
			<Button
				color="primary"
				key="annuler"
				onClick={onClose}
			>
				Annuler
			</Button>,
			<Button
				variant="contained"
				color="primary"
				key="valider"
				onClick={() => {
					onSubmit()
					onClose()
				}}
			>
				Confirmer
			</Button>
		]}
		content={<span>{popupOptions.message}</span>}
	/>
)


ConfirmEtatPopup.propTypes = {
	popupOptions: PropTypes.object,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func
}
export default ConfirmEtatPopup
