import React from 'react'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStyles, injectToolbarData, getToolbarData, setToolbarData, resetToolbarData } from 'isotope-client'
import { checkAuthorities, injectSession, PROFIL_UTILISATEUR, Tabs } from '@oceane/ui'
import { injectDonneesGenerales, setExploitationQuestion } from './services/BanqueQuestionsInjectors'
import { getFilArianeQuestion } from './services/banqueQuestionsFilAriane'
import OngletDonneesGenerales from './OngletDonneesGenerales'
import OngletEnonces from './OngletEnonces'
import OngletAffectation from './OngletAffectations'
import OngletAnnexes from './OngletAnnexes'
import OngletImport from './OngletImport'
import OngletExpertise from './OngletExpertise'
import { cleanQuestion } from './services/banqueQuestionsActions'
import * as selectors from './services/banqueQuestionsSelectors'
import { BNQ_ID_ETAT_QUESTION, BNQ_ID_ORIGINE_QUESTION } from '../../../config/enums'
import OngletEtatGlobal from './OngletEtatGlobal'

const styles = theme => getStyles({
	tab: {
		[theme.breakpoints.up('xs')]: {
			minWidth: 932/7
		}
	}
})

const QuestionGeneriquePage = ({ question, isExploitation, toolbarData, changeToolbarData, cleanToolbarData, cleanQuestion, profil, classes }) => {

	const tabs = [
		<Tab key="general" label="General" className={classes.tab} />,
		<Tab key="enonces" label="Enonces" className={classes.tab} />,
		<Tab key="affectations" label="Affectations" className={classes.tab} />,
		<Tab key="annexes" label="Annexes" className={classes.tab} />,
		<Tab key="expertise" label="Expertise" className={classes.tab} />,
		<Tab key="etat-global" label="Etat global" className={classes.tab} />
	]

	const tabViews = [
		<OngletDonneesGenerales isExploitation={isExploitation} />,
		<OngletEnonces isExploitation={isExploitation} />,
		<OngletAffectation isExploitation={isExploitation} />,
		<OngletAnnexes isExploitation={isExploitation} />,
		<OngletExpertise isExploitation={isExploitation} />,
		<OngletEtatGlobal isExploitation={isExploitation} />
	]

	// Pour les questions en exploitation, on ajoute l'onglet "Import"
	if (question.origine === BNQ_ID_ORIGINE_QUESTION.EUROPEENNE && !isExploitation) {
		tabs.splice(4, 0, <Tab key="import" label="Import" className={classes.tab} />)
		tabViews.splice(4, 0, <OngletImport />)
	}

	React.useEffect(() => {
		if (question.etat !== BNQ_ID_ETAT_QUESTION.SUPPRIMEE) {
			changeToolbarData(toolbarData.id, getFilArianeQuestion(question, profil))
		}
		return () => {
			cleanToolbarData(toolbarData.id)
		}
	}, [question.etat, question.aexpertiser])

	React.useEffect(() => cleanQuestion, [])

	return (
		<Tabs
			initialTab={1}
			tabs={tabs}
			tabViews={tabViews}
		/>
	)
}

const mapStateToProps = state => ({
	question: selectors.getDonneesGenerales(state),
	toolbarData: getToolbarData(state)
})

const actions = {
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	cleanQuestion
}

const sharedCompose = compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	injectDonneesGenerales,
	injectSession,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
		PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE
	),
	injectToolbarData(({ question, profil }) => getFilArianeQuestion(question, profil))
)

export const QuestionExploitationPage = compose(
	setExploitationQuestion(true),
	sharedCompose
)(QuestionGeneriquePage)

export const QuestionPage = compose(
	setExploitationQuestion(false),
	sharedCompose
)(QuestionGeneriquePage)
