import {
	Actions,
	Autocomplete,
	Button,
	checkAuthorities,
	Error,
	getVilles,
	injectSession,
	Input,
	InputFile,
	isCodePostalOk,
	isTelephoneOk,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	StyledTypography,
	injectFormSnackbar
} from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormInput, getStyles, getToolbarData, injectToolbarData, setToolbarData } from 'isotope-client'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {
	injectActions as injectSnackActions,
} from 'isotope-client/components/snackbar/services/snackbarInjector'
import { loadCentre } from './services/centreInjector'
import { loadSitePapier } from './services/sitePapierInjector'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { checkFileExist, modificationSitePapier } from './services/centrePapierActions'

const style = () => getStyles({
	tooltip: {
		margin: '-15px 0 0 16px'
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		marginRight: 5
	},
	buttonCharger: {
		marginTop: 19
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.capacite) {
		errors.capacite = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.ligne1) {
		errors.ligne1 = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.codePostal) {
		errors.codePostal = { id: 'global.erreurs.obligatoire' }
	} else if (!isCodePostalOk(values.codePostal)) {
		errors.codePostal = { id: 'global.erreurs.alphaTiret' }
	}

	if (!values.ville) {
		errors.ville = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.itineraire) {
		errors.itineraire = { id: 'global.erreurs.obligatoire' }
	}

	if (values.telephone && !isTelephoneOk(values.telephone)) {
		errors.telephone = { id: 'global.erreurs.telephone' }
	}

	if (values.telecopie && !isTelephoneOk(values.telecopie)) {
		errors.telecopie = { id: 'global.erreurs.telephone' }
	}

	return errors
}

const DetailSitePapier = ({
							  submitting,
							  handleSubmit,
							  initialValues,
							  classes,
							  changeToolbarData,
							  toolbarData,
							  centre,
							  getVilles,
							  snackError,
							  change,
							  file,
							  plan,
							  checkFileExist,
							  modificationSitePapier,
							  codePostal
						  }) => {
	const [customError, setCustomError] = React.useState(null)
	const [hidePlan, setHidePlan] = React.useState(plan !== null)

	React.useEffect(() => {
		if (toolbarData.id) {
			changeToolbarData(toolbarData.id, ({
				title: 'Gestion du centre papier',
				subheader: (centre) && {
					filArianeEntries: [{
						render: () => <StyledTypography bold>Centre : {centre.libelle}</StyledTypography>
					}]
				}
			}))
		}
	}, [centre])

	const getFileExtension = (filename) => {
		return filename.substr((filename.lastIndexOf('.') + 1))
	}

	const chargerFichier = () => {
		if (file) {
			const extension = getFileExtension(file[0].name)

			if (extension !== 'jpeg' && extension !== 'jpg' && extension !== 'gif' && extension !== 'bmp' && extension !== 'png' && extension !== 'tif' && extension !== 'tiff') {
				setCustomError(['L\'extension du fichier doit être au format .jpeg, .jpg, .gif, .bmp, .png, .tif, .tiff'])
				snackError('Une erreur est survenue')
			} else {
				setCustomError(null)
				setHidePlan(true)
			}
		} else {
			setCustomError(['Veuillez charger un fichier'])
			snackError('Une erreur est survenue')
		}
	}

	const downloadFile = (fichierNom) => {
		checkFileExist(centre.id, initialValues.id, fichierNom)
			.then(() =>
				download(`/files/sitePapier/${fichierNom}`)
			)
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const supprimerFichier = () => {
		setHidePlan(false)
		change('plan', null)
		// On indique qu'il faudra supprimer le fichier
		change('fichierASupprimer', true)
	}

	const enregistrer = (values) => {
		return modificationSitePapier(values, centre.id)
			.then(() => {
					if (!!values.plan) {
						setHidePlan(true)
					}
				}
			)
			.catch(e => {
				throw new SubmissionError(e)
			})
	}
	return (
		<>
			<Error error={customError} />
			<ResponsiveForm>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
					normalize={(value) => {
						if (value.indexOf(centre.libelle + ' - ') !== -1 && value.substring(0, centre.libelle.length + 3) !== (centre.libelle + ' - ')) {
							return value.substring(value.indexOf(centre.libelle + ' - '))
						} else if (value.substring(0, centre.libelle.length + 3) !== (centre.libelle + ' - ')) {
							if (value.length < (centre.libelle.length + 3)) {
								return centre.libelle + ' - '
							} else {
								return centre.libelle + ' - ' + value
							}
						} else {
							return value
						}
					}}
				/>
				<Field id="capacite" name="capacite" label="Capacité *" component={Input} />
				<Field name="ligne1" label="Ligne 1 *" component={Input} inputProps={{ maxLength: 38 }} />
				<Field name="ligne2" label="Ligne 2" component={Input} inputProps={{ maxLength: 38 }} />
				<Field name="ligne3" label="Ligne 3" component={Input} inputProps={{ maxLength: 38 }} />
				<Field name="codePostal" label="Code Postal *" component={Input} inputProps={{ maxLength: 10 }} />
				<Field
					id="ville"
					name="ville"
					component={Autocomplete}
					getSuggestion={() => getVilles(null, codePostal)}
					label="Ville *"
					canLoadIfEmpty
				/>
				<Field name="pays" label="Pays" component={Input} />
				<Field name="telephone" label="Téléphone" component={Input} inputProps={{ maxLength: 20 }} />
				<Field name="telecopie" label="Télécopie" component={Input} inputProps={{ maxLength: 20 }} />
				<Field
					name="itineraire"
					label="Itinéraire *"
					component={FormInput}
					fullWidth
					multiline
				/>
				<Grid container alignItems="flex-start">
					<Grid item>
						<InputFile
							name="file"
							id="file"
							label="Plan"
						/>
					</Grid>
					<Grid item>
						<Button
							color="primary"
							className={classes.buttonCharger}
							onClick={() => chargerFichier()}
						>
							Charger
						</Button>
					</Grid>
				</Grid>

				{(plan != null && hidePlan) && <Grid container>
					<Grid item>
						<Typography className={classes.link} onClick={() => downloadFile(plan)}>{plan}</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Button
							color="primary"
							onClick={() => supprimerFichier()}
						>
							Supprimer
						</Button>
					</Grid>
				</Grid>}
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(enregistrer)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
		</>
	)
}

const actions = {
	getVilles,
	changeToolbarData: setToolbarData,
	checkFileExist,
	modificationSitePapier
}

DetailSitePapier.propTypes = {
	getVilles: PropTypes.func,
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	checkFileExist: PropTypes.func,
	modificationSitePapier: PropTypes.func
}

const mapStateToProps = (state, { site }) => {
	const { file, plan, codePostal } = formValueSelector('DetailsSitePapier')(state, 'file', 'plan', 'codePostal')

	return ({
		initialValues: {
			...site,
			file: null
		},
		file,
		plan,
		codePostal,
		toolbarData: getToolbarData(state)
	})
}

export default compose(
	loadCentre,
	loadSitePapier,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'DetailsSitePapier',
		enableReinitialize: true,
		validate
	}),
	injectSession,
	withStyles(style),
	injectFormSnackbar(),
	injectToolbarData(({ centre }) => {
		return ({
			title: 'Gestion du centre papier',
			subheader: (centre) && {
				filArianeEntries: [{
					render: () => <StyledTypography bold>Centre : {centre.libelle}</StyledTypography>
				}]
			}
		})
	}),
	injectSnackActions
)(DetailSitePapier)
