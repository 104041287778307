import * as api from './organismeApi'

export const GET_LISTE_ORGANISMES_REQUEST = 'REFERENCES/GET_LISTE_ORGANISMES_REQUEST'
export const GET_LISTE_ORGANISMES_SUCCESS = 'REFERENCES/GET_LISTE_ORGANISMES_SUCCESS'
export const GET_LISTE_ORGANISMES_ERROR   = 'REFERENCES/GET_LISTE_ORGANISMES_ERROR'
export const getListeOrganismes = () => dispatch => {
	dispatch({ type: GET_LISTE_ORGANISMES_REQUEST })
	return api.getListeOrganismes()
		.then(organismes => dispatch({
			type: GET_LISTE_ORGANISMES_SUCCESS,
			organismes
		}))
		.catch(e => {
			dispatch({ type: GET_LISTE_ORGANISMES_ERROR })
			throw e
		})
}

export const GET_LISTE_FORMATIONS_THEORIQUES_REQUEST = 'REFERENCES/GET_LISTE_FORMATIONS_THEORIQUES_REQUEST'
export const GET_LISTE_FORMATIONS_THEORIQUES_SUCCESS = 'REFERENCES/GET_LISTE_FORMATIONS_THEORIQUES_SUCCESS'
export const GET_LISTE_FORMATIONS_THEORIQUES_ERROR = 'REFERENCES/GET_LISTE_FORMATIONS_THEORIQUES_ERROR'
export const getListeFormationsTheoriques = idOrganisme => dispatch => {
	dispatch({ type: GET_LISTE_FORMATIONS_THEORIQUES_REQUEST })
	return api.getListeFormationsTheoriques(idOrganisme)
		.then(result => {
			dispatch({
				type: GET_LISTE_FORMATIONS_THEORIQUES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_LISTE_FORMATIONS_THEORIQUES_ERROR, e })
			throw e
		})
}


export const HANDLE_QUERY_LISTE_ORGANISMES = 'HANDLE_QUERY_LISTE_ORGANISMES'
export const RESET_QUERY_LISTE_ORGANISMES = "RESET_QUERY_LISTE_ORGANISMES"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_LISTE_ORGANISMES,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_LISTE_ORGANISMES
	})
}