import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TopicItem from './TopicItem'
import * as shapes from '../../../../config/shapes'


const ListeTopics = ({ topics, editable, openPopup }) => {
	return (
		<Grid container direction="column">
			{React.Children.toArray(topics.map(topic => <TopicItem
				topic={topic}
				editable={editable}
				openPopup={openPopup}
			/>))}
		</Grid>
	)
}

ListeTopics.propTypes = {
	topics: PropTypes.arrayOf(shapes.topicShape),
	editable: PropTypes.bool,
	openPopup: PropTypes.func
}

export default ListeTopics
