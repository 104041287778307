import { getReferences } from '../../referencesSelectors'
import { createSelector } from 'reselect'

const getLocalState = createSelector(
	getReferences,
	references => references.matieres
)

const getAll = createSelector(
	getLocalState,
	state => state.all
)

const matieresIds = createSelector(
	getAll,
	matieres => matieres.ids
)

const matieresById = createSelector(
	getAll,
	matieres => matieres.byId
)

export const getListeMatieres = createSelector(
	matieresIds,
	matieresById,
	(ids, byId) => ids.map(id => byId[id])
)

const getMe = createSelector(
	getLocalState,
	state => state.me
)

const mesMatieresIds = createSelector(
	getMe,
	matieres => matieres.ids
)

const mesMatieresById = createSelector(
	getMe,
	matieres => matieres.byId
)

export const getMesMatieres = createSelector(
	mesMatieresIds,
	mesMatieresById,
	(ids, byId) => ids.map(id => byId[id])

)

const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)