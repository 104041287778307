import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getSitePhysique } from './sitesPhysiquesAction'


export const loadSite = (WrappedComponent) => {
	const LoadSiteInjector = ({ match, getSitePhysique, ...props }) => {
		const [site, setSite] = React.useState({})

		const loadSite = () => getSitePhysique(match.params.id)
			.then(({ site }) => setSite(site))

		React.useEffect(() => {
			if (match && match.params && match.params.id) {
				loadSite()
			}
		}, [])

		return <WrappedComponent site={site} getSitePhysique={loadSite} {...props} />
	}

	const actions = {
		getSitePhysique
	}

	LoadSiteInjector.propTypes = {
		match: PropTypes.object,
		getSitePhysique: PropTypes.func
	}

	return connect(null, actions)(LoadSiteInjector)
}
