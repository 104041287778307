export const TYPE_EDITION = {
	INSCRIPTION: 'Liste des inscriptions papier',
	REGLEMENTS: 'Etats des règlements',
	BORDEREAU:'Bordereau licences',
	RECAPITULATIF: 'Récapitulatif examens coordonnateur'
}

export const TRI_INSCRIPTIONS = [{
	value: '1',
	label: 'N° inscription'
}, {
	value: '2',
	label: 'Alphabétique'
}]

export const TYPE_ETAT = [{
	value: '1',
	label: 'Paiement'
}, {
	value: '3',
	label: 'Exonération'
}, {
	value: '2',
	label: 'Paiement différé'
}, {
	value: '4',
	label: 'Paiement par chèque'
}, {
	value: '5',
	label: 'Attestations de paiement'
}]

export const MOTIF = [{
	value: '1',
	label: 'Demandeur d\'emploi'
}, {
	value: '2',
	label: 'Report d\'inscription'
}]
