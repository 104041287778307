import * as administrationApi from './administrationApi'

const ROOT = 'ADMINISTRATION'

export const HANDLE_QUERY = `${ROOT}/HANDLE_QUERY`
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY,
		key,
		value
	})
}

export const RESET_FILTERS = `${ROOT}/RESET_FILTERS`
export const resetFilters = () => dispatch => {
	dispatch({
		type: RESET_FILTERS
	})
}

const RECHERCHER_DANS_LDAP_SUCCESS = 'RECHERCHER_DANS_LDAP_SUCCESS'
const RECHERCHER_DANS_LDAP_ERROR = 'RECHERCHER_DANS_LDAP_ERROR'
export const rechercherDansLdap = (idUtilisateur, profil) => dispatch =>
	administrationApi.rechercherDansLdap(idUtilisateur, profil)
		.then(message => dispatch({
			type: RECHERCHER_DANS_LDAP_SUCCESS,
			message
		}))
		.catch(e => dispatch({
			type: RECHERCHER_DANS_LDAP_ERROR,
			e
		}))

export const GET_UTILISATEUR_SUCCESS = 'GET_UTILISATEUR_SUCCESS'
export const GET_UTILISATEUR_ERROR = 'GET_UTILISATEUR_ERROR'
export const getUtilisateur = (id, profil) => dispatch =>
	administrationApi.getUtilisateur(id, profil)
		.then(utilisateur => dispatch({
			type: GET_UTILISATEUR_SUCCESS,
			utilisateur
		}))
		.catch(e => dispatch({
			type: GET_UTILISATEUR_ERROR,
			e
		}))

export const CLEAR_UTILISATEUR = `${ROOT}/CLEAR_UTILISATEUR`
export const clearUtilisateur = () => dispatch =>
	dispatch({ type: CLEAR_UTILISATEUR })

export const SAVE_UTILISATEUR_REQUEST = 'SAVE_UTILISATEUR_REQUEST'
export const SAVE_UTILISATEUR_SUCCESS = 'SAVE_UTILISATEUR_SUCCESS'
export const SAVE_UTILISATEUR_ERROR = 'SAVE_UTILISATEUR_ERROR'
export const saveUtilisateur = (id, values) => dispatch => {
	dispatch({ type: SAVE_UTILISATEUR_REQUEST, id, values })
	return administrationApi.putUtilisateur(id, values)
		.then(utilisateur => {
          dispatch({ type: SAVE_UTILISATEUR_SUCCESS, utilisateur })
          return new Promise(success => {
            success()
          })
        })
		.catch(e => {
			dispatch({ type: SAVE_UTILISATEUR_ERROR, e})
			throw e
		})
}

const SYNCHRONISER_LDAP_REQUEST = 'SYNCHRONISER_LDAP_REQUEST'
const SYNCHRONISER_LDAP_SUCCESS = 'SYNCHRONISER_LDAP_SUCCESS'
const SYNCHRONISER_LDAP_ERROR = 'SYNCHRONISER_LDAP_ERROR'

export const synchroniserLdap = () => dispatch => {
	dispatch({ type: SYNCHRONISER_LDAP_REQUEST })
	return administrationApi.synchroniserLdap()
		.then(response => dispatch({ type: SYNCHRONISER_LDAP_SUCCESS, response }))
		.catch(e => {
			dispatch({
				type: SYNCHRONISER_LDAP_ERROR,
				e
			})
			throw e
		})
}

export const GET_INFORMATION_SUCCESS = 'GET_INFORMATION_SUCCESS'
export const GET_INFORMATION_ERROR = 'GET_INFORMATION_ERROR'
export const GET_INFORMATION_REQUEST = 'GET_INFORMATION_REQUEST'
export const getInformation = (type) => dispatch => {
	dispatch({ type: GET_INFORMATION_REQUEST })
	return administrationApi.getInformation(type)
		.then(information => dispatch({
			type: GET_INFORMATION_SUCCESS,
			payload: information
		}))
		.catch(e => dispatch({
			type: GET_INFORMATION_ERROR,
			e
		}))
}

export const MODIFICATION_INFORMATION_REQUEST = `${ROOT}/MODIFICATION_INFORMATION_REQUEST`
export const MODIFICATION_INFORMATION_SUCCESS = `${ROOT}/MODIFICATION_INFORMATION_SUCCESS`
export const MODIFICATION_INFORMATION_ERROR = `${ROOT}/MODIFICATION_INFORMATION_ERROR`
export const modifierInformation = (values) => dispatch => {
	dispatch({ type: MODIFICATION_INFORMATION_REQUEST })
	return administrationApi.postInformation(values)
		.then((result) => {
			dispatch({
				type: MODIFICATION_INFORMATION_SUCCESS,
				payload: result
			})
		})
		.catch(e => {
			dispatch({
				type: MODIFICATION_INFORMATION_ERROR,
				e
			})
			throw e
		})
}


