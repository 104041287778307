import { fetchFactory } from 'isotope-client'

export const postFamille = (famille, idMatiere) => fetchFactory(`/matiere/${idMatiere}/familles`, {
	method: 'POST',
	body: JSON.stringify(famille)
})

export const getFamille = (idFamille, idMatiere) => fetchFactory(`/matiere/${idMatiere}/familles/${idFamille}`)

export const putFamille = (famille, idMatiere) => fetchFactory(`/matiere/${idMatiere}/familles/${famille.id}`, {
	method: 'PUT',
	body: JSON.stringify(famille)
})

export const deleteFamille = (idFamille, idMatiere) => fetchFactory(`/matiere/${idMatiere}/familles/${idFamille}`, {
	method: 'DELETE'
})
