import { Actions, Button, DateInput, Error, InputRadio, ResponsiveForm } from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { TYPE_STATISTIQUES_COMPETENCES_LINGUISTIQUES } from '../../../../config/enums'

const validate = values => {
	const errors = {}
	if (!values.debutPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.finPeriode) {
		errors.finPeriode = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const ConsulterStatistiquesCompetencesLinguistiquesForm = ({ submitting, handleSubmit, error }) => {
	return (
		<React.Fragment>
			<Error error={error} />
			<ResponsiveForm onSubmit={handleSubmit}>
				<Field
					id="debutPeriode"
					name="debutPeriode"
					label="Début période *"
					component={DateInput}
				/>
				<Field
					id="finPeriode"
					name="finPeriode"
					label="Fin période *"
					component={DateInput}
				/>
				<Field
					id="type"
					name="type"
					label="Type de statistique *"
					component={InputRadio}
					choices={[
						{
							value: '1',
							label: TYPE_STATISTIQUES_COMPETENCES_LINGUISTIQUES['1']
						},
						{
							value: '2',
							label: TYPE_STATISTIQUES_COMPETENCES_LINGUISTIQUES['2']
						}
					]}
				/>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						type="submit"
					>
						Exporter
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

ConsulterStatistiquesCompetencesLinguistiquesForm.propTypes = {
	error: PropTypes.array,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func
}

export default compose(
	reduxForm({
		form: 'ConsulterStatistiquesCompetencesLinguistiquesForm',
		initialValues: {
			debutPeriode: '',
			finPeriode: '',
			type: '1'
		},
		validate,
		enableReinitialize: true
	})
)(ConsulterStatistiquesCompetencesLinguistiquesForm)
