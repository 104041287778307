import React from 'react'
import PropTypes from 'prop-types'
import { EmptyResult } from '@oceane/ui'
import { connect } from 'react-redux'
import { getExamensTypePratique } from '../../../references/organisme/services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'
import { SpringDataTable, Title } from 'isotope-client'
import EditionBordereauActions from './EditionBordereauActions'

const HEADERS = [{
	key: 'nom',
	name: 'Nom',
	sortable: false
}, {
	key: 'prenom',
	name: 'Prénom',
	sortable: false
}, {
	key: 'dateNaissance',
	name: 'Date de naissance',
	sortable: false
}, {
	key: 'dateExamen',
	name: 'Date examen',
	sortable: false
}]

const EditionBordereau = ({ bordereauxSelectionnes, getExamensTypePratique }) => {
	const [examensPratiqueList, setExamensPratiqueList] = React.useState([])
	const [idExamen, setIdExamen] = React.useState(undefined)

	React.useEffect(() => {
		if (!examensPratiqueList.length) {
			getExamensTypePratique()
				.then((list) => {
					setExamensPratiqueList(list)
				})
		}
		return () => setExamensPratiqueList([])
	}, [])

	return (<React.Fragment>
		<Title value="Sélection des bordereaux à imprimer" />
		<EditionBordereauActions
			examensPratiqueList={examensPratiqueList}
			idExamen={idExamen}
			setIdExamen={setIdExamen}
		/>
		{idExamen && <SpringDataTable
			filters={{
				idExamenType: idExamen
			}}
			apiUrl="/examensTypes/examensFuturs"
			headers={HEADERS}
			selectable
			displaySelectAll
			nom="bordereaux"
			noPagination
			noResultFragment={<EmptyResult />}
		/>}
	</React.Fragment>)
}

EditionBordereau.propTypes = {
	getExamensTypePratique: PropTypes.func
}

const actions = {
	getExamensTypePratique
}

export default connect(null, actions)(EditionBordereau)
