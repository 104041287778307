import {
	Actions,
	Button,
	EXAMEN_CANDIDAT_ETAT,
	EXAMEN_CANDIDAT_OBJECTIF,
	getFilArianeExamen,
	injectProfil,
	PROFIL_UTILISATEUR,
	ResponsiveForm
} from '@oceane/ui'
import { push } from 'connected-react-router'
import { FormInput, getToolbarData, setToolbarData, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
	abandonner,
	enregistrer,
	isExamenCandidatSupprimable,
	reouvrir,
	supprimer
} from '../services/actionsAvanceesActions'
import { getIsSupprimable } from '../services/actionsAvanceesSelectors'
import ConfirmModifierEtatPopup from './popup/ConfirmModifierEtatPopup'

const validate = values => {
	const errors = {}
	if (!values.commentaire) {
		errors.commentaire = 'Ce champ est obligatoire'
	}
	return errors
}

const ACTION_AVANCEE = {
	ABANDON: 'ABANDON',
	SUPPRESSION: 'SUPPRESSION',
	REOUVERTURE: 'REOUVERTURE'
}

const ActionsAvancees = ({
	                         change,
	                         profil,
	                         classes,
	                         etatCivil,
	                         snackError,
	                         submitting,
	                         toolbarData,
	                         goToExamens,
	                         handleSubmit,
	                         snackSuccess,
	                         isSupprimable,
	                         setToolbarData,
	                         reouvrirAction,
	                         supprimerAction,
	                         abandonnerAction,
	                         enregistrerAction,
	                         examenSelectionne,
	                         isExamenCandidatSupprimableAction,
	                         commentaire,
                         }) => {

	const [error, setError] = React.useState()

	React.useEffect(() => {
		isExamenCandidatSupprimableAction(etatCivil.id, examenSelectionne.id)
		change('commentaire', examenSelectionne.commentaire)
	}, [])

	const [openPopup, setOpenPopup] = React.useState(false)
	const [actionAvancee, setActionAvancee] = React.useState(ACTION_AVANCEE.ABANDON)

	const filAriane = (etat) => setToolbarData(toolbarData.id, getFilArianeExamen(
		etatCivil.id,
		etatCivil.numeroOceane,
		etatCivil.nomNaissance,
		etatCivil.prenomPrincipal,
		examenSelectionne.examenTypeLibelle,
		etat,
		profil !== PROFIL_UTILISATEUR.GESTIONNAIRE_EXAMENS_PRATIQUES.id
	))

	const submitActions = (values) => {
		switch (actionAvancee) {
			case ACTION_AVANCEE.ABANDON:
				return abandonnerAction(etatCivil.id, examenSelectionne.id, values)
					.then(() => filAriane(EXAMEN_CANDIDAT_ETAT.ABANDONNE))
					.then(() => {
						snackSuccess()
						setOpenPopup(false)
					})
					.catch((e) => {
						snackError()
						if (e.bodyError) {
							setError(e.bodyError)
						}
					})
			case ACTION_AVANCEE.SUPPRESSION:
				return supprimerAction(etatCivil.id, examenSelectionne.id)
					.then(() => {
						snackSuccess()
						setOpenPopup(false)
						goToExamens(etatCivil.id)
					})
					.catch(() => {
						snackError()
					})
			case ACTION_AVANCEE.REOUVERTURE:
				return reouvrirAction(etatCivil.id, examenSelectionne.id, values)
					.then(() => filAriane(EXAMEN_CANDIDAT_ETAT.EN_COURS))
					.then(() => {
						snackSuccess()
						setOpenPopup(false)
					})
					.catch((e) => {
						snackError()
						if (e.bodyError) {
							setError(e.bodyError)
						}
					})
			default:
		}
	}

	const submitEnregistrer = (values) => enregistrerAction(etatCivil.id, examenSelectionne.id, values).then(() => snackSuccess())

	return <ResponsiveForm>
		<Title value="Modification de l'état de l'examen" />
		<Field
			id="commentaire"
			name="commentaire"
			label="Commentaire *"
			multiline
			fullWidth
			rows={10}
			rowsMax={10}
			component={FormInput}
			inputProps={{
				maxLength: 800
			}}
		/>
		<ConfirmModifierEtatPopup
			open={openPopup}
			submit={actionAvancee === ACTION_AVANCEE.SUPPRESSION ? submitActions : handleSubmit(submitActions)}
			closePopup={() => setOpenPopup(false)}
			action={actionAvancee}
			error={error}
		/>
		<Actions>
			{isSupprimable && <Button
				loading={submitting}
				color="primary"
				onClick={() => {
					setActionAvancee(ACTION_AVANCEE.SUPPRESSION)
					setOpenPopup(true)
				}}
			>
				Supprimer
			</Button>}
			{examenSelectionne.etat === EXAMEN_CANDIDAT_ETAT.EN_COURS && examenSelectionne.objectif === EXAMEN_CANDIDAT_OBJECTIF.OBTENTION_DIPLOME &&
			<Button
				loading={submitting}
				color="primary"
				onClick={() => {
					setActionAvancee(ACTION_AVANCEE.ABANDON)
					setOpenPopup(true)
				}}
				disabled={!commentaire}
			>
				Abandonner
			</Button>}
			{examenSelectionne.etat === EXAMEN_CANDIDAT_ETAT.ABANDONNE &&
			<Button
				loading={submitting}
				color="primary"
				onClick={() => {
					setActionAvancee(ACTION_AVANCEE.REOUVERTURE)
					setOpenPopup(true)
				}}
				disabled={!commentaire}
			>
				Réouvrir
			</Button>}
			<Button
				loading={submitting}
				color="primary"
				variant="contained"
				onClick={handleSubmit(submitEnregistrer)}
				disabled={!commentaire}
			>
				Enregistrer
			</Button>
		</Actions>
	</ResponsiveForm>
}

ActionsAvancees.propTypes = {
	commentaire: PropTypes.string,
	isSupprimable: PropTypes.bool,
	isExamenCandidatSupprimableAction: PropTypes.func,
	supprimerAction: PropTypes.func,
	enregistrerAction: PropTypes.func,
	abandonnerAction: PropTypes.func,
	reouvrirAction: PropTypes.func,
	goToExamens: PropTypes.func
}

const FORM_NAME = 'modifierEtatExamen'

const mapStateToProps = (state) => ({
	commentaire: formValueSelector(FORM_NAME)(state, 'commentaire'),
	isSupprimable: getIsSupprimable(state),
	toolbarData: getToolbarData(state)
})

const actions = {
	isExamenCandidatSupprimableAction: isExamenCandidatSupprimable,
	supprimerAction: supprimer,
	enregistrerAction: enregistrer,
	abandonnerAction: abandonner,
	reouvrirAction: reouvrir,
	goToExamens: (idCandidat) => dispatch => dispatch(push(`/candidats/${idCandidat}/examens`)),
	setToolbarData
}

export default compose(
	connect(mapStateToProps, actions),
	injectProfil,
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectSnackActions
)(ActionsAvancees)
