import { Input, Popup, Button } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { createTopic } from '../service/ligneProgrammeAction'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class NouveauTopicPopup extends Component {

	submitCreateMatiere = (values) => {
		const { createTopic, snackSuccess, refreshTable, closePopupCreation, idMatiere } = this.props

		return createTopic(values, idMatiere)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopupCreation()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	closePopup = () => {
		const {
			closePopupCreation,
			reset
		} = this.props

		reset()
		closePopupCreation()
	}

	popupContent = () => (
		<form
			id="TopicForm"
		>
			<Field
				id="code"
				name="code"
				label="Code *"
				component={Input}
			/>
			<Field
				id="libelle"
				name="libelle"
				label="Libellé *"
				component={Input}
			/>
		</form>
	)

	render() {
		const {
			open,
			handleSubmit,
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitCreateMatiere)}
			>
				Créer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Nouveau topic"
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

NouveauTopicPopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	createTopic: PropTypes.func,
	idMatiere: PropTypes.string
}

const actions = {
	createTopic,
	refreshTable: () => dataTableActions.refresh('listeTopics')
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'TopicForm',
		initialValues: {
			libelle: '',
			code: ''
		},
		validate
	}),
	injectSnackActions
)(NouveauTopicPopup)
