import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { getStyles, StaticDataTable } from 'isotope-client'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getListeCentresPapier } from './services/centrePapierActions'
import NouveauCentrePapierPopup from './NouveauCentrePapierPopup'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'

const style = () => getStyles({
    libelleColumn: {
        fontWeight: 'bold',
        '&:hover': {
            cursor: 'pointer',
        }
    }
})

const ListeCentresPapier = ({ getListeCentresPapier, classes, goToDetails }) => {
    const [openCreation, setOpenCreation] = React.useState(false)
    const [centres, setCentres] = React.useState([])

    useEffect(() => {
        getListeCentresPapier()
            .then(({ centres }) => setCentres(centres))
    }, [])


    return (
        <React.Fragment>
            <StaticDataTable
                headers={[
                    {
                        key: 'libelle',
                        name: 'Centre',
                        sortable: false,
                        render: data => <Typography className={classes.libelleColumn}
                                                    onClick={() => goToDetails(data)}>{data.libelle}</Typography>
                    },
                    {
                        key: 'decalageMetropole',
                        name: 'Décalage horaire / métropole (hiver)',
                        sortable: false
                    },
                    {
                        key: 'heureEte',
                        name: 'Gestion heure d\'été',
                        sortable: false,
                        render: data => <>{data.heureEte ? <Typography>Oui</Typography> :
                            <Typography>Non</Typography>}</>
                    }
                ]}
                defaultPageSize={10}
                data={centres}
                nom="listeCentresPapier"
                noResultFragment={<EmptyResult />}
            />
            <FloatingButton onClick={() => setOpenCreation(true)} />
            <NouveauCentrePapierPopup open={openCreation} closePopup={() => setOpenCreation(false)} />
        </React.Fragment>
    )
}

ListeCentresPapier.propTypes = {
    getListeCentresPapier: PropTypes.func,
    goToDetails: PropTypes.func
}

const actions = {
    getListeCentresPapier,
    goToDetails: (centre) => dispatch => dispatch(push({
        pathname: `/centres/${centre.id}`,
        state: { nom: centre.libelle }
    }))
}

export default compose(
    checkAuthorities(
        PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
        PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
    ),
    connect(null, actions),
    injectSession,
    withStyles(style)
)(ListeCentresPapier)
