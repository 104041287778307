import * as api from './sessionsApi'
import { ETAT_SESSION } from '../../../../utils/consts'

export const GET_SESSIONS_LIST_REQUEST = 'GET_SESSIONS_LIST_REQUEST'
export const GET_SESSIONS_LIST_SUCCESS = 'GET_SESSIONS_LIST_SUCCESS'
export const GET_SESSIONS_LIST_ERROR = 'GET_SESSIONS_LIST_ERROR'
export const getSessionsList = (etat = ETAT_SESSION.INITIALISEE) => dispatch => {
	dispatch({ type: GET_SESSIONS_LIST_REQUEST })
	return api.getSessionsList(etat)
		.then(list => dispatch({
			type: GET_SESSIONS_LIST_SUCCESS,
			etat,
			list
		}))
		.catch(() => dispatch({ type: GET_SESSIONS_LIST_ERROR, list: [] }))
}

export const CLEAN_SESSIONS_LIST = 'CLEAN_SESSIONS_LIST'
export const cleanSessionsList = (etat = ETAT_SESSION.INITIALISEE) => dispatch => dispatch({
	type: CLEAN_SESSIONS_LIST,
	etat
})