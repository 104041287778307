import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, Popup, Select, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import {
	createAssociationFormation,
	getListeTypesFormationActive
} from './services/nouvelleAssociationFormationActions'
import { dataTableActions } from 'isotope-client'
import * as selectors from './services/nouvelleAssociationFormationSelectors'

const validate = (values) => {
	const errors = {}
	if (!values.formation) {
		errors.formation = 'Ce champ est obligatoire'
	}
	return errors
}

const CreerAssociationFormationPopup = ({
											open,
											closePopup,
											refreshTable,
											handleSubmit,
											reset,
											idExamenType,
											typesFormationActive,
											createAssociationFormationAction,
											getListeTypesFormationActiveAction
										}) => {

	React.useEffect(() => {
		if (!!idExamenType) {
			getListeTypesFormationActiveAction(idExamenType)
		}
	}, [])

	const submitCreate = (values) => createAssociationFormationAction(idExamenType, values)
		.then(() => {
			refreshTable()
			closePopup()
			// Rafraîchissement de la liste
			getListeTypesFormationActiveAction(idExamenType)
		})

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreate)}
		>
			Ajouter
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		onExited={reset}
		title="Ajouter une formation"
		content={
			<form>
				<Field
					name="formation"
					label="Liste des formations *"
					component={Select}
					displayEmpty
				>
					<MenuItem value="">Sélectionnez...</MenuItem>
					{typesFormationActive.map((value, index) => <MenuItem key={index}
																		  value={value.id}>{value.libelle}</MenuItem>)}
				</Field>
			</form>
		}
	/>
}

CreerAssociationFormationPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	idExamenType: PropTypes.number.isRequired,
	closePopup: PropTypes.func.isRequired,
	typesFormationActive: PropTypes.array.isRequired,
	deleteAssociationFormationAction: PropTypes.func,
	getListeTypesFormationActiveAction: PropTypes.func,
	refreshTable: PropTypes.func
}

CreerAssociationFormationPopup.defaultProps = {
	typesFormationActive: []
}

const mapStateToProps = (state) => ({
	typesFormationActive: selectors.getListeTypesFormationActive(state)
})

const actions = {
	createAssociationFormationAction: createAssociationFormation,
	getListeTypesFormationActiveAction: getListeTypesFormationActive,
	refreshTable: () => dataTableActions.refresh('typesFormations')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'nouvelleAssociationFormationForm',
		validate
	}),
	injectFormSnackbar()
)(CreerAssociationFormationPopup)
