import * as api from './nouvelOrganismePopupApi'

export const OPEN_POPUP = 'NOUVEL_ORGANISME/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'NOUVEL_ORGANISME/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const ADD_ORGANISME_REQUEST = 'ADD_ORGANISME_REQUEST'
export const ADD_ORGANISME_SUCCESS = 'ADD_ORGANISME_SUCCESS'
export const ADD_ORGANISME_ERROR = 'ADD_ORGANISME_ERROR'
export const createOrganisme = (values) => dispatch => {
	dispatch({ type: ADD_ORGANISME_REQUEST })
	return api.createOrganisme(values)
		.then((newOganisme) =>
			dispatch({
				type: ADD_ORGANISME_SUCCESS,
				payload: newOganisme
			})
		)
		.catch((e) => {
			dispatch({
				type: ADD_ORGANISME_ERROR,
				e
			})
			throw e
		})
}

export const SET_DETAIL_ORGANISME_REQUEST = 'SET_DETAIL_ORGANISME_REQUEST'
export const SET_DETAIL_ORGANISME_SUCCESS = 'SET_DETAIL_ORGANISME_SUCCESS'
export const SET_DETAIL_ORGANISME_ERROR = 'SET_DETAIL_ORGANISME_ERROR'
export const getOrganisme = (id) => dispatch => {
	dispatch({ type: SET_DETAIL_ORGANISME_REQUEST })
	return api.getOrganisme(id)
		.then((organisme) => {
			dispatch({
				type: SET_DETAIL_ORGANISME_SUCCESS,
				payload: organisme
			})
		})
		.catch((e) => {
			dispatch({
				type: SET_DETAIL_ORGANISME_ERROR,
				e
			})
			throw e
		})
}
