import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ParagrapheItem from './ParagrapheItem'
import * as shapes from '../../../../config/shapes'


const ListeParagraphes = ({ paragraphes, editable, openPopup }) => {
	return (
		<Grid container direction="column">
			{React.Children.toArray(paragraphes.map(paragraphe => <ParagrapheItem
				paragraphe={paragraphe}
				editable={editable}
				openPopup={openPopup}
			/>))}
		</Grid>
	)
}


ListeParagraphes.propTypes = {
	topics: PropTypes.arrayOf(shapes.paragrapheShape),
	editable: PropTypes.bool,
	openPopup: PropTypes.func
}

export default ListeParagraphes
