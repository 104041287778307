import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { StaticDataTable } from 'isotope-client'
import { EmptyResult, Link } from '@oceane/ui'
import { FormattedMessage } from 'react-intl'
import * as selectors from './service/correctionCopiesSelectors'
import { ELS_ETAT_EPREUVE_SESSION } from '../../../config/enums'
import { epreuveSessionShape } from '../../../config/shapes'

const EpreuvesTable = ({ epreuves }) => {
	return (
		<StaticDataTable
			data={epreuves}
			headers={[{
				key: 'libelle',
				name: 'Epreuve',
				render: row => {
					if (row.etat === ELS_ETAT_EPREUVE_SESSION.SUJET_VALIDE) {
						return <Link to={`/correction-copies/epreuve/${row.id}`}>
							{row.libelleEpreuveType}
						</Link>
					}
					return row.libelleEpreuveType
				},
				sortable: false
			}, {
				key: 'etat',
				name: 'Etat',
				render: row => <FormattedMessage id={`references.sessions.elsEtatEpreuveSession.${row.etat}`}/>
			}]}
			noPagination
			noResultFragment={<EmptyResult />}
		/>
	)
}

EpreuvesTable.propTypes = {
	epreuves: PropTypes.arrayOf(epreuveSessionShape)
}

const mapStateToProps = (state) => ({
	epreuves: selectors.getListeEpreuves(state)
})


export default connect(mapStateToProps)(EpreuvesTable)
