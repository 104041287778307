import { createSelector } from 'reselect'
import { PLANNING_QUOTIDIEN_FILTRE_FORM_NAME } from '../PlanningQuotidienSiteEcran'
import { formValueSelector } from 'redux-form'

const getLocalState = state => state.sitesEcran

const getPlanning = createSelector(
	getLocalState,
	state => state.planning
)

const selectFiltre = state => formValueSelector(PLANNING_QUOTIDIEN_FILTRE_FORM_NAME)(state, 'filtre')

export const getPlanningFiltre = createSelector(
	getPlanning,
	selectFiltre,
	(planning, filtre) => filtre ? planning.filter(ligne =>
		String(ligne.numeroInscription).startsWith(filtre) ||
		ligne.nomCandidat.toLowerCase().startsWith(filtre) ||
		ligne.libelleEpreuve.toLowerCase().startsWith(filtre)
	) : planning
)

const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)

export const getSwitchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.switch
)