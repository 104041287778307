import React from 'react'
import PropTypes from 'prop-types'
import { StaticDataTable, Title, getStyles } from 'isotope-client'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as selectors from '../services/statistiquesQuestionsSelectors'
import { questionLightShape } from '../../../config/shapes'
import ImprimerStatistiquesQuestions from './ImprimerStatistiquesQuestions'
import Link from 'isotope-client/components/download/IsotopeLink'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = () => getStyles({
	link: {
		cursor: 'pointer',
		fontWeight: 'bold'
	}
})

const StatistiquesQuestionsTable = ({ data, classes }) => {
	const hasResults = !!data && data.length !== 0
	const headers = [{
		key: 'idOceane',
		name: 'ID',
		sortable: true,
		render: value => <Link href={`/files/statistiques/questions/${value.idOceane}`}>
			<Typography className={classes.link} component="span">{value.idOceane}</Typography>
		</Link>
	}, {
		key: 'numeroEuropeen',
		name: 'N° européen',
		sortable: true
	}, {
		key: 'ligneDeProgramme',
		name: 'Ligne programme',
		sortable: true
	}, {
		key: 'occurenceVersionBanque',
		name: 'Occurence',
		sortable: true
	}, {
		key: 'pourcentageReponseJuste',
		name: 'RJ%',
		sortable: true,
		render: value => value.pourcentageReponseJuste.toFixed(1)
	}, {
		key: 'pourcentageReponseFausse1',
		name: 'F1%',
		sortable: true,
		render: value => value.pourcentageReponseFausse1.toFixed(1)

	}, {
		key: 'pourcentageReponseFausse2',
		name: 'F2%',
		sortable: true,
		render: value => value.pourcentageReponseFausse2.toFixed(1)

	}, {
		key: 'pourcentageReponseFausse3',
		name: 'F3%',
		sortable: true,
		render: value => value.pourcentageReponseFausse3.toFixed(1)
	}]

	return <React.Fragment>
		{hasResults && <Title value="Résultat de recherche" />}
		<StaticDataTable
			data={data}
			headers={headers}
			noResultFragment={<React.Fragment />}
		/>
		{hasResults && <ImprimerStatistiquesQuestions />}
	</React.Fragment>
}

StatistiquesQuestionsTable.propTypes = {
	date: PropTypes.arrayOf(questionLightShape),
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	data: selectors.getListeQuestions(state)
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(StatistiquesQuestionsTable)
