import React from 'react'
import { StyledTypography, DownloadButton, formatQuery } from '@oceane/ui'
import { FormattedMessage } from 'react-intl'
import SearchIcon from '@material-ui/icons/Search'
import { BNQ_ID_ORIGINE_QUESTION } from '../../../../config/enums'
import { download } from 'isotope-client/components/download/IsotopeLink'

export const getFilArianeQuestion = ({ idOceane, numeroEuropeen, etat, aexpertiser, origine }, profil) => ({
	title: 'Détail question',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography bold>N° {idOceane}</StyledTypography>
				{origine === BNQ_ID_ORIGINE_QUESTION.EUROPEENNE && <StyledTypography bold> / N° européen {numeroEuropeen}</StyledTypography>}
				<StyledTypography primary bold><FormattedMessage id={`enums.bnqIdEtatQuestion.${etat}`}/></StyledTypography>
				{aexpertiser && <SearchIcon />}
			</>
		}],
		actions: <DownloadButton
			onClick={() => download(`/files/questions/fiches?${formatQuery({ idOceane, profil })}`)}
			title="Imprimer"
		/>,
	}
})
