import { Input, Popup, Button, DateInput, Select, isPrenomOuNomOk, isDateOk } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import { createSurveillant } from '../services/surveillantsActions'
import moment from 'moment'
import { push } from 'connected-react-router'

const validate = (values) => {
    const errors = {}

    if (!values.civilite) {
        errors.civilite = { id: 'global.erreurs.obligatoire' }
    }

    if (!values.nom) {
        errors.nom = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isPrenomOuNomOk(values.nom)) {
            errors.nom = { id: 'global.erreurs.nom' }
        }
    }

    if (!values.prenom) {
        errors.prenom = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isPrenomOuNomOk(values.prenom)) {
            errors.prenom = { id: 'global.erreurs.nom' }
        }
    }

    if (!values.dateDeNaissance) {
        errors.dateDeNaissance = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isDateOk(moment(values.dateDeNaissance, 'YYYY-MM-DD').format('DD/MM/YYYY'))) {
            errors.dateDeNaissance = { id: 'global.erreurs.date.invalid' }
        }
    }

    return errors
}

const NouveauSurveillantPopup = ({
                                     handleSubmit,
                                     open,
                                     reset,
                                     createSurveillant,
                                     snackSuccess,
                                     goToEtatCivil,
                                     closePopupCreation,
                                 }) => {

    const submitCreateSurveillant = (values) =>
        createSurveillant(values)
            .then(({ surveillant }) => {
                snackSuccess({ id: 'references.succes' })
                goToEtatCivil(surveillant)
                closePopupCreation()
            })
            .catch(e => {
                throw new SubmissionError(e)
            })

    const closePopup = () => {
        closePopupCreation()
    }

    const popupContent = () => {
        return (
            <React.Fragment>
                <form
                    id="NouveauSurveillantForm"
                >
                    <Field
                        id="civilite"
                        name="civilite"
                        label="Civilité *"
                        component={Select}
                    >
                        <MenuItem key={1} value={1}>
                            M
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            Mme
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                            Mlle
                        </MenuItem>
                    </Field>
                    <Field
                        id="nom"
                        name="nom"
                        label="Nom *"
                        component={Input}
                    />
                    <Field
                        id="prenom"
                        name="prenom"
                        label="Prénom *"
                        component={Input}
                    />
                    <Field
                        id="dateDeNaissance"
                        name="dateDeNaissance"
                        label="Date de naissance *"
                        component={DateInput}
                    />
                </form>
            </React.Fragment>
        )
    }

    const actions = [
        <Button key="close" color="primary" onClick={closePopup}>
            Annuler
        </Button>,
        <Button
            key="save"
            color="primary"
            variant="contained"
            onClick={handleSubmit(submitCreateSurveillant)}
        >
            Créer
        </Button>
    ]

    return (
        <Popup
            open={open}
            actions={actions}
            title="Nouveau surveillant"
            content={popupContent()}
            onExited={reset}
        />
    )
}

NouveauSurveillantPopup.propTypes = {
    open: PropTypes.bool,
    closePopupCreation: PropTypes.func,
    createSurveillant: PropTypes.func,
    goToEtatCivil: PropTypes.func
}

const actions = {
    createSurveillant,
    goToEtatCivil: (surveillant) => dispatch => dispatch(push({
        pathname: `/surveillants/${surveillant.id}`,
        state: { nom: surveillant.nom, civilite: surveillant.civilite, prenom: surveillant.prenom }
    }))
}

export default compose(
    connect(null, actions),
    reduxForm({
        form: 'NouveauSurveillantForm',
        initialValues: {
            civilite: '',
            nom: '',
            prenom: '',
            dateDeNaissance: ''
        },
        validate
    }),
    injectSnackActions
)(NouveauSurveillantPopup)
