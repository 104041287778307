import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getCentrePapier } from './centrePapierActions'

export const loadCentre = (WrappedComponent) => {
	const CentreInjector = (props) => {
		const { getCentrePapier, ...otherProps } = props

		const match = otherProps.match

		const [centre, setCentre] = React.useState({})
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			if (match && match.params) {
				setLoading(true)
				getCentrePapier(match.params.id)
					.then(({ centre }) => {
						setCentre(centre)
						setLoading(false)
					})
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent centre={centre} {...otherProps} />
	}

	CentreInjector.propTypes = {
		getCentre: PropTypes.func,
		match: PropTypes.object,
		snackError: PropTypes.func
	}

	const actions = {
		getCentrePapier
	}

	return compose(
		connect(null, actions),
		injectSnackActions
	)(CentreInjector)
}