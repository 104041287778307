import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Button, Popup } from '@oceane/ui'
import MuiButton from '@material-ui/core/Button'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { supprimerImport } from '../service/importKSAActions'
import * as shapes from '../../../../config/shapes'

const SuppressionImportKSAPopup = ({ open, closePopup, onExited, reloadTables, importASupprimer, supprimerImport, snackSuccess, snackError }) => {

	const [submitting, setSubmitting] = React.useState(false)

	const supprimer = () => {
		setSubmitting(true)
		return supprimerImport(importASupprimer.id)
			.then(() => {
				snackSuccess(`L'import des questions de la matière ${importASupprimer.matiere} a bien été supprimé.`)
				setSubmitting(false)
				reloadTables()
				closePopup()
			})
			.catch(() => {
				snackError()
				setSubmitting(false)
			})
	}

	const actions = [
		<MuiButton key="close" color="primary" onClick={closePopup}>
			Annuler
		</MuiButton>,
		<Button
			key="valider"
			variant="contained"
			onClick={supprimer}
			loading={submitting}
		>
			Supprimer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			onExited={onExited}
			title="Suppression de la programmation d'import"
			content={<span>Supprimer la programmation d'import des questions de la matière {importASupprimer.matiere}&nbsp;?</span>}
		/>
	)
}

SuppressionImportKSAPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	onExited: PropTypes.func.isRequired,
	reloadTables: PropTypes.func.isRequired,
	importASupprimer: shapes.importKSAShape,
	supprimerImport: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func
}

const actions = {
	supprimerImport
}

export default compose(
	connect(undefined, actions),
	injectSnackActions
)(SuppressionImportKSAPopup)
