import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import {
	checkAuthorities,
	EmptyResult, Error,
	FloatingButton,
	formatQuery,
	injectSession,
	Link,
	Loader,
	PROFIL_UTILISATEUR,
	SpringDataTableWithLoader
} from '@oceane/ui'
import { getStyles, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { BNQ_ID_ETAT_QUESTION } from '../../../../config/enums'
import { getListeMatieres } from '../../../references/matieres/services/matieresApi'
import CreationQuestionPopup from '../creation/CreationQuestionPopup'
import { DATATABLE_NAME } from '../services/banqueQuestionsActions'
import AccesDirect from './components/AccesDirect'
import QuestionsFiltres from './components/QuestionsFiltres'

const style = () => getStyles({
	container: {
		marginBottom: 75
	}
})

const OPTION_IMPRESSION = [
	{ type: 1, sort: 1, label: 'Imprimer les fiches par Id' },
	{ type: 1, sort: 2, label: 'Imprimer les fiches par N°européen' },
	{ type: 1, sort: 3, label: 'Imprimer les fiches par Famille' },
	{ type: 1, sort: 4, label: 'Imprimer les fiches par Ligne de programme' },
	{ type: 1, sort: 5, label: 'Imprimer les fiches par Etat' },
	{ type: 2, sort: 1, label: 'Imprimer la liste par Id' },
	{ type: 2, sort: 2, label: 'Imprimer la liste par N°européen' },
	{ type: 2, sort: 3, label: 'Imprimer la liste par Famille' },
	{ type: 2, sort: 4, label: 'Imprimer la liste par Ligne de programme' },
	{ type: 2, sort: 5, label: 'Imprimer la liste par Etat' }
]

const RechercheQuestion = ({ profil, classes }) => {
	const [filters, setFilters] = React.useState(undefined)
	const [anchorEl, setAnchorEl] = React.useState(undefined)
	const [openPopupCreation, setOpenPopupCreation] = React.useState(false)
	const [showForm, setShowForm] = React.useState(true)
	const [matieres, setMatieres] = React.useState(-1)
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		getListeMatieres(profil).then(result => {
			setMatieres(result.totalElements)
			setLoading(false)
		})
	}, [])

	const imprimerQuestions = (type, sort) => {
		if (type === 1) {
			download(`/files/questions/fiches?${formatQuery({ ...filters, profil, sort })}`)
		} else {
			download(`/files/questions/liste?${formatQuery({ ...filters, profil, sort })}`)
		}
		setAnchorEl(null)
	}

	if (matieres === 0) {
		return (
			<Error error={['Aucune matière ne vous est affectée. Merci de contacter l\'administrateur pour paramétrer vos droits']} />
		)
	}

	if (loading) {
		return (<Loader />)
	}

	return (<div className={classes.container}>
		<Title value="Accès direct" />
		<AccesDirect setFiltres={setFilters} closeForm={() => setShowForm(false)}/>

		<Title value="Recherche de questions" />
		<QuestionsFiltres setFilters={setFilters} setAnchorEl={setAnchorEl} showForm={showForm} setShowForm={setShowForm} />
		{!!filters && <SpringDataTableWithLoader
			apiUrl="/questions"
			nom={DATATABLE_NAME}
			headers={[
				{
					key: 'idOceane',
					name: 'Id',
					render: row => <Link
						to={`/${row.etat === BNQ_ID_ETAT_QUESTION.EXPLOITEE ? 'questionsExploitation' : 'questions'}/${row.id}`}>{`${row.idOceane} `}</Link>,
					sortable: true
				},
				{
					key: 'numeroEuropeen',
					name: 'N°Eur',
					sortable: true
				},
				{
					key: 'ligneProgramme',
					name: 'Ligne Programme',
					sortable: true
				},
				{
					key: 'etat',
					name: 'Etat',
					render: row => <FormattedMessage id={`banqueQuestions.etat.${row.etat}`} />,
					sortable: false
				},
				{
					key: 'nbAnnexes',
					name: 'Annexes',
					sortable: true,
					tableHeaderColumnProps: { style: { textAlign: 'center' } }
				},
				{
					key: 'famille',
					name: 'Famille',
					sortable: true
				},
				{
					key: 'visaRdb',
					name: 'Visa RDB',
					sortable: true
				},
				{
					key: 'texteFrancais',
					name: 'Texte français',
					sortable: true,
					render: row => {
						if (row.texteFrancais) {
							if (row.texteFrancais.length > 100) {
								return `${row.texteFrancais.substring(0, 99)}...`
							}
							return row.texteFrancais
						}
						return ''
					}
				}
			]}
			filters={filters}
			noResultFragment={<EmptyResult />}
		/>}
		<Menu
			id="optionImpression"
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
		>
			{OPTION_IMPRESSION.map(impression => <MenuItem key={`impression-${impression.type}-${impression.sort}`}
			                                               onClick={() => imprimerQuestions(impression.type, impression.sort)}>{impression.label}</MenuItem>)}
		</Menu>
		<FloatingButton onClick={() => setOpenPopupCreation(true)} />
		<CreationQuestionPopup open={openPopupCreation} closePopup={() => setOpenPopupCreation(false)} />
	</div>)

}

RechercheQuestion.propTypes = {}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
		PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE
	),
	withStyles(style),
	injectSession
)(RechercheQuestion)
