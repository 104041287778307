import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'isotope-client'
import { MultiSelect } from '@oceane/ui'
import { UTILISATEUR_FORM_NAME } from '../services/administrationValues'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as adminSelectors from '../services/administrationSelectors'
import { injectListeMatieres } from '../../references/matieres/services/injectListeMatieres'
import * as matieresSelectors from '../../references/matieres/services/matieresSelectors'
import { injectListExamensType } from '../../references/examensType/services/examenTypeInjector'
import * as examensSelectors from '../../references/examensType/services/examensTypesSelectors'

const GestionnaireBanqueForm = ({ listeMatieres, listeExamensTypes }) => {
	return (
		<React.Fragment>
			<Title value="Matières" />
			<Field
				name="rdbMatieres"
				label=""
				component={MultiSelect}
				choices={listeMatieres}
			/>
			<Title value="Examens types" />
			<Field
				name="examensType"
				label=""
				component={MultiSelect}
				choices={listeExamensTypes}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const utilisateur = adminSelectors.getUtilisateur(state)
	const listeMatieres = matieresSelectors.getListeMatieres(state).map(matiere => ({ id: matiere.id, libelle: `${matiere.code} ${matiere.libelle}` }))
	const listeExamensTypes = examensSelectors.getListeExamensTypes(state).map(examen => ({ id: examen.id, libelle: examen.libelleCourt }))

	return {
		listeMatieres,
		listeExamensTypes,
		initialValues: {
			rdbMatieres: listeMatieres.filter(matiere => utilisateur.rdbMatieres && utilisateur.rdbMatieres.includes(matiere.id)),
			examensType: utilisateur.examensType && utilisateur.examensType.map(examen => ({ id: examen.id, libelle: examen.libelleCourt }))
		}
	}
}

GestionnaireBanqueForm.propTypes = {
	listeMatieres: PropTypes.array,
	listeExamensTypes: PropTypes.array
}

export default compose(
	injectListeMatieres,
	injectListExamensType(true),
	connect(mapStateToProps),
	reduxForm({
		form: UTILISATEUR_FORM_NAME,
		enableReinitialize: true
	})
)(GestionnaireBanqueForm)
