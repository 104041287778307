import { Input, Popup } from '@oceane/ui'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { dataTableActions } from 'isotope-client'
import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import Button from '@material-ui/core/Button'
import { closePopup, createExaminateurEtranger } from './services/examinateurEtrangerActions'
import * as selectors from './services/examinateurEtrangerSelectors'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const validate = (values) => {
	const errors = {}

	if (!values.nom) {
		errors.nom = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.prenom) {
		errors.prenom = { id: 'global.erreurs.obligatoire' }
	}


	return errors
}

class NouvelExaminateurEtranger extends Component {

	submitCreateExaminateur = (values) => {
		const {
			closePopup,
			createExaminateurEtranger,
			snackSuccess,
			snackError,
			refreshTable,
			goToDetail
		} = this.props

		return createExaminateurEtranger(values)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopup()
			})
			.catch(e => {
				if (e.bodyError && e.bodyError.id === 'references.examinateurEtranger.erreurs.nomPrenomExaminateurEtrangerExistant') {
					goToDetail(e.bodyError.values.id, { error: e.bodyError })
				} else {
					snackError()
				}
			})
	}

	closePopup = () => {
		const {
			closePopup,
			reset
		} = this.props

		reset()
		closePopup()
	}

	popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="examinateurEtrangerForm"
				>
					<Field
						id="nom"
						name="nom"
						label={<FormattedMessage id="references.examinateurEtranger.form.nom"/>}
						component={Input}
						inputProps={{
							maxLength: 50
						}}
					/>
					<Field
						id="prenom"
						name="prenom"
						label={<FormattedMessage id="references.examinateurEtranger.form.prenom"/>}
						component={Input}
						inputProps={{
							maxLength: 50
						}}
					/>
					<Field
						id="contact"
						name="contact"
						label={<FormattedMessage id="references.examinateurEtranger.contact"/>}
						component={Input}
						inputProps={{
							maxLength: 128
						}}
					/>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			handleSubmit
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitCreateExaminateur)}
			>
				Créer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title={<FormattedMessage id='references.examinateurEtranger.titrePopup'/>}
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

NouvelExaminateurEtranger.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	createExaminateurEtranger: PropTypes.func.isRequired,
	refreshTable: PropTypes.func.isRequired,
	goToDetail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state)
})

const actions = {
	closePopup,
	createExaminateurEtranger,
	refreshTable: () => dataTableActions.refresh('examinateursEtrangers'),
	goToDetail: (id, error) => dispatch => {
		dispatch(push({
			pathname: `/examinateurs-etrangers/${id}`,
			state: error
		}))
	}
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'nouvelExaminateurEtranger',
		validate
	}),
	injectSnackActions
)(NouvelExaminateurEtranger)
