import * as api from './surveillantsApi'

export const POST_RECHERCHE_REQUEST = 'POST_RECHERCHE_REQUEST'
export const POST_RECHERCHE_SUCCESS = 'POST_RECHERCHE_SUCCESS'
export const POST_RECHERCHE_ERROR = 'POST_RECHERCHE_ERROR'
export const rechercher = (values) => dispatch => {
    dispatch({ type: POST_RECHERCHE_REQUEST })

    return api.rechercher(values)
        .then((response) => dispatch({ type: POST_RECHERCHE_SUCCESS, response }))
        .catch(e => {
            dispatch({ type: POST_RECHERCHE_ERROR })
            throw e
        })
}

export const CREATE_SURVEILLANT_REQUEST = 'CREATE_SURVEILLANT_REQUEST'
export const CREATE_SURVEILLANT_SUCCESS = 'CREATE_SURVEILLANT_SUCCESS'
export const CREATE_SURVEILLANT_ERROR = 'CREATE_SURVEILLANT_ERROR'
export const createSurveillant = (values) => dispatch => {
    dispatch({ type: CREATE_SURVEILLANT_REQUEST })

    return api.createSurveillant(values)
        .then((surveillant) => dispatch({ type: CREATE_SURVEILLANT_SUCCESS, surveillant }))
        .catch(e => {
            dispatch({ type: CREATE_SURVEILLANT_ERROR })
            throw e
        })
}

export const GET_SURVEILLANT_REQUEST = 'GET_SURVEILLANT_REQUEST'
export const GET_SURVEILLANT_SUCCESS = 'GET_SURVEILLANT_SUCCESS'
export const GET_SURVEILLANT_ERROR = 'GET_SURVEILLANT_ERROR'
export const getSurveillant = (id) => dispatch => {
    dispatch({ type: GET_SURVEILLANT_REQUEST })

    return api.getSurveillant(id)
        .then((surveillant) => dispatch({ type: GET_SURVEILLANT_SUCCESS, surveillant }))
        .catch(e => {
            dispatch({ type: GET_SURVEILLANT_ERROR })
            throw e
        })
}

export const SUPPRIMER_SURVEILLANT_REQUEST = 'SUPPRIMER_SURVEILLANT_REQUEST'
export const SUPPRIMER_SURVEILLANT_SUCCESS = 'SUPPRIMER_SURVEILLANT_SUCCESS'
export const SUPPRIMER_SURVEILLANT_ERROR = 'SUPPRIMER_SURVEILLANT_ERROR'
export const supprimerSurveillant = (id) => dispatch => {
    dispatch({ type: SUPPRIMER_SURVEILLANT_REQUEST })

    return api.supprimerSurveillant(id)
        .then(() => dispatch({ type: SUPPRIMER_SURVEILLANT_SUCCESS }))
        .catch(e => {
            dispatch({ type: SUPPRIMER_SURVEILLANT_ERROR })
            throw e
        })
}

export const ENREGISTRER_SURVEILLANT_REQUEST = 'ENREGISTRER_SURVEILLANT_REQUEST'
export const ENREGISTRER_SURVEILLANT_SUCCESS = 'ENREGISTRERR_SURVEILLANT_SUCCESS'
export const ENREGISTRER_SURVEILLANT_ERROR = 'ENREGISTRER_SURVEILLANT_ERROR'
export const enregistrerSurveillant = (values) => dispatch => {
    dispatch({ type: ENREGISTRER_SURVEILLANT_REQUEST })

    return api.enregistrerSurveillant(values)
        .then(() => dispatch({ type: ENREGISTRER_SURVEILLANT_SUCCESS }))
        .catch(e => {
            dispatch({ type: ENREGISTRER_SURVEILLANT_ERROR })
            throw e
        })
}

export const GET_SURVEILLANT_CENTRES_REQUEST = 'GET_SURVEILLANT_CENTRES_REQUEST'
export const GET_SURVEILLANT_CENTRES_SUCCESS = 'GET_SURVEILLANT_CENTRES_SUCCESS'
export const GET_SURVEILLANT_CENTRES_ERROR = 'GET_SURVEILLANT_CENTRES_ERROR'
export const getSurveillantCentres = (id) => dispatch => {
    dispatch({ type: GET_SURVEILLANT_CENTRES_REQUEST })

    return api.getSurveillantCentres(id)
        .then((surveillant) => dispatch({ type: GET_SURVEILLANT_CENTRES_SUCCESS, surveillant }))
        .catch(e => {
            dispatch({ type: GET_SURVEILLANT_CENTRES_ERROR })
            throw e
        })
}

export const ENREGISTRER_SURVEILLANT_CENTRE_REQUEST = 'ENREGISTRER_SURVEILLANT_CENTRE_REQUEST'
export const ENREGISTRER_SURVEILLANT_CENTRE_SUCCESS = 'ENREGISTRERR_SURVEILLANT_CENTRE_SUCCESS'
export const ENREGISTRER_SURVEILLANT_CENTRE_ERROR = 'ENREGISTRER_SURVEILLANT_CENTRE_ERROR'
export const enregistrerCentres = (values, id) => dispatch => {
    dispatch({ type: ENREGISTRER_SURVEILLANT_CENTRE_REQUEST })

    return api.enregistrerCentres(values, id)
        .then(() => dispatch({ type: ENREGISTRER_SURVEILLANT_CENTRE_SUCCESS }))
        .catch(e => {
            dispatch({ type: ENREGISTRER_SURVEILLANT_CENTRE_ERROR })
            throw e
        })
}
