import { Actions, Button, DateInput, Error, InputRadio, ResponsiveForm } from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { TYPE_STATISTIQUES_CANDIDATS } from '../../../../config/enums'

const validate = values => {
	const errors = {}
	if (!values.debutPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.finPeriode) {
		errors.finPeriode = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const StatistiquesCandidatForm = ({
	                                  submitting,
	                                  handleSubmit,
	                                  error
                                  }) => {

	return (
		<React.Fragment>
			<Error error={error} />
			<ResponsiveForm onSubmit={handleSubmit}>
				<Field
					id="debutPeriode"
					name="debutPeriode"
					label="Début période *"
					component={DateInput}
				/>
				<Field
					id="finPeriode"
					name="finPeriode"
					label="Fin période *"
					component={DateInput}
				/>
				<Field
					id="type"
					name="type"
					label="Type de statistique *"
					component={InputRadio}
					choices={Object.keys(TYPE_STATISTIQUES_CANDIDATS).map(type => {
						return {
							value: type,
							label: TYPE_STATISTIQUES_CANDIDATS[type]
						}
					})}
				/>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						type="submit"
					>
						Exporter
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

StatistiquesCandidatForm.propTypes = {
	submitting: PropTypes.bool,
	error: PropTypes.array,
	handleSubmit: PropTypes.func
}

export default compose(
	reduxForm({
		form: 'ConsulterStatistiquesCandidatsForm',
		initialValues: {
			debutPeriode: '',
			finPeriode: '',
			type: '4'
		},
		validate,
		enableReinitialize: true
	})
)(StatistiquesCandidatForm)
