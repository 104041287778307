import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { Actions, Button, DateInput, examenTypeShape, ResponsiveForm, Select, SmallSelect } from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import { injectListExamensType } from '../../references/examensType/services/examenTypeInjector'
import * as examensTypesSelectors from '../../references/examensType/services/examensTypesSelectors'
import { injectListeMatieres } from '../../references/matieres/services/injectListeMatieres'
import { getListeQuestions } from '../services/statistiquesQuestionsActions'
import { getStyles, Title } from 'isotope-client'
import { matiereReferenceShape } from '../../../config/shapes'

const styles = () => getStyles({
	grid: {
		paddingRight: 20
	},
	inputMatiere: {
		width: 120
	},
	inputExamenType: {
		width: 200
	},
	inputDate: {
		width: 250
	}
})

const validate = values => {
	const errors = {}
	if (!values.matiere) {
		errors.matiere = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.examenType) {
		errors.examenType = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.debutPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.finPeriode) {
		errors.finPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (values.debutPeriode > values.finPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.date.dateDebutSuperieureDateFin' }
	}
	return errors
}

const StatistiquesQuestionsFiltres = ({ listeExamens, listeMatieres, submitting, handleSubmit, classes }) => (
	<ResponsiveForm onSubmit={handleSubmit}>
		<Title value="Critères" />
		<Grid container alignItems="flex-end">
			<Grid item className={classes.grid} xs={2}>
				<Field
					name="matiere"
					label="Matière *"
					component={SmallSelect}
					inputStyle={classes.inputMatiere}
				>
					{listeMatieres.map(matiere => (
						<MenuItem
							key={matiere.id}
							value={matiere.id}
						>
							{matiere.code}
						</MenuItem>
					))}
				</Field>
			</Grid>
			<Grid item className={classes.grid} xs={3}>
				<Field
					name="examenType"
					label="Examen Type *"
					component={Select}
					inputStyle={classes.inputExamenType}
				>
					{listeExamens.map(examenType => (
						<MenuItem
							key={examenType.id}
							value={examenType.id}
						>
							{examenType.libelleCourt}
						</MenuItem>
					))}
				</Field>
			</Grid>
			<Grid item container direction="column" alignItems="flex-start">
				<Grid item container direction="row" justify="flex-start" alignItems="flex-start">
					<Grid item className={classes.grid}>
						<Field
							name="debutPeriode"
							label="Début période de mise en production *"
							component={DateInput}
							inputStyle={classes.inputDate}
						/>
					</Grid>
					<Grid item className={classes.grid}>
						<Field
							name="finPeriode"
							label="Fin période de mise en production *"
							component={DateInput}
							inputStyle={classes.inputDate}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Actions>
				<Button
					variant="contained"
					type="submit"
					loading={submitting}
				>
					Rechercher
				</Button>
			</Actions>
		</Grid>
	</ResponsiveForm>
)

StatistiquesQuestionsFiltres.propTypes = {
	listeExamens: PropTypes.arrayOf(examenTypeShape),
	listeMatieres: PropTypes.arrayOf(matiereReferenceShape),
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	listeExamens: examensTypesSelectors.getListeExamensTypesExploites(state)
})

const actions = {
	onSubmit: getListeQuestions
}

export default compose(
	withStyles(styles),
	injectListExamensType(),
	injectListeMatieres,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'statistiquesQuestionsFiltres',
		validate
	})
)(StatistiquesQuestionsFiltres)
