import Typography from '@material-ui/core/Typography'
import { Button, Error, Popup } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'

const SupprimerSousParagraphePopup = ({ open, onSubmit, closePopupSuppression }) => {

	const [error, setError] = React.useState()

	const popupContent = () => {
		return (
			<React.Fragment>
				{error && <Error error={error} isPopinContainer />}
				<Typography>
					La suppression de ce sous paragraphe entraînera la suppression de toutes les questions qui lui sont rattachées
				</Typography>
			</React.Fragment>
		)
	}

	const submit = () => {
		onSubmit().catch(setError)
	}

	const actions = [
		<Button
			color="primary"
			onClick={closePopupSuppression}
			key="fermer"
		>
			Annuler
		</Button>,
		<Button
			type="submit"
			color="primary"
			variant="contained"
			onClick={submit}
			key="valider"
		>
			Supprimer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Suppression du sous paragraphe"
			content={popupContent()}
		/>
	)
}

SupprimerSousParagraphePopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	onSubmit: PropTypes.func.isRequired
}

export default (SupprimerSousParagraphePopup)
