import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { getStyles, Title } from 'isotope-client'
import { InputDisabled } from '@oceane/ui'
import { injectComparatifImport, setExploitationQuestion } from './services/BanqueQuestionsInjectors'
import * as selectors from './services/banqueQuestionsSelectors'
import ImportExamensTable from './components/ImportExamensTable'

const styles = () => getStyles({
	tableContainer: {
		padding: 2
	}
})

const renderTexte = (texte) => texte && <span dangerouslySetInnerHTML={{ __html: texte.replace(/(?:\r\n|\r|\n)/g, '<br />')}} />

const OngletImport = ({ comparatifImport: { questionNMoins1, questionN }, classes }) => (
	<>
		<Grid container alignItems="flex-start" spacing={16}>
			<Grid item xs={6}>
				<Title value={`Version N-1 (${!!questionNMoins1 ? questionNMoins1.numeroVersion : ''})`} />
			</Grid>
			<Grid item xs={6}>
				<Title value={`Version N (${!!questionN ? questionN.numeroVersion : ''})`} />
			</Grid>
		</Grid>
		<Grid container alignItems="flex-start" spacing={16}>
			<Grid item xs={6}>
				<InputDisabled
					label="Ligne de programme"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.ligneProgramme) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Ligne de programme"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.ligneProgramme) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Texte (VA)"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.texte) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Texte (VA)"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.texte) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse juste (VA)"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.reponseJuste) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse juste (VA)"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.reponseJuste) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 1 (VA)"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.reponseFausse1) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 1 (VA)"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.reponseFausse1) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 2 (VA)"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.reponseFausse2) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 2 (VA)"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.reponseFausse2) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 3 (VA)"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.reponseFausse3) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Réponse fausse 3 (VA)"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.reponseFausse3) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Justificatif"
					fullWidth
				>
					{!!questionNMoins1 ? renderTexte(questionNMoins1.justificatif) : ''}
				</InputDisabled>
			</Grid>
			<Grid item xs={6}>
				<InputDisabled
					label="Justificatif"
					fullWidth
				>
					{!!questionN ? renderTexte(questionN.justificatif) : ''}
				</InputDisabled>
			</Grid>
		</Grid>
		<Grid container alignItems="flex-start" spacing={16}>
			<Grid item xs={6}>
				<Title value="Examens version N-1" />
			</Grid>
			<Grid item xs={6}>
				<Title value="Examens version N" />
			</Grid>
		</Grid>
		<Grid container direction="row" alignItems="flex-start" spacing={16}>
			<Grid item xs={6} className={classes.tableContainer}>
				{!!questionNMoins1 && <ImportExamensTable data={questionNMoins1.listeExamens} />}
			</Grid>
			<Grid item xs={6} className={classes.tableContainer}>
				{!!questionN && <ImportExamensTable data={questionN.listeExamens} />}
			</Grid>
		</Grid>
	</>
)

OngletImport.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = (state) => ({
	comparatifImport: selectors.getQuestionComparatifImport(state)
})

export default compose(
	setExploitationQuestion(false),
	injectComparatifImport,
	withStyles(styles),
	connect(mapStateToProps)
)(OngletImport)
