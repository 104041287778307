import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MuiButton from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import { Button, Error, Input, Popup, Select, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { push } from 'connected-react-router'
import { creerExamenType } from './services/examensTypesActions'

const validate = values => {
	const errors = {}
	if (!values.libelleCourt) {
		errors.libelleCourt = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const CreerExamenTypePopup = ({ open, closePopup, creerExamenType, goToProprietes, handleSubmit, reset, error, submitting }) => {

	const submitForm = values => {
		return creerExamenType(values)
			.then(id => {
				closePopup()
				goToProprietes(id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}


	const actions = [
		<MuiButton key="close" color="primary" onClick={closePopup}>
			<FormattedMessage id="global.btn.annuler" />
		</MuiButton>,
		<Button
			key="valider"
			variant="contained"
			onClick={handleSubmit(submitForm)}
			loading={submitting}
		>
			<FormattedMessage id="global.btn.creer" />
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		onExited={reset}
		title={<FormattedMessage id="references.examensTypes.form.popupTitre" />}
		content={
			<React.Fragment>
				<Error error={error} isPopinContainer />
				<form onSubmit={handleSubmit(submitForm)}>
					<Field
						name="libelleCourt"
						label={<FormattedMessage id="references.examensTypes.form.libelleCourt"/>}
						component={Input}
						inputProps={{
							maxLength: 20
						}}
					/>
					<Field
						name="type"
						label={<FormattedMessage id="references.examensTypes.form.type" />}
						component={Select}
					>
						{[1, 2, 3, 4].map(index =>
							<MenuItem
								key={index}
								value={index}
							>
								<FormattedMessage id={`references.examensTypes.type.${index}`} />
							</MenuItem>
						)}
					</Field>
				</form>
			</React.Fragment>
		}
	/>
}

CreerExamenTypePopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	classes: PropTypes.object
}

const actions = {
	creerExamenType,
	goToProprietes: id => dispatch => dispatch(push(`/examens-types/${id}`))
}

export default compose(
	connect(undefined, actions),
	reduxForm({
		form: 'creerExamenType',
		validate,
		initialValues: {
			type: 1
		}
	}),
	injectFormSnackbar()
)(CreerExamenTypePopup)
