import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { FormCheckbox, getStyles } from 'isotope-client'
import {
	Autocomplete,
	Button,
	injectSession,
	Input,
	Popup,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { getLignes, getListeAuteurs, creerQuestion } from '../services/banqueQuestionsActions'
import { getUser } from '../../../login/userSelectors'
import moment from 'moment'
import { push } from 'connected-react-router'

const styles = () => getStyles({
	popup: {
		maxWidth: 'unset',
		width: 800,
		overflow: 'visible'
	},
	content: {
		overflow: 'visible'
	}
})

const validate = values => {
	const errors = {}
	if (!values.auteur) {
		errors.auteur = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.ligneProgramme) {
		errors.ligneProgramme = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const CreationQuestionPopup = (props) => {

	const {
		user,
		isGestionnaire,
		getLignes,
		open,
		closePopup,
		handleSubmit,
		reset,
		initialize,
		initialValues,
		classes,
		getListeAuteurs,
		snackError,
		creerQuestion,
		goToDetailsQuestion
	} = props


	const [listeAuteurs, setListeAuteurs] = React.useState([])
	React.useEffect(() => {
		if (open) {
			if (isGestionnaire) {
				getListeAuteurs()
					.then(liste => {
						setListeAuteurs(liste)
						initialize({
							...initialValues,
							auteur: null
						})
					})
					.catch(() => {
						snackError()
						setListeAuteurs([])
						initialize({
							...initialValues,
							auteur: null
						})
					})
			} else {
				setListeAuteurs([{
					id: user.id,
					libelle: user.lastname
				}])
				initialize({
					...initialValues,
					auteur: user.id
				})
			}
		}
	}, [open])

	const submit = values => creerQuestion(values)
		.then(id => {
			closePopup()
			goToDetailsQuestion(id)
		})
		.catch(e => {
			throw new SubmissionError(e)
		})

	const actions = [
		<Button
			key="close"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			variant="contained"
			onClick={handleSubmit(submit)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			onExited={reset}
			actions={actions}
			dialogClasses={{ paper: classes.popup }}
			contentStyleName={classes.content}
			title="Création d'une question française"
			content={<>
				<ResponsiveForm>
					<Grid container>
						<Grid item xs={6}>
							<Field
								disabled
								label="Origine"
								name="origine"
								component={Input}
							/>
						</Grid>
						<Grid item xs={6}>
							<Field
								name="bilingue"
								label="Bilingue"
								component={FormCheckbox}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={6}>
							<Field
								disabled={!isGestionnaire}
								label="Auteur *"
								name="auteur"
								component={Select}
							>
								{React.Children.toArray(listeAuteurs.map(auteur => <MenuItem
									value={auteur.id}
								>
									{auteur.libelle}
								</MenuItem>))}
							</Field>
						</Grid>
						<Grid item xs={6}>
							<Field
								name="dateCreation"
								label="Date de création"
								component={Input}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid container justify="flex-start">
						<Grid item xs={6}>
							<Field
								disabled
								label="Etat"
								name="etat"
								component={Input}
							>
							</Field>
						</Grid>
					</Grid>
					<Grid container justify="flex-start">
						<Grid item xs={6}>
							<Field
								label="Ligne de programme *"
								name="ligneProgramme"
								component={Autocomplete}
								minLength={3}
								getSuggestion={(length, value) => getLignes(value)}
								inputProps={{
									maxLength: 11
								}}
							>
							</Field>
						</Grid>
					</Grid>
				</ResponsiveForm>
			</>
			}
		/>
	)
}

CreationQuestionPopup.propTypes = {
	user: PropTypes.object,
	isGestionnaire: PropTypes.bool,
	getLignes: PropTypes.func,
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	handleSubmit: PropTypes.func,
	reset: PropTypes.func,
	initialize: PropTypes.func,
	initialValues: PropTypes.object,
	classes: PropTypes.object,
	getListeAuteurs: PropTypes.func,
	snackError: PropTypes.func,
	creerQuestion: PropTypes.func,
	goToDetailsQuestion: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
	user: getUser(state),
	isGestionnaire: ownProps.profil === PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS.id
})

const actions = {
	getLignes,
	getListeAuteurs,
	creerQuestion,
	goToDetailsQuestion: id => dispatch => dispatch(push(`/questions/${id}`))
}

export default compose(
	injectSession,
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'creerQuestion',
		validate,
		initialValues: {
			origine: 'Française',
			dateCreation: moment(new Date()).format('DD/MM/YYYY'),
			etat: 'Créée'
		}
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(CreationQuestionPopup)
