import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, ResponsiveForm, Select, SmallInput } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'

const style = () => getStyles({
	input: {
		marginBottom: 10
	},
	inputSelect: {
		paddingTop: 2
	},
	rechercher: {
		marginTop: 15
	}
})

const validate = values => {
	const errors = {}

	if (!values.idExamenType) {
		errors.idExamenType = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.annee) {
		errors.annee = { id: 'global.erreurs.obligatoire' }
	} else if (values.annee.length < 4 || isNaN(values.annee)) {
		errors.annee = 'L\'année doit être un numérique sur 4 caractères'
	}

	return errors
}

const RechercheSessionsPapierForm = ({ listeExamensType, submitting, handleSubmit, classes }) => {
	return (<ResponsiveForm onSubmit={handleSubmit}>
		<Grid container justify="space-between" alignItems="flex-start">
			<Grid item xs={6}>
				<Field
					name="idExamenType"
					label="Examen type *"
					component={Select}
					inputStyle={classes.inputSelect}
				>
					{listeExamensType.map(value =>
						<MenuItem
							key={value.id}
							value={value.id}
						>
							{value.libelleCourt}
						</MenuItem>
					)}
				</Field>
			</Grid>
			<Grid item xs={4}>
				<Field
					name="annee"
					label="Année *"
					component={SmallInput}
					inputStyle={classes.input}
					inputProps={{
						maxLength: 4
					}}
				/>
			</Grid>
			<Grid item xs={2}>
				<Button
					loading={submitting}
					color="primary"
					variant="contained"
					className={classes.rechercher}
					type="submit"
				>
					Rechercher
				</Button>
			</Grid>
		</Grid>
	</ResponsiveForm>)
}

RechercheSessionsPapierForm.propTypes = {
	listeExamensType: PropTypes.array,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	reduxForm({
		form: 'RechercheSession',
		initialValues: {
			annee: new Date().getFullYear()
		},
		validate
	}),
	withStyles(style)
)(RechercheSessionsPapierForm)
