import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectToolbarData, Title } from 'isotope-client'
import { Actions, Button, Error, getFilArianeExamenType, Select, ResponsiveForm, examenTypeShape, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import * as selectors from '../services/examensTypesSelectors'
import { getListeExamensStandards, repliquerAffectations } from '../services/examensTypesActions'

const validate = values => {
	const errors = {}
	if (!values.idExamenTypeOrigine) {
		errors.idExamenTypeOrigine = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const OngletAffectations = ({ id, examensStandards, error, handleSubmit, reset, submitting, getListeExamensStandards, repliquerAffectations }) => {

	React.useEffect(() => {
		getListeExamensStandards()
	}, [id])

	const submit = values => repliquerAffectations(id, values)
		.then(reset)
		.catch(e => {
			throw new SubmissionError(e)
		})

	return (
		<ResponsiveForm>
			<Error error={error} />
			<Title value="Réplication des affectations" />
			<Field
				name="idExamenTypeOrigine"
				label="Examen d'origine *"
				component={Select}
			>
				{examensStandards.map((examen, index) => (
					<MenuItem
						key={`examenStandard-${index}`}
						value={examen.id}
					>
						{examen.libelle}
					</MenuItem>
				))}
			</Field>
			<Actions>
				<Button
					color="primary"
					variant="contained"
					loading={submitting}
					onClick={handleSubmit(submit)}
				>
					Valider
				</Button>
			</Actions>
		</ResponsiveForm>
	)
}


OngletAffectations.propTypes = {
	id: PropTypes.number,
	libelle: PropTypes.string,
	etat: PropTypes.number,
	examensStandards: PropTypes.arrayOf(examenTypeShape),
	initialValues: PropTypes.object,
	getListeExamensStandards: PropTypes.func,
	repliquerAffectations: PropTypes.func
}

const mapStateToProps = state => {
	const examenType = selectors.getExamenType(state)
	return {
		id: examenType.id,
		libelle: examenType.libelleCourt,
		etat: examenType.etat,
		examensStandards: selectors.getListeExamensReplicationPossible(state),
		initialValues: {
			idExamenTypeCourant: examenType.id
		}
	}
}

const actions = {
	getListeExamensStandards,
	repliquerAffectations
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'replicationAffectations',
		validate
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' }),
	injectToolbarData(({ libelle, etat }) => getFilArianeExamenType(libelle, etat))
)(OngletAffectations)
