import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { dataTableActions, getStyles, SpringDataTable, Title } from 'isotope-client'
import { EmptyResult, ORG_SESSION_ID_ETAT } from '@oceane/ui'
import * as selectors from '../../services/sessionsPapierSelectors'
import { injectIntl } from 'react-intl'
import { modifierAffectationEpreuveSession } from '../../services/sessionsPapierActions'
import ModifierEpreuveSessionPopup from '../components/ModifierEpreuveSessionPopup'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const style = () => getStyles({
	buttonAction: {
		padding: 8
	}
})

const OngletEpreuves = (
	{
		classes,
		snackError,
		refreshTable,
		sessionPapier,
		modifierAffectationEpreuveSession
	}
) => {

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [epreuveSession, setEpreuveSession] = React.useState(null)
	const open = Boolean(anchorEl);

	const handleClick = (event, epreuveSession) => {
		setAnchorEl(event.currentTarget)
		setEpreuveSession(epreuveSession)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setEpreuveSession(null)
	}

	const ACTIONS = {
		AFFECTER: 1,
		DESAFFECTER: 2,
		MODIFIER: 3
	}
	const [isOpenModifierEpreuveSessionPopup, setOpenModifierEpreuveSessionPopup] = React.useState(false)
	const [epreuveSessionAModifier, setEpreuveSessionAModifier] = React.useState(null)

	/**
	 * Gère la fermeture du menu Actions
	 * @param action indique quelle action mettre en oeuvre en plus de la fermeture du menu
	 * @param epreuveSessionPapier épreuve concernée
	 */
	const handleSelectAction = (action, epreuveSessionPapier) => {
		switch (action) {
			case ACTIONS.AFFECTER:
				modifierAffectationEpreuveSession(sessionPapier.id, epreuveSessionPapier.idEpreuveSession, true)
					.then(() => refreshTable())
					.catch(() => snackError())
				break
			case ACTIONS.DESAFFECTER:
				modifierAffectationEpreuveSession(sessionPapier.id, epreuveSessionPapier.idEpreuveSession, false)
					.then(() => refreshTable())
					.catch(() => snackError())
				break
			case ACTIONS.MODIFIER:
				setEpreuveSessionAModifier(epreuveSessionPapier)
				setOpenModifierEpreuveSessionPopup(true)
				break
			default:
				break
		}
	}

	/**
	 * Détermine l'affichage du menu
	 */
	const isDisplayMenu = epreuveSessionPapier => (!epreuveSessionPapier.affectee && sessionPapier.etat === ORG_SESSION_ID_ETAT.CREEE) ||
		(epreuveSessionPapier.affectee && sessionPapier.etat === ORG_SESSION_ID_ETAT.CREEE) ||
		(epreuveSessionPapier.affectee && (sessionPapier.etat === ORG_SESSION_ID_ETAT.INITIALISEE || sessionPapier.inscriptionsCloses))

	const makeActions = () => {
		let menuItems = []
		if (epreuveSession) {
			// Affecter
			if (!epreuveSession.affectee && sessionPapier.etat === ORG_SESSION_ID_ETAT.CREEE) {
				menuItems.push({
					label: 'Affecter', onClick: (epreuveSessionPapier) => {
						handleSelectAction(ACTIONS.AFFECTER, epreuveSessionPapier)
					}
				})
			}
			// Désaffecter
			else if (epreuveSession.affectee && sessionPapier.etat === ORG_SESSION_ID_ETAT.CREEE) {
				menuItems.push({
					label: 'Désaffecter', onClick: (epreuveSessionPapier) => {
						handleSelectAction(ACTIONS.DESAFFECTER, epreuveSessionPapier)
					}
				})
			}
			// Modifier
			else if (epreuveSession.affectee && (sessionPapier.etat === ORG_SESSION_ID_ETAT.INITIALISEE || sessionPapier.inscriptionsCloses)) {
				menuItems.push({
					label: 'Modifier',
					onClick: (epreuveSessionPapier) => handleSelectAction(ACTIONS.MODIFIER, epreuveSessionPapier)
				})
			}
		}

		return menuItems
	}

	return (<>
		<Title value="Liste des épreuves" />
		<SpringDataTable
			apiUrl={`/sessions/${sessionPapier.id}/epreuves-session`}
			headers={[
				{
					key: 'codeEpreuve',
					name: 'Code épreuve'
				},
				{
					key: 'libelle',
					name: 'Libellé épreuve'
				},
				{
					key: 'date',
					name: 'Date',
					render: epreuveSessionPapier => {
						if (epreuveSessionPapier.date !== null) {
							return new Date(epreuveSessionPapier.date).toLocaleDateString()
						}
						return null
					}
				},
				{
					key: 'heureDeDebut',
					name: 'Heure de début'
				},
				{
					key: 'duree',
					name: 'Durée'
				},
				{
					key: 'heureDeFin',
					name: 'Heure de fin'
				},
				{
					key: 'etat',
					name: '',
					render: row => {
						if (isDisplayMenu(row)) {
							return row.affectee ? 'Affectée' : 'Désaffectée'
						}
						return null
					}
				},
				{
					key: 'action',
					name: '',
					render: row => isDisplayMenu(row) && <IconButton className={classes.buttonAction} onClick={(event) => handleClick(event, row)}>
						<MoreVertIcon />
					</IconButton>
				}
			]}
			mode="get"
			applyRowStyle={row => {
				if (!row.affectee) {
					return {
						backgroundColor: '#EBEBEB'
					}
				}
				return {}
			}}
			nom="epreuvesSession"
			noResultFragment={<EmptyResult />}
			noPagination
		/>

		<Menu
			id="long-menu"
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleClose}
		>
			{makeActions().map((action, index) => <MenuItem
				key={`action-${index}`}
				onClick={() => {
					action.onClick(epreuveSession)
					setAnchorEl(null)
				}}
			>{action.label}</MenuItem>)}
		</Menu>

		<ModifierEpreuveSessionPopup
			open={isOpenModifierEpreuveSessionPopup}
			onClose={() => setOpenModifierEpreuveSessionPopup(false)}
			epreuveSession={epreuveSessionAModifier}
			sessionPapier={sessionPapier}
		/>
	</>)
}

const mapStateToProps = state => ({
	sessionPapier: selectors.getDonneesGenerales(state)
})

const actions = {
	modifierAffectationEpreuveSession,
	refreshTable: () => dataTableActions.refresh('epreuvesSession')
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(style),
	injectSnackActions
)(OngletEpreuves)
