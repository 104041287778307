import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, reduxForm } from 'redux-form'
import { push } from 'connected-react-router'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	Error,
	InputFile,
	PROFIL_UTILISATEUR
} from '@oceane/ui'
import { injectEpreuveSession } from './service/injectEpreuveSession'
import { getFilArianeEpreuveSession } from './service/FilArianeEpreuveSession'
import * as selectors from './service/correctionCopiesSelectors'
import { suppressionEtCreationCopies, controleAnomalies } from './service/correctionCopiesActions'
import CorrectionAucunFichierConfirmPopup from './popup/CorrectionAucunFichierConfirmPopup'

const styles = theme => getStyles({
	buttonCharger: {
		marginTop: 19
	},
	errorFiles: {
		fontSize: 13,
		color: theme.palette.warning,
		marginTop: -20
	}
})

const validate = values => {
	const errors = {}
	// Fichier non chargé
	if (!values.copies || !values.copies[0]) {
		errors.copies = 'Veuillez charger un fichier'
	}
	// RG1 : les seuls fichiers autorisés, auront une extension .txt KO =>
	// MSG124
	else if (values.copies[0].name.substr((values.copies[0].name.lastIndexOf('.') + 1)) !== 'txt') {
		errors.copies = 'L\'extension du fichier doit être au format .txt'
	}
	return errors
}

const CorrectionEpreuvePage = ({
								   file,
								   classes,
								   snackError,
								   submitting,
								   handleSubmit,
								   snackSuccess,
								   epreuveSession,
								   controleAnomalies,
								   goToGestionAnomalies,
								   suppressionEtCreationCopies
							   }) => {
	const [openCorrectionAucunFichierConfirmPopup, setOpenCorrectionAucunFichierConfirmPopup] = React.useState(false)
	const [isServerError, setIsServerError] = React.useState(false)
	const [isFichierCharge, setIsFichierCharge] = React.useState(false)

	const chargerEtCreerCopies = (values) => {
		setIsServerError(false)
		if (file) {
			return suppressionEtCreationCopies(epreuveSession.idSession, epreuveSession.id, values)
				.then(() => {
					snackSuccess('Les copies ont été bien créées !')
					setIsFichierCharge(true)
				})
				.catch(() => {
					snackError()
					setIsServerError(true)
				})
		}
	}

	/**
	 * Dans le cas d'une correction d'épreuve avec copies, appel du traitement
	 * de contrôle des anomalies puis redirection vers la page de correction de
	 * l'épreuve
	 */
	const controleEtCorrection = () => {
		controleAnomalies(epreuveSession.id, (!!epreuveSession.nomFichierCopie || isFichierCharge))
			.then(() => goToGestionAnomalies(epreuveSession.id))
			.catch(() => snackError())
	}

	const idEpreuveType = epreuveSession ? epreuveSession.libelleEpreuveType : ''
	return <>
		<Title value={`Acquisition épreuve ${idEpreuveType}`} />
		<BandeauInfo
			message="Re-créer les copies à partir d'un nouveau fichier annule tous les traitements effectués sur les copies en cours pour cette épreuve" />
		{isServerError && <Error error={['Une erreur technique est survenue']} />}
		<Grid container alignItems="flex-start">
			<Grid item>
				<InputFile
					name="copies"
					id="copies"
					label="Fichier de copies *"
					accept="text/plain"
				/>
			</Grid>
			<Grid item>
				<Button
					loading={submitting}
					className={classes.buttonCharger}
					onClick={handleSubmit(chargerEtCreerCopies)}
				>
					Charger et créer les copies
				</Button>
			</Grid>
		</Grid>

		<Actions>
			<Button
				variant="contained"
				onClick={() => {
					if(!epreuveSession.nomFichierCopie && !isFichierCharge) {
						setOpenCorrectionAucunFichierConfirmPopup(true)
					} else {
						controleEtCorrection()
					}
				}}
			>
				Contrôle et correction
			</Button>
		</Actions>

		<CorrectionAucunFichierConfirmPopup
			open={openCorrectionAucunFichierConfirmPopup}
			closePopup={() => setOpenCorrectionAucunFichierConfirmPopup(false)}
			controleEtCorrection={controleEtCorrection}
		/>
	</>
}

const mapStateToProps = state => ({
	epreuveSession: selectors.getEpreuveSession(state),
	initialValues: {
		file: null
	},
	file: formValueSelector('CorrectionEpreuveForm')(state, 'copies')
})

const actions = {
	suppressionEtCreationCopies,
	controleAnomalies,
	goToGestionAnomalies: (idEpreuveSession) => dispatch => dispatch(push(`/correction-copies/epreuve/${idEpreuveSession}/gestion-anomalies/`))
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS
	),
	connect(mapStateToProps, actions),
	injectEpreuveSession,
	reduxForm({
		form: 'CorrectionEpreuveForm',
		validate
	}),
	withStyles(styles),
	injectSnackActions,
	injectToolbarData(({ epreuveSession }) => ({
		...getFilArianeEpreuveSession(
			'Correction d\'une épreuve',
			epreuveSession ? epreuveSession.libelleSession : '',
			epreuveSession ? epreuveSession.libelleEpreuveType : ''
		)
	}))
)(CorrectionEpreuvePage)
