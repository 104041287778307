import withStyles from '@material-ui/core/styles/withStyles'
import {
	Actions,
	Button,
	checkAuthorities,
	EmptyResult, Error,
	injectSession,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	Link,
	injectFormSnackbar
} from '@oceane/ui'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { loadSessions } from './services/injectSessions'
import { getListeEpreuves, valider } from './services/epreuveSujetAction'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

const FORM_NAME = 'sessionsForm'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const GenerationSujetsOnglet = ({ session, sessions, getListeEpreuves, submitting, valider, error, handleSubmit, sessionRapide, change }) => {
	const [epreuves, setEpreuves] = React.useState([])
	const [sujetsGeneres, setSujetsGeneres] = React.useState(true)

	useEffect(() => { getEpreuves(session) }, [session])

	useEffect(() => {
		if (sessionRapide !== null) {
			change('session', sessionRapide)
			getEpreuves(sessionRapide)
		}
	}, [sessionRapide])

	const getEpreuves = (session) => {
		if (session && session !== '') {
			getListeEpreuves(session)
				.then(({ epreuves }) => {
					setEpreuves(epreuves)
					if (epreuves.length > 0) {
						setSujetsGeneres(epreuves[0].sujetsGeneres)
					}
				})
		} else {
			setEpreuves([])
			setSujetsGeneres(true)
		}
	}

	const validerSujets = () => {
		return valider(session)
			.then(({ result }) => {
				setSujetsGeneres(result)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	return (
		<>
			<Title value="Session" />
			<Error error={error} />
			<ResponsiveForm>
				<Field
					id="session"
					name="session"
					component={Select}
					label="Session"
					displayEmpty
					onChange={(event, value) => getEpreuves(value)}
				>
					<MenuItem value="" />
					{sessions.map(s =>
						<MenuItem key={s.id} value={s.id}>
							{s.libelle}
						</MenuItem>
					)}
				</Field>
				{(session !== '' && epreuves.length > 0) && <>
					<Title value="Session" />
					<StaticDataTable
						headers={[
							{
								key: 'libelleEpreuveType',
								name: 'Code épreuve',
								render: data => {
									if(data.etat < 4) {
										return <Link to={`/generer-sujet/generation/${data.id}`}>{data.libelleEpreuveType}</Link>
									} else {
										return <Typography>{data.libelleEpreuveType}</Typography>
									}
								}
							},
							{
								key: 'etat',
								name: 'Etat',
								render: row => <Typography><FormattedMessage id={`references.sessions.elsEtatEpreuveSession.${row.etat}`}/></Typography>
							}
						]}
						defaultPageSize={10}
						data={epreuves}
						nom="listeSites"
						noResultFragment={<EmptyResult />}
					/>
				</>}
				<Actions>
					{sujetsGeneres !== true && <Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(validerSujets)}
					>
						Valider génération sujets
					</Button>}
				</Actions>
			</ResponsiveForm>
		</>
	)
}

const mapStateToProps = state => ({
	session: formValueSelector(FORM_NAME)(state, 'session')
})


const actions = {
	getListeEpreuves,
	valider
}

GenerationSujetsOnglet.propTypes = {
	session: PropTypes.number,
	getListeEpreuves: PropTypes.func,
	valider: PropTypes.func
}

export default compose(
	loadSessions,
	connect(mapStateToProps, actions),
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	reduxForm({
		form: FORM_NAME,
		enableReinitialize: true,
		destroyOnUnmount: false
	}),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(GenerationSujetsOnglet)
