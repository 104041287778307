import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import { Button, Popup } from '@oceane/ui'
import * as selectors from '../services/banqueQuestionsSelectors'
import { supprimerAffectation } from '../services/banqueQuestionsActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { questionAffectationShape } from '../../../../config/shapes'

const ConfirmerDesaffectationPopup = ({ affectation, idQuestion, closePopup, supprimerAffectation, snackSuccess, snackError }) => {

	const supprimer = () => supprimerAffectation(idQuestion, affectation)
		.then(() => {
			closePopup()
			snackSuccess()
		})
		.catch(() => {
			snackError()
		})


	return (
		<Popup
			open={!!affectation}
			actions={[
				<Button key="annuler" onClick={closePopup}>
					Annuler
				</Button>,
				<Button
					key="valider"
					variant="contained"
					onClick={supprimer}
				>
					Valider
				</Button>
			]}
			title="Supprimer l'affectation"
			content={
				<Typography>
					Cette action supprimera le niveau de maturité de la question pour l'examen. Confirmez-vous votre action ?
				</Typography>
			}
		/>
	)
}

ConfirmerDesaffectationPopup.propTypes = {
	affectation: questionAffectationShape,
	idQuestion: PropTypes.number,
	closePopup: PropTypes.func,
	supprimerAffectation: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func
}

const mapStateToProps = state => ({
	idQuestion: selectors.getIdQuestion(state)
})

const actions = {
	supprimerAffectation
}

export default compose(
	connect(mapStateToProps, actions),
	injectSnackActions,
)(ConfirmerDesaffectationPopup)
