import React from 'react'
import { StyledTypography } from '@oceane/ui'
import { FormattedMessage } from 'react-intl'

export const getFilArianeScript = (
	libelleExamenType,
	etatExamenType,
	libelleEpreuveType,
	etatEpreuveType,
	etatScript
) => ({
	title: 'Propriétés du script',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			text: libelleExamenType,
			render: text => <>
				<StyledTypography>Examen Type : </StyledTypography>
				<StyledTypography>{text}</StyledTypography>
				{etatExamenType && <StyledTypography primary>
					<FormattedMessage id={`references.examensTypes.etat.${etatExamenType}`} />
				</StyledTypography>}
			</>
		}, {
			text: libelleEpreuveType,
			render: text => <>
				<StyledTypography>Epreuve Type : {text}</StyledTypography>
				<StyledTypography primary>
					<FormattedMessage id={`references.epreuvesTypes.etat.${etatEpreuveType}`} />
				</StyledTypography>
			</>
		}, {
			text: libelleEpreuveType,
			render: text => <>
				<StyledTypography bold>Script : {text}</StyledTypography>
				{etatScript && <StyledTypography bold primary>
					<FormattedMessage id={`references.scripts.etat.${etatScript}`} />
				</StyledTypography>}
			</>
		}]
	}
})
