import * as actions from './organismeActions'
import { HANDLE_QUERY_LISTE_ORGANISMES, RESET_QUERY_LISTE_ORGANISMES } from './organismeActions'
import { combineReducers } from 'redux'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_ORGANISMES_SUCCESS:
			return (action.organismes || []).map(type => type.id)
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_ORGANISMES_SUCCESS:
			return (action.organismes || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const initialRecherche = {
	search: '',
	switch: false
}
const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_LISTE_ORGANISMES:
			return {
				...state,
				[action.key]: action.value
			}
		case RESET_QUERY_LISTE_ORGANISMES:
			return {
				...state,
				search: ''
			}
		default:
			return state
	}
}

export const organismes = combineReducers({
	ids,
	byId,
	recherche
})
