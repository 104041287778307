import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { Field } from 'redux-form'
import { DateInput, InputRadio, Select } from '@oceane/ui'
import { MOTIF, TYPE_ETAT } from '../services/editionValues'
import { injectCompteClient } from '../../../references/compteClient/services/injectCompteClient'
import { injectListExamensType } from '../../../references/examensType/services/examenTypeInjector'
import { compose } from 'redux'

const EditionEtatReglement = ({ comptesClients, listeExamensType }) => {
	const examensExploitesExploitables = listeExamensType.filter(examen => examen.exploite || examen.exploitable)

	return (<Grid container>
		<Grid item xs={6}>
			<Field
				name="etatReglement.dateDebut"
				label="Debut période *"
				component={DateInput}
			/>
		</Grid>
		<Grid item xs={6}>
			<Field
				name="etatReglement.dateFin"
				label="Fin période *"
				component={DateInput}
			/>
		</Grid>
		<Grid item xs={12}>
			<Field
				id="examenType"
				name="etatReglement.idExamenType"
				label="Examen type *"
				component={Select}
			>
				{examensExploitesExploitables.map(examen =>
					<MenuItem key={`examen-${examen.id}`} value={examen.id}>
						{examen.libelleCourt}
					</MenuItem>
				)}
			</Field>
		</Grid>

		<Grid item xs={6}>
			<Field
				name="etatReglement.typeReglement"
				label="Type d'état"
				component={InputRadio}
				choices={TYPE_ETAT}
			/>
		</Grid>
		<Grid container item xs={6}>
			<Grid item xs={12}>
				<Field
					id="motif"
					name="etatReglement.idMotifExoneration"
					label="Motif"
					displayEmpty
					component={Select}
				>
					<MenuItem value="" />
					{MOTIF.map(motif =>
						<MenuItem key={`motif-${motif.value}`} value={motif.value}>
							{motif.label}
						</MenuItem>
					)}
				</Field>
			</Grid>
			<Grid item xs={12}>
				<Field
					id="compteClient"
					name="etatReglement.idCompteClientDCS"
					label="Compte client"
					displayEmpty
					component={Select}
				>
					<MenuItem value="" />
					{comptesClients.map(client =>
						<MenuItem key={`client-${client.id}`} value={client.id}>
							{client.libelle}
						</MenuItem>
					)}
				</Field>
			</Grid>
		</Grid>
	</Grid>)
}

EditionEtatReglement.propTypes = {
	comptesClients: PropTypes.array,
	listeExamensType: PropTypes.array,
}

export default compose(
	injectCompteClient,
	injectListExamensType()
)(EditionEtatReglement)
