import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'isotope-client'
import { MultiSelect } from '@oceane/ui'
import { UTILISATEUR_FORM_NAME } from '../services/administrationValues'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as adminSelectors from '../services/administrationSelectors'
import * as siteEcranActions from '../../organiserExamens/siteEcran/services/siteEcranAction'

const SuperviseurForm = ({ utilisateur, getListeSitesEcran, initialize }) => {
	const [listeSites, setListeSites] = React.useState([])

	React.useEffect(() => {
		getListeSitesEcran()
			.then(({ listeSitesEcran }) => {
				setListeSites(listeSitesEcran)
				initialize({
					superviseurSites: listeSitesEcran.filter(site => utilisateur.superviseurSites && utilisateur.superviseurSites.includes(site.id))
				})
			})
		return () => setListeSites([])
	}, [utilisateur])

	return (
		<React.Fragment>
			<Title value="Sites" />
			<Field
				name="superviseurSites"
				label=""
				component={MultiSelect}
				choices={listeSites}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const utilisateur = adminSelectors.getUtilisateur(state)

	return {
		utilisateur
	}
}

const actions = {
	getListeSitesEcran: siteEcranActions.getListeSitesEcran
}

SuperviseurForm.propTypes = {
	utilisateur: PropTypes.object,
	getListeSitesEcran: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: UTILISATEUR_FORM_NAME,
		enableReinitialize: true
	})
)(SuperviseurForm)
