import Button from '@material-ui/core/Button'
import { LargeInput, Popup } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { closePopupCreation, createPieceJustificative } from './services/piecesJustificativesAction'
import * as selectors from './services/piecesJustificativesSelectors'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class NouvellePieceJustificativePopup extends Component {

	submitCreatePieceJustificative = (values) => {
		const {
			closePopupCreation,
			createPieceJustificative,
			snackSuccess,
			refreshTable,
			goToModification
		} = this.props

		return createPieceJustificative(values)
			.then((result) => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopupCreation()
				goToModification(result.id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	closePopup = () => {
		const {
			closePopupCreation,
			reset
		} = this.props

		reset()
		closePopupCreation()
	}

	popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="PieceJustificativeForm"
				>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={LargeInput}
					/>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			handleSubmit,
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitCreatePieceJustificative)}
			>
				Créer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Ajouter une pièce justificative"
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

NouvellePieceJustificativePopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	createPieceJustificative: PropTypes.func,
	goToModification: PropTypes.func
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpenCreation(state)
})

const actions = {
	closePopupCreation,
	createPieceJustificative,
	refreshTable: () => dataTableActions.refresh('piecesJustificatives'),
	goToModification: (id) => dispatch => dispatch(push(`/pieces-justificatives/${id}`))
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'PieceJustificativeForm',
		initialValues: {
			libelle: ''
		},
		validate
	}),
	injectSnackActions
)(NouvellePieceJustificativePopup)
