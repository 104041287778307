import { createSelector } from 'reselect'

const getLocalState = state => state.candidat.nouvelleInscription

export const getTypeInscription = createSelector(
	getLocalState,
	state => state.content.typeInscription
)

export const getSessionsInit = createSelector(
	getLocalState,
	state => state.content.sessionsInitialisees
)