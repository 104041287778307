import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { getStyles, Title, injectToolbarData } from 'isotope-client'
import {
	Actions,
	Button,
	checkAuthorities,
	formatQuery,
	InputRadio,
	PROFIL_UTILISATEUR,
	Select,
	SmallInput
} from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { injectListeCentresPapier } from './services/listeCentresPapierInjector'

const styles = () => getStyles({
	heureRadio: {
		marginBottom: 13
	},
	anneeInput: {
		marginTop: 5
	}
})

const validate = values => {
	const errors = {}
	if (values.sessions === '1') {
		if (!values.annee) {
			errors.annee = { id: 'global.erreurs.obligatoire' }
		}
		if (isNaN(values.annee)) {
			errors.annee = { id: 'global.erreurs.numeric' }
		}
	}
	if (values.heure === '4' && !values.centre) {
		errors.centre = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const CalendrierSessionPapierPage = ({ listeCentres, handleSubmit, classes }) => {

	const submitForm = values => download(`/files/calendrierSessionPapier?${formatQuery(values)}`)

	return (
		<>
			<Title value="Sessions" />
			<Grid container direction="row" alignItems="flex-start">
				<Grid item>
					<Field
						name="sessions"
						component={InputRadio}
						choices={[{
							label: 'De l\'année',
							value: '1'
						}, {
							label: 'Initialisées',
							value: '2'
						}]}
					/>
				</Grid>
				<Grid item className={classes.anneeInput}>
					<Field
						name="annee"
						component={SmallInput}
						inputProps={{
							maxLength: 4
						}}
					/>
				</Grid>
			</Grid>
			<Title value="Heure" />
			<Grid container direction="row" alignItems="flex-end">
				<Grid item className={classes.heureRadio}>
					<Field
						name="heure"
						component={InputRadio}
						choices={[{
							label: 'Métropole',
							value: '3'
						}, {
							label: 'Locale du centre',
							value: '4'
						}]}
					/>
				</Grid>
				<Grid item>
					<Field
						name="centre"
						id="centre"
						label=""
						component={Select}
					>
						{listeCentres.map((centre, index) => <MenuItem
							key={`examenStandard-${index}`}
							value={centre.id}
						>
							{centre.libelle}
						</MenuItem>)}
					</Field>
				</Grid>
			</Grid>
			<Actions>
				<Button variant="contained" onClick={handleSubmit(submitForm)}>
					Afficher
				</Button>
			</Actions>
		</>
	)
}

CalendrierSessionPapierPage.propTypes = {
	listeCentres: PropTypes.array,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE),
	withStyles(styles),
	injectListeCentresPapier,
	reduxForm({
		form: 'calendrierSessionForm',
		initialValues: {
			sessions: '1',
			annee: new Date().getFullYear(),
			heure: '3'
		},
		validate
	}),
	injectToolbarData({ title: 'Calendrier des sessions' })
)(CalendrierSessionPapierPage)
