import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import { checkAuthorities, EmptyResult, Error, FloatingButton, hasAccess, injectSession, ListFiltres, Loader, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ETAT_SITE_ECRAN, ETAT_SITE_ECRAN_DESACTIVE, TYPE_SITE_ECRAN } from '../../../config/enums'
import NouveauSiteEcranPopup from './components/NouveauSiteEcranPopup'
import * as siteEcranAction from './services/siteEcranAction'
import { getSearchValue, getSwitchValue } from './services/siteEcranSelectors'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer'
		}
	}
})

const ListeSitesEcran = ({
	profil,
	goToCalendrierPage,
	classes,
	goToModificationPage,
	getSitesEcran,
	handleQuery,
	resetQuery,
	searchValue,
	switchValue
}) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [sites, setSites] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	useEffect(() => {
		getSitesEcran(profil).then(({ sites }) => {
			setSites(sites)
			setLoading(false)
		})
	}, [profil])

	const sitesFiltered = React.useMemo(() => {
		if (sites.length !== 0) {
			return sites.filter(site =>
				site.libelle.toLowerCase().includes(searchValue.toLowerCase()) && ((!switchValue && site.etat !== ETAT_SITE_ECRAN_DESACTIVE) || switchValue)
			)
		}
		return []
	}, [sites, searchValue, switchValue])

	if (loading) {
		return (<Loader />)
	}

	if (sites.length === 0) {
		return (
			<Error error={['Aucun site ne vous est affecté. Merci de contacter l\'administrateur pour paramétrer vos droits']} />
		)
	}

	return (
		<React.Fragment>
			<Title value="Sites d'examens écrans" />
			<ListFiltres
				handleQueryValue={handleQuery}
				resetQueryValue={resetQuery}
				searchValue={searchValue}
				switchValue={switchValue}
				searchPlaceholderId="sitesEcran.search"
				switchDescriptionId="sitesEcran.switch"
			/>
			<StaticDataTable
				headers={[
					{
						key: 'libelle',
						name: 'Site',
						render: data => <Typography className={classes.libelleColumn}
						                            onClick={() => goToModificationPage(data)}>{data.libelle}</Typography>
					},
					{
						key: 'capacite',
						name: 'Capacité'
					},
					{
						key: 'type',
						name: 'Type',
						render: row => <Typography>{TYPE_SITE_ECRAN[row.type]}</Typography>
					},
					{
						key: 'etat',
						name: 'Etat',
						render: row => <Typography>{ETAT_SITE_ECRAN[row.etat]}</Typography>
					},
					{
						key: 'calendrier',
						render: row => <IconButton onClick={() => goToCalendrierPage(row)}><CalendarIcon /></IconButton>
					}
				]}
				defaultPageSize={50}
				data={sitesFiltered}
				nom="listeSites"
				noResultFragment={<EmptyResult />}
			/>
			{hasAccess(PROFILS_LOGISTIQUE, profil) && <React.Fragment>
				<FloatingButton onClick={() => setOpenCreation(true)} />
				<NouveauSiteEcranPopup
					open={openCreation}
					closePopup={() => setOpenCreation(false)}
				/>
			</React.Fragment>}
		</React.Fragment>
	)
}

const actions = {
	goToModificationPage: (site) => dispatch => dispatch(push({
		pathname: `/examens-ecran/sites-ecran/${site.id}`,
		state: { libelle: site.libelle }
	})),
	getSitesEcran: siteEcranAction.getSitesEcran,
	goToCalendrierPage: (site) => dispatch => dispatch(push({
		pathname: `/examens-ecran/sites-ecran/calendrier/${site.id}`,
		state: { libelle: site.libelle }
	})),
	handleQuery: siteEcranAction.handleQuery,
	resetQuery: siteEcranAction.resetQuery
}

const mapStateToProps = state => ({
	searchValue: getSearchValue(state),
	switchValue: getSwitchValue(state)
})

ListeSitesEcran.propTypes = {
	goToModificationPage: PropTypes.func,
	goToCalendrierPage: PropTypes.func,
	getSitesEcran: PropTypes.func,
	handleQuery: PropTypes.func,
	resetQuery: PropTypes.func
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	injectSession,
	withStyles(style)
)(ListeSitesEcran)
