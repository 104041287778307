import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Title, FormInput } from 'isotope-client'
import { Field, SubmissionError, reduxForm } from 'redux-form'
import { InputRadio, Actions, Button, INS_ETAT_EPREUVE_INSCRIPTION, injectFormSnackbar } from '@oceane/ui'
import { modifierMention } from './services/gestionCopiesActions'
import * as selectors from './services/gestionCopiesSelectors'

const choices = [
	{
		value: `${INS_ETAT_EPREUVE_INSCRIPTION.REUSSIE}`,
		label: 'Réussie'
	},
	{
		value: `${INS_ETAT_EPREUVE_INSCRIPTION.ECHOUEE}`,
		label: 'Echouée'
	}
]

const validate = values => {
	const errors = {}
	if (!values.commentaire) {
		errors.commentaire = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}


const ResultatEpreuveMentionForm = ({ handleSubmit, modifierMention }) => {

	const submit = values => modifierMention(values)
		.catch(e => {
			throw new SubmissionError(e)
		})

	return (
		<>
			<Title value="Mention" />
			<Field
				name="mention"
				component={InputRadio}
				choices={choices}
			/>
			<Field
				name="commentaire"
				label="Commentaire *"
				component={FormInput}
				fullWidth
				multiline
				rows={5}
				rowsMax={5}
			/>
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					Changer mention
				</Button>
			</Actions>
		</>
	)
}

ResultatEpreuveMentionForm.propTypes = {
	handleSubmit: PropTypes.func,
	modifierMention: PropTypes.func
}

const mapStateToProps = (state) => ({
	initialValues: selectors.getMention(state)
})

const actions = {
	modifierMention
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'resultatEpreuveMention',
		validate
	}),
	injectFormSnackbar()
)(ResultatEpreuveMentionForm)
