import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { StaticDataTable, Title } from 'isotope-client'
import { injectAffectations } from './services/BanqueQuestionsInjectors'
import * as selectors from './services/banqueQuestionsSelectors'
import { BNQ_ID_ETAT_QUESTION } from '../../../config/enums'
import { EmptyResult } from '@oceane/ui'
import QuestionAffectationPopup from './popup/QuestionAffectationPopup'
import ConfirmerDesaffectationPopup from './popup/ConfirmerDesaffectationPopup'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { questionAffectationShape } from '../../../config/shapes'
import StatistiquesExamenPopup from './popup/StatistiquesExamenPopup'

const OngletAffectations = ({ affectations, etatQuestion, snackError }) => {

	const [creation, setCreation] = React.useState(false)
	const [affectation, setAffectation] = React.useState({})

	const openPopupCreation = affectation => {
		if (affectation.canAffect) {
			setAffectation(affectation)
			setCreation(true)
		} else {
			snackError('La question est monolingue alors que l\'examen est bilingue, l\'affectation est impossible')
		}
	}

	const openPopupEdition = affectation => {
		setAffectation(affectation)
		setCreation(false)
	}
	const closePopup = () => {
		setAffectation({})
	}

	const [suppression, setSuppression] = React.useState(null)
	const [statistiques, setStatistiques] = React.useState(null)

	return (
		<>
			<Title value="Liste des affectations aux examens" />
			<StaticDataTable
				data={affectations}
				headers={[{
					key: 'libelleExamen',
					name: 'Nom',
					sortable: true,
					type: 'string'
				}, {
					key: 'dureeExamen',
					name: 'Durée',
					sortable: true,
					type: 'string'
				}, {
					key: 'score',
					name: 'Score',
					sortable: true
				}, {
					key: 'statistiques',
					name: 'Statistiques',
					render: row => {
						if (row.affectee) {
							return <IconButton onClick={() => setStatistiques(row)}>
								<AssignmentIcon />
							</IconButton>
						}
					},
					sortable: false
				}]}
				makeActions={row => [
					{
						label: 'Modifier',
						action: () => openPopupEdition(row),
						show: () => row.affectee
					},
					{
						label: 'Désaffecter',
						action: () => setSuppression(row),
						show: () => row.affectee
					},
					{
						label: 'Affecter',
						action: () => openPopupCreation(row),
						show: () => !row.affectee
					}
				]}
				showActions={[BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE].includes(etatQuestion)}
				applyRowStyle={row => {
					if (!row.affectee) {
						return {
							backgroundColor: '#EBEBEB'
						}
					}
					return {}
				}}
				noResultFragment={<EmptyResult />}
				noPagination
			/>
			<QuestionAffectationPopup creation={creation} affectation={affectation} closePopup={closePopup} />
			<ConfirmerDesaffectationPopup affectation={suppression} closePopup={() => setSuppression(null)} />
			<StatistiquesExamenPopup affectation={statistiques} clearAffectation={() => setStatistiques(null)} />
		</>
	)
}


OngletAffectations.propTypes = {
	affectations: PropTypes.arrayOf(questionAffectationShape),
	etatQuestion: PropTypes.number,
	snackError: PropTypes.func
}

const mapStateToProps = state => ({
	affectations: selectors.getAffectations(state),
	etatQuestion: selectors.getEtatQuestion(state)
})

export default compose(
	connect(mapStateToProps),
	injectAffectations,
	injectSnackActions
)(OngletAffectations)
