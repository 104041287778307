import MenuItem from '@material-ui/core/MenuItem'
import {
	Actions,
	Button,
	checkAuthorities,
	injectSession,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import { Title } from 'isotope-client'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { getVersionsBanque } from './services/statistiquesBanqueActions'
import PropTypes from 'prop-types'
import moment from 'moment'
import { download } from 'isotope-client/components/download/IsotopeLink'

const ConsulterStatistiquesBanque = ({
										 submitting,
										 handleSubmit,
										 getVersionsBanque
									 }) => {
	const [versions, setVersions] = React.useState([])

	React.useEffect(() => {
		getVersionsBanque()
			.then(({ response }) => {
				setVersions(response)
			})
	}, [])

	const exporter = (values) => {
		download(`/files/statistiques/banque/${versions[values.versionBanque].id}`)
	}

	return (
		<React.Fragment>
			<Title value="QCM par organisme" />
			<ResponsiveForm>
				<Field
					id="versionBanque"
					name="versionBanque"
					label="Version de la banque *"
					component={Select}
				>
					{versions && versions.length !== 0 && versions.map((version, index) =>
						<MenuItem key={index} value={index}>
							{moment(version.dateExploitation).format('DD/MM/YYYY')}
						</MenuItem>
					)}
				</Field>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(exporter)}
					>
						Exporter
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({})

ConsulterStatistiquesBanque.propTypes = {
	getVersionsBanque: PropTypes.func
}

const actions = {
	getVersionsBanque
}

export default compose(
    checkAuthorities(
        PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS
    ),
    connect(mapStateToProps, actions),
    injectSession,
    reduxForm({
        form: 'ConsulterStatistiquesBanqueForm',
        initialValues: {
            versionBanque: 0
        },
        enableReinitialize: true
    }),
    injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(ConsulterStatistiquesBanque)
