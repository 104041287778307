import * as actions from './matieresActions'
import { HANDLE_QUERY_LISTE_MATIERES, RESET_QUERY_LISTE_MATIERES } from './matieresActions'
import { combineReducers } from 'redux'
import { LOGOUT_SUCCESS } from 'isotope-client'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_MATIERES_SUCCESS:
			return (action.matieres || []).map(type => type.id)
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_MATIERES_SUCCESS:
			return (action.matieres || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const mesMatieresIds = (state = [], action) => {
	switch (action.type) {
		case actions.GET_MES_MATIERES_SUCCESS:
			return (action.matieres || []).map(type => type.id)
		case LOGOUT_SUCCESS:
			return []
		default:
			return state
	}
}

const mesMatieresById = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_MES_MATIERES_SUCCESS:
			return (action.matieres || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const initialRecherche = {
	search: ''
}

const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_LISTE_MATIERES:
			return {
				search: action.value
			}
		case RESET_QUERY_LISTE_MATIERES:
			return {
				search: ''
			}
		default:
			return state
	}
}

export const matieres = combineReducers({
	all: combineReducers({
		ids,
		byId,
	}),
	me: combineReducers({
		ids: mesMatieresIds,
		byId: mesMatieresById
	}),
	recherche
})