import { fetchFactory } from 'isotope-client'
import { formatQuery } from '@oceane/ui'

export const rechercherInscription = values => fetchFactory(`/inscriptions/recherche?${formatQuery(values)}`)

export const getResultatEpreuve = idEpreuveInscription => fetchFactory(`/epreuvesInscription/${idEpreuveInscription}/resultat`)

export const modifierMention = values => fetchFactory(`/epreuvesInscription/${values.idEpreuveInscription}/mention`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const modifierFraude = values => fetchFactory(`/epreuvesInscription/${values.idEpreuveInscription}/fraude`, {
	method: 'post',
	body: JSON.stringify(values)
})