import React from 'react'
import { StyledTypography, DownloadButton } from '@oceane/ui'
import moment from 'moment'
import { download } from 'isotope-client/components/download/IsotopeLink'
import Grid from '@material-ui/core/Grid'

export const getFilArianePlanningQuotidien = ({ site, dateJour }) => {
	return ({
		title: 'Sites écrans',
		subheader: {
			separator: () => '>',
			filArianeEntries: [{
				text: `Site : ${site.libelle}`,
				render: text => <StyledTypography>{text}</StyledTypography>
			}, {
				text: moment(dateJour, 'YYYY-MM-DD').format('DD/MM/YYYY'),
				render: text => <StyledTypography bold>Journée du {text}</StyledTypography>
			}],
			actions: <Grid
				container
				justify="flex-end"
				spacing={24}
			>
				<Grid item><DownloadButton
					onClick={() => download(`/files/sitesEcran/${site.id}/imprimerJournee?date=${dateJour}`)}
					title="Imprimer"
				/></Grid>
				<Grid item><DownloadButton
					onClick={() => download(`/files/sitesEcran/${site.id}/exporterJournee?date=${dateJour}`)}
					title="Exporter"
				/></Grid>
			</Grid>
		}
	})
}
