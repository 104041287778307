import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Link } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
	base: {
		padding: 2
	},
	bold: {
		fontWeight: 'bold'
	},
	primary: {
		color: theme.palette.primary.main
	}
})

const StyledTypography = ({ bold, primary, children, classes }) => {
	const classNames = [classes.base]
	if (bold) {
		classNames.push(classes.bold)
	}
	if (primary) {
		classNames.push(classes.primary)
	}
	return <Typography component="span" className={classNames.join(' ')}>
		{children}
	</Typography>
}
StyledTypography.propTypes = {
	bold: PropTypes.bool,
	primary: PropTypes.bool,
	children: PropTypes.node,
	classes: PropTypes.object
}

StyledTypography.defaultProps = {
	bold: false,
	primary: false
}

const StyledTypographyHOC = withStyles(styles)(StyledTypography)

export const getFilArianeLigne = (
	detailMatiere,
	detailTopic,
	detailParagraphe,
	codeSousParagraphe,
	canGoBack = true
) => {
	return {
		subheader: ({
			separator: () => '>',
			filArianeEntries: [{
				render: () => <Link to={`/lignes-programme`}>
					<StyledTypographyHOC bold>Liste matières </StyledTypographyHOC>
				</Link>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics`}>
						<StyledTypographyHOC bold>
							Matière {detailMatiere.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics/${detailTopic.id}/liste-paragraphes`}>
						<StyledTypographyHOC bold>
							Topic {detailTopic.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics/${detailTopic.id}/liste-paragraphes/${detailParagraphe.id}/liste-sous-paragraphes`}>
						<StyledTypographyHOC bold>
							Paragraphe {detailParagraphe.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<StyledTypographyHOC bold>
						Sous paragraphe {codeSousParagraphe}
					</StyledTypographyHOC>
				</>
			}],
			canGoBack
		})
	}
}

export const getFilArianeSousParagraphe = (
	detailMatiere,
	detailTopic,
	codeParagraphe,
	canGoBack = true
) => {
	return {
		subheader: ({
			separator: () => '>',
			filArianeEntries: [{
				render: () => <Link to={`/lignes-programme`}>
					<StyledTypographyHOC bold>Liste matières </StyledTypographyHOC>
				</Link>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics`}>
						<StyledTypographyHOC bold>
							Matière {detailMatiere.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics/${detailTopic.id}/liste-paragraphes`}>
						<StyledTypographyHOC bold>
							Topic {detailTopic.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<StyledTypographyHOC bold>
						Paragraphe {codeParagraphe}
					</StyledTypographyHOC>
				</>
			}],
			canGoBack
		})
	}
}

export const getFilArianeParagraphe = (
	detailMatiere,
	codeTopic,
	canGoBack = true
) => ({
		subheader: ({
			separator: () => '>',
			filArianeEntries: [{
				render: () => <Link to={`/lignes-programme`}>
					<StyledTypographyHOC bold>Liste matières </StyledTypographyHOC>
				</Link>
			}, {
				render: () => <>
					<Link to={`/ligne-programme/${detailMatiere.id}/liste-topics`}>
						<StyledTypographyHOC bold>
							Matière {detailMatiere.code}
						</StyledTypographyHOC>
					</Link>
				</>
			}, {
				render: () => <>
					<StyledTypographyHOC bold>
						Topic {codeTopic}
					</StyledTypographyHOC>
				</>
			}],
			canGoBack
		})
	})

export const getFilArianeTopics = (
	codeMatiere,
	canGoBack = true
) => ({
		subheader: ({
			separator: () => '>',
			filArianeEntries: [{
				render: () => <Link to={`/lignes-programme`}>
					<StyledTypographyHOC bold>Liste matières</StyledTypographyHOC>
				</Link>
			}, {
				render: () => <>
					<StyledTypographyHOC bold>
						Matière {codeMatiere.code}
					</StyledTypographyHOC>
				</>
			}],
			canGoBack
		})
	})
