import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Title, injectToolbarData } from 'isotope-client'
import { injectResultatEpreuve } from './services/injectResultatEpreuve'
import { checkAuthorities, Input, LargeInput, INS_ETAT_EPREUVE_INSCRIPTION, PROFIL_UTILISATEUR } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import ResultatEpreuveMentionForm from './ResultatEpreuveMentionForm'
import ResultatEpreuveFraudeForm from './ResultatEpreuveFraudeForm'


const ModificationResultatEpreuvePage = ({ resultatEpreuve }) => (
	<>
		<Title value="Détail de l'epreuve candidat" />
		<Grid container direction="row">
			<Grid item xs={6}>
				<LargeInput
					meta={{}}
					label="Candidat"
					value={`${resultatEpreuve.candidatNom} ${resultatEpreuve.candidatPrenom} (${resultatEpreuve.candidatDateNaissance})`}
					disabled
				/>
			</Grid>
			<Grid item xs={3}>
				<Input
					meta={{}}
					label="Examen"
					value={resultatEpreuve.libelleExamen}
					disabled
				/>
			</Grid>
			<Grid item xs={3}>
				<Input
					meta={{}}
					label="Epreuve"
					value={resultatEpreuve.libelleEpreuve}
					disabled
				/>
			</Grid>
		</Grid>
		{(resultatEpreuve.etat === INS_ETAT_EPREUVE_INSCRIPTION.REUSSIE || resultatEpreuve.etat === INS_ETAT_EPREUVE_INSCRIPTION.ECHOUEE) &&
			<ResultatEpreuveMentionForm />
		}
		<ResultatEpreuveFraudeForm />
	</>
)

ModificationResultatEpreuvePage.propTypes = {
	resultatEpreuve: PropTypes.object
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectResultatEpreuve,
	injectToolbarData({ title: 'Modification d\'une épreuve' }),
)(ModificationResultatEpreuvePage)
