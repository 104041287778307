import MenuItem from '@material-ui/core/MenuItem'
import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	Error,
	hasAccess,
	injectFormSnackbar,
	injectSession,
	Input,
	MultiSelect,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	StyledTypography
} from '@oceane/ui'
import { FormCheckbox, getStyles, injectToolbarData, Title } from 'isotope-client'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { TYPE_SITE_ECRAN } from '../../../config/enums'
import { getSitesPhysiques } from '../localisationSites/services/sitesPhysiquesAction'
import Grid from '@material-ui/core/Grid'
import { activationSiteEcran, controlSitesEcrans, getTypesSitesEcrans, modificationSiteEcran } from './services/siteEcranAction'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'
import { loadListeExamensType, loadSiteEcran } from './services/injectSiteEcran'
import DateUtils from '../../../utils/DateUtils'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

const FORM_NAME = 'ModifierSiteEcranForm'
const FIELD_HEURE_OUVERTURE = 'heureOuverture'
const FIELD_HEURE_DEBUT_PAUSE = 'heureDebutPause'
const FIELD_HEURE_FIN_PAUSE = 'heureFinPause'
const FIELD_HEURE_FERMETURE = 'heureFermeture'
const FIELD_LOCALISATION_SITE = 'localisationSite'
const FIELD_EXAMENS_TYPE_ADDED = 'examensTypeAdded'
const FIELD_EXAMENS_TYPE_REMOVED = 'examensTypeRemoved'
const FIELD_HAS_REMOVED_ALL_EXAMEN_TYPES = 'hasRemovedAllExamenTypes'

const styles = () => getStyles({
	tooltip: {
		margin: '-15px 0 0 16px'
	}
})

const validate = values => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = 'Le champ Libellé est obligatoire'
	}
	if (!values.capacite && values.capacite !== 0) {
		errors.capacite = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.capacite)) {
		errors.capacite = { id: 'global.erreurs.numeric' }
	}
	if (!values.localisationSite) {
		errors.localisationSite = { id: 'global.erreurs.obligatoire' }
	}
	if (values.heureOuverture === '') {
		errors.heureOuverture = { id: 'global.erreurs.obligatoire' }
	} else if (!DateUtils.isValidTime(values.heureOuverture)) {
		errors.heureOuverture = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureDebutPause !== '' && !DateUtils.isValidTime(values.heureDebutPause)) {
		errors.heureDebutPause = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureDebutPause !== '' && !DateUtils.isValidTime(values.heureFinPause)) {
		errors.heureFinPause = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureFermeture === '') {
		errors.heureFermeture = { id: 'global.erreurs.obligatoire' }
	} else if (!DateUtils.isValidTime(values.heureFermeture)) {
		errors.heureFermeture = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}

	return errors
}

const DetailSiteEcran = ({
	getSitesPhysiques,
	profil,
	error,
	listeExamensType,
	submitting,
	handleSubmit,
	getTypesSitesEcrans,
	modificationSiteEcran,
	activationSiteEcran,
	site,
	match,
	controlSitesEcrans,
	heureOuverture,
	heureDebutPause,
	heureFinPause,
	heureFermeture,
	localisationSite,
	classes,
	snackSuccess,
	snackError,
	change,
	initialValues,
	examensTypeAdded,
	examensTypeRemoved,
}) => {
	const [customError, setCustomError] = React.useState(null)
	const [localisationSiteListe, setLocalisationSiteListe] = React.useState([])
	const [type, setType] = React.useState([])
	const [activer, setActiver] = React.useState(1)
	const [disabled, setDisabled] = React.useState(true)
	const [responseControle, setResponseControle] = React.useState(null)

	const id = match.params.id

	/**
	 * On maintient une liste des examens type affectés au site pour ne pas devoir rafraîchir les données à chaque enregistrement.
	 */
	const [examensTypeAffectes, setExamensTypeAffectes] = React.useState(initialValues.examensType)

	// Init
	React.useEffect(() => {
		setExamensTypeAffectes(initialValues.examensType)
	}, [initialValues.examensType.length])

	React.useEffect(() => {
		if (localisationSiteListe && localisationSiteListe.length === 0) {
			getSitesPhysiques(profil).then(({ sites }) => setLocalisationSiteListe(sites))
		}
		if (type && type.length === 0) {
			getTypesSitesEcrans().then(({ types }) => setType(types))
		}

		if (site && site.etat) {
			setActiver(site.etat)
		}
		setDisabled(!hasAccess(PROFILS_LOGISTIQUE, profil))

	}, [site, profil])

	const controlerHeures = () => {
		const object = {
			id: id,
			heureOuverture: heureOuverture,
			heureDebutPause: heureDebutPause,
			heureFinPause: heureFinPause,
			heureFermeture: heureFermeture,
			idSitePhysique: localisationSite
		}
		return controlSitesEcrans(object)
			.then(({ response }) => {
				// Affichage du message d'information
				if (response.warning) {
					setResponseControle(response.warning)
				}
				setCustomError(null)
			})
			.catch(e => {
				setResponseControle(null)
				setCustomError(e._error)
				snackError('Le formulaire comporte des erreurs')
			})
	}

	const activerSite = () =>
		activationSiteEcran(id)
			.then(() => {
				if (activer === 1) {
					snackSuccess('Le site écran a bien été désactivé')
					setActiver(2)
				} else {
					snackSuccess('Le site écran a bien été activé')
					setActiver(1)
				}
			})
			.catch(() => snackError())

	const modificationSite = (values) =>
		modificationSiteEcran(values)
			.then(() => {
				setCustomError(null)
				setResponseControle(null)
				setExamensTypeAffectes([...examensTypeAffectes, ...examensTypeAdded].filter(ex => !examensTypeRemoved.some(er => er.id === ex.id)))
				change(FIELD_EXAMENS_TYPE_ADDED, [])
				change(FIELD_EXAMENS_TYPE_REMOVED, [])
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	const updateAddedRemovedExamenTypes = (currentExamensType) => {
		change(FIELD_HAS_REMOVED_ALL_EXAMEN_TYPES, currentExamensType.length === 0)
		change(FIELD_EXAMENS_TYPE_ADDED, currentExamensType.filter(et => !examensTypeAffectes.some(e => e.id === et.id)))
		change(FIELD_EXAMENS_TYPE_REMOVED, examensTypeAffectes.filter(et => !currentExamensType.some(e => e.id === et.id)))
	}

	return (
		<React.Fragment>
			<Title value="Détail du site" />
			{!!responseControle && <BandeauInfo message={responseControle} />}
			<Error error={error || customError} />
			<ResponsiveForm>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
					disabled={disabled}
				/>
				<Field
					id="capacite"
					name="capacite"
					label="Capacité *"
					component={Input}
				/>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="type"
							name="type"
							label="Type"
							component={Input}
							disabled
							inputProps={{
								value: TYPE_SITE_ECRAN[site.type]
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						{site.type !== 0 && site.type !== 1 &&
							<Field
								id="niveau6"
								name="niveau6"
								label="Niveau 6"
								component={FormCheckbox}
							/>}
					</Grid>
				</Grid>
				<Field
					id="localisationSite"
					name="localisationSite"
					label="Localisation site *"
					component={Select}
					disabled={disabled}
				>
					{localisationSiteListe && localisationSiteListe.length !== 0 && localisationSiteListe.map(site =>
						<MenuItem key={site.id} value={site.id}>
							{site.libelle}
						</MenuItem>
					)}
				</Field>
				<Grid container>
					<Field
						id={FIELD_HEURE_OUVERTURE}
						name={FIELD_HEURE_OUVERTURE}
						label="Heure d'ouverture *"
						component={Input}
					/>
					<Tooltip
						title="La modification des horaires d'ouverture influe sur l'algorithme de réservation, mais pas sur les réservations déjà effectuées"
						placement="right"
						className={classes.tooltip}
					>
						<HelpIcon color="primary" />
					</Tooltip>
				</Grid>
				<Field
					id={FIELD_HEURE_DEBUT_PAUSE}
					name={FIELD_HEURE_DEBUT_PAUSE}
					label="Heure début de pause"
					component={Input}
				/>
				<Field
					id={FIELD_HEURE_FIN_PAUSE}
					name={FIELD_HEURE_FIN_PAUSE}
					label="Heure fin de pause"
					component={Input}
				/>
				<Field
					id={FIELD_HEURE_FERMETURE}
					name={FIELD_HEURE_FERMETURE}
					label="Heure de fermeture *"
					component={Input}
				/>

				<Field
					id="examensType"
					name="examensType"
					label="Examens type *"
					component={MultiSelect}
					onChange={(event, value) => updateAddedRemovedExamenTypes(value)}
					choices={listeExamensType || []}
					getLabelElement={element => element.libelleCourt}
					disabled={disabled}
				/>

				<Actions>
					<Button
						color="primary"
						onClick={controlerHeures}
					>
						Contrôler
					</Button>
					{activer === 2 && <Button
						loading={submitting}
						color="primary"
						onClick={activerSite}
					>
						Activer
					</Button>}
					{activer === 1 && <Button
						loading={submitting}
						color="primary"
						onClick={activerSite}
					>
						Désactiver
					</Button>}
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(modificationSite)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

const mapStateToProps = (state, { site, listeExamensType }) => {
	const {
		heureOuverture,
		heureDebutPause,
		heureFinPause,
		heureFermeture,
		localisationSite,
		examensTypeAdded,
		examensTypeRemoved,
	} = formValueSelector(FORM_NAME)(
		state,
		FIELD_HEURE_OUVERTURE,
		FIELD_HEURE_DEBUT_PAUSE,
		FIELD_HEURE_FIN_PAUSE,
		FIELD_HEURE_FERMETURE,
		FIELD_LOCALISATION_SITE,
		FIELD_EXAMENS_TYPE_ADDED,
		FIELD_EXAMENS_TYPE_REMOVED,
	)

	const resultSite = {
		...site,
		examensType: listeExamensType.filter(examen => (site.siteExamens || []).includes(examen.id)).map(examen => ({
			id: examen.id,
			libelleCourt: examen.libelle
		}))
	}

	return ({
		initialValues: {
			...resultSite,
			localisationSite: site && site.idSitePhysique,
			heureOuverture: site && site.heureOuverture ? site.heureOuverture : '',
			heureFermeture: site && site.heureFermeture ? site.heureFermeture : '',
			heureDebutPause: site && site.heureDebutPause ? site.heureDebutPause : '',
			heureFinPause: site && site.heureFinPause ? site.heureFinPause : '',
			hasRemovedAllExamenTypes: false,
			examensTypeAdded: [],
			examensTypeRemoved: [],
		},
		site,
		heureOuverture,
		heureDebutPause,
		heureFinPause,
		heureFermeture,
		localisationSite,
		examensTypeAdded,
		examensTypeRemoved,
		listeExamensType: listeExamensType.map(examen => ({
			id: examen.id,
			libelleCourt: examen.libelle
		}))
	})
}

DetailSiteEcran.propTypes = {
	classes: PropTypes.object,
	heureOuverture: PropTypes.string,
	heureDebutPause: PropTypes.string,
	heureFinPause: PropTypes.string,
	heureFermeture: PropTypes.string,
	localisationSite: PropTypes.number,
	site: PropTypes.object,
	listeExamensType: PropTypes.array,
	getSitesPhysiques: PropTypes.func,
	getTypesSitesEcrans: PropTypes.func,
	controlSitesEcrans: PropTypes.func,
	activationSiteEcran: PropTypes.func,
	getListeExamensTypes: PropTypes.func,
}

const actions = {
	getSitesPhysiques,
	getTypesSitesEcrans,
	controlSitesEcrans,
	activationSiteEcran,
	modificationSiteEcran
}

export default compose(
	loadSiteEcran,
	loadListeExamensType,
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	injectSession,
	reduxForm({
		form: FORM_NAME,
		validate,
		enableReinitialize: true
	}),
	injectToolbarData(({ location: { state } }) => ({
		title: 'Gestion site écran',
		subheader: {
			filArianeEntries: [{
				text: state && `Site : ${state.libelle}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' }),
	withStyles(styles)
)(DetailSiteEcran)
