import React from 'react'
import { compose } from 'redux'
import { Button, Popup } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const PopupValidationDossierExamenCandidat = ({
	open,
	closePopup,
	actionValider,
}) => {
	const buttonActions = [
		<Button key="annuler" onClick={closePopup}>
			<FormattedMessage id="global.btn.annuler" />
		</Button>,
		<Button key="valider"
				variant="contained"
				onClick={actionValider}
		>
			<FormattedMessage id="global.btn.valider" />
		</Button>
	]

	return (
		<Popup
			title={<FormattedMessage id="candidats.examens.piecesJustificatives.popins.validerDossier.title" />}
			open={open}
			content={
				<React.Fragment>
					<Typography>
						<FormattedHTMLMessage id="candidats.examens.piecesJustificatives.popins.validerDossier.body" />
					</Typography>
				</React.Fragment>
			}
			actions={buttonActions}
		/>
	)
}

export default compose(
)(PopupValidationDossierExamenCandidat)
