import * as api from './sessionsPapierApi'

const ROOT = 'SESSIONS_PAPIER'

///////////////////////////////////////////////////
//                   RECHERCHE                   //
///////////////////////////////////////////////////

export const GET_RECHERCHE_REQUEST = `${ROOT}/GET_RECHERCHE_REQUEST`
export const GET_RECHERCHE_SUCCESS = `${ROOT}/GET_RECHERCHE_SUCCESS`
export const GET_RECHERCHE_ERROR = `${ROOT}/GET_RECHERCHE_ERROR`
export const rechercher = (values) => dispatch => {
    dispatch({ type: GET_RECHERCHE_REQUEST })

    return api.rechercher(values)
        .then((response) => dispatch({ type: GET_RECHERCHE_SUCCESS, response }))
        .catch(e => {
            dispatch({ type: GET_RECHERCHE_ERROR })
            throw e
        })
}

export const POST_SESSION_PAPIER_REQUEST = `${ROOT}/POST_SESSION_PAPIER_REQUEST`
export const POST_SESSION_PAPIER_SUCCESS = `${ROOT}/POST_SESSION_PAPIER_SUCCESS`
export const POST_SESSION_PAPIER_ERROR = `${ROOT}/POST_SESSION_PAPIER_ERROR`
export const postSessionPapier = (values) => dispatch => {
    dispatch({ type: POST_SESSION_PAPIER_REQUEST, values })

    return api.postSessionPapier(values)
        .then(session => dispatch({ type: POST_SESSION_PAPIER_SUCCESS, session }))
        .catch(e => {
            dispatch({ type: POST_SESSION_PAPIER_ERROR, e })
            throw e
        })
}

///////////////////////////////////////////////////
//               DONNEES GENERALES               //
///////////////////////////////////////////////////

export const GET_SESSION_PAPIER_DONNEES_GENERALES_REQUEST = `${ROOT}/GET_SESSION_PAPIER_DONNEES_GENERALES_REQUEST`
export const GET_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS = `${ROOT}/GET_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS`
export const GET_SESSION_PAPIER_DONNEES_GENERALES_ERROR = `${ROOT}/GET_SESSION_PAPIER_DONNEES_GENERALES_ERROR`
export const getSessionPapierDonneesGenerales = (id) => dispatch => {
	dispatch({ type: GET_SESSION_PAPIER_DONNEES_GENERALES_REQUEST, id })
	return api.getSessionPapierDonneesGenerales(id)
		.then(donneesGenerales => dispatch({
				type: GET_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS,
			donneesGenerales
			}))
		.catch(e => {
			dispatch({ type: GET_SESSION_PAPIER_DONNEES_GENERALES_ERROR })
			throw e
		})
}

export const CLEAN_SESSION_PAPIER = 'CLEAN_SESSION_PAPIER'
export const cleanSessionPapier = () => dispatch =>
    dispatch({ type: CLEAN_SESSION_PAPIER })

export const PUT_SESSION_PAPIER_DONNEES_GENERALES_REQUEST = `${ROOT}/PUT_SESSION_PAPIER_DONNEES_GENERALES_REQUEST`
export const PUT_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS = `${ROOT}/PUT_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS`
export const PUT_SESSION_PAPIER_DONNEES_GENERALES_ERROR = `${ROOT}/PUT_SESSION_PAPIER_DONNEES_GENERALES_ERROR`
export const putSessionPapierDonneesGenerales = (id, values) => dispatch => {
	dispatch({ type: PUT_SESSION_PAPIER_DONNEES_GENERALES_REQUEST, id, values })
	return api.putSessionPapierDonneesGenerales(id, values)
		.then(donneesGenerales => dispatch({
			type: PUT_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS,
			donneesGenerales
		}))
		.catch(e => {
			dispatch({ type: PUT_SESSION_PAPIER_DONNEES_GENERALES_ERROR })
			throw e
		})
}

export const MODIFIER_ACCEPTATION_EPREUVE_REQUEST = `${ROOT}/MODIFIER_ACCEPTATION_EPREUVE_REQUEST`
export const MODIFIER_ACCEPTATION_EPREUVE_SUCCESS = `${ROOT}/MODIFIER_ACCEPTATION_EPREUVE_SUCCESS`
export const MODIFIER_ACCEPTATION_EPREUVE_ERROR   = `${ROOT}/MODIFIER_ACCEPTATION_EPREUVE_ERROR`
export const modifierAffectationEpreuveSession = (idSession, idEpreuveSession, affectee) => dispatch => {
	dispatch({ type: MODIFIER_ACCEPTATION_EPREUVE_REQUEST })
	return api.modifierAffectationEpreuveSession(idSession, idEpreuveSession, affectee)
		.then(() => dispatch({ type: MODIFIER_ACCEPTATION_EPREUVE_SUCCESS, idEpreuveSession }))
		.catch(e => {
			dispatch({ type: MODIFIER_ACCEPTATION_EPREUVE_ERROR })
			throw e
		})
}

export const MODIFIER_EPREUVE_REQUEST = `${ROOT}/MODIFIER_EPREUVE_REQUEST`
export const MODIFIER_EPREUVE_SUCCESS = `${ROOT}/MODIFIER_EPREUVE_SUCCESS`
export const MODIFIER_EPREUVE_ERROR   = `${ROOT}/MODIFIER_EPREUVE_ERROR`
export const modifierEpreuveSession = (idEpreuveSession, idSession, form) => dispatch => {
	dispatch({ type: MODIFIER_EPREUVE_REQUEST })
	return api.modifierEpreuveSession(idSession, idEpreuveSession, form)
		.then(() => dispatch({ type: MODIFIER_EPREUVE_SUCCESS, idEpreuveSession }))
		.catch(e => {
			dispatch({ type: MODIFIER_EPREUVE_ERROR })
			throw e
		})
}

export const DELETE_SESSION_PAPIER_DONNEES_GENERALES_REQUEST = `${ROOT}/DELETE_SESSION_PAPIER_DONNEES_GENERALES_REQUEST`
export const DELETE_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS = `${ROOT}/DELETE_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS`
export const DELETE_SESSION_PAPIER_DONNEES_GENERALES_ERROR = `${ROOT}/DELETE_SESSION_PAPIER_DONNEES_GENERALES_ERROR`
export const deleteSessionPapier = (id, values) => dispatch => {
	dispatch({ type: DELETE_SESSION_PAPIER_DONNEES_GENERALES_REQUEST, id, values })
	return api.deleteSessionPapier(id)
		.then(() => dispatch({ type: DELETE_SESSION_PAPIER_DONNEES_GENERALES_SUCCESS }))
		.catch(() => { dispatch({ type: DELETE_SESSION_PAPIER_DONNEES_GENERALES_ERROR }) })
}

export const GET_SUJET_REQUEST = 'GET_SUJET_REQUEST'
export const GET_SUJET_SUCCESS = 'GET_SUJET_SUCCESS'
export const GET_SUJET_ERROR = 'GET_SUJET_ERROR'
export const getSujet = (idSession) => dispatch => {
	dispatch({ type: GET_SUJET_REQUEST })

	return api.getSujet(idSession)
		.then((result) => dispatch({ type: GET_SUJET_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_SUJET_ERROR })
			throw e
		})
}

export const GET_CANDIDAT_EDITION_REQUEST = 'GET_CANDIDAT_EDITION_REQUEST'
export const GET_CANDIDAT_EDITION_SUCCESS = 'GET_CANDIDAT_EDITION_SUCCESS'
export const GET_CANDIDAT_EDITION_ERROR = 'GET_CANDIDAT_EDITION_ERROR'
export const getCandidat = (idSession) => dispatch => {
	dispatch({ type: GET_CANDIDAT_EDITION_REQUEST })

	return api.getCandidat(idSession)
		.then((result) => dispatch({ type: GET_CANDIDAT_EDITION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_CANDIDAT_EDITION_ERROR })
			throw e
		})
}

export const GET_LISTE_SITES_SESSION_REQUEST = 'GET_LISTE_SITES_SESSION_REQUEST'
export const GET_LISTE_SITES_SESSION_SUCCESS = 'GET_LISTE_SITES_SESSION_SUCCESS'
export const GET_LISTE_SITES_SESSION_ERROR = 'GET_LISTE_SITES_SESSION_ERROR'
export const getListeSitesSession = (idSession) => dispatch => {
	dispatch({ type: GET_LISTE_SITES_SESSION_REQUEST })

	return api.getListeSitesSession(idSession)
		.then((result) => dispatch({ type: GET_LISTE_SITES_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_LISTE_SITES_SESSION_ERROR })
			throw e
		})
}

export const GET_SITE_SESSION_REQUEST = 'GET_SITE_SESSION_REQUEST'
export const GET_SITE_SESSION_SUCCESS = 'GET_SITE_SESSION_SUCCESS'
export const GET_SITE_SESSION_ERROR = 'GET_SITE_SESSION_ERROR'
export const getSiteSession = (idSession, idSite) => dispatch => {
	dispatch({ type: GET_SITE_SESSION_REQUEST })

	return api.getSiteSession(idSession, idSite)
		.then((result) => dispatch({ type: GET_SITE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_SITE_SESSION_ERROR })
			throw e
		})
}

export const GET_SURVEILLANCE_SESSION_REQUEST = 'GET_SURVEILLANCE_SESSION_REQUEST'
export const GET_SURVEILLANCE_SESSION_SUCCESS = 'GET_SURVEILLANCE_SESSION_SUCCESS'
export const GET_SURVEILLANCE_SESSION_ERROR = 'GET_SURVEILLANCE_SESSION_ERROR'
export const getSurveillance = (idSession) => dispatch => {
	dispatch({ type: GET_SURVEILLANCE_SESSION_REQUEST })

	return api.getSurveillance(idSession)
		.then((result) => dispatch({ type: GET_SURVEILLANCE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_SURVEILLANCE_SESSION_ERROR })
			throw e
		})
}

export const CHECK_PLANNING_EXIST_SESSION_REQUEST = 'CHECK_PLANNING_EXIST_SESSION_REQUEST'
export const CHECK_PLANNING_EXIST_SESSION_SUCCESS = 'CHECK_PLANNING_EXIST_SESSION_SUCCESS'
export const CHECK_PLANNING_EXIST_SESSION_ERROR = 'CHECK_PLANNING_EXIST_SESSION_ERROR'
export const checkPlanningExist = (idSession, idSite) => dispatch => {
	dispatch({ type: CHECK_PLANNING_EXIST_SESSION_REQUEST })
	return api.checkPlanningExist(idSession, idSite)
		.then((result) => dispatch({ type: CHECK_PLANNING_EXIST_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: CHECK_PLANNING_EXIST_SESSION_ERROR, e })
			throw e
		})
}

export const METTRE_CANDIDAT_ABSENT_REQUEST = 'METTRE_CANDIDAT_ABSENT_REQUEST'
export const METTRE_CANDIDAT_ABSENT_SUCCESS = 'METTRE_CANDIDAT_ABSENT_SUCCESS'
export const METTRE_CANDIDAT_ABSENT_ERROR = 'METTRE_CANDIDAT_ABSENT_ERROR'
export const mettreCandidatAbsent = (idSession, idSite, idEpreuve, idCandidat) => dispatch => {
	dispatch({ type: METTRE_CANDIDAT_ABSENT_REQUEST })

	return api.mettreCandidatAbsent(idSession, idSite, idEpreuve, idCandidat)
		.then((result) => dispatch({ type: METTRE_CANDIDAT_ABSENT_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: METTRE_CANDIDAT_ABSENT_ERROR })
			throw e
		})
}

export const METTRE_CANDIDAT_PRESENT_REQUEST = 'METTRE_CANDIDAT_PRESENT_REQUEST'
export const METTRE_CANDIDAT_PRESENT_SUCCESS = 'METTRE_CANDIDAT_PRESENT_SUCCESS'
export const METTRE_CANDIDAT_PRESENT_ERROR = 'METTRE_CANDIDAT_PRESENT_ERROR'
export const mettreCandidatPresent = (idSession, idSite, idEpreuve, idCandidat) => dispatch => {
	dispatch({ type: METTRE_CANDIDAT_PRESENT_REQUEST })

	return api.mettreCandidatPresent(idSession, idSite, idEpreuve, idCandidat)
		.then((result) => dispatch({ type: METTRE_CANDIDAT_PRESENT_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: METTRE_CANDIDAT_PRESENT_ERROR })
			throw e
		})
}

export const GET_EPREUVE_SESSION_REQUEST = 'GET_EPREUVE_SESSION_REQUEST'
export const GET_EPREUVE_SESSION_SUCCESS = 'GET_EPREUVE_SESSION_SUCCESS'
export const GET_EPREUVE_SESSION_ERROR = 'GET_EPREUVE_SESSION_ERROR'
export const getEpreuveSession = (idSession, idSite, idEpreuve) => dispatch => {
	dispatch({ type: GET_EPREUVE_SESSION_REQUEST })

	return api.getEpreuveSession(idSession, idSite, idEpreuve)
		.then((result) => dispatch({ type: GET_EPREUVE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_EPREUVE_SESSION_ERROR })
			throw e
		})
}

export const GET_LISTE_AFFECTATIONS_REQUEST = 'GET_LISTE_AFFECTATIONS_REQUEST'
export const GET_LISTE_AFFECTATIONS_SUCCESS = 'GET_LISTE_AFFECTATIONS_SUCCESS'
export const GET_LISTE_AFFECTATIONS_ERROR = 'GET_LISTE_AFFECTATIONS_ERROR'
export const getListeAffectations = (idSession) => dispatch => {
	dispatch({ type: GET_LISTE_AFFECTATIONS_REQUEST })

	return api.getListeAffectations(idSession)
		.then((result) => dispatch({ type: GET_LISTE_AFFECTATIONS_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_LISTE_AFFECTATIONS_ERROR })
			throw e
		})
}

export const FERMER_CENTRE_SESSION_REQUEST = 'FERMER_CENTRE_SESSION_REQUEST'
export const FERMER_CENTRE_SESSION_SUCCESS = 'FERMER_CENTRE_SESSION_SUCCESS'
export const FERMER_CENTRE_SESSION_ERROR = 'FERMER_CENTRE_SESSION_ERROR'
export const fermerCentreSession = (idSession, idCentre) => dispatch => {
	dispatch({ type: FERMER_CENTRE_SESSION_REQUEST })

	return api.fermerCentreSession(idSession, idCentre)
		.then((result) => dispatch({ type: FERMER_CENTRE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: FERMER_CENTRE_SESSION_ERROR })
			throw e
		})
}

export const OUVRIR_CENTRE_SESSION_REQUEST = 'OUVRIR_CENTRE_SESSION_REQUEST'
export const OUVRIR_CENTRE_SESSION_SUCCESS = 'OUVRIR_CENTRE_SESSION_SUCCESS'
export const OUVRIR_CENTRE_SESSION_ERROR = 'OUVRIR_CENTRE_SESSION_ERROR'
export const ouvrirCentreSession = (idSession, idCentre) => dispatch => {
	dispatch({ type: OUVRIR_CENTRE_SESSION_REQUEST })

	return api.ouvrirCentreSession(idSession, idCentre)
		.then((result) => dispatch({ type: OUVRIR_CENTRE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: OUVRIR_CENTRE_SESSION_ERROR })
			throw e
		})
}

export const OUVRIR_SITE_SESSION_REQUEST = 'OUVRIR_SITE_SESSION_REQUEST'
export const OUVRIR_SITE_SESSION_SUCCESS = 'OUVRIR_SITE_SESSION_SUCCESS'
export const OUVRIR_SITE_SESSION_ERROR = 'OUVRIR_SITE_SESSION_ERROR'
export const ouvrirSiteSession = (idSession, idCentre, idSite) => dispatch => {
	dispatch({ type: OUVRIR_SITE_SESSION_REQUEST })

	return api.ouvrirSiteSession(idSession, idCentre, idSite)
		.then((result) => dispatch({ type: OUVRIR_SITE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: OUVRIR_SITE_SESSION_ERROR })
			throw e
		})
}

export const FERMER_SITE_SESSION_REQUEST = 'FERMER_SITE_SESSION_REQUEST'
export const FERMER_SITE_SESSION_SUCCESS = 'FERMER_SITE_SESSION_SUCCESS'
export const FERMER_SITE_SESSION_ERROR = 'FERMER_SITE_SESSION_ERROR'
export const fermerSiteSession = (idSession, idCentre, idSite) => dispatch => {
	dispatch({ type: FERMER_SITE_SESSION_REQUEST })

	return api.fermerSiteSession(idSession, idCentre, idSite)
		.then((result) => dispatch({ type: FERMER_SITE_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: FERMER_SITE_SESSION_ERROR })
			throw e
		})
}

export const GET_CENTRE_PAPIER_SESSION_REQUEST = 'GET_CENTRE_PAPIER_SESSION_REQUEST'
export const GET_CENTRE_PAPIER_SESSION_SUCCESS = 'GET_CENTRE_PAPIER_SESSION_SUCCESS'
export const GET_CENTRE_PAPIER_SESSION_ERROR = 'GET_CENTRE_PAPIER_SESSION_ERROR'
export const getCentrePapierSession = (idSession, idCentre) => dispatch => {
	dispatch({ type: GET_CENTRE_PAPIER_SESSION_REQUEST })

	return api.getCentrePapierSession(idSession, idCentre)
		.then((result) => dispatch({ type: GET_CENTRE_PAPIER_SESSION_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_CENTRE_PAPIER_SESSION_ERROR })
			throw e
		})
}

export const GET_EPREUVES_SURVEILLANCE_REQUEST = 'GET_EPREUVES_SURVEILLANCE_REQUEST'
export const GET_EPREUVES_SURVEILLANCE_SUCCESS = 'GET_EPREUVES_SURVEILLANCE_SUCCESS'
export const GET_EPREUVES_SURVEILLANCE_ERROR = 'GET_EPREUVES_SURVEILLANCE_ERROR'
export const getListeEpreuvesSurveillance = (idSession, idSite) => dispatch => {
	dispatch({ type: GET_EPREUVES_SURVEILLANCE_REQUEST })

	return api.getListeEpreuvesSurveillance(idSession, idSite)
		.then((result) => dispatch({ type: GET_EPREUVES_SURVEILLANCE_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_EPREUVES_SURVEILLANCE_ERROR })
			throw e
		})
}

export const GET_SURVEILLANTS_EPREUVE_REQUEST = 'GET_SURVEILLANTS_EPREUVE_REQUEST'
export const GET_SURVEILLANTS_EPREUVE_SUCCESS = 'GET_SURVEILLANTS_EPREUVE_SUCCESS'
export const GET_SURVEILLANTS_EPREUVE_ERROR = 'GET_SURVEILLANTS_EPREUVE_ERROR'
export const getSurveillantsEpreuve = (idSession, idSite, idEpreuve) => dispatch => {
	dispatch({ type: GET_SURVEILLANTS_EPREUVE_REQUEST })

	return api.getSurveillantsEpreuve(idSession, idSite, idEpreuve)
		.then((result) => dispatch({ type: GET_SURVEILLANTS_EPREUVE_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_SURVEILLANTS_EPREUVE_ERROR })
			throw e
		})
}

export const POST_AFFECTER_SURVEILLANT_REQUEST = 'POST_AFFECTER_SURVEILLANT_REQUEST'
export const POST_AFFECTER_SURVEILLANT_SUCCESS = 'POST_AFFECTER_SURVEILLANT_SUCCESS'
export const POST_AFFECTER_SURVEILLANT_ERROR = 'POST_AFFECTER_SURVEILLANT_ERROR'
export const affecterSurveillant = (form, idSession, idSite) => dispatch => {
	dispatch({ type: POST_AFFECTER_SURVEILLANT_REQUEST })

	return api.affecterSurveillant(form, idSession, idSite)
		.then((result) => dispatch({ type: POST_AFFECTER_SURVEILLANT_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: POST_AFFECTER_SURVEILLANT_ERROR })
			throw e
		})
}

export const DELETE_SURVEILLANT_REQUEST = 'DELETE_SURVEILLANT_REQUEST'
export const DELETE_SURVEILLANT_SUCCESS = 'DELETE_SURVEILLANT_SUCCESS'
export const DELETE_SURVEILLANT_ERROR = 'DELETE_SURVEILLANT_ERROR'
export const deleteSurveillant = (idSession, idSite, idEpreuve, idSurveillant) => dispatch => {
	dispatch({ type: DELETE_SURVEILLANT_REQUEST })

	return api.deleteSurveillant(idSession, idSite, idEpreuve, idSurveillant)
		.then((result) => dispatch({ type: DELETE_SURVEILLANT_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: DELETE_SURVEILLANT_ERROR })
			throw e
		})
}
