import Grid from '@material-ui/core/Grid'
import { checkAuthorities, Error, EtatCivilForm, PROFIL_UTILISATEUR, validateEtatCivil, ResponsiveForm, Button, injectFormSnackbar } from '@oceane/ui'
import { push } from 'connected-react-router'
import { injectToolbarData, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { creerCandidat } from './services/creerCandidatActions'

class CreerCandidatPage extends Component {

	creerCandidat = (values) => {
		const { creerCandidat, dispatch } = this.props
		return creerCandidat(values)
			.then(id => dispatch(push(`/candidats/${id}`)))
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	render() {
		const { handleSubmit, error, initialValues, submitting, codePostal } = this.props
		return (
			<React.Fragment>
				<Title value={<FormattedMessage id="candidats.profil.tabs.etatCivil" />} />
				<Error error={error} />
				<ResponsiveForm>
					<EtatCivilForm
						initialValues={initialValues}
						codePostal={codePostal}
					/>
					<Grid container justify="flex-end">
						<Button
							onClick={handleSubmit(this.creerCandidat)}
							loading={submitting}
							color="primary"
							variant="contained"
						>
							<FormattedMessage id="global.btn.enregistrer" />
						</Button>
					</Grid>
				</ResponsiveForm>
			</React.Fragment>
		)
	}
}


CreerCandidatPage.propTypes = {
	intl: intlShape,
	initialValues: PropTypes.object,
	codePostal: PropTypes.string,
	handleSubmit: PropTypes.func,
	error: PropTypes.array
}

const FORM_NAME = 'creerCandidat'

const mapStateToProps = (state, { intl }) => {
	const adresse = formValueSelector(FORM_NAME)(state, 'adresse')

	return ({
		initialValues: {
			adresse: {},
			nationalite: intl.formatMessage({ id: 'candidats.etatCivil.nationalite.defaut' })
		},
		codePostal: adresse && adresse.codePostal
	})
}

const actions = {
	creerCandidat
}


export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE),
	injectToolbarData({ title: { id: 'toolbarTitle.creationCandidat' } }),
	reduxForm({
		form: FORM_NAME,
		validate: validateEtatCivil
	}),
	injectFormSnackbar()
)(CreerCandidatPage)
