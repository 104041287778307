import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = state => getReferences(state).piecesJustificatives

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)

export const isPopupOpenCreation = createSelector(
	getLocalState,
	state => state.openCreation
)

export const getPieceJustificative = createSelector(
	getLocalState,
	state => state.detailPieceJustificative
)