import Typography from '@material-ui/core/Typography'
import { Button, Error, Popup } from '@oceane/ui'
import { goBack } from 'connected-react-router'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { deleteAnnexe } from '../services/annexeAction'


const SuppressionAnnexePopup = ({ open, idAnnexe, idMatiere, onClose, deleteAnnexe, snackSuccess, refreshTable, goBack }) => {
	const [error, setError] = React.useState()

	const popupContent = () => (
		<React.Fragment>
			{error && <Error error={error} isPopinContainer />}
			<Typography variant="body1">
				Voulez-vous supprimer l'annexe ?
			</Typography>
		</React.Fragment>
	)

	const submit = () => {
		deleteAnnexe(idAnnexe, idMatiere)
			.then(() => {
				onClose()
				snackSuccess('Suppression de l\'annexe réussie')
				refreshTable()
				goBack()
			})
			.catch((e) => {
				if (e.bodyError && e.bodyError.globalErrors && !!e.bodyError.globalErrors.length) {
					setError([e.bodyError.globalErrors[0].code])
				}
			})
	}

	const actions = [
		<Button
			key="annuler"
			color="primary"
			onClick={onClose}
		>
			Annuler
		</Button>,
		<Button
			key="supprimer"
			color="primary"
			variant="contained"
			onClick={submit}
		>
			Supprimer
		</Button>
	]

	return (
		<Popup
			open={open}
			title="Suppression d'une annexe"
			content={popupContent()}
			actions={actions}
		/>
	)
}

const actions = {
	deleteAnnexe,
	refreshTable: () => dataTableActions.refresh('listeAnnexe'),
	goBack: () => dispatch => dispatch(goBack())
}

SuppressionAnnexePopup.propTypes = {
	idAnnexe: PropTypes.string,
	idMatiere:PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	deleteAnnexe: PropTypes.func,
	snackSuccess: PropTypes.func,
	refreshTable: PropTypes.func,
	goBack: PropTypes.func
}

export default compose(
	connect(null, actions),
	injectSnackActions
)(SuppressionAnnexePopup)
