import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, Popup } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { deleteAssociationFormation } from '../services/examensTypesActions'
import { getListeTypesFormationActive } from './services/nouvelleAssociationFormationActions'

const DeleteAssociationFormationPopup = ({ open, closePopup, refreshTable, snackSuccess, snackError, idExamenType, idAssociation, libelleAssociation, deleteAssociationFormationAction, getListeTypesFormationActiveAction }) => {

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={() => {
				deleteAssociationFormationAction(idExamenType, idAssociation)
					.then(() => {
						refreshTable()
						closePopup()
						snackSuccess('L\'élément a bien été supprimé')
						// Rafraîchissement de la liste
						getListeTypesFormationActiveAction(idExamenType)
					})
					.catch(() => snackError())
			}}
		>
			Supprimer
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		title="Confirmation de suppression"
		content={<Typography>Voulez-vous supprimer le type de formation <strong>{libelleAssociation}</strong>&nbsp;?</Typography>}
	/>
}

DeleteAssociationFormationPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	refreshTable: PropTypes.func.isRequired,
	deleteAssociationFormationAction: PropTypes.func,
	libelleAssociation: PropTypes.string,
	idAssociation: PropTypes.number,
	getListeTypesFormationActive: PropTypes.func
}

const actions = {
	deleteAssociationFormationAction: deleteAssociationFormation,
	refreshTable: () => dataTableActions.refresh('typesFormations'),
	getListeTypesFormationActiveAction: getListeTypesFormationActive
}

export default compose(
	connect(null, actions),
	injectSnackActions
)(DeleteAssociationFormationPopup)
