import { Button, Error, Input, MultiSelect, Popup, TYPE_EXAMEN, injectFormSnackbar } from '@oceane/ui'
import { push } from 'connected-react-router'
import { FormCheckbox } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { injectListeMatieres } from '../../matieres/services/injectListeMatieres'
import * as matieresSelectors from '../../matieres/services/matieresSelectors'
import * as selectors from '../services/examensTypesSelectors'
import { creerEpreuveType } from './services/nouvelleEpreuveTypeActions'

const validate = (values, { type }) => {
	const errors = {}
	// RG : le libellé court doit être renseigné
	if (!values.libelleCourt) {
		errors.libelleCourt = 'Ce champ est obligatoire'
	}
	// RG : la sélection d'une matière est obligatoire si l'examen n'est pas
	// de type "Compétences linguistiques"
	if ((!values.matieres || values.matieres.length === 0) && type === TYPE_EXAMEN.STANDARD) {
		errors._error = { id: 'Vous devez renseigner au moins une matière' }
	}
	return errors
}

const CreerEpreuveTypePopup = ({ open, submitFailed, closePopup, type, handleSubmit, reset, error, submitting, goToProprietesEpreuveType, ...otherProps }) => {

	const submitForm = values => {
		const { id, creerEpreuveType } = otherProps
		let newValues = Object.assign({}, values)
		if (values.matieres) {
			// On ne garde que les ID des matières sélectionnées
			newValues.matieres = values.matieres.map(m => m.id)
		}
		return creerEpreuveType(newValues, id)
			.then(({ epreuve }) => {
				closePopup()
				goToProprietesEpreuveType(id, epreuve.id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitForm)}
			loading={submitting}
		>
			Créer
		</Button>
	]

	const { listeMatieres } = otherProps

	return <Popup
		open={open}
		actions={actions}
		onExited={reset}
		title="Nouvelle épreuve type"
		content={
			<React.Fragment>
				{submitFailed && <Error error={error} isPopinContainer />}
				<form onSubmit={handleSubmit(submitForm)}>
					{type !== TYPE_EXAMEN.PRATIQUE && type !== TYPE_EXAMEN.STANDARD && <Field
						name="nonOaci"
						label="Non comptabilisée OACI"
						component={FormCheckbox}
					/>}
					{type !== TYPE_EXAMEN.PRATIQUE && type === TYPE_EXAMEN.STANDARD && <Field
						name="matieres"
						label="Matières"
						component={MultiSelect}
						choices={listeMatieres} />}
					<Field
						name="libelleCourt"
						label="Libellé court *"
						component={Input}
						inputProps={{
							maxLength: 20
						}}
					/>
				</form>
			</React.Fragment>
		}
	/>
}

CreerEpreuveTypePopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	classes: PropTypes.object,
	listeMatieres: PropTypes.arrayOf(PropTypes.object),
	id: PropTypes.number,
	creerEpreuveType: PropTypes.func
}

const mapStateToProps = (state) => {
	const examenType = selectors.getExamenType(state)
	return {
		listeMatieres: matieresSelectors.getListeMatieres(state).map(value => ({ id: value.id, libelle: value.code })),
		id: examenType.id
	}
}

const actions = {
	creerEpreuveType,
	goToProprietesEpreuveType: (idExamenType, idEpreuveType) => dispatch => dispatch(push(`/examens-types/${idExamenType}/epreuves-types/${idEpreuveType}`))
}

export default compose(
	injectListeMatieres,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'creerEpreuveType',
		validate,
		initialValues: {
			nonOaci: false,
			libelleCourt: ''
		}
	}),
	injectFormSnackbar()
)(CreerEpreuveTypePopup)
