import * as api from './nouvelleEpreuveTypeApi'

export const CREER_EPREUVE_TYPE_REQUEST = 'REFERENCES/CREER_EPREUVE_TYPE_REQUEST'
export const CREER_EPREUVE_TYPE_SUCCESS = 'REFERENCES/CREER_EPREUVE_TYPE_SUCCESS'
export const CREER_EPREUVE_TYPE_ERROR   = 'REFERENCES/CREER_EPREUVE_TYPE_ERROR'
export const creerEpreuveType = (values, idExamenType) => dispatch => {
	dispatch({ type: CREER_EPREUVE_TYPE_REQUEST })
	return api.creerEpreuveType(values, idExamenType)
		.then(epreuve => dispatch({
			type: CREER_EPREUVE_TYPE_SUCCESS,
			epreuve
		}))
		.catch(e => {
			dispatch({ type: CREER_EPREUVE_TYPE_ERROR })
			throw e
		})
}