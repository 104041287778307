import {
	checkAuthorities, injectSession, StyledTypography,
	Tabs,
	PROFIL_UTILISATEUR
} from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { injectToolbarData, getToolbarData, setToolbarData } from 'isotope-client'
import Tab from '@material-ui/core/Tab'
import DetailCentrePapierOnglet from './DetailCentrePapierOnglet'
import ListeSitesCentrePapierOnglet from './ListeSitesCentrePapierOnglet'
import { loadCentre } from './services/centreInjector'
import { connect } from 'react-redux'

const DetailsCentrePapierPage = ({ match, changeToolbarData, centre, toolbarData }) => {

	const [tab, setTab] = React.useState(0)

	React.useEffect(() => {
		if (toolbarData.id) {
			changeToolbarData(toolbarData.id, ({
				title: 'Gestion du centre papier',
				subheader: (centre) && {
					filArianeEntries: [{
						render: () => <StyledTypography bold>Centre : {centre.libelle}</StyledTypography>
					}]
				}
			}))
		}
	}, [centre])

	const handleChangeTab = (value) => {
		setTab(value)
	}

	const tabViews = [
		<DetailCentrePapierOnglet match={match} />,
		<ListeSitesCentrePapierOnglet match={match} nom={centre.libelle} />
	]

	return (
		<React.Fragment>
			<Tabs
				initialTab={tab}
				tabs={[
					<Tab key="details" label="Détails" />,
					<Tab key="sites" label="Sites" />
				]}
				tabViews={tabViews}
				onChangeTab={handleChangeTab}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	toolbarData: getToolbarData(state)
})

const actions = {
	changeToolbarData: setToolbarData
}

export default compose(
	loadCentre,
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectSession,
	injectToolbarData(({ centre }) => {
		return ({
			title: 'Gestion du centre papier',
			subheader: (centre) && {
				filArianeEntries: [{
					render: () => <StyledTypography bold>Centre : {centre.libelle}</StyledTypography>
				}]
			}
		})
	})
)(DetailsCentrePapierPage)
