import PropTypes from 'prop-types'

export const donneeReferenceShape = {
	libelle: PropTypes.string,
	formationEnCours: PropTypes.bool
}

export const sessionInitialiseeShape = PropTypes.shape({
	id: PropTypes.number,
	libelle: PropTypes.string
})

export const examenCandidatShape = PropTypes.shape({
	id: PropTypes.number,
	typeExamen: PropTypes.number,
	examenTypeLibelle: PropTypes.string,
	examenTypeId: PropTypes.number,
	objectif: PropTypes.number,
	etat: PropTypes.number,
	dateEtat: PropTypes.string,
	commentaire: PropTypes.string,
	numeroDiplome: PropTypes.number,
	typeFCL: PropTypes.bool,
	typePrive: PropTypes.bool,
	isPratique: PropTypes.bool
})

export const familleShape = PropTypes.shape({
	id: PropTypes.number,
	idMatiere: PropTypes.number,
	libelle: PropTypes.string
})

export const annexeShape = PropTypes.shape({
	id: PropTypes.number,
	idMatiere: PropTypes.number,
	numero: PropTypes.string,
	isAImprimer: PropTypes.bool,
	extention: PropTypes.string
})

export const scriptParametrageShape = PropTypes.shape({
	id: PropTypes.number,
	etat: PropTypes.number,
	nbQuestions: PropTypes.number,
	pourcentageReussite: PropTypes.number,
	isExploitation: PropTypes.bool
})

export const paragrapheShape = PropTypes.shape({
	libelle: PropTypes.string,
	codeRechercheQuestions: PropTypes.string,
	cleNoeudDansMap: PropTypes.string,
	idTopicOuParagraphe: PropTypes.number,
	idScript: PropTypes.number,
	code: PropTypes.string,
	id: PropTypes.number,
	nbQuestionsATirer: PropTypes.number,
	nbQuestionsNouvellesATirer: PropTypes.number,
	nbQuestionsJeunesATirer: PropTypes.number,
	nbQuestionsMaturesATirer: PropTypes.number,
	parametrable: PropTypes.bool,
	type: PropTypes.number,
	parametre: PropTypes.bool,
	nbQuestionsTotal: PropTypes.number,
	nbQuestionsDisponibles: PropTypes.number,
	nbQuestionsNouvellesDisponibles: PropTypes.number,
	nbQuestionsJeunesDisponibles: PropTypes.number,
	nbQuestionsMaturesDisponibles: PropTypes.number,
})

export const topicShape = PropTypes.shape({
	libelle: PropTypes.string,
	codeRechercheQuestions: PropTypes.string,
	cleNoeudDansMap: PropTypes.string,
	idTopicOuParagraphe: PropTypes.number,
	idScript: PropTypes.number,
	code: PropTypes.string,
	id: PropTypes.number,
	nbQuestionsATirer: PropTypes.number,
	nbQuestionsNouvellesATirer: PropTypes.number,
	nbQuestionsJeunesATirer: PropTypes.number,
	nbQuestionsMaturesATirer: PropTypes.number,
	parametrable: PropTypes.bool,
	type: PropTypes.number,
	topicSansParagraphe: PropTypes.bool,
	parametre: PropTypes.bool,
	lisetParagraphes: PropTypes.arrayOf(paragrapheShape),
	nbQuestionsTotal: PropTypes.number,
	nbQuestionsDisponibles: PropTypes.number,
	nbQuestionsNouvellesDisponibles: PropTypes.number,
	nbQuestionsJeunesDisponibles: PropTypes.number,
	nbQuestionsMaturesDisponibles: PropTypes.number,
})

export const matiereShape = PropTypes.shape({
	id: PropTypes.number,
	libelle: PropTypes.string,
	codeRechercheQuestions: PropTypes.string,
	cleNoeudDansMap: PropTypes.string,
	code: PropTypes.string,
	listeTopics: PropTypes.arrayOf(topicShape)
})

export const matiereReferenceShape = PropTypes.shape({
	id: PropTypes.number,
	libelle: PointerEvent.string,
	code: PropTypes.string
})

export const questionLightShape = PropTypes.shape({
	idOceane: PropTypes.number,
	idExamenType: PropTypes.number,
	numeroEuropeen: PropTypes.string,
	ligneDeProgramme: PropTypes.string,
	occurenceVersionBanque: PropTypes.number,
	dateExploitationVersionBanque: PropTypes.string,
	nbReponsesJuste: PropTypes.number,
	nbReponsesFausse1: PropTypes.number,
	nbReponsesFausse2: PropTypes.number,
	nbReponsesFausse3: PropTypes.number,
	nbAucuneReponse: PropTypes.number,
	pourcentageReponseJuste: PropTypes.number,
	pourcentageReponseFausse1:PropTypes.number,
	pourcentageReponseFausse2:PropTypes.number,
	pourcentageReponseFausse3:PropTypes.number
})

export const sessionShape = PropTypes.shape({
	id: PropTypes.number,
	libelle: PropTypes.string,
	exceptionnelle: PropTypes.bool
})

export const epreuveSessionShape = PropTypes.shape({
	id: PropTypes.number,
	libelleEpreuveType: PropTypes.string,
	etat: PropTypes.number
})

export const questionAffectationShape = PropTypes.shape({
	idAffectationExamen: PropTypes.number,
	idExamenType: PropTypes.number,
	libelleExamen: PropTypes.string,
	dureeExamen: PropTypes.string,
	score: PropTypes.number,
	canAffect: PropTypes.bool,
	affectee: PropTypes.bool
})

export const questionReponseShape = PropTypes.shape({
	idQuestion: PropTypes.number,
	libelleFrancais: PropTypes.string,
	libelleAnglais: PropTypes.string
})

export const questionExpertiseShape = PropTypes.shape({
	idQuestion: PropTypes.number,
	numeroLigneProgramme: PropTypes.string,
	libelleLigneProgramme: PropTypes.string,
	reponseA: questionReponseShape,
	reponseB: questionReponseShape,
	reponseC: questionReponseShape,
	reponseD: questionReponseShape,
	affectations: PropTypes.arrayOf(questionAffectationShape)
})

export const questionHistoriqueShape = PropTypes.shape({
	dateAction: PropTypes.string,
	typeAction: PropTypes.number,
	nomPrenomUtilisateur: PropTypes.string,
	commentaire: PropTypes.string
})

export const questionEtatGlobalShape = PropTypes.shape({
	idQuestion: PropTypes.number,
	nomPrenomVisaRDB: PropTypes.string,
	uniquementHelicoptere: PropTypes.bool,
	boutonVisaRDBVisible: PropTypes.bool,
	listeDernieresActions: PropTypes.arrayOf(questionHistoriqueShape)
})

export const importKSAShape = PropTypes.shape({
	id: PropTypes.number,
	matiere: PropTypes.string,
	version: PropTypes.string,
	date: PropTypes.string,
	fichierCharge: PropTypes.string,
	compteRendu: PropTypes.string,
	etat: PropTypes.number
})