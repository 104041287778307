import {
	Actions,
	Button,
	checkAuthorities,
	Error,
	injectSession,
	Input,
	INS_ETAT_EPREUVE_INSCRIPTION,
	OPM_CODE_RETOUR,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	SmallSelect,
	StyledTypography,
	injectFormSnackbar
} from '@oceane/ui'
import { injectToolbarData, Title } from 'isotope-client'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { loadEpreuve, loadSiteEcran } from './services/injectSiteEcran'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes from 'prop-types'
import {
	checkFileExist,
	enregistrerDetails,
	putPanne,
	reouvrirConnexionsEpreuve,
	validerServeurLocal
} from './services/siteEcranAction'
import PopupAskForAction, { TYPE_ACTION } from './popup/PopupAskForAction'
import { injectIntl } from 'react-intl'
import { EXAMEN_CANDIDAT_STATUT_DOSSIER, EXAMEN_SPECIFIQUE } from '../../../utils/consts'

const DetailEpreuveEcran = ({
								submitting,
								initialValues,
								putPanne,
								change,
								validerServeurLocal,
								match,
								reouvrirConnexionsEpreuve,
								checkFileExist,
								handleSubmit,
								enregistrerDetails,
								snackSuccess,
								snackError
							}) => {
	const [openPopupError, setOpenPopupError] = React.useState(false)
	const [popupError, setPopupError] = React.useState({
		title: '',
		typeAction: null,
		callback: () => {
		}
	})
	const [customError, setCustomError] = React.useState(null)
	const [loadingAcces, setLoadingAcces] = React.useState(false)
	const [loadingConnexion, setLoadingConnexion] = React.useState(false)

	const idSite = match.params.id
	const date = match.params.date
	const idEpreuve = match.params.idEpreuve

	const panne = () => {
		return putPanne(initialValues.id)
			.then(() => {
				change('formattedEtat', 'Panne')
				snackSuccess('La panne a bien été prise en compte')
			})
			.catch(() => snackError('Une erreur est survenue'))
	}

	const handleOpenPopup = (title, typeAction, callback) => {
		setOpenPopupError(true)
		setPopupError({
			title,
			typeAction,
			callback
		})
	}

	const ouvrirAcces = () => download(`/files/sitesEcran/${idSite}/ouvertureAcces/${idEpreuve}?date=${date}`)

	const handleOuvrirAcces = () => {
		setLoadingAcces(true)
		return validerServeurLocal(idSite)
			.then(result => {
				setLoadingAcces(false)
				switch (result) {
					case OPM_CODE_RETOUR.OK:
						return ouvrirAcces()
					case OPM_CODE_RETOUR.CONNECTION_REFUSED:
						return handleOpenPopup('Accès épreuve', TYPE_ACTION.ACTION_1, () => ouvrirAcces())
					default:
						return handleOpenPopup('Accès épreuve', TYPE_ACTION.ACTION_2, () => ouvrirAcces())
				}
			})
			.catch(() => setLoadingAcces(false))
	}

	const handleReouvrirConnexions = () =>
		reouvrirConnexionsEpreuve(idEpreuve)
			.then(() => snackSuccess('La connexion a bien été réouverte'))
			.catch(() => snackError('Une erreur est survenue'))

	const handleValiderServeurLocalEtReouvrirConnexions = () => {
		setLoadingConnexion(true)
		return validerServeurLocal(idSite)
			.then(result => {
				setLoadingConnexion(false)
				switch (result) {
					case OPM_CODE_RETOUR.OK:
						return handleReouvrirConnexions()
					case OPM_CODE_RETOUR.CONNECTION_REFUSED:
						return handleOpenPopup('Re-ouvrir connexions', TYPE_ACTION.ACTION_1, () => handleReouvrirConnexions())
					default:
						return handleOpenPopup('Re-ouvrir connexions', TYPE_ACTION.ACTION_2, () => handleReouvrirConnexions())
				}
			})
			.catch(() => setLoadingConnexion(false))
	}

	const clearPopupContent = () => {
		setPopupError({
			titre: '',
			typeAction: null,
			callback: () => {
			}
		})
	}

	const lettreDeResultat = () => {
		return checkFileExist(idEpreuve, idSite, date)
			.then(() =>
				download(`/files/sitesEcran/${idSite}/lettreResultat/${idEpreuve}?date=${date}`)
			)
			.catch(e => {
				setCustomError(e._error)
				snackError('Le fichier est absent')
			})
	}

	const enregistrer = (values) => {
		return enregistrerDetails(values, idSite, idEpreuve, date)
			.then(({ result }) => {
				change('formattedEtat', result.libelleEtat)
			})
	}

	return (
		<>
			<Error error={customError} />
			<Title value="Candidat" />
			<ResponsiveForm>
				<Grid container>
					<Grid item xs={12}>
						<Field name="civilite" label="Civilité" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="nom" label="Nom" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="prenoms" label="Prénoms" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="dateDeNaissance" label="Date de naissance" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="villeDeNaissance" label="Ville de naissance" component={Input} disabled />
					</Grid>
				</Grid>
			</ResponsiveForm>
			<Title value="Epreuve" />
			<ResponsiveForm>
				<Grid container>
					<Grid item xs={6}>
						<Field name="numeroInscription" label="N° inscription" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="formattedEtat" label="Etat" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="libelleExamen" label="Examen" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="libelleEpreuve" label="Epreuve" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="dateEpreuve" label="Date" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						<Field name="horairesPrevus" label="Horaires" component={Input} disabled />
					</Grid>
					<Grid item xs={6}>
						{!initialValues.examenStandart &&
						<Field name="note" label="Note" component={SmallSelect} disabled={!initialValues.modifiable}>
							{[...Array(21).keys()].map(e =>
								<MenuItem key={e} value={e}>{e}</MenuItem>
							)}
						</Field>
						}
					</Grid>
					<Grid item xs={6}>
						{!initialValues.examenStandart &&
						<Field
							name="code"
							label="Code"
							component={Input}
							disabled={!initialValues.modifiable}
							inputProps={{ maxLength: 50 }}
						/>
						}
					</Grid>
				</Grid>
			</ResponsiveForm>
			<Actions>
				{
					initialValues.examenStandart
					&& (initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.AUTORISEE || initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.PRESENCE)
					&& (
						initialValues.examenCandidatStatutDossier === null
						|| initialValues.examenCandidatStatutDossier === undefined
						|| initialValues.examenCandidatStatutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
					)
					&& (
						<Button
							loading={loadingAcces}
							color="primary"
							onClick={handleOuvrirAcces}
						>
							Accès épreuve
						</Button>

					)
				}
				{initialValues.examenStandart && initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.PRESENCE && <Button
					loading={loadingConnexion}
					color="primary"
					onClick={handleValiderServeurLocalEtReouvrirConnexions}
				>
					Ré-ouvrir connexion
				</Button>}
				{initialValues.examenStandart && initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.ABSENCE && <Button
					loading={submitting}
					color="primary"
					onClick={panne}
				>
					Panne
				</Button>}
				{!initialValues.examenStandart && initialValues.modifiable && <Button
					loading={submitting}
					color="primary"
					onClick={handleSubmit(enregistrer)}
				>
					Enregistrer
				</Button>}
				{(initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.REUSSIE || initialValues.etat === INS_ETAT_EPREUVE_INSCRIPTION.ECHOUEE) &&
				<Button
					loading={submitting}
					color="primary"
					onClick={lettreDeResultat}
				>
					Lettre de résultats
				</Button>}
				<Button
					loading={submitting}
					color="primary"
					variant="contained"
					onClick={() => download(`/files/candidats/${initialValues.idCandidat}/inscriptions/${initialValues.idInscription}/ficheInscription`)}
				>
					Inscription
				</Button>
			</Actions>
			<PopupAskForAction
				open={openPopupError}
				closePopup={() => setOpenPopupError(false)}
				clearPopupContent={clearPopupContent}
				{...popupError}
			/>
		</>
	)
}

const mapStateToProps = (state, { epreuve, intl }) => {
	// RG4120/63626
	const etat = (
		epreuve.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS
		&& epreuve.examenCandidatStatutDossier !== EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
	) ? 'catsNonValide' : epreuve.etat
	return {
		initialValues: {
			...epreuve,
			formattedEtat: epreuve && epreuve.etat && intl.formatMessage({ id: `references.sitesEcran.insEtatEpreuveInscription.${etat}` })
		} || {}
	}
}

DetailEpreuveEcran.propTypes = {
	putPanne: PropTypes.func,
	initialValues: PropTypes.object,
	validerServeurLocal: PropTypes.func,
	reouvrirConnexionsEpreuve: PropTypes.func,
	getLettreDeResultat: PropTypes.func,
	enregistrerDetails: PropTypes.func
}

const actions = {
	putPanne,
	validerServeurLocal,
	reouvrirConnexionsEpreuve,
	checkFileExist,
	enregistrerDetails
}

export default compose(
	loadSiteEcran,
	loadEpreuve,
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectIntl,
	connect(mapStateToProps, actions),
	injectSession,
	reduxForm({
		form: 'DetailEpreuveEcranForm',
		enableReinitialize: true
	}),
	injectToolbarData(({ site }) => ({
		title: 'Gestion site écran',
		subheader: {
			filArianeEntries: [{
				text: `Site : ${site.libelle}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs', success: 'L\'épreuve a bien été modifiée' })
)(DetailEpreuveEcran)
