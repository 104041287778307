import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
	Actions,
	Button,
	EmptyResult,
	EPREUVE_EXAMEN_CANDIDAT_ETAT,
	epreuveExamenCandidatShape,
	Error,
	getExamenCandidatSelectionne,
	getIdCandidat,
	Input,
	InputDisabled,
	organismeShape,
	Select,
	TYPE_EXAMEN,
	typeFormationShape
} from '@oceane/ui'
import { FormCheckbox, FormSwitch, getStyles, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { injectCompteClient } from '../../../references/compteClient/services/injectCompteClient'
import {
	autoriserEpreuveExamenCandidat,
	getEpreuvesExamenCandidat,
	getListeDesOrganismesDeFormationActives,
	getOrganismesExamenType,
	getTypesFormations,
	modifierCompteClient,
	modifierEpreuvesExamenCandidat,
	refuserEpreuveExamenCandidat,
	resetEpreuvesExamenCandidat,
	resetTypesFormations,
	toggleEquivalence
} from '../services/formationEquivalencesActions'
import * as selectors from '../services/formationEquivalencesSelector'
import FormationSuivieActions from './FormationSuivieActions'
import moment from 'moment'

const styles = (theme) => getStyles({
	row: {
		minHeight: 60,
		borderBottom: '1px solid rgba(224, 224, 224, 1)' // Diviseur entre les lignes du "tableau"
	},
	header: { // On est obligé de simuler les header d'une table
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 13,
		paddingBottom: 15,
		paddingTop: 15
	},
	headerBox: {
		borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	checkbox: {
		marginTop: 5,
		marginBottom: -5,
		paddingLeft: 14
	}
})

const FormationsEquivalences = ({
	                                change,
	                                classes,
	                                epreuves,
	                                idCandidat,
	                                snackError,
	                                submitting,
	                                handleSubmit,
	                                snackSuccess,
	                                comptesClients,
	                                typesFormations,
	                                organismesActives,
	                                examenSelectionne,
	                                organismesExamenType,
	                                epreuvesSelectionnees,
	                                epreuvesExamenCandidat,
	                                toggleEquivalenceAction,
	                                getTypesFormationsAction,
	                                modifierCompteClientAction,
	                                resetTypesFormationsAction,
	                                getOrganismesExamenTypeAction,
	                                getEpreuvesExamenCandidatAction,
	                                resetEpreuvesExamenCandidatAction,
	                                refuserEpreuveExamenCandidatAction,
	                                autoriserEpreuveExamenCandidatAction,
	                                modifierEpreuvesExamenCandidatAction,
	                                getListeDesOrganismesDeFormationActivesAction
                                }) => {

	React.useEffect(() => {
		if (!!idCandidat && !!examenSelectionne && epreuvesExamenCandidat.length === 0) {
			getEpreuvesExamenCandidatAction(idCandidat, examenSelectionne.id)
				.then(({ listeEpreuves }) => {
					change('listeEpreuves', listeEpreuves.map(epreuve => ({
						...epreuve,
						formationSuivie: false
					})))
					// On se sert de la liste des épreuves (qui n'en contient qu'une dans le cas
					// d'un examen pratique) pour aller chercher le type formation associé à l'organisme
					// de formation
					if (examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE && listeEpreuves[0].idOrganisme) {
						getTypesFormationsAction(listeEpreuves[0].idOrganisme, examenSelectionne.examenTypeId)
							.then(({ typesFormations }) => {
								change('idTypeFormation', listeEpreuves[0].idTypeFormation)
								change('organisme', listeEpreuves[0].idOrganisme)
								change('referenceATO', listeEpreuves[0].referenceAto)
							})
					}
				})
				.catch(() => {
					change('listeEpreuves', [])
				})
			if (examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE) {
				getOrganismesExamenTypeAction(examenSelectionne.examenTypeId)
			}
		}
		// Liste des organismes pour la modification d'épreuves
		getListeDesOrganismesDeFormationActivesAction()
		return () => {
			resetEpreuvesExamenCandidatAction()
		}
	}, [])

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [menuContent, setMenuContent] = React.useState(null)
	const [error, setError] = React.useState(null)
	const isMenuOpen = Boolean(anchorEl)

	/**
	 * Création du menu des états qu'il est possible de sélectionner pour une épreuve
	 * @param event évènement JS
	 * @param etatApprobation état courant de l'épreuve
	 * @param idEpreuve ID de l'épreuve
	 */
	const handleClickChangerEtat = (event, etatApprobation, idEpreuve) => {
		setAnchorEl(event.currentTarget)
		const menuItems = []
		if (etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.EN_COURS || etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.AUTORISEE) {
			menuItems.push(<MenuItem key={EPREUVE_EXAMEN_CANDIDAT_ETAT.REFUSEE}
									 onClick={() => handleCloseChangerEtat(idEpreuve, EPREUVE_EXAMEN_CANDIDAT_ETAT.REFUSEE)}>Refuser</MenuItem>)
		}
		if (etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.EN_COURS || etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.REFUSEE) {
			menuItems.push(<MenuItem key={EPREUVE_EXAMEN_CANDIDAT_ETAT.AUTORISEE}
									 onClick={() => handleCloseChangerEtat(idEpreuve, EPREUVE_EXAMEN_CANDIDAT_ETAT.AUTORISEE)}>Autoriser</MenuItem>)
		}
		setMenuContent(menuItems)
	}

	/**
	 * Fermeture du menu des états et application de l'action choisie s'il y a lieu
	 * @param idEpreuve ID de l'épreuve à modifier
	 * @param nouvelEtat état par lequel remplacer l'état actuel
	 */
	const handleCloseChangerEtat = (idEpreuve, nouvelEtat) => {
		if (nouvelEtat !== null && idEpreuve !== null) {
			if (nouvelEtat === EPREUVE_EXAMEN_CANDIDAT_ETAT.REFUSEE) {
				refuserEpreuveExamenCandidatAction(examenSelectionne.id, idEpreuve, idCandidat)
					.catch(() => snackError())
			} else if (nouvelEtat === EPREUVE_EXAMEN_CANDIDAT_ETAT.AUTORISEE) {
				autoriserEpreuveExamenCandidatAction(examenSelectionne.id, idEpreuve, idCandidat)
					.catch(e => {
						snackError()
						if (e && e.bodyError && e.bodyError.id) {
							setError(e.bodyError)
						}
					})
			}
		}
		setAnchorEl(null)
	}

	/**
	 * Gestion de la soumission du formulaire "Formation" dans le cas d'un examen pratique
	 */
	const submitModifierFormationPratique = (values) => {
		const newValues = { ...values }
		newValues.epreuvesChangerFormation = epreuvesExamenCandidat.map(e => e.idEpreuveType)
		newValues.epreuvesSelectionnees = epreuvesExamenCandidat.map(e => e.idEpreuveType)
		modifierEpreuvesExamenCandidatAction(examenSelectionne.id, idCandidat, newValues)
			.then(() => {
				setError(null)
				snackSuccess()
			})
			.catch(e => {
				snackError()
				if (!!e.bodyError) {
					setError(e.bodyError.globalErrors[0].code)
				}
				throw e
			})
	}

	// RG : si l'examen a un indicateur candidat libre à vrai,
	// alors la cellule est toujours vierge
	const getEtatApprobation = (etat) => examenSelectionne.candidatLibre ? '' :
		<FormattedMessage id={`candidats.profil.examens.etatApprobationEpreuve.${etat}`} />

	/**
	 * Gestion de l'interrupteur "Equivalence"
	 */
	const handleCheckEquivalence = (event, idEpreuve, field) => {
		toggleEquivalenceAction(examenSelectionne.id, idEpreuve, event.target.checked, idCandidat)
			.catch(e => {
				snackError()
				change(`${field}.equivalence`, !event.target.checked)
				if (e && e.bodyError && e.bodyError.id) {
					setError(e.bodyError)
				}
			})
	}

	/**
	 * Gestion de l'incidence du cochage d'une épreuve sur la case "sélectionner tout"
	 */
	const handleCheckFormationSuivie = (event) => {
		if (!event.target.checked) {
			change('selectionnerTout', false)
		}
	}

	/**
	 * Gestion de la case "sélectionner tout"
	 */
	const handleCheckSelectionnerTout = (event) => {
		change('listeEpreuves', epreuves.map(epreuve => ({
			...epreuve,
			formationSuivie: event.target.checked
		})))
	}

	const submitCompteClient = (values) => modifierCompteClientAction(examenSelectionne.id, idCandidat, values.compteClient)
		.then(() => snackSuccess())
		.catch(() => snackError())

	const renderEpreuves = () => epreuvesExamenCandidat.map((epreuve, index) => {
		return <Grid key={index} container direction="row" className={classes.row}>
			{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE && <Grid item xs={1} className={classes.checkbox}>
				<Field
					name={`listeEpreuves[${index}].formationSuivie`}
					component={FormCheckbox}
					onChange={handleCheckFormationSuivie}
				/>
			</Grid>}
			{examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE ?
				<Grid item xs={2}><Typography>{epreuve.libelleCourtEpreuveType}</Typography></Grid> :
				<Grid item xs={1}><Typography>{epreuve.libelleCourtEpreuveType}</Typography></Grid>}
			<Grid item xs={3}><Typography>{epreuve.libelleEpreuveType}</Typography></Grid>
			<Grid item xs={2}><Typography>{epreuve.libelleOrganisme}</Typography></Grid>
			{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE && <Grid item container direction="row" xs={2}>
				<Grid item xs={5}><Typography>{getEtatApprobation(epreuve.etatApprobation)}</Typography></Grid>
				{!examenSelectionne.candidatLibre &&
				(epreuve.etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.EN_COURS ||
					epreuve.etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.AUTORISEE ||
					epreuve.etatApprobation === EPREUVE_EXAMEN_CANDIDAT_ETAT.REFUSEE) && <Grid item xs={7}><IconButton
					id={`id_${index}`}
					aria-controls="menuChangerEtat"
					onClick={(event) => handleClickChangerEtat(event, epreuve.etatApprobation, epreuve.id)}
				>
					<MoreVertIcon />
				</IconButton></Grid>}
			</Grid>}
			{examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE &&
			<Grid item xs={2}><Typography>{epreuve.libelleTypeFormation}</Typography></Grid>}
			<Grid item xs={2}><Typography>{epreuve.referenceAto}</Typography></Grid>
			{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE && <Grid item xs={1}>
				<Field
					name={`listeEpreuves[${index}].equivalence`}
					component={FormSwitch}
					color="primary"
					onChange={(event, value) => handleCheckEquivalence(event, epreuve.id, `listeEpreuves[${index}]`)}
				/>
			</Grid>}
		</Grid>
	})

	return (
		<React.Fragment>
			{examenSelectionne.typeExamen === TYPE_EXAMEN.STANDARD && <React.Fragment>
				<Title value="Compte client" />
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
				>
					<Field
						id="compteClient"
						name="compteClient"
						component={Select}
						label="Compte client"
						displayEmpty
					>
						<MenuItem value="" />
						{comptesClients.map(compte => <MenuItem key={compte.id}
																value={compte.id}>{compte.libelle}</MenuItem>)}
					</Field>
					<Button
						key="save"
						color="primary"
						variant="contained"
						loading={submitting}
						onClick={handleSubmit(submitCompteClient)}
					>
						Enregistrer
					</Button>
				</Grid>
			</React.Fragment>}

			{examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE && <React.Fragment>
				<Title value="Formation" />
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="flex-start"
				>
					<Field
						id="organisme"
						name="organisme"
						component={Select}
						label="Organisme *"
						onChange={(event) => {
							const idOrg = event.target.value
							if (!!idOrg) {
								getTypesFormationsAction(event.target.value, examenSelectionne.examenTypeId)
									.then(({ typesFormations }) => {
										change('idTypeFormation', typesFormations[0].id)
									})
							} else {
								resetTypesFormationsAction()
								change('referenceATO', '')
							}
						}}
						displayEmpty
					>
						<MenuItem value="" />
						{organismesExamenType.map(o => <MenuItem key={o.id} value={o.id}>{o.libelleLong}</MenuItem>)}
					</Field>

					<Field
						id="idTypeFormation"
						name="idTypeFormation"
						component={Select}
						label="Type de formation *"
						disabled={typesFormations.length === 0}
						displayEmpty
					>
						{typesFormations.map(tf => <MenuItem key={tf.id} value={tf.id}>
							{tf.libelle}
						</MenuItem>)}
					</Field>

					<Field
						id="referenceATO"
						name="referenceATO"
						component={Input}
						disabled={typesFormations.length === 0}
						label="Référence ATO"
						inputProps={{
							maxLength: 50
						}}
					/>

					<Actions>
						<Button
							key="save"
							color="primary"
							variant="contained"
							loading={submitting}
							onClick={handleSubmit(submitModifierFormationPratique)}
						>
							Enregistrer
						</Button>
					</Actions>
				</Grid>
			</React.Fragment>}

			<Title value="Epreuves de l'examen" />
			{error && <Error error={[error]} />}
			{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE && <FormationSuivieActions
				epreuvesSelectionnees={epreuvesSelectionnees}
				organismesActives={organismesActives}
				idCandidat={idCandidat}
				idExamenCandidat={examenSelectionne.id}
				setError={setError}
			/>}

			{epreuvesExamenCandidat.length > 0 && <React.Fragment>
				<Grid container direction="row" className={classes.headerBox}>
					{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE &&
					<Grid item md={1} className={classes.checkbox}>
						<Field
							name="selectionnerTout"
							component={FormCheckbox}
							onChange={handleCheckSelectionnerTout}
						/></Grid>}
					{examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE ?
						<Grid item md={2}><Typography className={classes.header}>Code épreuve</Typography></Grid> :
						<Grid item md={1}><Typography className={classes.header}>Code épreuve</Typography></Grid>}
					<Grid item md={3}><Typography className={classes.header}>Libellé épreuve</Typography></Grid>
					<Grid item md={2}><Typography className={classes.header}>Organisme</Typography></Grid>
					{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE && <Grid item md={2}>
						<Typography className={classes.header}>Etat d'approbation</Typography>
					</Grid>}
					{examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE && <Grid item md={2}>
						<Typography className={classes.header}>Type de formation</Typography>
					</Grid>}
					<Grid item md={2}><Typography className={classes.header}>Référence ATO</Typography></Grid>
					{examenSelectionne.typeExamen !== TYPE_EXAMEN.PRATIQUE &&
					<Grid item md={1}><Typography className={classes.header}>Equivalence</Typography></Grid>}
				</Grid>
				{renderEpreuves()}
			</React.Fragment>}
			{epreuvesExamenCandidat.length === 0 && <EmptyResult />}
			{examenSelectionne.epreuveKSA && <InputDisabled
				fullWidth
				label="Epreuve 100 KSA"
			>
				{examenSelectionne.validateurATO ? `Validée le ${moment(examenSelectionne.dateValidationKSA).format('DD/MM/YYYY')} par ${examenSelectionne.validateurATO}` : 'Non validée'}
			</InputDisabled>}

			<Menu
				id="menuChangerEtat"
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={() => handleCloseChangerEtat(null, null)}
			>
				{menuContent}
			</Menu>
		</React.Fragment>
	)
}

FormationsEquivalences.defaultProps = {
	epreuvesExamenCandidat: [],
	organismesActives: [],
	comptesClients: []
}

FormationsEquivalences.propTypes = {
	epreuves: PropTypes.arrayOf(epreuveExamenCandidatShape),
	idCandidat: PropTypes.number,
	typesFormations: PropTypes.arrayOf(typeFormationShape),
	organismesExamenType: PropTypes.arrayOf(organismeShape),
	epreuvesSelectionnees: PropTypes.arrayOf(epreuveExamenCandidatShape),
	epreuvesExamenCandidat: PropTypes.arrayOf(epreuveExamenCandidatShape),
	organismesActives: PropTypes.arrayOf(organismeShape)
}

const mapStateToProps = (state) => {
	const listeEpreuves = formValueSelector('formationsEquivalencesForm')(state, 'listeEpreuves')
	return {
		epreuves: listeEpreuves,
		idCandidat: getIdCandidat(state),
		typesFormations: selectors.getTypesFormations(state),
		organismesExamenType: selectors.getOrganismesExamenType(state),
		epreuvesSelectionnees: !!listeEpreuves ? listeEpreuves.filter(epreuve => epreuve.formationSuivie) : [],
		epreuvesExamenCandidat: selectors.getEpreuvesExamenCandidat(state),
		organismesActives: selectors.getOrganismesActives(state),
		initialValues: {
			compteClient: !!getExamenCandidatSelectionne(state).idCompteClientDCS ? getExamenCandidatSelectionne(state).idCompteClientDCS : ''
		}
	}
}

const actions = {
	toggleEquivalenceAction: toggleEquivalence,
	getTypesFormationsAction: getTypesFormations,
	resetTypesFormationsAction: resetTypesFormations,
	modifierCompteClientAction: modifierCompteClient,
	getOrganismesExamenTypeAction: getOrganismesExamenType,
	getEpreuvesExamenCandidatAction: getEpreuvesExamenCandidat,
	resetEpreuvesExamenCandidatAction: resetEpreuvesExamenCandidat,
	refuserEpreuveExamenCandidatAction: refuserEpreuveExamenCandidat,
	autoriserEpreuveExamenCandidatAction: autoriserEpreuveExamenCandidat,
	modifierEpreuvesExamenCandidatAction: modifierEpreuvesExamenCandidat,
	getListeDesOrganismesDeFormationActivesAction: getListeDesOrganismesDeFormationActives
}

export default compose(
	injectIntl,
	injectCompteClient,
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'formationsEquivalencesForm'
	}),
	injectSnackActions
)(FormationsEquivalences)
