import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { getTypesExamenAutorises } from './miseEnExploitationActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

export const injectTypesExamensAutorises = WrappedComponent => {
	const TypesExamenAutorisesInjector = ({ getTypesExamenAutorises, snackSuccess, snackError, ...props }) => {
		const [typesExamen, setTypesExamens] = React.useState([])
		const [loading, setLoading] = React.useState(true)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			getTypesExamenAutorises()
				.then(({ examensAutorises }) => {
					setTypesExamens(examensAutorises)
					setLoading(false)
				})
				.catch(() => {
					setError(true)
					setLoading(false)
					snackError()
				})
		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent typesExamen={typesExamen} {...props} />
	}

	TypesExamenAutorisesInjector.propTypes = {
		getTypesExamenAutorises: PropTypes.func,
		snackSuccess: PropTypes.func,
		snackError: PropTypes.func
	}

	const actions = {
		getTypesExamenAutorises
	}

	return compose(
		injectSnackActions,
		connect(undefined, actions)
	)(TypesExamenAutorisesInjector)
}
