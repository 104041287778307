import {
    Actions,
    Autocomplete,
    Button,
    checkAuthorities,
    DateInput,
    getVilles,
    injectSession,
    Input, isCodePostalOk,
    isDateOk,
    isEmailOk,
    isPrenomOuNomOk, isTelephoneOk,
    ResponsiveForm,
    SmallSelect,
    PROFIL_UTILISATEUR,
    Link,
    injectFormSnackbar
} from '@oceane/ui'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MailIcon from '@material-ui/icons/Mail'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getStyles } from 'isotope-client'
import { Field, reduxForm } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { loadSurveillant } from '../services/surveillantInjector'
import { enregistrerSurveillant, supprimerSurveillant } from '../services/surveillantsActions'
import moment from 'moment'
import ConfirmationSuppressionSurveillantPopup from './ConfirmationSuppressionSurveillantPopup'

const style = () => getStyles({
    tooltip: {
        margin: '-15px 0 0 16px'
    },
    divider: {
        color: 'rgba(0,0,0,0.12)',
        marginBottom: 16
    }
})

const validate = (values) => {
    const errors = {}

    if (!values.civilite) {
        errors.civilite = { id: 'global.erreurs.obligatoire' }
    }

    if (!values.nom) {
        errors.nom = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isPrenomOuNomOk(values.nom)) {
            errors.nom = { id: 'global.erreurs.nom' }
        }
    }

    if (!values.prenom) {
        errors.prenom = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isPrenomOuNomOk(values.prenom)) {
            errors.prenom = { id: 'global.erreurs.nom' }
        }
    }

    if (!values.dateDeNaissance) {
        errors.dateDeNaissance = { id: 'global.erreurs.obligatoire' }
    } else {
        if (!isDateOk(moment(values.dateDeNaissance, 'YYYY-MM-DD').format('DD/MM/YYYY'))) {
            errors.dateDeNaissance = { id: 'global.erreurs.date.invalid' }
        }
    }

    if (values.mail && !isEmailOk(values.mail)) {
        errors.mail = { id: 'global.erreurs.formatEmail' }
    }

    if (values.codePostal && !isCodePostalOk(values.codePostal)) {
        errors.codePostal = { id: 'references.organismes.onglets.errors.codePostal' }
    }

    if (values.autreTelephone && !isTelephoneOk(values.autreTelephone)) {
        errors.autreTelephone = { id: 'global.erreurs.telephone' }
    }

    if (values.telephone && !isTelephoneOk(values.telephone)) {
        errors.telephone = { id: 'global.erreurs.telephone' }
    }

    if (values.telecopie && !isTelephoneOk(values.telecopie)) {
        errors.telecopie = { id: 'global.erreurs.telephone' }
    }

    return errors
}

const SurveillantEtatCivilOnglet = ({
                                        submitting,
                                        handleSubmit,
                                        getVilles,
                                        initialValues,
                                        classes,
                                        match,
                                        supprimerSurveillant,
                                        goToRecherche,
                                        snackSuccess,
                                        enregistrerSurveillant
                                    }) => {
    const [open, setOpen] = React.useState(false)
    const idSurveillant = match.params.id

    const supprimer = () =>
        supprimerSurveillant(idSurveillant)
            .then(() => {
                setOpen(false)
                goToRecherche()
                snackSuccess('Suppression du surveillant réussie')
            })

    const enregistrer = (values) => enregistrerSurveillant(values)

    return (
        <>
            <ResponsiveForm>
                <Field
                    name="civilite"
                    label="Civilité *"
                    component={SmallSelect}
                >
                    <MenuItem key={1} value={1}>M</MenuItem>
                    <MenuItem key={2} value={2}>Mme</MenuItem>
                    <MenuItem key={3} value={3}>Mlle</MenuItem>
                </Field>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Field name="nom" label="Nom *" component={Input} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field name="prenom" label="Prenom *" component={Input} />
                    </Grid>
                </Grid>
                <Field id="dateDeNaissance" name="dateDeNaissance" label="Date de naissance *" component={DateInput} />
                <Grid container direction="row">
                    <Field name="mail" label="Adresse électronique" component={Input} />
                    <Link href={`mailto:${initialValues.mail}`}>
                        <MailIcon className={classes.tooltip} color="primary" />
                    </Link>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Field name="ligne1" label="Ligne 1" component={Input} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field name="ligne2" label="Ligne 2" component={Input} />
                    </Grid>
                </Grid>
                <Field name="ligne3" label="Ligne 3" component={Input} />
                <Field name="codePostal" label="Code Postal" component={Input} />
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Field
                            id="ville"
                            name="ville"
                            component={Autocomplete}
                            getSuggestion={(length, value) => getVilles(value)}
                            label="Ville"
                            canLoadIfEmpty
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field name="pays" label="Pays" component={Input} />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Field name="telephone" label="Téléphone" component={Input} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field name="telecopie" label="Télécopie" component={Input} />
                    </Grid>
                </Grid>
                <Field name="autreTelephone" label="Autre téléphone" component={Input} />
                <Actions>
                    <Button
                        color="primary"
                        onClick={() => setOpen(true)}
                    >
                        Supprimer
                    </Button>
                    <Button
                        loading={submitting}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(enregistrer)}
                    >
                        Enregistrer
                    </Button>
                </Actions>
            </ResponsiveForm>
            <ConfirmationSuppressionSurveillantPopup open={open} onClose={() => setOpen(false)} supprimer={supprimer} />
        </>
    )
}

const actions = {
    getVilles,
    supprimerSurveillant,
    goToRecherche: () => dispatch => dispatch(push(`/surveillants`)),
    enregistrerSurveillant
}

SurveillantEtatCivilOnglet.propTypes = {
    getVilles: PropTypes.func,
    supprimerSurveillant: PropTypes.func,
    goToRecherche: PropTypes.func,
    enregistrerSurveillant: PropTypes.func
}

const mapStateToProps = (state, { surveillant }) => ({
    initialValues: surveillant
})

export default compose(
    loadSurveillant,
    checkAuthorities(
        PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
        PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
    ),
    connect(mapStateToProps, actions),
    reduxForm({
        form: 'DetailsSurveillant',
        enableReinitialize: true,
        validate
    }),
    injectSession,
    withStyles(style),
	injectFormSnackbar()
)(SurveillantEtatCivilOnglet)
