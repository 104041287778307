import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStyles, StaticDataTable, getToolbarData, setToolbarData, resetToolbarData } from 'isotope-client'
import { checkAuthorities, injectSession, PROFIL_UTILISATEUR, StyledTypography, DownloadIcon } from '@oceane/ui'
import { FormattedMessage } from 'react-intl'
import * as selectors from '../../services/sessionsPapierSelectors'
import { cleanSessionPapier } from '../../services/sessionsPapierActions'
import {
	injectDonneesGenerales,
	injectSiteSession
} from '../../services/sessionsPapierInjectors'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { ETAT_SESSION } from '../../../../../utils/consts'
import { push } from 'connected-react-router'

const styles = theme => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const filArianeEpreuves = (sessionPapier, site) => (sessionPapier && {
	title: 'Détail session',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography>Session : {sessionPapier.libelleSession}</StyledTypography>
				{sessionPapier.etat && <StyledTypography primary bold><FormattedMessage
					id={`references.sessions.etatPapier.${sessionPapier.etat}`} /></StyledTypography>}
			</>
		}, {
			render: () => <>
				<StyledTypography bold>Site : {site.libelle}</StyledTypography>
			</>
		}]
	}
})

const SessionPapierPage = ({ sessionPapier, toolbarData, changeToolbarData, cleanToolbarData, site, classes, goToCandidats }) => {
	const listeEpreuves = site.listeEpreuves

	React.useEffect(() => {
		changeToolbarData(toolbarData.id, filArianeEpreuves(sessionPapier, site))
		return () => cleanToolbarData(toolbarData.id)
	}, [sessionPapier.etat])

	return (
		<StaticDataTable
			data={listeEpreuves}
			headers={[{
				key: 'codeEpreuve',
				name: 'Code épreuve',
				sortable: false,
				render: data => {
					if (sessionPapier.etat === ETAT_SESSION.CONVOCATIONS_ENVOYEES) {
						return <Typography className={classes.libelleColumn} onClick={() => goToCandidats(sessionPapier.id, site.idSite, data.id)}>{data.codeEpreuve}</Typography>
					}
					return <Typography>{data.codeEpreuve}</Typography>
				}
			}, {
				key: 'libelle',
				name: 'Libellé épreuve',
				sortable: false
			}, {
				key: 'date',
				name: 'Date',
				sortable: false,
				render: data => <Typography>{moment(data.date).format('DD/MM/YYYY')}</Typography>
			}, {
				name: 'Candidats',
				sortable: false,
				render: data => <IconButton
					onClick={() => download(`/files/${sessionPapier.id}/pv-salle/${site.idSite}/candidats/${data.id}`)}
				>
					<DownloadIcon />
				</IconButton>
			}, {
				name: 'Présents',
				sortable: false,
				render: data => <IconButton
					onClick={() => download(`/files/${sessionPapier.id}/pv-salle/${site.idSite}/presents/${data.id}`)}
				>
					<DownloadIcon />
				</IconButton>
			}, {
				name: 'Absents',
				sortable: false,
				render: data => <IconButton
					onClick={() => download(`/files/${sessionPapier.id}/pv-salle/${site.idSite}/absents/${data.id}`)}
				>
					<DownloadIcon />
				</IconButton>
			}]}
		/>
	)
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state),
	toolbarData: getToolbarData(state)
})

const actions = {
	cleanSessionPapier,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	goToCandidats: (idSession, idSite, idEpreuve) => dispatch => dispatch(push(`/sessions-papier/${idSession}/epreuves/${idSite}/candidats/${idEpreuve}`))
}

SessionPapierPage.propTypes = {
	classes: PropTypes.object,
	sessionPapier: PropTypes.object,
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	cleanToolbarData: PropTypes.func,
	site: PropTypes.object
}

export default compose(
	injectSiteSession,
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	withStyles(styles),
	injectDonneesGenerales,
	connect(mapStateToProps, actions),
	injectSession
)(SessionPapierPage)
