import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { Input, SelectWithPlaceholder, SmallInput } from '@oceane/ui'
import * as administrationActions from '../services/administrationActions'
import * as administrationSelectors from '../services/administrationSelectors'
import { PROFILS } from '../services/administrationValues'

const style = theme => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '11px 20px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 892
	},
	input: {
		marginBottom: 0
	},
	select: {
		height: 33
	},
	tooltip: {}
})

const UtilisateursFiltres = ({ classes, nom, identifiant, profil, handleQuery, resetFilters }) => (
	<Paper elevation={0} square className={classes.filtres}>
		<Input
			name="nom"
			placeholder="Nom"
			meta={{}}
			value={nom}
			onChange={(e) => handleQuery('nom', e.target.value)}
			inputStyle={classes.input}
		/>
		<SmallInput
			name="identifiant"
			placeholder="Identifiant"
			meta={{}}
			value={identifiant}
			onChange={(e) => handleQuery('identifiant', e.target.value)}
			inputStyle={classes.input}
		/>
		<SelectWithPlaceholder
			value={profil}
			onChange={e => handleQuery('profil', e.target.value)}
			placeholder="Profil"
			emptyValue={-1}
			id="profil"
			selectClassName={classes.select}
		>
			{PROFILS.map(profil =>
				<MenuItem key={`profil-${profil.id}`} value={profil.id}>
					{profil.nom}
				</MenuItem>
			)}
		</SelectWithPlaceholder>
		<Button
			type="submit"
			color="primary"
			onClick={resetFilters}
		>
			Réinitialiser
		</Button>
	</Paper>
)

const mapStateToProps = state => ({
	nom: administrationSelectors.getNom(state),
	identifiant: administrationSelectors.getIdentifiant(state),
	profil: administrationSelectors.getProfil(state)
})

const actions = {
	handleQuery: administrationActions.handleQuery,
	resetFilters: administrationActions.resetFilters
}

UtilisateursFiltres.propTypes = {
	nom: PropTypes.string.isRequired,
	identifiant: PropTypes.string.isRequired,
	profil: PropTypes.number.isRequired,
	handleQuery: PropTypes.func.isRequired,
	resetFilters: PropTypes.func.isRequired
}

export default compose(
	connect(mapStateToProps, actions),
	withTheme(),
	withStyles(style)
)(UtilisateursFiltres)
