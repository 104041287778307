import withStyles from '@material-ui/core/styles/withStyles'
import { Actions, Button, checkAuthorities, injectSession, loadCentres, MultiSelect, PROFIL_UTILISATEUR, ResponsiveForm, injectFormSnackbar } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { loadSurveillantCentres } from '../services/surveillantCentresInjector'
import { enregistrerCentres } from '../services/surveillantsActions'

const style = () => getStyles({
	tooltip: {
		margin: '-15px 0 0 16px'
	},
	divider: {
		color: 'rgba(0,0,0,0.12)',
		marginBottom: 16
	}
})

const SurveillantEtatCivilOnglet = ({
	                                    submitting,
	                                    handleSubmit,
	                                    match,
	                                    centres,
	                                    enregistrerCentres
                                    }) => {
	const enregistrer = (values) => {
		let liste = []
		values.centres.forEach(it => {
			liste.push(it.id)
		})

		return enregistrerCentres(liste, match.params.id).catch(e => {
			throw new SubmissionError(e)
		})
	}

	return (
		<>
			<ResponsiveForm>
				<Field
					name="centres"
					label="Centres"
					component={MultiSelect}
					choices={centres}
				/>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(enregistrer)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
		</>
	)
}

const actions = {
	enregistrerCentres
}

SurveillantEtatCivilOnglet.propTypes = {
	enregistrerCentres: PropTypes.func
}

const mapStateToProps = (state, { surveillant, centres }) => ({
	initialValues: { centres: centres.filter(centre => surveillant.centres.includes(centre.id)) },
	centres: centres
})

export default compose(
	loadSurveillantCentres,
	loadCentres,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'CentresSurveillant',
		enableReinitialize: true
	}),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(SurveillantEtatCivilOnglet)
