import { createSelector } from 'reselect'
import { getReferences } from '../../../referencesSelectors'

const getLocalState = state => getReferences(state).nouvelOrganisme

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)

export const getOrganismeDetails = createSelector(
	getLocalState,
	state => state.organismeDetails
)

export const getOrganismeActif = createSelector(
	getLocalState,
	state => state.organismeActif
)
