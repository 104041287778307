import { fetchFactory } from 'isotope-client'

export const getListeAuteurs = () => fetchFactory('/questions/listeAuteurs')

export const getLignes = (codeLdp) => fetchFactory(`/questions/lignes?codeLdp=${codeLdp}`)

export const getFamilles = (codeMatiere) => fetchFactory(`/questions/familles?codeMatiere=${codeMatiere}`)

export const getQuestionIdByIdentifiant = (identifiant, exploitee) => fetchFactory(`/questions/identifiant/${identifiant}?exploitee=${exploitee}`)

export const getQuestionIdByNumeroEuropeen = (numeroEuropeen, exploitee) => fetchFactory(`/questions/numero-europeen/${numeroEuropeen}?exploitee=${exploitee}`)

export const creerQuestion = values => fetchFactory(`/questions`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getQuestionDonneesGenerales = (id, isExploitation) => fetchFactory(`/questions/${id}/donneesGenerales?isExploitation=${isExploitation}`)

export const saveQuestionDonneesGenerales = (id, values) => fetchFactory(`/questions/${id}/donneesGenerales`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getEnonces = (id, isExploitation) => fetchFactory(`/questions/${id}/enonces?isExploitation=${isExploitation}`)

export const saveQuestionEnonces = (id, values) => fetchFactory(`/questions/${id}/enonces`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getQuestionAffectations = (id, isExploitation) => fetchFactory(`/questions/${id}/affectations?isExploitation=${isExploitation}`)

export const creerAffectation = (id, values) => fetchFactory(`/questions/${id}/affectations`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const modifierAffectation = (id, values) => fetchFactory(`/questions/${id}/affectations/${values.idAffectationExamen}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const supprimerAffectation = (id, idExamenType) => fetchFactory(`/questions/${id}/affectations/${idExamenType}`, {
	method: 'delete'
})

export const getStatistiquesAffectation = (idOceane, idExamenType) => fetchFactory(`/statistiques/questions/${idOceane}/affectations/${idExamenType}/statistiques`)

export const getQuestionAnnexes = (id, isExploitation) => fetchFactory(`/questions/${id}/annexes?isExploitation=${isExploitation}`)

export const rendreDisponibleAnnexe = (idQuestion, idAnnexe) => fetchFactory(`/questions/${idQuestion}/annexes/${idAnnexe}/rendreDisponible`, { method: 'PUT' })

export const affecterAnnexe = (idQuestion, idAnnexe) => fetchFactory(`/questions/${idQuestion}/annexes/${idAnnexe}/affecter`, { method: 'PUT' })

export const getQuestionExpertise = (idQuestion, isExploitation) => fetchFactory(`/questions/${idQuestion}/expertise?isExploitation=${isExploitation}`)

export const getQuestionEtatGlobal = (idQuestion, isExploitation) => fetchFactory(`/questions/${idQuestion}/etatGlobal?isExploitation=${isExploitation}`)

export const refuserQuestion = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/refuser`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const accepterQuestion = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/accepter`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const supprimerQuestion = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/supprimer`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const appliquerQuestion = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/appliquer`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const restaurerQuestion = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/restaurer`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const setVisaRDB = (idQuestion, values) => fetchFactory(`/questions/${idQuestion}/visaRDB`, {
	method: 'put',
})

export const getQuestionComparatifImport = idQuestion => fetchFactory(`/questions/${idQuestion}/comparatifImport`)