import { createSelector } from 'reselect'
import { RES_ID_TYPE_SANCTION_EPREUVE } from '../../../../config/enums'
import moment from 'moment'

const getLocalState = state => state.gestionCopies

export const getInscription = createSelector(
	getLocalState,
	state => state.inscription
)

export const getListeEpreuves = createSelector(
	getInscription,
	inscription => inscription.listeEpreuves
)

export const getResultatEpreuve = createSelector(
	getLocalState,
	state => state.resultatEpreuve
)

export const getEtat = createSelector(
	getResultatEpreuve,
	resultatEpreuve => resultatEpreuve.etat
)

export const getFraude = createSelector(
	getResultatEpreuve,
	resultatEpreuve => ({
		idEpreuveInscription: resultatEpreuve.id,
		...resultatEpreuve.fraude,
		sanction: `${(resultatEpreuve.fraude && resultatEpreuve.fraude.sanction) || RES_ID_TYPE_SANCTION_EPREUVE.EPREUVE}`,
		dateFinSanction: resultatEpreuve.fraude && resultatEpreuve.fraude.dateFinSanction && moment(resultatEpreuve.fraude.dateFinSanction).format('YYYY-MM-DD')
	})
)

export const getMention = createSelector(
	getResultatEpreuve,
	resultatEpreuve => ({
		idEpreuveInscription: resultatEpreuve.id,
		...resultatEpreuve.mention,
		mention: `${resultatEpreuve.mention && resultatEpreuve.mention.mention}`
	})
)
