import * as api from './epreuveSujetApi'

export const GET_SESSIONS_REQUEST = 'EPREUVES_SUJET/GET_SESSIONS_REQUEST'
export const GET_SESSIONS_SUCCESS = 'EPREUVES_SUJET/GET_SESSIONS_SUCCESS'
export const GET_SESSIONS_ERROR   = 'EPREUVES_SUJET/GET_SESSIONS_ERROR'
export const getSessions = () => dispatch => {
	dispatch({ type: GET_SESSIONS_REQUEST })
	return api.getSessions()
		.then(sessions => dispatch({ type: GET_SESSIONS_SUCCESS, sessions }))
		.catch(e => {
			dispatch({ type: GET_SESSIONS_ERROR, e })
			throw e
		})
}

export const GET_EXAMENS_TYPES_REQUEST = 'EPREUVES_SUJET/GET_EXAMENS_TYPES_REQUEST'
export const GET_EXAMENS_TYPES_SUCCESS = 'EPREUVES_SUJET/GET_EXAMENS_TYPES_SUCCESS'
export const GET_EXAMENS_TYPES_ERROR   = 'EPREUVES_SUJET/GET_EXAMENS_TYPES_ERROR'
export const getExamensTypes = () => dispatch => {
	dispatch({ type: GET_EXAMENS_TYPES_REQUEST })
	return api.getExamensTypes()
		.then(examensTypes => dispatch({ type: GET_EXAMENS_TYPES_SUCCESS, examensTypes }))
		.catch(e => {
			dispatch({ type: GET_EXAMENS_TYPES_ERROR, e })
			throw e
		})
}

export const GET_EPREUVES_REQUEST = 'EPREUVES_SUJET/GET_EPREUVES_REQUEST'
export const GET_EPREUVES_SUCCESS = 'EPREUVES_SUJET/GET_EPREUVES_SUCCESS'
export const GET_EPREUVES_ERROR   = 'EPREUVES_SUJET/GET_EPREUVES_ERROR'
export const getListeEpreuves = (session) => dispatch => {
	dispatch({ type: GET_EPREUVES_REQUEST })
	return api.getListeEpreuves(session)
		.then(epreuves => dispatch({ type: GET_EPREUVES_SUCCESS, epreuves }))
		.catch(e => {
			dispatch({ type: GET_EPREUVES_ERROR, e })
			throw e
		})
}

export const VALIDER_SUJETS_REQUEST = 'EPREUVES_SUJET/VALIDER_SUJETS_REQUEST'
export const VALIDER_SUJETS_SUCCESS = 'EPREUVES_SUJET/VALIDER_SUJETS_SUCCESS'
export const VALIDER_SUJETS_ERROR   = 'EPREUVES_SUJET/VALIDER_SUJETS_ERROR'
export const valider = (idSession) => dispatch => {
	dispatch({ type: VALIDER_SUJETS_REQUEST })
	return api.valider(idSession)
		.then((result) => dispatch({ type: VALIDER_SUJETS_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: VALIDER_SUJETS_ERROR, e })
			throw e
		})
}

export const VALIDER_RAPIDE_REQUEST = 'EPREUVES_SUJET/VALIDER_RAPIDE_REQUEST'
export const VALIDER_RAPIDE_SUCCESS = 'EPREUVES_SUJET/VALIDER_RAPIDE_SUCCESS'
export const VALIDER_RAPIDE_ERROR   = 'EPREUVES_SUJET/VALIDER_RAPIDE_ERROR'
export const validerRapide = (idSession) => dispatch => {
	dispatch({ type: VALIDER_RAPIDE_REQUEST })
	return api.validerRapide(idSession)
		.then((result) => dispatch({ type: VALIDER_RAPIDE_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: VALIDER_RAPIDE_ERROR, e })
			throw e
		})
}

export const GET_SUJET_REQUEST = 'EPREUVES_SUJET/GET_SUJET_REQUEST'
export const GET_SUJET_SUCCESS = 'EPREUVES_SUJET/GET_SUJET_SUCCESS'
export const GET_SUJET_ERROR   = 'EPREUVES_SUJET/GET_SUJET_ERROR'
export const getSujet = (id) => dispatch => {
	dispatch({ type: GET_SUJET_REQUEST })
	return api.getSujet(id)
		.then((result) => dispatch({ type: GET_SUJET_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GET_SUJET_ERROR, e })
			throw e
		})
}

export const GENERER_SUJET_REQUEST = 'EPREUVES_SUJET/GENERER_SUJET_REQUEST'
export const GENERER_SUJET_SUCCESS = 'EPREUVES_SUJET/GENERER_SUJET_SUCCESS'
export const GENERER_SUJET_ERROR   = 'EPREUVES_SUJET/GENERER_SUJET_ERROR'
export const genererSujet = (id) => dispatch => {
	dispatch({ type: GENERER_SUJET_REQUEST })
	return api.genererSujet(id)
		.then((result) => dispatch({ type: GENERER_SUJET_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: GENERER_SUJET_ERROR, e })
			throw e
		})
}

export const SUPPRIMER_SUJET_REQUEST = 'EPREUVES_SUJET/SUPPRIMER_SUJET_REQUEST'
export const SUPPRIMER_SUJET_SUCCESS = 'EPREUVES_SUJET/SUPPRIMER_SUJET_SUCCESS'
export const SUPPRIMER_SUJET_ERROR   = 'EPREUVES_SUJET/SUPPRIMER_SUJET_ERROR'
export const supprimerSujet = (id) => dispatch => {
	dispatch({ type: SUPPRIMER_SUJET_REQUEST })
	return api.supprimerSujet(id)
		.then((result) => dispatch({ type: SUPPRIMER_SUJET_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: SUPPRIMER_SUJET_ERROR, e })
			throw e
		})
}

export const CHECK_ANNEXE_EXIST_REQUEST = 'EPREUVES_SUJET/CHECK_ANNEXE_EXIST_REQUEST'
export const CHECK_ANNEXE_EXIST_SUCCESS = 'EPREUVES_SUJET/CHECK_ANNEXE_EXIST_SUCCESS'
export const CHECK_ANNEXE_EXIST_ERROR   = 'EPREUVES_SUJET/CHECK_ANNEXE_EXIST_ERROR'
export const checkAnnexeExist = (id, nomFichier) => dispatch => {
	dispatch({ type: CHECK_ANNEXE_EXIST_REQUEST })
	return api.checkAnnexeExist(id, nomFichier)
		.then((result) => dispatch({ type: CHECK_ANNEXE_EXIST_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: CHECK_ANNEXE_EXIST_ERROR, e })
			throw e
		})
}

export const CHECK_ANNEXES_EXIST_REQUEST = 'EPREUVES_SUJET/CHECK_ANNEXES_EXIST_REQUEST'
export const CHECK_ANNEXES_EXIST_SUCCESS = 'EPREUVES_SUJET/CHECK_ANNEXES_EXIST_SUCCESS'
export const CHECK_ANNEXES_EXIST_ERROR   = 'EPREUVES_SUJET/CHECK_ANNEXES_EXIST_ERROR'
export const checkAnnexesExist = (idEpreuveSession) => dispatch => {
	dispatch({ type: CHECK_ANNEXES_EXIST_REQUEST })
	return api.checkAnnexesExist(idEpreuveSession)
		.then((result) => dispatch({ type: CHECK_ANNEXES_EXIST_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: CHECK_ANNEXES_EXIST_ERROR, e })
			throw e
		})
}

export const CHECK_SUJET_EXIST_REQUEST = 'EPREUVES_SUJET/CHECK_SUJET_EXIST_REQUEST'
export const CHECK_SUJET_EXIST_SUCCESS = 'EPREUVES_SUJET/CHECK_SUJET_EXIST_SUCCESS'
export const CHECK_SUJET_EXIST_ERROR   = 'EPREUVES_SUJET/CHECK_SUJET_EXIST_ERROR'
export const checkSujetExist = (idEpreuveSession, type, idSujet, numeroSerie) => dispatch => {
	dispatch({ type: CHECK_SUJET_EXIST_REQUEST })
	return api.checkSujetExist(idEpreuveSession, type, idSujet, numeroSerie)
		.then((result) => dispatch({ type: CHECK_SUJET_EXIST_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: CHECK_SUJET_EXIST_ERROR, e })
			throw e
		})
}

export const CHECK_GRILLES_EXIST_REQUEST = 'EPREUVES_SUJET/CHECK_GRILLES_EXIST_REQUEST'
export const CHECK_GRILLES_EXIST_SUCCESS = 'EPREUVES_SUJET/CHECK_GRILLES_EXIST_SUCCESS'
export const CHECK_GRILLES_EXIST_ERROR   = 'EPREUVES_SUJET/CHECK_GRILLES_EXIST_ERROR'
export const checkGrillesExist = (idEpreuveSession) => dispatch => {
	dispatch({ type: CHECK_GRILLES_EXIST_REQUEST })
	return api.checkGrillesExist(idEpreuveSession)
		.then((result) => dispatch({ type: CHECK_GRILLES_EXIST_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: CHECK_GRILLES_EXIST_ERROR, e })
			throw e
		})
}

export const VALIDER_ET_GENERER_SERIES_REQUEST = 'EPREUVES_SUJET/VALIDER_ET_GENERER_SERIES_REQUEST'
export const VALIDER_ET_GENERER_SERIES_SUCCESS = 'EPREUVES_SUJET/VALIDER_ET_GENERER_SERIES_SUCCESS'
export const VALIDER_ET_GENERER_SERIES_ERROR   = 'EPREUVES_SUJET/VALIDER_ET_GENERER_SERIES_ERROR'
export const validerEtGenererSeries = (id) => dispatch => {
	dispatch({ type: VALIDER_ET_GENERER_SERIES_REQUEST })
	return api.validerEtGenererSeries(id)
		.then((result) => dispatch({ type: VALIDER_ET_GENERER_SERIES_SUCCESS, result }))
		.catch(e => {
			dispatch({ type: VALIDER_ET_GENERER_SERIES_ERROR, e })
			throw e
		})
}