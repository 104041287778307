import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = state => getReferences(state).ligneProgramme

export const getDetailMatiere = createSelector(
	getLocalState,
	state => state.detailMatiere
)

export const getDetailTopic = createSelector(
	getLocalState,
	state => state.detailTopic
)

export const getDetailParagraphe = createSelector(
	getLocalState,
	state => state.detailParagraphe
)

export const getDetailSousParagraphe = createSelector(
	getLocalState,
	state => state.detailSousParagraphe
)

export const getDetailLigne = createSelector(
	getLocalState,
	state => state.detailLigne
)
