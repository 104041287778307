import React from 'react'
import PropTypes from 'prop-types'
import { Error, Button, Popup } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'

const ACTION_AVANCEE = {
	ABANDON: 'ABANDON',
	SUPPRESSION: 'SUPPRESSION',
	REOUVERTURE: 'REOUVERTURE'
}

const ConfirmModifierEtatPopup = ({ open, error, closePopup, submit, action }) => {

	const getTitle = () => {
		switch (action) {
			case ACTION_AVANCEE.ABANDON:
				return 'Abandon d\'un examen'
			case ACTION_AVANCEE.SUPPRESSION:
				return 'Suppression d\'un examen'
			case ACTION_AVANCEE.REOUVERTURE:
				return 'Réouverture d\'un examen'
			default:
				return ''
		}
	}

	const getMessage = () => {
		switch (action) {
			case ACTION_AVANCEE.ABANDON:
				return <Typography>Confirmez-vous l'abandon&nbsp;?</Typography>
			case ACTION_AVANCEE.SUPPRESSION:
				return <Typography>Confirmez-vous la suppression&nbsp;?</Typography>
			case ACTION_AVANCEE.REOUVERTURE:
				return <Typography>Confirmez-vous la réouverture&nbsp;?</Typography>
			default:
				return ''
		}
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={submit}
		>
			Confirmer
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		title={getTitle()}
		content={<React.Fragment>
			{error && <Error error={error} isPopinContainer />}
			{getMessage()}
		</React.Fragment>}
	/>
}

ConfirmModifierEtatPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	action: PropTypes.string.isRequired,
	error: PropTypes.object
}

export default ConfirmModifierEtatPopup
