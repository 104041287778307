import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Actions, Button, EmptyResult, Error, injectSession, StyledTypography } from '@oceane/ui'
import { getStyles, injectToolbarData, StaticDataTable, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { checkFileExist, checkImpressionGlobalePossible, getAnnexesAImprimer } from './services/aImprimerAction'

const style = () => getStyles({
	annexeColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})


const AnnexeListeAImprimer = ({ match, getAnnexesAImprimer, checkFileExist, classes, checkImpressionGlobalePossible }) => {
	const [annexes, setAnnexes] = React.useState([])
	const [customError, setCustomError] = React.useState(null)

	const idMatiere = match.params.id

	useEffect(() => {
		getAnnexesAImprimer(idMatiere)
			.then(liste => setAnnexes(liste.annexes))
	}, [idMatiere])

	const clickNumero = (idAnnexe) => {
		return checkFileExist(idAnnexe, idMatiere)
			.then(() =>
				download(`/files/annexesAImprimer/${idAnnexe}`)
			)
			.catch(e => {
				setCustomError([{ id: e.bodyError.globalErrors[0].code }])
			})

	}

	const clickPdf = () => {
		return checkImpressionGlobalePossible(idMatiere)
			.then(() =>
				download(`/files/annexesAImprimer/${idMatiere}/impressionGlobale`)
			)
			.catch(e => {
				setCustomError(e._error)
			})
	}

	return (
		<React.Fragment>
			<Title value="Liste des annexes de la matière" />
			<Error error={customError} />
			<StaticDataTable
				headers={[
					{
						key: 'link',
						name: 'Annexe',
						render: row => <Typography className={classes.annexeColumn}
												   onClick={() => clickNumero(row.id)}>{row.numero}</Typography>
					}
				]}
				defaultPageSize={10}
				data={annexes}
				noResultFragment={<EmptyResult />}
			/>
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={clickPdf}
				>
					Impression globale PDF
				</Button>
			</Actions>
		</React.Fragment>
	)
}

AnnexeListeAImprimer.propTypes = {
	getAnnexesAImprimer: PropTypes.func,
	checkFileExist: PropTypes.func
}

const actions = {
	getAnnexesAImprimer,
	checkFileExist,
	checkImpressionGlobalePossible
}

export default compose(
	connect(null, actions),
	injectSession,
	injectToolbarData(({ location: { state } }) => ({
		title: 'Gestion des livrets d\'annexes',
		subheader: (state.code && state.libelle) && {
			filArianeEntries: [{
				text: `Matière ${state.code} ${state.libelle}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	withStyles(style)
)(AnnexeListeAImprimer)
