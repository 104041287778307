import { Input, Popup } from '@oceane/ui'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { dataTableActions } from 'isotope-client'
import React, { Component } from 'react'
import { compose } from 'redux'
import Button from '@material-ui/core/Button'
import { closePopup, createCompteClient } from './services/nouveauCompteClientPopupActions'
import * as selectors from './services/nouveauCompteClientPopupSelectors'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

class NouveauCompteClientPopup extends Component {

	submitCreateCompteClient = (values) => {
		const { closePopup, createCompteClient, snackSuccess, snackError, refreshTable } = this.props

		return createCompteClient(values)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				closePopup()
			})
			.catch(e => {
				e.bodyError ? snackError(e.bodyError) : snackError()
			})
	}

	closePopup = () => {
		const {
			closePopup,
			reset
		} = this.props

		reset()
		closePopup()
	}

	popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="compteClientForm"
				>
					<Field
						id="libelle"
						name="libelle"
						label={<FormattedMessage id="references.comptesClients.popup.inputLabel"/>}
						component={Input}
					/>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			handleSubmit
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				<FormattedMessage id="global.btn.annuler" />
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitCreateCompteClient)}
			>
				<FormattedMessage id='global.btn.enregistrer' />
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title={<FormattedMessage id='references.comptesClients.popup.title'/>}
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

NouveauCompteClientPopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	createCompteClient: PropTypes.func
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state)
})

const actions = {
	closePopup,
	createCompteClient,
	refreshTable: () => dataTableActions.refresh('comptesClients')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'nouveauCompteClientPopup',
		initialValues: {
			libelle: ''
		}
	}),
	injectSnackActions
)(NouveauCompteClientPopup)
