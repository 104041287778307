import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormInput, getStyles, StaticDataTable, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import Grid from '@material-ui/core/Grid'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { injectEnonces, injectExpertise } from './services/BanqueQuestionsInjectors'
import * as selectors from './services/banqueQuestionsSelectors'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandMore from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import { EmptyResult } from '@oceane/ui'
import StatistiquesExamenPopup from './popup/StatistiquesExamenPopup'
import * as shapes from '../../../config/shapes'


const styles = theme => getStyles({
	collapse: {
		width: '100%'
	},
	button: {
		color: '#000000',
		padding: 5,
		marginTop: -1
	},
	icon: {
		transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shortest }),
		transform: 'rotate(-90deg)'
	},
	expanded: {
		transform: 'rotate(0)'
	},
})

const OngletExpertise = ({ enonces, expertise, classes }) => {

	const [expanded, setExpanded] = React.useState(false)
	const switchExpand = () => setExpanded(!expanded)

	const [statistiquesAffectation, setStatistiquesAffectation] = React.useState(null)

	const classnames = [classes.icon]
	if (expanded) {
		classnames.push(classes.expanded)
	}

	return (
		<>
			<Title value="Ligne de programme" />
			<Grid container>
				<Grid item xs={6}>
					<FormInput
						meta={{}}
						label="Numéro"
						fullWidth
						value={expertise.numeroLigneProgramme}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<FormInput
						meta={{}}
						label="Libellé"
						fullWidth
						value={expertise.libelleLigneProgramme}
						disabled
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<Title value="Version française" />
				</Grid>
				<Grid item xs={6}>
					<Title value="Version anglaise" />
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start">
				<Grid item xs={6}>
					<FormInput
						meta={{}}
						label="Question"
						value={enonces.texteFr}
						disabled
						multiline
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<FormInput
						meta={{}}
						label="Question"
						value={enonces.texteEn}
						disabled
						multiline
						fullWidth
					/>
				</Grid>
				{expertise.reponseA && <Grid container alignItems="flex-start">
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse A"
							value={expertise.reponseA.libelleFrancais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse A"
							value={expertise.reponseA.libelleAnglais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
				</Grid>}
				{expertise.reponseB && <Grid container alignItems="flex-start">
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse B"
							value={expertise.reponseB.libelleFrancais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse B"
							value={expertise.reponseB.libelleAnglais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
				</Grid>}
				{expertise.reponseC && <Grid container alignItems="flex-start">
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse C"
							value={expertise.reponseC.libelleFrancais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse C"
							value={expertise.reponseC.libelleAnglais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
				</Grid>}
				{expertise.reponseD && <Grid container alignItems="flex-start">
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse D"
							value={expertise.reponseD.libelleFrancais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
					<Grid item xs={6}>
						<FormInput
							meta={{}}
							label="Réponse D"
							value={expertise.reponseD.libelleAnglais}
							disabled
							multiline
							fullWidth
						/>
					</Grid>
				</Grid>}
				<Grid item xs={12} container direction="column">
					<Grid item container direction="row" alignItems="center">
						<Typography component="span" inline variant="subtitle2" color="primary">Justificatif</Typography>
						<IconButton onClick={switchExpand} className={classes.button} disableRipple>
							<ExpandMore className={classNames(classnames)} fontSize="inherit" />
						</IconButton>
					</Grid>
					<Collapse in={expanded} className={classes.collapse}>
						<FormInput
							meta={{}}
							value={enonces.justificatif}
							disabled
							multiline
							fullWidth
						/>
					</Collapse>
				</Grid>
			</Grid>
			<Title value="Examens" />
			<StaticDataTable
				data={expertise.affectations}
				headers={[{
					key: 'libelleExamen',
					name: 'Nom',
					sortable: true
				}, {
					key: 'dureeExamen',
					name: 'Durée',
					sortable: true
				}, {
					key: 'score',
					name: 'Score',
					sortable: true
				}, {
					key: 'statistiques',
					name: 'Statistiques',
					render: row => {
						if (row.affectee) {
							return <IconButton onClick={() => setStatistiquesAffectation(row)}>
								<AssignmentIcon />
							</IconButton>
						}
					},
					sortable: false
				}]}
				applyRowStyle={row => {
					if (!row.affectee) {
						return {
							backgroundColor: '#EBEBEB'
						}
					}
					return {}
				}}
				noResultFragment={<EmptyResult />}
				noPagination
			/>
			<StatistiquesExamenPopup affectation={statistiquesAffectation} clearAffectation={() => setStatistiquesAffectation(null)} />
		</>
	)
}

OngletExpertise.propTypes = {
	enonces: PropTypes.object,
	expertise: shapes.questionExpertiseShape,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	enonces: selectors.getEnonces(state),
	expertise: selectors.getQuestionExpertise(state)
})

export default compose(
	injectEnonces,
	injectExpertise,
	withStyles(styles),
	connect(mapStateToProps),
	injectSnackActions
)(OngletExpertise)
