import { Button, Input, Popup, ResponsiveForm, Select } from '@oceane/ui'
import { dataTableActions, FormCheckbox } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import { loadDecalageHoraire } from './services/decalageHoraireInjector'
import { createCentrePapier } from './services/centrePapierActions'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouveauCentrePapierPopup = ({ open, closePopup, handleSubmit, refreshTable, createCentrePapier, decalageH, goToDetails }) => {

	const submitCreateCentrePapier = (values) => {
		createCentrePapier(values)
			.then(({ centre }) => {
				closePopup()
				refreshTable()
				goToDetails(centre)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateCentrePapier)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouveau centre papier"
			content={<ResponsiveForm>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
				/>
				<Field
					id="decalageMetropole"
					name="decalageMetropole"
					label="Décalage horaire / métropole (hiver) *"
					component={Select}
				>
					{decalageH && decalageH.length !== 0 && decalageH.map(decalage =>
						<MenuItem key={decalage.id} value={decalage.id}>
							{decalage.libelle}
						</MenuItem>
					)}
				</Field>
				<Field
					id="heureEte"
					name="heureEte"
					label="Gestion heure d'été"
					component={FormCheckbox}
				/>
			</ResponsiveForm>}
		/>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('listeCentresPapier'),
	createCentrePapier,
	goToDetails: (centre) => dispatch => dispatch(push({
		pathname: `/centres/${centre.id}`,
		state: { nom: centre.libelle }
	}))
}

NouveauCentrePapierPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	decalageH: PropTypes.array,
	closePopup: PropTypes.func.isRequired,
	getDecalageHoraire: PropTypes.func,
	refreshTable: PropTypes.func,
	goToDetails: PropTypes.func
}

export default compose(
	loadDecalageHoraire,
	connect(null, actions),
	reduxForm({
		form: 'creationCentrePapier',
		initialValues: {
			libelle: '',
			decalageMetropole: 0,
			heureEte: true
		},
		validate
	})
)(NouveauCentrePapierPopup)
