import * as actions from './correctionCopiesActions'
import { combineReducers } from 'redux'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_SESSION_EPREUVES_SUCCESS:
			return action.result.map(epreuve => epreuve.id)
		case actions.CLEAN_SESSION_EPREUVES:
			return []
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_SESSION_EPREUVES_SUCCESS:
			return action.result.reduce((acc, epreuve) => ({
				...acc,
				[epreuve.id]: epreuve
			}), state)
		default:
			return state
	}
}

const epreuveSession = (state = null, action) => {
	switch (action.type) {
		case actions.GET_EPREUVE_SESSION_SUCCESS:
			return action.result
		case actions.CREATION_COPIES_SUCCESS:
			return {
				...state,
				fichierCharge: true
			}
		default:
			return state
	}
}

const resultatControleEtCorrection = (state = null, action) => {
	switch (action.type) {
		case actions.CONTROLE_ANOMALIES_SUCCESS:
			return action.result
		case actions.DECLARER_ABSENCE_SUCCESS:
			// On supprime de la liste des copies non rendues celle pour laquelle on a déclaré une absence
			return {
				...state,
				listeCopiesNonRendues: state.listeCopiesNonRendues.filter(c => c.numeroInscription !== action.numeroInscription)
			}
		case actions.DECLARER_PRESENCE_SUCCESS:
			const newListeCopiesEnAnomalie = [...state.listeCopiesEnAnomalie]
			const index = newListeCopiesEnAnomalie.findIndex(c => c.id === action.idCopiePapier)
			if (index !== -1) {
				// l'indicateur d'anomalie de type cohérence sur la copie est mis à "faux"
				const newVal = { ...newListeCopiesEnAnomalie[index], anomalieCoherence: false }
				newListeCopiesEnAnomalie.splice(index, 1, newVal)
			}
			return {
				...state,
				listeCopiesEnAnomalie: newListeCopiesEnAnomalie
			}
		case actions.MODIFIER_REPONSE_QUESTION_SUCCESS:
			const copies = [...state.listeCopiesEnAnomalie]
			const indexOfCopie = copies.findIndex(c => c.id === action.idCopiePapier)
			if(indexOfCopie !== -1) {
				const indexOfReponse = copies[indexOfCopie].reponsesCopiePapier.findIndex(r => r.positionQuestion === action.result.positionQuestion)
				if(indexOfReponse !== -1) {
					copies[indexOfCopie].reponsesCopiePapier.splice(indexOfReponse, 1, { ...copies[indexOfCopie].reponsesCopiePapier[indexOfReponse], reponse: action.result.reponse })
				}
			}
			return {
				...state,
				listeCopiesEnAnomalie: copies
			}
		case actions.ENREGISTRER_TRAITEMENT_ANOMALIES_SUCCESS:
			const newListeCopiesEnAnomalie2 = [...state.listeCopiesEnAnomalie]
			const index2 = newListeCopiesEnAnomalie2.findIndex(c => c.id === action.idCopiePapier)
			if (index2 !== -1) {
				const newVal = {
					...newListeCopiesEnAnomalie2[index2],
					numeroInscription: action.result.copiePapier.numeroInscription,
					numeroSerie: action.result.copiePapier.numeroSerie,
					numeroSerieActuelValide: action.result.copiePapier.numeroSerieActuelValide
				}
				newListeCopiesEnAnomalie2.splice(index2, 1, newVal)
			}
			return {
				...state,
				listeCopiesNonRendues: action.result.listeCopiesNonRendues,
				listeCopiesEnAnomalie: newListeCopiesEnAnomalie2
			}
		default:
			return state
	}
}

export const correctionCopies = combineReducers({
	epreuves: combineReducers({
		ids,
		byId,
		epreuveSession,
		resultatControleEtCorrection
	})
})