import * as api from './actionsAvanceesApi'

export const GET_IS_EXAMEN_SUPPRIMABLE_REQUEST = 'ACTIONS_AVANCEES/GET_IS_EXAMEN_SUPPRIMABLE_REQUEST'
export const GET_IS_EXAMEN_SUPPRIMABLE_SUCCESS = 'ACTIONS_AVANCEES/GET_IS_EXAMEN_SUPPRIMABLE_SUCCESS'
export const GET_IS_EXAMEN_SUPPRIMABLE_ERROR   = 'ACTIONS_AVANCEES/GET_IS_EXAMEN_SUPPRIMABLE_ERROR'
export const isExamenCandidatSupprimable = (idCandidat, idExamenType) => dispatch => {
	dispatch({ type: GET_IS_EXAMEN_SUPPRIMABLE_REQUEST })
	api.isExamenCandidatSupprimable(idCandidat, idExamenType)
		.then(isSupprimable => dispatch({
			type: GET_IS_EXAMEN_SUPPRIMABLE_SUCCESS,
			isSupprimable
		}))
		.catch(e => {
			dispatch({
				type: GET_IS_EXAMEN_SUPPRIMABLE_ERROR,
				e
			})
			throw e
		})
}

export const ENREGISTRER_EXAMEN_REQUEST = 'ACTIONS_AVANCEES/ENREGISTRER_EXAMEN_REQUEST'
export const ENREGISTRER_EXAMEN_SUCCESS = 'ACTIONS_AVANCEES/ENREGISTRER_EXAMEN_SUCCESS'
export const ENREGISTRER_EXAMEN_ERROR   = 'ACTIONS_AVANCEES/ENREGISTRER_EXAMEN_ERROR'
export const enregistrer = (idCandidat, idExamenType, values) => dispatch => {
	dispatch({ type: ENREGISTRER_EXAMEN_REQUEST })
	return api.enregistrer(idCandidat, idExamenType, values)
		.then(() => dispatch({
			type: ENREGISTRER_EXAMEN_SUCCESS,
			values
		}))
		.catch(e => {
			dispatch({
				type: ENREGISTRER_EXAMEN_ERROR,
				e
			})
			throw e
		})
}

export const ABANDONNER_EXAMEN_REQUEST = 'ACTIONS_AVANCEES/ABANDONNER_EXAMEN_REQUEST'
export const ABANDONNER_EXAMEN_SUCCESS = 'ACTIONS_AVANCEES/ABANDONNER_EXAMEN_SUCCESS'
export const ABANDONNER_EXAMEN_ERROR   = 'ACTIONS_AVANCEES/ABANDONNER_EXAMEN_ERROR'
export const abandonner = (idCandidat, idExamenType, values) => dispatch => {
	dispatch({ type: ABANDONNER_EXAMEN_REQUEST })
	return api.abandonner(idCandidat, idExamenType, values)
		.then(() => dispatch({
			type: ABANDONNER_EXAMEN_SUCCESS,
			values
		}))
		.catch(e => {
			dispatch({
				type: ABANDONNER_EXAMEN_ERROR,
				e
			})
			throw e
		})
}

export const REOUVRIR_EXAMEN_REQUEST = 'ACTIONS_AVANCEES/REOUVRIR_EXAMEN_REQUEST'
export const REOUVRIR_EXAMEN_SUCCESS = 'ACTIONS_AVANCEES/REOUVRIR_EXAMEN_SUCCESS'
export const REOUVRIR_EXAMEN_ERROR   = 'ACTIONS_AVANCEES/REOUVRIR_EXAMEN_ERROR'
export const reouvrir = (idCandidat, idExamenType, values) => dispatch => {
	dispatch({ type: REOUVRIR_EXAMEN_REQUEST })
	return api.reouvrir(idCandidat, idExamenType, values)
		.then(() => dispatch({
			type: REOUVRIR_EXAMEN_SUCCESS,
			values
		}))
		.catch(e => {
			dispatch({
				type: REOUVRIR_EXAMEN_ERROR,
				e
			})
			throw e
		})
}

export const SUPPRIMER_EXAMEN_REQUEST = 'ACTIONS_AVANCEES/SUPPRIMER_EXAMEN_REQUEST'
export const SUPPRIMER_EXAMEN_SUCCESS = 'ACTIONS_AVANCEES/SUPPRIMER_EXAMEN_SUCCESS'
export const SUPPRIMER_EXAMEN_ERROR   = 'ACTIONS_AVANCEES/SUPPRIMER_EXAMEN_ERROR'
export const supprimer = (idCandidat, idExamenType) => dispatch => {
	dispatch({ type: SUPPRIMER_EXAMEN_REQUEST })
	return api.supprimer(idCandidat, idExamenType)
		.then(() => dispatch({
			type: SUPPRIMER_EXAMEN_SUCCESS
		}))
		.catch(e => {
			dispatch({
				type: SUPPRIMER_EXAMEN_ERROR,
				e
			})
			throw e
		})
}