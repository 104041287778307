import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as sessionsPapierActions from './sessionsPapierActions'
import { getEpreuveSession, getSiteSession } from './sessionsPapierActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { Loader } from '@oceane/ui'
import { createMatchSelector } from 'connected-react-router'
import * as selectors from './sessionsPapierSelectors'
import { getCentrePapierSession } from './sessionsPapierActions'

export const injectMatchSession = (WrappedComponent) => {
	const mapStateToProps = state => {
		const match = createMatchSelector({ path: '/sessions-papier/:idSession/:tab' })(state)
		return {
			idSession: match ? Number(match.params.idSession) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)
}

const injectMatchSessionEpreuves = (WrappedComponent) => {
	const mapStateToProps = state => {
		const match = createMatchSelector({ path: '/sessions-papier/:idSession/epreuves/:idSite' })(state)
		return {
			idSession: match ? Number(match.params.idSession) : null,
			idSite: match ? Number(match.params.idSite) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)
}

const injectMatchSessionParametrage = (WrappedComponent) => {
	const mapStateToProps = state => {
		const match = createMatchSelector({ path: '/sessions-papier/:idSession/parametrage/:idCentre' })(state)
		return {
			idSession: match ? Number(match.params.idSession) : null,
			idCentre: match ? Number(match.params.idCentre) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)
}

const injectMatchSessionCandidats = (WrappedComponent) => {
	const mapStateToProps = state => {
		const match = createMatchSelector({ path: '/sessions-papier/:idSession/epreuves/:idSite/candidats/:idEpreuve' })(state)
		return {
			idSession: match ? Number(match.params.idSession) : null,
			idSite: match ? Number(match.params.idSite) : null,
			idEpreuve: match ? Number(match.params.idEpreuve) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)
}

const injectMatchSiteSurveillance = (WrappedComponent) => {
	const mapStateToProps = state => {
		const match = createMatchSelector({ path: '/sessions-papier/:idSession/surveillance/:idSite' })(state)
		return {
			idSession: match ? Number(match.params.idSession) : null,
			idSite: match ? Number(match.params.idSite) : null
		}
	}

	return connect(mapStateToProps)(WrappedComponent)
}

export const injectDonneesGenerales = (WrappedComponent) => {
	const GeneralInjector = ({ idSession, getSessionPapierDonneesGenerales, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)

		React.useEffect(() => {
			if (idSession != null && props.donneesGenerales.id !== idSession) {
				setLoading(true)
				getSessionPapierDonneesGenerales(idSession)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent {...props} />
	}

	GeneralInjector.propTypes = {
		idSessionPapier: PropTypes.number,
		donneesGenerales: PropTypes.object,
		getSessionPapierDonneesGenerales: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getSessionPapierDonneesGenerales: sessionsPapierActions.getSessionPapierDonneesGenerales,
	}

	return compose(
		connect(mapStateToProps, actions),
		injectMatchSession,
		injectSnackActions
	)(GeneralInjector)
}

export const injectSiteSession = (WrappedComponent) => {
	const GeneralInjector = ({ idSession, idSite, getSiteSession, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)
		const [site, setSite] = React.useState({})

		React.useEffect(() => {
			if (idSession != null) {
				setLoading(true)
				getSiteSession(idSession, idSite)
					.then(({ result }) => {
						setLoading(false)
						setSite(result)
					})
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [idSession])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent site={site} {...props} />
	}

	GeneralInjector.propTypes = {
		idSessionPapier: PropTypes.number,
		idSite: PropTypes.number,
		donneesGenerales: PropTypes.object,
		getSessionPapierDonneesGenerales: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getSiteSession
	}

	return compose(
		injectMatchSessionEpreuves,
		injectSnackActions,
		connect(mapStateToProps, actions)
	)(GeneralInjector)
}

export const injectSiteSurveillanceSession = (WrappedComponent) => {
	const GeneralInjector = ({ idSession, idSite, getSiteSession, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)
		const [site, setSite] = React.useState({})

		React.useEffect(() => {
			if (idSession != null) {
				setLoading(true)
				getSiteSession(idSession, idSite)
					.then(({ result }) => {
						setLoading(false)
						setSite(result)
					})
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [idSession])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent site={site} {...props} />
	}

	GeneralInjector.propTypes = {
		idSessionPapier: PropTypes.number,
		idSite: PropTypes.number,
		donneesGenerales: PropTypes.object,
		getSessionPapierDonneesGenerales: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getSiteSession
	}

	return compose(
		injectMatchSiteSurveillance,
		injectSnackActions,
		connect(mapStateToProps, actions)
	)(GeneralInjector)
}

export const injectCandidatSession = (WrappedComponent) => {
	const GeneralInjector = ({ idSession, idSite, idEpreuve, getEpreuveSession, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(false)
		const [isError, setError] = React.useState(false)
		const [epreuve, setEpreuve] = React.useState({})

		React.useEffect(() => {
			if (idSession != null) {
				setLoading(true)
				getEpreuveSession(idSession, idSite, idEpreuve)
					.then(({ result }) => {
						setLoading(false)
						setEpreuve(result)
					})
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [idSession])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent epreuve={epreuve} {...props} />
	}

	GeneralInjector.propTypes = {
		idSession: PropTypes.number,
		idSite: PropTypes.number,
		idEpreuve: PropTypes.number,
		donneesGenerales: PropTypes.object,
		getSessionPapierDonneesGenerales: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getEpreuveSession
	}

	return compose(
		injectMatchSessionCandidats,
		injectSnackActions,
		connect(mapStateToProps, actions)
	)(GeneralInjector)
}

export const injectCentrePapier = (WrappedComponent) => {
	const GeneralInjector = ({ idSession, idCentre, getCentrePapierSession, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(true)
		const [isError, setError] = React.useState(false)
		const [centre, setCentre] = React.useState({})

		React.useEffect(() => {
			if (idSession != null && idCentre !== undefined) {
				getCentrePapierSession(idSession, idCentre)
					.then(({ result }) => {
						setLoading(false)
						setCentre(result)
					})
					.catch(() => {
						setLoading(false)
						setError(true)
						snackError()
					})
			}
		}, [idCentre])

		return loading ? <Loader /> : isError ? <></> : <WrappedComponent centre={centre} idCentre={idCentre} {...props} />
	}

	GeneralInjector.propTypes = {
		idSession: PropTypes.number,
		idCentre: PropTypes.number,
		donneesGenerales: PropTypes.object,
		getCentrePapierSession: PropTypes.func
	}

	const mapStateToProps = state => ({
		donneesGenerales: selectors.getDonneesGenerales(state)
	})

	const actions = {
		getCentrePapierSession
	}

	return compose(
		injectMatchSessionParametrage,
		injectSnackActions,
		connect(mapStateToProps, actions)
	)(GeneralInjector)
}
