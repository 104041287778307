import { Button, Input, Popup } from '@oceane/ui'
import PropTypes from 'prop-types'
import { dataTableActions } from 'isotope-client'
import React, { Component } from 'react'
import { compose } from 'redux'
import { createMatiere } from '../service/ligneProgrammeAction'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class NouvelleMatierePopup extends Component {

	submitCreateMatiere = (values) => {
		const { createMatiere, snackSuccess, refreshTable, onClose } = this.props

		return createMatiere(values)
			.then(() => {
				snackSuccess({ id: 'references.succes' })
				refreshTable()
				onClose()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	closePopup = () => {
		const {
			onClose,
			reset
		} = this.props

		reset()
		onClose()
	}

	popupContent = () => {
		return (
			<React.Fragment>
				<form
					id="MatiereForm"
				>
					<Field
						id="code"
						name="code"
						label="Code *"
						component={Input}
						inputProps={{
							maxLength: 3
						}}
					/>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={Input}
					/>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			handleSubmit,
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitCreateMatiere)}
			>
				Créer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Nouvelle matière"
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

NouvelleMatierePopup.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	createMatiere: PropTypes.func,
	refreshTable: PropTypes.func
}

const actions = {
	createMatiere,
	refreshTable: () => dataTableActions.refresh('listeMatiere')
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'MatiereForm',
		initialValues: {
			libelle: '',
			code: ''
		},
		validate
	}),
	injectSnackActions
)(NouvelleMatierePopup)
