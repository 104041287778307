import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Title } from 'isotope-client'
import { Actions, Button, DateInput, InputRadio, ResponsiveForm, Select, formatQuery } from '@oceane/ui'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { download } from 'isotope-client/components/download/IsotopeLink'

const QUESTIONS_PAYEES_FORM = 'QuestionPayeesForm'
const TYPE_ETAT = [
	{ label: 'Etat global', value: '1' },
	{ label: 'Etat du jour', value: '2' }
]
const TYPE_IMPRESSION = [
	{ label: 'Liste', value: '1' },
	{ label: 'Nominatif', value: '2' },
]

const validate = values => {
	const errors = {}
	if (!values.dateDePaiement) {
		errors.dateDePaiement = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const QuestionsPayees = ({ selectedMatiere, origine, etat, handleSubmit }) => {

	const imprimer = (values) => {
		// Etat global
		if (values.etat === '1') {
			download(`/files/questions/paiements/etat-global-questions-payees?idMatiere=${selectedMatiere}&origine=${origine}`)
		} else {
			download(`/files/questions/paiements/etat-jour-questions-payees?${formatQuery({
				...values,
				idMatiere: selectedMatiere,
				origine
			})}`)
		}
	}

	return (<>
		<Title value="Questions payées" />
		<Field
			name="etat"
			label="Type d'état *"
			component={Select}
		>
			{TYPE_ETAT.map(etat => <MenuItem key={`etat-${etat.value}`} value={etat.value}>{etat.label}</MenuItem>)}
		</Field>
		<ResponsiveForm>
			{etat === '2' && <>
				<Field
					name="dateDePaiement"
					type="date"
					label="Date de paiement *"
					component={DateInput}
				/>
				<Field
					name="etatJour"
					component={InputRadio}
					choices={TYPE_IMPRESSION}
				/>
			</>}
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(imprimer)}
				>
					Imprimer
				</Button>
			</Actions>
		</ResponsiveForm>
	</>)
}

QuestionsPayees.propTypes = {
	selectedMatiere: PropTypes.number,
	origine: PropTypes.number,
	etat: PropTypes.string
}

const mapStateToProps = state => ({
	etat: formValueSelector(QUESTIONS_PAYEES_FORM)(state, 'etat')
})

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: QUESTIONS_PAYEES_FORM,
		initialValues: {
			etat: '1',
			dateDePaiement: moment().format('YYYY-MM-DD'),
			etatJour: '1'
		},
		validate
	})
)(QuestionsPayees)
