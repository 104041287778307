import React from 'react'
import StatistiquesQuestionsFiltres from './StatistiquesQuestionsFiltres'
import StatistiquesQuestionsTable from './StatistiquesQuestionsTable'
import { checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'


const StatistiquesQuestionsPage = () => {

	return <React.Fragment>
		<StatistiquesQuestionsFiltres />
		<StatistiquesQuestionsTable />
	</React.Fragment>
}



export default checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS)(StatistiquesQuestionsPage)
