import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Button, Error, Popup, Select, injectFormSnackbar } from '@oceane/ui'
import { FormCheckbox } from 'isotope-client'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import * as selectors from '../services/examensTypesSelectors'
import { creerPj, getListePjsAbsentes } from '../services/examensTypesActions'
import MenuItem from '@material-ui/core/MenuItem'

const validate = (values) => {
	const errors = {}
	if (!values.pieceJustificative) {
		errors.pieceJustificative = 'Ce champ est obligatoire'
	}
	return errors
}

const NouvellePjPopup = ({
							 id,
							 open,
							 type,
							 reset,
							 error,
							 closePopup,
							 submitting,
							 handleSubmit,
							 creerPjAction,
							 listePjAbsentes,
							 goToProprietesEpreuveType,
							 getListePjsAbsentesAction
						 }) => {

	React.useEffect(() => {
		if (!!id) {
			getListePjsAbsentesAction(id)
		}
	})

	const submitForm = values => {
		return creerPjAction(id, values)
			.then(closePopup)
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitForm)}
			loading={submitting}
		>
			Ajouter
		</Button>
	]

	return <Popup
		open={open}
		actions={actions}
		onExited={reset}
		title="Ajouter une pièce justificative"
		content={<React.Fragment>
			<Error error={error} isPopinContainer />
			<form onSubmit={handleSubmit(submitForm)}>
				<Field
					name="pieceJustificative"
					label="Pièce *"
					component={Select}
					displayEmpty
				>
					<MenuItem value="">Sélectionnez...</MenuItem>
					{listePjAbsentes.map((value, index) => <MenuItem key={index} value={value.id}>
						{value.libelle}
					</MenuItem>)}
				</Field>
				<Field
					name="obligatoire"
					label="Obligatoire avant passage"
					component={FormCheckbox}
				/>
			</form>
		</React.Fragment>}
	/>
}

NouvellePjPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	classes: PropTypes.object,
	listeMatieres: PropTypes.arrayOf(PropTypes.object),
	id: PropTypes.number,
	getListeMatieres: PropTypes.func,
	creerPj: PropTypes.func
}

const mapStateToProps = (state) => {
	const examenType = selectors.getExamenType(state)
	return {
		listePjAbsentes: selectors.getPiecesJustificativesAbsentes(state),
		id: examenType.id
	}
}

const actions = {
	getListePjsAbsentesAction: getListePjsAbsentes,
	creerPjAction: creerPj
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'creerPj',
		validate,
		initialValues: {
			obligatoire: true,
			pieceJustificative: ''
		}
	}),
	injectFormSnackbar()
)(NouvellePjPopup)
