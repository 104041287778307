import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectToolbarData } from 'isotope-client'
import ConsulterValidationsKSAForm from './components/ConsulterValidationsKSAForm'
import { injectListeOrganismes } from '../../references/organisme/services/injectListeOrganismes'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { checkAuthorities, formatQuery, PROFIL_UTILISATEUR } from '@oceane/ui'

const ValidationsKSAPage = ({ listeOrganismes }) => {
	const exporter = (values) => Promise.resolve().then(() => download(`/files/validations-ksa?${formatQuery(values)}`))

	return <ConsulterValidationsKSAForm onSubmit={exporter} listeOrganismes={listeOrganismes} />
}

ValidationsKSAPage.propTypes = {
	listeOrganismes: PropTypes.array
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectListeOrganismes,
	injectToolbarData({ title: { id: 'toolbarTitle.validations-ksa' } })
)(ValidationsKSAPage)