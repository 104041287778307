import { Loader } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { clearAnnexe, getAnnexe } from './annexeAction'
import * as selectors from './annexeSelectors'

export const loadAnnexe = (WrappedComponent) => {
	const AnnexeInjector = ({ annexe, getAnnexe, clearAnnexe, ...otherProps }) => {
		const match = otherProps.match

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if ((match && match.params && match.params.idAnnexe && annexe && match.params.idAnnexe !== annexe.id) || !annexe) {
				setLoading(true)
				getAnnexe(match.params.idAnnexe, match.params.idMatiere)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return clearAnnexe
		}, [])

		return loading ? <Loader /> : <WrappedComponent annexe={annexe} {...otherProps} />
	}

	AnnexeInjector.propTypes = {
		annexe: PropTypes.object,
		getAnnexe: PropTypes.func,
		clearAnnexe: PropTypes.func,
		match: PropTypes.object
	}

	const mapStateToProps = state => ({
		annexe: selectors.getAnnexe(state)
	})

	const actions = {
		getAnnexe,
		clearAnnexe
	}

	return compose(
		connect(mapStateToProps, actions),
	)(AnnexeInjector)
}
