import { Button, SelectWithPlaceholder } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import BackIcon from '@material-ui/icons/ArrowBack'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ETAT_PAIEMENT, ORIGINE_PAIEMENT } from '../../../../config/enums'
import { getListeAuteurs } from '../../questions/services/banqueQuestionsActions'
import { getListePaiements, setPaiementSelected, updateEtatPaiement } from '../services/paiementActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import Paper from '@material-ui/core/Paper'

import * as paiementSelectors from '../services/paiementSelectors'

const styles = theme => ({
	empty: {
		backgroundColor: theme.palette.containerFilter,
		padding: '10px 20px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 892,
		height: 40,
	},
	icon: {
		padding: 0,
		margin: '2px 5px 2px 0',
		color: '#000000'
	}
})

const QuestionsNonPayeesActions = ({ classes, origine, selectedMatiere, paiementSelectionnes, dataTableRef, setPaiementSelected, getListePaiements, getListeAuteurs, updateEtatPaiement, refreshTable, snackSuccess, snackError }) => {
	const [attribution, setAttribution] = React.useState('')
	const [listeAuteurs, setListeAuteurs] = React.useState([])
	const isEuropeen = ORIGINE_PAIEMENT.EUROPEENNE === origine

	React.useEffect(() => {
		if (isEuropeen && listeAuteurs.length === 0) {
			getListeAuteurs()
				.then(result => setListeAuteurs(result))
		}
		setPaiementSelected([])
	}, [origine])

	const idPaiements = (paiementSelectionnes || []).map(paiement => paiement.id)

	const handleAction = (etatDePaiement) => {
		updateEtatPaiement({
			idsQuestion: idPaiements,
			etatDePaiement,
			idRdb: attribution,
			idMatiere: selectedMatiere,
			origine
		})
			.then(() => {
				getListePaiements(selectedMatiere, origine)
				dataTableRef.current.clearSelection()
				setPaiementSelected([])
				setAttribution('')
				snackSuccess(etatDePaiement === ETAT_PAIEMENT.NON_PAYEE ? 'Les pré-paiements ont été retirés' : 'Les pré-paiements ont été enregistrés')
			})
			.catch(() => snackError())
	}

	if (idPaiements.length < 1) {
		return <Paper elevation={0} square className={classes.empty}><Typography>Sélectionnez les questions à mettre à jour</Typography></Paper>
	}

	return (<Paper elevation={0} square className={classes.empty}>
		<Grid container alignItems="center" justify="space-between">
			<Grid item xs={4}>
				<IconButton
					className={classes.icon}
					onClick={() => {
						dataTableRef.current.clearSelection()
						setPaiementSelected([])
					}}
					disableRipple
				>
					<BackIcon />
				</IconButton>
				<FormattedMessage id="actions.elementsSelectionnes" values={{ selection: idPaiements.length }} />
			</Grid>
			<Grid item container spacing={8} xs={8} justify="flex-end">
				{isEuropeen && <Grid item>
					<SelectWithPlaceholder
						value={attribution}
						onChange={event => setAttribution(event.target.value)}
						id="attribution"
						placeholder="Attribuer à"
						emptyValue=""
					>
						{listeAuteurs.map((auteur, index) => (
							<MenuItem
								key={index}
								value={auteur.id}
							>
								{auteur.libelleLong}
							</MenuItem>
						))}
					</SelectWithPlaceholder>
				</Grid>}
				<Grid item>
					<Button
						color="primary"
						onClick={() => handleAction(ETAT_PAIEMENT.PRE_PAYEE)}
						disabled={isEuropeen && attribution.length === 0}
					>
						Pré-payer
					</Button>
				</Grid>
				<Grid item>
					<Button
						color="primary"
						onClick={() => handleAction(ETAT_PAIEMENT.NON_PAYEE)}
					>
						Retirer
					</Button>
				</Grid>
			</Grid>
		</Grid>
	</Paper>)
}

QuestionsNonPayeesActions.propTypes = {
	classes: PropTypes.object,
	dataTableRef: PropTypes.object,
	selectedMatiere: PropTypes.number,
	origine: PropTypes.number,
	paiementSelectionnes: PropTypes.arrayOf(PropTypes.object),
	setPaiementSelected: PropTypes.func,
	getListePaiements: PropTypes.func,
	getListeAuteurs: PropTypes.func,
	updateEtatPaiement: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func
}

const mapStateToProps = (state) => ({
	paiementSelectionnes: paiementSelectors.getSelectedPaiements(state)
})

const actions = {
	getListePaiements,
	getListeAuteurs,
	setPaiementSelected,
	updateEtatPaiement
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	injectSnackActions
)(QuestionsNonPayeesActions)
