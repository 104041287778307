import React from 'react'
import PropTypes from 'prop-types'
import { Popup, Button } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'

export const TYPE_ACTION = {
	ACTION_1: {
		message: 'Attention : le programme de secours OceanePexManager (OPM) nécessaire en cas de défaillance dans l\'accès au serveur central OCEANE n\'est pas démarré sur le port indiqué dans l\'url du serveur local définie dans la page de localisation du site.',
		annuler: 'Annuler pour démarrer OPM ou indiquer un autre port',
		ignorer: 'Ignorer et continuer sans vérifier OPM'
	},
	ACTION_2: {
		message: 'Attention : le serveur central ne peut accéder à l\'url du serveur local définie dans la page de localisation du site et ne peut donc vérifier le démarrage d\'OceanePexManager (OPM).',
		annuler: 'Annuler pour vérifier cette url',
		ignorer: 'Ignorer et continuer malgré cette url'
	}
}

const PopupAskForAction = ({ open, title, typeAction, closePopup, clearPopupContent, callback }) => {

	const actions = [
		<Button
			key="annuler"
			onClick={closePopup}
		>
			{typeAction && typeAction.annuler}
		</Button>,
		<Button
			key="ignorer"
			onClick={() => {
				callback()
				closePopup()
			}}
		>
			{typeAction && typeAction.ignorer}
		</Button>
	]

	return (
		<Popup
			title={title}
			onExited={clearPopupContent}
			open={open}
			content={typeAction ? <Typography>{typeAction.message}</Typography> : <React.Fragment />}
			actions={actions}
		/>
	)
}


PopupAskForAction.propTypes = {
	classes: PropTypes.object
}

export default PopupAskForAction
