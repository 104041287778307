import { createSelector } from 'reselect'
import { getReferences } from '../../../referencesSelectors'

const getLocalState = state => getReferences(state).comptesClients

export const getComptesClients = createSelector(
	getLocalState,
	state => state.comptesClient
)

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)
