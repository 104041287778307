import { combineReducers } from 'redux'
import { CLOSE_POPUP, OPEN_POPUP } from '../popup/nouvelOrganismePopupActions'
import { GET_COMTPES_CLIENTS_SUCCESS } from './detailOrganismeAction'

const comptesClient = (state = [], action) => {
	switch (action.type) {
		case GET_COMTPES_CLIENTS_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

export const comptesClients = combineReducers({
	comptesClient,
	open
})
