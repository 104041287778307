import * as api from './aImprimerApi'

export const GET_MATIERES_A_IMPRIMER_REQUEST = 'GET_MATIERES_A_IMPRIMER_REQUEST'
export const GET_MATIERES_A_IMPRIMER_SUCCESS = 'GET_MATIERES_A_IMPRIMER_SUCCESS'
export const GET_MATIERES_A_IMPRIMER_ERROR = 'GET_MATIERES_A_IMPRIMER_ERROR'

export const getMatieresAImprimer = (profil) => dispatch => {
	dispatch({ type: GET_MATIERES_A_IMPRIMER_REQUEST })

	return api.getMatieresAImprimer(profil)
		.then((matieres) => dispatch({ type: GET_MATIERES_A_IMPRIMER_SUCCESS, matieres }))
		.catch(() => dispatch({ type: GET_MATIERES_A_IMPRIMER_ERROR }))
}

export const GET_ANNEXES_A_IMPRIMER_REQUEST = 'GET_ANNEXES_A_IMPRIMER_REQUEST'
export const GET_ANNEXES_A_IMPRIMER_SUCCESS = 'GET_ANNEXES_A_IMPRIMER_SUCCESS'
export const GET_ANNEXES_A_IMPRIMER_ERROR = 'GET_ANNEXES_A_IMPRIMER_ERROR'

export const getAnnexesAImprimer = (idMatiere) => dispatch => {
	dispatch({ type: GET_ANNEXES_A_IMPRIMER_REQUEST })

	return api.getAnnexesAImprimer(idMatiere)
		.then((annexes) => dispatch({ type: GET_ANNEXES_A_IMPRIMER_SUCCESS, annexes }))
		.catch(() => dispatch({ type: GET_ANNEXES_A_IMPRIMER_ERROR }))
}

export const CHECK_DOWNLOAD_REQUEST = 'CHECK_DOWNLOAD_REQUEST'
export const CHECK_DOWNLOAD_SUCCESS = 'CHECK_DOWNLOAD_SUCCESS'
export const CHECK_DOWNLOAD_ERROR = 'CHECK_DOWNLOAD_ERROR'

export const checkFileExist = (idAnnexe, idMatiere) => dispatch => {
	dispatch({ type: CHECK_DOWNLOAD_REQUEST })

	return api.checkFileExist(idAnnexe, idMatiere)
		.then(() => dispatch({ type: CHECK_DOWNLOAD_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHECK_DOWNLOAD_ERROR })
			throw e
		})
}

export const CHECK_IMPRESSION_REQUEST = 'CHECK_IMPRESSION_REQUEST'
export const CHECK_IMPRESSION_SUCCESS = 'CHECK_IMPRESSION_SUCCESS'
export const CHECK_IMPRESSION_ERROR = 'CHECK_IMPRESSION_ERROR'

export const checkImpressionGlobalePossible = (idMatiere) => dispatch => {
	dispatch({ type: CHECK_IMPRESSION_REQUEST })

	return api.checkImpressionGlobalePossible(idMatiere)
		.then(() => dispatch({ type: CHECK_IMPRESSION_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHECK_IMPRESSION_ERROR })
			throw e
		})
}
