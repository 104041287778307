import * as api from './miseEnExploitationApi'

export const GET_TYPES_EXAMEN_AUTORISES_REQUEST = 'EXPLOITATION/GET_TYPES_EXAMEN_AUTORISES_REQUEST'
export const GET_TYPES_EXAMEN_AUTORISES_SUCCESS = 'EXPLOITATION/GET_TYPES_EXAMEN_AUTORISES_SUCCESS'
export const GET_TYPES_EXAMEN_AUTORISES_ERROR = 'EXPLOITATION/GET_TYPES_EXAMEN_AUTORISES_ERROR'
export const getTypesExamenAutorises = () => dispatch => {
	dispatch({ type: GET_TYPES_EXAMEN_AUTORISES_REQUEST })
	return api.getTypesExamenAutorises()
		.then(examensAutorises => dispatch({
			type: GET_TYPES_EXAMEN_AUTORISES_SUCCESS,
			examensAutorises
		}))
		.catch(e => {
			dispatch({ type: GET_TYPES_EXAMEN_AUTORISES_ERROR, e })
			throw e
		})
}

export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST = 'EXPLOITATION/VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST'
export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS = 'EXPLOITATION/VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS'
export const VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR = 'EXPLOITATION/VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR'
export const verifierDisponibiliteCompteRendu = id => dispatch => {
	dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_REQUEST  })
	return api.verifierDisponibiliteCompteRendu(id)
		.then(() => dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_SUCCESS }))
		.catch(e => {
			dispatch({ type: VERIFIER_DISPONIBILITE_COMPTE_RENDU_ERROR })
			throw e
		})
}

export const VERIFIER_AVANT_MISE_EN_EXPLOITATION_REQUEST = 'EXPLOITATION/VERIFIER_AVANT_MISE_EN_EXPLOITATION_REQUEST'
export const VERIFIER_AVANT_MISE_EN_EXPLOITATION_SUCCESS = 'EXPLOITATION/VERIFIER_AVANT_MISE_EN_EXPLOITATION_SUCCESS'
export const VERIFIER_AVANT_MISE_EN_EXPLOITATION_ERROR = 'EXPLOITATION/VERIFIER_AVANT_MISE_EN_EXPLOITATION_ERROR'
export const verifierAvantMiseEnExploitation = (values) => dispatch => {
	dispatch({ type: VERIFIER_AVANT_MISE_EN_EXPLOITATION_REQUEST })
	return api.verifierAvantMiseEnExploitation(values)
		.then(() => dispatch({ type: VERIFIER_AVANT_MISE_EN_EXPLOITATION_SUCCESS }))
		.catch(e => {
			dispatch({ type: VERIFIER_AVANT_MISE_EN_EXPLOITATION_ERROR, e})
			throw e
		})
}
export const DEMANDER_MISE_EN_EXPLOITATION_REQUEST = 'EXPLOITATION/DEMANDER_MISE_EN_EXPLOITATION_REQUEST'
export const DEMANDER_MISE_EN_EXPLOITATION_SUCCESS = 'EXPLOITATION/DEMANDER_MISE_EN_EXPLOITATION_SUCCESS'
export const DEMANDER_MISE_EN_EXPLOITATION_ERROR = 'EXPLOITATION/DEMANDER_MISE_EN_EXPLOITATION_ERROR'
export const demanderMiseEnExploitation = (values) => dispatch => {
	dispatch({ type: DEMANDER_MISE_EN_EXPLOITATION_REQUEST })
	return api.demanderMiseEnExploitation(values)
		.then(() => dispatch({ type: DEMANDER_MISE_EN_EXPLOITATION_SUCCESS }))
		.catch(e => {
			dispatch({ type: DEMANDER_MISE_EN_EXPLOITATION_ERROR, e})
			throw e
		})
}