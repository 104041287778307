import MenuItem from '@material-ui/core/MenuItem'
import { Button, Input, Popup, Select, injectSession } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { getSitesPhysiques } from '../../localisationSites/services/sitesPhysiquesAction'
import { getTypesSitesEcrans, postSiteEcran } from '../services/siteEcranAction'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.capacite) {
		errors.capacite = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.capacite)) {
		errors.capacite = { id: 'global.erreurs.numeric' }
	}
	if (!values.type && values.type !== 0) {
		errors.type = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.localisationSite) {
		errors.localisationSite = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouveauSiteEcranPopup = ({ open, profil, closePopup, handleSubmit, postSiteEcran, refreshTable, getSitesPhysiques, getTypesSitesEcrans, goToModificationPage }) => {
	const [localisationSite, setLocalisationSite] = React.useState([])
	const [type, setType] = React.useState([])

	React.useEffect(() => {
		if (localisationSite && localisationSite.length === 0) {
			getSitesPhysiques(profil)
				.then(liste => setLocalisationSite(liste.sites))
		}
		if (type && type.length === 0) {
			getTypesSitesEcrans()
				.then((liste) => setType(liste.types.filter(type => type.id !== 1)))
		}
	}, [])

	const submitCreateSiteEcran = (values) =>
		postSiteEcran(values)
			.then(({ site }) => {
				closePopup()
				goToModificationPage(site)
			})

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateSiteEcran)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouveau site"
			content={<form>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
				/>
				<Field
					id="capacite"
					name="capacite"
					label="Capacité *"
					component={Input}
				/>
				<Field
					id="type"
					name="type"
					label="Type *"
					component={Select}
				>
					{type && type.length !== 0 && type.map(t =>
						<MenuItem key={t.id} value={t.id}>
							{t.libelle}
						</MenuItem>
					)}
				</Field>
				<Field
					id="localisationSite"
					name="localisationSite"
					label="Localisation site *"
					component={Select}
				>
					{localisationSite && localisationSite.length !== 0 && localisationSite.map(site =>
						<MenuItem key={site.id} value={site.id}>
							{site.libelle}
						</MenuItem>
					)}
				</Field>
			</form>}
		/>
	)
}

const actions = {
	postSiteEcran,
	refreshTable: () => dataTableActions.refresh('listeSites'),
	getSitesPhysiques,
	getTypesSitesEcrans,
	goToModificationPage: (site) => dispatch => dispatch(push({ pathname: `/examens-ecran/sites-ecran/${site.id}`, state: { libelle: site.libelle } }))
}

NouveauSiteEcranPopup.propTypes = {
	profil: PropTypes.number,
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	postSiteEcran: PropTypes.func,
	refreshTable: PropTypes.func,
	getSitesPhysiques: PropTypes.func,
	getTypesSitesEcrans: PropTypes.func,
	goToModificationPage: PropTypes.func
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'creationSiteEcran',
		initialValues: {
			libelle: '',
			capacite: '',
			type: 0,
			localisationSite: null
		},
		validate
	}),
	injectSession
)(NouveauSiteEcranPopup)
