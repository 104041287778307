import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { FormCheckbox, getStyles } from 'isotope-client'
import * as selectors from './services/banqueQuestionsSelectors'
import {
	getFamilles,
	getLignes,
	getQuestionDonneesGenerales,
	saveQuestionDonneesGenerales
} from './services/banqueQuestionsActions'
import { Actions, Autocomplete, Button, Input, ResponsiveForm, Select, injectFormSnackbar } from '@oceane/ui'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { BNQ_ID_ETAT_QUESTION, BNQ_ID_ORIGINE_QUESTION } from '../../../config/enums'
import { injectIntl, intlShape } from 'react-intl'

const styles = theme => getStyles({
	divider: {
		color: 'rgba(0,0,0,0.12)',
		marginBottom: 16
	},
	typography: {
		marginBottom: theme.spacing.unit * 2
	}
})

const validate = values => {
	const errors = {}
	if (!values.ligneProgramme) {
		errors.ligneProgramme = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const OngletDonneesGenerales = ({ donneesGenerales, isMarquage, classes, intl, getLignes, getFamilles, change, handleSubmit, saveQuestionDonneesGenerales }) => {

	const [listeFamilles, setListeFamilles] = React.useState([])
	const [codeMatiere, setCodeMatiere] = React.useState(null)

	const isEuropeenne = donneesGenerales.origine === BNQ_ID_ORIGINE_QUESTION.EUROPEENNE

	const getListeFamilles = (ev, value) => {
		// On récupère l'id matière via les 3 premiers caractères
		if (value.length > 2) {
			const newCode = value.substring(0, 3)
			// On ne recalcule les familles que si le code matière change
			if (newCode !== codeMatiere) {
				setCodeMatiere(newCode)
				change('famille', '')
				getFamilles(newCode)
					.then(({ familles }) => setListeFamilles(familles))
			}
		} else {
			change('famille', '')
			setListeFamilles([])
		}
	}

	const submit = values => saveQuestionDonneesGenerales(donneesGenerales.idQuestion, values)
		.catch(e => {
			throw new SubmissionError(e)
		})


	React.useEffect(() => {
		if (!!donneesGenerales.ligneProgramme && donneesGenerales.ligneProgramme.length > 2) {
			const newCode = donneesGenerales.ligneProgramme.substring(0, 3)
			getFamilles(newCode)
				.then(({ familles }) => setListeFamilles(familles))
		}
	}, [])

	return (
		<ResponsiveForm>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Identifiant question"
						value={donneesGenerales.idOceane}
						disabled
					/>
				</Grid>
				{isEuropeenne && <Grid item xs={6}>
					<Input
						meta={{}}
						label="Numéro Europe"
						value={donneesGenerales.numeroEuropeen}
						disabled
					/>
				</Grid>}
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Origine"
						value={intl.formatMessage({ id: `enums.bnqIdOrigineQuestion.${donneesGenerales.origine}` })}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						label="Bilingue"
						name="bilingue"
						component={FormCheckbox}
						disabled={![BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE].includes(donneesGenerales.etat) || isEuropeenne}
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Nom auteur"
						value={donneesGenerales.nomAuteur}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Date de création"
						value={donneesGenerales.dateCreation}
						disabled
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Nom appliqueur"
						value={donneesGenerales.nomAppliqueur}
						disabled
					/>
				</Grid>
				{isEuropeenne && <Grid item xs={6}>
					<Input
						meta={{}}
						label="Chargement Europe"
						value={donneesGenerales.chargementEurope}
						disabled
					/>
				</Grid>}
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Etat"
						value={intl.formatMessage({ id: `enums.bnqIdEtatQuestion.${donneesGenerales.etat}` })}
						disabled
					/>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container>
				<Grid item xs={6}>
					<Field
						label="Ligne de programme *"
						name="ligneProgramme"
						component={Autocomplete}
						minLength={3}
						getSuggestion={(length, value) => getLignes(value)}
						onChange={getListeFamilles}
						disabled={![BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE, BNQ_ID_ETAT_QUESTION.SUPPRIMEE].includes(donneesGenerales.etat)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						label="Famille"
						name="famille"
						component={Select}
						placeholder={<MenuItem key="famille-aucune" value="" />}
						disabled={![BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE, BNQ_ID_ETAT_QUESTION.SUPPRIMEE].includes(donneesGenerales.etat)}
					>
						{listeFamilles.map(famille => <MenuItem key={`famille-${famille.id}`} value={famille.id}>{famille.libelle}</MenuItem>)}
					</Field>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Typography className={classes.typography}>{donneesGenerales.libelleLigneProgramme}</Typography>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Etat de paiement"
						value={intl.formatMessage({ id: `enums.bnqIdEtatPaiementQuestion.${donneesGenerales.etatPaiement}` })}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Payé à"
						value={donneesGenerales.nomPersonne1Payee}
						disabled
					/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6}>
					<Input
						meta={{}}
						label="Date de paiement"
						value={donneesGenerales.datePaiement}
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Input
						meta={{}}
						value={donneesGenerales.nomPersonne2Payee}
						disabled
					/>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container>
				<Grid item xs={6}>
					<Field
						label="Marquage"
						name="marquage"
						component={FormCheckbox}
						disabled={donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.EXPLOITEE || (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.SUPPRIMEE && !isMarquage)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						label="Expertise"
						name="aExpertiser"
						component={FormCheckbox}
						disabled={![BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE, BNQ_ID_ETAT_QUESTION.ACCEPTEE, BNQ_ID_ETAT_QUESTION.HISTORISEE].includes(donneesGenerales.etat)}
					/>
				</Grid>
			</Grid>
			{donneesGenerales.etat !== BNQ_ID_ETAT_QUESTION.EXPLOITEE && <Actions>
				<Button
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					Enregistrer
				</Button>
			</Actions>}
		</ResponsiveForm>
	)
}

OngletDonneesGenerales.propTypes = {
	classes: PropTypes.object,
	intl: intlShape
}

const FORM_NAME = 'questionDonneesGenerales'

const formValues = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => ({
	donneesGenerales: selectors.getDonneesGenerales(state),
	isMarquage: formValues(state, 'marquage'),
	initialValues: selectors.getDonneesGeneralesForm(state)
})

const actions = {
	getQuestionDonneesGenerales,
	getLignes,
	getFamilles,
	saveQuestionDonneesGenerales
}

export default compose(
	injectIntl,
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectFormSnackbar()
)(OngletDonneesGenerales)
