import { postMultipart, fetchFactory } from 'isotope-client'

export const getSitesPhysiques = (profil) => fetchFactory(`/sitesPhysique?profil=${profil}`)

export const postLocalisationSite = (values) => fetchFactory('/sitesPhysique', {
	method: 'post',
	body: JSON.stringify(values)
})

export const getSitePhysique = (id) => fetchFactory(`/sitesPhysique/${id}`)

export const modificationLocalisationSite = (values, profil) => postMultipart(`/sitesPhysique/${values.id}/${profil}`, {
	body: values
})

export const checkFileExist = (id, nomFichier) => fetchFactory(`/sitesPhysique/${id}/file`, {
	method: 'post',
	body: nomFichier
})

export const getDecalageHoraire = () => fetchFactory('/donneesReference/decalagesHoraires')
