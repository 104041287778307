import {
	Actions,
	Button,
	checkAuthorities,
	colors,
	DownloadIcon,
	injectSession,
	ORG_SESSION_ID_ETAT,
	PROFIL_UTILISATEUR,
	EmptyResult,
	injectFormSnackbar
} from '@oceane/ui'
import React from 'react'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import * as selectors from '../../services/sessionsPapierSelectors'
import IconButton from '@material-ui/core/IconButton'
import { fermerCentreSession, getListeAffectations, ouvrirCentreSession } from '../../services/sessionsPapierActions'
import { download } from 'isotope-client/components/download/IsotopeLink'
import classNames from 'classnames'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	},
	sousCapacite: {
		fontWeight: 'bold',
		fontSize: 15,
		color: 'red'
	}
})

const OngletAffectationsSession = ({ sessionPapier, getListeAffectations, fermerCentreSession, ouvrirCentreSession, goToModifierPage, classes }) => {
	const [affectations, setAffectations] = React.useState([])
	const etat = sessionPapier.etat

	React.useEffect(() => {
		getListeAffectations(sessionPapier.id)
			.then(({ result }) => {
				setAffectations(result)
			})
	}, [sessionPapier])

	const ouvrir = (idCentre) => {
		return ouvrirCentreSession(sessionPapier.id, idCentre)
			.then(({ result }) => {
				setAffectations(result)
			})
	}

	const fermer = (idCentre) => {
		return fermerCentreSession(sessionPapier.id, idCentre)
			.then(({ result }) => {
				setAffectations(result)
			})
	}

	return (
		<>
			<Title value="Centres d'examen papier" />
			<StaticDataTable
				data={affectations}
				noResultFragment={<EmptyResult />}
				noPagination
				headers={[{
					key: 'libelleCentre',
					name: 'Centre',
					sortable: true
				}, {
					key: 'capacite',
					name: <span>Capacité des<br />sites ouverts</span>,
					sortable: false
				}, {
					key: 'nbInscrits',
					name: <span>Plus grand nombre<br />d'inscrits à une épreuve</span>,
					sortable: false,
					render: row => <span className={classNames({ [classes.sousCapacite]: row.capacite < row.nbInscrits })}>{row.nbInscrits}</span>
				}, {
					key: 'edition',
					name: 'Edition',
					sortable: false,
					render: data => {
						if (data.edition) {
							return <IconButton
								onClick={() => download(`/files/${sessionPapier.id}/edition/${data.id}`)}>
								<DownloadIcon />
							</IconButton>
						} else {
							return null
						}
					}
				}, {
					key: 'nbAffectes',
					name: <span>Plus grand nombre<br />d'affectés à une épreuve</span>,
					sortable: false
				}, {
					key: 'ouvertFerme',
					sortable: false,
					render: row => <span>{row.ouvert ? 'Ouvert' : 'Fermé'}</span>
				}]}
				makeActions={(row) => {
					if (!row.ouvert && (etat === ORG_SESSION_ID_ETAT.CREEE || etat === ORG_SESSION_ID_ETAT.INITIALISEE || etat === ORG_SESSION_ID_ETAT.INSCRIPTIONS_CLOSES)) {
						return [{ label: 'Ouvrir', action: () => ouvrir(row.id) }]
					}
					if (row.ouvert && (etat === ORG_SESSION_ID_ETAT.CREEE || etat === ORG_SESSION_ID_ETAT.INITIALISEE)) {
						return [{ label: 'Fermer', action: () => fermer(row.idCentreSession) }]
					}
					if (row.ouvert && (etat === ORG_SESSION_ID_ETAT.INSCRIPTIONS_CLOSES)) {
						return [{
							label: 'Modifier',
							action: () => goToModifierPage(sessionPapier.id, row.id)
						}, {
							label: 'Fermer',
							action: () => fermer(row.idCentreSession)
						}]
					}
				}}
				showActions={etat === ORG_SESSION_ID_ETAT.CREEE || etat === ORG_SESSION_ID_ETAT.INITIALISEE || etat === ORG_SESSION_ID_ETAT.INSCRIPTIONS_CLOSES}
				applyRowStyle={row => {
					if (!row.ouvert) {
						return {
							backgroundColor: colors.disabled
						}
					}
					return {}
				}}
			/>
			{etat === ORG_SESSION_ID_ETAT.INSCRIPTIONS_CLOSES && <Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={() => download(`/files/${sessionPapier.id}/quantites`)}
				>
					Afficher quantités
				</Button>
			</Actions>}
		</>
	)
}

const actions = {
	getListeAffectations,
	fermerCentreSession,
	ouvrirCentreSession,
	goToModifierPage: (idSession, idCentre) => dispatch => dispatch(push(`/sessions-papier/${idSession}/parametrage/${idCentre}`))
}

OngletAffectationsSession.propTypes = {
	sessionPapier: PropTypes.object,
	getListeAffectations: PropTypes.func,
	fermerCentreSession: PropTypes.func,
	ouvrirCentreSession: PropTypes.func,
	goToModifierPage: PropTypes.func
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state)
})

export default compose(
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(OngletAffectationsSession)
