import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { EmptyResult, getFilArianeExamenType } from '@oceane/ui'
import { FormSwitch, getStyles, injectToolbarData, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import * as selectors from '../services/examensTypesSelectors'
import {
	getPiecesJustificativesByExamenType,
	toggleActivePj,
	toggleObligatoirePj
} from '../services/examensTypesActions'

const styles = (theme) => getStyles({
	row: {
		minHeight: 60,
		borderBottom: '1px solid rgba(224, 224, 224, 1)' // Diviseur entre les lignes du "tableau"
	},
	header: { // On est obligé de simuler les header d'une table
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 13,
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		paddingBottom: 15,
		paddingTop: 15
	}
})

const OngletPiecesJustificatives = ({
										change,
										classes,
										snackError,
										idExamenType,
										toggleActivePjAction,
										toggleObligatoirePjAction,
										listePiecesJustificatives,
										getPiecesJustificativesByExamenTypeAction
									}) => {

	React.useEffect(() => {
		if (!!idExamenType) {
			getPiecesJustificativesByExamenTypeAction(idExamenType)
				.then(({ pj }) => {
					change('listePiecesJustificatives', pj.map(piece => ({
						...piece,
						obligatoire: piece.obligatoire,
						active: piece.active
					})))
				})
				.catch(() => {
					change('listePiecesJustificatives', [])
				})
		}
	}, [listePiecesJustificatives])

	const handleCheckObligatoire = (event, idPj, field) => {
		toggleObligatoirePjAction(idExamenType, idPj, event.target.checked)
			.catch(() => {
				snackError()
				change(`${field}.obligatoire`, !event.target.checked)
			})
	}

	const handleCheckActive = (event, idPj, field) => {
		toggleActivePjAction(idExamenType, idPj, event.target.checked)
			.catch(() => {
				snackError()
				change(`${field}.active`, !event.target.checked)
			})
	}

	const renderPj = ({ fields }) => <React.Fragment>
				{listePiecesJustificatives.map((pj, index) => {
						return <Grid key={index} container direction="row" className={classes.row}>
							<Grid item md={7}><Typography>{pj.libelle}</Typography></Grid>
							<Grid item md={3}>
								<Field
									name={`listePiecesJustificatives[${index}].obligatoire`}
									component={FormSwitch}
									color="primary"
									onChange={(event, value) => handleCheckObligatoire(event, pj.id, `listePiecesJustificatives[${index}]`)}
								/>
							</Grid>
							<Grid item md={2}>
								<Field
									name={`listePiecesJustificatives[${index}].active`}
									component={FormSwitch}
									color="primary"
									onChange={(event, value) => handleCheckActive(event, pj.id, `listePiecesJustificatives[${index}]`)}
								/>
							</Grid>
						</Grid>
					}
				)}
				{listePiecesJustificatives.length === 0 && <EmptyResult />}
			</React.Fragment>

	return <React.Fragment>
		<Title value="Pièces justificatives" />
		<Grid container direction="row">
			<Grid item md={7}>
				<Typography className={classes.header}>Pièce</Typography>
			</Grid>
			<Grid item md={3}>
				<Typography className={classes.header}>Obligatoire avant passage</Typography>
			</Grid>
			<Grid item md={2}>
				<Typography className={classes.header}>Activé</Typography>
			</Grid>
		{listePiecesJustificatives.length > 0 && <FieldArray name="listePiecesJustificatives" component={renderPj} rerenderOnEveryChange />}
		</Grid>
	</React.Fragment>
}

OngletPiecesJustificatives.propTypes = {
	classes: PropTypes.object,
	idExamenType: PropTypes.number,
	goToDetailEpreuveTypePage: PropTypes.func,
	getPiecesJustificativesByExamenTypeAction: PropTypes.func
}

const mapStateToProps = state => {
	const examenType = selectors.getExamenType(state)
	return {
		idExamenType: examenType.id,
		libelle: examenType.libelleCourt,
		etat: examenType.etat,
		listePiecesJustificatives: selectors.getPiecesJustificatives(state)
	}
}

const actions = {
	getPiecesJustificativesByExamenTypeAction: getPiecesJustificativesByExamenType,
	toggleObligatoirePjAction: toggleObligatoirePj,
	toggleActivePjAction: toggleActivePj
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	injectToolbarData(({ libelle, etat }) => getFilArianeExamenType(libelle, etat)),
	reduxForm({
		form: 'gestionPiecesJustificatives',
		enableReinitialize: true
	}),
	injectSnackActions
)(OngletPiecesJustificatives)