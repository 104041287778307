import * as actions from './sessionsActions'
import { combineReducers } from 'redux'

const ids = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_SESSIONS_LIST_SUCCESS:
			return {
				...state,
				[action.etat]: action.list.map(session => session.id)
			}
		case actions.CLEAN_SESSIONS_LIST:
			const { [action.etat]: omit, ...rest } = state
			return rest
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_SESSIONS_LIST_SUCCESS:
			return action.list.reduce((acc, session) => ({
				...acc,
				[session.id]: session
			}), state)
		default:
			return state
	}
}

export const sessions = combineReducers({
	ids,
	byId
})