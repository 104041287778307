import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { checkAuthorities, EmptyResult, FloatingButton, hasAccess, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import NouvelleLocalisationPopup from './components/NouvelleLocalisationPopup'
import { getSitesPhysiques } from './services/sitesPhysiquesAction'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]


const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const ListeSitesPhysique = ({ profil, getSitesPhysiques, classes, goToDetailLocalisationPage }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [sites, setSites] = React.useState([])

	useEffect(() => {
		getSitesPhysiques(profil)
			.then(liste => setSites(liste.sites))
	}, [profil])


	return (
		<React.Fragment>
			<Title value="Liste des localisations des sites" />
			<StaticDataTable
				headers={[
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: false,
						render: data => <Typography className={classes.libelleColumn}
						                            onClick={() => goToDetailLocalisationPage(data)}>{data.libelle}</Typography>
					}
				]}
				defaultPageSize={10}
				data={sites}
				nom="listeLocalisation"
				noResultFragment={<EmptyResult />}
			/>
			{hasAccess(PROFILS_LOGISTIQUE, profil) && <React.Fragment>
				<FloatingButton onClick={() => setOpenCreation(true)} />
				<NouvelleLocalisationPopup
					open={openCreation}
					closePopup={() => setOpenCreation(false)}
				/>
			</React.Fragment>}
		</React.Fragment>
	)
}

const actions = {
	getSitesPhysiques,
	goToDetailLocalisationPage: (data) => dispatch => dispatch(push({
		pathname: `/examens-ecran/localisation-sites/${data.id}`,
		state: { site: data }
	}))
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(null, actions),
	injectSession,
	withStyles(style)
)(ListeSitesPhysique)
