import { combineReducers } from 'redux'
import * as actions from './examensTypesActions'
import { HANDLE_QUERY_EXAMEN_TYPES, RESET_QUERY_EXAMEN_TYPES } from './examensTypesActions'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_EXAMENS_TYPES_SUCCESS:
			return (action.result || []).map(type => type.id)
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_EXAMENS_TYPES_SUCCESS:
			return (action.result || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const standardsIds = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_EXAMENS_STANDARDS_SUCCESS:
			return (action.result || []).map(type => type.id)
		default:
			return state
	}
}

const standardsById = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_EXAMENS_STANDARDS_SUCCESS:
			return (action.result || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const detailsInit = {}
const details = (state = detailsInit, action) => {
	switch (action.type) {
		case actions.GET_EXAMEN_TYPE_SUCCESS:
		case actions.SAVE_EXAMEN_TYPE_SUCCESS:
		case actions.EXPLOITER_EXAMEN_TYPE_SUCCESS:
		case actions.ACTIVER_EXAMEN_TYPE_SUCCESS:
		case actions.DESACTIVER_EXAMEN_TYPE_SUCCESS:
			return action.result
		case actions.SUPPRIMER_EXAMEN_TYPE_SUCCESS:
		case actions.CLEAR_EXAMEN_TYPE:
			return detailsInit
		default:
			return state
	}
}

const piecesJustificatives = (state = [], action) => {
	switch (action.type) {
		case actions.GET_PJ_EXAMEN_TYPE_SUCCESS:
			return action.pj
		case actions.CLEAR_PJ_EXAMEN_TYPE:
			return []
		case actions.TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_SUCCESS:
			state.find(pj => pj.id === action.pj.id)[0] = action.pj
			return state
		case actions.TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_SUCCESS:
			state.find(pj => pj.id === action.pj.id)[0] = action.pj
			return state
		case actions.CREATE_PJ_EXAMEN_TYPE_SUCCESS:
			return action.pj
		default:
			return state
	}
}

const piecesJustificativesAbsentes = (state = [], action) => {
	switch (action.type) {
		case actions.GET_PJ_ABSENTES_EXAMEN_TYPE_SUCCESS:
			return action.pj
		default:
			return state
	}
}

const initialRecherche = {
	search: '',
	switch: false
}
const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_EXAMEN_TYPES:
			return {
				...state,
				[action.key]: action.value
			}
		case RESET_QUERY_EXAMEN_TYPES:
			return {
				...state,
				search: ''
			}
		default:
			return state
	}
}

export const examensTypes = combineReducers({
	ids,
	byId,
	standards: combineReducers({
		ids: standardsIds,
		byId: standardsById
	}),
	details,
	piecesJustificatives,
	piecesJustificativesAbsentes,
	recherche
})