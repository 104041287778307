import { createSelector } from 'reselect'
import DateUtils from '../../../../utils/DateUtils'

const getLocalState = state => state.candidat.profil

const inscriptionSort = (i1, i2) => {
	if (i1.dateDebut !== null && i2.dateDebut !== null) {
		return DateUtils.parseDate(i1.dateDebut) - DateUtils.parseDate(i2.dateDebut)
	} else if (i1.libelleSession !== null && i2.libelleSession !== null) {
		return i2.libelleSession.localeCompare(i1.libelleSession)
	}
}

export const getListeInscriptions = createSelector(
	getLocalState,
	state => state.examensCandidat.selectionneInscriptions.sort(inscriptionSort)
)
