import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { ResponsiveForm, SmallSelect } from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import { injectListeMatieres } from '../../../references/matieres/services/injectListeMatieres'

const PAIEMENT_FORM_NAME = 'paiementForm'

const PaiementForm = ({ listeMatieres, origine, reset }) => {
	React.useEffect(() => {
		reset(PAIEMENT_FORM_NAME)
	}, [origine])

	return <ResponsiveForm>
		<Field
			id="matiere"
			name="matiere"
			label="Matière"
			component={SmallSelect}
		>
			{listeMatieres.map(matiere =>
				<MenuItem key={matiere.id} value={matiere.id}>
					{matiere.code}
				</MenuItem>
			)}
		</Field>
	</ResponsiveForm>
}


PaiementForm.propTypes = {
	origine: PropTypes.number,
	listeMatieres: PropTypes.array,
	reset: PropTypes.func
}

export default compose(
	injectListeMatieres,
	reduxForm({
		form: PAIEMENT_FORM_NAME
	})
)(PaiementForm)
