import { combineReducers } from 'redux'
import { OPEN_POPUP, CLOSE_POPUP, ADD_ORGANISME_SUCCESS, SET_DETAIL_ORGANISME_SUCCESS } from './nouvelOrganismePopupActions'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const organismeDetails = (state = {}, action) => {
	switch (action.type) {
		case SET_DETAIL_ORGANISME_SUCCESS:
			return action.payload
		default:
			return state
	}
}

const organismeActif = (state = {}, action) => {
	switch (action.type) {
		case ADD_ORGANISME_SUCCESS:
			return action.payload
		default:
			return state
	}
}

export const nouvelOrganisme = combineReducers({
	open,
	organismeDetails,
	organismeActif
})
