import React from 'react'
import { SpringDataTable, Title } from 'isotope-client'
import { EmptyResult, injectSession, Link, ListFiltres } from '@oceane/ui'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as matieresSelectors from '../matieres/services/matieresSelectors'
import * as matieresActions from '../matieres/services/matieresActions'
import PropTypes from 'prop-types'

const FamilleListeMatiere = ({ profil, handleQuery, resetQuery, searchValue }) => {

	React.useEffect(() => resetQuery(), [resetQuery])

	return (
		<React.Fragment>
			<Title value="Liste des matières" />
			<ListFiltres
				handleQueryValue={handleQuery}
				resetQueryValue={resetQuery}
				searchValue={searchValue}
				searchPlaceholderId="references.famille.matieres.search"
			/>
			<SpringDataTable
				apiUrl="/matiere"
				headers={[
					{
						key: 'code',
						name: 'Matière',
						sortable: true
					},
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: true,
					},
					{
						key: 'link',
						render: row => <Link to={`/familles/${row.id}`} state={{ code: row.code }} >Gestion de famille</Link>
					}
				]}
				filters={{
					profilActif: profil,
					code: searchValue
				}}
				defaultPageSize={50}
				noResultFragment={<EmptyResult/>}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	searchValue: matieresSelectors.getSearchValue(state)
})

const actions = {
	handleQuery: matieresActions.handleQuery,
	resetQuery: matieresActions.resetQuery
}

FamilleListeMatiere.propTypes = {
	handleQuery: PropTypes.func.isRequired,
	resetQuery: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired
}

export default compose(
	injectSession,
	connect(mapStateToProps, actions)
)(FamilleListeMatiere)
