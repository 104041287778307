import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { Field, reduxForm } from 'redux-form'
import {
	Actions,
	Button,
	checkAuthorities,
	InputDisabled,
	ListeQuestions,
	PROFIL_UTILISATEUR,
	RES_ID_NUMERO_SERIE,
	SmallSelect,
	StyledTypography,
	injectFormSnackbar
} from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import { injectEpreuveSession } from './service/injectEpreuveSession'
import * as selectors from './service/correctionCopiesSelectors'
import { controleAnomalies, declarerPresence, enregistrerTraitementAnomalies } from './service/correctionCopiesActions'
import ReponsesQuestionPopup from './popup/ReponsesQuestionPopup'

const styles = theme => getStyles({
	/* Eléments de la légende */
	dot: {
		height: 8,
		width: 8,
		borderRadius: '50%',
		display: 'inline-block',
		border: '1px solid #707070',
		marginRight: 9
	},
	dotNoAnswer: {
		backgroundColor: theme.palette.questionList.noAnswer
	},
	dotMultipleAnswers: {
		backgroundColor: theme.palette.questionList.multipleAnswers
	},
	legend: {
		fontSize: 11,
		color: '#707070'
	}
})

const getFilArianeEpreuveSession = (title, session, epreuve, canGoBack = true, addTitle = true) => ({
	title: addTitle ? title : undefined,
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			text: `Session : ${session}`
		}, {
			text: `Epreuve : ${epreuve}`,
			render: text => <StyledTypography bold>{text}</StyledTypography>
		}],
		canGoBack
	}
})

const TraitementAnomaliesPage = (
	{
		classes,
		change,
		reset,
		snackError,
		snackSuccess,
		handleSubmit,
		epreuveSession,
		goBackToEpreuve,
		declarerPresence,
		controleAnomalies,
		goBackToGestionAnomalies,
		resultatControleEtCorrection,
		enregistrerTraitementAnomalies,
	}
) => {
	const [indexCopieEnAnomalieCourante, setIndexCopieEnAnomalieCourante] = React.useState(0)
	const [isReponsesQuestionPopupOpen, setIsReponsesQuestionPopupOpen] = React.useState(false)
	const [questionCourante, setQuestionCourante] = React.useState(null)

	React.useEffect(() => {
		if (resultatControleEtCorrection !== null && resultatControleEtCorrection.listeCopiesNonRendues.length === 0) {
			change('numInscription', '')
		}
	}, [resultatControleEtCorrection])

	/**
	 * Enregistre les traitement apportés aux anomalies de la copie
	 */
	const enregistrer = (values) => {
		return enregistrerTraitementAnomalies(
			epreuveSession.id,
			resultatControleEtCorrection.listeCopiesEnAnomalie[indexCopieEnAnomalieCourante].id,
			{ ...values, fichierCharge: epreuveSession.fichierCharge }
		)
			.then(reset)
	}

	/**
	 * Appel du traitement de contrôle des anomalies puis redirection vers la page de
	 * correction de l'épreuve
	 */
	const controleEtCorrection = () => controleAnomalies(epreuveSession.id, epreuveSession.fichierCharge)
		.then(() => {
			goBackToGestionAnomalies(epreuveSession.id)
		})
		.catch(e => {
			goBackToEpreuve(epreuveSession.id)
			snackError()
			throw e
		})

	if (resultatControleEtCorrection) {
		const copieCourante = resultatControleEtCorrection.listeCopiesEnAnomalie[indexCopieEnAnomalieCourante]
		if (!!copieCourante) {
			return <>
				<Title value="Données générales" />
				<InputDisabled
					label="Anomalie niveau 1"
					fullWidth
				>
					{copieCourante.anomalieNiveau1 ? 'Oui' : 'Non'}
				</InputDisabled>
				{copieCourante.typeNiveau1 !== null && <InputDisabled
					label="Types"
					fullWidth
				>
					{copieCourante.typeNiveau1Libelle}
				</InputDisabled>}
				{copieCourante.typeNiveau1 === null && !copieCourante.numeroSerieInitialValide && <InputDisabled
					label="Types"
					fullWidth
				>
					{copieCourante.typeNiveau1Libelle}
				</InputDisabled>}

				<Grid
					container
					direction="row"
					alignItems="baseline"
				>
					<Grid item>
						<InputDisabled
							label="N° d'inscription"
						>
							{copieCourante.numeroInscription}
						</InputDisabled>
					</Grid>
					{copieCourante.typeNiveau1 !== null && <>
						<Grid item>
							<Field
								name="numInscription"
								label=" "
								component={SmallSelect}
								displayEmpty
							>
								<MenuItem value="" />
								{(!!resultatControleEtCorrection.listeCopiesNonRendues &&
									resultatControleEtCorrection.listeCopiesNonRendues.length !== 0) &&
								resultatControleEtCorrection.listeCopiesNonRendues.map((value, key) =>
									<MenuItem key={key}
											  value={value.numeroInscription}>{value.numeroInscription}</MenuItem>
								)}
							</Field>
						</Grid>
					</>}

				</Grid>

				<Grid
					container
					direction="row"
					alignItems="baseline"
				>
					<Grid item>
						<InputDisabled
							label="Série N°"
						>
							{copieCourante.numeroSerie !== null ? `40${copieCourante.numeroSerie}` : ''}
						</InputDisabled>
					</Grid>
					{!copieCourante.numeroSerieActuelValide && <Grid item>
						<Field
							name="numSerie"
							label=" "
							component={SmallSelect}
						>
							{Object.keys(RES_ID_NUMERO_SERIE).map(num => <MenuItem
								key={num}
								value={num}
							>
								{RES_ID_NUMERO_SERIE[num]}
							</MenuItem>)}
						</Field>
					</Grid>}
				</Grid>

				<Actions>
					{copieCourante.anomalieCoherence && <Button
						onClick={() => {
							declarerPresence(epreuveSession.id, copieCourante.idEpreuveInscription, copieCourante.id)
								.then(() => snackSuccess())
								.catch(() => snackError())
						}}
					>
						Présent
					</Button>}
					<Button
						type="submit"
						variant="contained"
						onClick={handleSubmit(enregistrer)}
					>
						Enregistrer
					</Button>
				</Actions>

				<Title value="Questions" />

				{copieCourante.reponsesCopiePapier !== null && <Grid container direction="row">
					<Grid item md={8}>
						<ListeQuestions>
							{copieCourante.reponsesCopiePapier.map((value, key) => ({
								label: value.positionQuestion,
								trigger: () => {
									setQuestionCourante(value)
									setIsReponsesQuestionPopupOpen(true)
								},
								noAnswer: value.reponse === 0,
								multipleAnswers: value.reponse === 5
							}))}
						</ListeQuestions>
					</Grid>
					<Grid item md={4}>
						<span className={[classes.dot, classes.dotNoAnswer].join(' ')}> </span>
						<span className={classes.legend}>Pas de réponse</span>
						<br />
						<span className={[classes.dot, classes.dotMultipleAnswers].join(' ')}> </span>
						<span className={classes.legend}>Plusieurs réponses</span>
					</Grid>
				</Grid>}

				<ReponsesQuestionPopup
					open={isReponsesQuestionPopupOpen}
					closePopup={() => setIsReponsesQuestionPopupOpen(false)}
					question={questionCourante}
					idEpreuveSession={epreuveSession.id}
					idCopiePapier={copieCourante.id}
				/>


				<Actions>
					{indexCopieEnAnomalieCourante < resultatControleEtCorrection.listeCopiesEnAnomalie.length - 1 &&
					<Button
						variant="contained"
						onClick={() => {
							// On reset le form quand on change de copie
							reset()
							// Incrémenter l'index permet d'afficher la copie suivante
							setIndexCopieEnAnomalieCourante(indexCopieEnAnomalieCourante + 1)
						}}
					>
						{'Copie suivante >>'}
					</Button>}
					{indexCopieEnAnomalieCourante >= resultatControleEtCorrection.listeCopiesEnAnomalie.length - 1 &&
					<Button
						variant="contained"
						onClick={() => {
							// Une fois toutes les copies en anomalies traitées ou passées,
							// on retourne sur la page de gestion des anomalies
							controleEtCorrection()
						}}
					>
						Terminer
					</Button>}
				</Actions>
			</>
		} else return null
	}
	// Si l'utilisateur tente d'accéder à la page sans être passé par le calcul
	// controle/correction de l'étape précédente, il est redirigé vers cette étape
	goBackToEpreuve(epreuveSession.id)
	snackError()
	return null
}

const actions = {
	declarerPresence,
	controleAnomalies,
	enregistrerTraitementAnomalies,
	goBackToEpreuve: idEpreuveSession => dispatch => dispatch(push(`/correction-copies/epreuve/${idEpreuveSession}`)),
	// La page de gestion des anomalies est la page précédente
	// On utilise goBack plutôt que push pour que par la suite, la flèche de retour arrière ne ramène pas sur cette page
	// Corrige #645
	goBackToGestionAnomalies: () => dispatch => dispatch(goBack())
}

const mapStateToProps = state => ({
	epreuveSession: selectors.getEpreuveSession(state),
	resultatControleEtCorrection: selectors.getResultatControleEtCorrection(state)
})

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'TraitementAnomaliesForm',
		initialValues: {
			numSerie: Object.keys(RES_ID_NUMERO_SERIE)[0]
		}
	}),
	injectEpreuveSession,
	injectToolbarData(({ epreuveSession }) => ({
		...getFilArianeEpreuveSession(
			'Traitement des anomalies',
			epreuveSession ? epreuveSession.libelleSession : '',
			epreuveSession ? epreuveSession.libelleEpreuveType : ''
		)
	})),
	injectFormSnackbar(),
	withStyles(styles)
)(TraitementAnomaliesPage)