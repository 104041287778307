import { combineReducers } from 'redux'
import * as actions from './administrationActions'

const initialRecherche = {
	nom: '',
	identifiant: '',
	profil: -1,
}

export const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case actions.HANDLE_QUERY:
			return {
				...state,
				[action.key]: action.value
			}
		case actions.RESET_FILTERS:
			return initialRecherche
		default:
			return state
	}
}

const initialUtilisateur = {}

export const utilisateur = (state = initialUtilisateur, action) => {
	switch (action.type) {
		case actions.GET_UTILISATEUR_SUCCESS:
		case actions.SAVE_UTILISATEUR_SUCCESS:
			return action.utilisateur
		case actions.GET_UTILISATEUR_ERROR:
			return initialRecherche
		default:
			return state
	}
}

const initialInformations = {
	loading: false
}
export const information = (state = initialInformations, action) => {
	switch (action.type) {
		case actions.GET_INFORMATION_REQUEST:
			return {
				...state,
				loading: true
			}
		case actions.GET_INFORMATION_SUCCESS:
		case actions.GET_INFORMATION_ERROR:
			return initialInformations
		default:
			return state
	}
}

export const administration = combineReducers({
	recherche,
	utilisateur,
	information
})
