import { injectSession } from '@oceane/ui'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as banqueQuestionsActions from '../../services/banqueQuestionsActions'
import { push } from 'connected-react-router'
import AccesDirectForm from './AccesDirectForm'
import { FILTRE_QUESTIONS_FORM_NAME } from './QuestionsFiltres'
import { reset } from 'redux-form'

const AccesDirect = ({
						 profil,
						 goToQuestionPage,
						 snackError,
						 setFiltres,
						 resetFormRecherche,
						 getQuestionIdByIdentifiant,
						 getQuestionIdByNumeroEuropeen,
						 closeForm
					 }) => {
	/**
	 * Redirige sur la page de la question si celle-ci existe, sinon un message d'erreur est affiché
	 */
	const goToQuestion = (values) => {
		let promise = null
		if (!!values.identifiant) {
			promise = getQuestionIdByIdentifiant(values.identifiant, values.exploitee, profil)
		} else if (!!values.numeroEuropeen) {
			promise = getQuestionIdByNumeroEuropeen(values.numeroEuropeen, values.exploitee, profil)
		}

		if (!!promise) {
			promise
				.then(({ listeIds }) => {
					if (listeIds.length === 1) {
						goToQuestionPage(listeIds[0], values.exploitee)
					} else {
						resetFormRecherche()
						closeForm()
						setFiltres({
							listeIds
						})
					}
				})
				.catch(() => snackError('Aucune question ne correspond au critère recherché'))
		}
	}

	return <AccesDirectForm onSubmit={goToQuestion} />
}

const actions = {
	getQuestionIdByIdentifiant: banqueQuestionsActions.getQuestionIdByIdentifiant,
	getQuestionIdByNumeroEuropeen: banqueQuestionsActions.getQuestionIdByNumeroEuropeen,
	goToQuestionPage: (id, exploitee) => dispatch => dispatch(push(`/questions${exploitee ? 'Exploitation' : ''}/${id}`)),
	resetFormRecherche: () => dispatch => dispatch(reset(FILTRE_QUESTIONS_FORM_NAME))
}

AccesDirect.propTypes = {
	profil: PropTypes.number,
	getQuestionIdByIdentifiant: PropTypes.func,
	getQuestionIdByNumeroEuropeen: PropTypes.func,
	goToQuestionPage: PropTypes.func
}

export default compose(
	connect(null, actions),
	injectSnackActions,
	injectSession
)(AccesDirect)
