import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import classNames from 'classnames'
import ParametrageButton from './ParametrageButton'
import * as shapes from '../../../../config/shapes'

const style = (theme) => getStyles({
	paragraphe: {
		paddingLeft: 50,
		lineHeight: '2.5em'
	},
	nbQuestions: {
		paddingLeft: 5,
		paddingRight: 10
	},
	nonParametrable: {},
	parametrable: {
		color: '#FF0000'
	},
	parametre: {
		fontWeight: 'bold',
		color: theme.palette.primary.main
	}
})

const ParagrapheItem = ({ paragraphe, editable, classes, openPopup }) => {

	const {
		parametrable,
		nbQuestionsATirer,
		nbQuestionsNouvellesATirer,
		nbQuestionsJeunesATirer,
		nbQuestionsMaturesATirer
	} = paragraphe

	const parametre = nbQuestionsATirer !== null

	let textStyle = classes.nonParametrable
	if (parametrable) {
		if (parametre) {
			textStyle = classes.parametre
		} else {
			textStyle = classes.parametrable
		}
	}

	return <Grid item container direction="row">
		<Grid item xs={5}>
			<Typography
				component="span"
				inline
				className={classNames(classes.paragraphe, textStyle)}
			>
				{`Paragraphe ${paragraphe.code}`}
			</Typography>
		</Grid>
		{parametrable && <Grid container item xs={7} justify="space-between">
			{parametre && <Typography component="span" inline className={classNames(classes.nbQuestions, textStyle)}>
				<FormattedMessage
					id={editable ? 'references.scripts.nbQuestions' : 'references.scripts.nbQuestionsFull'}
					values={{
						nb: nbQuestionsATirer,
						nouvelles: nbQuestionsNouvellesATirer,
						jeunes: nbQuestionsJeunesATirer,
						matures: nbQuestionsMaturesATirer
					}}
				/>
			</Typography>}
			{editable && <ParametrageButton noeud={paragraphe} parametre={parametre} openPopup={openPopup} />}
		</Grid>}
	</Grid>
}

ParagrapheItem.propTypes = {
	paragraphe: shapes.paragrapheShape,
	editable: PropTypes.bool,
	classes: PropTypes.object,
	openPopup: PropTypes.func
}

export default compose(
	withStyles(style)
)(ParagrapheItem)
