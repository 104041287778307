import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import { goBack } from 'connected-react-router'
import moment from 'moment'
import { Actions, Button, DateInput, Error, Input, ResponsiveForm, injectFormSnackbar } from '@oceane/ui'
import { FormCheckbox, getStyles } from 'isotope-client'
import * as selectors from '../../services/sessionsPapierSelectors'
import ConfirmEtatPopup from './ConfirmEtatPopup'
import { injectIntl } from 'react-intl'
import { ETAT_SESSION } from '../../../../../utils/consts'
import { deleteSessionPapier, putSessionPapierDonneesGenerales } from '../../services/sessionsPapierActions'

const style = () => getStyles({
	button: {
		marginRight: 7
	}
})

const validate = values => {
	const errors = {}

	if (!values.dateOuvertureInscriptions) {
		errors.dateOuvertureInscriptions = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.dateClotureInscriptions) {
		errors.dateClotureInscriptions = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.datePublicationResultats) {
		errors.datePublicationResultats = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

// Mapping sur l'enum ETAT_SESSION
const PASSAGE_ETAT_CONFIRMATION = {
	2: { title: 'Initialisation de la session', message: 'Confirmez-vous l\'initialisation de la session ?' },
	3: { title: 'Clôture des inscriptions', message: 'Confirmez-vous la clôture des inscriptions de la session ?' },
	4: { title: 'Planification de la session', message: 'Confirmez-vous la planification de la session ?' },
	5: { title: 'Fin d\'envoi des convocations', message: 'Confirmez-vous la fin d\'envoi des convocations ?' },
	6: { title: 'Fin de saisie des PV', message: 'Confirmez-vous la fin de saisie des PV de salle pour la session ?' },
	7: { title: 'Fin de correction', message: 'Confirmez-vous la fin de correction pour la session ?' },
	8: { title: 'Fin d\'édition des lettres de résultats', message: 'Confirmez-vous la fin d\'édition des lettres de résultats pour la session ?' },
	9: { title: 'Suppression de la session', message: 'Confirmez-vous la suppression de cette session ?' }, // Suppression
	10: { title: 'Réinitialisation de la session', message: 'Confirmez-vous la réinitialisation de la session (les affectations des candidats et surveillants existantes seront supprimées) ?' } // Réinitialisation
}

const OngletGeneral = ({ id, etat, classes, error, submitting, handleSubmit, putSessionPapierDonneesGenerales, deleteSessionPapier, goToRechercheSession, snackSuccess, snackError }) => {
	const [popup, setPopup] = React.useState({})
	const [newEtat, setNewEtat] = React.useState(null)

	const openPopup = (etat) => {
		setNewEtat(etat)
		setPopup({
			open: true,
			...PASSAGE_ETAT_CONFIRMATION[etat]
		})
	}

	const enregistrer = (values) =>
		putSessionPapierDonneesGenerales(values.id, {
			...values,
			etat: undefined,
			enregistrement: true
		}).catch(e => {
			throw new SubmissionError(e)
		})

	const changerEtat = (values) =>
		putSessionPapierDonneesGenerales(id, {
			...values,
			etat: newEtat,
			reinitialisation: newEtat === 10
		}).then(() => setPopup({}))
			.catch(e => {
				throw new SubmissionError(e)
			})

	const supprimer = () =>
		deleteSessionPapier(id)
			.then(() => {
				snackSuccess('La session a bien été supprimée')
				goToRechercheSession()
			})
			.catch(() => snackError('Une erreur est survenue'))


	const handleActionButtons = () => {
		switch (etat) {
			case ETAT_SESSION.CREE:
				return <>
					<Button className={classes.button} color="primary" onClick={() => openPopup(9)} loading={newEtat === 9 && submitting}>Supprimer</Button>
					<Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.INITIALISEE)} loading={newEtat === ETAT_SESSION.INITIALISEE && submitting}>Initialiser</Button>
				</>
			case ETAT_SESSION.INITIALISEE:
				return <Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.INSCRIPTIONS_CLOSES)} loading={newEtat === ETAT_SESSION.INSCRIPTIONS_CLOSES && submitting}>Clôturer les inscriptions</Button>
			case ETAT_SESSION.INSCRIPTIONS_CLOSES:
				return <>
					<Button className={classes.button} color="primary" onClick={() => openPopup(10)} loading={newEtat === 10 && submitting}>Réinitialiser</Button>
					<Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.PLANIFIEE)} loading={newEtat === ETAT_SESSION.PLANIFIEE && submitting}>Planifier</Button>
				</>
			case ETAT_SESSION.PLANIFIEE:
				return <>
					<Button className={classes.button} color="primary" onClick={() => openPopup(10)} loading={newEtat === 10 && submitting}>Réinitialiser</Button>
					<Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.CONVOCATIONS_ENVOYEES)} loading={newEtat === ETAT_SESSION.CONVOCATIONS_ENVOYEES && submitting}>Convocations envoyées</Button>
				</>
			case ETAT_SESSION.CONVOCATIONS_ENVOYEES:
				return <Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.PV_SAISIS)} loading={newEtat === ETAT_SESSION.PV_SAISIS && submitting}>PV saisis</Button>
			case ETAT_SESSION.PV_SAISIS:
				return <Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.CORRIGEE)} loading={newEtat === ETAT_SESSION.CORRIGEE && submitting}>Session corrigée</Button>
			case ETAT_SESSION.CORRIGEE:
				return <Button color="primary" variant="contained" onClick={() => openPopup(ETAT_SESSION.CLOTUREE)} loading={newEtat === ETAT_SESSION.CLOTUREE && submitting}>Clôturer la session</Button>
			default:
				return <></>
		}

	}

	return (<>
		<Error error={error} />
		<ResponsiveForm>
			<Field
				name="libelleSession"
				label="Libellé de la session"
				component={Input}
				disabled
			/>
			<Field
				name="dateOuvertureInscriptions"
				type="date"
				label="Date d'ouverture des inscriptions *"
				component={DateInput}
				disabled={etat !== ETAT_SESSION.CREE}
			/>
			<Field
				name="dateClotureInscriptions"
				type="date"
				label="Date de clôture des inscriptions *"
				component={DateInput}
				disabled={etat !== ETAT_SESSION.CREE}
			/>
			<Field
				name="datePublicationResultats"
				type="date"
				label="Date de publication des résultats *"
				component={DateInput}
				disabled={etat === ETAT_SESSION.CLOTUREE}
			/>
			<Field
				name="exceptionnelle"
				label="Session exceptionnelle"
				component={FormCheckbox}
				disabled
			/>
			<Field
				name="etat"
				label="Etat"
				component={Input}
				disabled
			/>
			<Actions>
				{etat !== ETAT_SESSION.CLOTUREE && <Button
					color="primary"
					onClick={handleSubmit(enregistrer)}
					loading={!newEtat && submitting}
				>
					Enregistrer
				</Button>}
				{handleActionButtons()}
			</Actions>
			<ConfirmEtatPopup popupOptions={popup} onSubmit={(values) => newEtat === 9 ? supprimer() : handleSubmit(changerEtat)(values)} onClose={() => setPopup({})} />
		</ResponsiveForm>
	</>)
}

const mapStateToProps = (state, { intl }) => {
	const sessionPapier = selectors.getDonneesGenerales(state)

	return {
		initialValues: {
			...sessionPapier,
			etat: sessionPapier && sessionPapier.etat && intl.formatMessage({ id: `references.sessions.etatPapier.${sessionPapier.etat}` }),
			dateOuvertureInscriptions: sessionPapier && sessionPapier.dateOuvertureInscriptions && moment(sessionPapier.dateOuvertureInscriptions).format('YYYY-MM-DD'),
			dateClotureInscriptions: sessionPapier && sessionPapier.dateClotureInscriptions && moment(sessionPapier.dateClotureInscriptions).format('YYYY-MM-DD'),
			datePublicationResultats: sessionPapier && sessionPapier.datePublicationResultats && moment(sessionPapier.datePublicationResultats).format('YYYY-MM-DD'),
		},
		etat: sessionPapier.etat,
		id: sessionPapier.id
	}
}

const actions = {
	putSessionPapierDonneesGenerales,
	deleteSessionPapier,
	goToRechercheSession: () => dispatch => dispatch(goBack())
}

OngletGeneral.propTypes = {
	classes: PropTypes.object,
	id: PropTypes.number,
	etat: PropTypes.number,
	error: PropTypes.object,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	putSessionPapierDonneesGenerales: PropTypes.func,
	deleteSessionPapier: PropTypes.func,
	goToRechercheSession: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'sessionGeneraleForm',
		validate,
		enableReinitialize: true
	}),
	injectFormSnackbar({ success: 'La session a bien été modifiée', error: 'La session comporte des erreurs' }),
	withStyles(style)
)(OngletGeneral)
