import { createSelector } from 'reselect'
import { getReferences } from '../../../referencesSelectors'

const getLocalState = state => getReferences(state).examenPratique

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)

export const isPopupOpenCreation = createSelector(
	getLocalState,
	state => state.openCreation
)

export const getExamensTypePratique = createSelector(
	getLocalState,
	state => state.examensPratique.examenType
)

export const getFormationTypePratique = createSelector(
	getLocalState,
	state => state.examensPratique.formationType
)