import React from 'react'

const SvgHelicopter = props => (
	<svg width="3em" height="3em" viewBox="0 0 512 512" {...props}>
		<path
			d="M432.733 399.533c43.708 0 79.267-35.559 79.267-79.267 0-79.145-64.389-143.533-143.533-143.533h-33.2v-49.267c0-8.284-6.716-15-15-15h-17.133V78.2H497c8.284 0 15-6.716 15-15s-6.716-15-15-15H79.267c-8.284 0-15 6.716-15 15s6.716 15 15 15h193.867v34.267H256c-8.284 0-15 6.716-15 15v49.267H94.267v-1.067c0-25.754-20.804-47.133-47.133-47.133H15c-8.284 0-15 6.716-15 15v80.333C0 249.856 21.144 271 47.133 271h113.533v17.133c0 56.717 42.202 103.148 96.4 110.397v35.27h-81.4c-8.284 0-15 6.716-15 15s6.716 15 15 15h282.546c18.662 0 36.204-7.267 49.395-20.461 5.857-5.859 5.855-15.356-.004-21.213s-15.356-5.855-21.213.004c-7.523 7.526-17.53 11.671-28.178 11.671h-74.747v-34.267h49.268zm48.275-94.266h-96.474c-27.166 0-49.267-22.101-49.267-49.267v-49.267h33.2c57.518 0 105.177 42.996 112.541 98.534zM271 142.467h34.267v34.267H271zm-80.333 145.666V256c0-8.284-6.716-15-15-15H47.133C37.686 241 30 233.314 30 223.867v-65.333h17.133c9.459 0 17.133 7.657 17.133 17.133v16.067c0 8.284 6.716 15 15 15h226V256c0 43.708 35.559 79.267 79.267 79.267h95.124c-6.361 19.852-24.988 34.267-46.924 34.267H272.067c-44.953-.001-81.4-36.368-81.4-81.401zm162.8 145.667h-66.4v-34.267h66.4z"
			fill="#6361a9"
		/>
	</svg>
);

export default SvgHelicopter;
