import { checkAuthorities, colors, injectSession, PROFIL_UTILISATEUR, StyledTypography } from '@oceane/ui'
import { reduxForm } from 'redux-form'
import React from 'react'
import { compose } from 'redux'
import { getStyles, getToolbarData, resetToolbarData, setToolbarData, StaticDataTable, Title } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import * as selectors from '../../services/sessionsPapierSelectors'
import { FormattedMessage } from 'react-intl'
import { cleanSessionPapier, fermerSiteSession, ouvrirSiteSession } from '../../services/sessionsPapierActions'
import { injectCentrePapier, injectDonneesGenerales } from '../../services/sessionsPapierInjectors'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const filAriane = (sessionPapier) => (sessionPapier && {
	title: 'Détail session',
	subheader: {
		separator: () => '|',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography bold>Session : {sessionPapier.libelleSession}</StyledTypography>
				{sessionPapier.etat && <StyledTypography primary bold><FormattedMessage
					id={`references.sessions.etatPapier.${sessionPapier.etat}`} /></StyledTypography>}
			</>
		}]
	}
})

const OngletAffectationsSession = ({ sessionPapier, centre, idCentre, toolbarData, changeToolbarData, cleanToolbarData, fermerSiteSession, ouvrirSiteSession }) => {
	const [centreSite, setCentreSite] = React.useState({})
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [menuContent, setMenuContent] = React.useState(null)
	const isMenuOpen = Boolean(anchorEl)

	React.useEffect(() => {
		changeToolbarData(toolbarData.id, filAriane(sessionPapier))
		return () => cleanToolbarData(toolbarData.id)
	}, [sessionPapier])

	React.useEffect(() => {
		setCentreSite(centre)
	}, [centre])

	const ouvrir = (idSite) => {
		return ouvrirSiteSession(sessionPapier.id, idCentre, idSite)
			.then(({ result }) => {
				setCentreSite(result)
				setMenuContent([<MenuItem onClick={() => fermer(result.sites.id)}>Fermer</MenuItem>])
				setAnchorEl(null)
			})
	}

	const fermer = (idSite) => {
		return fermerSiteSession(sessionPapier.id, idCentre, idSite)
			.then(({ result }) => {
				setCentreSite(result)
				setMenuContent([<MenuItem onClick={() => ouvrir(result.sites.id)}>Ouvrir</MenuItem>])
				setAnchorEl(null)
			})
	}

	const openMenu = (event, ouvert, id) => {
		setAnchorEl(event.currentTarget)
		if (!ouvert) {
			setMenuContent([<MenuItem onClick={() => ouvrir(id)}>Ouvrir</MenuItem>])
		} else {
			setMenuContent([<MenuItem onClick={() => fermer(id)}>Fermer</MenuItem>])
		}
	}

	return (
		<>
			<Title value="Centre" />
			<StyledTypography>{centre.libelleCentre}</StyledTypography>
			<Title value="Ouverture des sites du centre" />
			<StaticDataTable
				data={centreSite.sites}
				headers={[{
					key: 'libelle',
					name: 'Site',
					sortable: true
				}, {
					key: 'capacite',
					name: 'Capacité',
					sortable: false
				}, {
					key: 'actions',
					sortable: false,
					render: row => {
						if (!row.ouvert) {
							return <Grid container><StyledTypography>Fermé</StyledTypography> <IconButton
								onClick={(event) => openMenu(event, row.ouvert, row.id)}><MoreVertIcon /></IconButton></Grid>
						} else {
							return <Grid container><StyledTypography>Ouvert</StyledTypography> <IconButton
								onClick={(event) => openMenu(event, row.ouvert, row.id)}><MoreVertIcon /></IconButton></Grid>
						}
					}
				}]}
				applyRowStyle={row => {
					if (!row.ouvert) {
						return {
							backgroundColor: colors.disabled
						}
					}
					return {}
				}}
			/>
			<Menu
				id="menuEtat"
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={() => setAnchorEl(null)}
			>
				{menuContent}
			</Menu>
		</>
	)
}

const actions = {
	cleanSessionPapier,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	fermerSiteSession,
	ouvrirSiteSession
}

OngletAffectationsSession.propTypes = {
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	cleanToolbarData: PropTypes.func,
	sessionPapier: PropTypes.object,
	centre: PropTypes.object,
	fermerSiteSession: PropTypes.func,
	ouvrirSiteSession: PropTypes.func,
	idCentre: PropTypes.number
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state),
	toolbarData: getToolbarData(state)
})

export default compose(
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	injectCentrePapier,
	injectDonneesGenerales,
	withStyles(style),
	reduxForm({
		form: 'parametrageCentrePapierForm'
	})
)(OngletAffectationsSession)
