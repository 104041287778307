import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { FormInput, getStyles, Title } from 'isotope-client'
import { Actions, Button, Error, injectSession, LargeInput, PROFIL_UTILISATEUR, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import * as selectors from '../services/banqueQuestionsSelectors'
import { BNQ_ID_ETAT_QUESTION, BNQ_ID_ORIGINE_QUESTION, BNQ_ID_TYPE_ACTION } from '../../../../config/enums'
import { changerEtatQuestion, setVisaRDB } from '../services/banqueQuestionsActions'
import { push } from 'connected-react-router'
import * as shapes from '../../../../config/shapes'
import SvgHelicopter from '../../../../layouts/assets/SvgHelicopter'

const styles = () => getStyles({
	field: {
		width: '100%'
	},
	tooltip: {
		margin: '-15px 0 0 16px'
	},
})

const QuestionChangerEtatForm = ({ profil, donneesGenerales, etatGlobal, changerEtatQuestion, setVisaRDB, goToRecherche, handleSubmit, initialize, reset, error, classes, initialValues }) => {

	const submit = typeAction => values => changerEtatQuestion(etatGlobal.idQuestion, typeAction, values)
		.then(() => {
			if (typeAction === BNQ_ID_TYPE_ACTION.SUPPRESSION) {
				goToRecherche()
			} else {
				reset()
			}
		})
		.catch(e => {
			throw new SubmissionError(e)
		})

	const submitVisaRDB = () => setVisaRDB(etatGlobal.idQuestion)
		.then(nouvelEtatGlobal => initialize({
			commentaire: '',
			nomPrenomVisaRDB: nouvelEtatGlobal.nomPrenomVisaRDB
		}))
		.catch(e => {
			throw new SubmissionError(e)
		})

	const isCreeeOuRefusee = [BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.REFUSEE].includes(donneesGenerales.etat)
	const isAcceptee = BNQ_ID_ETAT_QUESTION.ACCEPTEE === donneesGenerales.etat
	const isSupprimee = BNQ_ID_ETAT_QUESTION.SUPPRIMEE === donneesGenerales.etat
	const isRefusee = BNQ_ID_ETAT_QUESTION.REFUSEE === donneesGenerales.etat
	const isCree = BNQ_ID_ETAT_QUESTION.CREEE === donneesGenerales.etat
	const isCreeeOuAccepteeOuApplicable = [BNQ_ID_ETAT_QUESTION.CREEE, BNQ_ID_ETAT_QUESTION.ACCEPTEE, BNQ_ID_ETAT_QUESTION.APPLICABLE].includes(donneesGenerales.etat)
	const origineFrance = BNQ_ID_ORIGINE_QUESTION.FRANCAISE === donneesGenerales.origine

	const isCreeeRefuseeFrancaise = isCreeeOuRefusee && !donneesGenerales.bilingue && origineFrance
	const isAccepteeBilingue = isAcceptee && donneesGenerales.bilingue
	const isMonolingueRefusee = isRefusee && !donneesGenerales.bilingue

	const showButtonSupprimer = isCreeeOuRefusee && origineFrance
	const showButtonAccepter = isCreeeOuRefusee && donneesGenerales.bilingue
	const showButtonVisaRDB = profil === PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id && etatGlobal.boutonVisaRDBVisible && (isCreeeRefuseeFrancaise || isAccepteeBilingue)
	const showButtonAppliquer = profil === PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS.id && (isAccepteeBilingue || isCreeeRefuseeFrancaise)
	const showButtonRestaurer = isSupprimee && origineFrance
	const showButtonRefuser = isCreeeOuAccepteeOuApplicable

	// Gestion variant button
	const isBtnVisaRdbPrimary = profil === PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id && (isAccepteeBilingue || isCreeeRefuseeFrancaise)
	const isBtnSupprimerPrimary = profil === PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id && isMonolingueRefusee && initialValues && !!initialValues.nomPrenomVisaRDB
	const isBtnRefuserPrimary = profil === PROFIL_UTILISATEUR.RESPONSABLE_DE_BANQUE.id && initialValues && !!initialValues.nomPrenomVisaRDB && (isAcceptee || isCree)

	return (
		<>
			<Error error={error} />
			<Title value="Informations" />
			<Grid container direction="column" alignItems="flex-start">
				<Grid item xs={12} className={classes.field}>
					<Field
						name="commentaire"
						label="Commentaire"
						fullWidth
						multiline
						rows={5}
						rowsMax={5}
						component={FormInput}
					/>
				</Grid>
				<Grid item xs={12} container direction="row" className={classes.field}>
					<Field
						name="nomPrenomVisaRDB"
						label="Visa RDB"
						component={LargeInput}
						disabled
					/>
					{etatGlobal.uniquementHelicoptere && <SvgHelicopter />}
				</Grid>
			</Grid>
			<Actions>
				{showButtonSupprimer && (isBtnSupprimerPrimary ?
						<Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.SUPPRESSION))} variant="contained">Supprimer</Button>
						: <Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.SUPPRESSION))}>Supprimer</Button>
				)}
				{showButtonRestaurer && <Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.RESTAURATION))} variant="contained">Restaurer</Button>}
				{showButtonRefuser && (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.APPLICABLE || isBtnRefuserPrimary ?
						<Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.REFUS))} variant="contained">Refuser</Button>
						: <Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.REFUS))}>Refuser</Button>
				)}
				{showButtonVisaRDB && (isBtnVisaRdbPrimary ?
						<Button onClick={handleSubmit(submitVisaRDB)} variant="contained">Visa RDB</Button>
						: <Button onClick={handleSubmit(submitVisaRDB)}>Visa RDB</Button>
				)}
				{showButtonAccepter && <Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.ACCEPTATION))} variant="contained">Accepter</Button>}
				{showButtonAppliquer && <Button onClick={handleSubmit(submit(BNQ_ID_TYPE_ACTION.APPLICATION))} variant="contained">Appliquer</Button>}
			</Actions>
		</>
	)
}

QuestionChangerEtatForm.propTypes = {
	profil: PropTypes.number,
	donneesGenerales: PropTypes.object,
	etatGlobal: shapes.questionEtatGlobalShape,
	changerEtatQuestion: PropTypes.func,
	setVisaRDB: PropTypes.func,
	goToRecherche: PropTypes.func,
	handleSubmit: PropTypes.func,
	initialize: PropTypes.func,
	reset: PropTypes.func,
	error: PropTypes.array,
	classes: PropTypes.object
}

const mapStateToProps = (state) => {
	const etatGlobal = selectors.getEtatGlobal(state)
	return ({
		donneesGenerales: selectors.getDonneesGenerales(state),
		etatGlobal,
		initialValues: {
			commentaire: '',
			nomPrenomVisaRDB: etatGlobal.nomPrenomVisaRDB
		}
	})
}

const actions = {
	changerEtatQuestion,
	setVisaRDB,
	goToRecherche: () => dispatch => dispatch(push('/questions'))
}

export default compose(
	injectSession,
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'questionNouvelEtatForm'
	}),
	injectFormSnackbar()
)(QuestionChangerEtatForm)
