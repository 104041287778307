import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Actions, Button, Error, Input, InputFile, ResponsiveForm, StyledTypography, injectFormSnackbar } from '@oceane/ui'
import { push, replace } from 'connected-react-router'
import { FormCheckbox, getStyles, injectToolbarData, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import SuppressionAnnexePopup from './components/SuppressionAnnexePopup'
import { checkFileExist, EDIT_ANNEXE_FORM, postAnnexe, putAnnexe } from './services/annexeAction'
import { loadAnnexe } from './services/annexeInjector'

const styles = (theme) => getStyles({
	errorFiles: {
		fontSize: 10,
		color: theme.palette.warning,
		marginTop: -21
	},
	buttonCharger: {
		marginTop: 19
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		margin: '10px 0'
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.numero) {
		errors.numero = { id: 'global.erreurs.obligatoire' }
	}
	if (values.nomFichier && values.nomFichier.length) {
		const extension = getFileExtension(values.nomFichier[0].name)
		if (extension !== 'pdf') {
			errors.nomFichier = { id: 'global.erreurs.typeInvalide', values: { type: '.pdf' } }
		}
	}
	if (values.nomFichierImage && values.nomFichierImage.length) {
		const extension = getFileExtension(values.nomFichierImage[0].name)
		if (extension !== 'jpg' && extension !== 'png' && extension !== 'jpeg' && extension !== 'gif') {
			errors.nomFichierImage = { id: 'global.erreurs.typeInvalide', values: { type: '.gif, .jpeg, .jpg, .png' } }
		}
	}
	return errors
}

const getFileExtension = (filename) => {
	return filename.substr((filename.lastIndexOf('.') + 1))
}

const TYPE_ERREUR = {
	DOWNLOAD: 'download',
	FICHIER: 'fichier',
	FICHIER_IMAGE: 'fichierImage'
}

const EditionAnnexe = ({ classes, goToListeAnnexePage, handleSubmit, match, putAnnexe, checkFileExist, error, location: { state }, nomFichier, nomFichierImage, annexe, goToAnnexe, initialize }) => {
	const [open, setOpen] = React.useState(false)
	const [customError, setCustomError] = React.useState({})
	const [newNomfichier, setnewNomfichier] = React.useState('')
	const [newNomfichierImage, setnewNomfichierImage] = React.useState('')

	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	const idAnnexe = match.params.idAnnexe
	const idMatiere = match.params.idMatiere

	if (Object.keys(annexe).length !== 0 && newNomfichier === '') {
		setnewNomfichier(`${annexe.fichier}.${annexe.extension}`)
		setnewNomfichierImage(`${annexe.fichier}.${annexe.extensionImage}`)
	}

	const supprimerAnnexe = () => {
		if (idAnnexe) {
			handleOpenPopup()
		} else {
			retourListeAnnexes()
		}
	}

	const submitAnnexe = (values) => {
		// Validation des fichiers à la soumission
		setCustomError({})
		if ((typeof nomFichier == "undefined") || (typeof nomFichierImage == "undefined")) {
			throw new SubmissionError({ _error: 'Veuillez charger les deux fichiers de l\'annexe' })
		}

		if (idAnnexe) {
			const newValues = {
				...values,
				extension: nomFichier && nomFichier[0] ? getFileExtension(nomFichier[0].name) : values.extension,
				extensionImage: nomFichierImage && nomFichierImage[0] ? getFileExtension(nomFichierImage[0].name) : values.extensionImage
			}
			return putAnnexe(newValues, idMatiere)
				.then(({ annexe }) => {
					setnewNomfichier(`${annexe.fichier}.${annexe.extension}`)
					setnewNomfichierImage(`${annexe.fichier}.${annexe.extensionImage}`)
				})
		} else {
			const newValues = {
				...values,
				extension: getFileExtension(nomFichier[0].name),
				extensionImage: getFileExtension(nomFichierImage[0].name),
				id: state.annexe.id,
				idMatiere: state.annexe.idMatiere
			}
			return putAnnexe(newValues, idMatiere)
				.then(({ annexe }) => {
					initialize(annexe)
					goToAnnexe(idMatiere, annexe.id)
				})
		}
	}

	const retourListeAnnexes = () => {
		goToListeAnnexePage(idMatiere, state.code)
	}

	const setErrorFile = (key, message) => {
		setCustomError({
			...customError,
			[key]: message
		})
	}

	const clickDownload = (fichierNom) =>
		checkFileExist(idAnnexe, idMatiere, fichierNom)
			.then(() =>
				download(`/files/annexes/${fichierNom}/${idMatiere}`)
			)
			.catch(e => {
				setErrorFile(TYPE_ERREUR.DOWNLOAD, [e.bodyError.globalErrors[0].code])
			})

	const clickDownloadImage = (fichierNom) =>
		checkFileExist(idAnnexe, idMatiere, fichierNom)
			.then(() =>
				download(`/files/annexesImage/${fichierNom}/${idMatiere}`)
			)
			.catch(e => {
				setErrorFile(TYPE_ERREUR.DOWNLOAD, [e.bodyError.globalErrors[0].code])
			})

	return (
		<React.Fragment>
			<Title value="Modification de l'annexe" />
			{((error && !!error.length && [error]) || customError.download) && <Error error={(error && [error]) || customError.download} />}
			<ResponsiveForm>
				<Field
					id="numero"
					name="numero"
					label="Numéro *"
					component={Input}
					inputProps={{
						maxLength: 30
					}}
				/>
				<Grid container alignItems="flex-start">
					<Grid item>
						<InputFile
							name="nomFichier"
							id="nomFichier"
							label="Nom fichier *"
							accept="application/pdf, application/doc"
						/>
					</Grid>
				</Grid>
				{!!newNomfichier && <Typography className={classes.link} onClick={() => clickDownload(newNomfichier)}>{newNomfichier}</Typography>}
				<Grid container alignItems="flex-start">
					<Grid item>
						<InputFile
							name="nomFichierImage"
							id="nomFichierImage"
							label="Nom fichier image *"
							accept="image/jpg, image/png, image/gif, image/jpeg"
						/>
					</Grid>
				</Grid>
				{!!newNomfichierImage && <Typography className={classes.link} onClick={() => clickDownloadImage(newNomfichierImage)}>{newNomfichierImage}</Typography>}
				<Field
					id="aimprimer"
					name="aimprimer"
					label="A imprimer"
					defaultValue={false}
					component={FormCheckbox}
				/>
				<Actions>
					<Button
						color="primary"
						onClick={supprimerAnnexe}
					>
						Supprimer
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={handleSubmit(submitAnnexe)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
			<SuppressionAnnexePopup
				open={open}
				idAnnexe={idAnnexe}
				idMatiere={idMatiere}
				onClose={handleClosePopup}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = (state, { annexe }) => {
	const { numero, nomFichier, nomFichierImage } = formValueSelector(EDIT_ANNEXE_FORM)(state, 'numero', 'nomFichier', 'nomFichierImage')

	return ({
		numero,
		nomFichier,
		nomFichierImage,
		initialValues: {
			id: annexe && annexe.id,
			idMatiere: annexe && annexe.idMatiere,
			aimprimer: annexe && annexe.aimprimer,
			numero: numero || (annexe && annexe.numero)
		}
	})
}

const actions = {
	putAnnexe,
	postAnnexe,
	goToListeAnnexePage: (idMatiere, code) => dispatch => dispatch(push({
		pathname: `/annexes/${idMatiere}`,
		state: { code }
	})),
	goToAnnexe: (idMatiere, idAnnexe) => dispatch => dispatch(replace(`/annexes/${idMatiere}/${idAnnexe}`)),
	checkFileExist
}

EditionAnnexe.propTypes = {
	putAnnexe: PropTypes.func,
	postAnnexe: PropTypes.func,
	annexe: PropTypes.object,
	goToListeAnnexePage: PropTypes.func,
	checkFileExist: PropTypes.func,
	numero: PropTypes.string,
	nomFichier: PropTypes.object,
	nomFichierImage: PropTypes.object,
	initialValues: PropTypes.object,
	goToAnnexe: PropTypes.func
}

export default compose(
	loadAnnexe,
	connect(mapStateToProps, actions),
	reduxForm({
		form: EDIT_ANNEXE_FORM,
		validate
	}),
	injectFormSnackbar(),
	injectToolbarData(({ location: { state }, numero, annexe }) => ({
		title: 'Annexe',
		subheader: {
			separator: () => '>',
			filArianeEntries: [{
				text: `Matière ${state.code}`
			}, {
				text: `Annexe ${annexe.numero || numero || ''}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	withStyles(styles)
)(EditionAnnexe)
