import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Select, InputRadio } from '@oceane/ui'
import { getSessionsList, cleanSessionsList } from '../../../references/sessions/services/sessionsActions'
import { TRI_INSCRIPTIONS } from '../services/editionValues'

const EditionInscriptionPapier = ({ getSessionsList, cleanSessionsList }) => {
	const [sessionList, setSessionList] = React.useState([])

	React.useEffect(() => {
		if (!sessionList.length) {
			getSessionsList().then(({ list }) => setSessionList(list))
		}
		return () => {
			cleanSessionsList()
			setSessionList([])
		}
	}, [])

	return (<React.Fragment>
		<Field
			id="session"
			name="inscriptionPapier.sessionId"
			label="Session *"
			component={Select}
		>
			{sessionList.map(session =>
				<MenuItem key={session.id} value={session.id}>
					{session.libelle}
				</MenuItem>
			)}
		</Field>
		<Field
			name="inscriptionPapier.tri"
			label="Tri"
			component={InputRadio}
			choices={TRI_INSCRIPTIONS}
		/>
	</React.Fragment>)
}

const actions = {
	getSessionsList,
	cleanSessionsList
}

EditionInscriptionPapier.propTypes = {
	getSessionList: PropTypes.func
}

export default connect(null, actions)(EditionInscriptionPapier)
