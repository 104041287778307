import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import { DownloadIcon, Input, LargeInput, MODE_PASSAGE, MultilineTooltip, INS_ETAT_EPREUVE_INSCRIPTION } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import * as selectors from './services/gestionCopiesSelectors'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { push } from 'connected-react-router'


const styles = () => getStyles({
	downloadButton: {
		padding: 5,
		marginLeft: -5
	}
})

const ResultatRechercheInscription = ({ inscription, classes, goToEditionResultatEpreuve }) => {
	if (inscription) {
				return (
			<>
				<Title value="Détail de l'inscription" />
				<Grid container direction="row">
					<Grid item xs={6}>
						<LargeInput
							meta={{}}
							label="Candidat"
							value={`${inscription.candidatCivilite} ${inscription.candidatNom} ${inscription.candidatPrenom} (${inscription.candidatDateNaissance})`}
							disabled
						/>
					</Grid>
					<Grid item xs={6}>
						<Input
							meta={{}}
							label="Examen"
							value={inscription.libelleExamen}
							disabled
						/>
					</Grid>
				</Grid>
				<StaticDataTable
					noPagination
					data={inscription.listeEpreuves}
					noResultFragment={<></>}
					headers={[
						{
							key: 'libelleEpreuve',
							name: "Epreuve",
							sortable: false
						}, {
							key: 'date',
							name: "Date",
							sortable: false,
							render: row => row.date && moment(row.date).format('DD/MM/YYYY')
						}, {
							key: 'etat',
							name: "Etat",
							sortable: false,
							render: row => <FormattedMessage id={`enums.insEtatEpreuveInscription.${row.etat}`} />
						}, {
							key: 'note',
							name: "Note",
							sortable: false,
							render: row => row.terminee ? row.note : ''
						}, {
							key: 'barreReussite',
							name: "Barre de réussite",
							sortable: false,
							render: row => row.terminee ? row.barreReussite : ''
						}, {
							key: 'totalPoints',
							name: "Points",
							sortable: false,
							render: row => row.totalPoints
						}, {
							key: 'pourcentageReussite',
							name: '% de réussite',
							sortable: false,
							render: row => row.terminee ? row.pourcentageReussite : ''
						}, {
							key: 'copie',
							name: "Copie",
							sortable: false,
							render: row => {
								if (row.terminee) {
									if (row.copieDisponible) {
										return <IconButton
											disableRipple
											className={classes.downloadButton}
											onClick={() => download(`/files/epreuveInscription/${row.id}/copie`)}
										>
											<DownloadIcon />
										</IconButton>
									} else {
										return <MultilineTooltip
											message="Le document n'est plus disponible"
											placement="top-start"
										>
											<div>
												<IconButton
													className={classes.downloadButton}
													disableRipple
													disabled
												>
													<DownloadIcon />
												</IconButton>
											</div>
										</MultilineTooltip>
									}
								}
								return null
							}
						}, {
							key: 'grilles',
							name: "Grilles (les 4 séries)",
							sortable: false,
							render: row => {
								if (inscription.typePassage === MODE_PASSAGE.PAPIER) {
									if (row.grillesDisponibles) {
										return <IconButton
											className={classes.downloadButton}
											disableRipple
											onClick={() => download(`/files/epreuveInscription/${row.id}/grilles`)}
										>
											<DownloadIcon />
										</IconButton>
									} else {
										return <MultilineTooltip
											message="Le document n'est plus disponible"
											placement="top-start"
										>
											<div>
												<IconButton
													className={classes.downloadButton}
													disableRipple
													disabled
												>
													<DownloadIcon />
												</IconButton>
											</div>
										</MultilineTooltip>
									}
								}
								return null
							}
						}, {
							key: 'modifierEtat',
							name: "Modifier état",
							sortable: false,
							render: row => (INS_ETAT_EPREUVE_INSCRIPTION.REUSSIE === row.etat || INS_ETAT_EPREUVE_INSCRIPTION.FRAUDE === row.etat || INS_ETAT_EPREUVE_INSCRIPTION.ECHOUEE === row.etat) && <IconButton
								className={classes.downloadButton}
								disableRipple
								onClick={() => goToEditionResultatEpreuve(row.id)}
							>
								<Edit />
							</IconButton>
						}
					]}
				/>
			</>
		)
	}
	return <></>
}

ResultatRechercheInscription.propTypes = {
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	inscription: selectors.getInscription(state)
})
const actions = {
	goToEditionResultatEpreuve: (idEpreuveInscription) => dispatch => dispatch(push(`/gestion-copies/resultat-epreuve/${idEpreuveInscription}`))
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(ResultatRechercheInscription)
