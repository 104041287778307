import { fetchFactory } from 'isotope-client'

export const getSessions = () => fetchFactory('/epreuveSujet/sessions')

export const getExamensTypes = () => fetchFactory('/epreuveSujet/examensTypes')

export const getListeEpreuves = (session) => fetchFactory(`/epreuveSujet/${session}`)

export const valider = (idSession) => fetchFactory(`/epreuveSujet/valider`, {
	method: 'post',
	body: idSession
})

export const validerRapide = (idExamen) => fetchFactory('/epreuveSujet/validerRapide', {
	method: 'post',
	body: idExamen
})

export const getSujet = (id) => fetchFactory(`/sujet/${id}`)

export const genererSujet = (id) => fetchFactory(`/sujet/genererSujet/${id}`)

export const supprimerSujet = (id) => fetchFactory(`/sujet/supprimerSujet/${id}`, {
	method: 'delete'
})

export const checkAnnexeExist = (id, nomFichier) => fetchFactory(`/sujet/annexe/check/${id}`, {
	method: 'post',
	body: nomFichier
})

export const checkAnnexesExist = (idEpreuveSession) => fetchFactory('/sujet/annexes/check', {
	method: 'post',
	body: idEpreuveSession
})

export const checkSujetExist = (idEpreuveSession, type, idSujet, numeroSerie) => fetchFactory(`/sujet/check/${idSujet}/${type}/${numeroSerie}`, {
	method: 'post',
	body: idEpreuveSession
})

export const checkGrillesExist = (idEpreuveSession) => fetchFactory(`/sujet/grilles/check`, {
	method: 'post',
	body: idEpreuveSession
})

export const validerEtGenererSeries = (idEpreuveSession) => fetchFactory(`/sujet/validerGenererSeries/${idEpreuveSession}`, {
	method: 'post'
})