import { combineReducers } from 'redux'
import {
	CLOSE_POPUP,
	CLOSE_POPUP_CREATION,
	CREATE_PIECE_JUSTIFICATIVE_SUCCESS, DETAIL_PIECE_JUSTIFICATIVE_SUCCESS,
	OPEN_POPUP,
	OPEN_POPUP_CREATION
} from './piecesJustificativesAction'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const openCreation = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP_CREATION:
			return true
		case CLOSE_POPUP_CREATION:
			return false
		default:
			return state
	}
}

const detailPieceJustificative = (state = {}, action) => {
	switch (action.type) {
		case CREATE_PIECE_JUSTIFICATIVE_SUCCESS:
		case DETAIL_PIECE_JUSTIFICATIVE_SUCCESS:
			return action.payload
		default:
			return state
	}
}

export const piecesJustificatives = combineReducers({
	open,
	openCreation,
	detailPieceJustificative
})