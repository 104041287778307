import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = state => getReferences(state).examinateurEtranger

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)

export const getExaminateur = createSelector(
	getLocalState,
	state => state.examinateur
)

export const isLoading = createSelector(
	getLocalState,
	state => state.loading
)

const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)
