import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { goBack, replace } from 'connected-react-router'
import PropTypes from 'prop-types'
import {
	Actions,
	Button,
	checkAuthorities,
	EmptyResult,
	ETAT_EPREUVE_TYPE,
	EXAMEN_TYPE,
	getFilArianeEpreuveType,
	injectFormSnackbar,
	Input,
	InputRadio,
	Link,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	TYPE_EPREUVE_TYPE
} from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import { FormCheckbox, injectToolbarData, StaticDataTable, Title } from 'isotope-client'
import * as selectors from './services/epreuveTypeSelectors'
import { Field, reduxForm } from 'redux-form'
import * as examenTypeSelectors from '../examensType/services/examensTypesSelectors'
import { injectEpreuveType } from './services/epreuveTypeInjector'
import { injectExamenType } from '../examensType/services/examenTypeInjector'
import { injectIntl } from 'react-intl'
import { initialiserEpreuveType, modifierEpreuveType, supprimerEpreuveType } from './services/epreuveTypeActions'
import ConfirmInitialisationPopup from './popup/ConfirmInitialisationPopup'
import { ELS_SCRIPT_ETAT } from '../../../config/enums'

const validate = (values) => {
	const errors = {}
	if (!values.libelleLong) {
		errors.libelleLong = 'Ce champ est obligatoire'
	}
	if (!values.libelleLongAnglais) {
		errors.libelleLongAnglais = 'Ce champ est obligatoire'
	}
	if (values.montantInscription === null || values.montantInscription === '') {
		errors.montantInscription = 'Ce champ est obligatoire'
	} else if (isNaN(Number(values.montantInscription))) {
		errors.montantInscription = 'Ce champ doit être un montant'
	}
	if (!values.dureeEpreuve) {
		errors.dureeEpreuve = 'Ce champ est obligatoire'
	}
	if (!values.arrondi) {
		errors.arrondi = 'Ce champ est obligatoire'
	}
	return errors
}

const DetailEpreuveTypePage = ({
								   intl,
								   snackError,
								   examenType,
								   epreuveType,
								   handleSubmit,
								   snackSuccess,
								   idEpreuveType,
								   etatEpreuveType,
								   goToEpreuvesTypes,
								   libelleEpreuveType,
								   modifierEpreuveTypeAction,
								   initialiserEpreuveTypeAction,
								   supprimerEpreuveTypeAction
							   }) => {

	const [popupOpen, setPopupOpen] = React.useState(false)

	const submitModifier = (values) => modifierEpreuveTypeAction(idEpreuveType, values)

	const supprimer = () => {
		return supprimerEpreuveTypeAction(idEpreuveType)
			.then(() => {
				goToEpreuvesTypes(examenType.id)
				snackSuccess('L\'épreuve type a bien été supprimée')
			})
	}
	const submitInitialiser = (values) => {
		return initialiserEpreuveTypeAction(idEpreuveType, values)
			.then(() => {
				setPopupOpen(false)
			})
	}

	const actions = []
	if (etatEpreuveType === ETAT_EPREUVE_TYPE.CREEE) {
		actions.push(
			<Button
				color="primary"
				onClick={supprimer}
			>
				Supprimer
			</Button>,
			<Button
				color="primary"
				onClick={() => setPopupOpen(true)}
			>
				Initialiser
			</Button>
		)
	}
	actions.push(<Button
		color="primary"
		variant="contained"
		onClick={handleSubmit(submitModifier)}
	>
		Enregistrer
	</Button>)

	return <React.Fragment>
		<Title value="Données générales" />
		<ResponsiveForm>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Field
						name="libelleCourt"
						label="Libellé court"
						component={Input}
						disabled
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					{epreuveType.type === TYPE_EPREUVE_TYPE.CL_NON_OACI &&
					<Field
						name="oaci"
						component={Input}
						disabled
					/>}
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						name="libelleLong"
						label="Libellé long *"
						component={Input}
						inputProps={{
							maxLength: 75
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Field
						name="libelleLongAnglais"
						label="Libellé long anglais *"
						component={Input}
						inputProps={{
							maxLength: 75
						}}
					/>
				</Grid>
				{examenType.type !== EXAMEN_TYPE.PRATIQUE && <Grid item xs={12} sm={6}>
					<Field
						name="matieres"
						label="Matières"
						component={Input}
						disabled
					/></Grid>}
				<Grid item xs={12} sm={6}>

					<Field
						name="etat"
						label="Etat"
						component={Input}
						disabled
					/>
				</Grid>
				{examenType.type !== EXAMEN_TYPE.PRATIQUE &&
				<Grid item md={12}>
					<Field
						name="dureeEpreuve"
						label="Durée épreuve *"
						component={Input}
						type="time"
						disabled={etatEpreuveType !== ETAT_EPREUVE_TYPE.CREEE}
					/>
				</Grid>}
				<Grid item md={12}>
					<Field
						name="montantInscription"
						label="Montant Inscription *"
						component={Input}
						inputProps={{
							maxLength: 9
						}}
					/>
				</Grid>
				{examenType.type !== EXAMEN_TYPE.PRATIQUE && <React.Fragment>
					<Grid item xs={12} sm={6}>
						<Field
							name="calculetteAutorisee"
							label="Calculette autorisée"
							component={FormCheckbox}
							disabled={etatEpreuveType !== ETAT_EPREUVE_TYPE.CREEE}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							name="arrondi"
							component={InputRadio}
							choices={[
								{
									value: '1',
									label: 'Arrondi supérieur'
								},
								{
									value: '2',
									label: 'Arrondi inférieur'
								}
							]}
							disabled={etatEpreuveType !== ETAT_EPREUVE_TYPE.CREEE}
						/>
					</Grid>
				</React.Fragment>}
			</Grid>
		</ResponsiveForm>
		{examenType.type === EXAMEN_TYPE.STANDARD && <React.Fragment>
			<Title value="Scripts" />
			<StaticDataTable
				data={epreuveType.scripts}
				noResultFragment={<EmptyResult />}
				headers={[
					{
						key: 'script',
						name: 'Script',
						sortable: false,
						render: () => `Script ${libelleEpreuveType}`
					},
					{
						key: 'etat',
						name: 'Etat',
						sortable: false,
						render: row => intl.formatMessage({ id: `references.scripts.etat.${row.etat}` })
					},
					{
						key: 'dateExploitation',
						name: 'Date d\'exploitation',
						sortable: false,
						render: row => row.dateExploitation ? new Date(row.dateExploitation).toLocaleDateString() : null
					},
					{
						key: 'action',
						name: 'Action',
						sortable: false,
						render: row => {
							const typeScript = row.etat === ELS_SCRIPT_ETAT.EXPLOITE ? 'scriptsExploitation' : 'scripts'
							return <Link to={`/examens-types/${examenType.id}/epreuves-types/${idEpreuveType}/${typeScript}/${row.id}`}>
								Consulter
							</Link>
						}
					}
				]}
			/>
		</React.Fragment>}
		<Actions>{actions}</Actions>
		<ConfirmInitialisationPopup
			open={popupOpen}
			onSubmit={handleSubmit(submitInitialiser)}
			onClose={() => setPopupOpen(false)}
		/>
	</React.Fragment>
}


const mapStateToProps = (state, { intl }) => {
	const examenType = examenTypeSelectors.getExamenType(state)
	const epreuveType = selectors.getEpreuveType(state)
	const idEpreuveType = epreuveType && epreuveType.id
	const libelleEpreuveType = epreuveType ? epreuveType.libelle : ''
	const libelleLongEpreuveType = epreuveType ? epreuveType.libelleLong : ''
	const libelleLongAnglaisEpreuveType = epreuveType ? epreuveType.libelleLongAnglais : ''
	const etatEpreuveType = epreuveType.etat || 0
	const dureeEpreuveType = epreuveType ? new Date(epreuveType.dureeEpreuve).toLocaleTimeString() : ''
	const montantInscriptionEpreuveType = epreuveType ? epreuveType.montantInscription : ''
	return {
		examenType,
		epreuveType,
		idEpreuveType,
		libelleExamenType: examenType.libelleCourt || '',
		etatExamenType: examenType.etat || 0,
		libelleEpreuveType,
		etatEpreuveType,
		initialValues: {
			libelleCourt: libelleEpreuveType,
			libelleLong: libelleLongEpreuveType,
			libelleLongAnglais: libelleLongAnglaisEpreuveType,
			matieres: epreuveType.matieres,
			etat: intl.formatMessage({ id: `references.epreuvesTypes.etat.${etatEpreuveType}` }),
			oaci: 'Non comptabilisée OACI',
			dureeEpreuve: dureeEpreuveType,
			montantInscription: montantInscriptionEpreuveType,
			calculetteAutorisee: epreuveType ? epreuveType.calculetteAutorisee : false,
			arrondi: epreuveType ? `${epreuveType.arrondi}` : '1'
		}
	}
}

DetailEpreuveTypePage.propTypes = {
	examenType: PropTypes.object,
	epreuveType: PropTypes.object,
	idEpreuveType: PropTypes.string,
	libelleExamenType: PropTypes.string,
	etatExamenType: PropTypes.number,
	libelleEpreuveType: PropTypes.string,
	etatEpreuveType: PropTypes.number
}

const actions = {
	initialiserEpreuveTypeAction: initialiserEpreuveType,
	modifierEpreuveTypeAction: modifierEpreuveType,
	supprimerEpreuveTypeAction: supprimerEpreuveType,
	goToEpreuvesTypes: (idExamenType) => dispatch => {
		dispatch(goBack())
		dispatch(replace(`/examens-types/${idExamenType}/1`))
	}
}


export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectIntl,
	injectExamenType,
	injectEpreuveType,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modifierExamenType',
		validate
	}),
	injectToolbarData(({
						   libelleExamenType,
						   etatExamenType,
						   libelleEpreuveType,
						   etatEpreuveType
					   }) => ({
			...getFilArianeEpreuveType(
				libelleExamenType,
				etatExamenType,
				libelleEpreuveType,
				etatEpreuveType
			),
			title: 'Propriétés de l\'épreuve type'
		})
	),
	injectFormSnackbar()
)(DetailEpreuveTypePage)
