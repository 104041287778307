import { EmptyResult, injectSession, Link } from '@oceane/ui'
import { StaticDataTable, Title, injectToolbarData } from 'isotope-client'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getMatieresAImprimer } from './services/aImprimerAction'

const MatiereListeAImprimer = ({ profil, getMatieresAImprimer }) => {
	const [matieres, setMatieres] = React.useState([])

	useEffect(() => {
		getMatieresAImprimer(profil)
			.then(liste => setMatieres(liste.matieres))
	}, [profil])

	return (
		<React.Fragment>
			<Title value="Liste de matières" />
			<StaticDataTable
				headers={[
					{
						key: 'code',
						name: 'Code Matière'
					},
					{
						key: 'libelle',
						name: 'Libellé matière'
					},
					{
						key: 'link',
						name: 'Annexes à imprimer',
						render: row => <Link to={`/examens-ecran/annexes/${row.id}`} state={{ code: row.code, libelle: row.libelle }}>Annexes</Link>
					}
				]}
				defaultPageSize={10}
				data={matieres}
				noResultFragment={<EmptyResult />}
			/>
		</React.Fragment>
	)
}

const actions = {
	getMatieresAImprimer
}

export default compose(
	connect(null, actions),
	injectSession,
	injectToolbarData({ title: 'Gestion des livrets d\'annexes' })
)(MatiereListeAImprimer)
