import withStyles from '@material-ui/core/styles/withStyles'
import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR, Loader, ORG_SESSION_ID_ETAT, Link } from '@oceane/ui'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import RechercheSessionsPapierForm from './components/RechercheSessionsPapierForm'
import { rechercher } from '../services/sessionsPapierActions'
import { getListeExamensTypes } from '../../../references/examensType/services/examensTypesActions'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import NouvelleSessionPapierPopup from './components/NouvelleSessionPapierPopup'

const style = () => getStyles({
	input: {
		marginBottom: 10
	},
	inputSelect: {
		marginBottom: 10,
		paddingTop: 2
	},
	rechercher: {
		marginTop: 15
	},
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	container: {
		marginBottom: 75
	}
})

const RechercheSessionsPapier = ({ profil, submitting, handleSubmit, classes, getListeExamensTypes, rechercher }) => {
	const [resultats, setResultats] = React.useState([])
	const [openCreation, setOpenCreation] = React.useState(false)
	const [showResult, setShowResult] = React.useState(false)
	const [listeExamensType, setListeExamensType] = React.useState([])
	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		setLoading(true)
		getListeExamensTypes(PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE.id === profil)
			.then(({ result }) => {
				setLoading(false)
				setListeExamensType(result.filter(examen => examen.exploite || examen.exploitable))
			})
			.catch(() => setLoading(false))
	}, [profil])

	const recherche = (values) => {
		return rechercher(values)
			.then(({ response }) => {
				setResultats(response)
				setShowResult(true)
			})
	}

	if (loading) {
		return <Loader />
	}

	return (<div className={classes.container}>
		<Title value="Critères" />
		<RechercheSessionsPapierForm onSubmit={recherche} listeExamensType={listeExamensType}/>
		{showResult && <>
			<Title value="Résultat de recherche" />
			<StaticDataTable
				data={resultats}
				headers={[{
					key: 'libelleSession',
					name: 'Session',
					sortable: true,
					type: 'string',
					render: row => <Link to={`/sessions-papier/${row.id}/general`} >{row.libelleSession}</Link>
				}, {
					key: 'dateMin',
					name: 'Dates des épreuves',
					type: 'date',
					sortable: true,
					render: row => {
						if (row.dateMin != null && row.etat >= ORG_SESSION_ID_ETAT.PLANIFIEE) {
							return `Du ${moment(row.dateMin).format('DD/MM/YYYY')} au ${moment(row.dateMax).format('DD/MM/YYYY')}`
						}
						return ''
					}
				}, {
					key: 'etat',
					name: 'Etat',
					sortable: true,
					render: row => <FormattedMessage id={`references.sessions.etatPapier.${row.etat}`} />
				}]}
				noResultFragment={<EmptyResult />}
			/>
		</>
		}
		<FloatingButton onClick={() => setOpenCreation(true)} />
		<NouvelleSessionPapierPopup
			open={openCreation}
			onClose={() => setOpenCreation(false)}
			listeExamensType={listeExamensType}
		/>
	</div>)
}

const actions = {
	rechercher,
	getListeExamensTypes,
	goToSessionPapier: () => dispatch => {
	}
}

RechercheSessionsPapier.propTypes = {
	profil: PropTypes.number,
	rechercher: PropTypes.func,
	goToSessionPapier: PropTypes.func,
	getListeExamensTypes: PropTypes.func
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(null, actions),
	withStyles(style),
	injectSession
)(RechercheSessionsPapier)
