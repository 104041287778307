import withStyles from '@material-ui/core/styles/withStyles'
import { checkAuthorities, EmptyResult, injectSession, Link, ListFiltres, PROFIL_UTILISATEUR } from '@oceane/ui'
import { getStyles, SpringDataTable } from 'isotope-client'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import * as examinateurEtrangerActions from './services/examinateurEtrangerActions'
import NouveauExaminateurEtranger from './NouvelExaminateurEtranger'
import * as examinateurEtrangerSelectors from './services/examinateurEtrangerSelectors'

const style = () => getStyles({
	fab: {
		position: 'fixed',
		right: 10,
		bottom: 20
	}
})

class ExaminateurEtranger extends Component {

	render() {
		const {
			classes,
			openPopup,
			handleQuery,
			resetQuery,
			searchValue
		} = this.props

		return (
			<React.Fragment>
				<ListFiltres
					handleQueryValue={handleQuery}
					resetQueryValue={resetQuery}
					searchValue={searchValue}
					searchPlaceholderId="references.examinateurEtranger.search"
				/>
				<SpringDataTable
					apiUrl="/examinateursEtrangers"
					headers={[
						{
							key: 'nom',
							name: <FormattedMessage id="references.examinateurEtranger.nom" />,
							sortable: true,
							render: row =>
								<Link to={`/examinateurs-etrangers/${row.id}`}>
									{row.nom}
								</Link>
						},
						{
							key: 'prenom',
							name: <FormattedMessage id="references.examinateurEtranger.prenom" />,
							sortable: true
						},
						{
							key: 'contact',
							name: <FormattedMessage id="references.examinateurEtranger.contact" />,
							sortable: true
						},
						{
							key: 'autorisations',
							name: <FormattedMessage id="references.examinateurEtranger.autorisations" />,
							sortable: true
						},
					]}
					nom="examinateursEtrangers"
					noResultFragment={<EmptyResult />}
					filters={{
						nom: searchValue
					}}
					defaultPageSize={50}
				/>
				<Fab color="primary" aria-label="Add" className={classes.fab} onClick={openPopup}>
					<AddIcon />
				</Fab>
				<NouveauExaminateurEtranger />
			</React.Fragment>
		)
	}
}

ExaminateurEtranger.propTypes = {
	openPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	searchValue: examinateurEtrangerSelectors.getSearchValue(state)

})

const actions = {
	openPopup: examinateurEtrangerActions.openPopup,
	handleQuery: examinateurEtrangerActions.handleQuery,
	resetQuery: examinateurEtrangerActions.resetQuery
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	withStyles(style),
	injectSession
)(ExaminateurEtranger)
