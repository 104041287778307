import { fetchFactory } from 'isotope-client'

export const getEpreuveType = (idExamenType, idEpreuveType) => fetchFactory(
	`/examensTypes/${idExamenType}/epreuvesTypes/${idEpreuveType}`
)

export const modifierEpreuveType = (idEpreuveType, values) => fetchFactory(`/epreuvesTypes/${idEpreuveType}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const supprimerEpreuveType = (idEpreuveType) => fetchFactory(`/epreuvesTypes/${idEpreuveType}`, {
	method: 'delete'
})

export const initialiserEpreuveType = (idEpreuveType, values) => fetchFactory(`/epreuvesTypes/${idEpreuveType}/initialisation`, {
	method: 'put',
	body: JSON.stringify(values)
})