import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStyles, StaticDataTable, getToolbarData, setToolbarData, resetToolbarData } from 'isotope-client'
import { checkAuthorities, injectSession, PROFIL_UTILISATEUR, StyledTypography, colors, INS_ETAT_EPREUVE_INSCRIPTION } from '@oceane/ui'
import { FormattedMessage } from 'react-intl'
import * as selectors from '../../services/sessionsPapierSelectors'
import { cleanSessionPapier, mettreCandidatAbsent, mettreCandidatPresent } from '../../services/sessionsPapierActions'
import {
	injectCandidatSession,
	injectDonneesGenerales,
	injectSiteSession
} from '../../services/sessionsPapierInjectors'
import Typography from '@material-ui/core/Typography'
import { ETAT_PRESENCE, ETAT_PRESENCE_LABEL, INS_ETAT_INSCRIPTION_EPREUVE } from '../../../../../config/enums'

const styles = theme => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const filArianeCandidatss = (sessionPapier, site, epreuve) => (sessionPapier && {
	title: 'Détail session',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography>Session : {sessionPapier.libelleSession}</StyledTypography>
				{sessionPapier.etat && <StyledTypography primary bold><FormattedMessage
					id={`references.sessions.etatPapier.${sessionPapier.etat}`} /></StyledTypography>}
			</>
		}, {
			render: () => <>
				<StyledTypography>Site : {site.libelle}</StyledTypography>
			</>
		}, {
			render: () => <>
				<StyledTypography bold>Epreuve : {epreuve.codeEpreuve}</StyledTypography>
			</>
		}]
	}
})

const SaisiePvSallesListeCandidats = ({ sessionPapier, toolbarData, changeToolbarData, cleanToolbarData, site, epreuve, mettreCandidatAbsent, mettreCandidatPresent }) => {
	const [listeCandidats, setListeCandidats] = React.useState([])

	React.useEffect(() => {
		changeToolbarData(toolbarData.id, filArianeCandidatss(sessionPapier, site, epreuve))
		setListeCandidats(epreuve.listeCandidats)
		return () => {
			cleanToolbarData(toolbarData.id)
		}
	}, [sessionPapier.etat, site, epreuve])

	const mettreAbsent = (idCandidat) => {
		return mettreCandidatAbsent(sessionPapier.id, site.idSite, epreuve.idEpreuve, idCandidat)
			.then(({ result }) => {
				setListeCandidats(result.listeCandidats)
			})
	}

	const mettrePresent = (idCandidat) => {
		return mettreCandidatPresent(sessionPapier.id, site.idSite, epreuve.idEpreuve, idCandidat)
			.then(({ result }) => {
				setListeCandidats(result.listeCandidats)
			})
	}

	return (
		<>
			<StaticDataTable
				data={listeCandidats}
				headers={[{
					key: 'numeroInscription',
					name: 'Numéro inscription',
					sortable: false
				}, {
					key: 'nom',
					name: 'Nom',
					sortable: false
				}, {
					key: 'prenom',
					name: 'Prénom',
					sortable: false
				}, {
					key: 'etatEpreuve',
					name: 'Etat épreuve',
					sortable: false,
					render: row => <Typography>{INS_ETAT_INSCRIPTION_EPREUVE[row.etatEpreuve]}</Typography>
				}, {
					key: 'etatPresence',
					name: 'Etat présence',
					sortable: false,
					render: row => <Typography>{ETAT_PRESENCE_LABEL[row.etatPresence]}</Typography>
				}]}
				makeActions={row => {
					if (row.etatPresence === ETAT_PRESENCE.PRESENT) {
						return [
							{ label: 'Absence', action: () => mettreAbsent(row.id) }
						]
					} else {
						return [
							{ label: 'Présence', action: () => mettrePresent(row.id) }
						]
					}
				}}
				showActions
				applyRowStyle={row => {
					let backgroundColor = 'unset'
					switch (row.etatEpreuve) {
						case INS_ETAT_EPREUVE_INSCRIPTION.INITIALISEE:
						case INS_ETAT_EPREUVE_INSCRIPTION.REFUSEE:
							backgroundColor = colors.rejected
							break
						default:
							break
					}
					return {
						backgroundColor
					}
				}}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state),
	toolbarData: getToolbarData(state)
})

const actions = {
	cleanSessionPapier,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	mettreCandidatAbsent,
	mettreCandidatPresent
}

SaisiePvSallesListeCandidats.propTypes = {
	classes: PropTypes.object,
	sessionPapier: PropTypes.object,
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	cleanToolbarData: PropTypes.func,
	site: PropTypes.object,
	epreuve: PropTypes.object,
	mettreCandidatAbsent: PropTypes.func,
	mettreCandidatPresent: PropTypes.func
}

export default compose(
	injectSiteSession,
	injectCandidatSession,
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	withStyles(styles),
	injectDonneesGenerales,
	connect(mapStateToProps, actions),
	injectSession
)(SaisiePvSallesListeCandidats)
