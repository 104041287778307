import {
	Button,
	checkAuthorities,
	colors,
	EmptyResult,
	Filtres,
	injectSession,
	PROFIL_UTILISATEUR,
	Select,
	StyledTypography,
	injectFormSnackbar
} from '@oceane/ui'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import Chip from '@material-ui/core/Chip'
import React from 'react'
import { compose } from 'redux'
import { FormCheckbox, getStyles, getToolbarData, resetToolbarData, setToolbarData, Title } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import * as selectors from '../../services/sessionsPapierSelectors'
import Typography from '@material-ui/core/Typography'
import {
	affecterSurveillant,
	cleanSessionPapier,
	deleteSurveillant,
	getListeEpreuvesSurveillance,
	getSurveillance,
	getSurveillantsEpreuve
} from '../../services/sessionsPapierActions'
import Grid from '@material-ui/core/Grid'
import { injectDonneesGenerales, injectSiteSurveillanceSession } from '../../services/sessionsPapierInjectors'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import AffichageSurveillantsPopup from './AffichageSurveillantsPopup'
import classNames from 'classnames'

const style = (theme) => getStyles({
	header: { // On est obligé de simuler les header d'une table
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 13,
		paddingBottom: 10,
		paddingTop: 10
	},
	chip: {
		marginTop: -7,
		marginLeft: 20,
		color: '#FFF'
	},
	ko: {
		backgroundColor: colors.snackError
	},
	ok: {
		backgroundColor: colors.snackSuccess
	},
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	},
	placeholder: {
		color: 'rgba(0, 0, 0, 0.4)',
		lineHeight: 'unset'
	},
	filtreInput: {
		margin: 0
	},
	filtreEmpty: {
		margin: 13.5
	},
	errorText: {
		marginTop: 3,
		marginBottom: -13
	},
	row: {
		minHeight: 60,
		borderBottom: '1px solid rgba(224, 224, 224, 1)' // Diviseur entre les lignes du "tableau"
	},
	headerBox: {
		borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	checkbox: {
		marginTop: 5,
		marginBottom: -5,
		paddingLeft: 14
	}
})

const filArianeEpreuves = (sessionPapier, site) => (sessionPapier && {
	title: 'Détail session',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			render: () => <>
				<StyledTypography>Session : {sessionPapier.libelleSession}</StyledTypography>
				{sessionPapier.etat && <StyledTypography primary bold><FormattedMessage
					id={`references.sessions.etatPapier.${sessionPapier.etat}`} /></StyledTypography>}
			</>
		}, {
			render: () => <>
				<StyledTypography bold>Site : {site.libelle}</StyledTypography>
			</>
		}]
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.surveillant || values.surveillant === 0) {
		errors.surveillant = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const OK = 1

const DetailSurveillanceSession = ({
									   submitting,
									   sessionPapier,
									   change,
									   toolbarData,
									   changeToolbarData,
									   cleanToolbarData,
									   site,
									   getListeEpreuvesSurveillance,
									   classes,
									   liste,
									   handleSubmit,
									   affecterSurveillant,
									   getSurveillantsEpreuve,
									   deleteSurveillant,
									   surveillant,
									   untouch
								   }) => {
	const [surveillance, setSurveillance] = React.useState({})
	const [oneCheck, setOneCheck] = React.useState(0)
	const [open, setOpen] = React.useState(false)
	const [nEpreuve, setNEpreuve] = React.useState('')
	const [surveillantsEpreuve, setSurveillantsEpreuve] = React.useState([])
	const [idEpreuveClick, setIdEpreuveClick] = React.useState(null)
	const [disabledAffecter, setDisabledAffecter] = React.useState(true)

	React.useEffect(() => {
		changeToolbarData(toolbarData.id, filArianeEpreuves(sessionPapier, site))
		if (sessionPapier.id !== undefined && site.idSite !== undefined) {
			getListeEpreuvesSurveillance(sessionPapier.id, site.idSite)
				.then(({ result }) => {
					setSurveillance(result)
					change('liste', result.listeEpreuves.map(epreuve => ({
						...epreuve,
						check: false
					})))
				})
		}

		return () => cleanToolbarData(toolbarData.id)
	}, [sessionPapier.etat])

	React.useEffect(() => {
		if (surveillant !== 0) {
			setDisabledAffecter(false)
		} else {
			setDisabledAffecter(true)
		}
	}, [surveillant])

	/**
	 * Gestion de la case "sélectionner tout"
	 */
	const handleCheckSelectionnerTout = (event) => {
		change('liste', surveillance.listeEpreuves.map(epreuve => ({
			...epreuve,
			check: event.target.checked
		})))
		if (event.target.checked) {
			setOneCheck(liste.length)
		} else {
			setOneCheck(0)
		}
	}

	/**
	 * Gestion de l'incidence du cochage d'une épreuve sur la case "sélectionner tout"
	 */
	const handleCheckFormationSuivie = (event) => {
		if (!event.target.checked) {
			change('selectionnerTout', false)
			setOneCheck(oneCheck - 1)
		} else {
			setOneCheck(oneCheck + 1)
		}
	}

	const submitAffectation = (values) => {
		return affecterSurveillant(values, sessionPapier.id, site.idSite)
			.then(({ result }) => {
				setSurveillance(result)
				change('liste', result.listeEpreuves.map(epreuve => ({
					...epreuve,
					check: false
				})))
				change('selectionnerTout', false)
				change('surveillant', 0)
				change('isResponsable', false)
				untouch('surveillant')
				setOneCheck(0)
			})
	}

	const openPopup = (idEpreuve, numeroEpreuve) => {
		return getSurveillantsEpreuve(sessionPapier.id, site.idSite, idEpreuve)
			.then(({ result }) => {
				setSurveillantsEpreuve(result)
				setIdEpreuveClick(idEpreuve)
				setNEpreuve(numeroEpreuve)
				setOpen(true)
			})
	}

	const deleteSurveillantSite = (idSurveillant) => {
		deleteSurveillant(sessionPapier.id, site.idSite, idEpreuveClick, idSurveillant)
			.then(({ result }) => {
				setSurveillantsEpreuve(surveillantsEpreuve.filter(e => e.id !== idSurveillant))
				setSurveillance(result)
			})
	}

	if (!surveillance.listeEpreuves || surveillance.listeEpreuves.length === 0) {
		return <EmptyResult />
	}

	return <>
		<Title value="Épreuves du site" />
		{oneCheck === 0 && <Filtres>
			<Typography className={classes.filtreEmpty}>
				Sélectionnez des épreuves à mettre à jour
			</Typography>
		</Filtres>}
		{oneCheck > 0 && <Filtres>
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
			>
				<Field
					id="surveillant"
					name="surveillant"
					component={Select}
					displayEmpty
					placeholder={<MenuItem value={0}>
						<Typography className={classes.placeholder}>Surveillant</Typography>
					</MenuItem>}
					inputStyle={classes.filtreInput}
					classes={{
						errorText: classes.errorText
					}}
				>
					{surveillance.listeSurveillant.map(surveillant => <MenuItem key={surveillant.id}
																				value={surveillant.id}>{surveillant.nom} {surveillant.prenom}</MenuItem>)}
				</Field>
				<Field
					id="isResponsable"
					name="isResponsable"
					label="Responsable"
					defaultValue={false}
					component={FormCheckbox}
					containerStyle={classes.filtreInput}
				/>
				<Button
					key="save"
					color="primary"
					loading={submitting}
					onClick={handleSubmit(submitAffectation)}
					disabled={disabledAffecter}
				>
					Affecter
				</Button>
			</Grid>
		</Filtres>}
		<Grid container direction="row" className={classes.headerBox}>
			<Grid item md={1} className={classes.checkbox}>
				<Field
					name="selectionnerTout"
					component={FormCheckbox}
					onChange={handleCheckSelectionnerTout}
				/></Grid>
			<Grid item md={1}><Typography className={classes.header}>Code épreuve</Typography></Grid>
			<Grid item md={3}><Typography className={classes.header}>Libellé épreuve</Typography></Grid>
			<Grid item md={2}><Typography className={classes.header}>Date</Typography></Grid>
			<Grid item md={1}><Typography className={classes.header}>Heure début</Typography></Grid>
			<Grid item md={1}><Typography className={classes.header}>Durée</Typography></Grid>
			<Grid item md={1}><Typography className={classes.header}>Heure fin</Typography></Grid>
			<Grid item md={2}><Typography className={classes.header}>Etat de la surveillance</Typography></Grid>
		</Grid>
		{surveillance.listeEpreuves.map((epreuve, index) => {
			return <Grid key={index} container direction="row" className={classes.row}>
				<Grid item xs={1} className={classes.checkbox}>
					<Field
						name={`liste[${index}].check`}
						component={FormCheckbox}
						onChange={handleCheckFormationSuivie}
					/>
				</Grid>
				<Grid item md={1}><Typography
					className={classes.libelleColumn}
					onClick={() => openPopup(epreuve.id, epreuve.codeEpreuve)}
				>{epreuve.codeEpreuve}</Typography></Grid>
				<Grid item md={3}><Typography>{epreuve.libelle}</Typography></Grid>
				<Grid item md={2}><Typography>{moment(epreuve.date).format('DD/MM/YYYY')}</Typography></Grid>
				<Grid item md={1}><Typography>{epreuve.heureDebut}</Typography></Grid>
				<Grid item md={1}><Typography>{epreuve.duree}</Typography></Grid>
				<Grid item md={1}><Typography>{epreuve.heureFin}</Typography></Grid>
				<Grid item md={2}>
					<Chip label={epreuve.etatSurveillance === OK ? 'Ok' : 'Ko'}
						  className={classNames(classes.chip, epreuve.etatSurveillance === OK ? classes.ok : classes.ko)} />
				</Grid>
			</Grid>
		})}
		<AffichageSurveillantsPopup open={open} closePopup={() => setOpen(false)}
									deleteSurveillant={(id) => deleteSurveillantSite(id)}
									surveillants={surveillantsEpreuve} nEpreuve={nEpreuve} />
	</>
}

const actions = {
	cleanSessionPapier,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	getSurveillance,
	getListeEpreuvesSurveillance,
	affecterSurveillant,
	getSurveillantsEpreuve,
	deleteSurveillant
}

DetailSurveillanceSession.propTypes = {
	sessionPapier: PropTypes.object,
	getSurveillance: PropTypes.func,
	toolbarData: PropTypes.object,
	changeToolbarData: PropTypes.func,
	cleanToolbarData: PropTypes.func,
	site: PropTypes.object,
	getListeEpreuvesSurveillance: PropTypes.func,
	liste: PropTypes.array,
	affecterSurveillant: PropTypes.func,
	getSurveillantsEpreuve: PropTypes.func,
	deleteSurveillant: PropTypes.func
}

const mapStateToProps = (state) => {
	const liste = formValueSelector('detailsSurveillanceForm')(state, 'liste')
	return {
		sessionPapier: selectors.getDonneesGenerales(state),
		toolbarData: getToolbarData(state),
		liste,
		surveillant: formValueSelector('detailsSurveillanceForm')(state, 'surveillant')
	}

}

export default compose(
	injectSiteSurveillanceSession,
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	reduxForm({
		form: 'detailsSurveillanceForm',
		initialValues: {
			liste: [],
			surveillant: 0,
			isResponsable: false
		},
		validate
	}),
	injectDonneesGenerales,
	withStyles(style),
	injectFormSnackbar()
)(DetailSurveillanceSession)
