import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popover from '@material-ui/core/Popover'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Edit from '@material-ui/icons/Edit'
import AddCircle from '@material-ui/icons/AddCircle'
import { effacerNoeud, getQuestionsDisponibles, getScript } from '../services/scriptActions'
import * as selectors from '../services/scriptSelectors'
import * as epreuveTypeSelectors from '../../epreuveType/services/epreuveTypeSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const styles = () => getStyles({
	button: {
		color: '#000000',
		padding: 5
	}
})

const ParametrageButton = ({ noeud, parametre, isExploitation, idEpreuveType, openPopup, classes, getQuestionsDisponibles, effacerNoeud, getScript, snackError }) => {

	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClose = () => setAnchorEl(null)

	const handleEdit = () => getQuestionsDisponibles(noeud, isExploitation)
		.then(() => {
			openPopup()
			handleClose()
		})
		.catch(() => {
			snackError()
			handleClose()
		})

	const handleClick = event => {
		if (parametre) {
			setAnchorEl(event.currentTarget)
		} else {
			handleEdit()
		}
	}


	const handleEffacerParametrage = () => effacerNoeud(noeud, idEpreuveType, isExploitation)
		.then(() => {
			getScript(idEpreuveType, noeud.idScript, isExploitation)
			handleClose()
		})
		.catch(() => {
			snackError()
			handleClose()
		})


	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				className={classes.button}
			>
				{parametre ? <Edit /> : <AddCircle />}
			</IconButton>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<ClickAwayListener onClickAway={handleClose}>
					<MenuList>
						<MenuItem onClick={handleEdit}>Modifier paramétrage</MenuItem>
						<MenuItem onClick={handleEffacerParametrage}>Supprimer paramétrage</MenuItem>
					</MenuList>
				</ClickAwayListener>
			</Popover>
		</React.Fragment>
	)
}


ParametrageButton.propTypes = {
	classes: PropTypes.object,
	sExploitation: PropTypes.number,
	idEpreuveType: PropTypes.number,
	getQuestionsDisponibles: PropTypes.func,
	effacerNoeud: PropTypes.func,
	getScript: PropTypes.func
}

const mapStateToProps = (state) => ({
	isExploitation: selectors.isExploitation(state),
	idEpreuveType: epreuveTypeSelectors.getIdEpreuveType(state)
})

const actions = {
	getQuestionsDisponibles,
	effacerNoeud,
	getScript
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	injectSnackActions
)(ParametrageButton)
