import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Loader } from '@oceane/ui'
import { getScript, clearScript } from './scriptActions'
import * as selectors from './scriptSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as shapes from '../../../../config/shapes'

export const injectScript = (isExploitation) => (WrappedComponent) => {

	const ScriptInjector = props => {
		const { getScript, clearScript, script, ...otherProps } = props

		const match = props.match

		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (match && match.params && match.params.idScript !== script.id) {
				setLoading(true)
				getScript(match.params.idEpreuveType, match.params.idScript, isExploitation)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false)
						otherProps.snackError()
					})
			}
			return () => {
				clearScript()
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent script={script} {...otherProps} />
	}

	ScriptInjector.propTypes = {
		script: shapes.scriptParametrageShape,
		getScript: PropTypes.func,
		clearScript: PropTypes.func
	}

	const mapStateToProps = state => ({
		script: selectors.getParametrage(state)
	})

	const actions = {
		getScript,
		clearScript
	}

	return compose(
		connect(mapStateToProps, actions),
		injectSnackActions
	)(ScriptInjector)
}