import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { dataTableActions, injectToolbarData, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ModificationMatierePopup from './ModificationMatierePopup'
import NouvelleMatierePopup from './NouvelleMatierePopup'
import { getDetailsMatiere, suppressionMatiere } from '../service/ligneProgrammeAction'
import SupprimerMatierePopup from './SupprimerMatierePopup'

const ListeMatierePage = ({ getDetailsMatiere, suppressionMatiere, refreshTable, goToTopicsPage }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [openModification, setOpenModification] = React.useState(false)
	const [openSuppression, setOpenSuppression] = React.useState(false)
	const [id, setId] = React.useState()

	const clickModifier = (idMatiere) => {
		getDetailsMatiere(idMatiere)
			.then(() => setOpenModification(true))
	}

	const clickTopics = (idMatiere) => {
		goToTopicsPage(idMatiere)
	}

	const clickSupprimer = (idMatiere) => {
		setId(idMatiere)
		setOpenSuppression(true)
	}

	const deleteAction = () => {
		return suppressionMatiere(id)
			.then(() => {
				refreshTable()
				setOpenSuppression(false)
			})
	}

	return (
		<React.Fragment>
			<SpringDataTable
				apiUrl="/matiere/creees"
				headers={[
					{
						key: 'code',
						name: 'Code',
						sortable: true
					},
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: true
					}
				]}
				nom="listeMatiere"
				noResultFragment={<EmptyResult />}
				makeActions={row => [
					{ label: 'Modifier', action: () => clickModifier(row.id) },
					{ label: 'Gérer les topics', action: () => clickTopics(row.id) },
					{ label: 'Supprimer', action: () => clickSupprimer(row.id) }
				]}
				showActions
			/>
			<FloatingButton onClick={() => setOpenCreation(true)} />
			<NouvelleMatierePopup open={openCreation} onClose={() => setOpenCreation(false)} />
			<ModificationMatierePopup open={openModification} onClose={() => setOpenModification(false)}/>
			<SupprimerMatierePopup
				open={openSuppression}
				onClose={() => setOpenSuppression(false)}
				onSubmit={deleteAction}
			/>
		</React.Fragment>
	)

}

const actions = {
	suppressionMatiere,
	refreshTable: () => dataTableActions.refresh('listeMatiere'),
	goToTopicsPage: (idMatiere) => dispatch => dispatch(push(`/ligne-programme/${idMatiere}/liste-topics`)),
	getDetailsMatiere
}

ListeMatierePage.propTypes = {
	suppressionMatiere: PropTypes.func,
	refreshTable: PropTypes.func,
	goToTopicsPage: PropTypes.func,
	getDetailsMatiere: PropTypes.func
}

export default compose(
	injectToolbarData({ title: 'Liste des matières' }),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
	),
	connect(null, actions),
	injectSession
)(ListeMatierePage)
