import * as api from './statistiquesBanqueApi'

export const GET_VERSIONS_STATS_BANQUE_REQUEST = 'GET_VERSIONS_STATS_BANQUE_REQUEST'
export const GET_VERSIONS_STATS_BANQUE_SUCCESS = 'GET_VERSIONS_STATS_BANQUE_SUCCESS'
export const GET_VERSIONS_STATS_BANQUE_ERROR = 'GET_VERSIONS_STATS_BANQUE_ERROR'
export const getVersionsBanque = () => dispatch => {
    dispatch({ type: GET_VERSIONS_STATS_BANQUE_REQUEST })

    return api.getVersionsBanque()
        .then((response) => dispatch({ type: GET_VERSIONS_STATS_BANQUE_SUCCESS, response }))
        .catch(e => {
            dispatch({ type: GET_VERSIONS_STATS_BANQUE_ERROR })
            throw e
        })
}
