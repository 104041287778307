import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDetailsTopic } from './ligneProgrammeAction'
import * as selector from './ligneProgrammeSelectors'

export const injectDetailTopicWithIdTopic = WrappedComponent => {

	class injectDetailTopicWithIdTopic extends React.Component {

		componentDidMount() {
			const { getDetailsTopic, match } = this.props

			const idMatiere = match.params.idMatiere
			const idTopic = match.params.idTopic

			getDetailsTopic(idTopic, idMatiere)
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectDetailTopicWithIdTopic.propTypes = {
		detailsTopic: PropTypes.object
	}


	const mapStateToProps = state => ({
		detailsTopic: selector.getDetailTopic(state)
	})

	const actions = {
		getDetailsTopic
	}

	return connect(mapStateToProps, actions)(injectDetailTopicWithIdTopic)
}
