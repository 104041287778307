import * as api from './annexeApi'

export const EDIT_ANNEXE_FORM = 'editAnnexeForm'

export const POST_ANNEXE_REQUEST = 'POST_ANNEXE_REQUEST'
export const POST_ANNEXE_SUCCESS = 'POST_ANNEXE_SUCCESS'
export const POST_ANNEXE_ERROR = 'POST_ANNEXE_ERROR'

export const postAnnexe = (annexe, idMatiere) => dispatch => {
	dispatch({ type: POST_ANNEXE_REQUEST })

	return api.postAnnexe(annexe, idMatiere)
		.then((newAnnexe) => {
			dispatch({ type: POST_ANNEXE_SUCCESS })
			return newAnnexe
		})
		.catch(e => {
			dispatch({ type: POST_ANNEXE_ERROR })
			throw e
		})
}

export const PUT_ANNEXE_REQUEST = 'PUT_ANNEXE_REQUEST'
export const PUT_ANNEXE_SUCCESS = 'PUT_ANNEXE_SUCCESS'
export const PUT_ANNEXE_ERROR = 'PUT_ANNEXE_ERROR'

export const putAnnexe = (annexe, idMatiere) => dispatch => {
	dispatch({ type: PUT_ANNEXE_REQUEST })

	return api.putAnnexe(annexe, idMatiere)
		.then((annexe) => dispatch({ type: PUT_ANNEXE_SUCCESS, annexe }))
		.catch(e => {
			dispatch({ type: PUT_ANNEXE_ERROR })
			throw e
		})
}

export const DELETE_ANNEXE_REQUEST = 'DELETE_ANNEXE_REQUEST'
export const DELETE_ANNEXE_SUCCESS = 'DELETE_ANNEXE_SUCCESS'
export const DELETE_ANNEXE_ERROR = 'DELETE_ANNEXE_ERROR'

export const deleteAnnexe = (annexe, idMatiere) => dispatch => {
	dispatch({ type: DELETE_ANNEXE_REQUEST })

	return api.deleteAnnexe(annexe, idMatiere)
		.then(() => dispatch({ type: DELETE_ANNEXE_SUCCESS }))
		.catch(e => {
			dispatch({ type: DELETE_ANNEXE_ERROR })
			throw e
		})
}

export const GET_ANNEXE_REQUEST = 'GET_ANNEXE_REQUEST'
export const GET_ANNEXE_SUCCESS = 'GET_ANNEXE_SUCCESS'
export const GET_ANNEXE_ERROR = 'GET_ANNEXE_ERROR'

export const getAnnexe = (idAnnexe, idMatiere) => dispatch => {
	dispatch({ type: GET_ANNEXE_REQUEST })

	return api.getAnnexe(idAnnexe, idMatiere)
		.then((annexe) => dispatch({ type: GET_ANNEXE_SUCCESS, annexe }))
		.catch(() => dispatch({ type: GET_ANNEXE_ERROR }))
}

export const CHECK_DOWNLOAD_REQUEST = 'CHECK_DOWNLOAD_REQUEST'
export const CHECK_DOWNLOAD_SUCCESS = 'CHECK_DOWNLOAD_SUCCESS'
export const CHECK_DOWNLOAD_ERROR = 'CHECK_DOWNLOAD_ERROR'

export const checkFileExist = (idAnnexe, idMatiere, nomFichier) => dispatch => {
	dispatch({ type: CHECK_DOWNLOAD_REQUEST })

	return api.checkFileExist(idAnnexe, idMatiere, nomFichier)
		.then(() => dispatch({ type: CHECK_DOWNLOAD_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHECK_DOWNLOAD_ERROR })
			throw e
		})
}

export const CLEAR_ANNEXE = 'CLEAR_ANNEXE'
export const clearAnnexe = () => dispatch => dispatch({ type: CLEAR_ANNEXE })


export const HANDLE_QUERY_LISTE_ANNEXES = 'HANDLE_QUERY_LISTE_ANNEXES'
export const RESET_QUERY_LISTE_ANNEXES = "RESET_QUERY_LISTE_ANNEXES"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_LISTE_ANNEXES,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_LISTE_ANNEXES
	})
}