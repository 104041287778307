import { combineReducers } from 'redux'
import * as actions from './epreuveTypeActions'

const details = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_EPREUVE_TYPE_SUCCESS:
			return action.epreuve
		case actions.INITIALISER_EPREUVE_TYPE_SUCCESS:
			return action.epreuve
		case actions.CLEAR_EPREUVE_TYPE:
			return {}
		default:
			return state
	}
}

export const epreuvesTypes = combineReducers({
	details
})