import { combineReducers } from 'redux'
import * as actions from './gestionCopiesActions'
import {
	CLEAN_RESULTAT_EPREUVE,
	GET_RESULTAT_EPREUVE_SUCCESS,
	MODIFIER_FRAUDE_SUCCESS,
	MODIFIER_MENTION_SUCCESS
} from './gestionCopiesActions'

const inscription = (state = null, action) => {
	switch (action.type) {
		case actions.RECHERCHER_INSCRIPTION_SUCCESS:
			return action.inscription
		case actions.RECHERCHER_INSCRIPTION_ERROR:
		case actions.CLEAN_INSCRIPTION:
			return null
		default:
			return state
	}
}

const resultatEpreuve = (state = {}, action) => {
	switch (action.type) {
		case GET_RESULTAT_EPREUVE_SUCCESS:
		case MODIFIER_MENTION_SUCCESS:
		case MODIFIER_FRAUDE_SUCCESS:
			return action.resultat
		case CLEAN_RESULTAT_EPREUVE:
			return {}
		default:
			return state
	}
}

export const gestionCopies = combineReducers({
	inscription,
	resultatEpreuve
})