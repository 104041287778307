import { Button, Popup } from '@oceane/ui'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { ORG_SURVEILLANT_EPREUVE_SITE_SESSION } from '../../../../../config/enums'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'

const style = (theme) => getStyles({
	header: { // On est obligé de simuler les header d'une table
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 13,
		paddingBottom: 10,
		paddingTop: 10
	}
})

const AffichageSurveillantsPopup = ({ open, closePopup, nEpreuve, surveillants, deleteSurveillant, classes }) => {

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={closePopup}
		>
			Fermer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title={`Surveillants de l'épreuve ${nEpreuve}`}
			content={<>
				<Grid container direction="row" className={classes.headerBox}>
					<Grid item md={6}><Typography className={classes.header}>Surveillant</Typography></Grid>
					<Grid item md={4}><Typography className={classes.header}>Responsable de salle</Typography></Grid>
					<Grid item md={2}><Typography className={classes.header}>Supprimer</Typography></Grid>
				</Grid>
				{surveillants.map((s) => {
					return <Grid container direction="row">
						<Grid item md={6}><Typography>{s.nomPrenom}</Typography></Grid>
						<Grid item md={4}>
							<Typography>
								{s.responsable ? ORG_SURVEILLANT_EPREUVE_SITE_SESSION.TRUE : ORG_SURVEILLANT_EPREUVE_SITE_SESSION.FALSE}
							</Typography>
						</Grid>
						<Grid item md={2}>
							<IconButton onClick={() => deleteSurveillant(s.id)}><DeleteIcon /></IconButton>
						</Grid>
					</Grid>
				})}
			</>}
		/>)
}

AffichageSurveillantsPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	nEpreuve: PropTypes.string,
	surveillants: PropTypes.array,
	deleteSurveillant: PropTypes.func
}

export default compose(
	withStyles(style)
)(AffichageSurveillantsPopup)
