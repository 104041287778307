import { INS_ETAT_INSCRIPTION } from '@oceane/ui'

/**
 * Vérifie la présence d'inscriptions à l'état abandonné ou terminé
 * @returns {boolean} vrai si des inscriptions non finales existent
 */
export const inscriptionEtatNonFinalExiste = (listeInscriptions) => {
	if (!!listeInscriptions && listeInscriptions.length !== 0) {
		return listeInscriptions
			.filter(ins =>
				!ins.exceptionnelle &&
				ins.etatInscription !== INS_ETAT_INSCRIPTION.ABANDONNE &&
				ins.etatInscription !== INS_ETAT_INSCRIPTION.TERMINEE
			)
			.length > 0
	}
	return false
}
