import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getTypesFormation = createSelector(
	getReferences,
	references => references.typesFormation
)

const getTypesFormationIds = createSelector(
	getTypesFormation,
	typesFormation => typesFormation.ids
)


const getTypesFormationById = createSelector(
	getTypesFormation,
	typesFormation => typesFormation.byId
)

export const getListeTypesFormation = createSelector(
	getTypesFormationIds,
	getTypesFormationById,
	(ids, byId) => ids.map(id => byId[id])
)

export const getTypeFormation = createSelector(
	getTypesFormation,
	typesFormation => typesFormation.proprietes
)