import * as api from './scriptApi'

export const GET_SCRIPT_REQUEST = 'REFERENCES/GET_SCRIPT_REQUEST'
export const GET_SCRIPT_SUCCESS = 'REFERENCES/GET_SCRIPT_SUCCESS'
export const GET_SCRIPT_ERROR = 'REFERENCES/GET_SCRIPT_ERROR'
export const getScript = (idEpreuveType, idScript, isExploitation) => dispatch => {
	dispatch({ type: GET_SCRIPT_REQUEST })
	return api.getScript(idEpreuveType, idScript, isExploitation)
		.then(result => dispatch({
			type: GET_SCRIPT_SUCCESS,
			result,
			isExploitation
		}))
		.catch(e => {
			dispatch({ type: GET_SCRIPT_ERROR })
			throw e
		})
}

const convertPourcentage = values => ({
	...values,
	pourcentageReussite: Math.floor(Number(values.pourcentageReussite.replace(/,/, '.'))*10)/10
})


export const SAVE_SCRIPT_PARAMETRAGE_REQUEST = 'REFERENCES/SAVE_SCRIPT_PARAMETRAGE_REQUEST'
export const SAVE_SCRIPT_PARAMETRAGE_SUCCESS = 'REFERENCES/SAVE_SCRIPT_PARAMETRAGE_SUCCESS'
export const SAVE_SCRIPT_PARAMETRAGE_ERROR = 'REFERENCES/SAVE_SCRIPT_PARAMETRAGE_ERROR'
export const saveParametrage = (idScript, values) => dispatch => {
	dispatch({ type: SAVE_SCRIPT_PARAMETRAGE_REQUEST })
	return api.saveParametrage(idScript, convertPourcentage(values))
		.then(result => dispatch({
			type: SAVE_SCRIPT_PARAMETRAGE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: SAVE_SCRIPT_PARAMETRAGE_ERROR })
			throw e
		})
}

export const CLEAR_SCRIPT = 'REFERENCES/CLEAR_SCRIPT'
export const clearScript = () => dispatch => dispatch({ type: CLEAR_SCRIPT })

export const GET_DETAILS_NOEUD_REQUEST = 'REFERENCES/SCRIPT/GET_DETAILS_NOEUD_REQUEST'
export const GET_DETAILS_NOEUD_SUCCESS = 'REFERENCES/SCRIPT/GET_DETAILS_NOEUD_SUCCESS'
export const GET_DETAILS_NOEUD_ERROR = 'REFERENCES/SCRIPT/GET_DETAILS_NOEUD_ERROR'
export const getQuestionsDisponibles = (noeud, isExploitation) => dispatch => {
	dispatch({ type: GET_DETAILS_NOEUD_REQUEST })
	return api.getQuestionsDisponibles(noeud.idScript, noeud.codeRechercheQuestions, isExploitation)
		.then(questionsDisponibles => dispatch({
			type: GET_DETAILS_NOEUD_SUCCESS,
			noeud,
			questionsDisponibles
		}))
		.catch(e => {
			dispatch({ type: GET_DETAILS_NOEUD_ERROR })
			throw e
		})
}

export const CLEAR_NOEUD_COURANT = 'REFERENCES/SCRIPT/CLEAR_NOEUD_COURANT'
export const clearNoeudCourant = () => dispatch => dispatch({ type: CLEAR_NOEUD_COURANT })

export const EFFACER_NOEUD_REQUEST = 'REFERENCES/SCRIPT/EFFACER_NOEUD_REQUEST'
export const EFFACER_NOEUD_SUCCESS = 'REFERENCES/SCRIPT/EFFACER_NOEUD_SUCCESS'
export const EFFACER_NOEUD_ERROR = 'REFERENCES/SCRIPT/EFFACER_NOEUD_ERROR'
export const effacerNoeud = (noeud) => dispatch => {
	dispatch({ type: EFFACER_NOEUD_REQUEST })
	return api.effacerNoeud(noeud)
		.then(() => dispatch({ type: EFFACER_NOEUD_SUCCESS }))
		.catch(e => {
			dispatch({ type: EFFACER_NOEUD_ERROR })
			throw e
		})
}

export const CREER_NOEUD_REQUEST = 'REFERENCES/SCRIPT/CREER_NOEUD_REQUEST'
export const CREER_NOEUD_SUCCESS = 'REFERENCES/SCRIPT/CREER_NOEUD_SUCCESS'
export const CREER_NOEUD_ERROR = 'REFERENCES/SCRIPT/CREER_NOEUD_ERROR'
export const creerNoeud = (values) => dispatch => {
	dispatch({ type: CREER_NOEUD_REQUEST })
	return api.creerNoeud(values)
		.then(() => dispatch({ type: CREER_NOEUD_SUCCESS }))
		.catch(e => {
			dispatch({ type: CREER_NOEUD_ERROR })
			throw e
		})
}

export const MODIFIER_NOEUD_REQUEST = 'REFERENCES/SCRIPT/MODIFIER_NOEUD_REQUEST'
export const MODIFIER_NOEUD_SUCCESS = 'REFERENCES/SCRIPT/MODIFIER_NOEUD_SUCCESS'
export const MODIFIER_NOEUD_ERROR = 'REFERENCES/SCRIPT/MODIFIER_NOEUD_ERROR'
export const modifierNoeud = (idNoeud, values) => dispatch => {
	dispatch({ type: MODIFIER_NOEUD_REQUEST })
	return api.modifierNoeud(idNoeud, values)
		.then(() => dispatch({ type: MODIFIER_NOEUD_SUCCESS }))
		.catch(e => {
			dispatch({ type: MODIFIER_NOEUD_ERROR })
			throw e
		})
}


export const VALIDER_SCRIPT_REQUEST = 'REFERENCES/SCRIPT/VALIDER_SCRIPT_REQUEST'
export const VALIDER_SCRIPT_SUCCESS = 'REFERENCES/SCRIPT/VALIDER_SCRIPT_SUCCESS'
export const VALIDER_SCRIPT_ERROR = 'REFERENCES/SCRIPT/VALIDER_SCRIPT_ERROR'
export const validerScript = (values) => dispatch => {
	dispatch({ type: VALIDER_SCRIPT_REQUEST })
	return api.validerScript(values)
		.then(() => dispatch({ type: VALIDER_SCRIPT_SUCCESS, }))
		.catch(e => {
			dispatch({ type: VALIDER_SCRIPT_ERROR })
			throw e
		})
}

export const DEVALIDER_SCRIPT_REQUEST = 'REFERENCES/SCRIPT/DEVALIDER_SCRIPT_REQUEST'
export const DEVALIDER_SCRIPT_SUCCESS = 'REFERENCES/SCRIPT/DEVALIDER_SCRIPT_SUCCESS'
export const DEVALIDER_SCRIPT_ERROR = 'REFERENCES/SCRIPT/DEVALIDER_SCRIPT_ERROR'
export const devaliderScript = (values) => dispatch => {
	dispatch({ type: DEVALIDER_SCRIPT_REQUEST })
	return api.devaliderScript(values)
		.then(() => dispatch({ type: DEVALIDER_SCRIPT_SUCCESS, }))
		.catch(e => {
			dispatch({ type: DEVALIDER_SCRIPT_ERROR })
			throw e
		})
}
