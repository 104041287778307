import { fetchFactory } from 'isotope-client'
import { InscriptionBean } from '../../../customTypings/InscriptionBean'

/**
 * Recherche la liste des inscriptions affectées (etat=3) rattachées à un examen candidat au statut dossier à valider (=2).
 * RG2870/63626
 *
 * @return inscriptions à valider
 */
export const getInscriptionsAValiderExamenType = (idExamenType: number,
                                                  idSiteEcran?: number): Promise<InscriptionBean[]> => fetchFactory(
	'/inscriptions/a-valider',
	{
		method: 'POST',
		body: JSON.stringify({ idExamenType, idSiteEcran })
	}
)