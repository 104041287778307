import { fetchFactory } from 'isotope-client'

export const isExamenCandidatSupprimable = (idCandidat, idExamenCandidat) =>
	fetchFactory(`/candidats/${idCandidat}/examens/${idExamenCandidat}/isSupprimable`)

export const enregistrer = (idCandidat, idExamenCandidat, values) => fetchFactory(`/candidats/${idCandidat}/examens/${idExamenCandidat}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const abandonner = (idCandidat, idExamenCandidat, values) => fetchFactory(`/candidats/${idCandidat}/examens/${idExamenCandidat}/abandonner`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const reouvrir = (idCandidat, idExamenCandidat, values) => fetchFactory(`/candidats/${idCandidat}/examens/${idExamenCandidat}/reouvrir`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const supprimer = (idCandidat, idExamenCandidat, values) => fetchFactory(`/candidats/${idCandidat}/examens/${idExamenCandidat}`, {
	method: 'delete'
})

