import * as api from './siteEcranApi'

export const GET_SITES_ECRAN_REQUEST = 'GET_SITES_ECRAN_REQUEST'
export const GET_SITES_ECRAN_SUCCESS = 'GET_SITES_ECRAN_SUCCESS'
export const GET_SITES_ECRAN_ERROR = 'GET_SITES_ECRAN_ERROR'
export const getSitesEcran = (profil) => dispatch => {
	dispatch({ type: GET_SITES_ECRAN_REQUEST })

	return api.getSitesEcran(profil)
		.then((sites) => dispatch({ type: GET_SITES_ECRAN_SUCCESS, sites }))
		.catch(() => dispatch({ type: GET_SITES_ECRAN_ERROR }))
}

export const GET_SITES_ECRAN_BY_ID_REQUEST = 'GET_SITES_ECRAN_BY_ID_REQUEST'
export const GET_SITES_ECRAN_BY_ID_SUCCESS = 'GET_SITES_ECRAN_BY_ID_SUCCESS'
export const GET_SITES_ECRAN_BY_ID_ERROR = 'GET_SITES_ECRAN_BY_ID_ERROR'
export const getSiteEcranById = (id) => dispatch => {
	dispatch({ type: GET_SITES_ECRAN_BY_ID_REQUEST })

	return api.getSiteEcranById(id)
		.then((site) => dispatch({ type: GET_SITES_ECRAN_BY_ID_SUCCESS, site }))
		.catch(() => dispatch({ type: GET_SITES_ECRAN_BY_ID_ERROR }))
}

export const GET_EXAMENS_TYPE_BY_SITE_REQUEST = 'GET_EXAMENS_TYPE_BY_SITE_REQUEST'
export const GET_EXAMENS_TYPE_BY_SITE_SUCCESS = 'GET_EXAMENS_TYPE_BY_SITE_SUCCESS'
export const GET_EXAMENS_TYPE_BY_SITE_ERROR = 'GET_EXAMENS_TYPE_BY_SITE_ERROR'
export const getExamensTypeBySite = (id) => dispatch => {
	dispatch({ type: GET_EXAMENS_TYPE_BY_SITE_REQUEST })

	return api.getExamensTypeBySite(id)
		.then((examens) => dispatch({ type: GET_EXAMENS_TYPE_BY_SITE_SUCCESS, examens }))
		.catch(() => dispatch({ type: GET_EXAMENS_TYPE_BY_SITE_ERROR }))
}

export const CREATE_SITE_ECRAN_REQUEST = 'CREATE_SITE_ECRAN_REQUEST'
export const CREATE_SITE_ECRAN_SUCCESS = 'CREATE_SITE_ECRAN_SUCCESS'
export const CREATE_SITE_ECRAN_ERROR = 'CREATE_SITE_ECRAN_ERROR'
export const postSiteEcran = (values) => dispatch => {
	dispatch({ type: CREATE_SITE_ECRAN_REQUEST })

	return api.postSiteEcran(values)
		.then((site) => dispatch({ type: CREATE_SITE_ECRAN_SUCCESS, site }))
		.catch(e => {
			dispatch({ type: CREATE_SITE_ECRAN_ERROR })
			throw e
		})
}

export const GET_TYPES_SITES_ECRANS_REQUEST = 'GET_TYPES_SITES_ECRANS_REQUEST'
export const GET_TYPES_SITES_ECRANS_SUCCESS = 'GET_TYPES_SITES_ECRANS_SUCCESS'
export const GET_TYPES_SITES_ECRANS_ERROR = 'GET_TYPES_SITES_ECRANS_ERROR'
export const getTypesSitesEcrans = () => dispatch => {
	dispatch({ type: GET_TYPES_SITES_ECRANS_REQUEST })

	return api.getTypesSitesEcrans()
		.then((types) => dispatch({ type: GET_TYPES_SITES_ECRANS_SUCCESS, types }))
		.catch(e => {
			dispatch({ type: GET_TYPES_SITES_ECRANS_ERROR })
			throw e
		})
}

export const FILTRER_CALENDRIER_REQUEST = 'FILTRER_CALENDRIER_REQUEST'
export const FILTRER_CALENDRIER_SUCCESS = 'FILTRER_CALENDRIER_SUCCESS'
export const FILTRER_CALENDRIER_ERROR = 'FILTRER_CALENDRIER_ERROR'
export const filtrerCalendrier = (values) => dispatch => {
	dispatch({ type: FILTRER_CALENDRIER_REQUEST })

	return api.filtrerCalendrier(values)
		.then((calendrier) => dispatch({ type: FILTRER_CALENDRIER_SUCCESS, calendrier }))
		.catch(e => {
			dispatch({ type: FILTRER_CALENDRIER_ERROR })
			throw e
		})
}

export const FERMER_JOUR_REQUEST = 'FERMER_JOUR_REQUEST'
export const FERMER_JOUR_SUCCESS = 'FERMER_JOUR_SUCCESS'
export const FERMER_JOUR_ERROR = 'FERMER_JOUR_ERROR'
export const fermerJour = (values, jour) => dispatch => {
	dispatch({ type: FERMER_JOUR_REQUEST })

	return api.fermerJour(values, jour)
		.then((calendrier) => dispatch({ type: FERMER_JOUR_SUCCESS, calendrier }))
		.catch(e => {
			dispatch({ type: FERMER_JOUR_ERROR })
			throw e
		})
}

export const CONTROL_SITES_ECRANS_REQUEST = 'CONTROL_SITES_ECRANS_REQUEST'
export const CONTROL_SITES_ECRANS_SUCCESS = 'CONTROL_SITES_ECRANS_SUCCESS'
export const CONTROL_SITES_ECRANS_ERROR = 'CONTROL_SITES_ECRANS_ERROR'
export const controlSitesEcrans = (values) => dispatch => {
	dispatch({ type: CONTROL_SITES_ECRANS_REQUEST })

	return api.controlSitesEcrans(values)
		.then((response) => dispatch({ type: CONTROL_SITES_ECRANS_SUCCESS, response }))
		.catch(e => {
			dispatch({ type: CONTROL_SITES_ECRANS_ERROR })
			throw e
		})
}

export const ACTIVATION_SITE_ECRAN_REQUEST = 'ACTIVATION_SITE_ECRAN_REQUEST'
export const ACTIVATION_SITE_ECRAN_SUCCESS = 'ACTIVATION_SITE_ECRAN_SUCCESS'
export const ACTIVATION_SITE_ECRAN_ERROR = 'ACTIVATION_SITE_ECRAN_ERROR'
export const activationSiteEcran = (values) => dispatch => {
	dispatch({ type: ACTIVATION_SITE_ECRAN_REQUEST })

	return api.activationSiteEcran(values)
		.then(() => dispatch({ type: ACTIVATION_SITE_ECRAN_SUCCESS }))
		.catch(e => {
			dispatch({ type: ACTIVATION_SITE_ECRAN_ERROR })
			throw e
		})
}

export const MODIFICATION_SITE_ECRAN_REQUEST = 'MODIFICATION_SITE_ECRAN_REQUEST'
export const MODIFICATION_SITE_ECRAN_SUCCESS = 'MODIFICATION_SITE_ECRAN_SUCCESS'
export const MODIFICATION_SITE_ECRAN_ERROR = 'MODIFICATION_SITE_ECRAN_ERROR'
export const modificationSiteEcran = (values) => dispatch => {
	dispatch({ type: MODIFICATION_SITE_ECRAN_REQUEST })

	return api.modificationSiteEcran(values)
		.then(() => dispatch({ type: MODIFICATION_SITE_ECRAN_SUCCESS }))
		.catch(e => {
			dispatch({ type: MODIFICATION_SITE_ECRAN_ERROR })
			throw e
		})
}

export const GET_LISTE_SITES_ECRAN_REQUEST = 'GET_LISTE_SITES_ECRAN_REQUEST'
export const GET_LISTE_SITES_ECRAN_SUCCESS = 'GET_LISTE_SITES_ECRAN_SUCCESS'
export const GET_LISTE_SITES_ECRAN_ERROR = 'GET_LISTE_SITES_ECRAN_ERROR'
export const getListeSitesEcran = () => dispatch => {
	dispatch({ type: GET_LISTE_SITES_ECRAN_REQUEST })

	return api.getListeSitesEcran()
		.then(listeSitesEcran => dispatch({ type: GET_LISTE_SITES_ECRAN_SUCCESS, listeSitesEcran }))
		.catch(e => {
			dispatch({ type: GET_LISTE_SITES_ECRAN_ERROR })
			throw e
		})
}

export const POST_JOURNEE_SITE_ECRAN_REQUEST = 'POST_JOURNEE_SITE_ECRAN_REQUEST'
export const POST_JOURNEE_SITE_ECRAN_SUCCESS = 'POST_JOURNEE_SITE_ECRAN_SUCCESS'
export const POST_JOURNEE_SITE_ECRAN_ERROR = 'POST_JOURNEE_SITE_ECRAN_ERROR'
export const modificationJourneeSiteEcran = (values) => dispatch => {
	dispatch({ type: POST_JOURNEE_SITE_ECRAN_REQUEST })

	return api.modificationJourneeSiteEcran(values)
		.then((response) => dispatch({ type: POST_JOURNEE_SITE_ECRAN_SUCCESS, response }))
		.catch(e => {
		    dispatch({ type: POST_JOURNEE_SITE_ECRAN_ERROR })
            throw e
        })
}

export const GET_PLANNING_JOURNEE_REQUEST = 'SITE_ECRAN/GET_PLANNING_JOURNEE_REQUEST'
export const GET_PLANNING_JOURNEE_SUCCESS = 'SITE_ECRAN/GET_PLANNING_JOURNEE_SUCCESS'
export const GET_PLANNING_JOURNEE_ERROR = 'SITE_ECRAN/GET_PLANNING_JOURNEE_ERROR'
export const getPlanningJournee = (idSite, jour, filtre) => dispatch => {
	dispatch({ type: GET_PLANNING_JOURNEE_REQUEST })
	return api.getPlanningJournee(idSite, jour, filtre)
		.then(result => dispatch({
			type: GET_PLANNING_JOURNEE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_PLANNING_JOURNEE_ERROR })
			throw e
		})
}

export const CLEAN_PLANNING_JOURNEE = 'SITE_ECRAN/CLEAN_PLANNING_JOURNEE'
export const cleanPlanning = () => dispatch => dispatch({ type: CLEAN_PLANNING_JOURNEE })

export const VALIDER_SERVEUR_LOCAL_REQUEST = 'SITE_ECRAN/VALIDER_SERVEUR_LOCAL_REQUEST'
export const VALIDER_SERVEUR_LOCAL_SUCCESS = 'SITE_ECRAN/VALIDER_SERVEUR_LOCAL_SUCCESS'
export const VALIDER_SERVEUR_LOCAL_ERROR = 'SITE_ECRAN/VALIDER_SERVEUR_LOCAL_ERROR'
export const validerServeurLocal = (idSite) => dispatch => {
	dispatch({ type: VALIDER_SERVEUR_LOCAL_REQUEST })
	return api.testOPMRunningStatut(idSite)
		.then(result => {
			dispatch({ type: VALIDER_SERVEUR_LOCAL_SUCCESS })
			return result.code
		})
		.catch(e => {
			dispatch({ type: VALIDER_SERVEUR_LOCAL_ERROR })
			throw e
	})
}

export const REOUVRIR_CONNEXIONS_REQUEST = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_REQUEST'
export const REOUVRIR_CONNEXIONS_SUCCESS = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_SUCCESS'
export const REOUVRIR_CONNEXIONS_ERROR = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_ERROR'
export const reouvrirConnexions = (idSite, date) => dispatch => {
	dispatch({ type: REOUVRIR_CONNEXIONS_REQUEST })
	return api.reouvrirConnexions(idSite, date)
		.then(() => dispatch({ type: REOUVRIR_CONNEXIONS_SUCCESS }))
		.catch(e => {
			dispatch({ type: REOUVRIR_CONNEXIONS_ERROR })
			throw e
	})
}

export const REOUVRIR_CONNEXIONS_EPREUVE_REQUEST = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_EPREUVE_REQUEST'
export const REOUVRIR_CONNEXIONS_EPREUVE_SUCCESS = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_EPREUVE_SUCCESS'
export const REOUVRIR_CONNEXIONS_EPREUVE_ERROR = 'SITE_ECRAN/REOUVRIR_CONNEXIONS_EPREUVE_ERROR'
export const reouvrirConnexionsEpreuve = (id) => dispatch => {
	dispatch({ type: REOUVRIR_CONNEXIONS_EPREUVE_REQUEST })
	return api.reouvrirConnexionsEpreuve(id)
		.then(() => dispatch({ type: REOUVRIR_CONNEXIONS_EPREUVE_SUCCESS }))
		.catch(e => {
			dispatch({ type: REOUVRIR_CONNEXIONS_EPREUVE_ERROR })
			throw e
	})
}

export const GET_EPREUVE_REQUEST = 'SITE_ECRAN/GET_EPREUVE_REQUEST'
export const GET_EPREUVE_SUCCESS = 'SITE_ECRAN/GET_EPREUVE_SUCCESS'
export const GET_EPREUVE_ERROR = 'SITE_ECRAN/GET_EPREUVE_ERROR'
export const getEpreuve = (id, idSite, date) => dispatch => {
	dispatch({ type: GET_EPREUVE_REQUEST })
	return api.getEpreuve(id, idSite, date)
		.then(result => dispatch({
			type: GET_EPREUVE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_EPREUVE_ERROR })
			throw e
		})
}

export const PUT_PANNE_EPREUVE_REQUEST = 'SITE_ECRAN/PUT_PANNE_EPREUVE_REQUEST'
export const PUT_PANNE_EPREUVE_SUCCESS = 'SITE_ECRAN/PUT_PANNE_EPREUVE_SUCCESS'
export const PUT_PANNE_EPREUVE_ERROR = 'SITE_ECRAN/PUT_PANNE_EPREUVE_ERROR'
export const putPanne = (id) => dispatch => {
	dispatch({ type: PUT_PANNE_EPREUVE_REQUEST })
	return api.putPanne(id)
		.then(() => dispatch({
			type: PUT_PANNE_EPREUVE_SUCCESS
		}))
		.catch(e => {
			dispatch({ type: PUT_PANNE_EPREUVE_ERROR })
			throw e
		})
}

export const ENREGISTRER_EPREUVE_REQUEST = 'SITE_ECRAN/ENREGISTRER_EPREUVE_REQUEST'
export const ENREGISTRER_EPREUVE_SUCCESS = 'SITE_ECRAN/ENREGISTRER_EPREUVE_SUCCESS'
export const ENREGISTRER_EPREUVE_ERROR = 'SITE_ECRAN/ENREGISTRER_EPREUVE_ERROR'
export const enregistrerDetails = (form, idSite, idEpreuve, date) => dispatch => {
	dispatch({ type: ENREGISTRER_EPREUVE_REQUEST })
	return api.enregistrerDetails(form, idSite, idEpreuve, date)
		.then((result) => dispatch({
			type: ENREGISTRER_EPREUVE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: ENREGISTRER_EPREUVE_ERROR })
			throw e
		})
}

export const CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_REQUEST = 'SITE_ECRAN/CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_REQUEST'
export const CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_SUCCESS = 'SITE_ECRAN/CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_SUCCESS'
export const CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_ERROR = 'SITE_ECRAN/CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_ERROR'
export const checkFileExist = (id, idSite, date) => dispatch => {
	dispatch({ type: CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_REQUEST })
	return api.checkFileExist(id, idSite, date)
		.then(() => dispatch({
			type: CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_SUCCESS
		}))
		.catch(e => {
			dispatch({ type: CHECK_FILE_EXIST_LETTRE_RESULTAT_EPREUVE_ERROR })
			throw e
		})
}

export const HANDLE_QUERY_SITES_ECRAN = 'HANDLE_QUERY_SITES_ECRAN'
export const RESET_QUERY_SITES_ECRAN = "RESET_QUERY_SITES_ECRAN"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_SITES_ECRAN,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_SITES_ECRAN
	})
}