import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { dataTableActions, getStyles, SpringDataTable, Title } from 'isotope-client'
import moment from 'moment'
import { Actions, Button, DownloadIcon, EmptyResult, Error, ResponsiveForm, Select, colors, BandeauInfo, injectFormSnackbar } from '@oceane/ui'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { injectTypesExamensAutorises } from './services/MiseEnExploitationInjectors'
import {
	demanderMiseEnExploitation,
	verifierAvantMiseEnExploitation,
	verifierDisponibiliteCompteRendu
} from './services/miseEnExploitationActions'
import { BNQ_ETAT_VERSION_BANQUE } from '../../../config/enums'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const validate = values => {
	const errors = {}
	if (!values.typeExamen) {
		errors.typeExamen = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const styles = theme => getStyles({
	ko: {
		color: theme.palette.warning
	}
})

const DATA_TABLE_NAME = 'historiqueExploitation'

const MiseEnExploitationPage = ({ typesExamen, verifierDisponibiliteCompteRendu, verifierAvantMiseEnExploitation, demanderMiseEnExploitation, refreshTable, handleSubmit, snackSuccess, snackError, error, classes }) => {

	const [infoSuccess, setInfoSuccess] = React.useState(null)

	const handleDownloadCompteRendu = id => verifierDisponibiliteCompteRendu(id)
		.then(() => download(`/files/exploitation/${id}/compte-rendu`))
		.catch(() => snackError('Le document n\'existe pas'))

	const verifier = values => {
		setInfoSuccess(null)
		return verifierAvantMiseEnExploitation(values)
			.then(() => setInfoSuccess('La vérification est terminée et ne comporte par d\'erreur'))
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const demander = values => {
		setInfoSuccess(null)
		return demanderMiseEnExploitation(values)
			.then(() => {
				refreshTable()
				setInfoSuccess('La vérification est terminée et ne comporte par d\'erreur')
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	return (
		<>
			<Title value="Mise en exploitation" />
			<Error error={error} />
			{infoSuccess && <BandeauInfo message={infoSuccess}/>}
			<SpringDataTable
				apiUrl="/versions-banque"
				nom="historiqueExploitation"
				noResultFragment={<EmptyResult />}
				headers={[{
					key: 'dateExploitation',
					name: 'Date d\'exploitation',
					sortable: true,
					render: row => <span
						className={classNames({ [classes.ko]: row.etat === BNQ_ETAT_VERSION_BANQUE.KO })}
					>
						{moment(row.dateExploitation).format('DD/MM/YYYY')}
					</span>
				}, {
					key: 'nbQuestions',
					name: 'Nb de questions',
					sortable: true,
					render: row => <span
						className={classNames({ [classes.ko]: row.etat === BNQ_ETAT_VERSION_BANQUE.KO })}
					>
						{row.nbQuestions}
					</span>
				}, {
					key: 'etat',
					name: 'Etat',
					sortable: true,
					render: row => <span
						className={classNames({ [classes.ko]: row.etat === BNQ_ETAT_VERSION_BANQUE.KO })}
					>
						<FormattedMessage id={`enums.bnqEtatVersionBanque.${row.etat}`} />
					</span>
				}, {
					key: 'compteRendu',
					name: 'Compte rendu',
					sortable: false,
					render: row => {
						if (row.nomFichierCompteRendu) {
							return <IconButton
								disableRipple
								onClick={() => handleDownloadCompteRendu(row.id)}
							>
								<DownloadIcon />
							</IconButton>
						}
						return null
					}
				}]}
				applyRowStyle={row => {
					let color = 'unset'
					if (row.etat === BNQ_ETAT_VERSION_BANQUE.KO) {
						color = colors.error
					}
					return {
						color
					}
				}}
			/>
			<ResponsiveForm>
				<Field
					label="Type d'examen *"
					name="typeExamen"
					component={Select}
				>
					{typesExamen.map((type, index) => <MenuItem
						key={`typeExamen-${index}`}
						value={type.id}
					>
						{type.libelle}
					</MenuItem>)}
				</Field>
				<Actions>
					<Button onClick={handleSubmit(verifier)}>
						Vérification avant mise en exploitation
					</Button>
					<Button onClick={handleSubmit(demander)} variant="contained">
						Mettre en exploitation
					</Button>
				</Actions>
			</ResponsiveForm>
		</>
	)
}

MiseEnExploitationPage.propTypes = {
	verifierDisponibiliteCompteRendu: PropTypes.func,
	verifierAvantMiseEnExploitation: PropTypes.func,
	demanderMiseEnExploitation: PropTypes.func,
	refreshTable: PropTypes.func
}

const actions = {
	verifierDisponibiliteCompteRendu,
	verifierAvantMiseEnExploitation,
	demanderMiseEnExploitation,
	refreshTable: () => dataTableActions.refresh(DATA_TABLE_NAME)
}

export default compose(
	withStyles(styles),
	injectTypesExamensAutorises,
	connect(undefined, actions),
	reduxForm({
		form: 'miseEnExploitation',
		validate
	}),
	injectFormSnackbar({ errorMessage: 'La vérification avant mise en exploitation comporte des erreurs', successMessage: 'La vérification est terminée' })
)(MiseEnExploitationPage)
