import { createSelector } from 'reselect'

const getLocalState = state => state.administration

const getRecherche = createSelector(
	getLocalState,
	administration => administration.recherche
)

const getInformation = createSelector(
	getLocalState,
	administration => administration.information
)

export const isInformationLoading = createSelector(
	getInformation,
	information => information.loading
)

export const getNom = createSelector(
	getRecherche,
	recherche => recherche.nom
)

export const getIdentifiant = createSelector(
	getRecherche,
	recherche => recherche.identifiant
)

export const getProfil = createSelector(
	getRecherche,
	recherche => recherche.profil
)

export const getUtilisateur = createSelector(
	getLocalState,
	administration => administration.utilisateur
)
