import * as api from './banqueQuestionsApi'
import { BNQ_ID_ETAT_QUESTION, BNQ_ID_TYPE_ACTION } from '../../../../config/enums'

export const DATATABLE_NAME = 'questions'

/**
 * Utilisé pour l'autocomplete de la search
 */
const GET_LIGNES_SUCCESS = 'GET_LIGNES_SUCCESS'
const GET_LIGNES_ERROR = 'GET_LIGNES_ERROR'
export const getLignes = (codeLdp) => dispatch =>
	api.getLignes(codeLdp)
		.then((lignes) => {
			const formattedLignes = lignes.map(ligne => ({ label: ligne.codeLigneDeProg }))
			dispatch({
				type: GET_LIGNES_SUCCESS,
				lignes: formattedLignes
			})
			return formattedLignes
		})
		.catch((e) => {
			dispatch({
				type: GET_LIGNES_ERROR
			})
			throw e
		})

/**
 * Récupération des familles via le code matière spécifié
 */
const GET_FAMILLES_SUCCESS = 'GET_FAMILLES_SUCCESS'
const GET_FAMILLES_ERROR = 'GET_FAMILLES_ERROR'
export const getFamilles = (codeMatiere) => dispatch =>
	api.getFamilles(codeMatiere)
		.then(familles => dispatch({
				type: GET_FAMILLES_SUCCESS,
				familles
			})
		)
		.catch((e) => {
			dispatch({
				type: GET_FAMILLES_ERROR
			})
			throw e
		})

export const GET_LISTE_AUTEURS_REQUEST = 'BANQUE_QUESTIONS/GET_LISTE_AUTEURS_REQUEST'
export const GET_LISTE_AUTEURS_SUCCESS = 'BANQUE_QUESTIONS/GET_LISTE_AUTEURS_SUCCESS'
export const GET_LISTE_AUTEURS_ERROR = 'BANQUE_QUESTIONS/GET_LISTE_AUTEURS_ERROR'
export const getListeAuteurs = () => dispatch => {
	dispatch({ type: GET_LISTE_AUTEURS_REQUEST })
	return api.getListeAuteurs()
		.then(result => {
			dispatch({ type: GET_LISTE_AUTEURS_SUCCESS })
			return result
		})
		.catch(e => {
			dispatch({ type: GET_LISTE_AUTEURS_ERROR })
			throw e
		})
}

const GET_QUESTION_ID_BY_IDENTIFIANT_REQUEST = 'GET_QUESTION_ID_BY_IDENTIFIANT_REQUEST'
const GET_QUESTION_ID_BY_IDENTIFIANT_SUCCESS = 'GET_QUESTION_ID_BY_IDENTIFIANT_SUCCESS'
const GET_QUESTION_ID_BY_IDENTIFIANT_ERROR = 'GET_QUESTION_ID_BY_IDENTIFIANT_ERROR'
export const getQuestionIdByIdentifiant = (identifiant, exploitee) => dispatch => {
	dispatch({ type: GET_QUESTION_ID_BY_IDENTIFIANT_REQUEST, identifiant, exploitee })
	return api.getQuestionIdByIdentifiant(identifiant, exploitee)
		.then(idQuestion => dispatch({
			type: GET_QUESTION_ID_BY_IDENTIFIANT_SUCCESS,
			listeIds: [idQuestion]
		}))
		.catch(e => {
			dispatch({
				type: GET_QUESTION_ID_BY_IDENTIFIANT_ERROR,
				e
			})
			throw e
		})
}

const GET_QUESTION_ID_BY_NUMERO_EUROPEEN_REQUEST = 'GET_QUESTION_ID_BY_NUMERO_EUROPEEN_REQUEST'
const GET_QUESTION_ID_BY_NUMERO_EUROPEEN_SUCCESS = 'GET_QUESTION_ID_BY_NUMERO_EUROPEEN_SUCCESS'
const GET_QUESTION_ID_BY_NUMERO_EUROPEEN_ERROR = 'GET_QUESTION_ID_BY_NUMERO_EUROPEEN_ERROR'
export const getQuestionIdByNumeroEuropeen = (numeroEuropeen, exploitee) => dispatch => {
	dispatch({ type: GET_QUESTION_ID_BY_NUMERO_EUROPEEN_REQUEST, numeroEuropeen, exploitee })
	return api.getQuestionIdByNumeroEuropeen(numeroEuropeen, exploitee)
		.then(listeIds => dispatch({
			type: GET_QUESTION_ID_BY_NUMERO_EUROPEEN_SUCCESS,
			listeIds
		}))
		.catch(e => {
			dispatch({
				type: GET_QUESTION_ID_BY_NUMERO_EUROPEEN_ERROR,
				e
			})
			throw e
		})
}

export const CREER_QUESTION_REQUEST = 'BANQUE_QUESTIONS/CREER_QUESTION_REQUEST'
export const CREER_QUESTION_SUCCESS = 'BANQUE_QUESTIONS/CREER_QUESTION_SUCCESS'
export const CREER_QUESTION_ERROR = 'BANQUE_QUESTIONS/CREER_QUESTION_ERROR'
export const creerQuestion = values => dispatch => {
	dispatch({ type: CREER_QUESTION_REQUEST })
	return api.creerQuestion(values)
		.then(id => {
			dispatch({ type: CREER_QUESTION_SUCCESS })
			return id
		})
		.catch(e => {
			dispatch({ type: CREER_QUESTION_ERROR })
			throw e
		})
}

///////////////////////////////////////////////////
//                    GLOBAL                     //
///////////////////////////////////////////////////
export const CLEAN_QUESTION = 'BANQUE_QUESTIONS/CLEAN_QUESTION'
export const cleanQuestion = () => dispatch => dispatch({ type: CLEAN_QUESTION })


///////////////////////////////////////////////////
//               DONNEES GENERALES               //
///////////////////////////////////////////////////

export const GET_QUESTION_DONNEES_GENERALES_REQUEST = 'BANQUE_QUESTIONS/GET_QUESTION_DONNEES_GENERALES_REQUEST'
export const GET_QUESTION_DONNEES_GENERALES_SUCCESS = 'BANQUE_QUESTIONS/GET_QUESTION_DONNEES_GENERALES_SUCCESS'
export const GET_QUESTION_DONNEES_GENERALES_ERROR = 'BANQUE_QUESTIONS/GET_QUESTION_DONNEES_GENERALES_ERROR'
export const getQuestionDonneesGenerales = (id, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_DONNEES_GENERALES_REQUEST })
	return api.getQuestionDonneesGenerales(id, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_DONNEES_GENERALES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_DONNEES_GENERALES_ERROR })
			throw e
		})
}

export const SAVE_QUESTION_DONNEES_GENERALES_REQUEST = 'BANQUE_QUESTIONS/SAVE_QUESTION_DONNEES_GENERALES_REQUEST'
export const SAVE_QUESTION_DONNEES_GENERALES_SUCCESS = 'BANQUE_QUESTIONS/SAVE_QUESTION_DONNEES_GENERALES_SUCCESS'
export const SAVE_QUESTION_DONNEES_GENERALES_ERROR = 'BANQUE_QUESTIONS/SAVE_QUESTION_DONNEES_GENERALES_ERROR'
export const saveQuestionDonneesGenerales = (id, values) => dispatch => {
	dispatch({ type: SAVE_QUESTION_DONNEES_GENERALES_REQUEST })
	return api.saveQuestionDonneesGenerales(id, values)
		.then(result => {
			dispatch({
				type: SAVE_QUESTION_DONNEES_GENERALES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: SAVE_QUESTION_DONNEES_GENERALES_ERROR })
			throw e
		})
}

export const GET_QUESTION_ENONCES_REQUEST = 'BANQUE_QUESTIONS/GET_QUESTION_ENONCES_REQUEST'
export const GET_QUESTION_ENONCES_SUCCESS = 'BANQUE_QUESTIONS/GET_QUESTION_ENONCES_SUCCESS'
export const GET_QUESTION_ENONCES_ERROR = 'BANQUE_QUESTIONS/GET_QUESTION_ENONCES_ERROR'
export const getEnonces = (id, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_ENONCES_REQUEST })
	return api.getEnonces(id, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_ENONCES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_ENONCES_ERROR })
			throw e
		})
}

export const SAVE_QUESTION_ENONCES_REQUEST = 'BANQUE_QUESTIONS/SAVE_QUESTION_ENONCES_REQUEST'
export const SAVE_QUESTION_ENONCES_SUCCESS = 'BANQUE_QUESTIONS/SAVE_QUESTION_ENONCES_SUCCESS'
export const SAVE_QUESTION_ENONCES_ERROR = 'BANQUE_QUESTIONS/SAVE_QUESTION_ENONCES_ERROR'
export const saveQuestionEnonces = (id, values) => dispatch => {
	dispatch({ type: SAVE_QUESTION_ENONCES_REQUEST })
	return api.saveQuestionEnonces(id, values)
		.then(result => {
			dispatch({
				type: SAVE_QUESTION_ENONCES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: SAVE_QUESTION_ENONCES_ERROR })
			throw e
		})
}


///////////////////////////////////////////////////
//                  AFFECTATION                  //
///////////////////////////////////////////////////

export const GET_QUESTION_AFFECTATIONS_REQUEST = 'BANQUE_QUESTIONS/GET_QUESTION_AFFECTATIONS_REQUEST'
export const GET_QUESTION_AFFECTATIONS_SUCCESS = 'BANQUE_QUESTIONS/GET_QUESTION_AFFECTATIONS_SUCCESS'
export const GET_QUESTION_AFFECTATIONS_ERROR = 'BANQUE_QUESTIONS/GET_QUESTION_AFFECTATIONS_ERROR'
export const getQuestionAffectations = (id, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_AFFECTATIONS_REQUEST })
	return api.getQuestionAffectations(id, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_AFFECTATIONS_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_AFFECTATIONS_ERROR, e })
			throw e
		})
}

export const CREER_AFFECTATION_REQUEST = 'BANQUE_QUESTIONS/CREER_AFFECTATION_REQUEST'
export const CREER_AFFECTATION_SUCCESS = 'BANQUE_QUESTIONS/CREER_AFFECTATION_SUCCESS'
export const CREER_AFFECTATION_ERROR = 'BANQUE_QUESTIONS/CREER_AFFECTATION_ERROR'
export const creerAffectation = (idQuestion, values) => dispatch => {
	dispatch({ type: CREER_AFFECTATION_REQUEST })
	return api.creerAffectation(idQuestion, values)
		.then(id => dispatch({
			type: CREER_AFFECTATION_SUCCESS,
			values: {
				...values,
				idAffectationExamen: id
			}
		}))
		.catch(e => {
			dispatch({ type: CREER_AFFECTATION_ERROR, e })
			throw e
		})
}
export const MODIFIER_AFFECTATION_REQUEST = 'BANQUE_QUESTIONS/MODIFIER_AFFECTATION_REQUEST'
export const MODIFIER_AFFECTATION_SUCCESS = 'BANQUE_QUESTIONS/MODIFIER_AFFECTATION_SUCCESS'
export const MODIFIER_AFFECTATION_ERROR = 'BANQUE_QUESTIONS/MODIFIER_AFFECTATION_ERROR'
export const modifierAffectation = (idQuestion, values) => dispatch => {
	dispatch({ type: MODIFIER_AFFECTATION_REQUEST })
	return api.modifierAffectation(idQuestion, values)
		.then(() => dispatch({
			type: MODIFIER_AFFECTATION_SUCCESS,
			values
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_AFFECTATION_ERROR, e })
			throw e
		})
}

export const SUPPRIMER_AFFECTATION_REQUEST = 'BANQUE_QUESTIONS/SUPPRIMER_AFFECTATION_REQUEST'
export const SUPPRIMER_AFFECTATION_SUCCESS = 'BANQUE_QUESTIONS/SUPPRIMER_AFFECTATION_SUCCESS'
export const SUPPRIMER_AFFECTATION_ERROR = 'BANQUE_QUESTIONS/SUPPRIMER_AFFECTATION_ERROR'
export const supprimerAffectation = (idQuestion, affectation) => dispatch => {
	dispatch({ type: SUPPRIMER_AFFECTATION_REQUEST })
	return api.supprimerAffectation(idQuestion, affectation.idAffectationExamen)
		.then(() => dispatch({
			type: SUPPRIMER_AFFECTATION_SUCCESS,
			idExamenType: affectation.idExamenType
		}))
		.catch(e => {
			dispatch({ type: SUPPRIMER_AFFECTATION_ERROR, e })
			throw e
		})
}

export const GET_STATISTIQUES_AFFECTATION_REQUEST = 'BANQUE_QUESTION/GET_STATISTIQUES_AFFECTATION_REQUEST'
export const GET_STATISTIQUES_AFFECTATION_SUCCESS = 'BANQUE_QUESTION/GET_STATISTIQUES_AFFECTATION_SUCCESS'
export const GET_STATISTIQUES_AFFECTATION_ERROR = 'BANQUE_QUESTION/GET_STATISTIQUES_AFFECTATION_ERROR'
export const getStatistiquesAffectation = (idOceane, idExamenType) => dispatch => {
	dispatch({ type: GET_STATISTIQUES_AFFECTATION_REQUEST })
	return api.getStatistiquesAffectation(idOceane, idExamenType)
		.then(result => {
			dispatch({
				type: GET_STATISTIQUES_AFFECTATION_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_STATISTIQUES_AFFECTATION_ERROR, e })
			throw e
		})
}
///////////////////////////////////////////////////
//                    ANNEXES                    //
///////////////////////////////////////////////////

export const GET_QUESTION_ANNEXES_REQUEST = 'BANQUE_QUESTIONS/GET_QUESTION_ANNEXES_REQUEST'
export const GET_QUESTION_ANNEXES_SUCCESS = 'BANQUE_QUESTIONS/GET_QUESTION_ANNEXES_SUCCESS'
export const GET_QUESTION_ANNEXES_ERROR = 'BANQUE_QUESTIONS/GET_QUESTION_ANNEXES_ERROR'
export const getQuestionAnnexes = (id, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_ANNEXES_REQUEST })
	return api.getQuestionAnnexes(id, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_ANNEXES_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_ANNEXES_ERROR })
			throw e
		})
}

export const UPDATE_ANNEXE_QUESTION_REQUEST = 'BANQUE_QUESTIONS/UPDATE_ANNEXE_QUESTION_REQUEST'
export const UPDATE_ANNEXE_QUESTION_SUCCESS = 'BANQUE_QUESTIONS/UPDATE_ANNEXE_QUESTION_SUCCESS'
export const UPDATE_ANNEXE_QUESTION_ERROR = 'BANQUE_QUESTIONS/UPDATE_ANNEXE_QUESTION_ERROR'
export const updateAnnexeQuestion = (idQuestion, idAnnexe, isAffectation) => dispatch => {
	dispatch({ type: UPDATE_ANNEXE_QUESTION_REQUEST, idQuestion, idAnnexe, isAffectation })
	const promise = isAffectation ? api.affecterAnnexe : api.rendreDisponibleAnnexe

	return promise(idQuestion, idAnnexe)
		.then(() => dispatch({
			type: UPDATE_ANNEXE_QUESTION_SUCCESS,
			idAnnexe,
			isAffectation
		}))
		.catch(e => {
			dispatch({ type: UPDATE_ANNEXE_QUESTION_ERROR, e })
			throw e
		})
}
///////////////////////////////////////////////////
//                   EXPERTISE                   //
///////////////////////////////////////////////////

export const GET_QUESTION_EXPERTISE_REQUEST = 'BANQUE_QUESTIONS/GET_EXPERTISE_REQUEST'
export const GET_QUESTION_EXPERTISE_SUCCESS = 'BANQUE_QUESTIONS/GET_EXPERTISE_SUCCESS'
export const GET_QUESTION_EXPERTISE_ERROR = 'BANQUE_QUESTIONS/GET_EXPERTISE_ERROR'
export const getQuestionExpertise = (idQuestion, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_EXPERTISE_REQUEST })
	return api.getQuestionExpertise(idQuestion, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_EXPERTISE_SUCCESS,
				result
			})
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_EXPERTISE_ERROR, e })
			throw e
		})
}

///////////////////////////////////////////////////
//                  ETAT GLOBAL                  //
///////////////////////////////////////////////////
export const GET_QUESTION_ETAT_GLOBAL_REQUEST = 'BANQUE_QUESTIONS/GET_ETAT_GLOBAL_REQUEST'
export const GET_QUESTION_ETAT_GLOBAL_SUCCESS = 'BANQUE_QUESTIONS/GET_ETAT_GLOBAL_SUCCESS'
export const GET_QUESTION_ETAT_GLOBAL_ERROR = 'BANQUE_QUESTIONS/GET_ETAT_GLOBAL_ERROR'
export const getQuestionEtatGlobal = (idQuestion, isExploitation) => dispatch => {
	dispatch({ type: GET_QUESTION_ETAT_GLOBAL_REQUEST })
	return api.getQuestionEtatGlobal(idQuestion, isExploitation)
		.then(result => {
			dispatch({
				type: GET_QUESTION_ETAT_GLOBAL_SUCCESS,
				result
			})
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_ETAT_GLOBAL_ERROR, e })
			throw e
		})
}

export const CHANGER_ETAT_QUESTION_REQUEST = 'BANQUE_QUESTIONS/CHANGER_ETAT_QUESTION_REQUEST'
export const CHANGER_ETAT_QUESTION_SUCCESS = 'BANQUE_QUESTIONS/CHANGER_ETAT_QUESTION_SUCCESS'
export const CHANGER_ETAT_QUESTION_ERROR = 'BANQUE_QUESTIONS/CHANGER_ETAT_QUESTION_ERROR'
export const changerEtatQuestion = (idQuestion, typeAction, values) => dispatch => {
	dispatch({ type: CHANGER_ETAT_QUESTION_REQUEST, typeAction })
	let promise
	let nouvelEtat
	switch (typeAction) {
		case BNQ_ID_TYPE_ACTION.REFUS:
			promise = api.refuserQuestion(idQuestion, values)
			nouvelEtat = BNQ_ID_ETAT_QUESTION.REFUSEE
			break
		case BNQ_ID_TYPE_ACTION.SUPPRESSION:
			promise = api.supprimerQuestion(idQuestion, values)
			nouvelEtat = BNQ_ID_ETAT_QUESTION.SUPPRIMEE
			break
		case BNQ_ID_TYPE_ACTION.ACCEPTATION:
			promise = api.accepterQuestion(idQuestion, values)
			nouvelEtat = BNQ_ID_ETAT_QUESTION.ACCEPTEE
			break
		case BNQ_ID_TYPE_ACTION.RESTAURATION:
			promise = api.restaurerQuestion(idQuestion, values)
			nouvelEtat = BNQ_ID_ETAT_QUESTION.REFUSEE
			break
		case BNQ_ID_TYPE_ACTION.APPLICATION:
			promise = api.appliquerQuestion(idQuestion, values)
			nouvelEtat = BNQ_ID_ETAT_QUESTION.APPLICABLE
			break
		default:
			promise = Promise.reject('Action invalide')
	}

	return promise
		.then(result => {
			dispatch({
				type: CHANGER_ETAT_QUESTION_SUCCESS,
				typeAction,
				nouvelEtat,
				result
			})
		})
		.catch(e => {
			dispatch({ type: CHANGER_ETAT_QUESTION_ERROR, typeAction, e })
			throw e
		})
}

export const SET_VISA_RDB_REQUEST = 'BANQUE_QUESTIONS/SET_VISA_RDB_REQUEST'
export const SET_VISA_RDB_SUCCESS = 'BANQUE_QUESTIONS/SET_VISA_RDB_SUCCESS'
export const SET_VISA_RDB_ERROR = 'BANQUE_QUESTIONS/SET_VISA_RDB_ERROR'
export const setVisaRDB = (idQuestion) => dispatch => {
	dispatch({ type: SET_VISA_RDB_REQUEST })
	return api.setVisaRDB(idQuestion)
		.then(result => {
			dispatch({
				type: SET_VISA_RDB_SUCCESS,
				result
			})
			return result
		})
		.catch(e => {
			dispatch({ type: SET_VISA_RDB_ERROR, e })
			throw e
		})
}

///////////////////////////////////////////////////
//               COMPARATIF IMPORT               //
///////////////////////////////////////////////////
export const GET_QUESTION_COMPARATIF_IMPORT_REQUEST = 'BANQUE_QUESTION/GET_QUESTION_COMPARATIF_IMPORT_REQUEST'
export const GET_QUESTION_COMPARATIF_IMPORT_SUCCESS = 'BANQUE_QUESTION/GET_QUESTION_COMPARATIF_IMPORT_SUCCESS'
export const GET_QUESTION_COMPARATIF_IMPORT_ERROR = 'BANQUE_QUESTION/GET_QUESTION_COMPARATIF_IMPORT_ERROR'
export const getQuestionComparatifImport = idQuestion => dispatch => {
	dispatch({ type: GET_QUESTION_COMPARATIF_IMPORT_REQUEST })
	return api.getQuestionComparatifImport(idQuestion)
		.then(result => {
			dispatch({
				type: GET_QUESTION_COMPARATIF_IMPORT_SUCCESS,
				result
			})
		})
		.catch(e => {
			dispatch({ type: GET_QUESTION_COMPARATIF_IMPORT_ERROR, e })
			throw e
		})
}