import { EmptyResult, getFilArianeExamenType, Link } from '@oceane/ui'
import { injectToolbarData, SpringDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as selectors from '../services/examensTypesSelectors'

const OngletEpreuvesTypes = ({ id }) => {
	return (<React.Fragment>
		<Title value="Epreuves types de l'examen" />
		{id && <SpringDataTable
			nom="epreuvesTypes"
			apiUrl={`/examensTypes/${id}/epreuvesTypesMatieres`}
			headers={
				[
					{
						key: 'libelle',
						name: 'Libellé court',
						sortable: false,
						render: (row) => <Link to={`/examens-types/${id}/epreuves-types/${row.id}`}>
							{row.libelle}
						</Link>
					},
					{
						key: 'libelleLong',
						name: 'Libellé long',
						sortable: false,
						render: (row) => row.libelleLong
					},
					{
						key: 'matieres',
						name: 'Matières',
						sortable: false
					}
				]
			}
			noPagination
			noResultFragment={
				<EmptyResult />
			}
		/>}
	</React.Fragment>)
}

OngletEpreuvesTypes.propTypes = {
	classes: PropTypes.object,
	goToDetailEpreuveTypePage: PropTypes.func
}

const mapStateToProps = state => {
	const examenType = selectors.getExamenType(state)
	return {
		id: examenType.id,
		libelle: examenType.libelleCourt,
		type: examenType.type,
		etat: examenType.etat,
		typeExamen: examenType.typeExamen
	}
}

export default compose(
	connect(mapStateToProps),
	injectToolbarData(({ libelle, etat }) => getFilArianeExamenType(libelle, etat))
)(OngletEpreuvesTypes)
