import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { Button, Error, Input, Popup, Select, normalizeMinutesSecondes, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import * as selectors from '../services/banqueQuestionsSelectors'
import { creerAffectation, modifierAffectation } from '../services/banqueQuestionsActions'
import { questionAffectationShape } from '../../../../config/shapes'

const validate = values => {
	const errors = {}
	if (!values.dureeExamen) {
		errors.dureeExamen = { id: 'global.erreurs.obligatoire' }
	} else if (!/[0-5]\d:[0-5]\d/.test(values.dureeExamen)) {
		errors.dureeExamen = { id: 'global.erreurs.date.formatMinutesSecondes' }
	}
	return errors
}

const QuestionAffectationPopup = ({ idQuestion, affectation, closePopup, creation, handleSubmit, reset, error, initialize, creerAffectation, modifierAffectation }) => {

	const submit = values => {
		if (creation) {
			return creerAffectation(idQuestion, values)
				.then(closePopup)
				.catch(e => {
					throw new SubmissionError(e)
				})
		} else {
			return modifierAffectation(idQuestion, values)
				.then(closePopup)
				.catch(e => {
					throw new SubmissionError(e)
				})
		}
	}

	React.useEffect(() => {
		initialize({
			...affectation,
			score: affectation.score || 1
		})
		return () => initialize({ score: 1 })
	}, [affectation])

	return (
		<Popup
			open={!!affectation.idExamenType}
			actions={[
				<Button
					key="annuler"
					onClick={closePopup}
				>
					Annuler
				</Button>,
				<Button
					key="valider"
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					Enregistrer
				</Button>
			]}
			onExited={reset}
			title="Paramètres de l'affectation"
			content={
				<>
					<Error error={error} isPopinContainer />
					<form onSubmit={handleSubmit}>
						<Field
							name="libelleExamen"
							label="Examen"
							component={Input}
							disabled
						/>
						<Field
							name="dureeExamen"
							label="Durée *"
							component={Input}
							placeholder="mm:ss"
							normalize={normalizeMinutesSecondes}
						/>
						<Field
							name="score"
							label="Score"
							component={Select}
						>
							{[1, 2, 3, 4, 5].map(index =>
								<MenuItem key={index} value={index}>
									{index}
								</MenuItem>
							)}
						</Field>
					</form>
				</>
			}
		/>
	)
}

QuestionAffectationPopup.propTypes = {
	idQuestion: PropTypes.number,
	affectation: questionAffectationShape,
	closePopup: PropTypes.func,
	creation: PropTypes.bool,
	handleSubmit: PropTypes.func,
	reset: PropTypes.func,
	error: PropTypes.array,
	initialize: PropTypes.func,
	creerAffectation: PropTypes.func,
	modifierAffectation: PropTypes.func
}

const mapStateToProps = state => ({
	idQuestion: selectors.getIdQuestion(state)
})

const actions = {
	creerAffectation,
	modifierAffectation
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modifierAffectation',
		validate
	}),
	injectFormSnackbar()
)(QuestionAffectationPopup)
