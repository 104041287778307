import { fetchFactory } from 'isotope-client'
import { ExamenTypeLightBean } from '../../../../customTypings/ExamenTypeLightBean'

export const getListeExamensTypesByUser = (fromExamenTypeListe: boolean) => fetchFactory(`/examensTypes?fromExamenTypeListe=${fromExamenTypeListe}`)

export const getAllExamensTypes = () => fetchFactory('/donneesReference/examensType/all')

export const getListeExamensStandards = () => fetchFactory(`/examensTypes/standards`)

export const getListeExamensSpecifiques = (examenSpecifique: number): Promise<ExamenTypeLightBean[]> => (
	fetchFactory(`/examensTypes/examensSpecifiques/${examenSpecifique}`)
)

export const getExamenType = (idExamenType: number) => fetchFactory(`/examensTypes/${idExamenType}`)

export const getPiecesJustificativesByExamenType = (idExamenType: number) => fetchFactory(
	`/piecesjustificatives/byexamentype?idExamenType=${idExamenType}`
)

export const getPjAbsentes = (idExamenType: number) => fetchFactory(
	`/piecesjustificatives/absentes?idExamenType=${idExamenType}`
)

export const creerPj = (idExamenType: number, values: any) => fetchFactory(`/examensTypes/${idExamenType}/pieces-justificatives`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const toggleObligatoirePj = (idExamenType: number,
                                    idPj: number,
                                    obligatoire: boolean) => fetchFactory(
	`/examensTypes/${idExamenType}/pieces-justificatives/${idPj}/toggle-obligatoire?obligatoire=${obligatoire}`,
	{ method: 'put' }
)

export const toggleActivePj = (idExamenType: number,
                               idPj: number,
                               active: boolean) => fetchFactory(
	`/examensTypes/${idExamenType}/pieces-justificatives/${idPj}/toggle-active?active=${active}`,
	{ method: 'put' }
)

export const creerExamenType = (values: any) => fetchFactory(
	'/examensTypes',
	{
		method: 'post',
		body: JSON.stringify(values)
	}
)

export const saveExamenType = (values: any) => fetchFactory(
	`/examensTypes/${values.id}`,
	{
		method: 'put',
		body: JSON.stringify(values)
	}
)

export const exploiterExamenType = (values: any) => fetchFactory(
	`/examensTypes/${values.id}/exploiter`,
	{
		method: 'put',
		body: JSON.stringify(values)
	}
)

export const activerExamenType = (idExamenType: number) => fetchFactory(
	`/examensTypes/${idExamenType}/activer`,
	{ method: 'put' }
)

export const desactiverExamenType = (idExamenType: number) => fetchFactory(
	`/examensTypes/${idExamenType}/desactiver`,
	{
		method: 'put',
		body: idExamenType
	}
)

export const supprimerExamenType = (idExamenType: number) => fetchFactory(
	`/examensTypes/${idExamenType}`,
	{ method: 'delete' }
)

export const deleteAssociationFormation = (idExamenType: number,
                                           idFormation: number) => fetchFactory(
	`/examensTypes/${idExamenType}/types-formations/${idFormation}`,
	{ method: 'delete' }
)

export const repliquerAffectations = (idExamenType: number,
                                      values: any) => fetchFactory(
	`/examensTypes/${idExamenType}/repliquerAffectations`,
	{
		method: 'put',
		body: JSON.stringify(values)
	}
)
