import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Tab from '@material-ui/core/Tab'
import { injectIntl, intlShape } from 'react-intl'
import { replace } from 'connected-react-router'
import { injectExamenType } from './services/examenTypeInjector'
import { getStyles } from 'isotope-client'
import {
	checkAuthorities,
	EXAMEN_CANDIDAT_ETAT,
	FloatingButton,
	PROFIL_UTILISATEUR,
	Tabs,
	TYPE_EXAMEN
} from '@oceane/ui'
import OngletProprietes from './onglets/OngletProprietes'
import OngletEpreuvesTypes from './onglets/OngletEpreuvesTypes'
import CreerEpreuveTypePopup from './popup/CreerEpreuveTypePopup'
import * as selectors from './services/examensTypesSelectors'
import OngletPiecesJustificatives from './onglets/OngletPiecesJustificatives'
import NouvellePjPopup from './popup/NouvellePjPopup'
import OngletAffectations from './onglets/OngletAffectations'
import OngletAssociationsFormations from './onglets/OngletAssociationsFormations'
import DeleteAssociationFormationPopup from './popup/DeleteAssociationFormationPopup'
import CreerAssociationFormationPopup from './popup/CreerAssociationFormationPopup'
import { EXAMEN_SPECIFIQUE } from '../../../utils/consts'

const styles = () => getStyles({
	tabs: {
		marginBottom: 18
	}
})

const ExamenTypePage = ({ examenType, id, tab, changeTab, intl, classes, typeExamenTypeSelectionne, etatExamenTypeSelectionne }) => {

	const handleChangeTab = (tab) => changeTab(id, tab)

	const [openNouvelleEpreuveType, setOpenNouvelleEpreuveType] = React.useState(false)
	const [openNouvellePj, setOpenNouvellePj] = React.useState(false)
	const [openNouveauTypeFormation, setOpenNouveauTypeFormation] = React.useState(false)
	const [openDeleteAssociation, setOpenDeleteAssociation] = React.useState(false)

	const [idAssociationToDelete, setIdAssociationToDelete] = React.useState(null)
	const [libelleAssociationToDelete, setLibelleAssociationToDelete] = React.useState('')

	const handleOpenNouvelleEpreuveTypePopup = () => setOpenNouvelleEpreuveType(true)
	const handleCloseNouvelleEpreuveTypePopup = () => setOpenNouvelleEpreuveType(false)

	const openDeleteAssociationPopup = (idAssociation, libelleAssociation, open) => {
		setIdAssociationToDelete(idAssociation)
		setLibelleAssociationToDelete(libelleAssociation)
		setOpenDeleteAssociation(open)
	}

	const tabs = [
		<Tab key="proprietes" label={intl.formatMessage({ id: 'references.examensTypes.tabs.proprietes' })} />,
		<Tab key="epreuvesTypes" label={intl.formatMessage({ id: 'references.examensTypes.tabs.epreuvesTypes' })} />
	]
	const tabViews = [<OngletProprietes />, <OngletEpreuvesTypes />]

	if (examenType.type === TYPE_EXAMEN.STANDARD) {
		tabs.push(<Tab key="affectations" label={intl.formatMessage({ id: 'references.examensTypes.tabs.affectations' })} />)
		tabViews.push(<OngletAffectations />)
	} else if (examenType.type === TYPE_EXAMEN.PRATIQUE) {
		tabs.push(
			<Tab key="assoFormations" label={intl.formatMessage({ id: 'references.examensTypes.tabs.associationsFormations' })} />,
		)
		tabViews.push(
			<OngletAssociationsFormations openPopupDelete={openDeleteAssociationPopup} />
		)
	}

	if (
		examenType.type === TYPE_EXAMEN.PRATIQUE
		|| examenType.examenSpecifique === EXAMEN_SPECIFIQUE.CATS
	) {
		tabs.push(<Tab key="piecesJustificatives" label={intl.formatMessage({ id: 'references.examensTypes.tabs.piecesJustificatives' })} />)
		tabViews.push(<OngletPiecesJustificatives />)
	}

	return <React.Fragment>
		<Tabs
			initialTab={tab}
			tabs={tabs}
			tabViews={tabViews}
			onChangeTab={handleChangeTab}
		/>
		{etatExamenTypeSelectionne === EXAMEN_CANDIDAT_ETAT.CREE && tab === 1 && <React.Fragment>
			<FloatingButton onClick={handleOpenNouvelleEpreuveTypePopup} />
			<CreerEpreuveTypePopup
				open={openNouvelleEpreuveType}
				closePopup={handleCloseNouvelleEpreuveTypePopup}
				type={typeExamenTypeSelectionne}
			/>
		</React.Fragment>}
		{examenType.type === TYPE_EXAMEN.PRATIQUE && tab === 2 && <React.Fragment>
			<DeleteAssociationFormationPopup
				open={openDeleteAssociation}
				closePopup={() => setOpenDeleteAssociation(false)}
				idAssociation={idAssociationToDelete}
				libelleAssociation={libelleAssociationToDelete}
				idExamenType={id}
			/>
			<CreerAssociationFormationPopup
				open={openNouveauTypeFormation}
				closePopup={() => setOpenNouveauTypeFormation(false)}
				idExamenType={id}
			/>
			<FloatingButton onClick={() => setOpenNouveauTypeFormation(true)} />
		</React.Fragment>}
		{
			(
				examenType.type === TYPE_EXAMEN.PRATIQUE
				|| examenType.examenSpecifique === EXAMEN_SPECIFIQUE.CATS
			)
			&& tab === 3 && (
				<React.Fragment>
					<FloatingButton onClick={() => setOpenNouvellePj(true)} />
					<NouvellePjPopup
						open={openNouvellePj}
						closePopup={() => setOpenNouvellePj(false)}
					/>
				</React.Fragment>
			)
		}
	</React.Fragment>
}

ExamenTypePage.propTypes = {
	examenType: PropTypes.object,
	id: PropTypes.number,
	tab: PropTypes.number.isRequired,
	intl: intlShape,
	classes: PropTypes.object,
	etatExamenTypeSelectionne: PropTypes.number,
	typeExamenTypeSelectionne: PropTypes.number,
	changeTab: PropTypes.func
}

ExamenTypePage.defaultProps = {
	tab: 0
}

const mapStateToProps = (state, ownProps) => {
	const examenType = selectors.getExamenType(state)
	return {
		id: Number(ownProps.match.params.id),
		tab: ownProps.match.params.tab ? Number(ownProps.match.params.tab) : 0,
		etatExamenTypeSelectionne: examenType.etat,
		typeExamenTypeSelectionne: examenType.type
	}
}

const actions = {
	changeTab: (id, tab) => dispatch => dispatch(replace(`/examens-types/${id}/${tab}`))
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectIntl,
	injectExamenType,
	connect(mapStateToProps, actions),
	withStyles(styles)
)(ExamenTypePage)
