import { combineReducers } from 'redux'
import * as actions from './nouvelleInscriptionActions'

const open = (state = false, action) => {
	switch (action.type) {
		case actions.OPEN_POPUP:
			return true
		case actions.CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const initialContent = {
	typeInscription: 0, 	  // Code du type d'inscription (conditionne le formulaire et le titre de la popup)
	sessionsInitialisees: [], // Liste des sessions initialisées pour remplir le sélecteur pour nouvelle inscr. papier
}
const content = (state = initialContent, action) => {
	switch (action.type) {
		case actions.OPEN_POPUP:
			return {
				...state,
				typeInscription: action.typeInscription
			}
		case actions.GET_SESSIONS_SUCCESS:
			return {
				...state,
				sessionsInitialisees: action.listeSessions
			}
		default:
			return state
	}
}

export default combineReducers({
	open,
	content
})