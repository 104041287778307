import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getSessionsList, cleanSessionsList } from './sessionsActions'
import * as selectors from './sessionsSelectors'


export const injectSessionsList = (...etats) => (WrappedComponent) => {
	const ListeSessionsInjector = ({ listesSessions, getSessionsList, cleanSessionsList, ...props }) => {

		React.useEffect(() => {
			etats.forEach(etat => {
				if (!listesSessions[etat] || !listesSessions[etat].length) {
					getSessionsList(etat)
				}
			})
			return () => {
				etats.forEach(etat => cleanSessionsList(etat))
			}
		}, [])

		return <WrappedComponent {...props} />
	}

	const mapStateToProps = state => ({
		listesSessions: etats.map(etat => selectors.getListeParEtat(etat)(state))
	})

	const actions = {
		getSessionsList,
		cleanSessionsList
	}

	ListeSessionsInjector.propTypes = {
		getSessionList: PropTypes.func,
		cleanSessionList: PropTypes.func,
		listesSessions: PropTypes.array
	}

	return connect(mapStateToProps, actions)(ListeSessionsInjector)
}
