import * as api from './statistiquesQuestionsApi'

export const GET_LISTE_QUESTIONS_REQUEST = 'STATISTIQUES/GET_LISTE_QUESTIONS_REQUEST'
export const GET_LISTE_QUESTIONS_SUCCESS = 'STATISTIQUES/GET_LISTE_QUESTIONS_SUCCESS'
export const GET_LISTE_QUESTIONS_ERROR = 'STATISTIQUES/GET_LISTE_QUESTIONS_ERROR'
export const getListeQuestions = filtres => dispatch => {
	dispatch({ type: GET_LISTE_QUESTIONS_REQUEST })
	return api.getListeQuestions(filtres)
		.then(result => dispatch({
			type: GET_LISTE_QUESTIONS_SUCCESS,
			result,
			filtres
		}))
		.catch(e => {
			dispatch({ type: GET_LISTE_QUESTIONS_ERROR })
			throw e
		})
}
