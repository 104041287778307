import { fetchFactory } from 'isotope-client'

export const getEpreuvesExamenCandidat = (idCandidat, idExamenCandidat) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/epreuves`
)

export const getComptesClients = () => fetchFactory('/comptesClients/all')

export const getListeDesOrganismesDeFormationActives = () => fetchFactory('/organismes/actives')

export const getOrganismesExamenType = (idExamenType) => fetchFactory(`/formations-organisme/byexamentype/organismes?idExamenType=${idExamenType}`)

export const getTypesFormations = (idOrganisme, idExamenType) => fetchFactory(`/typesFormation/byorganismeandexamentype?idOrganisme=${idOrganisme}&idExamenType=${idExamenType}`)

export const toggleEquivalenceEpreuve = (idExamenCandidat, idEpreuve, checked, idCandidat) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/epreuves/${idEpreuve}/toggle-equivalence?equivalence=${checked}`, {
	method: 'put'
})

export const modifierEpreuveExamenCandidat = (idExamenCandidat, idCandidat, values) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/epreuves/modifier`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const autoriserEpreuveExamenCandidat = (idExamenCandidat, idEpreuve, idCandidat) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/epreuves/${idEpreuve}/autoriser`, {
	method: 'put'
})

export const refuserEpreuveExamenCandidat = (idExamenCandidat, idEpreuve, idCandidat) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/epreuves/${idEpreuve}/refuser`, {
	method: 'put'
})

export const modifierCompteClient = (idExamenCandidat, idCandidat, idCompteClient) => fetchFactory(
	`/candidats/${idCandidat}/examens/${idExamenCandidat}/compte-client?compteClient=${idCompteClient}`, {
	method: 'put'
})