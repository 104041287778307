import Fab from '@material-ui/core/Fab'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import { checkAuthorities, EmptyResult, hasAccess, injectSession, ListFiltres, PROFIL_UTILISATEUR } from '@oceane/ui'
import { getStyles, injectToolbarData, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import NouvelOrganismePopup from './NouvelOrganismePopup'
import { openPopup as openPopupNouvelOrganisme } from './services/popup/nouvelOrganismePopupActions'
import { push } from 'connected-react-router'
import * as organismeActions from './services/organismeActions'
import * as organismeSelectors from './services/organismeSelectors'

const style = () => getStyles({
	fab: {
		position: 'fixed',
		right: 10,
		bottom: 20
	},
	organismeColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

class OrganismesPage extends Component {

	render() {
		const {
			classes,
			openPopupNouvelOrganisme,
			profil,
			goToDetails,
			handleQuery,
			resetQuery,
			searchValue,
			switchValue
		} = this.props

		if (!hasAccess(PROFILS_LOGISTIQUE, profil)) {
			return null
		} else {
			return (
				<React.Fragment>
					<ListFiltres
						handleQueryValue={handleQuery}
						resetQueryValue={resetQuery}
						searchValue={searchValue}
						switchValue={switchValue}
						searchPlaceholderId="references.organismes.search"
						switchDescriptionId="references.organismes.switch"
					/>
					<SpringDataTable
						apiUrl="/organismes"
						headers={[
							{
								key: 'libelleLong',
								name: <FormattedMessage id='references.organismes.form.organisme'/>,
								sortable: true,
								render: data => <Typography className={classes.organismeColumn} onClick={() => goToDetails(data.id)}>{data.libelleLong}</Typography>
							},
							{
								key: 'etat',
								name: <FormattedMessage id='references.organismes.form.etat'/>,
								sortable: true,
								render: data => <Typography><FormattedMessage
									id={`references.organismes.form.${data.etat}`}/></Typography>
							},
						]}
						nom="organismes"
						noResultFragment={<EmptyResult/>}
						filters={{
							libelle: searchValue,
							disabled: switchValue
						}}
						defaultPageSize={50}
					/>
					<Fab color="primary" aria-label="Add" className={classes.fab}
						 onClick={openPopupNouvelOrganisme}>
						<AddIcon/>
					</Fab>
					<NouvelOrganismePopup/>
				</React.Fragment>
			)
		}
	}
}

const mapStateToProps = state => ({
	searchValue: organismeSelectors.getSearchValue(state),
	switchValue: organismeSelectors.getSwitchValue(state)
})

const actions = {
	openPopupNouvelOrganisme,
	goToDetails: (organismeId) => dispatch =>  dispatch(push(`/organismes/${organismeId}`)),
	handleQuery: organismeActions.handleQuery,
	resetQuery: organismeActions.resetQuery
}

OrganismesPage.propTypes = {
	profil: PropTypes.number,
	openPopupNouvelOrganisme: PropTypes.func,
	goToDetails: PropTypes.func.isRequired
}

export default compose(
	injectToolbarData({ title: { id: 'references.organismes.label' } }),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	withStyles(style),
	injectSession
)(OrganismesPage)
