import * as api from './importKSAApi'

export const GET_LISTE_IMPORTS_PROGRAMMES_REQUEST = '100KSA/GET_LISTE_IMPORTS_PROGRAMMES_REQUEST'
export const GET_LISTE_IMPORTS_PROGRAMMES_SUCCESS = '100KSA/GET_LISTE_IMPORTS_PROGRAMMES_SUCCESS'
export const GET_LISTE_IMPORTS_PROGRAMMES_ERROR = '100KSA/GET_LISTE_IMPORTS_PROGRAMMES_ERROR'
export const getListeImportsProgrammes = () => dispatch => {
	dispatch({ type: GET_LISTE_IMPORTS_PROGRAMMES_REQUEST })
	return api.getListeImportsProgrammes()
		.then(importsProgrammes => dispatch({
			type: GET_LISTE_IMPORTS_PROGRAMMES_SUCCESS,
			importsProgrammes
		}))
		.catch(e => { 
			dispatch({
				type: GET_LISTE_IMPORTS_PROGRAMMES_ERROR,
				e
			})
			throw e
		})
}

export const GET_LISTE_DERNIERS_IMPORTS_REQUEST = '100KSA/GET_LISTE_DERNIERS_IMPORTS_REQUEST'
export const GET_LISTE_DERNIERS_IMPORTS_SUCCESS = '100KSA/GET_LISTE_DERNIERS_IMPORTS_SUCCESS'
export const GET_LISTE_DERNIERS_IMPORTS_ERROR = '100KSA/GET_LISTE_DERNIERS_IMPORTS_ERROR'
export const getListeDerniersImports = () => dispatch => {
	dispatch({ type: GET_LISTE_DERNIERS_IMPORTS_REQUEST })
	return api.getListeDerniersImports()
		.then(derniersImports => dispatch({
			type: GET_LISTE_DERNIERS_IMPORTS_SUCCESS,
			derniersImports
		}))
		.catch(e => {
			dispatch({
				type: GET_LISTE_DERNIERS_IMPORTS_ERROR,
				e
			})
			throw e
		})
}

export const CREER_IMPORT_REQUEST = '100KSA/CREER_IMPORT_REQUEST'
export const CREER_IMPORT_SUCCESS = '100KSA/CREER_IMPORT_SUCCESS'
export const CREER_IMPORT_ERROR = '100KSA/CREER_IMPORT_ERROR'
export const creerImport = values => dispatch => {
	dispatch({ type: CREER_IMPORT_REQUEST })
	return api.creerImport(values)
		.then(() => dispatch({ type: CREER_IMPORT_SUCCESS }))
		.catch(e => {
			dispatch({ type: CREER_IMPORT_ERROR })
			throw e
		})
}

export const SUPPRIMER_IMPORT_REQUEST = '100KSA/SUPPRIMER_IMPORT_REQUEST'
export const SUPPRIMER_IMPORT_SUCCESS = '100KSA/SUPPRIMER_IMPORT_SUCCESS'
export const SUPPRIMER_IMPORT_ERROR = '100KSA/SUPPRIMER_IMPORT_ERROR'
export const supprimerImport = id => dispatch => {
	dispatch({ type: SUPPRIMER_IMPORT_REQUEST })
	return api.supprimerImport(id)
		.then(() => dispatch({ type: SUPPRIMER_IMPORT_SUCCESS }))
		.catch(e => {
			dispatch({ type: SUPPRIMER_IMPORT_ERROR })
			throw e
		})
}