import * as api from './examenPratiqueOrganismeApi'

export const deleteFormationOrganisme = (id) => dispatch => {
	return api.deleteFormationOrganisme(id)
		.catch((e) => {
			throw e
		})
}

export const OPEN_POPUP = 'EXAMEN_PRATIQUE_ORGANISME/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'EXAMEN_PRATIQUE_ORGANISME/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})
export const OPEN_POPUP_CREATION = 'EXAMEN_PRATIQUE_ORGANISME/OPEN_POPUP_CREATION'
export const openPopupCreation = () => dispatch => dispatch({
	type: OPEN_POPUP_CREATION
})

export const CLOSE_POPUP_CREATION = 'EXAMEN_PRATIQUE_ORGANISME/CLOSE_POPUP_CREATION'
export const closePopupCreation = () => dispatch => dispatch({
	type: CLOSE_POPUP_CREATION
})

export const EXAMENS_TYPE_PRATIQUE = 'DETAIL_ORGANISME/EXAMENS_TYPE_PRATIQUE'
export const getExamensTypePratique = () => dispatch => {
	return api.getExamensTypePratique()
		.then((result) => {
			dispatch({
				type: EXAMENS_TYPE_PRATIQUE,
				payload: result
			})
			return result
		})
}

export const FORMATION_TYPE_PRATIQUE = 'DETAIL_ORGANISME/FORMATION_TYPE_PRATIQUE'
export const getFormationTypePratique = (examenTypeId, organismeId) => dispatch => {
	return api.getFormationTypePratique(examenTypeId, organismeId)
		.then((result) => {
			dispatch({
				type: FORMATION_TYPE_PRATIQUE,
				payload: result
			})
		})
}

export const createFormationOrganisme = (values, organismeId) => dispatch => {
	return api.createFormationOrganisme(values, organismeId)
}