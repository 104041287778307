import * as api from './typesFormationApi'

export const CREER_TYPE_FORMATION_REQUEST = 'CREER_TYPE_FORMATION_REQUEST'
export const CREER_TYPE_FORMATION_SUCCESS = 'CREER_TYPE_FORMATION_SUCCESS'
export const CREER_TYPE_FORMATION_ERROR = 'CREER_TYPE_FORMATION_ERROR'
export const creerTypeFormation = (values) => dispatch => {
	dispatch({ type: CREER_TYPE_FORMATION_REQUEST })
	return api.creerTypeFormation(values)
		.then(result => {
			dispatch({
				type: CREER_TYPE_FORMATION_SUCCESS,
				result
			})
			return result.id
		})
		.catch(e => {
			dispatch({ type: CREER_TYPE_FORMATION_ERROR })
			throw e
		})
}

export const GET_LISTE_TYPES_FORMATION_REQUEST = 'GET_LISTE_TYPES_FORMATION_REQUEST'
export const GET_LISTE_TYPES_FORMATION_SUCCESS = 'GET_LISTE_TYPES_FORMATION_SUCCESS'
export const GET_LISTE_TYPES_FORMATION_ERROR = 'GET_LISTE_TYPES_FORMATION_ERROR'
export const getListeTypesFormation = () => dispatch => {
	dispatch({ type: GET_LISTE_TYPES_FORMATION_REQUEST })
	return api.getListeTypesFormation()
		.then(result => dispatch({
			type: GET_LISTE_TYPES_FORMATION_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_LISTE_TYPES_FORMATION_ERROR })
			throw e
		})
}

export const GET_TYPE_FORMATION_REQUEST = 'GET_TYPE_FORMATION_REQUEST'
export const GET_TYPE_FORMATION_SUCCESS = 'GET_TYPE_FORMATION_SUCCESS'
export const GET_TYPE_FORMATION_ERROR = 'GET_TYPE_FORMATION_ERROR'
export const getTypeFormation = id => dispatch => {
	dispatch({ type: GET_TYPE_FORMATION_REQUEST })
	return api.getTypeFormation(id)
		.then(result => dispatch({
			type: GET_TYPE_FORMATION_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_TYPE_FORMATION_ERROR })
			throw e
		})
}

export const SAVE_TYPE_FORMATION_REQUEST = 'SAVE_TYPE_FORMATION_REQUEST'
export const SAVE_TYPE_FORMATION_SUCCESS = 'SAVE_TYPE_FORMATION_SUCCESS'
export const SAVE_TYPE_FORMATION_ERROR = 'SAVE_TYPE_FORMATION_ERROR'
export const saveTypeFormation = (id, values) => dispatch => {
	dispatch({ type: SAVE_TYPE_FORMATION_REQUEST })
	return api.saveTypeFormation(id, values)
		.then(() => dispatch({ type: SAVE_TYPE_FORMATION_SUCCESS }))
		.catch(e => {
			dispatch({ type: SAVE_TYPE_FORMATION_ERROR })
			throw e
		})
}


export const CHANGE_ETAT_TYPE_FORMATION_REQUEST = 'CHANGE_ETAT_TYPE_FORMATION_REQUEST'
export const CHANGE_ETAT_TYPE_FORMATION_SUCCESS = 'CHANGE_ETAT_TYPE_FORMATION_SUCCESS'
export const CHANGE_ETAT_TYPE_FORMATION_ERROR = 'CHANGE_ETAT_TYPE_FORMATION_ERROR'
export const changeEtatTypeFormation = (id, active) => dispatch => {
	dispatch({ type: CHANGE_ETAT_TYPE_FORMATION_REQUEST })
	const promise = active ? api.desactiverTypeFormation : api.activerTypeFormation
	return promise(id)
		.then(() => dispatch({ type: CHANGE_ETAT_TYPE_FORMATION_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHANGE_ETAT_TYPE_FORMATION_ERROR })
			throw e
		})
}

export const CLEAR_TYPE_FORMATION = 'CLEAR_TYPE_FORMATION'
export const clearTypeFormation = () => dispatch => dispatch({ type: CLEAR_TYPE_FORMATION })
