import React from 'react'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError, clearSubmitErrors } from 'redux-form'
import PropTypes from 'prop-types'
import { Button, Error, Popup, ResponsiveForm, SmallInput, injectFormSnackbar } from '@oceane/ui'
import * as selectors from '../services/scriptSelectors'
import { clearNoeudCourant, creerNoeud, getScript, modifierNoeud } from '../services/scriptActions'
import { getStyles } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import * as epreuveTypeSelectors from '../../epreuveType/services/epreuveTypeSelectors'
import * as shapes from '../../../../config/shapes'
import { VALIDATION_SCRIPT_FORM_NAME } from '../ListeMatieres'

const style = theme => getStyles({
	libelle: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		fontSize: 13,
		marginBottom: 5
	},
	inputContainer: {
		marginBottom: 10
	},
	nbQuestions: {
		marginTop: 5,
		marginLeft: 5,
	}
})

const validate = values => {
	const errors = {}
	if (!values.nbQuestionsATirer) {
		errors.nbQuestionsATirer = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.nbQuestionsATirer)) {
		errors.nbQuestionsATirer = { id: 'global.erreurs.numeric' }
	}
	if (!values.nbQuestionsNouvellesATirer) {
		errors.nbQuestionsNouvellesATirer = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.nbQuestionsNouvellesATirer)) {
		errors.nbQuestionsNouvellesATirer = { id: 'global.erreurs.numeric' }
	}
	if (!values.nbQuestionsJeunesATirer) {
		errors.nbQuestionsJeunesATirer = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.nbQuestionsJeunesATirer)) {
		errors.nbQuestionsJeunesATirer = { id: 'global.erreurs.numeric' }
	}
	return errors
}

const EditionNoeudPopup = (props) => {

	const {
		open,
		noeud,
		closePopup,
		idEpreuveType,
		getScript,
		isExploitation,
		modifierNoeud,
		creerNoeud,
		clearNoeudCourant,
		handleSubmit,
		submitting,
		error,
		reset,
		clearValiderScriptErrors,
		classes
	} = props

	const handleReset = () => {
		reset()
		clearNoeudCourant()
	}

	const submit = values => {
		if (noeud.parametre) {
			return modifierNoeud(noeud.id, values)
				.then(() => {
					getScript(idEpreuveType, noeud.idScript, isExploitation)
					clearValiderScriptErrors()
					closePopup()
				})
				.catch(e => {
					throw new SubmissionError(e)
				})
		} else {
			return creerNoeud(values)
				.then(() => {
					getScript(idEpreuveType, noeud.idScript, isExploitation)
					clearValiderScriptErrors()
					closePopup()
				})
				.catch(e => {
					throw new SubmissionError(e)
				})
		}
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submit)}
			loading={submitting}
		>
			Enregistrer
		</Button>
	]

	const {
		type,
		codeRechercheQuestions,
		nbQuestionsTotal,
		nbQuestionsDisponibles,
		nbQuestionsNouvellesDisponibles,
		nbQuestionsJeunesDisponibles,
		nbQuestionsMaturesATirer,
		nbQuestionsMaturesDisponibles
	} = noeud

	return (
		<Popup
			open={open}
			onExited={handleReset}
			title={`Paramétrage ${type === 1 ? 'topic' : 'paragraphe'} ${codeRechercheQuestions}`}
			actions={actions}
			content={<ResponsiveForm>
				<Error error={error} isPopinContainer />
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Nombre total de questions
					</Typography>
					<Typography className={classes.inputContainer}>{nbQuestionsTotal}</Typography>
				</Grid>
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Nombre de questions disponibles
					</Typography>
					<Typography className={classes.inputContainer}>{nbQuestionsDisponibles}</Typography>
				</Grid>
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Nombre réglementaire de questions à tirer *
					</Typography>
					<Grid item container direction="row" justify="flex-start" alignItems="flex-start">
						<Field
							name="nbQuestionsATirer"
							component={SmallInput}
							inputProps={{
								maxLength: 3
							}}
						/>
						<Typography className={classes.nbQuestions}>{` / ${nbQuestionsDisponibles}`}</Typography>
					</Grid>
				</Grid>
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Dont questions nouvelles *
					</Typography>
					<Grid item container direction="row" justify="flex-start" alignItems="flex-start">
						<Field
							name="nbQuestionsNouvellesATirer"
							component={SmallInput}
							inputProps={{
								maxLength: 3
							}}
						/>
						<Typography className={classes.nbQuestions}>
							{` / ${nbQuestionsNouvellesDisponibles}`}
						</Typography>
					</Grid>
				</Grid>
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Dont questions jeunes *
					</Typography>
					<Grid item container direction="row" justify="flex-start" alignItems="flex-start">
						<Field
							name="nbQuestionsJeunesATirer"
							component={SmallInput}
							inputProps={{
								maxLength: 3
							}}
						/>
						<Typography className={classes.nbQuestions}>{` / ${nbQuestionsJeunesDisponibles}`}</Typography>
					</Grid>
				</Grid>
				<Grid container direction="column" alignItems="flex-start">
					<Typography className={classes.libelle}>
						Dont questions matures *
					</Typography>
					<Typography
						className={classes.inputContainer}>{`${nbQuestionsMaturesATirer || 0} / ${nbQuestionsMaturesDisponibles}`}</Typography>
				</Grid>
			</ResponsiveForm>}
		/>
	)
}


EditionNoeudPopup.propTypes = {
	classes: PropTypes.object,
	noeud: PropTypes.oneOfType([shapes.topicShape, shapes.paragrapheShape]),
	isExploitation: PropTypes.bool,
	idEpreuveType: PropTypes.number,
	initialValues: PropTypes.shape({
		id: PropTypes.number,
		idTopicOuParagraphe: PropTypes.number,
		isExploitation: PropTypes.bool,
		code: PropTypes.string,
		codeRechercheQuestions: PropTypes.string,
		idScript: PropTypes.number,
		nbQuestionsATirer: PropTypes.string,
		nbQuestionsNouvellesATirer: PropTypes.string,
		nbQuestionsJeunesATirer: PropTypes.string,
		nbQuestionsMaturesATirer: PropTypes.string,
		type: PropTypes.number,
	})
}

const mapStateToProps = (state) => {
	const noeudCourant = selectors.getNoeudCourant(state)
	return ({
		noeud: noeudCourant,
		isExploitation: selectors.isExploitation(state),
		idEpreuveType: epreuveTypeSelectors.getIdEpreuveType(state),
		initialValues: {
			id: noeudCourant.id,
			idTopicOuParagraphe: noeudCourant.idTopicOuParagraphe,
			isExploitation: noeudCourant.isExploitation,
			code: noeudCourant.code,
			codeRechercheQuestions: noeudCourant.codeRechercheQuestions,
			idScript: noeudCourant.idScript,
			nbQuestionsATirer: `${noeudCourant.nbQuestionsATirer || 0}`,
			nbQuestionsNouvellesATirer: `${noeudCourant.nbQuestionsNouvellesATirer || 0}`,
			nbQuestionsJeunesATirer: `${noeudCourant.nbQuestionsJeunesATirer || 0}`,
			nbQuestionsMaturesATirer: `${noeudCourant.nbQuestionsMaturesATirer || 0}`,
			type: noeudCourant.type,
		}
	})
}

const actions = {
	clearNoeudCourant,
	creerNoeud,
	modifierNoeud,
	getScript,
	clearValiderScriptErrors: () => dispatch => dispatch(clearSubmitErrors(VALIDATION_SCRIPT_FORM_NAME))
}

export default compose(
	withStyles(style),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'parametrageNoeudScript',
		validate,
		enableReinitialize: true
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(EditionNoeudPopup)
