import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandMore from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import ListeParagraphes from './ListeParagraphes'
import ParametrageButton from './ParametrageButton'
import * as shapes from '../../../../config/shapes'


const style = theme => ({
	button: {
		color: '#000000',
		padding: 5
	},
	nbQuestions: {
		paddingLeft: 5,
		paddingRight: 5,
	},
	icon: {
		transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shortest }),
		transform: 'rotate(-90deg)'
	},
	expanded: {
		transform: 'rotate(0)'
	},
	collapse: {
		width: '100%'
	},
	nonParametrable: {},
	parametrable: {
		color: '#FF0000'
	},
	parametre: {
		fontWeight: 'bold',
		color: theme.palette.primary.main
	}
})

const TopicItem = ({ topic, editable, classes, openPopup }) => {
	const [expanded, setExpanded] = React.useState(false)

	const switchExpand = () => setExpanded(!expanded)

	const classnames = [classes.icon]
	if (expanded) {
		classnames.push(classes.expanded)
	}

	const {
		parametrable,
		nbQuestionsATirer,
		nbQuestionsNouvellesATirer,
		nbQuestionsJeunesATirer,
		nbQuestionsMaturesATirer
	} = topic

	const parametre = nbQuestionsATirer !== null

	let textStyle = classes.nonParametrable
	if (parametrable) {
		if (parametre) {
			textStyle = classes.parametre
		} else {
			textStyle = classes.parametrable
		}
	}

	return <Grid item xs={12} container direction="column">
		<Grid item container direction="row">
			<Grid item xs={5}>
				<IconButton onClick={switchExpand} className={classes.button} disableRipple>
					<ExpandMore className={classNames(classnames)} fontSize="inherit" />
				</IconButton>
				<Typography component="span" inline className={textStyle}>{`Topic ${topic.code}`}</Typography>
			</Grid>
			{parametrable && <Grid item xs={7} container justify="space-between">
				{parametre &&
				<Typography component="span" inline className={classNames(classes.nbQuestions, textStyle)}>
					<FormattedMessage
						id={editable ? 'references.scripts.nbQuestions' : 'references.scripts.nbQuestionsFull'}
						values={{
							nb: nbQuestionsATirer,
							nouvelles: nbQuestionsNouvellesATirer,
							jeunes: nbQuestionsJeunesATirer,
							matures: nbQuestionsMaturesATirer
						}}
					/>
				</Typography>}
				{editable && <ParametrageButton noeud={topic} parametre={parametre} openPopup={openPopup} />}
			</Grid>}
		</Grid>
		<Collapse in={expanded} className={classes.collapse}>
			<Grid item>
				<ListeParagraphes paragraphes={topic.listeParagraphes} editable={editable} openPopup={openPopup} />
			</Grid>
		</Collapse>
	</Grid>
}

TopicItem.propTypes = {
	topic: shapes.topicShape,
	editable: PropTypes.bool,
	classes: PropTypes.object,
	openPopup: PropTypes.func
}

export default compose(
	withStyles(style)
)(TopicItem)
