import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Input, ResponsiveForm, Select } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'


const style = () => getStyles({
	input: {
		marginBottom: 10
	},
	inputSelect: {
		marginBottom: 10,
		paddingTop: 2
	},
	rechercher: {
		marginTop: 15
	}
})

const RechercheSurveillantsForm = ({ submitting, handleSubmit, classes, centres }) => {
	return (<ResponsiveForm onSubmit={handleSubmit}>
		<Grid container justify="space-between" alignItems="flex-start">
			<Field
				name="nom"
				label="Nom"
				component={Input}
				inputStyle={classes.input}
			/>
			<Field
				name="centre"
				label="Centre"
				component={Select}
				inputStyle={classes.inputSelect}
			>
				<MenuItem value=""> </MenuItem>
				{!!centres && centres.length !== 0 && centres.map(value =>
					<MenuItem
						key={value.id}
						value={value.id}
					>
						{value.libelle}
					</MenuItem>
				)}
			</Field>
			<Button
				loading={submitting}
				color="primary"
				variant="contained"
				className={classes.rechercher}
				type="submit"
			>
				Rechercher
			</Button>
		</Grid>
	</ResponsiveForm>)
}

RechercheSurveillantsForm.propTypes = {
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object,
	centres: PropTypes.array
}

export default compose(
	reduxForm({
		form: 'RechercheSurveillant'
	}),
	withStyles(style)
)(RechercheSurveillantsForm)
