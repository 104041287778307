import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import {
	Button,
	checkAuthorities,
	injectSession,
	PROFIL_UTILISATEUR,
	Actions,
	Error,
	DownloadIcon,
	injectFormSnackbar
} from '@oceane/ui'
import {
	getStyles,
	Title,
	injectToolbarData,
	getToolbarData,
	setToolbarData,
	StaticDataTable,
	resetToolbarData
} from 'isotope-client'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { loadSujet } from './services/injectSessions'
import {
	checkAnnexeExist,
	checkAnnexesExist,
	checkGrillesExist,
	checkSujetExist,
	genererSujet,
	supprimerSujet,
	validerEtGenererSeries
} from './services/epreuveSujetAction'
import ConfirmationGenerationPopup from './components/ConfirmationGenerationPopup'
import ConfirmationSuppressionPopup from './components/ConfirmationSuppressionPopup'
import { download } from 'isotope-client/components/download/IsotopeLink'
import Typography from '@material-ui/core/Typography'
import { getFilArianeSujet } from './services/sujetFilAriane'
import ConfirmationValiderGenererSeriesPopup from './components/ConfirmationValiderGenererSeriesPopup'

const EPREUVE_TYPE_CREEE = 1
const EPREUVE_TYPE_GENERE = 2
const EPREUVE_TYPE_VALIDE = 3

const TYPE_SUJET_FRANCAIS = 1
const TYPE_SUJET_ANGLAIS = 2

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const GenererSujet = ({
						  submitting,
						  sujet,
						  match,
						  genererSujet,
						  changeToolbarData,
						  toolbarData,
						  supprimerSujet,
						  classes,
						  checkAnnexeExist,
						  snackError,
						  checkAnnexesExist,
						  validerEtGenererSeries,
						  checkSujetExist,
						  checkGrillesExist,
						  cleanToolbarData
					  }) => {
	const idEpreuveSession = match.params.id
	const [sujetAffiche, setSujetAffiche] = React.useState({})
	const [openValidation, setOpenValidation] = React.useState(false)
	const [openGeneration, setOpenGeneration] = React.useState(false)
	const [openSuppression, setOpenSuppression] = React.useState(false)
	const [customError, setCustomError] = React.useState(null)

	React.useEffect(() => {
		setSujetAffiche(sujet)
		return () => {
			cleanToolbarData(toolbarData.id)
		}
	}, [sujet])

	const generationSujet = () => {
		return genererSujet(idEpreuveSession)
			.then(({ result }) => {
				setOpenGeneration(false)
				setSujetAffiche(result)
				changerToolbar(result)
			})
	}

	const suppressionSujet = () => {
		return supprimerSujet(idEpreuveSession)
			.then(({ result }) => {
				setOpenSuppression(false)
				setSujetAffiche(result)
				changerToolbar(result)
			})
	}

	const changerToolbar = (result) => {
		changeToolbarData(toolbarData.id, getFilArianeSujet(result))
	}

	const downloadAnnexe = (id, nom) => {
		return checkAnnexeExist(id, nom)
			.then(() => {
				download(`/files/sujet/annexe/${id}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const downloadAnnexes = () => {
		return checkAnnexesExist(idEpreuveSession)
			.then(() => {
				download(`/files/sujet/annexes/${idEpreuveSession}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError(e._error)
				snackError('Une erreur est survenue')
			})
	}

	const generationSeries = () => {
		return validerEtGenererSeries(idEpreuveSession)
			.then(({ result }) => {
				setOpenValidation(false)
				setSujetAffiche(result)
				changerToolbar(result)
			})
	}

	const downloadSujet = (type, idSujet, numeroSerie) => {
		return checkSujetExist(idEpreuveSession, type, idSujet, numeroSerie)
			.then(() => {
				download(`/files/sujet/${idEpreuveSession}/${type}/${idSujet}/${numeroSerie}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const downloadGrilles = () => {
		return checkGrillesExist(idEpreuveSession)
			.then(() => {
				download(`/files/sujet/grilles/${idEpreuveSession}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const downloadCorrige = (idSerie) => {
		download(`/files/sujet/corrige/${idEpreuveSession}/${idSerie}`)
	}

	const headersSeries = [{
		key: 'libelle',
		sortable: false,
		render: data => <Typography>Série {data.libelle}</Typography>
	}, {
		key: 'francais',
		name: 'Français',
		render: data => <IconButton onClick={() => downloadSujet(TYPE_SUJET_FRANCAIS, data.id, data.numeroSerie)}>
			<DownloadIcon />
		</IconButton>
	}, {
		key: 'corrige',
		name: 'Corrigé',
		render: data => <IconButton onClick={() => downloadCorrige(data.id)}><DownloadIcon /></IconButton>
	}]

	const headersSeriesBilingue = [{
		key: 'libelle',
		sortable: false,
		render: data => <Typography>Série {data.libelle}</Typography>
	}, {
		key: 'francais',
		name: 'Français',
		render: data => <IconButton onClick={() => downloadSujet(TYPE_SUJET_FRANCAIS, data.id, data.numeroSerie)}>
			<DownloadIcon />
		</IconButton>

	}, {
		key: 'anglais',
		name: 'Anglais',
		render: data => <IconButton onClick={() => downloadSujet(TYPE_SUJET_ANGLAIS, data.id, data.numeroSerie)}>
			<DownloadIcon />
		</IconButton>
	}, {
		key: 'corrige',
		name: 'Corrigé',
		render: data => <IconButton onClick={() => downloadCorrige(data.id)}><DownloadIcon /></IconButton>
	}]

	return (
		<>
			<Error error={customError} />
			<Title value="Sujet" />
			{sujetAffiche.etatEpreuveType === EPREUVE_TYPE_CREEE && <>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={() => setOpenGeneration(true)}
					>
						Générer sujet
					</Button>
				</Actions>
			</>}
			{sujetAffiche.etatEpreuveType !== EPREUVE_TYPE_CREEE && <>
				<Grid container justify="space-between">
					<Grid item>
						Annexes : {sujetAffiche.nbAnnexes}
					</Grid>
					<Grid item>
						Points : {sujetAffiche.nbPoints}
					</Grid>
					<Grid item>
						Questions : {sujetAffiche.nbQuestions}
					</Grid>
					<Grid item>
						Difficulté : {sujetAffiche.difficulte}
					</Grid>
					<Grid item>
						Durée : {sujetAffiche.duree}
					</Grid>
				</Grid>
				<Actions>
					{sujetAffiche.etatEpreuveType === EPREUVE_TYPE_GENERE && <>
						<Button
							loading={submitting}
							color="primary"
							onClick={() => setOpenSuppression(true)}
						>
							Supprimer sujet
						</Button>
						<Button
							loading={submitting}
							color="primary"
							onClick={() => setOpenGeneration(true)}
						>
							Re-générer
						</Button>
					</>}
					<Button
						loading={submitting}
						color="primary"
						onClick={() => download(`/files/sujet/listerQuestions/${idEpreuveSession}`)}
					>
						Lister questions
					</Button>
					{sujetAffiche.nbAnnexes > 0 && <Button
						loading={submitting}
						color="primary"
						onClick={downloadAnnexes}
					>
						Afficher annexes
					</Button>}
					<Button
						loading={submitting}
						color="primary"
						onClick={() => download(`/files/sujet/afficherSujet/${idEpreuveSession}`)}
					>
						Afficher sujet
					</Button>
				</Actions>
				{sujetAffiche.nbAnnexes > 0 && <>
					<Title value="Annexes" />
					<StaticDataTable
						data={sujetAffiche.listeAnnexes}
						headers={[{
							key: 'nom',
							name: 'Nom',
							sortable: false,
							render: data => <Typography className={classes.libelleColumn}
														onClick={() => downloadAnnexe(data.id, data.nom)}>{data.nom}</Typography>

						}]}
					/>
				</>}
				{sujetAffiche.etatEpreuveType !== EPREUVE_TYPE_VALIDE && <Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={() => setOpenValidation(true)}
					>
						Valider et générer séries
					</Button>
				</Actions>}
				{(sujetAffiche.listeSeries && sujetAffiche.listeSeries.length > 0) && <>
					<Title value="Séries" />
					{sujetAffiche.bilingue && <StaticDataTable
						data={sujetAffiche.listeSeries}
						headers={headersSeriesBilingue}
					/>}
					{sujetAffiche.bilingue === false && <StaticDataTable
						data={sujetAffiche.listeSeries}
						headers={headersSeries}
					/>}
					<Actions>
						<Button
							loading={submitting}
							color="primary"
							onClick={downloadGrilles}
						>
							Grilles
						</Button>
					</Actions>
				</>}
			</>}
			<ConfirmationValiderGenererSeriesPopup open={openValidation} onClose={() => setOpenValidation(false)}
												   valider={generationSeries} />
			<ConfirmationGenerationPopup open={openGeneration} onClose={() => setOpenGeneration(false)}
										 generer={generationSujet} />
			<ConfirmationSuppressionPopup open={openSuppression} onClose={() => setOpenSuppression(false)}
										  supprimer={suppressionSujet} />
		</>
	)
}

const actions = {
	genererSujet,
	supprimerSujet,
	changeToolbarData: setToolbarData,
	cleanToolbarData: resetToolbarData,
	checkAnnexeExist,
	checkAnnexesExist,
	validerEtGenererSeries,
	checkSujetExist,
	checkGrillesExist
}

GenererSujet.propTypes = {
	genererSujet: PropTypes.func,
	supprimerSujet: PropTypes.func,
	changeToolbarData: PropTypes.func,
	toolbarData: PropTypes.object,
	checkAnnexeExist: PropTypes.func,
	checkAnnexesExist: PropTypes.func,
	validerEtGenererSeries: PropTypes.func,
	checkSujetExist: PropTypes.func,
	checkGrillesExist: PropTypes.func
}

const mapStateToProps = state => ({
	toolbarData: getToolbarData(state)
})

export default compose(
	loadSujet,
	connect(mapStateToProps, actions),
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectSession,
	withStyles(style),
	injectFormSnackbar(),
	injectToolbarData(({ sujet }) => getFilArianeSujet(sujet))
)(GenererSujet)
