import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = createSelector(
	getReferences,
	references => references.annexes
)

export const getAnnexe = createSelector(
	getLocalState,
	state => state.annexe
)

const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)
