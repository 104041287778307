import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles, Title } from 'isotope-client'
import { Button, checkAuthorities, Error, PROFIL_UTILISATEUR, SmallInput } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import { rechercherInscription } from './services/gestionCopiesActions'
import ResultatRechercheInscription from './ResultatRechercheInscription'

const styles = theme => getStyles({
	acces: {
		padding: '11px 0px'
	},
	input: {
		margin: 0
	},
	button: {
		marginTop: theme.spacing.unit * 2.0,
		marginLeft: 30
	}
})

const validate = values => {
	const errors = {}
	if (!values.numeroInscription) {
		errors.numeroInscription = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.numeroInscription)) {
		errors.numeroInscription = { id: 'global.erreurs.numeric' }
	}
	return errors
}

const RechercheInscriptionPage = ({ rechercherInscription, handleSubmit, error, classes }) => {

	const submitForm = values => rechercherInscription(values)
		.catch(e => {
			if (e.bodyError) {
				throw new SubmissionError({ _error: e.bodyError })
			}
		})

	return (
		<>
			<Error error={error} />
			<Title value="Recherche" />
			<Grid container justify="space-between" alignItems="flex-start" className={classes.acces}>
				<Grid item xs={10}>
					<Field
						name="numeroInscription"
						label="N° d'inscription *"
						component={SmallInput}
						inputStyle={classes.input}
						inputProps={{
							maxLength: 10
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Button
						onClick={handleSubmit(submitForm)}
						variant="contained"
						className={classes.button}
					>
						Rechercher
					</Button>
				</Grid>
			</Grid>
			<ResultatRechercheInscription />
		</>
	)
}


RechercheInscriptionPage.propTypes = {
	classes: PropTypes.object
}

const actions = {
	rechercherInscription
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	withStyles(styles),
	connect(undefined, actions),
	reduxForm({
		form: 'gestionCopiesRechercheInscription',
		validate,
		destroyOnUnmount: false
	})
)(RechercheInscriptionPage)
