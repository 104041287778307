export const PROFILS = [
	{ id: 1, nom : "Administrateur" },
	{ id: 2, nom: "Gestionnaire logistique des examens" },
	{ id: 3, nom: "Responsable logistique" },
	{ id: 4, nom: "Superviseur de site écran" },
	{ id: 5, nom: "Gestionnaire de la banque de questions" },
	{ id: 6, nom: "Responsable de banque" },
	{ id: 7, nom: "Gestionnaire licences" },
	{ id: 8, nom: "Organisme de formation" }
]

export const UPDATE_PROFIL_IDS = [2, 4, 5, 6, 8]

export const RDB = {
	'1': 'Avion',
	'2': 'Helicoptère',
	'3': 'Mixte'
}

export const UTILISATEUR_FORM_NAME = 'UtilisateurForm'
