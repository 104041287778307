import Tab from '@material-ui/core/Tab'
import {
	checkAuthorities,
	EXAMEN_SPECIFIQUE,
	examenCandidatShape,
	getFilArianeExamen,
	injectProfil,
	injectSelectedExam,
	injectSession,
	PROFIL_UTILISATEUR,
	Tabs
} from '@oceane/ui'
import { replace } from 'connected-react-router'
import { getToolbarData, injectToolbarData, setToolbarData } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ActionsAvanceesTab from './components/ActionsAvancees'
import FormationsEquivalencesTab from './components/FormationsEquivalences'
import InscriptionsTab from './components/Inscriptions'
import PiecesJustificativesExamenCandidat from '../piecesJustificatives/components/PiecesJustificativesExamenCandidat'

const TAB_FORMATIONS_EQUIVALENCES = 0
const TAB_INSCRIPTIONS = 1
const TAB_PIECES_JUSTIFICATIVES = 2
const TAB_ACTIONS_AVANCEES = 3
const URL_TAB_FORMATIONS_EQUIVALENCES = 'formations-equivalences'
const URL_TAB_INSCRIPTIONS = 'inscriptions'
const URL_TAB_PIECES_JUSTIFICATIVES = 'pieces-justificatives'
const URL_TAB_ACTIONS_AVANCEES = 'actions-avancees'

const TABS_DETAIL_EXAMEN_PAGE_ID_BY_URL = {
	[URL_TAB_FORMATIONS_EQUIVALENCES]: TAB_FORMATIONS_EQUIVALENCES,
	[URL_TAB_INSCRIPTIONS]: TAB_INSCRIPTIONS,
	[URL_TAB_PIECES_JUSTIFICATIVES]: TAB_PIECES_JUSTIFICATIVES,
	[URL_TAB_ACTIONS_AVANCEES]: TAB_ACTIONS_AVANCEES,
}

const TABS_DETAIL_EXAMEN_PAGE_URL_BY_ID = {
	[TAB_FORMATIONS_EQUIVALENCES]: URL_TAB_FORMATIONS_EQUIVALENCES,
	[TAB_INSCRIPTIONS]: URL_TAB_INSCRIPTIONS,
	[TAB_PIECES_JUSTIFICATIVES]: URL_TAB_PIECES_JUSTIFICATIVES,
	[TAB_ACTIONS_AVANCEES]: URL_TAB_ACTIONS_AVANCEES,
}

const DetailExamenPage = ({
	intl,
	examenSelectionne,
	etatCivil,
	profil,
	toolbarData,
	setToolbarData,
	tab,
	switchTab
}) => {
	React.useEffect(() => {
		setToolbarData(toolbarData.id, getFilArianeExamen(
			etatCivil.id,
			etatCivil.numeroOceane,
			etatCivil.nomNaissance,
			etatCivil.prenomPrincipal,
			examenSelectionne.examenTypeLibelle,
			examenSelectionne.etat,
			profil !== PROFIL_UTILISATEUR.GESTIONNAIRE_EXAMENS_PRATIQUES.id)
		)
	}, [examenSelectionne.etat])

	const tabIndexes = [
		TAB_FORMATIONS_EQUIVALENCES,
		TAB_INSCRIPTIONS,
		// RG2879/63626
		examenSelectionne.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS ? TAB_PIECES_JUSTIFICATIVES : undefined,
		TAB_ACTIONS_AVANCEES,
	].filter(tab => tab !== undefined)

	const handleChangeTab = (selectedTab) => {
		switchTab(etatCivil.id, examenSelectionne.id, TABS_DETAIL_EXAMEN_PAGE_URL_BY_ID[tabIndexes[selectedTab]] || '')
	}

	return (
		<>
			<Tabs
				initialTab={tab}
				tabs={tabIndexes.map(tabIndex => <Tab key={tabIndex} label={intl.formatMessage({ id: `candidats.profil.examens.inscriptions.tabs.${tabIndex}` })} />)}
				onChangeTab={handleChangeTab}
				tabViews={[
					<FormationsEquivalencesTab examenSelectionne={examenSelectionne} />,
					<InscriptionsTab examenSelectionne={examenSelectionne} />,
					examenSelectionne.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS ?
						<PiecesJustificativesExamenCandidat examenSelectionne={examenSelectionne} />
						: undefined,
					<ActionsAvanceesTab examenSelectionne={examenSelectionne} />
				].filter(tab => tab !== undefined)}
			/>
		</>
	)
}

DetailExamenPage.propTypes = {
	intl: intlShape.isRequired,
	etatCivil: PropTypes.object.isRequired,
	examenSelectionne: examenCandidatShape.isRequired,
	openPopupNouvelleInscriptionAction: PropTypes.func,
	listeInscriptions: PropTypes.array,
	tab: PropTypes.number,
	switchTab: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
	toolbarData: getToolbarData(state),
	tab: TABS_DETAIL_EXAMEN_PAGE_ID_BY_URL[ownProps.match.params.tab] || TAB_FORMATIONS_EQUIVALENCES,
})

const actions = {
	setToolbarData,
	switchTab: (idCandidat, idExamenCandidat, tab) => dispatch => dispatch(replace(`/candidats/${idCandidat}/examens/${idExamenCandidat}/${tab}`))
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectProfil,
	injectSelectedExam,
	injectSession,
	injectToolbarData(({
		                   etatCivil,
		                   profil,
		                   examenSelectionne: { examenTypeLibelle, etat }
	                   }) => getFilArianeExamen(
		etatCivil.id,
		etatCivil.numeroOceane,
		etatCivil.nomNaissance,
		etatCivil.prenomPrincipal,
		examenTypeLibelle,
		etat,
		profil !== PROFIL_UTILISATEUR.GESTIONNAIRE_EXAMENS_PRATIQUES.id
	)),
	connect(mapStateToProps, actions),
	injectIntl
)(DetailExamenPage)
