import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDetailsMatiere } from './ligneProgrammeAction'
import * as selector from './ligneProgrammeSelectors'
import { Loader } from '@oceane/ui'

export const injectDetailMatiereWithIdMatiere = WrappedComponent => {

	const injectDetailMatiereWithIdMatiere = ({ getDetailsMatiere, ...otherProps }) => {
		const idMatiere = otherProps.match.params.idMatiere

		const [load, setload] = React.useState(false)

		React.useEffect(() => {
			setload(true)
			getDetailsMatiere(idMatiere)
				.then(() => setload(false))
				.catch(() => setload(false))
		}, [])

		return load ? <Loader /> : <WrappedComponent {...otherProps} />
	}

	injectDetailMatiereWithIdMatiere.propTypes = {
		detailsMatiere: PropTypes.object
	}


	const mapStateToProps = state => ({
		detailsMatiere: selector.getDetailMatiere(state)
	})

	const actions = {
		getDetailsMatiere
	}

	return connect(mapStateToProps, actions)(injectDetailMatiereWithIdMatiere)
}
