import { Button, Input, Popup } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { EDIT_ANNEXE_FORM, postAnnexe } from '../services/annexeAction'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}
	if (!values.numero) {
		errors.numero = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const NouvelleAnnexePopup = ({ open, closePopup, idMatiere, handleSubmit, goToModificationPage, code, postAnnexe }) => {

	const submitCreateAnnexe = (values) =>
		postAnnexe(values, idMatiere)
			.then((annexe) => {
				closePopup()
				goToModificationPage(idMatiere, code, annexe)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateAnnexe)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouvelle annexe dans la matière"
			content={<form>
				<Field
					id="numero"
					name="numero"
					label="Numéro *"
					component={Input}
					inputProps={{
						maxLength: 30
					}}
				/>
			</form>}
		/>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('listeAnnexe'),
	postAnnexe,
	goToModificationPage: (idMatiere, code, annexe) => dispatch => dispatch(push({
		pathname: `/annexes/${idMatiere}/nouvelle-annexe`,
		state: { code: code, annexe: annexe }
	}))
}

NouvelleAnnexePopup.propTypes = {
	idMatiere: PropTypes.string,
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	refreshTable: PropTypes.func.isRequired,
	postAnnexe: PropTypes.func
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: EDIT_ANNEXE_FORM,
		initialValues: {
			numero: ''
		},
		destroyOnUnmount: false,
		validate
	})
)(NouvelleAnnexePopup)
