import * as actions from './statistiquesQuestionsActions'
import { combineReducers } from 'redux'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_QUESTIONS_SUCCESS:
			return (action.result || []).map(type => type.idOceane)
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_QUESTIONS_SUCCESS:
			return (action.result || []).reduce((acc, type) => ({
				...acc,
				[type.idOceane]: type
			}), state)
		default:
			return state
	}
}

const filtres = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_QUESTIONS_SUCCESS:
			return action.filtres
		default:
			return state
	}
}

export const questions = combineReducers({
	ids,
	byId,
	filtres
})