import * as api from './creerCandidatApi'

export const CREER_CANDIDAT_REQUEST = 'CREER_CANDIDAT_REQUEST'
export const CREER_CANDIDAT_SUCCESS = 'CREER_CANDIDAT_SUCCESS'
export const CREER_CANDIDAT_ERROR = 'CREER_CANDIDAT_ERROR'
export const creerCandidat = (values) => dispatch => {
	dispatch({ type: CREER_CANDIDAT_REQUEST })
	return api.creerCandidat(values)
		.then(idCandidat => {
			dispatch({ type: CREER_CANDIDAT_SUCCESS })
			return idCandidat
		})
		.catch(e => {
			dispatch({
				type: CREER_CANDIDAT_ERROR,
				e
			})
			throw e
		})
}