import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { Button, InputFile, injectFormSnackbar } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import { reduxForm, SubmissionError } from 'redux-form'
import { chargerFichierImport } from './services/importBCEActions'
import Typography from '@material-ui/core/Typography'

const styles = () => getStyles({
	charger: {
		marginTop: 19
	},
	nomFichier: {
		marginTop: 20
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.fichier || values.fichier.length === 0) {
		errors.fichier = { id: 'global.erreurs.obligatoire' }
	} else if ((values.fichier[0].type !== 'text/csv' && values.fichier[0].type !== 'application/vnd.ms-excel') || !values.fichier[0].name.endsWith('.csv')) {
		errors.fichier = { id: 'global.erreurs.typeInvalide', values: { type: 'CSV' } }
	}
	return errors
}

const FileUploadForm = ({ chargerFichierImport, setVersionCD, handleSubmit, nomFichier, classes }) => {

	const handleChargerFichier = values => chargerFichierImport({ fichier: values.fichier })
		.then(({ result }) => setVersionCD(result))
		.catch(e => {
			if (e.bodyError) {
				throw new SubmissionError(e.bodyError)
			}
			throw new SubmissionError(e)
		})

	return (
		<Grid container alignItems="flex-start">
			<Grid item>
				<InputFile
					label="Nom fichier *"
					name="fichier"
					accept="text/csv"
				/>
				<Typography className={classes.nomFichier}>{nomFichier}</Typography>
			</Grid>
			<Grid item>
				<Button onClick={handleSubmit(handleChargerFichier)} className={classes.charger}>
					Charger
				</Button>
			</Grid>
		</Grid>
	)
}

FileUploadForm.propTypes = {
	chargerFichierImport: PropTypes.func,
	setVersionCD: PropTypes.func,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

const actions = {
	chargerFichierImport
}

export default compose(
	withStyles(styles),
	connect(undefined, actions),
	reduxForm({
		form: 'importBCEFichier',
		validate
	}),
	injectFormSnackbar({ success: 'Le fichier a bien été chargé.' })
)(FileUploadForm)
