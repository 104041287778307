import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import {
	BandeauInfo,
	DownloadIcon,
	EmptyResult,
	getIdCandidat,
	getInscriptionsByIdExamen,
	injectProfil,
	INS_ETAT_EXAMEN_CANDIDAT,
	INS_ETAT_INSCRIPTION,
	Link,
	MODE_PASSAGE,
	resetInscriptionsExamenSelectionne,
	TYPE_EXAMEN
} from '@oceane/ui'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getListeInscriptions } from '../services/detailExamenSelectors'
import { inscriptionEtatNonFinalExiste } from '../services/detailExamenUtils'
import { openPopupNouvelleInscription } from '../services/popup/nouvelleInscriptionActions'
import NouvelleInscriptionPopup from './popup/NouvelleInscriptionPopup'

const style = () => getStyles({
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})

const Inscriptions = (
	{
		idCandidat,
		examenSelectionne,
		getInscriptionsByIdExamen,
		listeInscriptions,
		classes,
		resetInscriptionsExamenSelectionne,
		openPopupNouvelleInscription,
		intl
	}
) => {

	const [anchorEl, setAnchorEl] = React.useState(null)

	React.useEffect(() => {
		if (!!idCandidat && !!examenSelectionne && !!examenSelectionne.id) {
			getInscriptionsByIdExamen(idCandidat, examenSelectionne.id)
		}

		return () => {
			// On vide la dataTable
			resetInscriptionsExamenSelectionne()
		}
	}, [])


	/**
	 * Gère la fermeture du menu intermédiaire de choix du type de nouvelle inscription
	 */
	const handleCloseMenuIntermediaire = (typeInscription) => {
		setAnchorEl(null)
		if (typeInscription !== -1) {
			openPopupNouvelleInscription(typeInscription)
		}
	}


	/**
	 * Renvoie les colonnes de la table qui correspondent à une inscription pratique ou pas
	 */
	const getDataTableColumnHeaders = () => {
		const headers = [
			{
				key: 'numeroInscription',
				name: <FormattedMessage id="candidats.profil.examens.inscriptions.inscription" />,
				render: row => <Link
					to={`/candidats/${idCandidat}/examens/${examenSelectionne.id}/inscription-${examenSelectionne.pratique ? 'pratique' : 'theorique'}/${row.id}`}>
					{row.numeroInscription}
				</Link>,
				sortable: true
			},
			{
				key: 'etatInscription',
				name: <FormattedMessage id="candidats.profil.examens.inscriptions.etat" />,
				render: row => <FormattedMessage id={`candidats.profil.examens.inscriptions.etatInscription.${row.etatInscription}`} />,
				sortable: true
			}
		]
		// Cas d'une inscription pratique
		if (examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE) {
			headers.push(
				{
					key: 'dateDebutPrevue',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.dateDebutPrevue" />,
					sortable: true
				},
				{
					key: 'enteteDossierCandidat',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.etiquetteDossier" />,
					render: row => <IconButton
						disableRipple
						onClick={() => download(`/files/inscriptions/${row.id}/en-tete-dossier`)}
					>
						<DownloadIcon />
					</IconButton>
				},
				{
					key: 'ficheRecapitulative',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.ficheRecapitulative" />,
					render: row => {
						// On n'affiche le bouton que pour un état au minimum "affecté"
						// et présence d'un accord coordonnateur (true)
						if (row.etatInscription >= INS_ETAT_INSCRIPTION.AFFECTEE &&
							row.accordCoordonnateur) {
							return <IconButton
								disableRipple
								onClick={() => download(`/files/candidats/${idCandidat}/inscriptions/${row.id}/ficheRecapitulative`)}
							>
								<DownloadIcon />
							</IconButton>
						}
						return null
					}
				},
				{
					key: 'lettreResultat',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.resultat" />,
					render: row => {
						// Pas de conditions sur la réussite de l'examen ici, Le gestionnaire peut toujours l'imprimer
						// contrairement au candidat (cf. PORTAIL)
						if (row.etatInscription >= INS_ETAT_INSCRIPTION.TERMINEE) {
							return <IconButton
								disableRipple
								onClick={() => download(`/files/candidats/${idCandidat}/inscriptions/${row.id}/lettreResultat`)}
							>
								<DownloadIcon />
							</IconButton>
						}
						return null
					}
				}
			)
		}
		// Cas d'une inscription théorique
		else {
			headers.push(
				{
					key: 'libelleSession',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.session" />,
					sortable: true
				},
				{
					key: 'ficheInscription',
					name: <FormattedMessage id="candidats.profil.examens.inscriptions.ficheInscription" />,
					render: row => {
						// On n'affiche le bouton que pour un état au minimum "affecté"
						if (row.etatInscription >= INS_ETAT_INSCRIPTION.AFFECTEE) {
							return <IconButton
								disableRipple
								onClick={() => download(`/files/candidats/${idCandidat}/inscriptions/${row.id}/ficheInscription`)}
							>
								<DownloadIcon />
							</IconButton>
						}
						return null
					}
				}
			)
		}
		return headers
	}

	/**
	 * Gère l'apparition de la popup, ou d'un menu intermédiaire
	 * s'il y a plusieurs possibilités de nouvelle inscription
	 */
	const handleClickNouvelleInscription = (event) => {
		// Seul un examen standard donne le choix entre inscription papier ou écran,
		// on propose ces options dans un menu intermédiaire
		if (examenSelectionne.typeExamen === TYPE_EXAMEN.STANDARD) {
			setAnchorEl(event.currentTarget)
		}
		// Tout autre type d'examen signifie un seul type d'inscription possible,
		// donc on ouvre directement la popup de création
		else {
			if (examenSelectionne.typeExamen === TYPE_EXAMEN.IFR || examenSelectionne.typeExamen === TYPE_EXAMEN.VFR) {
				openPopupNouvelleInscription(MODE_PASSAGE.ECRAN)
			} else {
				openPopupNouvelleInscription(MODE_PASSAGE.PRATIQUE)
			}
		}
	}

	const open = Boolean(anchorEl)

	const canCreateInscription = !(examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.EDITE
		|| examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.FRAUDE
		|| examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.ABANDONNE
		|| examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.REUSSI
		|| inscriptionEtatNonFinalExiste(listeInscriptions))

	let errorMessage = undefined
	// RG : si l'examen est à l'état "fraude", "abandonné", "édité" ou "réussi"
	// aucune inscription pour cet examen n'est autorisée. KO = MSG118
	if (
		examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.FRAUDE ||
		examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.ABANDONNE ||
		examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.EDITE ||
		examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.REUSSI
	) {
		errorMessage = 'candidats.profil.examens.inscriptions.erreurs.examenTermine'
	} else if (inscriptionEtatNonFinalExiste(listeInscriptions)) {
		// RG : l'utilisateur n'est pas autorisé à créer une inscription à un
		// examen qui possède déjà
		// une inscription à un état non final (terminée ou abandonnée). KO =
		// MSG117
		// Sont exclues de ce test les inscriptions exceptionnelles.
		errorMessage = 'candidats.profil.examens.inscriptions.erreurs.inscriptionsNonFinales'
	}

	return (
		<>
			{!!errorMessage && <BandeauInfo message={intl.formatMessage({ id: errorMessage })} />}
			<Title value={<FormattedMessage id="candidats.profil.examens.inscriptions.detailExamen" />} />
			<StaticDataTable
				headers={getDataTableColumnHeaders()}
				data={listeInscriptions}
				defaultPageSize={10}
				noResultFragment={<EmptyResult message="Aucune inscription pour cet examen" />}
			/>
			<NouvelleInscriptionPopup idCandidat={idCandidat} canCreateInscription={canCreateInscription} examenSelectionne={examenSelectionne} />
			<Fab
				className={classes.fab}
				color="primary"
				onClick={handleClickNouvelleInscription}
			>
				<AddIcon />
			</Fab>
			<Menu
				id="menuTypeInscription"
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleCloseMenuIntermediaire(-1)}
			>
				<MenuItem
					key={MODE_PASSAGE.PAPIER}
					onClick={() => handleCloseMenuIntermediaire(MODE_PASSAGE.PAPIER)}
				>
					<FormattedMessage
						id={`candidats.profil.examens.inscriptions.nouvelleInscription.${MODE_PASSAGE.PAPIER}`}
					/>
				</MenuItem>
				<MenuItem
					key={MODE_PASSAGE.ECRAN}
					onClick={() => handleCloseMenuIntermediaire(MODE_PASSAGE.ECRAN)}
				>
					<FormattedMessage
						id={`candidats.profil.examens.inscriptions.nouvelleInscription.${MODE_PASSAGE.ECRAN}`}
					/>
				</MenuItem>
			</Menu>
		</>
	)
}

const mapStateToProps = state => ({
	listeInscriptions: getListeInscriptions(state),
	idCandidat: getIdCandidat(state)
})

Inscriptions.propTypes = {
	listeInscriptions: PropTypes.array.isRequired,
	idCandidat: PropTypes.number.isRequired,
	resetInscriptionsExamenSelectionne: PropTypes.func.isRequired,
	openPopupNouvelleInscription: PropTypes.func.isRequired,
	getInscriptionsByIdExamen: PropTypes.func.isRequired
}

const actions = {
	getInscriptionsByIdExamen,
	resetInscriptionsExamenSelectionne,
	openPopupNouvelleInscription
}

export default compose(
	injectIntl,
	injectProfil,
	withStyles(style),
	connect(mapStateToProps, actions)
)(Inscriptions)
