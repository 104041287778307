import * as api from './examenTheoriqueOrganismeApi'

export const OPEN_POPUP = 'EXAMEN_THEORIQUE_ORGANISME/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'EXAMEN_THEORIQUE_ORGANISME/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const DELETE_FORMATION_THEORIQUE_REQUEST = 'EXAMEN_THEORIQUE_ORGANISME/DELETE_FORMATION_THEORIQUE_REQUEST'
export const DELETE_FORMATION_THEORIQUE_SUCCESS = 'EXAMEN_THEORIQUE_ORGANISME/DELETE_FORMATION_THEORIQUE_SUCCESS'
export const DELETE_FORMATION_THEORIQUE_ERROR = 'EXAMEN_THEORIQUE_ORGANISME/DELETE_FORMATION_THEORIQUE_ERROR'
export const deleteFormationTheoriqueOrganisme = (id) => dispatch => {
	dispatch({ type: DELETE_FORMATION_THEORIQUE_REQUEST })
	return api.deleteFormationTheoriqueOrganisme(id)
		.then((result => dispatch({
			type: DELETE_FORMATION_THEORIQUE_SUCCESS,
			payload: result
		})))
		.catch((e) => {
			dispatch({
				type: DELETE_FORMATION_THEORIQUE_ERROR,
				e
			})
			throw e
		})
}

export const OPEN_POPUP_CREATION = 'EXAMEN_THEORIQUE_ORGANISME/OPEN_POPUP_CREATION'
export const openPopupCreation = () => dispatch => dispatch({
	type: OPEN_POPUP_CREATION
})

export const CLOSE_POPUP_CREATION = 'EXAMEN_THEORIQUE_ORGANISME/CLOSE_POPUP_CREATION'
export const closePopupCreation = () => dispatch => dispatch({
	type: CLOSE_POPUP_CREATION
})

export const GET_EXAMENS_THEORIQUES_REQUEST = 'GET_EXAMENS_THEORIQUES_REQUEST'
export const GET_EXAMENS_THEORIQUES_SUCCESS = 'GET_EXAMENS_THEORIQUES_SUCCESS'
export const GET_EXAMENS_THEORIQUES_ERROR = 'GET_EXAMENS_THEORIQUES_ERROR'
export const getExamensTypeTheorique = (idOrganisme) => dispatch => {
	dispatch({ type: GET_EXAMENS_THEORIQUES_REQUEST })
	return api.getExamensTypeTheorique(idOrganisme)
		.then((result) => dispatch({
				type: GET_EXAMENS_THEORIQUES_SUCCESS,
				payload: result
			})
		)
		.catch((e) => dispatch({
			type: GET_EXAMENS_THEORIQUES_ERROR,
			e
		}))
}

export const CREATE_FORMATION_THEORIQUES_REQUEST = 'CREATE_FORMATION_THEORIQUES_REQUEST'
export const CREATE_FORMATION_THEORIQUES_SUCCESS = 'CREATE_FORMATION_THEORIQUES_SUCCESS'
export const CREATE_FORMATION_THEORIQUES_ERROR = 'CREATE_FORMATION_THEORIQUES_ERROR'
export const createFormationTheoriqueOrganisme = (value, id) => dispatch => {
	dispatch({ type: CREATE_FORMATION_THEORIQUES_REQUEST })
	return api.createFormationTheoriqueOrganisme(value, id)
		.then((result) => dispatch({
				type: CREATE_FORMATION_THEORIQUES_SUCCESS,
				payload: result
			})
		)
		.catch((e) => dispatch({
			type: CREATE_FORMATION_THEORIQUES_ERROR,
			e
		}))
}