import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getComptesClients } from '../../organisme/services/detail/detailOrganismeAction'
import * as selector from '../../organisme/services/detail/detailOrganismeSelector'

export const injectCompteClient = (WrappedComponent) => {

	class injectCompteClient extends React.Component {

		componentDidMount() {
			const { getComptesClients } = this.props

			getComptesClients()
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectCompteClient.propTypes = {
		comptesClients: PropTypes.array
	}


	const mapStateToProps = state => ({
		comptesClients: selector.getComptesClients(state)
	})

	const actions = {
		getComptesClients
	}

	return connect(mapStateToProps, actions)(injectCompteClient)
}
