import { fetchFactory } from 'isotope-client'

export const rechercher = (values) => fetchFactory('/surveillants/recherche', {
    method: 'post',
    body: JSON.stringify(values)
})

export const createSurveillant = (values) => fetchFactory('/surveillants', {
    method: 'post',
    body: JSON.stringify(values)
})

export const getSurveillant = (id) => fetchFactory(`/surveillants/${id}`)

export const getSurveillantCentres = (id) => fetchFactory(`/surveillants/${id}/centres`)

export const supprimerSurveillant = (id) => fetchFactory(`/surveillants/${id}`, {
    method: 'delete'
})

export const enregistrerSurveillant = (values) => fetchFactory(`/surveillants/${values.id}`, {
    method: 'put',
    body: JSON.stringify(values)
})

export const enregistrerCentres = (values, id) => fetchFactory(`/surveillants/${id}/centres`, {
    method: 'put',
    body: JSON.stringify(values)
})
