import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'isotope-client'
import { InputRadio, MultiSelect } from '@oceane/ui'
import { RDB, UTILISATEUR_FORM_NAME } from '../services/administrationValues'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as adminSelectors from '../services/administrationSelectors'
import { injectListeMatieres } from '../../references/matieres/services/injectListeMatieres'
import * as matieresSelectors from '../../references/matieres/services/matieresSelectors'

const RespoBanqueForm = ({ listeMatieres }) => {
	return (
		<React.Fragment>
			<Title value="Spécialisation" />
			<Field
				id="typeRdb"
				name="typeRdb"
				label="Type de RDB"
				component={InputRadio}
				choices={Object.keys(RDB).map(rdb => ({
					value: rdb,
					label: RDB[rdb]
				}))}
			/>
			<Title value="Matières" />
			<Field
				name="rdbMatieres"
				label=""
				component={MultiSelect}
				choices={listeMatieres}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const utilisateur = adminSelectors.getUtilisateur(state)
	const listeMatieres = matieresSelectors.getListeMatieres(state).map(matiere => ({ id: matiere.id, libelle: `${matiere.code} ${matiere.libelle}` }))
	return {
		listeMatieres,
		initialValues: {
			typeRdb: utilisateur.profil && utilisateur.profil.typeRdb ? `${utilisateur.profil.typeRdb}` : '1',
			rdbMatieres: listeMatieres.filter(matiere => utilisateur.rdbMatieres && utilisateur.rdbMatieres.includes(matiere.id))
		}
	}
}

RespoBanqueForm.propTypes = {
	listeMatieres: PropTypes.array
}

export default compose(
	injectListeMatieres,
	connect(mapStateToProps),
	reduxForm({
		form: UTILISATEUR_FORM_NAME,
		enableReinitialize: true
	})
)(RespoBanqueForm)
