import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Popup } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'

const ConfirmInitialisationPopup = ({ open, onSubmit, onClose }) => {

	const popupContent = () => <Typography>
		Après initialisation, l'épreuve n'est plus modifiable. Confirmez-vous cette action&nbsp;?
	</Typography>

	const actions = [
		<Button
			color="primary"
			onClick={onClose}
			key="fermer"
		>
			Annuler
		</Button>,
		<Button
			type="submit"
			color="primary"
			variant="contained"
			onClick={onSubmit}
			key="valider"
		>
			Confirmer
		</Button>
	]

	return (
		<Popup
			open={open}
			title="Initialisation"
			content={popupContent()}
			actions={actions}
		/>
	)
}

ConfirmInitialisationPopup.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
}

export default ConfirmInitialisationPopup