import { getStatistiques } from '../statistiquesSelectors'
import { createSelector } from 'reselect'

const getLocalState = createSelector(
	getStatistiques,
	statistiques => statistiques.questions
)

const questionsIds = createSelector(
	getLocalState,
	questions => questions.ids
)

const questionsById = createSelector(
	getLocalState,
	questions => questions.byId
)

export const getListeQuestions = createSelector(
	questionsIds,
	questionsById,
	(ids, byId) => ids.map(id => byId[id])
)

export const getFiltres = createSelector(
	getLocalState,
	state => state.filtres
)