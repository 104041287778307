import { fetchFactory } from 'isotope-client'

export const getMatieresAImprimer = (profil) => fetchFactory(`/matiere/aImprimer/${profil}`)

export const getAnnexesAImprimer = (idMatiere) => fetchFactory(`/annexesGenerique/aImprimer/${idMatiere}`)

export const checkFileExist = (idAnnexe, idMatiere) => fetchFactory(`/annexesGenerique/aImprimer/${idMatiere}/annexes/${idAnnexe}/file`, {
	method: 'post',
	body: idAnnexe
})

export const checkImpressionGlobalePossible = (idMatiere) => fetchFactory(`/annexesGenerique/aImprimer/${idMatiere}/impression`, {
	method: 'post',
	body: idMatiere
})