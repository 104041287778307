import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { infoShape, injectInfos, Input, login as loginApi, Button, fetchUser as fetchUserApi, getProfilByNom } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { getUser as getUserAction, logUser as logUserAction } from 'isotope-client/modules/login/services/loginActions'
import { storeLocalUser as storeLocalUserApi } from 'isotope-client/modules/login/services/loginApi'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import * as userSelectors from './userSelectors'


const validate = values => {
	const errors = {}
	if (!values.login) {
		errors.login = 'L\'identifiant est obligatoire'
	}
	if (!values.password) {
		errors.password = 'Le mot de passe est obligatoire'
	}
	return errors
}

const styles = theme => getStyles({
	buttonGrid: {
		textAlign: 'right'
	},
	loginButton: {
		width: 110
	},
	welcomeMessage: {
		marginTop: 20,
		marginBottom: 20
	}
})

class LoginForm extends Component {

	loginSubmit = (data, dispatch) => {
		const { nextPathname } = this.props
		return new Promise((resolve, reject) => loginApi(data)
			.then((json) => {
				if (json) {
					dispatch(logUserAction(json.token))
					storeLocalUserApi(json)
					resolve(json.token)
				} else {
					reject({
						login: { id: 'global.erreurs.mauvaisIdentifiants' }
					})
				}
			})
			.catch(e => {
				if(e && e.response && (e.response.status === 400 || e.response.status === 401)) {
					reject(e)
				}
				reject({
					login: { id: 'global.erreurs.connexionImpossible' }
				})
			})
		).then((token) => fetchUserApi(token)
		).then((user) => {
			dispatch(getUserAction(user))
			return user
		}).then(({ authorities }) => {
			if (nextPathname) {
				dispatch(push(nextPathname))
			} else {
				// Gestion de la redirection selon le profil
				if (authorities && authorities.length && authorities[0].authority && getProfilByNom(authorities[0].authority)) {
					dispatch(push(getProfilByNom(authorities[0].authority).defaultUrl))
				} else {
					dispatch(push('/'))
				}
			}
		}).catch(e => {
			throw new SubmissionError(e)
		})
	}

	render() {
		const { handleSubmit, submitting, classes, infos: { version } } = this.props

		return (
			<form onSubmit={handleSubmit(this.loginSubmit)} autoComplete="off">
				<Typography variant="h5" component="h1">
					Authentification
				</Typography>
				{version && <Typography variant="body1" className={classes.welcomeMessage}>
					Oceane V{version}
				</Typography>}
				<Field
					component={Input}
					label="Identifiant *"
					name="login"
					placeholder="Identifiant de connexion"
					fullWidth
				/>
				<Field
					component={Input}
					type="password"
					label="Mot de passe *"
					name="password"
					placeholder="Mot de passe"
					fullWidth
				/>
				<Grid container>
					<Grid item xs={12} className={classes.buttonGrid}>
						<Button
							type="submit"
							loading={submitting}
							variant="contained"
							color="primary"
							className={classes.loginButton}
						>
							Valider
						</Button>
					</Grid>
				</Grid>
			</form>
		)
	}

}

LoginForm.propTypes = {
	nextPathname: PropTypes.string,
	// redux-form
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	infos: infoShape
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state)
})

export default compose(
	injectInfos,
	connect(mapStateToProps),
	reduxForm({
		form: 'loginForm',
		validate
	}),
	withStyles(styles)
)(LoginForm)
