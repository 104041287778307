import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getDetailsSousParagraphe } from './ligneProgrammeAction'
import * as selector from './ligneProgrammeSelectors'

export const injectDetailSousParagrapheWithIdSousParagraphe = WrappedComponent => {

	class injectDetailSousParagrapheWithIdSousParagraphe extends React.Component {

		componentDidMount() {
			const { getDetailsSousParagraphe, match } = this.props

			const idMatiere = match.params.idMatiere
			const idTopic = match.params.idTopic
			const idParagraphe = match.params.idParagraphe
			const idSousParagraphe = match.params.idSousParagraphe

			getDetailsSousParagraphe(idMatiere, idTopic, idParagraphe, idSousParagraphe)
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectDetailSousParagrapheWithIdSousParagraphe.propTypes = {
		detailsSousParagraphe: PropTypes.object
	}


	const mapStateToProps = state => ({
		detailsSousParagraphe: selector.getDetailSousParagraphe(state)
	})

	const actions = {
		getDetailsSousParagraphe
	}

	return connect(mapStateToProps, actions)(injectDetailSousParagrapheWithIdSousParagraphe)
}
