import * as api from './matieresApi'

export const GET_LISTE_MATIERES_REQUEST = 'REFERENCES/GET_LISTE_MATIERES_REQUEST'
export const GET_LISTE_MATIERES_SUCCESS = 'REFERENCES/GET_LISTE_MATIERES_SUCCESS'
export const GET_LISTE_MATIERES_ERROR   = 'REFERENCES/GET_LISTE_MATIERES_ERROR'
export const getListeMatieres = () => dispatch => {
	dispatch({ type: GET_LISTE_MATIERES_REQUEST })
	return api.getListeMatieresReference()
		.then(matieres => dispatch({
			type: GET_LISTE_MATIERES_SUCCESS,
			matieres
		}))
		.catch(e => {
			dispatch({ type: GET_LISTE_MATIERES_ERROR })
			throw e
		})
}

export const GET_MES_MATIERES_REQUEST = 'REFERENCES/GET_MES_MATIERES_REQUEST'
export const GET_MES_MATIERES_SUCCESS = 'REFERENCES/GET_MES_MATIERES_SUCCESS'
export const GET_MES_MATIERES_ERROR   = 'REFERENCES/GET_MES_MATIERES_ERROR'
export const getMesMatieres = () => dispatch => {
	dispatch({ type: GET_MES_MATIERES_REQUEST })
	return api.getMesMatieres()
		.then(matieres => dispatch({
			type: GET_MES_MATIERES_SUCCESS,
			matieres
		}))
		.catch(e => {
			dispatch({ type: GET_MES_MATIERES_ERROR })
			throw e
		})
}

export const HANDLE_QUERY_LISTE_MATIERES = 'HANDLE_QUERY_LISTE_MATIERES'
export const RESET_QUERY_LISTE_MATIERES = "RESET_QUERY_LISTE_MATIERES"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_LISTE_MATIERES,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_LISTE_MATIERES
	})
}