import { createSelector } from 'reselect'
import { getReferences } from '../../../referencesSelectors'

const getLocalState = state => getReferences(state).examenTheorique

const getData = createSelector(
	getLocalState,
	state => state.data
)

export const isPopupOpen = createSelector(
	getLocalState,
	state => state.open
)

export const isPopupOpenCreation = createSelector(
	getLocalState,
	state => state.openCreation
)

export const getExamensTheoriques = createSelector(
	getData,
	data => data.ids.map(id => data.byId[id])
)