import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import { Popup, Button } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'

const styles = theme => getStyles({})

const PopupConfirmerOuvertureAcces = ({ open, closePopup, actionValider }) => {
	const actions = [
		<Button key="annuler" onClick={closePopup}>
			Annuler
		</Button>,
		<Button key="valider"
			onClick={() => actionValider()
				.then(closePopup)
				.catch(closePopup)
			}
		>
			Valider
		</Button>
	]

	return (
		<Popup
			title="Accès journée"
			open={open}
			content={<Typography>Ouvrir l'accès aux épreuves de tous les candidats de la journée ?</Typography>}
			actions={actions}
		/>
	)
}

PopupConfirmerOuvertureAcces.propTypes = {
	classes: PropTypes.object
}

export default compose(
	withStyles(styles)
)(PopupConfirmerOuvertureAcces)
