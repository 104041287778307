import * as api from './statistiquesCandidatsApi'

export const CHECK_FORM_VALID_REQUEST = 'CHECK_FORM_VALID_REQUEST'
export const CHECK_FORM_VALID_SUCCESS = 'CHECK_FORM_VALID_SUCCESS'
export const CHECK_FORM_VALID_ERROR = 'CHECK_FORM_VALID_ERROR'
export const checkFormValid = (values) => dispatch => {
    dispatch({ type: CHECK_FORM_VALID_REQUEST })
    return api.checkFormValid(values)
        .then(() => dispatch({ type: CHECK_FORM_VALID_SUCCESS }))
        .catch(e => {
            dispatch({ type: CHECK_FORM_VALID_ERROR })
            throw e
        })
}