import { fetchFactory, postMultipart } from 'isotope-client'

export const getEpreuves = idSession => fetchFactory(`/sessions/${idSession}/epreuves`)
export const getEpreuveSession = idEpreuve => fetchFactory(`/correction-copies/epreuve/${idEpreuve}`)
export const suppressionEtCreationCopies = (idSession, idEpreuveSession, fichierCopies) => postMultipart(`/sessions/${idSession}/epreuves/${idEpreuveSession}/creer-copies`, {
	body: fichierCopies
})
export const controleAnomalies = (idEpreuveSession, isFichierCharge) => fetchFactory(`/correction-copies/epreuve/${idEpreuveSession}/controle-anomalies?isFichierCharge=${isFichierCharge}`)
export const declarerAbsence = (idEpreuveSession, idEpreuveInscriptionPapier) => fetchFactory(`/correction-copies/epreuve/${idEpreuveSession}/declarer-absence?idEpreuveInscriptionPapier=${idEpreuveInscriptionPapier}`, {
	method: 'put'
})
export const declarerPresence = (idEpreuveSession, idEpreuveInscriptionPapier) => fetchFactory(`/correction-copies/epreuve/${idEpreuveSession}/declarer-presence?idEpreuveInscriptionPapier=${idEpreuveInscriptionPapier}`, {
	method: 'put'
})
export const corrigerCopie = (idEpreuveSession, isFichierCharge) => fetchFactory(`/correction-copies/epreuve/${idEpreuveSession}/corriger?isFichierCharge=${isFichierCharge}`, {
	method: 'put'
})
export const enregistrerTraitementAnomalies = (idEpreuveSession, idCopiePapier, form) => fetchFactory(
	`/correction-copies/epreuve/${idEpreuveSession}/traitement-anomalies/${idCopiePapier}`,
	{
		method: 'put',
		body: JSON.stringify(form)
	}
)
export const modifierReponseQuestion = (idEpreuveSession, idReponseCopiePapier, reponse) => fetchFactory(
	`/correction-copies/epreuve/${idEpreuveSession}/traitement-anomalies/modifier-reponse/${idReponseCopiePapier}?reponse=${reponse}`, {
	method: 'put'
})
