import { CLEAN_PLANNING_JOURNEE, GET_PLANNING_JOURNEE_SUCCESS, HANDLE_QUERY_SITES_ECRAN, RESET_QUERY_SITES_ECRAN } from './siteEcranAction'
import { combineReducers } from 'redux'

const planning = (state = [], action) => {
	switch (action.type) {
		case GET_PLANNING_JOURNEE_SUCCESS:
			return action.result
		case CLEAN_PLANNING_JOURNEE:
			return []
		default:
			return state
	}
}

const initialRecherche = {
	search: '',
	switch: false
}
const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_SITES_ECRAN:
			return {
				...state,
				[action.key]: action.value
			}
		case RESET_QUERY_SITES_ECRAN:
			return {
				...state,
				search: ''
			}
		default:
			return state
	}
}

export const sitesEcran = combineReducers({
	planning,
	recherche
})

