import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'isotope-client'
import { Select } from '@oceane/ui'
import MenuItem from '@material-ui/core/MenuItem'
import { UTILISATEUR_FORM_NAME } from '../services/administrationValues'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as adminSelectors from '../services/administrationSelectors'
import { injectListeOrganismes } from '../../references/organisme/services/injectListeOrganismes'
import * as organismeSelectors from '../../references/organisme/services/organismeSelectors'

const OrganismeForm = ({ listeOrganismes }) => {
	return (
		<React.Fragment>
			<Title value="Organisme" />
			<Field
				id="organisme"
				name="organisme"
				label="Organisme de formation"
				component={Select}
				placeholder={<MenuItem key="placeholder" value={null} />}
			>
				{listeOrganismes.map(organisme =>
					<MenuItem key={organisme.id} value={organisme.id}>
						{organisme.libelleCourt}
					</MenuItem>
				)}
			</Field>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const utilisateur = adminSelectors.getUtilisateur(state)
	const listeOrganismes = organismeSelectors.getListeOrganismes(state)

	return {
		listeOrganismes,
		initialValues: {
			organisme: utilisateur.profil && utilisateur.profil.idOrganisme
		}
	}
}

OrganismeForm.propTypes = {
	listeOrganismes: PropTypes.array
}

export default compose(
	injectListeOrganismes,
	connect(mapStateToProps),
	reduxForm({
		form: UTILISATEUR_FORM_NAME,
		enableReinitialize: true
	})
)(OrganismeForm)
