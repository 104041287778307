import * as api from './nouvelleAssociationFormationApi'

export const GET_TYPES_FORMATION_ACTIVE_REQUEST = 'REFERENCES/GET_TYPES_FORMATION_ACTIVE_REQUEST'
export const GET_TYPES_FORMATION_ACTIVE_SUCCESS = 'REFERENCES/GET_TYPES_FORMATION_ACTIVE_SUCCESS'
export const GET_TYPES_FORMATION_ACTIVE_ERROR   = 'REFERENCES/GET_TYPES_FORMATION_ACTIVE_ERROR'
export const getListeTypesFormationActive = (idExamenType) => dispatch => {
	dispatch({ type: GET_TYPES_FORMATION_ACTIVE_REQUEST })
	return api.getListeTypesFormationActive(idExamenType)
		.then(typesFormation => dispatch({
			type: GET_TYPES_FORMATION_ACTIVE_SUCCESS,
			typesFormation
		}))
		.catch(e => {
			dispatch({ type: GET_TYPES_FORMATION_ACTIVE_ERROR })
			throw e
		})
}

export const CREATE_ASSOCIATION_FORMATION_REQUEST = 'REFERENCES/CREATE_ASSOCIATION_FORMATION_REQUEST'
export const CREATE_ASSOCIATION_FORMATION_SUCCESS = 'REFERENCES/CREATE_ASSOCIATION_FORMATION_SUCCESS'
export const CREATE_ASSOCIATION_FORMATION_ERROR   = 'REFERENCES/CREATE_ASSOCIATION_FORMATION_ERROR'
export const createAssociationFormation = (idExamenType, values) => dispatch => {
	dispatch({ type: CREATE_ASSOCIATION_FORMATION_REQUEST })
	return api.createAssociationFormation(idExamenType, values)
		.then(typeFormation => dispatch({
			type: CREATE_ASSOCIATION_FORMATION_SUCCESS,
			typeFormation
		}))
		.catch(e => {
			dispatch({ type: CREATE_ASSOCIATION_FORMATION_ERROR })
			throw e
		})
}