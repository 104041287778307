import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormInput, getStyles } from 'isotope-client'
import { Button, Popup } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

const styles = (theme) => getStyles({
	input: {
		marginTop: '20px',
	}
})

const FORM_NAME = 'popupDeclarationNonConformiteForm'

const PopupDeclarationNonConformite = ({
	open,
	closePopup,
	actionValider,
	reset,
	classes,
	handleSubmit,
}) => {
	React.useEffect(() => {
		if (open) {
			reset(FORM_NAME)
		}
	}, [open])

	const buttonActions = [
		<Button key="annuler" onClick={closePopup}>
			<FormattedMessage id="global.btn.annuler" />
		</Button>,
		<Button key="valider"
				variant="contained"
				onClick={handleSubmit(actionValider)}
		>
			<FormattedMessage id="candidats.examens.piecesJustificatives.popins.declarerNonConforme.buttons.declarerNonConforme" />
		</Button>
	]

	return (
		<Popup
			title={<FormattedMessage id="candidats.examens.piecesJustificatives.popins.declarerNonConforme.title" />}
			open={open}
			content={
				<React.Fragment>
					<Typography>
						<FormattedHTMLMessage id="candidats.examens.piecesJustificatives.popins.declarerNonConforme.body.paragraph1" />
					</Typography>
					<Typography>
						<FormattedMessage id="candidats.examens.piecesJustificatives.popins.declarerNonConforme.body.paragraph2" />
					</Typography>

					<Field
						name="commentaire"
						label="Commentaire"
						component={FormInput}
						fullWidth
						multiline
						rows={5}
						rowsMax={5}
						className={classes.input}
						inputProps={{
							maxLength: 255
						}}
					/>
				</React.Fragment>
			}
			actions={buttonActions}
		/>
	)
}

const actions = {}

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		commentaire: ownProps.examenCandidat.messageStatutDossier
	}
})

PopupDeclarationNonConformite.propTypes = {
	classes: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		enableReinitialize: true
	}),
	withStyles(styles)
)(PopupDeclarationNonConformite)
