import { fetchFactory, postMultipart } from 'isotope-client'

export const getVersionCDActuelle = () => fetchFactory('/questions/versionCD')

export const chargerFichierImport = values => postMultipart('/questions/importBCE', {
	body: values
})

export const importerCD = values => fetchFactory('/questions/importBCE',  {
	method: 'put',
	body: JSON.stringify(values)
})

export const verifierDisponibiliteCompteRendu = () => fetchFactory('/questions/importBCE/disponibilite-compte-rendu')
