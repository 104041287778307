import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import MenuItem from '@material-ui/core/MenuItem'
import { dataTableValueSelectors, FormCheckbox, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Actions, Autocomplete, injectSession, Input, InputRadio, Select, SmallSelect } from '@oceane/ui'
import * as banqueQuestionsActions from '../../services/banqueQuestionsActions'
import { injectListExamensType } from '../../../../references/examensType/services/examenTypeInjector'

const style = theme => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '11px 20px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 892
	},
	placeholder: {
		color: 'rgba(0, 0, 0, 0.4)',
		lineHeight: 'unset'
	},
	input: {
		marginBottom: 20
	},
	checkbox: {
		marginBottom: 18
	}
})

const ETAT_QUESTION = [
	{ value: 1, label: 'Créée' },
	{ value: 2, label: 'Supprimée' },
	{ value: 3, label: 'Refusée' },
	{ value: 4, label: 'Acceptée' },
	{ value: 5, label: 'Applicable' },
	{ value: 6, label: 'Exploitée' }
]

const CRITERE_CIBLE = [
	{ value: '1', label: 'Texte' },
	{ value: '2', label: 'Texte et réponse' },
	{ value: '3', label: 'Justificatif' }
]

const OUI_NON = [
	{ value: 1, label: 'Oui' },
	{ value: 2, label: 'Non' }
]

export const FILTRE_QUESTIONS_FORM_NAME = 'searchQuestionsForm'

const QuestionsFiltres = ({
							  classes,
							  examensExploitesExploitables,
							  profil,
							  ligneProgramme,
							  resultSize,
							  handleSubmit,
							  getLignes,
							  getFamilles,
							  reset,
							  change,
							  setFilters,
							  setAnchorEl,
							  showForm,
							  setShowForm
						  }) => {
	const [listeFamilles, setListeFamilles] = React.useState([])
	const [codeMatiere, setCodeMatiere] = React.useState(null)

	React.useEffect(() => {
		// Si l'on revient sur la page avec une ligneProgramme, on charge la liste des familles
		if (!!ligneProgramme) {
			getFamilles(ligneProgramme)
				.then(({ familles }) => setListeFamilles(familles))
		}
	}, [])

	const rechercher = (values) => {
		setShowForm(false)
		setFilters({
			...values,
			annexe: Number(values.annexe) ? values.annexe : '0',
			marquage: Number(values.marquage) ? values.marquage : '0'
		})
	}

	const resetForm = () => {
		reset()
	}

	const getListeFamilles = (ev, value) => {
		// On récupère l'id matière via les 3 premiers caractères
		if (value.length > 2) {
			const newCode = value.substring(0, 3)
			// On ne recalcule les familles que si le code matière change
			if (newCode !== codeMatiere) {
				setCodeMatiere(newCode)
				change('idFamille', '')
				getFamilles(newCode)
					.then(({ familles }) => setListeFamilles(familles))
			}
		} else {
			change('idFamille', '')
			setListeFamilles([])
		}
	}

	const getFormulaire = () =>
		<Grid container alignItems="flex-start">
			<Grid item container xs={5}>
				<Field
					name="ligneProgramme"
					component={Autocomplete}
					getSuggestion={(length, value) => getLignes(value)}
					inputProps={{
						placeholder: 'Ligne de programme',
						maxLength: 11
					}}
					inputStyle={classes.input}
					minLength={3}
					onChange={getListeFamilles}
				/>
				<Field
					id="etat"
					name="etat"
					component={Select}
					inputStyle={classes.input}
					displayEmpty
					placeholder={<MenuItem value=""><Typography className={classes.placeholder}>Etat</Typography></MenuItem>}
				>
					{ETAT_QUESTION.map(etat => <MenuItem key={`etat-${etat.value}`} value={etat.value}>{etat.label}</MenuItem>)}
				</Field>
				<Field
					id="examen"
					name="idExamen"
					component={Select}
					inputStyle={classes.input}
					displayEmpty
					placeholder={<MenuItem value=""><Typography className={classes.placeholder}>Examen</Typography></MenuItem>}
				>
					{examensExploitesExploitables.map(examen => <MenuItem key={`examen-${examen.id}`} value={examen.id}>{examen.libelleCourt}</MenuItem>)}
				</Field>

				<Grid item xs={12}>
					<Field
						id="annexe"
						name="annexe"
						component={SmallSelect}
						inputStyle={classes.input}
						displayEmpty
						placeholder={<MenuItem value=""><Typography className={classes.placeholder}>Annexe</Typography></MenuItem>}
					>
						{OUI_NON.map(etat => <MenuItem key={`etat-${etat.value}`} value={etat.value}>{etat.label}</MenuItem>)}
					</Field>
				</Grid>
				<Grid item xs={12}>
					<Field
						id="marquage"
						name="marquage"
						component={SmallSelect}
						inputStyle={classes.input}
						displayEmpty
						placeholder={<MenuItem value=""><Typography className={classes.placeholder}>Marquage</Typography></MenuItem>}
					>
						{OUI_NON.map(etat => <MenuItem key={`etat-${etat.value}`} value={etat.value}>{etat.label}</MenuItem>)}
					</Field>
				</Grid>
				<Grid item xs={12}>
					<Field
						name="expertise"
						label="Expertise"
						component={FormCheckbox}
					/>
				</Grid>
			</Grid>
			<Grid item container xs={7}>
				<Field
					id="famille"
					name="idFamille"
					component={Select}
					inputStyle={classes.input}
					displayEmpty
					placeholder={<MenuItem value=""><Typography className={classes.placeholder}>Famille</Typography></MenuItem>}
				>
					{listeFamilles.map(famille => <MenuItem key={`famille-${famille.id}`} value={famille.id}>{famille.libelle}</MenuItem>)}
				</Field>
				<Field
					name="dernierImport"
					label="lors du dernier import de questions européennes"
					component={FormCheckbox}
					containerStyle={classes.checkbox}
				/>
				<Grid item container xs={12} alignItems="flex-start">
					<Grid item xs={8}>
						<Field
							name="texte"
							placeholder="Texte"
							component={Input}
							inputStyle={classes.input}
							inputProps={{
								maxLength: 30
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<Field
							id="texteOption"
							name="texteOption"
							component={InputRadio}
							choices={CRITERE_CIBLE}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Actions>
				<Button
					color="primary"
					onClick={() => setShowForm(false)}
				>
					Masquer
				</Button>
				<Button
					color="primary"
					onClick={resetForm}
				>
					Réinitialiser
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(rechercher)}
				>
					Rechercher
				</Button>
			</Actions>
		</Grid>

	const getResultBar = () =>
		<Grid container justify="flex-end">
			{resultSize > 0 && <Button
				color="primary"
				onClick={event => setAnchorEl(event.currentTarget)}
			>
				Imprimer
			</Button>
			}
			<Button
				color="primary"
				onClick={() => setShowForm(true)}
			>
				Modifier la recherche
			</Button>
		</Grid>

	return (<Paper elevation={1} square className={classes.filtres}>
		{showForm ? getFormulaire() : getResultBar()}
	</Paper>)
}

const mapStateToProps = (state, { listeExamensType }) => {
	return {
		examensExploitesExploitables: listeExamensType.filter(examen => (examen.exploite || examen.exploitable) && examen.standard),
		resultSize: dataTableValueSelectors.getDataTableNumberOfElements(state)(banqueQuestionsActions.DATATABLE_NAME),
		ligneProgramme: formValueSelector(FILTRE_QUESTIONS_FORM_NAME)(state, 'ligneProgramme')
	}
}

const actions = {
	getLignes: banqueQuestionsActions.getLignes,
	getFamilles: banqueQuestionsActions.getFamilles
}

QuestionsFiltres.propTypes = {
	ligneProgramme: PropTypes.string,
	examensExploitesExploitables: PropTypes.array,
	profil: PropTypes.number,
	resultSize: PropTypes.number,
	handleSubmit: PropTypes.func,
	getLignes: PropTypes.func,
	getFamilles: PropTypes.func,
	setFilters: PropTypes.func,
	setAnchorEl: PropTypes.func
}

export default compose(
	injectListExamensType(),
	connect(mapStateToProps, actions),
	reduxForm({
		form: FILTRE_QUESTIONS_FORM_NAME,
		destroyOnUnmount: false,
		initialValues: {
			ligneProgramme: '',
			idFamille: '',
			etat: '',
			dernierImport: false,
			idExamen: '',
			texte: '',
			texteOption: '1',
			annexe: '',
			marquage: '',
			expertise: false
		}
	}),
	withTheme(),
	withStyles(style),
	injectSession
)(QuestionsFiltres)
