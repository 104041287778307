import * as api from './editionDiplomeApi'

export const GET_EXAMENS_TYPE_DIPLOME_REQUEST = 'GET_EXAMENS_TYPE_DIPLOME_REQUEST'
export const GET_EXAMENS_TYPE_DIPLOME_SUCCESS = 'GET_EXAMENS_TYPE_DIPLOME_SUCCESS'
export const GET_EXAMENS_TYPE_DIPLOME_ERROR = 'GET_EXAMENS_TYPE_DIPLOME_ERROR'
export const getExamensTypeDiplome = () => dispatch => {
    dispatch({ type: GET_EXAMENS_TYPE_DIPLOME_REQUEST })

    return api.getExamensTypeDiplome()
        .then((response) => dispatch({ type: GET_EXAMENS_TYPE_DIPLOME_SUCCESS, response }))
        .catch(e => {
            dispatch({ type: GET_EXAMENS_TYPE_DIPLOME_ERROR })
            throw e
        })
}

export const PASSER_A_EDITE_DIPLOME_REQUEST = 'PASSER_A_EDITE_DIPLOME_REQUEST'
export const PASSER_A_EDITE_DIPLOME_SUCCESS = 'PASSER_A_EDITE_DIPLOME_SUCCESS'
export const PASSER_A_EDITE_DIPLOME_ERROR = 'PASSER_A_EDITE_DIPLOME_ERROR'
export const passerAEditeDiplome = (idExamenType) => dispatch => {
    dispatch({ type: PASSER_A_EDITE_DIPLOME_REQUEST })

    return api.passerAEditeDiplome(idExamenType)
        .then(() => dispatch({ type: PASSER_A_EDITE_DIPLOME_SUCCESS }))
        .catch(e => {
            dispatch({ type: PASSER_A_EDITE_DIPLOME_ERROR })
            throw e
        })
}
