import { combineReducers } from 'redux'
import * as actions from './examinateurEtrangerActions'
import { HANDLE_QUERY_LISTE_EXAMINATEURS_ETRANGERS, RESET_QUERY_LISTE_EXAMINATEURS_ETRANGERS } from './examinateurEtrangerActions'

const open = (state = false, action) => {
	switch (action.type) {
		case actions.OPEN_POPUP:
			return true
		case actions.CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const examinateur = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_EXAMINATEUR_SUCCESS:
		case actions.SAVE_EXAMINATEUR_SUCCESS:
			return action.examinateur
		case actions.GET_EXAMINATEUR_ERROR:
			return {}
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case actions.GET_EXAMINATEUR_REQUEST:
			return true
		case actions.GET_EXAMINATEUR_SUCCESS:
		case actions.GET_EXAMINATEUR_ERROR:
			return false
		default:
			return state
	}
}

const initialRecherche = {
	search: ''
}
const recherche = (state = initialRecherche, action) => {
	switch (action.type) {
		case HANDLE_QUERY_LISTE_EXAMINATEURS_ETRANGERS:
			return {
				search: action.value
			}
		case RESET_QUERY_LISTE_EXAMINATEURS_ETRANGERS:
			return {
				search: ''
			}
		default:
			return state
	}
}

export const examinateurEtranger = combineReducers({
	open,
	examinateur,
	loading,
	recherche
})
