import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { push } from 'connected-react-router'
import { dataTableActions, injectToolbarData, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import ModificationTopicPopup from './ModificationTopicPopup'
import NouveauTopicPopup from './NouveauTopicPopup'
import { getFilArianeTopics } from '../service/filArianeProfil'
import { injectDetailMatiereWithIdMatiere } from '../service/injectDetailMatiereWithIdMatiere'
import { getDetailsTopic, suppressionTopic } from '../service/ligneProgrammeAction'
import SupprimerTopicPopup from './SupprimerTopicPopup'

const ListeTopicsPage = ({ suppressionTopic, getDetailsTopic, refreshTable, match, goToParagraphePage }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [openModifier, setOpenModifier] = React.useState(false)
	const [openSupprimer, setOpenSupprimer] = React.useState(false)
	const [idTopic, setIdTopic] = React.useState(null)
	const idMatiere = match.params.idMatiere

	const clickModifier = (id) => {
		getDetailsTopic(id, idMatiere)
			.then(() => setOpenModifier(true))
	}

	const clickSupprimer = (id) => {
		setIdTopic(id)
		setOpenSupprimer(true)
	}

	const deleteAction = () => {
		return suppressionTopic(idTopic, idMatiere)
			.then(() => {
				refreshTable()
				setOpenSupprimer(false)
			})
	}

	return (
		<React.Fragment>
			<SpringDataTable
				apiUrl={`/matiere/${idMatiere}/listeTopics`}
				headers={[
					{
						key: 'code',
						name: 'Code',
						sortable: true
					},
					{
						key: 'libelle',
						name: 'Libelle',
						sortable: true
					}

				]}
				nom="listeTopics"
				noResultFragment={<EmptyResult />}
				makeActions={row => [
					{ label: 'Modifier', action: () => clickModifier(row.id) },
					{ label: 'Gérer les paragraphes', action: () => goToParagraphePage(idMatiere, row.id) },
					{ label: 'Supprimer', action: () => clickSupprimer(row.id) }
				]}
				showActions
			/>
			<FloatingButton onClick={() => setOpenCreation(true)} />
			<NouveauTopicPopup
				open={openCreation}
				closePopupCreation={() => setOpenCreation(false)}
				idMatiere={idMatiere}
			/>
			<SupprimerTopicPopup
				open={openSupprimer}
				closePopupSuppression={() => setOpenSupprimer(false)}
				onSubmit={deleteAction}
			/>
			<ModificationTopicPopup
				open={openModifier}
				closePopupModification={() => setOpenModifier(false)}
				idMatiere={idMatiere}
			/>
		</React.Fragment>
	)
}

const actions = {
	suppressionTopic,
	getDetailsTopic,
	refreshTable: () => dataTableActions.refresh('listeTopics'),
	goToParagraphePage: (idMatiere, idTopic) => dispatch => dispatch(push(`/ligne-programme/${idMatiere}/liste-topics/${idTopic}/liste-paragraphes`)),
}

ListeTopicsPage.propTypes = {
	suppressionTopic: PropTypes.func,
	refreshTable: PropTypes.func,
	detailMatiere: PropTypes.object,
	getDetailsTopic: PropTypes.func,
	goToParagraphePage: PropTypes.func
}

export default compose(
	injectDetailMatiereWithIdMatiere,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
	),
	connect(null, actions),
	injectSession,
	injectToolbarData(({ detailsMatiere }) => ({
		...getFilArianeTopics(
			detailsMatiere,
			true
		),
		title: 'Liste de topics'
	}))
)(ListeTopicsPage)
