import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles, Title, FormInput } from 'isotope-client'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { Actions, Button, DateInput, InputRadio, INS_ETAT_EPREUVE_INSCRIPTION, BandeauInfo, injectFormSnackbar } from '@oceane/ui'
import { modifierFraude } from './services/gestionCopiesActions'
import { RES_ID_TYPE_SANCTION_EPREUVE } from '../../../config/enums'
import Grid from '@material-ui/core/Grid'
import * as selectors from './services/gestionCopiesSelectors'

const styles = () => getStyles({
	selectSanction: {
		marginBottom: 13
	}
})

const choices = [{
	value: RES_ID_TYPE_SANCTION_EPREUVE.EPREUVE,
	label: 'Epreuve'
}, {
	value: RES_ID_TYPE_SANCTION_EPREUVE.EXAMEN,
	label: 'Examen'
}, {
	value: RES_ID_TYPE_SANCTION_EPREUVE.CANDIDAT,
	label: 'Candidat'
}]

const validate = values => {
	const errors = {}
	if (!values.commentaire) {
		errors.commentaire = { id: 'global.erreurs.obligatoire' }
	}
	if (values.sanction === RES_ID_TYPE_SANCTION_EPREUVE.CANDIDAT && !values.dateFinSanction) {
		errors.dateFinSanction = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}


const ResultatEpreuveFraudeForm = ({ isFraude, isSanctionCandidat, handleSubmit, change, modifierFraude, classes }) => {

	const submit = values => modifierFraude(values)
		.catch(e => {
			throw new SubmissionError(e)
		})

	return (
		<>
			<Title value="Fraude" />
			{!isFraude && <BandeauInfo message="Attention, la déclaration d'une fraude est irréversible !"/>}
			<Grid container direction="row" alignItems="flex-end">
				<Grid item className={classes.selectSanction}>
					<Field
						name="sanction"
						component={InputRadio}
						choices={choices}
						disabled={isFraude}
						onChange={(event, value, prev) => {
							if (RES_ID_TYPE_SANCTION_EPREUVE.CANDIDAT !== value && RES_ID_TYPE_SANCTION_EPREUVE.CANDIDAT === prev) {
								change('dateFinSanction', '')
							}
						}}
					/>
				</Grid>
				{isSanctionCandidat && <Grid item>
					<Field
						name="dateFinSanction"
						label="Jusqu'au *"
						component={DateInput}
					/>
				</Grid>}
			</Grid>
			<Field
				name="commentaire"
				label="Commentaire *"
				component={FormInput}
				fullWidth
				multiline
				rows={5}
				rowsMax={5}
			/>
			<Actions>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(submit)}
				>
					{isFraude ? 'Enregistrer' : 'Fraude'}
				</Button>
			</Actions>
		</>
	)
}

ResultatEpreuveFraudeForm.propTypes = {
	isFraude: PropTypes.bool,
	isSanctionCandidat: PropTypes.bool,
	handleSubmit: PropTypes.func,
	change: PropTypes.func,
	modifierFraude: PropTypes.func,
	classes: PropTypes.object
}

const FORM_NAME = 'resultatEpreuveFraude'


const selectFormValues = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => ({
	isFraude: selectors.getEtat(state) === INS_ETAT_EPREUVE_INSCRIPTION.FRAUDE,
	isSanctionCandidat: RES_ID_TYPE_SANCTION_EPREUVE.CANDIDAT === selectFormValues(state, 'sanction'),
	initialValues: selectors.getFraude(state)
})

const actions = {
	modifierFraude
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectFormSnackbar()
)(ResultatEpreuveFraudeForm)
