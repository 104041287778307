import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { FormattedMessage, injectIntl } from 'react-intl'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getStyles, injectToolbarData, StaticDataTable, Title } from 'isotope-client'
import { checkAuthorities, DownloadIcon, EmptyResult, Loader, MultilineTooltip, PROFIL_UTILISATEUR } from '@oceane/ui'
import moment from 'moment'
import { getListeDerniersImports, getListeImportsProgrammes } from './service/importKSAActions'
import NouvelImportKSAPopup from './components/NouvelImportKSAPopup'
import { injectMesMatieres } from '../../references/matieres/services/injectListeMatieres'
import SuppressionImportKSAPopup from './components/SuppressionImportKSAPopup'
import { BNQ_ETAT_IMPORT_KSA } from '../../../config/enums'
import { download } from 'isotope-client/components/download/IsotopeLink'

const styles = () => getStyles({
	iconButton: {
		padding: 5,
		marginLeft: -5
	},
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})

const ImportKSAPage = ({ getListeImportsProgrammes, getListeDerniersImports, classes, snackError }) => {

	const [loading, setLoading] = React.useState(true)
	const [importsProgrammes, setImportsProgrammes] = React.useState([])
	const [derniersImports, setDerniersImports] = React.useState([])
	const [openPopup, setOpenPopup] = React.useState(false)
	const [popupSuppression, setPopupSuppression] = React.useState({
		open: false,
		importASupprimer: {}
	})

	const openPopupSuppression = (importASupprimer) => setPopupSuppression({
		open: true,
		importASupprimer
	})

	const closePopupSuppression = () => setPopupSuppression(popup => ({
		...popup,
		open: false
	}))

	const onPopupSuppressionExited = () => setPopupSuppression(popup => ({
		...popup,
		importASupprimer: {}
	}))

	const reloadTables = () => Promise.all([getListeImportsProgrammes(), getListeDerniersImports()])
		.then(([{ importsProgrammes }, { derniersImports }]) => {
			setImportsProgrammes(importsProgrammes)
			setDerniersImports(derniersImports)
		})
		.catch(() => {
			snackError()
		})

	React.useEffect(() => {
		Promise.all([getListeImportsProgrammes(), getListeDerniersImports()])
			.then(([{ importsProgrammes }, { derniersImports }]) => {
				setImportsProgrammes(importsProgrammes)
				setDerniersImports(derniersImports)
				setLoading(false)
			})
			.catch(() => {
				snackError()
			})
	}, [])

	if (loading) {
		return <Loader />
	}

	return (
		<>
			<Title value="Imports programmés" />
			<StaticDataTable
				noResultFragment={<EmptyResult message="Aucun import programmé" />}
				data={importsProgrammes}
				headers={[{
					key: 'matiere',
					name: 'Matiere',
					sortable: false
				}, {
					key: 'version',
					name: 'Version',
					sortable: false
				}, {
					key: 'date',
					name: 'Date de programmation',
					sortable: false,
					render: row => moment(row.date).format('DD/MM/YYYY')
				}, {
					key: 'fichierCharge',
					name: 'Fichier d\'import',
					sortable: false
				}, {
					key: 'etat',
					name: 'État',
					sortable: false,
					render: row => <FormattedMessage id={`enums.bnqEtatImport100ksa.${row.etat}`} />
				}, {
					key: 'supprimer',
					sortable: false,
					render: row => {
						if (row.etat === BNQ_ETAT_IMPORT_KSA.EN_ATTENTE) {
							return <IconButton
								disableRipple
								className={classes.iconButton}
								onClick={() => openPopupSuppression(row)}
							>
								<DeleteIcon />
							</IconButton>
						}
					}
				}]}
				noPagination
			/>
			<Title value="Derniers imports" />
			<StaticDataTable
				noResultFragment={<EmptyResult message="Aucun import récent" />}
				data={derniersImports}
				headers={[{
					key: 'matiere',
					name: 'Matiere',
					sortable: false
				}, {
					key: 'version',
					name: 'Version',
					sortable: false
				}, {
					key: 'date',
					name: 'Date de programmation',
					sortable: false,
					render: row => moment(row.date).format('DD/MM/YYYY')
				}, {
					key: 'etat',
					name: 'État',
					sortable: false,
					render: row => <FormattedMessage id={`enums.bnqEtatImport100ksa.${row.etat}`} />
				}, {
					key: 'fichier',
					name: 'Compte-rendu',
					sortable: false,
					render: row => {
						if (Boolean(row.compteRendu)) {
							return <IconButton
								disableRippled
								className={classes.iconButton}
								onClick={() => download(`/files/import-ksa/${row.id}`)}
							>
								<DownloadIcon />
							</IconButton>
						} else {
							return <MultilineTooltip
								message="Le document n'est plus disponible"
								placement="top-start"
							>
								<div>
									<IconButton
										className={classes.iconButton}
										disableRipple
										disabled
									>
										<DownloadIcon />
									</IconButton>
								</div>
							</MultilineTooltip>
						}
					}
				}]}
				noPagination
			/>
			<Fab
				color="primary"
				label="Add"
				className={classes.fab}
				onClick={() => setOpenPopup(true)}
			>
				<AddIcon />
			</Fab>
			<NouvelImportKSAPopup open={openPopup} closePopup={() => setOpenPopup(false)} reloadTables={reloadTables} />
			<SuppressionImportKSAPopup {...popupSuppression} closePopup={closePopupSuppression} onExited={onPopupSuppressionExited} reloadTables={reloadTables} />
		</>
	)
}

ImportKSAPage.propTypes = {
	getListeImportsProgrammes: PropTypes.func,
	getListeDerniersImports: PropTypes.func,
	snackError: PropTypes.func,
	classes: PropTypes.object
}

const actions = {
	getListeImportsProgrammes,
	getListeDerniersImports
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectMesMatieres,
	withStyles(styles),
	injectSnackActions,
	injectIntl,
	injectToolbarData({ title: { id: 'toolbarTitle.importKSA' } }),
	connect(undefined, actions)
)(ImportKSAPage)
