import * as api from './examinateurEtrangerApi'

export const OPEN_POPUP = 'NOUVEAU_EXAMINATEUR_ETRANGER/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'NOUVEAU_EXAMINATEUR_ETRANGER/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const CREATE_EXAMINATEUR_ETRANGER = 'CREATE_EXAMINATEUR_ETRANGER'
export const createExaminateurEtranger = (values) => dispatch => {
	dispatch({ type: CREATE_EXAMINATEUR_ETRANGER })
	return api.createExaminateurEtranger(values)
		.catch((e) => {
			throw e
		})
}

export const GET_EXAMINATEUR_REQUEST = 'GET_EXAMINATEUR_REQUEST'
export const GET_EXAMINATEUR_SUCCESS = 'GET_EXAMINATEUR_SUCCESS'
export const GET_EXAMINATEUR_ERROR = 'GET_EXAMINATEUR_ERROR'
export const getExaminateur = (id) => dispatch => {
	dispatch({ type: GET_EXAMINATEUR_REQUEST })
	return api.getExaminateur(id)
		.then(examinateur =>
			dispatch({
				type: GET_EXAMINATEUR_SUCCESS,
				examinateur
			})
		)
		.catch(e =>
			dispatch({
				type: GET_EXAMINATEUR_ERROR,
				e
			})
		)
}

export const SAVE_EXAMINATEUR_REQUEST = 'SAVE_EXAMINATEUR_REQUEST'
export const SAVE_EXAMINATEUR_SUCCESS = 'SAVE_EXAMINATEUR_SUCCESS'
export const SAVE_EXAMINATEUR_ERROR = 'SAVE_EXAMINATEUR_REQUEST'
export const saveExaminateur = values => dispatch => {
	dispatch({ type: SAVE_EXAMINATEUR_REQUEST })
	return api.putExaminateur(values)
		.then(examinateur =>
			dispatch({
				type: SAVE_EXAMINATEUR_SUCCESS,
				examinateur
			})
		)
		.catch(e => {
			dispatch({
				type: SAVE_EXAMINATEUR_ERROR,
				e
			})
			throw e
		})
}

export const HANDLE_QUERY_LISTE_EXAMINATEURS_ETRANGERS = 'HANDLE_QUERY_LISTE_EXAMINATEURS_ETRANGERS'
export const RESET_QUERY_LISTE_EXAMINATEURS_ETRANGERS = "RESET_QUERY_LISTE_EXAMINATEURS_ETRANGERS"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_LISTE_EXAMINATEURS_ETRANGERS,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_LISTE_EXAMINATEURS_ETRANGERS
	})
}