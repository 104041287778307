import Typography from '@material-ui/core/Typography'
import { Popup, Error, Button } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { deleteFamille } from '../services/familleAction'
import { familleShape } from '../../../../config/shapes'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { dataTableActions } from 'isotope-client'
import { goBack } from 'connected-react-router'


const SuppressionFamillePopup = ({ open, famille, onClose, deleteFamille, snackSuccess, refreshTable, goBack }) => {
	const [error, setError] = React.useState()

	const popupContent = () => (
		<React.Fragment>
			{error && <Error error={error} isPopinContainer />}
			<Typography variant="body1">
				Voulez-vous supprimer la famille ?
			</Typography>
		</React.Fragment>
	)

	const submit = () => {
		deleteFamille(famille.id, famille.idMatiere)
			.then(() => {
				onClose()
				snackSuccess('Suppression de la famille réussie')
				refreshTable()
				goBack()
			})
			.catch((e) => {
				if (e.bodyError) {
					setError(e.bodyError)
				}
			})
	}

	const actions = [
		<Button
			key="annuler"
			color="primary"
			onClick={onClose}
		>
			Annuler
		</Button>,
		<Button
			key="supprimer"
			color="primary"
			variant="contained"
			onClick={submit}
		>
			Supprimer
		</Button>
	]

	return (
		<Popup
			open={open}
			title="Suppression d'une famille"
			content={popupContent()}
			actions={actions}
		/>
	)
}

const actions = {
	deleteFamille,
	refreshTable: () => dataTableActions.refresh('listeFamille'),
	goBack: () => dispatch => dispatch(goBack())
}

SuppressionFamillePopup.propTypes = {
	famille: familleShape,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	deleteFamille: PropTypes.func,
	snackSuccess: PropTypes.func,
	refreshTable: PropTypes.func,
	goBack: PropTypes.func
}

export default compose(
	connect(null, actions),
	injectSnackActions
)(SuppressionFamillePopup)
