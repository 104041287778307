import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { dataTableActions, injectToolbarData, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ModificationLignePopup from './ModificationLignePopup'
import NouvelleLignePopup from './NouvelleLignePopup'
import { getFilArianeLigne } from '../service/filArianeProfil'
import { injectDetailMatiereWithIdMatiere } from '../service/injectDetailMatiereWithIdMatiere'
import { injectDetailParagrapheWithIdParagraphe } from '../service/injectDetailParagrapheWithIdParagraphe'
import { injectDetailSousParagrapheWithIdSousParagraphe } from '../service/injectDetailSousParagrapheWithIdSousParagraphe'
import { injectDetailTopicWithIdTopic } from '../service/injectDetailTopicWithIdTopic'
import { getDetailsLigne, suppressionLigne } from '../service/ligneProgrammeAction'
import SupprimerLignePopup from './SupprimerLignePopup'

const ListeLignePage = ({ classes, refreshTable, match, suppressionLigne, getDetailsLigne }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [openModifier, setOpenModifier] = React.useState(false)
	const [openSupprimer, setOpenSupprimer] = React.useState(false)
	const [idLigne, setIdLigne] = React.useState(null)
	const idMatiere = match.params.idMatiere
	const idTopic = match.params.idTopic
	const idParagraphe = match.params.idParagraphe
	const idSousParagraphe = match.params.idSousParagraphe

	const clickModifier = (id) => {
		getDetailsLigne(idMatiere, idTopic, idParagraphe, idSousParagraphe, id)
			.then(() => setOpenModifier(true))
	}

	const clickSupprimer = (id) => {
		setIdLigne(id)
		setOpenSupprimer(true)
	}

	const deleteAction = () => {
		return suppressionLigne(idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne)
			.then(() => {
				refreshTable()
				setOpenSupprimer(false)
			})
	}

	return (
		<React.Fragment>
			<SpringDataTable
				apiUrl={`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}/listeLignes`}
				headers={[
					{
						key: 'code',
						name: 'Code',
						sortable: true
					},
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: true
					}
				]}
				nom="listeLignes"
				noResultFragment={<EmptyResult />}
				makeActions={row => [
					{ label: 'Modifier', action: () => clickModifier(row.id) },
					{ label: 'Supprimer', action: () => clickSupprimer(row.id) }
				]}
				showActions
			/>
			<FloatingButton onClick={() => setOpenCreation(true)} />
			<NouvelleLignePopup
				idMatiere={idMatiere}
				idTopic={idTopic}
				idParagraphe={idParagraphe}
				idSousParagraphe={idSousParagraphe}
				closePopupCreation={() => setOpenCreation(false)}
				open={openCreation}
			/>
			<ModificationLignePopup
				idMatiere={idMatiere}
				idTopic={idTopic}
				idParagraphe={idParagraphe}
				idSousParagraphe={idSousParagraphe}
				closePopupModification={() => setOpenModifier(false)}
				open={openModifier}
			/>
			<SupprimerLignePopup
				open={openSupprimer}
				closePopupSuppression={() => setOpenSupprimer(false)}
				onSubmit={deleteAction}
			/>
		</React.Fragment>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('listeLignes'),
	suppressionLigne,
	getDetailsLigne
}

ListeLignePage.propTypes = {
	refreshTable: PropTypes.func,
	detailMatiere: PropTypes.object,
	detailsTopic: PropTypes.object,
	detailsParagraphe: PropTypes.object,
	suppressionLigne: PropTypes.func,
	getDetailsLigne: PropTypes.func
}

export default compose(
	injectDetailMatiereWithIdMatiere,
	injectDetailTopicWithIdTopic,
	injectDetailParagrapheWithIdParagraphe,
	injectDetailSousParagrapheWithIdSousParagraphe,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS,
	),
	connect(null, actions),
	injectSession,
	injectToolbarData(({ detailsMatiere, detailsTopic, detailsParagraphe, detailsSousParagraphe }) => ({
		...getFilArianeLigne(
			detailsMatiere,
			detailsTopic,
			detailsParagraphe,
			detailsSousParagraphe.code,
			true
		),
		title: { id: 'Liste des lignes de programme' }
	}))
)(ListeLignePage)
