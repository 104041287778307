export const ETAT_SESSION = {
	CREE: 1,
	INITIALISEE: 2,
	INSCRIPTIONS_CLOSES: 3,
	PLANIFIEE: 4,
	CONVOCATIONS_ENVOYEES: 5,
	PV_SAISIS: 6,
	CORRIGEE: 7,
	CLOTUREE: 8
}

/**
 * Valeurs possibles de <code>examencandidat.examenspecifique</code>.
 */
export const EXAMEN_SPECIFIQUE = {
	PARAPRO_P: 1,
	CCAP: 2,
	CATS: 3
}

/**
 * Statut global du dossier de pièces justificatives du candidat pour un examen spécifique qui les requiert.
 */
export const EXAMEN_CANDIDAT_STATUT_DOSSIER = {
	A_COMPLETER: 1,
	A_VALIDER: 2,
	NON_CONFORME: 3,
	VALIDE: 4
}