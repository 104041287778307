import * as api from './nouveauCompteClientPopupApi'

export const OPEN_POPUP = 'NOUVEAU_COMPTE_CLIENT/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'NOUVEAU_COMPTE_CLIENT/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const CREATE_COMPTE_CLIENT = 'CREATE_COMPTE_CLIENT'
export const createCompteClient = (values) => dispatch => {
	return api.createCompteClient(values)
		.catch((e) => {
			throw e
		})
}
