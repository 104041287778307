import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getListeMatieres, getMesMatieres } from './matieresActions'
import * as selectors from './matieresSelectors'
import { Loader } from '@oceane/ui'
import * as shapes from '../../../../config/shapes'


export const injectListeMatieres = (WrappedComponent) => {
	const ListMatieresInjector = ({ listeMatieres, getListeMatieres, ...props }) => {
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			if (!listeMatieres || !listeMatieres.length) {
				setLoading(true)
				getListeMatieres()
					.then(() => setLoading(false))
					.catch(() => setLoading(false))
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent listeMatieres={listeMatieres} {...props} />
	}

	const mapStateToProps = state => ({
		listeMatieres: selectors.getListeMatieres(state)
	})

	const actions = {
		getListeMatieres
	}

	ListMatieresInjector.propTypes = {
		getListeMatieres: PropTypes.func,
		listeMatieres: PropTypes.array
	}

	return connect(mapStateToProps, actions)(ListMatieresInjector)
}

export const injectMesMatieres = WrappedComponent => {
	const MesMatieresInjector = ({ matieres, getMesMatieres, ...props }) => {
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			if (!matieres || !matieres.length) {
				getMesMatieres()
					.then(() => setLoading(false))
					.catch(() => setLoading(false))
			} else {
				setLoading(false)
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent matieres={matieres} {...props} />
	}

	MesMatieresInjector.propTypes = {
		getMesMatieres: PropTypes.func,
		matieres: PropTypes.arrayOf(shapes.matiereReferenceShape)
	}


	const mapStateToProps = state => ({
		matieres: selectors.getMesMatieres(state)
	})

	const actions = {
		getMesMatieres
	}

	return connect(mapStateToProps, actions)(MesMatieresInjector)
}
