import { checkAuthorities, Tabs, PROFIL_UTILISATEUR, injectSession } from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { push } from 'connected-react-router'
import Tab from '@material-ui/core/Tab'
import { connect } from 'react-redux'
import GenerationSujetsOnglet from './GenerationSujetsOnglet'
import CreationSessionRapideOnglet from './CreationSessionRapideOnglet'

const GENERATION = 0
const CREATION = 1

const TABS = {
	[GENERATION]: 'generation',
	[CREATION]: 'creation'
}

const ListeEpreuvesSujet = ({ location: { state }, switchTab, tab}) => {

	const [sessionRapide, setSessionRapide] = React.useState(null)

	const handleChangeTab = (value) => {
		switchTab(TABS[value])
	}

	const tabViews = [
		<GenerationSujetsOnglet sessionRapide={sessionRapide} />,
		<CreationSessionRapideOnglet changeTab={(value, sessionR) => {
			handleChangeTab(value)
			setSessionRapide(sessionR)
		}}/>
	]

	return (
		<React.Fragment>
			<Tabs
				initialTab={0}
				tabs={[
					<Tab key="generation-sujet" label="Génération sujets" />,
					<Tab key="creation-sessions" label="Création sessions rapides" />
				]}
				tabViews={tabViews}
				onChangeTab={handleChangeTab}
				tabValue={tab}
			/>
		</React.Fragment>
	)
}

const actions = {
	switchTab: (tab) => dispatch => dispatch(push(`/generer-sujet/${tab}`))
}

const mapStateToProps = (state, ownProps) => ({
	tab: ownProps.match.params.tab === TABS[GENERATION] ? GENERATION : CREATION,
})

export default compose(
	connect(mapStateToProps, actions),
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectSession
)(ListeEpreuvesSujet)
