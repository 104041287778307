import { fetchFactory } from 'isotope-client'

export const deleteFormationOrganisme = (id) => fetchFactory(`/organismes/${id}/listesFormations`, {
	method: 'delete',
})

export const getExamensTypePratique = () => fetchFactory('/organismes/examenType')

export const getFormationTypePratique = (examenTypeId, organismeId) => fetchFactory(`/organismes/${organismeId}/formationType/${examenTypeId}`)

export const createFormationOrganisme = (values, organismeId) => fetchFactory(`/organismes/${organismeId}/listesFormations`, {
	method: 'post',
	body: JSON.stringify({typeFormationId: values.typeFormation, examenTypeId: values.examen})
})