import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { checkAuthorities, EmptyResult, FloatingButton, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import NouveauSitePapierPopup from './NouveauSitePapierPopup'
import { getSites } from './services/centrePapierApi'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'


const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const ListeSitesEcran = ({ classes, match, nom, goToDetails }) => {
	const [openCreation, setOpenCreation] = React.useState(false)
	const [sites, setSites] = React.useState([])

	const idCentrePapier = match.params.id

	useEffect(() => {
		getSites(idCentrePapier)
			.then((response) => setSites(response))
	}, [])

	const closePopup = () => {
		setOpenCreation(false)
		getSites(idCentrePapier)
			.then((response) => setSites(response))
	}

	return (
		<>
			<Title value="Sites d'un centre" />
			<StaticDataTable
				headers={[
					{
						key: 'libelle',
						name: 'Sites du centre',
						render: data => <Typography className={classes.libelleColumn}
													onClick={() => goToDetails(idCentrePapier, data.id)}>{data.libelle}</Typography>
					},
					{
						key: 'capacite',
						name: 'Capacité'
					}
				]}
				defaultPageSize={10}
				data={sites}
				nom="listeSites"
				noResultFragment={<EmptyResult />}
			/>
			<FloatingButton onClick={() => setOpenCreation(true)} />
			<NouveauSitePapierPopup open={openCreation} closePopupCreation={closePopup} nom={nom}
									idCentre={idCentrePapier} />
		</>
	)
}

const actions = {
	goToDetails: (idCentre, idSite) => dispatch => dispatch(push({
		pathname: `/centres/${idCentre}/${idSite}`
	}))
}

ListeSitesEcran.propTypes = {
	getSites: PropTypes.func
}

export default compose(
	connect(null, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectSession,
	withStyles(style)
)(ListeSitesEcran)
