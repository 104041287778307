import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Button, InputRadio, Popup, RES_ID_REPONSE_LIBELLE } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { modifierReponseQuestion } from '../service/correctionCopiesActions'

const ReponsesQuestionPopup = ({
								   open,
								   change,
								   question,
								   closePopup,
								   snackError,
								   snackSuccess,
								   handleSubmit,
								   idCopiePapier,
								   idEpreuveSession,
								   modifierReponseQuestion
							   }) => {

	React.useEffect(() => {
		if (question !== null) {
			change('reponse', `${question.reponse}`)
		}
	}, [question])

	const modifierReponse = values => {
		return modifierReponseQuestion(idEpreuveSession, question.id, values.reponse, idCopiePapier)
			.then(() => {
				closePopup()
				snackSuccess()
			})
			.catch(() => {
				snackError()
			})
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={handleSubmit(modifierReponse)}
		>
			Enregistrer
		</Button>
	]
	if (question !== null) {
		return <Popup
			open={open}
			actions={actions}
			title={`Question n°${question.positionQuestion}`}
			content={<Field
				id="reponse"
				name="reponse"
				label="Réponse"
				component={InputRadio}
				choices={Object.keys(RES_ID_REPONSE_LIBELLE).map((value, key) => {
					return {
						value: value,
						label: RES_ID_REPONSE_LIBELLE[Number(value)]
					}
				})}
			/>}
		/>
	}
	return null
}

ReponsesQuestionPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	question: PropTypes.object
}

const actions = {
	modifierReponseQuestion
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'modifierReponseQuestion'
	}),
	injectSnackActions
)(ReponsesQuestionPopup)
