import { combineReducers } from 'redux'
import * as actions from './typesFormationActions'

const ids = (state = [], action) => {
	switch (action.type) {
		case actions.GET_LISTE_TYPES_FORMATION_SUCCESS:
			return (action.result || []).map(type => type.id)
		default:
			return state
	}
}

const byId = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_LISTE_TYPES_FORMATION_SUCCESS:
			return (action.result || []).reduce((acc, type) => ({
				...acc,
				[type.id]: type
			}), state)
		default:
			return state
	}
}

const proprietesInit = {}
const proprietes = (state = proprietesInit, action) => {
	switch (action.type) {
		case actions.CREER_TYPE_FORMATION_SUCCESS:
		case actions.GET_TYPE_FORMATION_SUCCESS:
			return action.result
		case actions.CLEAR_TYPE_FORMATION:
			return proprietesInit
		default:
			return state
	}
}

export const typesFormation = combineReducers({
	ids,
	byId,
	proprietes
})
