import { Button, Input, Popup, injectFormSnackbar } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { modificationLigne } from '../service/ligneProgrammeAction'
import * as selectors from '../service/ligneProgrammeSelectors'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const ModificationLignePopup = ({ modificationLigne, refreshTable, closePopupModification, idMatiere, idTopic, idParagraphe, idSousParagraphe, reset, handleSubmit, open }) => {

	const submitModificationLigne = (values) => {
		return modificationLigne(values, idMatiere, idTopic, idParagraphe, idSousParagraphe)
			.then(() => {
				refreshTable()
				closePopupModification()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const closePopup = () => {
		reset()
		closePopupModification()
	}

	const popupContent = () => (
		<form
			id="modificationLigneForm"
		>
			<Field
				id="code"
				name="code"
				label="Code *"
				component={Input}
			/>
			<Field
				id="libelle"
				name="libelle"
				label="Libellé *"
				component={Input}
			/>
		</form>
	)

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitModificationLigne)}
		>
			Enregistrer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Modification de la ligne"
			content={popupContent()}
			onExited={closePopup}
		/>
	)
}

ModificationLignePopup.propTypes = {
	open: PropTypes.bool,
	closePopupModification: PropTypes.func,
	modificationLigne: PropTypes.func,
	idMatiere: PropTypes.string
}

const mapStateToProps = state => ({
	initialValues: selectors.getDetailLigne(state)
})

const actions = {
	modificationLigne,
	refreshTable: () => dataTableActions.refresh('listeLignes')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modificationLigneForm',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar()
)(ModificationLignePopup)
