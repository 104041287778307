import { fetchFactory } from 'isotope-client'

export const deleteFormationTheoriqueOrganisme = (id) => fetchFactory(`/organismes/${id}/listesFormationsTheorique`, {
	method: 'delete',
})

export const getExamensTypeTheorique = (idOrganisme) => fetchFactory(`/organismes/${idOrganisme}/examenTypeTheorique`)

export const createFormationTheoriqueOrganisme = ({ examen: libelleExamen }, id) => fetchFactory(`/organismes/${id}/examenTypeTheorique`, {
	method: 'post',
	body: libelleExamen
})