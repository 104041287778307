import Typography from '@material-ui/core/Typography'
import { Popup, Button } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { closePopup } from './services/examenPratiqueOrganisme/examenPratiqueOrganismeAction'
import * as selectors from './services/examenPratiqueOrganisme/examenPratiqueSelector'

const ConfirmSupressionPratiquePopup = ({ open, closePopup, onSubmit }) => {
	const actions = [
		<Button
			key="annuler"
			color="primary"
			onClick={closePopup}
		>
			Annuler
		</Button>,
		<Button
			key="supprimer"
			color="primary"
			variant="contained"
			onClick={onSubmit}
		>
			Supprimer
		</Button>
	]

	return (
		<Popup
			open={open}
			title="Suppression d'une formation"
			content={<Typography variant="body1">
				Voulez-vous supprimer la formation ?
			</Typography>}
			actions={actions}
		/>
	)
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state)
})

const actions = {
	closePopup
}

ConfirmSupressionPratiquePopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	onSubmit: PropTypes.func.isRequired
}

export default compose(
	connect(mapStateToProps, actions)
)(ConfirmSupressionPratiquePopup)
