import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Title } from 'isotope-client'
import { Button, Input, ResponsiveForm, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import * as selectors from './services/scriptSelectors'
import { ELS_SCRIPT_ETAT } from '../../../config/enums'
import { saveParametrage } from './services/scriptActions'

const styles = () => ({
	saveButton: {
		marginTop: 15
	}
})

const validate = values => {
	const errors = {}
	if (!values.nbQuestions) {
		errors.nbQuestions = { id: 'global.erreurs.obligatoire' }
	} else if (isNaN(values.nbQuestions)) {
		errors.nbQuestions = { id: 'global.erreurs.numeric' }
	}
	if (!values.pourcentageReussite) {
		errors.pourcentageReussite = { id: 'global.erreurs.obligatoire' }
	} else {
		const pourcentageReussite = isNaN(values.pourcentageReussite) ? Number(values.pourcentageReussite.replace(/,/g, '.')) : values.pourcentageReussite
		if (isNaN(pourcentageReussite)) {
			errors.pourcentageReussite = { id: 'global.erreurs.numeric' }
		} else if (pourcentageReussite < 0 || pourcentageReussite > 100) {
			errors.pourcentageReussite = 'Le champ doit être au format suivant (99,99)'
		}
	}
	return errors
}

const ParametrageForm = ({ idScript, etat, saveParametrage, handleSubmit, submitting, classes }) => {

	const submit = values => saveParametrage(idScript, values)
		.catch(e => {
			throw new SubmissionError(e)
		})

	return (
		<ResponsiveForm>
			<Title value="Paramétrage" />
			<Grid container direction="row" alignItems="flex-start">
				<Grid item xs={5}>
					<Field
						name="nbQuestions"
						label="Nombre de questions *"
						component={Input}
						disabled={etat !== ELS_SCRIPT_ETAT.CREE}
					/>
				</Grid>
				<Grid item xs={5}>
					<Field
						name="pourcentageReussite"
						label="Pourcentage de réussite *"
						component={Input}
						disabled={etat !== ELS_SCRIPT_ETAT.CREE}
					/>
				</Grid>
				<Grid item xs={2}>
					{etat === ELS_SCRIPT_ETAT.CREE && <Button
						className={classes.saveButton}
						color="primary"
						variant="contained"
						loading={submitting}
						onClick={handleSubmit(submit)}
					>
						Enregistrer
					</Button>}
				</Grid>
			</Grid>
		</ResponsiveForm>
	)
}


ParametrageForm.propTypes = {
	idScript: PropTypes.number,
	etat: PropTypes.number,
	initialValues: PropTypes.shape({
		nbQuestions: PropTypes.number,
		pourcentageReussite: PropTypes.string
	}),
	classes: PropTypes.object,
	saveParametrage: PropTypes.func
}

const mapStateToProps = (state) => {
	const script = selectors.getParametrage(state)
	return ({
		idScript: script.id,
		etat: script.etat,
		initialValues: {
			nbQuestions: script.nbQuestions,
			pourcentageReussite: Number(script.pourcentageReussite).toFixed(1).replace(/\./g, ',')
		}
	})
}

const actions = {
	saveParametrage
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'parametrageScript',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(ParametrageForm)
