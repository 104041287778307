import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getDecalageHoraire } from '../../localisationSites/services/sitesPhysiquesAction'

export const loadDecalageHoraire = (WrappedComponent) => {
	const DecalageHoraireInjector = (props) => {
		const { getDecalageHoraire, ...otherProps } = props

		const [decalageH, setDecalageH] = React.useState([])
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			getDecalageHoraire()
				.then(({ decalagesHoraires }) => {
					setLoading(false)
					setDecalageH(decalagesHoraires)
				})
				.catch(() => {
					setLoading(false)
					otherProps.snackError()
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent decalageH={decalageH} {...otherProps} />
	}

	DecalageHoraireInjector.propTypes = {
		getDecalageHoraire: PropTypes.func,
		snackError: PropTypes.func
	}

	const actions = {
		getDecalageHoraire
	}

	return compose(
		connect(null, actions),
		injectSnackActions
	)(DecalageHoraireInjector)
}