import React from 'react'
import { connect } from 'react-redux'
import { getEpreuveSession } from './correctionCopiesActions'
import * as selectors from './correctionCopiesSelectors'
import { Loader } from '@oceane/ui'

export const injectEpreuveSession = (WrappedComponent) => {

	const injectEpreuveSession = ({ ...props }) => {
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			const idEpreuve = props.match.params.idEpreuve
			if (idEpreuve && epreuveSession.id !== Number(idEpreuve)) {
				props.getEpreuveSession(idEpreuve)
					.then(() => {
						setLoading(false)
					})
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent {...props} />
	}

	const epreuveSession = state => ({
		epreuveSession: selectors.getEpreuveSession(state)
	})

	const actions = {
		getEpreuveSession
	}

	return connect(epreuveSession, actions)(injectEpreuveSession)
}
