import {
	Actions,
	Button,
	checkAuthorities,
	injectSession,
	isEmailOk,
	isPrenomOuNomOk,
	isTelephoneOk,
	ResponsiveForm,
	PROFIL_UTILISATEUR,
	Input,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MailIcon from '@material-ui/icons/Mail'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getStyles, Title, Link } from 'isotope-client'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { loadCentre } from './services/centreInjector'
import { enregistrerCentre } from './services/centrePapierActions'

const style = () => getStyles({
	tooltip: {
		margin: '-15px 0 0 16px'
	},
	divider: {
		color: 'rgba(0,0,0,0.12)',
		marginBottom: 16
	}
})

const validate = (values) => {
	const errors = {}

	if (values.nom && !isPrenomOuNomOk(values.nom)) {
		errors.nom = { id: 'global.erreurs.nom' }
	}

	if (values.prenom && !isPrenomOuNomOk(values.prenom)) {
		errors.prenom = { id: 'global.erreurs.nom' }
	}

	if (values.mail && !isEmailOk(values.mail)) {
		errors.mail = { id: 'global.erreurs.formatEmail' }
	}

	if (values.telephone && !isTelephoneOk(values.telephone)) {
		errors.telephone = { id: 'global.erreurs.telephone' }
	}

	if (values.telecopie && !isTelephoneOk(values.telecopie)) {
		errors.telecopie = { id: 'global.erreurs.telephone' }
	}

	return errors
}

const SurveillantEtatCivilOnglet = ({
										submitting,
										handleSubmit,
										initialValues,
										classes,
										enregistrerCentre
									}) => {
	const [email, setEmail] = React.useState(initialValues.email)

	const enregistrer = (values) =>
		enregistrerCentre(values)
			.then(({ centre }) => {
				setEmail(centre.email)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	return (
		<>
			<ResponsiveForm>
				<Title value="Données générales" />
				<Field name="libelle" label="Libellé" component={Input} disabled={true} />
				<Field name="decalageMetropole" label="Décalage horaire / métropole (hiver)" component={Input}
					   disabled={true} />
				<Field
					name="heureEte"
					label="Gestion heure d'été"
					component={Select}
					disabled
				>
					<MenuItem key={1} value={true}>
						Oui
					</MenuItem>
					<MenuItem key={2} value={false}>
						Non
					</MenuItem>
				</Field>
				<Divider className={classes.divider} />
				<Title value="Responsable" />
				<Field
					name="nom"
					label="Nom"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
				/>
				<Field
					name="prenom"
					label="Prenom"
					component={Input}
					inputProps={{
						maxLength: 30
					}}
				/>
				<Grid container>
					<Field name="email" label="Adresse électronique" component={Input} />
					<Link href={`mailto:${email}`}>
						<MailIcon className={classes.tooltip} color="primary" />
					</Link>
				</Grid>
				<Field
					name="telephone"
					label="Téléphone"
					component={Input}
					inputProps={{
						maxLength: 20
					}}
				/>
				<Field
					name="telecopie"
					label="Télécopie"
					component={Input}
					inputProps={{
						maxLength: 20
					}}
				/>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={handleSubmit(enregistrer)}
					>
						Enregistrer
					</Button>
				</Actions>
			</ResponsiveForm>
		</>
	)
}

const actions = {
	enregistrerCentre
}

SurveillantEtatCivilOnglet.propTypes = {
	enregistrerCentre: PropTypes.func
}

const mapStateToProps = (state, { centre }) => ({
	initialValues: centre
})

export default compose(
	loadCentre,
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'DetailsCentre',
		enableReinitialize: true,
		validate
	}),
	injectSession,
	withStyles(style),
	injectFormSnackbar({ success: { id: 'references.succes' } })
)(SurveillantEtatCivilOnglet)
