import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import { checkAuthorities, EmptyResult, Link, ListFiltres, PROFIL_UTILISATEUR } from '@oceane/ui'
import { injectIntl, intlShape } from 'react-intl'
import * as examensTypesActions from './services/examensTypesActions'
import { getListeExamensTypes } from './services/examensTypesActions'
import * as selectors from './services/examensTypesSelectors'
import CreerExamenTypePopup from './CreerExamenTypePopup'
import { INS_ETAT_EXAMEN_TYPE } from '../../../config/enums'

const styles = () => getStyles({
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})
const ExamensTypeListe = ({
	liste,
	getListeExamensTypes,
	intl,
	classes,
	handleQuery,
	resetQuery,
	searchValue,
	switchValue
}) => {

	const [open, setOpen] = React.useState(false)
	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	React.useEffect(() => {
		getListeExamensTypes(false, true)
	}, [])

	const examenTypesFiltered = React.useMemo(() => {
		if (liste.length !== 0) {
			return liste.filter(examenType =>
				examenType.libelleCourt.toLowerCase().includes(searchValue.toLowerCase()) && ((!switchValue && examenType.etat !== INS_ETAT_EXAMEN_TYPE.DESACTIVE) || switchValue)
			)
		}
		return []
	}, [liste, searchValue, switchValue])

	return (
		<React.Fragment>
			<Title value={intl.formatMessage({ id: 'references.examensTypes.liste.titre' })} />
			<ListFiltres
				handleQueryValue={handleQuery}
				resetQueryValue={resetQuery}
				searchValue={searchValue}
				switchValue={switchValue}
				searchPlaceholderId="references.examensTypes.liste.search"
				switchDescriptionId="references.examensTypes.liste.switch"
			/>
			<StaticDataTable
				data={examenTypesFiltered}
				noResultFragment={<EmptyResult />}
				defaultPageSize={50}
				headers={[
					{
						key: 'libelle',
						name: intl.formatMessage({ id: 'references.examensTypes.liste.headers.libelleCourt' }),
						render: row => <Link to={`/examens-types/${row.id}`}>
							{row.libelleCourt}
						</Link>,
						sortable: false
					},
					{
						key: 'type',
						name: intl.formatMessage({ id: 'references.examensTypes.liste.headers.type' }),
						sortable: false,
						render: row => intl.formatMessage({ id: `references.examensTypes.type.${row.type}` })
					},
					{
						key: 'modePassage',
						name: intl.formatMessage({ id: 'references.examensTypes.liste.headers.modePassage' }),
						sortable: false,
						render: row => {
							if (!!row.modePassage) {
								return intl.formatMessage({ id: `references.examensTypes.modePassage.${row.modePassage}` })
							}
							return ''
						}
					},
					{
						key: 'etat',
						sortable: false,
						name: intl.formatMessage({ id: 'references.examensTypes.liste.headers.etat' }),
						render: row => intl.formatMessage({ id: `references.examensTypes.etat.${row.etat}` })

					}
				]}
			/>
			<Fab
				color="primary"
				label="Add"
				className={classes.fab}
				onClick={handleOpenPopup}
			>
				<AddIcon />
			</Fab>
			<CreerExamenTypePopup open={open} closePopup={handleClosePopup} />
		</React.Fragment>
	)
}


ExamensTypeListe.propTypes = {
	intl: intlShape,
	classes: PropTypes.object
}

const mapStateToProps = state => ({
	liste: selectors.getListeExamensTypes(state),
	searchValue: selectors.getSearchValue(state),
	switchValue: selectors.getSwitchValue(state)
})

const actions = {
	getListeExamensTypes,
	handleQuery: examensTypesActions.handleQuery,
	resetQuery: examensTypesActions.resetQuery
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectIntl,
	withStyles(styles),
	connect(mapStateToProps, actions),
)(ExamensTypeListe)
