import * as api from './gestionCopiesApi'

export const RECHERCHER_INSCRIPTION_REQUEST = 'GESTION_COPIES/RECHERCHER_INSCRIPTION_REQUEST'
export const RECHERCHER_INSCRIPTION_SUCCESS = 'GESTION_COPIES/RECHERCHER_INSCRIPTION_SUCCESS'
export const RECHERCHER_INSCRIPTION_ERROR = 'GESTION_COPIES/RECHERCHER_INSCRIPTION_ERROR'
export const rechercherInscription = values => dispatch => {
	dispatch({ type: RECHERCHER_INSCRIPTION_REQUEST })
	return api.rechercherInscription(values)
		.then(inscription => dispatch({
			type: RECHERCHER_INSCRIPTION_SUCCESS,
			inscription
		}))
		.catch(e => {
			dispatch({ type: RECHERCHER_INSCRIPTION_ERROR })
			throw e
		})
}

export const CLEAN_INSCRIPTION = 'GESTION_COPIES/CLEAN_INSCRIPTION'
export const cleanInscription = () => dispatch => dispatch({ type: CLEAN_INSCRIPTION })

export const GET_RESULTAT_EPREUVE_REQUEST = 'GESTION_COPIES/GET_RESULTAT_EPREUVE_REQUEST'
export const GET_RESULTAT_EPREUVE_SUCCESS = 'GESTION_COPIES/GET_RESULTAT_EPREUVE_SUCCESS'
export const GET_RESULTAT_EPREUVE_ERROR = 'GESTION_COPIES/GET_RESULTAT_EPREUVE_ERROR'
export const getResultatEpreuve = (idEpreuveInscription) => dispatch => {
	dispatch({ type: GET_RESULTAT_EPREUVE_REQUEST })
	return api.getResultatEpreuve(idEpreuveInscription)
		.then(resultat => dispatch({
			type: GET_RESULTAT_EPREUVE_SUCCESS,
			resultat
		}))
		.catch(e => {
			dispatch({ type: GET_RESULTAT_EPREUVE_ERROR })
			throw e
		})
}

export const CLEAN_RESULTAT_EPREUVE = 'GESTION_COPIES/CLEAN_RESULTAT_EPREUVE'
export const cleanResultatEpreuve = () => dispatch => dispatch({ type: CLEAN_RESULTAT_EPREUVE })

export const MODIFIER_MENTION_REQUEST = 'GESTION_COPIES/MODIFIER_MENTION_REQUEST'
export const MODIFIER_MENTION_SUCCESS = 'GESTION_COPIES/MODIFIER_MENTION_SUCCESS'
export const MODIFIER_MENTION_ERROR = 'GESTION_COPIES/MODIFIER_MENTION_ERROR'
export const modifierMention = values => dispatch => {
	dispatch({ type: MODIFIER_MENTION_REQUEST })
	return api.modifierMention(values)
		.then(resultat => dispatch({
			type: MODIFIER_MENTION_SUCCESS,
			resultat
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_MENTION_ERROR })
			throw e
		})
}

export const MODIFIER_FRAUDE_REQUEST = 'GESTION_COPIES/MODIFIER_FRAUDE_REQUEST'
export const MODIFIER_FRAUDE_SUCCESS = 'GESTION_COPIES/MODIFIER_FRAUDE_SUCCESS'
export const MODIFIER_FRAUDE_ERROR = 'GESTION_COPIES/MODIFIER_FRAUDE_ERROR'
export const modifierFraude = values => dispatch => {
	dispatch({ type: MODIFIER_FRAUDE_REQUEST })
	return api.modifierFraude(values)
		.then(resultat => dispatch({
			type: MODIFIER_FRAUDE_SUCCESS,
			resultat
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_FRAUDE_ERROR })
			throw e
		})
}