import { Button, Input, Popup, injectFormSnackbar } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { postFamille } from '../services/familleAction'

const validate = (values) => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}


const NouvelleFamillePopup = ({ open, closePopup, idMatiere, handleSubmit, reset, postFamille, refreshTable }) => {

	const submitCreateFamille = (values) => {
		return postFamille(values, idMatiere)
			.then(() => {
				refreshTable()
				handleClose()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const handleClose = () => {
		reset()
		closePopup()
	}

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={handleClose}
		>
			Annuler
		</Button>,
		<Button
			key="valider"
			color="primary"
			variant="contained"
			onClick={() => handleSubmit(submitCreateFamille)()}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouvelle famille dans la matière"
			content={<form>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={Input}
					inputProps={{
						maxLength: 50
					}}
				/>
			</form>}
		/>
	)
}

const actions = {
	refreshTable: () => dataTableActions.refresh('listeFamille'),
	postFamille
}

NouvelleFamillePopup.propTypes = {
	idMatiere: PropTypes.string,
	open: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired,
	refreshTable: PropTypes.func.isRequired,
	postFamille: PropTypes.func.isRequired
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'nouvelleFamillePopup',
		initialValues: {
			libelle: ''
		},
		validate
	}),
	injectFormSnackbar()
)(NouvelleFamillePopup)
