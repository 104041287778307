import { combineReducers } from 'redux'
import * as paiementActions from './paiementActions'

const initialListe = []
const liste = (state = initialListe, action) => {
	switch (action.type) {
		case paiementActions.GET_LISTE_PAIEMENTS_SUCCESS:
			return action.paiements
		case paiementActions.GET_LISTE_PAIEMENTS_ERROR:
		case paiementActions.CLEAN_LISTE_PAIEMENTS:
			return initialListe
		default:
			return state
	}
}

const paiementsSelected = []
const selected = (state = paiementsSelected, action) => {
	switch (action.type) {
		case paiementActions.SET_PAIEMENTS_SELECTED:
			return action.paiements
		default:
			return state
	}
}

export const paiements = combineReducers({
	liste,
	selected
})
