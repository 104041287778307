import React from 'react'
import { SpringDataTable, Title, injectToolbarData } from 'isotope-client'
import { EmptyResult, Link, FloatingButton, StyledTypography, ListFiltres } from '@oceane/ui'
import { compose } from 'redux'
import { connect } from 'react-redux'
import NouvelleFamillePopup from './components/NouvelleFamillePopup'
import * as familleAction from './services/familleAction'
import * as familleSelectors from './services/familleSelectors'
import PropTypes from 'prop-types'

const ListeFamille = ({
	match: { params },
	location: { state },
	handleQuery,
	resetQuery,
	searchValue
}) => {
	const [open, setOpen] = React.useState(false)
	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	return (
		<React.Fragment>
			<Title value="Liste des familles de la matière" />
			<ListFiltres
				handleQueryValue={handleQuery}
				resetQueryValue={resetQuery}
				searchValue={searchValue}
				searchPlaceholderId="references.famille.search"
			/>
			<SpringDataTable
				apiUrl={`/matiere/${params.idMatiere}/familles`}
				headers={[
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: true,
						render: row => <Link to={`/familles/${params.idMatiere}/${row.id}`} state={{ code: state.code }}>{row.libelle}</Link>
					}
				]}
				nom="listeFamille"
				filters={{
					libelle: searchValue
				}}
				noResultFragment={<EmptyResult />}
				defaultPageSize={50}
			/>
			<FloatingButton onClick={handleOpenPopup} />
			<NouvelleFamillePopup open={open} closePopup={handleClosePopup} idMatiere={params.idMatiere} />
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	searchValue: familleSelectors.getSearchValue(state)
})

const actions = {
	handleQuery: familleAction.handleQuery,
	resetQuery: familleAction.resetQuery
}

ListeFamille.propTypes = {
	handleQuery: PropTypes.func.isRequired,
	resetQuery: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired
}

export default compose(
	injectToolbarData(({ location: { state } }) => ({
		title: 'Familles',
		subheader: (state && state.code) && {
			filArianeEntries: [{
				text: `Matière ${state.code}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	connect(mapStateToProps, actions)
)(ListeFamille)
