import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ArrowBack'
import { compose } from 'redux'
import { Button, SelectWithPlaceholder } from '@oceane/ui'
import { connect } from 'react-redux'
import { dataTableActions, dataTableValueSelectors, getStyles } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { FormattedMessage } from 'react-intl'

const styles = theme => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '0 20px',
		height: 53,
		alignItems: 'center'
	},
	icon: {
		padding: 0,
		margin: '2px 5px 2px 0',
		color: '#000000'
	}
})

const EditionBordereauActions = ({ bordereauxSelectionnes, examensPratiqueList, idExamen, clearSelection, setIdExamen, classes }) => {

	const hasBordereauxSelectionnes = bordereauxSelectionnes && bordereauxSelectionnes.length !== 0

	return (<Grid alignContent="center" container className={classes.filtres}>
		{hasBordereauxSelectionnes ?
			<Grid container alignItems="center" justify="space-between">
				<Grid item>
					<IconButton
						className={classes.icon}
						onClick={clearSelection}
						disableRipple
					>
						<BackIcon />
					</IconButton>
					<FormattedMessage id="edition.bordereauxSelectionnes"
					                  values={{ selection: bordereauxSelectionnes.length }} />
				</Grid>
				<Grid item>
					<Button
						color="primary"
						onClick={() => {
							download(`/files/editionCandidat/bordereau?ids=${bordereauxSelectionnes.map(bordereau => bordereau.id)}`)
							setIdExamen(undefined)
						}}
					>
						Imprimer
					</Button>
				</Grid>
			</Grid>
			: <Grid item>
				<SelectWithPlaceholder
					value={idExamen}
					onChange={event => setIdExamen(event.target.value)}
					placeholder="Examen type (pratique)"
					emptyValue={undefined}
					id="typeExamen"
				>
					{examensPratiqueList.map(examen =>
						<MenuItem key={`examen-${examen.id}`} value={examen.id}>
							{examen.libelle}
						</MenuItem>
					)}
				</SelectWithPlaceholder>
			</Grid>
		}
	</Grid>)
}

const mapStateToProps = state => ({
	bordereauxSelectionnes: dataTableValueSelectors.getDataTableSelectedRows(state)('bordereaux')
})

const actions = {
	clearSelection: () => dataTableActions.selectRows('bordereaux', [])
}

EditionBordereauActions.propTypes = {
	classes: PropTypes.object,
	idExamen: PropTypes.number,
	bordereauxSelectionnes: PropTypes.array,
	examensPratiqueList: PropTypes.array,
	clearSelection: PropTypes.func,
	setIdExamen: PropTypes.func
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions)
)(EditionBordereauActions)
