import { combineReducers } from 'redux'
import * as banqueQuestionsActions from './banqueQuestionsActions'

const questionDonneesGenerales = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_DONNEES_GENERALES_SUCCESS:
		case banqueQuestionsActions.SAVE_QUESTION_DONNEES_GENERALES_SUCCESS:
			return action.result
		case banqueQuestionsActions.CHANGER_ETAT_QUESTION_SUCCESS:
			return {
				...state,
				etat: action.nouvelEtat
			}
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

const questionAffectationsIds = (state = [], action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_AFFECTATIONS_SUCCESS:
			return action.result.map(affectation => affectation.idExamenType)
		case banqueQuestionsActions.CLEAN_QUESTION:
			return []
		default:
			return state
	}
}

const questionAffectationsById = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_AFFECTATIONS_SUCCESS:
			return action.result.reduce((acc, affectation) => ({
				...acc,
				[affectation.idExamenType]: affectation
			}), state)
		case banqueQuestionsActions.CREER_AFFECTATION_SUCCESS:
		case banqueQuestionsActions.MODIFIER_AFFECTATION_SUCCESS:
			return {
				...state,
				[action.values.idExamenType]: {
					...action.values,
					affectee: true
				}
			}
		case banqueQuestionsActions.SUPPRIMER_AFFECTATION_SUCCESS:
			return {
				...state,
				[action.idExamenType]: {
					...state[action.idExamenType],
					dureeExamen: null,
					score: null,
					affectee: false
				}
			}
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}


const enonces = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_ENONCES_SUCCESS:
		case banqueQuestionsActions.SAVE_QUESTION_ENONCES_SUCCESS:
			return action.result
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

const annexesIds = (state = [], action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_ANNEXES_SUCCESS:
			return [
				...state,
				...action.result.map(annexe => annexe.id)
			].filter((value, index, self) => self.indexOf(value) === index)
		case banqueQuestionsActions.CLEAN_QUESTION:
			return []
		default:
			return state
	}
}

const annexesById = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_ANNEXES_SUCCESS:
			return action.result.reduce((map, annexe) => ({
				...map,
				[annexe.id]: annexe
			}), {})
		case banqueQuestionsActions.UPDATE_ANNEXE_QUESTION_SUCCESS: {
			const newState = { ...state }
			newState[action.idAnnexe] = { ...newState[action.idAnnexe], affectee: action.isAffectation }
			return newState
		}
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

const questionExpertise = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_EXPERTISE_SUCCESS:
			return action.result
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

const questionEtatGlobal = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_ETAT_GLOBAL_SUCCESS:
		case banqueQuestionsActions.SET_VISA_RDB_SUCCESS:
			return action.result
		case banqueQuestionsActions.CHANGER_ETAT_QUESTION_SUCCESS:
			if (!!action.result) {
				return {
					...state,
					listeDernieresActions: [
						action.result,
						...state.listeDernieresActions
					]
				}
			}
			return state
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

const questionComparatifImport = (state = {}, action) => {
	switch (action.type) {
		case banqueQuestionsActions.GET_QUESTION_COMPARATIF_IMPORT_SUCCESS:
			return action.result
		case banqueQuestionsActions.CLEAN_QUESTION:
			return {}
		default:
			return state
	}
}

export const banqueQuestions = combineReducers({
	question: combineReducers({
		donneesGenerales: questionDonneesGenerales,
		enonces,
		affectations: combineReducers({
			ids: questionAffectationsIds,
			byId: questionAffectationsById
		}),
		annexes: combineReducers({
			ids: annexesIds,
			byId: annexesById
		}),
		expertise: questionExpertise,
		etatGlobal: questionEtatGlobal,
		comparatifImport: questionComparatifImport
	})
})
