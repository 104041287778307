import withStyles from '@material-ui/core/styles/withStyles'
import { checkAuthorities, injectSession, PROFIL_UTILISATEUR, EmptyResult } from '@oceane/ui'
import { injectToolbarData, getStyles, SpringDataTable } from 'isotope-client'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import NouveauCompteClientPopup from './NouveauCompteClientPopup'
import {
	openPopup as openPopupNouveauCompteClient
} from './services/nouveauCompteClientPopupActions'

const style = () => getStyles({
	fab: {
		position: 'fixed',
		right: 10,
		bottom: 20
	}
})

class ComptesClient extends Component {

	render() {
		const {
			classes,
			openPopupNouveauCompteClient
		} = this.props

		return (
			<React.Fragment>
				<div>
					<SpringDataTable
						apiUrl="/comptesClients"
						headers={[
							{
								key: 'libelle',
								name: <FormattedMessage id='references.comptesClients.label' />,
								sortable: true
							},
						]}
						mode="get"
						nom="comptesClients"
						noResultFragment={<EmptyResult />}
					/>
					<Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => openPopupNouveauCompteClient()}>
						<AddIcon />
					</Fab>
					<NouveauCompteClientPopup/>
				</div>
			</React.Fragment>
		)
	}
}

const actions = {
	openPopupNouveauCompteClient
}

ComptesClient.propTypes = {
	profil: PropTypes.number,
	openPopupNouveauCompteClient: PropTypes.func,
}

export default compose(
	injectToolbarData({ title: { id: 'references.comptesClients.label' } }),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(null, actions),
	withStyles(style),
	injectSession
)(ComptesClient)
