import { combineReducers } from 'redux'
import {
	CLOSE_POPUP,
	CLOSE_POPUP_CREATION,
	EXAMENS_TYPE_PRATIQUE, FORMATION_TYPE_PRATIQUE,
	OPEN_POPUP,
	OPEN_POPUP_CREATION
} from './examenPratiqueOrganismeAction'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const openCreation = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP_CREATION:
			return true
		case CLOSE_POPUP_CREATION:
			return false
		default:
			return state
	}
}

const initialContent = {
	examenType: [],
	formationType: []
}
const examensPratique = (state = initialContent, action) => {
	switch (action.type) {
		case EXAMENS_TYPE_PRATIQUE:
			return {
				...state,
				examenType: action.payload
			}
		case FORMATION_TYPE_PRATIQUE:
			return {
				...state,
				formationType: action.payload
			}
		default:
			return state
	}
}

export const examenPratique = combineReducers({
	open,
	openCreation,
	examensPratique
})