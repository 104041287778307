import {
    checkAuthorities, injectSession, StyledTypography,
    Tabs,
    PROFIL_UTILISATEUR
} from '@oceane/ui'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { injectToolbarData } from 'isotope-client'
import Tab from '@material-ui/core/Tab'
import SurveillantEtatCivilOnglet from './components/SurveillantEtatCivilOnglet'
import SurveillantCentresOnglet from './components/SurveillantCentresOnglet'

const DetailsSurveillantPage = ({ match }) => {

    const [tab, setTab] = React.useState(0)

    const handleChangeTab = (value) => {
        setTab(value)
    }

    const tabViews = [
        <SurveillantEtatCivilOnglet match={match} />,
        <SurveillantCentresOnglet match={match} />
    ]

    return (
        <React.Fragment>
            <Tabs
                initialTab={tab}
                tabs={[
                    <Tab key="etat-civil" label="Etat civil" />,
                    <Tab key="centres" label="Centres" />
                ]}
                tabViews={tabViews}
                onChangeTab={handleChangeTab}
            />
        </React.Fragment>
    )
}

export default compose(
    injectToolbarData(({ location: { state } }) => ({
        title: 'Gestion du surveillant',
        subheader: (state && state.nom) && {
            filArianeEntries: [{
                render: () => <StyledTypography bold>Surveillant <FormattedMessage
                    id={`global.civilite.${state.civilite}`} /> {state.prenom} {state.nom}
                </StyledTypography>
            }]
        }
    })),
    checkAuthorities(
        PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
        PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
    ),
    injectSession
)(DetailsSurveillantPage)
