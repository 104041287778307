import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as selectors from './examenTheoriqueOrganismeSelector'
import { getExamensTypeTheorique } from './examenTheoriqueOrganismeAction'

export const injectExamenTheorique = (WrappedComponent) => {

	class injectExamenTheorique extends React.Component {

		componentDidMount() {
			const { getExamensTypeTheorique, examensTypeTheoriques, ...otherProps } = this.props

			if (examensTypeTheoriques.length === 0) {
				getExamensTypeTheorique(otherProps.match.params.idOrganisme)
			}
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectExamenTheorique.propTypes = {
		getExamensTypeTheorique: PropTypes.func,
		examensTypeTheoriques: PropTypes.array
	}


	const mapStateToProps = state => ({
		examensTypeTheoriques: selectors.getExamensTheoriques(state)
	})

	const actions = {
		getExamensTypeTheorique
	}

	return connect(mapStateToProps, actions)(injectExamenTheorique)
}
