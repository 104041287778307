import { combineReducers } from 'redux'
import * as actions from './formationEquivalencesActions'

const listeOrganismesActives = (state = [], action) => {
	switch (action.type) {
		case actions.GET_ORGANISMES_ACTIVES_SUCCESS:
			return action.organismes
		default:
			return state
	}
}

const listeOrganismesExamenType = (state = [], action) => {
	switch (action.type) {
		case actions.GET_ORGANISMES_EXAMEN_TYPE_SUCCESS:
			return action.organismes
		default:
			return state
	}
}

const listeComptesClients = (state = [], action) => {
	switch (action.type) {
		case actions.GET_COMPTES_CLIENTS_SUCCESS:
			return action.comptesClients
		default:
			return state
	}
}

const listeTypesFormations = (state = [], action) => {
	switch (action.type) {
		case actions.GET_TYPES_FORMATIONS_SUCCESS:
			return action.typesFormations
		case actions.RESET_TYPES_FORMATIONS:
			return []
		default:
			return state
	}
}

const listeEpreuves = (state = [], action) => {
	switch (action.type) {
		case actions.GET_EPREUVES_EXAMEN_CANDIDAT_SUCCESS:
			return action.listeEpreuves
		case actions.MODIFIER_EPREUVE_SUCCESS:
			return action.listeEpreuves
		case actions.REFUSER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS:
			return action.epreuves
		case actions.AUTORISER_EPREUVE_EXAMEN_CANDIDAT_SUCCESS:
			return action.epreuves
		case actions.RESET_EPREUVES_EXAMEN_CANDIDAT:
			return []
		default:
			return state
	}
}

export default combineReducers({
	listeOrganismesExamenType,
	listeOrganismesActives,
	listeTypesFormations,
	listeComptesClients,
	listeEpreuves
})