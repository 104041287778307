import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as selectors from './piecesJustificativesSelectors'
import { getDetailPieceJustificative } from './piecesJustificativesAction'

export const injectPieceJustificativeWithMatch = (WrappedComponent) => {

	class injectPieceJustificativeWithMatch extends React.Component {

		componentDidMount() {
			const { getDetailPieceJustificative, detailPieceJustificative, match } = this.props

			const idPj = match.params.idPj

			if (!detailPieceJustificative || detailPieceJustificative.id !== idPj) {
				getDetailPieceJustificative(idPj)
			}
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectPieceJustificativeWithMatch.propTypes = {
		getDetailPieceJustificative: PropTypes.func,
		detailPieceJustificative: PropTypes.object
	}


	const mapStateToProps = state => ({
		detailPieceJustificative: selectors.getPieceJustificative(state)
	})

	const actions = {
		getDetailPieceJustificative
	}

	return connect(mapStateToProps, actions)(injectPieceJustificativeWithMatch)
}
