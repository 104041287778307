import React from 'react'
import PropTypes from 'prop-types'
import { DateInput, Error, Input, InputDisabled, Popup, Button, injectFormSnackbar } from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { dataTableActions } from 'isotope-client'
import moment from 'moment'
import { modifierEpreuveSession } from '../../services/sessionsPapierActions'

const validate = (values, { sessionPapier }) => {
	const errors = {}
	if (!values.date) {
		errors.date = 'Ce champ est obligatoire'
	}
	if (!values.heureDeDebut) {
		errors.heureDeDebut = 'Ce champ est obligatoire'
	}
	if (!!values.date && !!sessionPapier) {
		const d = new Date(values.date)
		const dateClotureInscriptions = new Date(sessionPapier.dateClotureInscriptions)
		const datePublicationResultats = new Date(sessionPapier.datePublicationResultats)
		// RG1 (2a3) : on vérifie que la date est entre la date de clôture
		// des inscriptions et la date de publication des résultats. KO =
		// MSG60
		if (moment(dateClotureInscriptions).isAfter(moment(d), "day") || moment(d).isAfter(moment(datePublicationResultats), "day")) {
			errors.date = 'La date de déroulement de l\'épreuve doit se situer entre la date de clôture des inscriptions et la date de publication des résultats de la session'
		}
	}
	return errors
}

const FORM_NAME = 'modifierEpreuveSessionForm'

const ModifierEpreuveSessionPopup = (
	{
		open,
		change,
		onClose,
		submitting,
		handleSubmit,
		refreshTable,
		sessionPapier,
		epreuveSession,
		modifierEpreuveSession,
		destroy
	}
) => {

	const [error, setError] = React.useState()

	const onSubmit = values => modifierEpreuveSession(epreuveSession.idEpreuveSession, sessionPapier.id, values)
		.then(() => {
			refreshTable()
			closeForm()
		})
		.catch(e => {
			if (e.bodyError) {
				setError(e.bodyError)
			}
			throw e
		})

	const closeForm = () => {
		destroy()
		onClose()
		setError(undefined)
	}

	React.useEffect(() => {
		if (!!epreuveSession && !!epreuveSession.date) {
			const date = new Date(epreuveSession.date)
			change('date', moment(date).format('YYYY-MM-DD'))
			change('heureDeDebut', epreuveSession.heureDeDebut.replace(/h/, ':'))
		} else {
			change('date', '')
			change('heureDeDebut', '')
		}
	}, [epreuveSession])

	return (<Popup
		open={open}
		title="Paramétrage de l'épreuve"
		actions={[
			<Button
				color="primary"
				key="annuler"
				onClick={closeForm}
			>
				Annuler
			</Button>,
			<Button
				type="submit"
				loading={submitting}
				variant="contained"
				color="primary"
				key="valider"
				onClick={handleSubmit(onSubmit)}
			>
				Enregistrer
			</Button>
		]}
		content={<>
			{error && <Error error={error} />}
			<InputDisabled
				label="Libellé épreuve *"
			>
				{epreuveSession !== null && `${epreuveSession.codeEpreuve} ${epreuveSession.libelle}`}
			</InputDisabled>
			<InputDisabled
				label="Durée *"
			>
				{epreuveSession !== null && epreuveSession.duree !== null && `${epreuveSession.duree}`}
			</InputDisabled>
			<Field
				id="date"
				name="date"
				label="Date *"
				component={DateInput}
			/>
			<Field
				id="heureDeDebut"
				name="heureDeDebut"
				label="Heure de début *"
				component={Input}
				type="time"
			/>
		</>}
	/>)
}


ModifierEpreuveSessionPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	modifierEpreuveSession: PropTypes.func,
	epreuveSession: PropTypes.object,
	sessionPapier: PropTypes.object
}

const actions = {
	modifierEpreuveSession,
	refreshTable: () => dataTableActions.refresh('epreuvesSession')
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: FORM_NAME,
		validate
	}),
	injectFormSnackbar()
)(ModifierEpreuveSessionPopup)
