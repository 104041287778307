import { fetchFactory, postMultipart } from 'isotope-client'

export const postAnnexe = (annexe, idMatiere) => fetchFactory(`/matiere/${idMatiere}/annexes`, {
	method: 'POST',
	body: JSON.stringify(annexe)
})

export const getAnnexe = (idAnnexe, idMatiere) => fetchFactory(`/matiere/${idMatiere}/annexes/${idAnnexe}`)

export const putAnnexe = (annexe, idMatiere) => postMultipart(`/matiere/${idMatiere}/annexes/${annexe.id}`, {
	body: annexe
})

export const deleteAnnexe = (idAnnexe, idMatiere) => fetchFactory(`/matiere/${idMatiere}/annexes/${idAnnexe}`, {
	method: 'DELETE',
	body: idAnnexe
})

export const checkFileExist = (idAnnexe, idMatiere, nomFichier) => fetchFactory(`/matiere/${idMatiere}/annexes/${idAnnexe}/file`, {
	method: 'post',
	body: nomFichier
})
