import { MenuItem } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {
	Button,
	checkInscription,
	Error,
	getEtatCivil,
	getIdCandidat,
	MODE_PASSAGE,
	Popup,
	Select,
	TYPE_EXAMEN
} from '@oceane/ui'
import { push } from 'connected-react-router'
import { FormCheckbox, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { examenCandidatShape, sessionInitialiseeShape } from '../../../../../config/shapes'
import { clearPopupContent, closePopupNouvelleInscription, getSessionsInitialisees } from '../../services/popup/nouvelleInscriptionActions'
import { getSessionsInit, getTypeInscription } from '../../services/popup/nouvelleInscriptionSelectors'

const validate = (values, { intl }) => {
	let errors = {}
	if (!values.sessionPapier) {
		errors.sessionPapier = intl.formatMessage(
			{ id: 'global.erreurs.obligatoireSpecifique' },
			{ champ: 'Session' }
		)
	}
	return errors
}

const styles = (theme) => getStyles({
	popupForm: {
		marginTop: 20
	}
})

class NouvelleInscriptionPopup extends PureComponent {

	static propTypes = {
		typeInscription: PropTypes.number,
		examenSelectionne: examenCandidatShape,
		sessionsInitialisees: PropTypes.arrayOf(sessionInitialiseeShape),
		idCandidat: PropTypes.number,
		closePopupNouvelleInscriptionAction: PropTypes.func,
		createInscriptionAction: PropTypes.func,
		open: PropTypes.bool,
		getSessionsInitialiseesAction: PropTypes.func,
		clearPopupContentAction: PropTypes.func
	}

	componentDidMount() {
		const { getSessionsInitialiseesAction, examenSelectionne } = this.props
		if (!!examenSelectionne && !!examenSelectionne.examenTypeId) {
			getSessionsInitialiseesAction(examenSelectionne.examenTypeId)
		}
	}

	closeAndClearForm = () => {
		const {
			clearPopupContentAction,
			closePopupNouvelleInscriptionAction
		} = this.props
		// Fermeture du popup
		closePopupNouvelleInscriptionAction()
		// Clear state
		clearPopupContentAction()
	}

	submitCreateInscription = values => {
		const {
			idCandidat,
			examenSelectionne,
			closePopupNouvelleInscriptionAction,
			goToNouvelleInscriptionPage,
			checkInscription,
			typeInscription,
			intl,
			sessionPapier
		} = this.props

		return checkInscription(false, values)
			.catch(e => {
				throw new SubmissionError({
					_error: intl.formatMessage(
						{ id: !!e.bodyError ? e.bodyError.id : 'global.erreurs.generic' }
					)
				})
			}).then(() => {
				closePopupNouvelleInscriptionAction()
				goToNouvelleInscriptionPage(
					idCandidat,
					examenSelectionne.id,
					examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE,
					typeInscription,
					sessionPapier
				)
			})
	}

	hasNotEmailOrPhoneNumber = () => {
		return !this.props.etatCivilCandidat.telephonePrincipal || !this.props.etatCivilCandidat.email
	}

	getActions = () => {
		const actions = [
			<Button
				key="fermer"
				color="primary"
				onClick={this.props.closePopupNouvelleInscriptionAction}
			>
				Annuler
			</Button>
		]
		if (!this.hasNotEmailOrPhoneNumber()) {
			actions.push(
				<Button
					key="valider"
					color="primary"
					variant="contained"
					loading={this.props.submitting}
					onClick={this.props.handleSubmit(this.submitCreateInscription)}
				>
					Créer
				</Button>
			)
		}
		return actions
	}

	render() {
		const {
			intl,
			error,
			typeInscription,
			examenSelectionne,
			sessionsInitialisees,
			open,
			classes
		} = this.props

		return (
			<Popup
				open={open}
				actions={this.getActions()}
				title={intl.formatMessage({
					id: `candidats.profil.examens.inscriptions.nouvelleInscription.${typeInscription}`
				})}
				content={
					<div className={classes.popupForm}>
						<form>
							{!!error && <Error error={[error]} isPopinContainer />}
							{this.hasNotEmailOrPhoneNumber() && <FormattedMessage id="candidats.profil.examens.inscriptions.missingInfo" />}
							{typeInscription === MODE_PASSAGE.PAPIER && !this.hasNotEmailOrPhoneNumber() && <Field
								name="sessionPapier"
								label={intl.formatMessage({ id: 'candidats.profil.examens.inscriptions.formSession' })}
								component={Select}
								displayEmpty
							>
								<MenuItem value="">
									<FormattedMessage id="global.indications.selectEmpty" />
								</MenuItem>
								{(!!sessionsInitialisees && sessionsInitialisees.length !== 0) && sessionsInitialisees.map((value, key) =>
									<MenuItem key={key} value={value.id}>{value.libelle}</MenuItem>
								)}
							</Field>}
							{(!this.hasNotEmailOrPhoneNumber() && (typeInscription === MODE_PASSAGE.ECRAN || typeInscription === MODE_PASSAGE.PRATIQUE)) && <React.Fragment>
								<Field
									name="exceptionnelle"
									component={FormCheckbox}
									label={intl.formatMessage({ id: 'candidats.profil.examens.inscriptions.inscriptionExceptionnelle' })}
								/>
								{(typeInscription === MODE_PASSAGE.ECRAN && (examenSelectionne.typeExamen === TYPE_EXAMEN.IFR || examenSelectionne.typeExamen === TYPE_EXAMEN.VFR)) &&
								<Field
									name="isNiveau6"
									component={FormCheckbox}
									label={intl.formatMessage({ id: 'candidats.profil.examens.inscriptions.niveau6' })}
								/>}
							</React.Fragment>}
						</form>
					</div>
				}
				onExited={this.closeAndClearForm}
			/>

		)
	}
}

const formSelector = formValueSelector('nouvelleInscriptionForm')

const mapStateToProps = (state, { examenSelectionne, canCreateInscription }) => {
	const typeInscription = getTypeInscription(state)
	return {
		typeInscription,
		sessionsInitialisees: getSessionsInit(state),
		idCandidat: getIdCandidat(state),
		etatCivilCandidat: getEtatCivil(state),
		initialValues: {
			exceptionnelle: !canCreateInscription,
			isNiveau6: false,
			sessionPapier: '',
			typePassage: typeInscription,
			idExamen: examenSelectionne.id
		},
		sessionPapier: formSelector(state, 'sessionPapier'),
		open: state.candidat.nouvelleInscription.open
	}
}

const actions = {
	closePopupNouvelleInscriptionAction: closePopupNouvelleInscription,
	clearPopupContentAction: clearPopupContent,
	checkInscription,
	getSessionsInitialiseesAction: getSessionsInitialisees,
	goToNouvelleInscriptionPage: (idCandidat, idExamen, isPratique, typePassage, sessionPapier) => dispatch => {
		if (isPratique) {
			dispatch(push(`/candidats/${idCandidat}/examens/${idExamen}/nouvelle-inscription-pratique-4`))
		} else {
			if (sessionPapier) {
				dispatch(push(`/candidats/${idCandidat}/examens/${idExamen}/nouvelle-inscription-${typePassage}-${sessionPapier}`))
			} else {
				dispatch(push(`/candidats/${idCandidat}/examens/${idExamen}/nouvelle-inscription-${typePassage}`))
			}
		}
	}
}

export default compose(
	injectIntl,
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'nouvelleInscriptionForm',
		enableReinitialize: true,
		validate
	})
)(NouvelleInscriptionPopup)
