import { fetchFactory } from 'isotope-client'

export const createMatiere = (values) => fetchFactory('/matiere', {
	method: 'post',
	body: JSON.stringify(values)
})

export const modificationMatiere = (values) => fetchFactory(`/matiere/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getDetailsMatiere = (id) => fetchFactory(`/matiere/${id}`)

export const suppressionMatiere = (id) => fetchFactory(`/matiere/${id}`, {
	method: 'delete',
	body: id
})

// TOPICS

export const createTopic = (values, idMatiere) => fetchFactory(`/matiere/${idMatiere}/listeTopics`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const modificationTopic = (values, idMatiere) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${values.id}`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getDetailsTopic = (id, idMatiere) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${id}`)

export const suppressionTopic = (id, idMatiere) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${id}`, {
	method: 'delete',
	body: id
})

// PARAGRAPHE

export const createParagraphe = (values, idMatiere, idTopic) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const modificationParagraphe = (values, idMatiere, idTopic) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getDetailsParagraphe = (idMatiere, idTopic, id) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${id}`)

export const suppressionParagraphe = (idMatiere, idTopic, id) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${id}`, {
	method: 'delete',
	body: id
})

// SOUS PARAGRAPHE

export const createSousParagraphe = (values, idMatiere, idTopic, idParagraphe) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getDetailsSousParagraphe = (idMatiere, idTopic, idParagraphe, id) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${id}`)

export const suppressionSousParagraphe = (idMatiere, idTopic, idParagraphe, idSousParagraphe) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}`, {
	method: 'delete',
	body: idSousParagraphe
})

export const modificationSousParagraphe = (values, idMatiere, idTopic, idParagraphe) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

// LIGNE

export const createLigne = (values, idMatiere, idTopic, idParagraphe, idSousParagraphe) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}/listeLignes`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const getDetailsLigne = (idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}/listeLignes/${idLigne}`)

export const suppressionLigne = (idMatiere, idTopic, idParagraphe, idSousParagraphe, idLigne) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}/listeLignes/${idLigne}`, {
	method: 'delete',
	body: idLigne
})

export const modificationLigne = (values, idMatiere, idTopic, idParagraphe, idSousParagraphe) => fetchFactory(`/matiere/${idMatiere}/listeTopics/${idTopic}/listeParagraphes/${idParagraphe}/listeSousParagraphes/${idSousParagraphe}/listeLignes/${values.id}`, {
	method: 'put',
	body: JSON.stringify(values)
})