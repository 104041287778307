import { createSelector } from 'reselect'
import { getReferences } from '../../referencesSelectors'

const getLocalState = createSelector(
	getReferences,
	references => references.examensTypes
)

const getExamensTypesIds = createSelector(
	getLocalState,
	state => state.ids
)

export const getExamensTypesById = createSelector(
	getLocalState,
	state => state.byId
)

export const getListeExamensTypes = createSelector(
	getExamensTypesIds,
	getExamensTypesById,
	(ids, byId) => ids.map(id => byId[id])
)

export const getExamenType = createSelector(
	getLocalState,
	state => state.details
)

export const getPiecesJustificatives = createSelector(
	getLocalState,
	state => state.piecesJustificatives
)

export const getPiecesJustificativesAbsentes = createSelector(
	getLocalState,
	state => state.piecesJustificativesAbsentes
)

export const getIdExamenType = createSelector(
	getExamenType,
	examenType => examenType.id
)

export const getLibelleExamenType = createSelector(
	getExamenType,
	examenType => examenType.libelle
)

export const getEtatExamenType = createSelector(
	getExamenType,
	examenType => examenType.etat
)

const getExamensStandards = createSelector(
	getLocalState,
	state => state.standards
)

const getExamensStandardsIds = createSelector(
	getExamensStandards,
	state => state.ids
)

const getExamensStandardsById = createSelector(
	getExamensStandards,
	state => state.byId
)

export const getListeExamensStandards = createSelector(
	getExamensStandardsIds,
	getExamensStandardsById,
	(ids, byId) => ids.map(id => byId[id])
)


export const getListeExamensReplicationPossible = createSelector(
	getListeExamensStandards,
	getIdExamenType,
	(liste, id) => liste.filter(examenType => examenType.id !== id)
)

export const getListeExamensTypesExploites = createSelector(
	getListeExamensTypes,
	examensTypes => examensTypes.filter(examenType => examenType.exploite)
)


const getLocalStateSearch = state => getLocalState(state).recherche

export const getSearchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.search
)

export const getSwitchValue = createSelector(
	getLocalStateSearch,
	recherche => recherche.switch
)