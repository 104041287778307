import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import { injectTypeFormation } from './services/typeFormationInjector'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { Button, checkAuthorities, Error, getFilArianeTypeFormation, Input, PROFIL_UTILISATEUR, injectFormSnackbar } from '@oceane/ui'
import { changeEtatTypeFormation, saveTypeFormation } from './services/typesFormationActions'
import { push } from 'connected-react-router'

const style = () => getStyles({
	changerEtat: {
		marginRight: 20
	}
})


const validate = values => {
	const errors = {}
	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

const ProprietesTypeFormation = (props) => {

	const {
		typeFormation,
		saveTypeFormation,
		changeEtatTypeFormation,
		goToListeTypesFormation,
		snackError,
		intl,
		handleSubmit,
		submitting,
		error,
		classes
	} = props

	const submit = values => {
		return saveTypeFormation(typeFormation.id, values)
			.then(() => goToListeTypesFormation())
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const changeEtat = () => {
		return changeEtatTypeFormation(typeFormation.id, typeFormation.active)
			.then(() => goToListeTypesFormation())
			.catch(() => {
				snackError()
			})
	}

	return <React.Fragment>
		<Title value={intl.formatMessage({ id: 'references.typeFormation.proprietes.titre' })} />
		<Error error={error} />
		<form>
			<Field
				name="libelle"
				component={Input}
				inputProps={{
					maxLength: 50
				}}
				label={intl.formatMessage({ id: 'references.typeFormation.form.libelle' })}
			/>
			<Field
				name="activeString"
				component={Input}
				disabled
				label={intl.formatMessage({ id: 'references.typeFormation.form.etat' })}
			/>
			<Grid container justify="flex-end">
				<MuiButton
					color="primary"
					onClick={changeEtat}
					className={classes.changerEtat}
				>
					<FormattedMessage id={`global.btn.${typeFormation.active ? 'desactiver' : 'activer'}`}/>
				</MuiButton>
				<Button
					color="primary"
					variant="contained"
					loading={submitting}
					onClick={handleSubmit(submit)}
				>
					<FormattedMessage id="global.btn.enregistrer"/>
				</Button>
			</Grid>
		</form>
	</React.Fragment>
}

ProprietesTypeFormation.propTypes = {
	typeFormation: PropTypes.object.isRequired,
	classes: PropTypes.object,
	intl: intlShape
}

const mapStateToProps = (state, { typeFormation, intl }) => {

	const active = typeFormation.active ?
		intl.formatMessage({ id: 'references.typeFormation.etat.active' }) :
		intl.formatMessage({ id: 'references.typeFormation.etat.desactive' })

	return {
		initialValues: {
			...typeFormation,
			activeString: active
		}
	}
}

const actions = {
	saveTypeFormation,
	changeEtatTypeFormation,
	goToListeTypesFormation: () => dispatch => dispatch(push('/types-formation'))
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectIntl,
	withStyles(style),
	injectTypeFormation,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'proprietesTypeFormation',
		validate
	}),
	injectToolbarData(({ typeFormation }) => getFilArianeTypeFormation(typeFormation.libelle, typeFormation.active)),
	injectFormSnackbar()
)(ProprietesTypeFormation)
