import { combineReducers } from 'redux'
import * as actions from './scriptActions'
import { ELS_SCRIPT_ETAT } from '../../../../config/enums'

const parametrage = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_SCRIPT_SUCCESS:
			return {
				id: action.result.id,
				etat: action.result.etat,
				libelle: action.result.libelle,
				nbQuestions: action.result.nbQuestions,
				pourcentageReussite: action.result.pourcentageReussite,
				isExploitation: action.isExploitation
			}
		case actions.VALIDER_SCRIPT_SUCCESS:
			return {
				...state,
				etat: ELS_SCRIPT_ETAT.VALIDE
			}
		case actions.DEVALIDER_SCRIPT_SUCCESS:
			return {
				...state,
				etat: ELS_SCRIPT_ETAT.CREE
			}
		case actions.CLEAR_SCRIPT:
			return {}
		default:
			return state
	}
}

const matieres = (state = [], action) => {
	switch (action.type) {
		case actions.GET_SCRIPT_SUCCESS:
			return action.result.matieres
		case actions.CLEAR_SCRIPT:
			return []
		default:
			return state
	}
}

const isExploitation = (state = false, action) => {
	switch (action.type) {
		case actions.GET_SCRIPT_SUCCESS:
			return action.isExploitation
		case actions.CLEAR_SCRIPT:
			return false
		default:
			return state
	}
}

const noeudCourantInit = {}
const noeudCourant = (state = noeudCourantInit, action) => {
	switch (action.type) {
		case actions.GET_DETAILS_NOEUD_SUCCESS:
			return {
				...action.noeud,
				...action.questionsDisponibles
			}
		case actions.CLEAR_NOEUD_COURANT:
			return noeudCourantInit
		default:
			return state
	}
}

export const scripts = combineReducers({
	parametrage,
	matieres,
	isExploitation,
	noeudCourant
})