import React from 'react'
import { EmptyResult, getFilArianeExamenType } from '@oceane/ui'
import { injectToolbarData, SpringDataTable, Title } from 'isotope-client'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import * as selectors from '../services/examensTypesSelectors'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const OngletAssociationsFormations = ({ id, openPopupDelete }) => {
	return (<React.Fragment>
		<Title value="Types de formation" />
		<SpringDataTable
			nom="typesFormations"
			apiUrl={`/examensTypes/${id}/types-formations`}
			headers={
				[
					{
						key: 'libelle',
						name: 'Libellé',
						sortable: false
					},
					{
						key: 'supprimer',
						name: 'Supprimer',
						sortable: false,
						render: (row) => <IconButton onClick={() => openPopupDelete(row.id, row.libelle, true)}><DeleteIcon /></IconButton>
					}
				]
			}
			noResultFragment={
				<EmptyResult />
			}
		/>
	</React.Fragment>)
}

OngletAssociationsFormations.propTypes = {
	classes: PropTypes.object,
	id: PropTypes.number
}

const mapStateToProps = state => {
	const examenType = selectors.getExamenType(state)
	return {
		id: examenType.id
	}
}

export default compose(
	connect(mapStateToProps),
	injectToolbarData(({ libelle, etat }) => getFilArianeExamenType(libelle, etat))
)(OngletAssociationsFormations)
