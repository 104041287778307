import * as api from './examensTypesApi'
import { Dispatch } from 'react'

export const GET_LISTE_EXAMENS_TYPES_REQUEST = 'REFERENCES/GET_LISTE_EXAMENS_TYPES_REQUEST'
export const GET_LISTE_EXAMENS_TYPES_SUCCESS = 'REFERENCES/GET_LISTE_EXAMENS_TYPES_SUCCESS'
export const GET_LISTE_EXAMENS_TYPES_ERROR = 'REFERENCES/GET_LISTE_EXAMENS_TYPES_ERROR'
export const getListeExamensTypes = (isAdmin: boolean, fromExamenTypeListe = false) => (dispatch: Dispatch<any>) => {
	dispatch({ type: GET_LISTE_EXAMENS_TYPES_REQUEST })
	const promise = isAdmin ? api.getAllExamensTypes() : api.getListeExamensTypesByUser(fromExamenTypeListe)
	return promise
		.then((result: any[]) => dispatch({
			type: GET_LISTE_EXAMENS_TYPES_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: GET_LISTE_EXAMENS_TYPES_ERROR })
			throw e
		})
}

export const GET_LISTE_EXAMENS_STANDARDS_REQUEST = 'REFERENCES/GET_LISTE_EXAMENS_STANDARDS_REQUEST'
export const GET_LISTE_EXAMENS_STANDARDS_SUCCESS = 'REFERENCES/GET_LISTE_EXAMENS_STANDARDS_SUCCESS'
export const GET_LISTE_EXAMENS_STANDARDS_ERROR = 'REFERENCES/GET_LISTE_EXAMENS_STANDARDS_ERROR'
export const getListeExamensStandards = () => (dispatch: Dispatch<any>) => {
	dispatch({ type: GET_LISTE_EXAMENS_STANDARDS_REQUEST })
	return api.getListeExamensStandards()
		.then((result: any[]) => dispatch({
			type: GET_LISTE_EXAMENS_STANDARDS_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: GET_LISTE_EXAMENS_STANDARDS_ERROR })
			throw e
		})
}

export const GET_EXAMEN_TYPE_REQUEST = 'REFERENCES/GET_EXAMEN_TYPE_REQUEST'
export const GET_EXAMEN_TYPE_SUCCESS = 'REFERENCES/GET_EXAMEN_TYPE_SUCCESS'
export const GET_EXAMEN_TYPE_ERROR = 'REFERENCES/GET_EXAMEN_TYPE_ERROR'
export const getExamenType = (id: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: GET_EXAMEN_TYPE_REQUEST })
	return api.getExamenType(id)
		.then((result: any) => dispatch({
			type: GET_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: GET_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const CLEAR_EXAMEN_TYPE = 'REFERENCES/CLEAR_EXAMEN_TYPE'
export const clearExamenType = () => (dispatch: Dispatch<any>) => dispatch({ type: CLEAR_EXAMEN_TYPE })

export const CREER_EXAMEN_TYPE_REQUEST = 'REFERENCES/CREER_EXAMEN_TYPE_REQUEST'
export const CREER_EXAMEN_TYPE_SUCCESS = 'REFERENCES/CREER_EXAMEN_TYPE_SUCCESS'
export const CREER_EXAMEN_TYPE_ERROR = 'REFERENCES/CREER_EXAMEN_TYPE_ERROR'
export const creerExamenType = (values: any) => (dispatch: Dispatch<any>) => {
	dispatch({ type: CREER_EXAMEN_TYPE_REQUEST })
	return api.creerExamenType(values)
		.then((id: number) => {
			dispatch({
				type: CREER_EXAMEN_TYPE_SUCCESS
			})
			return id
		})
		.catch((e: any) => {
			dispatch({ type: CREER_EXAMEN_TYPE_ERROR })
			throw e
		})
}

const convertMontant = (values: any) => ({
	...values,
	montantForfaitaire: Math.floor(Number(values.montantForfaitaire.replace(/,/, '.'))*100)/100
})

export const SAVE_EXAMEN_TYPE_REQUEST = 'REFERENCES/SAVE_EXAMEN_TYPE_REQUEST'
export const SAVE_EXAMEN_TYPE_SUCCESS = 'REFERENCES/SAVE_EXAMEN_TYPE_SUCCESS'
export const SAVE_EXAMEN_TYPE_ERROR = 'REFERENCES/SAVE_EXAMEN_TYPE_ERROR'
export const saveExamenType = (values: any) => (dispatch: Dispatch<any>) => {
	dispatch({ type: SAVE_EXAMEN_TYPE_REQUEST })
	return api.saveExamenType(convertMontant(values))
		.then((result: any) => dispatch({
			type: SAVE_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: SAVE_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const EXPLOITER_EXAMEN_TYPE_REQUEST = 'REFERENCES/EXPLOITER_EXAMEN_TYPE_REQUEST'
export const EXPLOITER_EXAMEN_TYPE_SUCCESS = 'REFERENCES/EXPLOITER_EXAMEN_TYPE_SUCCESS'
export const EXPLOITER_EXAMEN_TYPE_ERROR = 'REFERENCES/EXPLOITER_EXAMEN_TYPE_ERROR'
export const exploiterExamenType = (values: any) => (dispatch: Dispatch<any>) => {
	dispatch({ type: EXPLOITER_EXAMEN_TYPE_REQUEST })
	return api.exploiterExamenType(convertMontant(values))
		.then((result: any) => dispatch({
			type: EXPLOITER_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: EXPLOITER_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const ACTIVER_EXAMEN_TYPE_REQUEST = 'REFERENCES/ACTIVER_EXAMEN_TYPE_REQUEST'
export const ACTIVER_EXAMEN_TYPE_SUCCESS = 'REFERENCES/ACTIVER_EXAMEN_TYPE_SUCCESS'
export const ACTIVER_EXAMEN_TYPE_ERROR = 'REFERENCES/ACTIVER_EXAMEN_TYPE_ERROR'
export const activerExamenType = (id: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: ACTIVER_EXAMEN_TYPE_REQUEST })
	return api.activerExamenType(id)
		.then((result: any) => dispatch({
			type: ACTIVER_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: ACTIVER_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const DESACTIVER_EXAMEN_TYPE_REQUEST = 'REFERENCES/DESACTIVER_EXAMEN_TYPE_REQUEST'
export const DESACTIVER_EXAMEN_TYPE_SUCCESS = 'REFERENCES/DESACTIVER_EXAMEN_TYPE_SUCCESS'
export const DESACTIVER_EXAMEN_TYPE_ERROR = 'REFERENCES/DESACTIVER_EXAMEN_TYPE_ERROR'
export const desactiverExamenType = (id: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: DESACTIVER_EXAMEN_TYPE_REQUEST })
	return api.desactiverExamenType(id)
		.then((result: any) => dispatch({
			type: DESACTIVER_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: DESACTIVER_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const SUPPRIMER_EXAMEN_TYPE_REQUEST = 'REFERENCES/SUPPRIMER_EXAMEN_TYPE_REQUEST'
export const SUPPRIMER_EXAMEN_TYPE_SUCCESS = 'REFERENCES/SUPPRIMER_EXAMEN_TYPE_SUCCESS'
export const SUPPRIMER_EXAMEN_TYPE_ERROR = 'REFERENCES/SUPPRIMER_EXAMEN_TYPE_ERROR'
export const supprimerExamenType = (id: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: SUPPRIMER_EXAMEN_TYPE_REQUEST })
	return api.supprimerExamenType(id)
		.then((result: any) => dispatch({
			type: SUPPRIMER_EXAMEN_TYPE_SUCCESS,
			result
		}))
		.catch((e: any) => {
			dispatch({ type: SUPPRIMER_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const REPLIQUER_AFFECTATIONS_REQUEST = 'REFERENCES/REPLIQUER_AFFECTATIONS_REQUEST'
export const REPLIQUER_AFFECTATIONS_SUCCESS = 'REFERENCES/REPLIQUER_AFFECTATIONS_SUCCESS'
export const REPLIQUER_AFFECTATIONS_ERROR = 'REFERENCES/REPLIQUER_AFFECTATIONS_ERROR'
export const repliquerAffectations = (id: number, values: any) => (dispatch: Dispatch<any>) => {
	dispatch({ type: REPLIQUER_AFFECTATIONS_REQUEST })
	return api.repliquerAffectations(id, values)
		.then(() => dispatch({
			type: REPLIQUER_AFFECTATIONS_SUCCESS
		}))
		.catch((e: any) => {
			dispatch({ type: REPLIQUER_AFFECTATIONS_ERROR })
			throw e
		})
}

export const SUPPRIMER_ASSOCIATION_FORMATION_REQUEST = 'REFERENCES/SUPPRIMER_ASSOCIATION_FORMATION_REQUEST'
export const SUPPRIMER_ASSOCIATION_FORMATION_SUCCESS = 'REFERENCES/SUPPRIMER_ASSOCIATION_FORMATION_SUCCESS'
export const SUPPRIMER_ASSOCIATION_FORMATION_ERROR   = 'REFERENCES/SUPPRIMER_ASSOCIATION_FORMATION_ERROR'
export const deleteAssociationFormation = (idExamenType: number, idFormation: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: SUPPRIMER_ASSOCIATION_FORMATION_REQUEST })
	return api.deleteAssociationFormation(idExamenType, idFormation)
		.then(() => dispatch({
			type: SUPPRIMER_ASSOCIATION_FORMATION_SUCCESS
		}))
		.catch((e: any) => {
			dispatch({ type: SUPPRIMER_ASSOCIATION_FORMATION_ERROR })
			throw e
		})
}

export const GET_PJ_EXAMEN_TYPE_REQUEST = 'REFERENCES/GET_PJ_EXAMEN_TYPE_REQUEST'
export const GET_PJ_EXAMEN_TYPE_SUCCESS = 'REFERENCES/GET_PJ_EXAMEN_TYPE_SUCCESS'
export const GET_PJ_EXAMEN_TYPE_ERROR   = 'REFERENCES/GET_PJ_EXAMEN_TYPE_ERROR'
export const getPiecesJustificativesByExamenType = (idExamenType: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: GET_PJ_EXAMEN_TYPE_REQUEST })
	return api.getPiecesJustificativesByExamenType(idExamenType)
		.then((pj: any) => dispatch({
			type: GET_PJ_EXAMEN_TYPE_SUCCESS,
			pj
		}))
		.catch((e: any) => {
			dispatch({ type: GET_PJ_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const CLEAR_PJ_EXAMEN_TYPE = 'REFERENCES/CLEAR_PJ_EXAMEN_TYPE'
export const clearPiecesJustificatives = () => (dispatch: Dispatch<any>) => {
	dispatch({ type: CLEAR_PJ_EXAMEN_TYPE })
}

export const GET_PJ_ABSENTES_EXAMEN_TYPE_REQUEST = 'REFERENCES/GET_PJ_ABSENTES_EXAMEN_TYPE_REQUEST'
export const GET_PJ_ABSENTES_EXAMEN_TYPE_SUCCESS = 'REFERENCES/GET_PJ_ABSENTES_EXAMEN_TYPE_SUCCESS'
export const GET_PJ_ABSENTES_EXAMEN_TYPE_ERROR   = 'REFERENCES/GET_PJ_ABSENTES_EXAMEN_TYPE_ERROR'
export const getListePjsAbsentes = (idExamenType: number) => (dispatch: Dispatch<any>) => {
	dispatch({ type: GET_PJ_ABSENTES_EXAMEN_TYPE_REQUEST })
	return api.getPjAbsentes(idExamenType)
		.then((pj: any[]) => dispatch({
			type: GET_PJ_ABSENTES_EXAMEN_TYPE_SUCCESS,
			pj
		}))
		.catch((e: any) => {
			dispatch({ type: GET_PJ_ABSENTES_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const CREATE_PJ_EXAMEN_TYPE_REQUEST = 'REFERENCES/CREATE_PJ_EXAMEN_TYPE_REQUEST'
export const CREATE_PJ_EXAMEN_TYPE_SUCCESS = 'REFERENCES/CREATE_PJ_EXAMEN_TYPE_SUCCESS'
export const CREATE_PJ_EXAMEN_TYPE_ERROR   = 'REFERENCES/CREATE_PJ_EXAMEN_TYPE_ERROR'
export const creerPj = (idExamenType: number, values: any) => (dispatch: Dispatch<any>) => {
	dispatch({ type: CREATE_PJ_EXAMEN_TYPE_REQUEST })
	return api.creerPj(idExamenType, values)
		.then((pj: any[]) => dispatch({
			type: CREATE_PJ_EXAMEN_TYPE_SUCCESS,
			pj
		}))
		.catch((e: any) => {
			dispatch({ type: CREATE_PJ_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_REQUEST = 'REFERENCES/TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_REQUEST'
export const TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_SUCCESS = 'REFERENCES/TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_SUCCESS'
export const TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_ERROR   = 'REFERENCES/TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_ERROR'
export const toggleObligatoirePj = (idExamenType: number,
                                    idPj: number,
                                    obligatoire: boolean) => (dispatch: Dispatch<any>) => {
	dispatch({ type: TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_REQUEST })
	return api.toggleObligatoirePj(idExamenType, idPj, obligatoire)
		.then((pj: any) => dispatch({
			type: TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_SUCCESS,
			pj
		}))
		.catch((e: any) => {
			dispatch({ type: TOGGLE_OBLIGATOIRE_PJ_EXAMEN_TYPE_ERROR })
			throw e
		})
}

export const TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_REQUEST = 'REFERENCES/TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_REQUEST'
export const TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_SUCCESS = 'REFERENCES/TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_SUCCESS'
export const TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_ERROR   = 'REFERENCES/TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_ERROR'
export const toggleActivePj = (idExamenType: number,
                               idPj: number,
                               active: boolean) => (dispatch: Dispatch<any>) => {
	dispatch({ type: TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_REQUEST })
	return api.toggleActivePj(idExamenType, idPj, active)
		.then((pj: any) => dispatch({
			type: TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_SUCCESS,
			pj
		}))
		.catch((e: any) => {
			dispatch({ type: TOGGLE_ACTIVE_PJ_EXAMEN_TYPE_ERROR })
			throw e
		})
}


export const HANDLE_QUERY_EXAMEN_TYPES = 'HANDLE_QUERY_EXAMEN_TYPES'
export const RESET_QUERY_EXAMEN_TYPES = "RESET_QUERY_EXAMEN_TYPES"
export const handleQuery = (key: string, value: any) => (dispatch: Dispatch<any>) => {
	dispatch({
		type: HANDLE_QUERY_EXAMEN_TYPES,
		key,
		value
	})
}
export const resetQuery = () => (dispatch: Dispatch<any>) => {
	dispatch({
		type: RESET_QUERY_EXAMEN_TYPES
	})
}