import MenuItem from '@material-ui/core/MenuItem'
import { Actions, Button, checkAuthorities, injectSession, PROFIL_UTILISATEUR, ResponsiveForm, Select, injectFormSnackbar } from '@oceane/ui'
import { injectToolbarData, Title } from 'isotope-client'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { getExamensTypeDiplome, passerAEditeDiplome } from './services/editionDiplomeActions'

const FORM_NAME = 'EditionDiplomeForm'

const EditionDiplome = ({
	                        examenTypeSelected,
	                        profil,
	                        submitting,
	                        handleSubmit,
	                        getExamensTypeDiplome,
	                        passerAEditeDiplome
                        }) => {
	const [examensType, setExamensType] = React.useState([])

	React.useEffect(() => {
		getExamensTypeDiplome()
			.then(({ response }) => {
				setExamensType(response)
			})
	}, [])

	const exporter = () => {
		download(`/files/editionDiplomes/${examensType[examenTypeSelected].id}?profil=${profil}`)
	}

	const passerAEdite = (values) =>
		passerAEditeDiplome(examensType[values.examenType].id)

	return (
		<React.Fragment>
			<Title value="Sélection de l'examen" />
			<ResponsiveForm>
				<Field
					id="examenType"
					name="examenType"
					label="Examen type *"
					component={Select}
				>
					{examensType && examensType.length !== 0 && examensType.map((examen, index) =>
						<MenuItem key={index} value={index}>
							{examen.libelle}
						</MenuItem>
					)}
				</Field>
				<Actions>
					<Button
						loading={submitting}
						color="primary"
						onClick={handleSubmit(passerAEdite)}
					>
						Passer à édité
					</Button>
					<Button
						loading={submitting}
						color="primary"
						variant="contained"
						onClick={exporter}
					>
						Exporter
					</Button>
				</Actions>
			</ResponsiveForm>
		</React.Fragment>
	)
}

EditionDiplome.propTypes = {
	examenTypeSelected: PropTypes.number,
	getExamensTypeDiplome: PropTypes.func,
	passerAEditeDiplome: PropTypes.func
}

const mapStateToProps = state => ({
	examenTypeSelected: formValueSelector(FORM_NAME)(state, 'examenType')
})

const actions = {
	getExamensTypeDiplome,
	passerAEditeDiplome
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE
	),
	connect(mapStateToProps, actions),
	injectSession,
	injectToolbarData({ title: 'Edition des diplômes' }),
	reduxForm({
		form: FORM_NAME,
		initialValues: {
			examenType: 0
		},
		enableReinitialize: true
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' })
)(EditionDiplome)
