import Typography from '@material-ui/core/Typography'
import { Popup, Button } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'


const ConfirmationGenerationPopup = ({ open, generer, onClose }) => {

	const popupContent = () => (
		<Typography variant="body1">
			Confirmez-vous la génération d'un nouveau sujet ?
		</Typography>
	)

	const actions = [
		<Button
			key="annuler"
			color="primary"
			onClick={onClose}
		>
			Annuler
		</Button>,
		<Button
			key="supprimer"
			color="primary"
			variant="contained"
			onClick={generer}
		>
			Confirmer
		</Button>
	]

	return (
		<Popup
			open={open}
			title="Génération nouveau sujet"
			content={popupContent()}
			actions={actions}
		/>
	)
}

ConfirmationGenerationPopup.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	generer: PropTypes.func
}

export default (ConfirmationGenerationPopup)
