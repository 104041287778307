import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getSiteEcranById, getExamensTypeBySite, getEpreuve } from './siteEcranAction'
import { Loader } from '@oceane/ui'

/**
 * Charge le site écran de la page courante.
 */
export const loadSiteEcran = (WrappedComponent) => {
	const LoadSiteEcranInjector = ({ getSiteEcranById, ...props }) => {
		const [site, setSite] = React.useState({})
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			if (props.match && props.match.params && props.match.params.id) {
				getSiteEcranById(props.match.params.id)
					.then(({ site }) => {
						setSite(site)
						setLoading(false)
					})
			}
		}, [])

		return loading ? null : <WrappedComponent site={site} {...props} />
	}

	const actions = {
		getSiteEcranById
	}

	LoadSiteEcranInjector.propTypes = {
		match: PropTypes.object,
		getSiteEcranById: PropTypes.func
	}

	return connect(null, actions)(LoadSiteEcranInjector)
}

/**
 * Charge la liste des examens type qu'il est possible d'affecter au site courant.
 * Cette liste inclut également les examens type déjà affectés à ce site.
 */
export const loadListeExamensType = (WrappedComponent) => {
	const LoadListeExamensInjector = ({ getExamensTypeBySite, ...props }) => {
		const [listeExamensType, setListeExamensType] = React.useState([])

		React.useEffect(() => {
			if (props.match && props.match.params && props.match.params.id) {
				getExamensTypeBySite(props.match.params.id)
					.then(({ examens }) => setListeExamensType(examens))
			}
		}, [])

		return <WrappedComponent listeExamensType={listeExamensType} {...props} />
	}

	const actions = {
		getExamensTypeBySite
	}

	LoadListeExamensInjector.propTypes = {
		match: PropTypes.object,
		getExamensTypeBySite: PropTypes.func
	}

	return connect(null, actions)(LoadListeExamensInjector)
}

export const loadEpreuve = (WrappedComponent) => {
	const LoadEpreuveInjector = ({ getEpreuve, ...props }) => {
		const [epreuve, setEpreuve] = React.useState(null)
		const [isLoading, setIsLoading] = React.useState(false)

		React.useEffect(() => {
			if (props.match && props.match.params && props.match.params.id && props.match.params.idEpreuve && props.match.params.date) {
				setIsLoading(true)
				getEpreuve(props.match.params.idEpreuve, props.match.params.id, props.match.params.date)
					.then(({ result }) => {
						setEpreuve(result)
						setIsLoading(false)
					})
					.catch(() => setIsLoading(false))
			}
		}, [])

		return isLoading ? <Loader /> : <WrappedComponent epreuve={epreuve} {...props} />
	}

	const actions = {
		getEpreuve
	}

	LoadEpreuveInjector.propTypes = {
		match: PropTypes.object,
		getExamensTypeBySite: PropTypes.func
	}

	return connect(null, actions)(LoadEpreuveInjector)
}
