import PropTypes from 'prop-types'
import React from 'react'
import { Button, Input, Popup, ResponsiveForm, Select, SmallSelect, Error, injectFormSnackbar } from '@oceane/ui'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { FormCheckbox } from 'isotope-client'
import { postSessionPapier } from '../../services/sessionsPapierActions'

const NOW_ANNEE = new Date().getFullYear()

const ANNEES = [NOW_ANNEE, NOW_ANNEE + 1, NOW_ANNEE + 2]

const validate = (values) => {
	const errors = {}

	if (!values.idExamenType) {
		errors.idExamenType = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouvelleSessionPapierPopup = ({
	                                    listeExamensType,
	                                    open,
	                                    submitting,
	                                    handleSubmit,
	                                    reset,
	                                    onSubmit,
	                                    onClose,
	                                    error,
	                                    postSessionPapier,
	                                    goToSessionPapierPage
                                    }) => {


	const creerSession = (values) => {
		return postSessionPapier(values)
			.then(({ session }) => {
				goToSessionPapierPage(session.id)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	const closePopup = () => {
		reset()
		onClose()
	}

	const popupContent = () => (
		<ResponsiveForm>
			{!!error && error.length > 0 && <Error error={error} isPopinContainer />}
			<Field
				id="annee"
				name="annee"
				label="Année *"
				component={SmallSelect}
			>
				{ANNEES.map(annee => <MenuItem key={`annee-${annee}`} value={annee}>{annee}</MenuItem>)}
			</Field>
			<Field
				id="mois"
				name="mois"
				label="Mois *"
				component={SmallSelect}
			>
				{[...Array(12)].map((item, index) => <MenuItem key={`mois-${index}`} value={index + 1}>{index + 1}</MenuItem>)}
			</Field>
			<Field
				id="idExamenType"
				name="idExamenType"
				label="Examen type *"
				component={Select}
			>
				{listeExamensType.filter(examen => !examen.ecran).map(value =>
					<MenuItem
						key={value.id}
						value={value.id}
					>
						{value.libelleCourt}
					</MenuItem>
				)}
			</Field>
			<Field
				name="suffixe"
				label="Suffixe"
				component={Input}
			/>
			<Field
				name="exceptionnelle"
				label="Session exceptionnelle"
				component={FormCheckbox}
			/>
		</ResponsiveForm>
	)

	const actions = [
		<Button key="close" color="primary" onClick={() => closePopup()}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(creerSession)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouvelle session papier"
			content={popupContent()}
			onExited={closePopup}
		/>
	)
}

NouvelleSessionPapierPopup.propTypes = {
	listeExamensType: PropTypes.array,
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	reset: PropTypes.func,
	handleSubmit: PropTypes.func,
	postSessionPapier: PropTypes.func,
	goToSessionPapierPage: PropTypes.func
}

const actions = {
	postSessionPapier,
	goToSessionPapierPage : (id) => dispatch => dispatch(push(`/sessions-papier/${id}/general`))
}

export default compose(
	connect(null, actions),
	reduxForm({
		form: 'SessionPapierForm',
		initialValues: {
			annee: ANNEES[1],
			mois: 1
		},
		validate
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs', success: 'La session a bien été créée' })
)(NouvelleSessionPapierPopup)
