import { Input, Popup, Button } from '@oceane/ui'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { createSitePapier } from './services/centrePapierActions'
import { push } from 'connected-react-router'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.capacite) {
		errors.capacite = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const NouveauSitePapierPopup = ({
									handleSubmit,
									open,
									reset,
									createSitePapier,
									snackSuccess,
									closePopupCreation,
									nom,
									idCentre,
									goToDetails
								}) => {
	const submitCreateSitePapier = (values) =>
		createSitePapier(values, idCentre)
			.then(({ site }) => {
				snackSuccess({ id: 'references.succes' })
				closePopupCreation()
				goToDetails(idCentre, site.id)
			})

	const closePopup = () => {
		closePopupCreation()
	}

	const popupContent = () => {
		return (
			<>
				<form>
					<Field
						id="libelle"
						name="libelle"
						label="Libellé *"
						component={Input}
						inputProps={{
							maxLength: 50
						}}
						normalize={(value) => {
							if (value.indexOf(nom + ' - ') !== -1 && value.substring(0, nom.length + 3) !== (nom + ' - ')) {
								return value.substring(value.indexOf(nom + ' - '))
							} else if (value.substring(0, nom.length + 3) !== (nom + ' - ')) {
								if (value.length < (nom.length + 3)) {
									return nom + ' - '
								} else {
									return nom + ' - ' + value
								}
							} else {
								return value
							}
						}}
					/>
					<Field
						id="capacite"
						name="capacite"
						label="Capacité *"
						component={Input}
					/>
				</form>
			</>
		)
	}

	const actions = [
		<Button key="close" color="primary" onClick={closePopup}>
			Annuler
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			onClick={handleSubmit(submitCreateSitePapier)}
		>
			Créer
		</Button>
	]

	return (
		<Popup
			open={open}
			actions={actions}
			title="Nouveau site papier"
			content={popupContent()}
			onExited={reset}
		/>
	)
}

NouveauSitePapierPopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	createSitePapier: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		libelle: ownProps.nom + ' - ',
		capacite: ''
	}
})

const actions = {
	createSitePapier,
	goToDetails: (idCentre, idSite) => dispatch => dispatch(push({
		pathname: `/centres/${idCentre}/${idSite}`
	}))
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'NouveauSitePapierForm',
		validate,
		enableReinitialize: true
	}),
	injectSnackActions
)(NouveauSitePapierPopup)
