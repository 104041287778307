import React from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field, reduxForm } from 'redux-form'
import { Error, Input, InputFile, Select, isNumeric } from '@oceane/ui'
import * as matieresSelectors from '../../../references/matieres/services/matieresSelectors'
import MenuItem from '@material-ui/core/MenuItem'
import * as shapes from '../../../../config/shapes'

const styles = () => getStyles({
	marginError: {
		marginBottom: 0
	}
})

const validate = values => {
	const errors = {}
	if (!values.matiere) {
		errors.matiere = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.version) {
		errors.version = { id: 'global.erreurs.obligatoire' }
	} else if (!isNumeric(values.version)) {
		errors.version = { id: 'global.erreurs.numeric' }
	}
	if (!values.fichierImport) {
		errors.fichierImport = { id: 'global.erreurs.obligatoire' }
	} else if (values.fichierImport[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || !values.fichierImport[0].name.endsWith('.xlsx')) {
		errors.fichierImport = { id: 'global.erreurs.typeInvalide', values: { type: 'XLSX' } }
	}

	return errors
}

const NouvelImportKSAForm = ({ handleSubmit, customError, matieres, classes }) => (
	<>
		<Error error={customError} isPopinContainer />
		<form onSubmit={handleSubmit}>
			<Field
				id="matiere"
				name="matiere"
				label="Matiere *"
				component={Select}
			>
				{matieres.filter(matiere => matiere.code.startsWith("K")).map((matiere, index) => <MenuItem
					key={`matiere-${index}`}
					value={matiere.id}
				>
					{matiere.code}
				</MenuItem>)}
			</Field>
			<Field
				id="version"
				name="version"
				label="Version *"
				component={Input}
				inputProps={{
					maxLength: 50
				}}
			/>
			<InputFile
				label="Fichier d'import *"
				id="fichierImport"
				name="fichierImport"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				classes={{
					marginError: classes.marginError
				}}
			/>
		</form>
	</>
)

NouvelImportKSAForm.propTypes = {
	customError: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		values: PropTypes.arrayOf(PropTypes.string)
	})),
	matieres: PropTypes.arrayOf(shapes.matiereReferenceShape),
	classes: PropTypes.object,
	handleSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
	matieres: matieresSelectors.getMesMatieres(state)
})

export const IMPORT_KSA_FORM_NAME = 'import100KSAForm'

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
	reduxForm({
		form: IMPORT_KSA_FORM_NAME,
		validate
	})
)(NouvelImportKSAForm)
