import Grid from '@material-ui/core/Grid'
import { Button, Error, Input, Loader, ResponsiveForm } from '@oceane/ui'
import { injectToolbarData, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { getExaminateur, saveExaminateur } from './services/examinateurEtrangerActions'
import * as selectors from './services/examinateurEtrangerSelectors'

const validate = (values) => {
	const errors = {}

	if (!values.nom) {
		errors.nom = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.prenom) {
		errors.prenom = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class DetailExaminateurEtranger extends Component {
	state = {
		locationError: undefined
	}

	componentDidMount() {
		const { match, location, getExaminateur } = this.props
		if (match && match.params && match.params.idExaminateur) {
			getExaminateur(match.params.idExaminateur)
		}
		if (location && location.state && location.state.error) {
			this.setState({ locationError: [location.state.error] })
		}

	}

	submitExaminateur = (values) => {
		const {
			saveExaminateur,
			snackSuccess,
			snackError
		} = this.props

		this.setState({ locationError: undefined })

		return saveExaminateur(values)
			.then(() => snackSuccess())
			.catch(e => {
				if (e.bodyError) {
					snackError()
					throw new SubmissionError({ _error: e.bodyError })
				}
				snackError()
			})

	}

	render() {
		const {
			loading,
			error,
			handleSubmit
		} = this.props

		const { locationError } = this.state

		if (loading) {
			return <Loader />
		}

		return <>
			<Title value={<FormattedMessage id="references.examinateurEtranger.etatCivil" />} />
			<Error error={(error && [error]) || locationError} />
			<ResponsiveForm>
				<Grid container>
					<Grid item xs={6}>
						<Field
							id="nom"
							name="nom"
							label={<FormattedMessage id="references.examinateurEtranger.form.nom" />}
							component={Input}
							inputProps={{
								maxLength: 50
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							id="prenom"
							name="prenom"
							label={<FormattedMessage id="references.examinateurEtranger.form.prenom" />}
							component={Input}
							inputProps={{
								maxLength: 50
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							id="contact"
							name="contact"
							label={<FormattedMessage id="references.examinateurEtranger.contact" />}
							component={Input}
							inputProps={{
								maxLength: 128
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							id="autorisations"
							name="autorisations"
							label={<FormattedMessage id="references.examinateurEtranger.autorisations" />}
							component={Input}
							inputProps={{
								maxLength: 128
							}}
						/>
					</Grid>
					<Grid container justify="flex-end">
						<Button
							type="submit"
							color="primary"
							variant="contained"
							onClick={handleSubmit(this.submitExaminateur)}
						>
							<FormattedMessage id="global.btn.enregistrer" />
						</Button>
					</Grid>
				</Grid>
			</ResponsiveForm>
		</>
	}
}

DetailExaminateurEtranger.propTypes = {
	loading: PropTypes.bool.isRequired,
	saveExaminateur: PropTypes.func.isRequired,
	getExaminateur: PropTypes.func.isRequired
}

const mapStateToProps = state => {
	const examinateur = selectors.getExaminateur(state)

	return {
		initialValues: examinateur,
		loading: selectors.isLoading(state)
	}
}

const actions = {
	saveExaminateur,
	getExaminateur
}

export default compose(
	injectToolbarData({ title: { id: 'references.examinateurEtranger.detail' } }),
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'detailExaminateurEtranger',
		validate,
		enableReinitialize: true
	}),
	injectSnackActions
)(DetailExaminateurEtranger)
