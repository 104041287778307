import { isCodePostalOk, isNumeric, ResponsiveForm, SmallInput, Button } from '@oceane/ui'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Grid from '@material-ui/core/Grid'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { FormCheckbox, getStyles } from 'isotope-client'

const style = () => getStyles({
	input: {
		margin: 0
	},
	checkbox: {
		marginTop: 10
	},
	button: {
		marginTop: 15
	}
})

const validate = (values) => {
	const errors = {}

	if (!!values.identifiant && !isNumeric(values.identifiant)) {
		errors.identifiant = { id: 'global.erreurs.numeric' }
	}

	if (values.numeroEuropeen && !isCodePostalOk(values.numeroEuropeen)) {
		errors.numeroEuropeen = { id: 'global.erreurs.alphaTiret' }
	}
	return errors
}

const FORM_NAME = 'accesDirectQuestionForm'

const AccesDirectForm = ({
	                     identifiant,
	                     numeroEuropeen,
	                     submitting,
	                     handleSubmit,
	                     classes
                     }) => {

	return (
		<ResponsiveForm onSubmit={handleSubmit}>
			<Grid container justify="space-between" alignItems="flex-start">
				<Grid item xs={4}>
					<Field
						name="identifiant"
						label="Identifiant"
						component={SmallInput}
						inputStyle={classes.input}
						inputProps={{
							maxLength: 10
						}}
						disabled={!!numeroEuropeen}
					/>
				</Grid>
				<Grid item xs={4}>
					<Field
						name="numeroEuropeen"
						label="N° européen"
						component={SmallInput}
						inputStyle={classes.input}
						inputProps={{
							maxLength: 10
						}}

						disabled={!!identifiant}
					/>
				</Grid>
				<Grid item xs={2} className={classes.checkbox}>
					<Field
						id="exploitee"
						name="exploitee"
						label="Exploitée"
						component={FormCheckbox}
					/>
				</Grid>
				<Grid container item xs={2} justify="flex-end">
					<Grid item className={classes.button}>
						<Button
							color="primary"
							variant="contained"
							disabled={!identifiant && !numeroEuropeen}
							type="submit"
							loading={submitting}
						>
							Afficher
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</ResponsiveForm>)
}

const mapStateToProps = state => {
	const { identifiant, numeroEuropeen } = formValueSelector(FORM_NAME)(state, 'identifiant', 'numeroEuropeen')
	return {
		identifiant,
		numeroEuropeen
	}
}

AccesDirectForm.propTypes = {
	classes: PropTypes.object,
	identifiant: PropTypes.string,
	numeroEuropeen: PropTypes.string,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func
}

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME,
		validate,
		destroyOnUnmount: false,
		initialValues: {
			identifiant: '',
			numeroEuropeen: '',
			exploitee: false
		}
	}),
	withTheme(),
	withStyles(style)
)(AccesDirectForm)
