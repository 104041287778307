import { Input, Popup, Button, injectFormSnackbar } from '@oceane/ui'
import { dataTableActions } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { modificationTopic } from '../service/ligneProgrammeAction'
import * as selectors from '../service/ligneProgrammeSelectors'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	if (!values.code) {
		errors.code = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class ModificationTopicPopup extends Component {

	submitModificationTopic = (values) => {
		const { modificationTopic, refreshTable, closePopupModification, idMatiere } = this.props

		return modificationTopic(values, idMatiere)
			.then(() => {
				refreshTable()
				closePopupModification()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	closePopup = () => {
		const {
			closePopupModification,
			reset
		} = this.props

		reset()
		closePopupModification()
	}

	popupContent = () => (
		<form
			id="modificationTopicForm"
		>
			<Field
				id="code"
				name="code"
				label="Code *"
				component={Input}
			/>
			<Field
				id="libelle"
				name="libelle"
				label="Libelle *"
				component={Input}
			/>
		</form>
	)

	render() {
		const {
			open,
			handleSubmit,
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				Annuler
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submitModificationTopic)}
			>
				Enregistrer
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Modification du topic"
				content={this.popupContent()}
				onExited={this.closePopup}
			/>
		)
	}
}

ModificationTopicPopup.propTypes = {
	open: PropTypes.bool,
	closePopupModification: PropTypes.func,
	modificationMatiere: PropTypes.func,
	idMatiere: PropTypes.string
}

const mapStateToProps = state => ({
	initialValues: selectors.getDetailTopic(state)
})

const actions = {
	modificationTopic,
	refreshTable: () => dataTableActions.refresh('listeTopics')
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modificationTopicForm',
		enableReinitialize: true,
		validate
	}),
	injectFormSnackbar()
)(ModificationTopicPopup)
