import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { Actions, checkAuthorities, injectSession, LargeInput, PROFIL_UTILISATEUR, injectFormSnackbar } from '@oceane/ui'
import { push } from 'connected-react-router'
import { dataTableActions, getStyles, injectToolbarData, Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { injectPieceJustificativeWithMatch } from './services/injectPieceJustificativeWithMatch'
import { modificationPieceJustificative } from './services/piecesJustificativesAction'
import * as selectors from './services/piecesJustificativesSelectors'

const style = () => getStyles({
	form: {
		marginTop: 20,
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

class ModifierPieceJustificativePage extends Component {
	submit = (values) => {
		const { modificationPieceJustificative, refreshTable, goToListePiecesJustificatives } = this.props

		return modificationPieceJustificative(values)
			.then(() => {
				refreshTable()
				goToListePiecesJustificatives()
			})
			.catch(e => {
				throw new SubmissionError(e)
			})

	}

	render() {
		const {
			classes,
			handleSubmit
		} = this.props

		return (
			<form
				className={classes.form}
				id="modifierPjForm"
			>
				<Title
					value="Données Générales"
				/>
				<Field
					id="libelle"
					name="libelle"
					label="Libellé *"
					component={LargeInput}
				/>
				<Actions>
					<Button
						color="primary"
						variant="contained"
						onClick={handleSubmit((values) => this.submit(values))}
					>
						Enregistrer
					</Button>
				</Actions>
			</form>
		)
	}
}

const mapStateToProps = state => ({
	initialValues: selectors.getPieceJustificative(state)
})

const actions = {
	modificationPieceJustificative,
	refreshTable: () => dataTableActions.refresh('piecesJustificatives'),
	goToListePiecesJustificatives: () => dispatch => dispatch(push('/pieces-justificatives'))
}

ModifierPieceJustificativePage.propTypes = {
	initialValues: PropTypes.object,
	modificationPieceJustificative: PropTypes.func,
	refreshTable: PropTypes.func
}

export default compose(
	injectToolbarData({ title: 'Détail de la pièce justificative' }),
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectPieceJustificativeWithMatch,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modifierPjForm',
		enableReinitialize: true,
		validate
	}),
	withStyles(style),
	injectSession,
	injectFormSnackbar({ success: { id: 'references.succes' } })
)(ModifierPieceJustificativePage)
