import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import MailIcon from '@material-ui/icons/Mail'
import {
	Autocomplete,
	Error,
	getVilles,
	Input,
	isCodePostalOk,
	isEmailOk,
	isPrenomOuNomOk,
	isTelephoneOk,
	Select
} from '@oceane/ui'
import { FormCheckbox, FormInput, getStyles, Link, Title } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { enregistrerOrganisme } from './services/detail/detailOrganismeAction'
import { injectCompteClient } from '../compteClient/services/injectCompteClient'
import * as selectors from './services/popup/nouvelOrganismePopupSelectors'
import ConfirmSupressionPratiquePopup from './ConfirmSupressionPratiquePopup'

const FORM_NAME = 'detailOrganismeForm'

const styles = (theme) => getStyles({
	popupForm: {
		marginTop: 20,
		marginBottom: 20
	},
	tooltip: {
		margin: '-15px 0 0 -130px'
	},
	globalErreur: {
		marginTop: 20
	},
	buttons: {
		marginLeft: 20
	}
})

const validate = (values) => {
	const errors = {}

	//CHAMPS OBLIGATOIRES
	if (!values.libelleCourt) {
		errors.libelleCourt = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.libelleLong) {
		errors.libelleLong = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.adresseCodePostal) {
		errors.adresseCodePostal = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.adresseVille) {
		errors.adresseVille = { id: 'global.erreurs.obligatoire' }
	}

	//ERREURS
	if (values.responsableNom && !isPrenomOuNomOk(values.responsableNom)) {
		errors.responsableNom = { id: 'global.erreurs.nom' }
	}
	if (values.responsablePrenom && !isPrenomOuNomOk(values.responsablePrenom)) {
		errors.responsablePrenom = { id: 'global.erreurs.nom' }
	}
	if (values.responsableEmail && !isEmailOk(values.responsableEmail)) {
		errors.responsableEmail = { id: 'global.erreurs.formatEmail' }
	}
	if (values.contact && !isEmailOk(values.contact)) {
		errors.contact = { id: 'global.erreurs.formatEmail' }
	}
	if (values.adresseCodePostal && !isCodePostalOk(values.adresseCodePostal)) {
		errors.adresseCodePostal = { id: 'references.organismes.onglets.errors.codePostal' }
	}
	if (values.responsableTelephone && !isTelephoneOk(values.responsableTelephone)) {
		errors.responsableTelephone = { id: 'global.erreurs.telephone' }
	}
	if (values.telephone && !isTelephoneOk(values.telephone)) {
		errors.telephone = { id: 'global.erreurs.telephone' }
	}
	if (values.telecopie && !isTelephoneOk(values.telecopie)) {
		errors.telecopie = { id: 'global.erreurs.telephone' }
	}
	if (values.memo && values.memo.length > 1024) {
		errors.memo = 'Le champ Mémo ne doit pas dépasser 1024 caractères'
	}

	return errors
}


const DetailOrganismeOnglet = ({ classes, initialValues, getVilles, comptesClients, etat, handleSubmit, error, enregistrerOrganisme, examenPratique, snackSuccess, snackError }) => {
	const [toggleActiveMode, setToggleActiveMode] = React.useState(false)
	const [open, setOpen] = React.useState(false)

	const openPopup = toggleActive => {
		setOpen(true)
		setToggleActiveMode(toggleActive)
	}
	const closePopup = () => {
		setOpen(false)
		setToggleActiveMode(false)
	}

	const enregistrer = (values, toggleActive) => {
		enregistrerOrganisme(values, toggleActive)
			.then(() => {
				snackSuccess()
				closePopup()
			})
			.catch(e => {
				snackError()
				throw new SubmissionError(e)
			})
	}

	const submit = toggleActive => values => {
		if (initialValues.examenPratique === true && !examenPratique) {
			openPopup(toggleActive)
		} else {
			enregistrer(values, toggleActive)
		}
	}

	return (
		<React.Fragment>
			<div className={classes.globalErreur}>
				<Error error={error} />
			</div>

			<form
				className={classes.popupForm}
				id="detailOrganismeForm"
			>
				<Title value="Données générales" />
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="libelleCourt"
							name="libelleCourt"
							label="Libellé court *"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="etat"
							name="etat"
							label="Etat de l'organisme"
							component={Select}
							disabled
						>
							<MenuItem key={2} value={2}>
								Désactivé
							</MenuItem>
							<MenuItem key={1} value={1}>
								Activé
							</MenuItem>
						</Field>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="libelleLong"
							name="libelleLong"
							label="Libellé long *"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="importCandidat"
							name="importCandidat"
							label="Autoriser l'import de candidats"
							component={FormCheckbox}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							name="idCompteClientDCS"
							label="Compte client"
							component={Select}
							displayEmpty
						>
							<MenuItem value="" />
							{comptesClients.map(compteClient =>
								<MenuItem key={compteClient.id} value={compteClient.id}>
									{compteClient.libelle}
								</MenuItem>
							)}
						</Field>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="etranger"
							name="etranger"
							label="Etranger"
							component={FormCheckbox}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="examenTheorique"
							name="examenTheorique"
							label="Examen théorique"
							component={FormCheckbox}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="examenPratique"
							name="examenPratique"
							label="Examen pratique"
							component={FormCheckbox}
						/>
					</Grid>
				</Grid>
				<Title value="Responsable pédagogique" />
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="responsableNom"
							name="responsableNom"
							label="Nom"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="responsablePrenom"
							name="responsablePrenom"
							label="Prénom"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="responsableTelephone"
							name="responsableTelephone"
							label="Téléphone"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="responsableEmail"
							name="responsableEmail"
							label="Adresse électronique"
							component={Input}
						/>
					</Grid>
					<Link href={`mailto:${initialValues.responsableEmail}`}>
						<MailIcon className={classes.tooltip} color="primary" />
					</Link>
				</Grid>
				<Title value="Coordonnées" />
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adresseL1"
							name="adresseL1"
							label="Adresse ligne 1"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="telephone"
							name="telephone"
							label="Téléphone"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adresseL2"
							name="adresseL2"
							label="Adresse ligne 2"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="telecopie"
							name="telecopie"
							label="Télécopie"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adresseL3"
							name="adresseL3"
							label="Adresse ligne 3"
							component={Input}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field
							id="contact"
							name="contact"
							label="Contact"
							component={Input}
						/>
					</Grid>
					<Link href={`mailto:${initialValues.contact}`}>
						<MailIcon className={classes.tooltip} color="primary" />
					</Link>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adresseCodePostal"
							name="adresseCodePostal"
							label="Code postal *"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adresseVille"
							name="adresseVille"
							component={Autocomplete}
							getSuggestion={(length, value) => getVilles(value)}
							label="Ville *"
							canLoadIfEmpty
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Field
							id="adressePays"
							name="adressePays"
							label="Pays"
							component={Input}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<Field
							id="memo"
							name="memo"
							label="Mémo"
							component={FormInput}
							fullWidth
							multiline
						/>
					</Grid>
				</Grid>
				<Grid
					container
					justify="flex-end"
				>
					<Button
						color="primary"
						onClick={handleSubmit(submit(true))}
					>
						{etat === 2 && 'Activer'}
						{etat === 1 && 'Désactiver'}
					</Button>
					<Button
						type="submit"
						color="primary"
						variant="contained"
						onClick={handleSubmit(submit(false))}
						className={classes.buttons}
					>
						Enregistrer
					</Button>
				</Grid>
			</form>
			<ConfirmSupressionPratiquePopup
				open={open}
				closePopup={closePopup}
				onSubmit={values => enregistrer(values, toggleActiveMode)}
			/>
		</React.Fragment>
	)
}

DetailOrganismeOnglet.propTypes = {
	organismeDetail: PropTypes.object,
	getVilles: PropTypes.func,
	initialValues: PropTypes.object,
	etat: PropTypes.number,
	examenPratique: PropTypes.bool,
	openPopup: PropTypes.func,
	enregistrerOrganisme: PropTypes.func
}

const mapStateToProps = state => {
	const { etat, examenPratique } = formValueSelector(FORM_NAME)(state, 'etat', 'examenPratique')

	return {
		initialValues: selectors.getOrganismeDetails(state),
		etat,
		examenPratique
	}
}

const actions = {
	getVilles,
	enregistrerOrganisme
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	reduxForm({
		form: 'detailOrganismeForm',
		enableReinitialize: true,
		validate
	}),
	injectCompteClient,
	injectSnackActions
)(DetailOrganismeOnglet)
