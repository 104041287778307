import { EmptyResult, hasAccess, injectSession, Link, PROFIL_UTILISATEUR, ListFiltres } from '@oceane/ui'
import { SpringDataTable, Title } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as matieresSelectors from '../matieres/services/matieresSelectors'
import * as matieresActions from '../matieres/services/matieresActions'
import PropTypes from 'prop-types'

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS]

const AnnexeListeMatiere = ({ profil, handleQuery, resetQuery, searchValue }) => {

	React.useEffect(() => resetQuery(), [resetQuery])

	if (!hasAccess(PROFILS_LOGISTIQUE, profil)) {
		return null
	} else {
		return (
			<React.Fragment>
				<Title value="Liste des matières" />
				<ListFiltres
					handleQueryValue={handleQuery}
					resetQueryValue={resetQuery}
					searchValue={searchValue}
					searchPlaceholderId="references.famille.matieres.search"
				/>
				<SpringDataTable
					apiUrl="/matiere"
					headers={[
						{
							key: 'code',
							name: 'Matière',
							sortable: true
						},
						{
							key: 'libelle',
							name: 'Libellé',
							sortable: true,
						},
						{
							key: 'link',
							render: row => <Link to={`/annexes/${row.id}`} state={{ code: row.code }}>Gestion annexes</Link>
						}
					]}
					filters={{
						profilActif: profil,
						code: searchValue
					}}
					noResultFragment={<EmptyResult />}
					defaultPageSize={50}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	searchValue: matieresSelectors.getSearchValue(state)
})

const actions = {
	handleQuery: matieresActions.handleQuery,
	resetQuery: matieresActions.resetQuery
}

AnnexeListeMatiere.propTypes = {
	handleQuery: PropTypes.func.isRequired,
	resetQuery: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired
}

export default compose(
	injectSession,
	connect(mapStateToProps, actions)
)(AnnexeListeMatiere)
