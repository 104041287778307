import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Actions, Button, DateInput, ResponsiveForm, Select } from '@oceane/ui'
import { Field, reduxForm } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'

const styles = () => getStyles({
	select: {
		marginTop: 20
	}
})

const validate = values => {
	const errors = {}
	if (!values.debutPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.finPeriode) {
		errors.finPeriode = { id: 'global.erreurs.obligatoire' }
	}
	if (values.debutPeriode > values.finPeriode) {
		errors.debutPeriode = { id: 'global.erreurs.date.dateDebutSuperieureDateFin' }
	}
	return errors
}

const ConsulterValidationsKSAForm = ({ listeOrganismes, submitting, handleSubmit, classes }) => {
	return (
		<ResponsiveForm onSubmit={handleSubmit}>
			<Field
				id="debutPeriode"
				name="debutPeriode"
				label="Début période *"
				component={DateInput}
			/>
			<Field
				id="finPeriode"
				name="finPeriode"
				label="Fin période *"
				component={DateInput}
			/>
			<div className={classes.select}>
				<Field
					id="organisme"
					name="organisme"
					label="Organisme de formation"
					component={Select}
					placeholder={<MenuItem
						key="organisme-tous"
						value="0"
					>
						Tous les organismes
					</MenuItem>}
				>
					{listeOrganismes.map((organisme, index) => <MenuItem
						key={`organisme-${index}`}
						value={organisme.id}
					>
						{organisme.libelleCourt}
					</MenuItem>)}
				</Field>
			</div>
			<Actions>
				<Button
					loading={submitting}
					color="primary"
					variant="contained"
					type="submit"
				>
					Exporter
				</Button>
			</Actions>
		</ResponsiveForm>
	)
}

ConsulterValidationsKSAForm.propTypes = {
	listeOrganismes: PropTypes.array,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	classes: PropTypes.object
}

export default compose(
	withStyles(styles),
	reduxForm({
		form: 'ConsulterValidationsKSAForm',
		initialValues: {
			debutPeriode: '',
			finPeriode: '',
			organisme: '0'
		},
		validate
	})
)(ConsulterValidationsKSAForm)