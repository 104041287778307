import { fetchFactory } from 'isotope-client'

export const getTypesExamenAutorises = () => fetchFactory('/questions/types-examen-autorises')

export const verifierDisponibiliteCompteRendu = id => fetchFactory(`/versions-banque/${id}/disponibilite-compte-rendu`)

export const verifierAvantMiseEnExploitation = (values) => fetchFactory('/questions/mise-en-exploitation/verification', {
	method: 'post',
	body: JSON.stringify(values)
})

export const demanderMiseEnExploitation = (values) => fetchFactory('/questions/mise-en-exploitation/demande', {
	method: 'post',
	body: JSON.stringify(values)
})
