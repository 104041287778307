import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getExamensTypes, getSessions, getSujet } from './epreuveSujetAction'
import { Loader } from '@oceane/ui'


export const loadSessions = (WrappedComponent) => {
	const LoadSessionsInjector = ({ getSessions, ...props }) => {
		const [sessions, setSessions] = React.useState({})
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			getSessions()
				.then(({ sessions }) => {
					setSessions(sessions)
					setLoading(false)
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent sessions={sessions} {...props} />
	}

	const actions = {
		getSessions
	}

	LoadSessionsInjector.propTypes = {
		getSessions: PropTypes.func
	}

	return connect(null, actions)(LoadSessionsInjector)
}

export const loadExamensTypes = (WrappedComponent) => {
	const LoadExamensTypesInjector = ({ getExamensTypes, ...props }) => {
		const [examensType, setExamensType] = React.useState([])
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			getExamensTypes()
				.then(({ examensTypes }) => {
					setExamensType(examensTypes)
					setLoading(false)
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent examensTypes={examensType} {...props} />
	}

	const actions = {
		getExamensTypes
	}

	LoadExamensTypesInjector.propTypes = {
		getExamensTypes: PropTypes.func
	}

	return connect(null, actions)(LoadExamensTypesInjector)
}

export const loadSujet = (WrappedComponent) => {
	const LoadSujetInjector = ({ getSujet, ...props }) => {
		const [sujet, setSujet] = React.useState({})
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			if(props.match && props.match.params && props.match.params.id) {
				getSujet(props.match.params.id)
					.then(({ result }) => {
						setSujet(result)
						setLoading(false)
					})
			}
		}, [])

		return loading ? <Loader /> : <WrappedComponent sujet={sujet} {...props} />
	}

	const actions = {
		getSujet
	}

	LoadSujetInjector.propTypes = {
		getSujet: PropTypes.func
	}

	return connect(null, actions)(LoadSujetInjector)
}