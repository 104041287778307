import React from 'react'
import PropTypes from 'prop-types'
import { injectToolbarData } from 'isotope-client'
import { Actions, Button, Input, ResponsiveForm, StyledTypography, injectFormSnackbar } from '@oceane/ui'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { loadFamille } from './services/familleInjector'
import { connect } from 'react-redux'
import { putFamille } from './services/familleAction'
import SuppressionFamillePopup from './components/SuppressionFamillePopup'
import { familleShape } from '../../../config/shapes'

const validate = (values) => {
	const errors = {}

	if (!values.libelle) {
		errors.libelle = { id: 'global.erreurs.obligatoire' }
	}

	return errors
}

const ModifierFamille = ({ famille, submitting, handleSubmit, putFamille }) => {
	const [open, setOpen] = React.useState(false)
	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	const submitFamille = (values) => {
		return putFamille(values, famille.idMatiere)
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	return (
		<ResponsiveForm>
			<Field
				id="libelle"
				name="libelle"
				label="Libellé famille *"
				component={Input}
				inputProps={{
					maxLength: 50
				}}
			/>
			<Actions>
				<Button
					color="primary"
					onClick={handleOpenPopup}
				>
					Supprimer
				</Button>
				<Button
					loading={submitting}
					color="primary"
					variant="contained"
					onClick={handleSubmit(submitFamille)}
				>
					Enregistrer
				</Button>
			</Actions>
			<SuppressionFamillePopup
				open={open}
				famille={famille}
				onClose={handleClosePopup}
			/>
		</ResponsiveForm>
	)
}

const mapStateToProps = (state, { famille: initialValues  }) => ({
	initialValues
})

const actions = {
	putFamille
}

ModifierFamille.propTypes = {
	famille: familleShape,
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func,
	putFamille: PropTypes.func
}

export default compose(
	loadFamille,
	connect(mapStateToProps, actions),
	injectToolbarData(({ location: { state }, famille }) => ({
		title: 'Modification de la famille',
		subheader: (state.code && famille.libelle) && {
			separator: () => '>',
			filArianeEntries: [{
				text: `Matière ${state.code}`
			}, {
				text: `Famille ${famille.libelle}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	reduxForm({
		form: 'ModifierFamilleForm',
		validate,
		enableReinitialize: true
	}),
	injectFormSnackbar({ error: 'Le formulaire comporte des erreurs' }),
)(ModifierFamille)
