import { fetchFactory } from 'isotope-client'

export const createExaminateurEtranger = (values) => fetchFactory('/examinateursEtrangers', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const getExaminateur = (id) => fetchFactory(`/examinateursEtrangers/${id}`)

export const putExaminateur = values => fetchFactory('/examinateursEtrangers', {
	method: 'PUT',
	body: JSON.stringify(values)
})
