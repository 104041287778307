import { reducers as reducersIsotope } from 'isotope-client'
import { reducers } from '@oceane/ui'
import { references } from '../modules/references/referencesReducers'
import { default as nouvelleInscriptionReducer } from '../modules/inscrirecandidats/detailexamen/services/popup/nouvelleInscriptionReducer'
import { default as formationEquivalencesReducer } from '../modules/inscrirecandidats/detailexamen/services/formationEquivalencesReducers'
import { statistiques } from '../modules/resultats/statistiquesReducers'
import { default as actionsAvanceesReducer } from '../modules/inscrirecandidats/detailexamen/services/actionsAvanceesReducers'
import { administration } from '../modules/administration/services/administrationReducers'
import { sitesEcran } from '../modules/organiserExamens/siteEcran/services/siteEcranReducers'
import { correctionCopies } from '../modules/obtenirresultats/correctionCopies/service/correctionCopiesReducers'
import { gestionCopies } from '../modules/obtenirresultats/gestionCopies/services/gestionCopiesReducers'
import { banqueQuestions } from '../modules/banqueQuestions/questions/services/banqueQuestionsReducer'
import { paiements } from '../modules/banqueQuestions/paiement/services/paiementReducers'
import { sessionPapier } from '../modules/organiserExamens/sessionsPapier/services/sessionsPapierReducer'

export default {
	...reducersIsotope,
	...reducers({
		formationEquivalences: formationEquivalencesReducer,
		nouvelleInscription: nouvelleInscriptionReducer, // Gère la popup de création d'une nouvelle inscription
		actionsAvancees: actionsAvanceesReducer
	}),
	references,
	administration,
	statistiques,
	sitesEcran,
	correctionCopies,
	gestionCopies,
	banqueQuestions,
	paiements,
	sessionPapier
}
