import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@oceane/ui'
import { getVersionCDActuelle } from './importBCEActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

export const injectVersionCDActuelle = (WrappedComponent) => {
	const VersionCDInjector = ({ getVersionCDActuelle, snackError, ...props }) => {

		const [loading, setLoading] = React.useState(true)
		const [isError, setError] = React.useState(false)
		const [versionCD, setVersionCD] = React.useState(null)

		React.useEffect(() => {
			getVersionCDActuelle()
				.then(({ result }) => {
					setVersionCD(result)
					setLoading(false)
				})
				.catch(() => {
					setError(true)
					setLoading(false)
					snackError()
				})
		}, [])

		return loading ? <Loader /> : isError ? <></> :
			<WrappedComponent versionCD={versionCD} setVersionCD={setVersionCD} {...props} />
	}

	VersionCDInjector.propTypes = {
		getVersionCDActuelle: PropTypes.func,
		snackError: PropTypes.func
	}

	const actions = {
		getVersionCDActuelle
	}

	return compose(
		connect(undefined, actions),
		injectSnackActions
	)(VersionCDInjector)
}


