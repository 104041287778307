import MenuItem from '@material-ui/core/MenuItem'
import { Button, Popup, Select } from '@oceane/ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import * as selectors from './services/examenTheoriqueOrganisme/examenTheoriqueOrganismeSelector'
import {
	closePopupCreation,
	createFormationTheoriqueOrganisme,
	getExamensTypeTheorique
} from './services/examenTheoriqueOrganisme/examenTheoriqueOrganismeAction'
import { injectExamenTheorique } from './services/examenTheoriqueOrganisme/injectExamenTheorique'
import * as selectorsDetail from './services/popup/nouvelOrganismePopupSelectors'

const validate = (values, { intl }) => {
	const errors = {}

	if (!values.examen) {
		errors.examen = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
	}

	return errors
}

class NouvelleFormationTheoriqueOrganismePopup extends Component {

	submit = (values) => {
		const { createFormationTheoriqueOrganisme, closePopupCreation, refreshTable, snackError, organismeDetail } = this.props

		return createFormationTheoriqueOrganisme(values, organismeDetail.id)
			.then(() => {
				refreshTable()
				closePopupCreation()
			})
			.catch(e => {
				e.bodyError ? snackError(e.bodyError) : snackError()
			})
	}

	popupContent = () => {
		const { listeExamensTheoriques } = this.props

		return (
			<React.Fragment>
				<form
					id="nouvelleFormationTheoriqueOrganismeForm"
				>
					<Field
						name="examen"
						label="Examen *"
						component={Select}
					>
						{listeExamensTheoriques.map(exam => (
								<MenuItem key={exam.id} value={exam.id}>
									{exam.libelle}
								</MenuItem>
							)
						)}
					</Field>
				</form>
			</React.Fragment>
		)
	}

	render() {
		const {
			open,
			closePopupCreation,
			handleSubmit,
			reset
		} = this.props

		const actions = [
			<Button
				key="annuler"
				color="primary"
				onClick={closePopupCreation}
			>
				Annuler
			</Button>,
			<Button
				key="valider"
				color="primary"
				variant="contained"
				onClick={handleSubmit(this.submit)}
			>
				Valider
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title="Ajouter un examen"
				content={this.popupContent()}
				onExited={reset}
			/>
		)
	}
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpenCreation(state),
	listeExamensTheoriques: selectors.getExamensTheoriques(state),
	organismeDetail: selectorsDetail.getOrganismeDetails(state)
})

const actions = {
	closePopupCreation,
	getExamensTypeTheorique,
	createFormationTheoriqueOrganisme
}

NouvelleFormationTheoriqueOrganismePopup.propTypes = {
	open: PropTypes.bool,
	closePopupCreation: PropTypes.func,
	getExamensTypeTheorique: PropTypes.func,
	listeExamensTheoriques: PropTypes.array,
	createFormationTheoriqueOrganisme: PropTypes.func,
	organismeDetail: PropTypes.object
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	injectExamenTheorique,
	reduxForm({
		form: 'nouvelleFormationTheoriqueOrganismeForm',
		enableReinitialize: true,
		validate
	}),
	injectSnackActions
)(NouvelleFormationTheoriqueOrganismePopup)
