import Fab from '@material-ui/core/Fab'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import { checkAuthorities, EmptyResult, hasAccess, injectSession, PROFIL_UTILISATEUR } from '@oceane/ui'
import { dataTableActions, getStyles, SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import NouvellePieceJustificativePopup from './NouvellePieceJustificativePopup'
import {
	closePopup,
	deletePieceJustificative,
	openPopup,
	openPopupCreation
} from './services/piecesJustificativesAction'
import SuppressionPieceJustificativePopup from './SuppressionPieceJustificativePopup'
import { push } from 'connected-react-router'
import IconButton from '@material-ui/core/IconButton'


const style = () => getStyles({
	fab: {
		position: 'fixed',
		right: 10,
		bottom: 20
	},
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const PROFILS_LOGISTIQUE = [PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN, PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE]

class PiecesJustificativesPage extends Component {
	state = {
		idDelete: null,
		libelleDelete: ''
	}

	openPopupDelete = (data) => {
		const { openPopup } = this.props

		this.setState({idDelete: data.id, libelleDelete: data.libelle})
		openPopup()
	}

	deleteAction = () => {
		const { deletePieceJustificative, refreshTable, closePopup } = this.props
		const { idDelete } = this.state

		return deletePieceJustificative(idDelete)
			.then(() => {
				refreshTable()
				closePopup()
			})
	}

	render() {
		const {
			profil,
			classes,
			openPopupCreation,
			goToModification
		} = this.props

		const {
			libelleDelete
		} = this.state

		if (!hasAccess(PROFILS_LOGISTIQUE, profil)) {
			return null
		} else {
			return (
				<React.Fragment>
					<SpringDataTable
						apiUrl="/piecesjustificatives"
						headers={[
							{
								key: 'libelle',
								name: 'Libellé',
								sortable: false,
								render: data => <Typography className={classes.libelleColumn} onClick={() => goToModification(data.id)}>{data.libelle}</Typography>
							},
							{
								key: 'supprimer',
								render: data => <IconButton onClick={() => this.openPopupDelete(data)}><DeleteIcon /></IconButton>
							}

						]}
						mode="get"
						nom="piecesJustificatives"
						noResultFragment={<EmptyResult/>}
					/>
					<Fab color="primary" aria-label="Add" className={classes.fab} onClick={openPopupCreation}>
						<AddIcon />
					</Fab>
					<SuppressionPieceJustificativePopup onSubmit={this.deleteAction} nom={libelleDelete} />
					<NouvellePieceJustificativePopup />
				</React.Fragment>
			)
		}
	}
}

const mapStateToProps = state => ({})

const actions = {
	refreshTable: () => dataTableActions.refresh('piecesJustificatives'),
	openPopup,
	closePopup,
	openPopupCreation,
	deletePieceJustificative,
	goToModification: (id) => dispatch => dispatch(push(`/pieces-justificatives/${id}`))
}

PiecesJustificativesPage.propTypes = {
	openPopup: PropTypes.func,
	closePopup: PropTypes.func,
	openPopupCreation: PropTypes.func,
	refreshTable: PropTypes.func,
	deletePieceJustificative: PropTypes.func,
	goToModification: PropTypes.func
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	connect(mapStateToProps, actions),
	withStyles(style),
	injectSession
)(PiecesJustificativesPage)
