import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import {
	Calendrier,
	checkAuthorities,
	formatDateForQuery,
	injectSession,
	PROFIL_UTILISATEUR,
	StyledTypography
} from '@oceane/ui'
import { injectToolbarData } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import withStyles from '@material-ui/core/styles/withStyles'
import { fermerJour, filtrerCalendrier } from './services/siteEcranAction'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import ModifierJourneePopup from './ModifierJourneePopup'
import { loadSiteEcran } from './services/injectSiteEcran'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import { push, replace } from 'connected-react-router'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'

const styles = theme => ({
	paperDay: {
		height: 90,
		textAlign: 'right',
		borderRadius: 0,
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: '#E0E0E0',
	},
	paperContent: {
		height: '100%',
		padding: 10
	},
	paperToday: {
		borderStyle: 'solid',
		borderWidth: 2,
		borderColor: theme.palette.primary.main,
		padding: 9
	},
	paperJour2: {
		color: 'black',
		backgroundColor: 'rgba(0,0,0,0.31)'
	},
	paperJour4: {
		color: 'black',
		backgroundColor: 'rgba(255,207,74,1)'
	},
	paperJour5: {
		color: '#A2A2A2',
	},
	paperJour6: {
		color: 'black',
		backgroundColor: 'rgba(201,227,244,1)'
	},
	paperJour7: {
		color: 'black',
		backgroundColor: 'rgba(211,240,205,1)'
	},
	dayOfMonth: {
		padding: 2
	},
	edit: {
		color: theme.palette.primary.main,
		'&:hover': {
			cursor: 'pointer',
		},
		display: 'block',
	},
	nbEpreuves: {
		color: theme.palette.primary.main,
		'&:hover': {
			cursor: 'pointer',
		},
		fontSize: 13,
	},
	nbEpreuvesIcon: {
		paddingRight: 5,
	},
	jour: {
		top: -18,
		right: -13,
		position: 'relative',
		fontSize: 14
	},
	jourNoEdit: {
		top: -30,
		right: -25,
		position: 'relative',
		fontSize: 14
	},
	legende: {
		marginTop: 10,
		marginLeft: 40
	},
	legendeCell: {
		width: 30,
		height: 15,
		marginTop: 10,
		position: 'absolute'
	},
	carreIFR: {
		backgroundColor: 'rgba(211,240,205,1)'
	},
	carreVFR: {
		backgroundColor: 'rgba(201,227,244,1)'
	},
	carreR: {
		backgroundColor: 'rgba(255,207,74,1)'
	},
	carreF: {
		backgroundColor: 'rgba(0,0,0,0.31)'
	}
})

const CalendrierSiteEcran = ({ classes, handleSubmit, setFlitresCalendrier, filtrerCalendrier, site, fermerJour, idSiteEcran, annee, mois, goToPlanningQuotidien, snackSuccess }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [jourClick, setJourClick] = React.useState({})
	const [indexHover, setIndexHover] = React.useState(-1)
	const [calendrier, setCalendrier] = React.useState([])
	const [openPopup, setOpenPopup] = React.useState(false)
	const [defaultHoraires, setDefaultHoraires] = React.useState({})

	React.useEffect(() => {
		const values = {
			idSite: idSiteEcran,
			annee,
			mois
		}
		filtrerCalendrier(values)
			.then(response => setCalendrier(response.calendrier))
	}, [annee, mois])

	const changeFiltresCalendrier = (values) => {
		if (!values.idSite) {
			values = {
				...values,
				idSite: idSiteEcran
			}
		}
		setFlitresCalendrier(values)
	}

	const getCalendrier = (values) => {
		if (!values.idSite) {
			values = {
				...values,
				idSite: idSiteEcran
			}
		}
		filtrerCalendrier(values)
			.then(response => setCalendrier(response.calendrier))
	}

	const clickMenuCase = (event, jour) => {
		setAnchorEl(event.currentTarget)
		setJourClick(jour)
	}

	const closeMenuCase = () => {
		setAnchorEl(null)
	}

	const mouseEnter = (index) => {
		setIndexHover(index)
	}

	const mouseLeave = () => {
		setIndexHover(-1)
	}

	const className = (jour) => {
		let jourEtat = 2
		if (site.type === 0 || site.type === 1) {
			jourEtat = jour.etat
		} else if (jour.etat === 5) {
			jourEtat = 2
		} else {
			jourEtat = jour.etat
		}
		return classNames(
			classes[`paperJour${jourEtat}`],
			classes.paperDay
		)
	}

	const classNameContent = jour => {
		if (jour.isAujourdhui) {
			return classNames(
				classes.paperContent,
				classes.paperToday
			)
		} else {
			return classes.paperContent
		}
	}

	const openPopupModification = (horaires) => {
		setOpenPopup(true)
		setAnchorEl(null)
		if (horaires) {
			setDefaultHoraires(horaires)
		}
	}

	const fermerJournee = (values) => {
		const val = {
			annee: values.annee,
			mois: values.mois,
			idSite: site.id
		}
		fermerJour(val, jourClick.jour)
			.then((response) => {
				setCalendrier(response.calendrier)
				snackSuccess()
				setAnchorEl(null)
			})
	}

	const renderJour = jour => <Grid
		id="paperJour"
		className={className(jour)}
		onMouseEnter={() => mouseEnter(jour.jour)}
		onMouseLeave={mouseLeave}
	>
		<Grid container direction="row" justify="space-between" alignContent="space-between"
			  className={classNameContent(jour)}>
			<Grid container item xs={6}>
				{indexHover === jour.jour &&
				<EditIcon
					id="editJour"
					className={classes.edit}
					onClick={(event) => clickMenuCase(event, jour)}
				/>
				}
			</Grid>
			<Grid container item xs={6} justify="flex-end">
				<Typography component="span" className={classes.dayOfMonth}>{jour.jour}</Typography>
			</Grid>
			<Grid container item xs={6}>
				{!!jour.nombreEpreuves && <Grid
					container
					item
					alignItems="center"
					className={classes.nbEpreuves}
					onClick={() => goToPlanningQuotidien(site, jour.dateJour)}
				>
					<PeopleIcon className={classes.nbEpreuvesIcon} />
					{jour.nombreEpreuves}
				</Grid>}
			</Grid>
			<Grid container item xs={6} />
		</Grid>
	</Grid>

	const legende = <React.Fragment>
		<Grid item sm={2}>
			<Typography className={classNames(classes.legendeCell, classes.carreF)} />
			<Typography className={classes.legende}>
				Fermée
			</Typography>
		</Grid>
		{(site.type === 0 || site.type === 1) && <Grid item sm={2}>
			<Typography className={classNames(classes.legendeCell, classes.carreR)} />
			<Typography className={classes.legende}>
				Réservée
			</Typography>
		</Grid>}
		{(site.type !== 0 && site.type !== 1) && <>
			<Grid item sm={2}>
				<Typography className={classNames(classes.legendeCell, classes.carreVFR)} />
				<Typography className={classes.legende}>
					VFR
				</Typography>
			</Grid>
			<Grid item sm={2}>
				<Typography className={classNames(classes.legendeCell, classes.carreIFR)} />
				<Typography className={classes.legende}>
					IFR
				</Typography>
			</Grid>
		</>}
	</React.Fragment>

	return (
		<React.Fragment>
			<Calendrier
				legende={legende}
				renderJour={renderJour}
				filtrerCalendrier={changeFiltresCalendrier}
				calendrier={calendrier}
				idSite={site.id}
				initialValues={{ annee, mois }}
			/>
			<Menu
				id="jour"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={closeMenuCase}
			>
				{(((site.type === 0 || site.type === 1) && jourClick.etat !== 2) || ((site.type !== 0 && site.type !== 1) && jourClick.etat !== 5)) &&
				<MenuItem onClick={openPopupModification}>Modifier</MenuItem>}
				{(((site.type === 0 || site.type === 1) && jourClick.etat === 2) || ((site.type !== 0 && site.type !== 1) && jourClick.etat === 5)) &&
				<MenuItem onClick={() => openPopupModification({
					heureOuverture: site && site.heureOuverture ? site.heureOuverture : '',
					heureFermeture: site && site.heureFermeture ? site.heureFermeture : '',
					heureDebutPause: site && site.heureDebutPause ? site.heureDebutPause : '',
					heureFinPause: site && site.heureFinPause ? site.heureFinPause : ''
				})}>Ouvrir</MenuItem>}
				{((site.type !== 0 && site.type !== 1 && jourClick.etat !== 5) || ((site.type === 0 || site.type === 1) && jourClick.etat !== 2)) &&
				<MenuItem onClick={handleSubmit(fermerJournee)}>Fermer</MenuItem>}
			</Menu>
			<ModifierJourneePopup
				open={openPopup}
				closePopup={() => setOpenPopup(false)}
				jour={jourClick}
				site={site}
				getCalendrier={handleSubmit(getCalendrier)}
				defaultHoraires={defaultHoraires}
			/>
		</React.Fragment>
	)
}

CalendrierSiteEcran.propTypes = {
	filtrerCalendrier: PropTypes.func,
	fermerJour: PropTypes.func,
	site: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	return {
		idSiteEcran: ownProps.match.params.id,
		annee: typeof ownProps.location.state.annee !== 'undefined' ? ownProps.location.state.annee : new Date().getFullYear(),
		mois: typeof ownProps.location.state.mois !== 'undefined' ? ownProps.location.state.mois : new Date().getMonth()
	}
}

const actions = {
	filtrerCalendrier,
	fermerJour,
	setFlitresCalendrier: (values) => dispatch => dispatch(replace({
		pathname: `/examens-ecran/sites-ecran/calendrier/${values.idSite}`,
		state: { annee: values.annee, mois: values.mois }
	})),
	goToPlanningQuotidien: (site, date) => dispatch => dispatch(push(`/examens/sites-ecran/${site.id}/planning-quotidien/${formatDateForQuery(date)}`))
}

export default compose(
	loadSiteEcran,
	checkAuthorities(
		PROFIL_UTILISATEUR.SUPERVISEUR_SITE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN,
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectSession,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'CalendrierSiteEcranForm'
	}),
	injectToolbarData(({ site }) => ({
		title: 'Sites écrans',
		subheader: {
			filArianeEntries: [{
				text: `Site : ${site.libelle}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	withStyles(styles),
	injectSnackActions
)(CalendrierSiteEcran)
