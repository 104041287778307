import {
	checkAuthorities,
	PROFIL_UTILISATEUR,
	Actions,
	Button,
	BandeauInfo,
	injectFormSnackbar
} from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import * as selectors from '../../services/sessionsPapierSelectors'
import { Title, getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { injectMatchSession } from '../../services/sessionsPapierInjectors'
import { ETAT_SESSION } from '../../../../../utils/consts'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const OngletEditionSujets = ({ sessionPapier, idSession }) => {
	const messageInfo =  'L\'état de la session ne permet pas d\'accéder à cette fonctionnalité'
	const etat = sessionPapier.etat

	return (
		<>
			{etat !== ETAT_SESSION.CLOTUREE && etat !== ETAT_SESSION.CORRIGEE && <BandeauInfo message={messageInfo} />}
			{(etat === ETAT_SESSION.CORRIGEE || etat === ETAT_SESSION.CLOTUREE) && <>
				<Title value="Lettres de résultats" />
				<Actions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => download(`/files/candidat/resultatsExport/${idSession}`)}
					>
						Exporter
					</Button>
				</Actions>
				<Title value="Liste des résultats pour tous les centres" />
				<Actions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => download(`/files/candidat/resultatsAffichage/${idSession}`)}
					>
						Afficher
					</Button>
				</Actions>
			</>}
		</>
	)
}

const actions = {}

OngletEditionSujets.propTypes = {
	sessionPapier: PropTypes.object,
	idSession: PropTypes.number
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state)
})


export default compose(
	injectMatchSession,
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	withStyles(style),
	injectFormSnackbar()
)(OngletEditionSujets)
