import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDetailsParagraphe } from './ligneProgrammeAction'
import * as selector from './ligneProgrammeSelectors'

export const injectDetailParagrapheWithIdParagraphe = WrappedComponent => {

	class injectDetailParagrapheWithIdParagraphe extends React.Component {

		componentDidMount() {
			const { getDetailsParagraphe, match } = this.props

			const idMatiere = match.params.idMatiere
			const idTopic = match.params.idTopic
			const idParagraphe = match.params.idParagraphe

			getDetailsParagraphe(idMatiere, idTopic, idParagraphe)
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	injectDetailParagrapheWithIdParagraphe.propTypes = {
		detailsParagraphe: PropTypes.object,
		getDetailsParagraphe: PropTypes.func
	}


	const mapStateToProps = state => ({
		detailsParagraphe: selector.getDetailParagraphe(state)
	})

	const actions = {
		getDetailsParagraphe
	}

	return connect(mapStateToProps, actions)(injectDetailParagrapheWithIdParagraphe)
}
