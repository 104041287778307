import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { checkAuthorities, PROFIL_UTILISATEUR, ResponsiveForm, Select } from '@oceane/ui'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { cleanEpreuves, getEpreuves } from './service/correctionCopiesActions'
import EpreuvesTable from './EpreuvesTable'
import { ETAT_SESSION } from '../../../utils/consts'
import * as sessionsSelectors from '../../references/sessions/services/sessionsSelectors'
import { injectSessionsList } from '../../references/sessions/services/injectSessionsList'
import { sessionShape } from '../../../config/shapes'

const CORRECION_COPIES_FORM_NAME = 'correctionCopies'

const CorrectionCopiesPage = ({ sessionList, selectedSession, getEpreuves, cleanEpreuves }) => {

	React.useEffect(() => {
		if (!!selectedSession) {
			getEpreuves(selectedSession)
		} else {
			cleanEpreuves()
		}
	}, [selectedSession])

	return (
		<>
			<ResponsiveForm>
				<Field
					id="session"
					name="sessionId"
					label="Session *"
					component={Select}
				>
					{sessionList.map(session =>
						<MenuItem key={session.id} value={session.id}>
							{session.libelle}
						</MenuItem>
					)}
				</Field>
			</ResponsiveForm>
			<EpreuvesTable />
		</>
	)
}

CorrectionCopiesPage.propTypes = {
	selectedSession: PropTypes.number,
	sessionList: PropTypes.arrayOf(sessionShape),
	getEpreuves: PropTypes.func,
	cleanEpreuves: PropTypes.func
}

const mapStateToProps = (state) => ({
	selectedSession: formValueSelector(CORRECION_COPIES_FORM_NAME)(state, 'sessionId'),
	sessionList: sessionsSelectors.getListeParEtat(ETAT_SESSION.PV_SAISIS)(state)
})

const actions = {
	getEpreuves,
	cleanEpreuves
}

export default compose(
	checkAuthorities(PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS),
	injectSessionsList(ETAT_SESSION.PV_SAISIS),
	connect(mapStateToProps, actions),
	reduxForm({
		form: CORRECION_COPIES_FORM_NAME
	})
)(CorrectionCopiesPage)
