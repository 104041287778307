import { Button, Input, InputRadio, Popup, ResponsiveForm, Error } from '@oceane/ui'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form'
import {
	SITE_ECRAN_JOURNEE_TYPE,
	SITE_ECRAN_NON_STANDART_JOURNEE_TYPE,
	TYPE_SITE_ECRAN_ID
} from '../../../config/enums'
import { modificationJourneeSiteEcran } from './services/siteEcranAction'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import DateUtils from '../../../utils/DateUtils'


const validate = (values) => {
	const errors = {}
	if (!values.heureOuverture || values.heureOuverture === '') {
		errors.heureOuverture = { id: 'global.erreurs.obligatoire' }
	} else if (!DateUtils.isValidTime(values.heureOuverture)) {
		errors.heureOuverture = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureDebutPause && values.heureDebutPause !== '' && !DateUtils.isValidTime(values.heureDebutPause)) {
		errors.heureDebutPause = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureFinPause && values.heureFinPause !== '' && !DateUtils.isValidTime(values.heureFinPause)) {
		errors.heureFinPause = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	if (values.heureFermeture === '') {
		errors.heureFermeture = { id: 'global.erreurs.obligatoire' }
	} else if (!DateUtils.isValidTime(values.heureFermeture)) {
		errors.heureFermeture = { id: 'examens.sitesEcran.modifierJournee.formErreurs.formatHeure' }
	}
	return errors
}

const ModifierJourneePopup = ({
								  submitting,
								  site,
								  open,
								  closePopup,
								  jour,
								  reset,
								  handleSubmit,
								  modificationJourneeSiteEcran,
								  snackSuccess,
								  snackError,
								  error,
								  getCalendrier,
								  etat,
							  }) => {

	const handleClose = () => {
		reset()
		closePopup()
	}

	const modificationJournee = (values) => {
		const val = {
			...values,
			idSite: site.id,
			id: jour.journee.id,
			date: jour.dateJour
		}
		return modificationJourneeSiteEcran(val)
			.then(({ response }) => {
				snackSuccess('L\'élément a bien été enregistré. ' + response.infos.toString())
				handleClose()
				getCalendrier()
			})
			.catch(e => {
					snackError('La journée a été configurée par une autre personne. Veuillez recharger la page.')
					throw new SubmissionError(e)
				}
			)
	}

	const actions = [
		<Button
			color="primary"
			key="fermer"
			onClick={handleClose}
		>
			Annuler
		</Button>,
		<Button
			key="enregistrer"
			loading={submitting}
			color="primary"
			variant="contained"
			onClick={handleSubmit(modificationJournee)}
		>
			Enregistrer
		</Button>
	]

	const choices = [
		{
			value: (site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME || site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) ? SITE_ECRAN_JOURNEE_TYPE.OUVERTE.id : SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.VFR.id,
			label: (site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME || site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) ? SITE_ECRAN_JOURNEE_TYPE.OUVERTE.name : SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.VFR.name
		},
		{
			value: (site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME || site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) ? SITE_ECRAN_JOURNEE_TYPE.RESERVEE.id : SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.IFR.id,
			label: (site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME || site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) ? SITE_ECRAN_JOURNEE_TYPE.RESERVEE.name : SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.IFR.name
		}
	]

	return (
		<Popup
			open={open}
			actions={actions}
			onExited={handleClose}
			title={`Paramétrage de la journée du ${jour.dateJour}`}
			content={<ResponsiveForm>
				<Error error={error} isPopinContainer />
				<Field
					name="etat"
					label="Etat d'ouverture *"
					component={InputRadio}
					choices={choices}
				/>
				{!(etat === SITE_ECRAN_JOURNEE_TYPE.OUVERTE.id && site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) && <>
					<Field
						name="heureOuverture"
						label="Heure d'ouverture *"
						component={Input}
					/>
					<Field
						name="heureDebutPause"
						label="Heure début de pause"
						component={Input}
					/>
					<Field
						name="heureFinPause"
						label="Heure fin de pause"
						component={Input}
					/>
					<Field
						name="heureFermeture"
						label="Heure de fermeture *"
						component={Input}
					/>
				</>
				}
			</ResponsiveForm>}
		/>
	)
}

const mapStateToProps = (state, { jour: { journee }, site, jour, defaultHoraires }) => {
	const etat = formValueSelector('ModifierJourneeForm')(state, 'etat')
	return ({
		initialValues: {
			etat: ((site.type === TYPE_SITE_ECRAN_ID.STANDART_FERME || site.type === TYPE_SITE_ECRAN_ID.STANDART_OUVERT) && `${jour.etat}` === SITE_ECRAN_JOURNEE_TYPE.FERMEE.id) ? SITE_ECRAN_JOURNEE_TYPE.OUVERTE.id : ((site.type !== TYPE_SITE_ECRAN_ID.STANDART_FERME && site.type !== TYPE_SITE_ECRAN_ID.STANDART_OUVERT) && `${jour.etat}` === SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.FERMEE.id) ? SITE_ECRAN_NON_STANDART_JOURNEE_TYPE.VFR.id : `${jour.etat}`,
			heureOuverture: journee && journee.dateOuverture ? journee.dateOuverture : '',
			heureFermeture: journee && journee.dateFermeture? journee.dateFermeture : '',
			heureDebutPause: journee && journee.heureDebutPause ? journee.heureDebutPause : '',
			heureFinPause: journee && journee.heureFinPause ? journee.heureFinPause : '',
			...defaultHoraires
		},
		etat
	})
}

ModifierJourneePopup.propTypes = {}

const actions = {
	modificationJourneeSiteEcran
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'ModifierJourneeForm',
		enableReinitialize: true,
		validate
	}),
	injectSnackActions
)(ModifierJourneePopup)
