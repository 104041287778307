import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { Popup } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { closePopup } from './services/piecesJustificativesAction'
import * as selectors from './services/piecesJustificativesSelectors'
import Typography from '@material-ui/core/Typography'

const styles = () => getStyles({
	popupForm: {
		marginTop: 20,
		marginLeft: 20
	}
})


class SuppressionPieceJustificativePopup extends Component {
	popupContent = () => {
		const {
			nom
		} = this.props

		return (
			<Typography variant="body1">
				<FormattedMessage id='references.piecesJustificatives.popupSuppression' values={{ nom: nom }} />
			</Typography>
		)
	}

	render() {
		const {
			open,
			classes,
			onSubmit,
			closePopup
		} = this.props

		const actions = [
			<Button
				color="primary"
				onClick={closePopup}
				className={classes.popupForm}
				key="fermer"
			>
				Annuler
			</Button>,
			<Button
				type="submit"
				color="primary"
				variant="contained"
				onClick={onSubmit}
				className={classes.popupForm}
				key="valider"
			>
				Supprimer
			</Button>
		]

		return (
			<Popup
				open={open}
				title="Suppression"
				actions={actions}
				contentStyleName={classes.popupContent}
				content={this.popupContent()}
			/>
		)
	}
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state)
})

const actions = {
	closePopup
}

SuppressionPieceJustificativePopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	onSubmit: PropTypes.func.isRequired
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles)
)(SuppressionPieceJustificativePopup)
