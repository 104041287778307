import { fetchFactory } from 'isotope-client'

export const getScript = (idEpreuveType, idScript, isExploitation) => fetchFactory(`/epreuvesTypes/${idEpreuveType}/scripts/${idScript}?isExploitation=${isExploitation}`)

export const saveParametrage = (id, values) => fetchFactory(`/scripts/${id}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const getQuestionsDisponibles = (idScript, codeNoeud, isExploitation) => fetchFactory(`/scripts/${idScript}/noeuds/${codeNoeud}/questionsDisponibles?isExploitation=${isExploitation}`)

export const creerNoeud = (values) => fetchFactory(`/noeuds`, {
	method: 'post',
	body: JSON.stringify(values)
})

export const modifierNoeud = (idNoeud, values) => fetchFactory(`/noeuds/${idNoeud}`, {
	method: 'put',
	body: JSON.stringify(values)
})

export const effacerNoeud = noeud => fetchFactory(`/scripts/${noeud.idScript}/noeuds/${noeud.idTopicOuParagraphe}`, {
	method: 'delete',
	body: noeud.type
})

export const validerScript = values => fetchFactory("/scripts/valider", {
	method: 'put',
	body: JSON.stringify(values)
})

export const devaliderScript = values => fetchFactory("/scripts/devalider", {
	method: 'put',
	body: JSON.stringify(values)
})