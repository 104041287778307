import { combineReducers } from 'redux'
import {
	CLOSE_POPUP,
	CLOSE_POPUP_CREATION,
	GET_EXAMENS_THEORIQUES_SUCCESS,
	OPEN_POPUP,
	OPEN_POPUP_CREATION
} from './examenTheoriqueOrganismeAction.js'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const openCreation = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP_CREATION:
			return true
		case CLOSE_POPUP_CREATION:
			return false
		default:
			return state
	}
}

const byId = (state = [], action) => {
	switch (action.type) {
		case GET_EXAMENS_THEORIQUES_SUCCESS:
			return action.payload.reduce((map, element) => ({
				[element.id]: element,
				...map
			}), {})
		default:
			return state
	}
}

const ids = (state = [], action) => {
	switch (action.type) {
		case GET_EXAMENS_THEORIQUES_SUCCESS:
			return action.payload.map(examen => examen.id)
		default:
			return state
	}
}

const data = combineReducers({
	byId,
	ids
})

export const examenTheorique = combineReducers({
	open,
	openCreation,
	data
})