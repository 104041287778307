import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { StaticDataTable, Title } from 'isotope-client'
import { injectDonneesGenerales, injectEtatGlobal } from './services/BanqueQuestionsInjectors'
import * as selectors from './services/banqueQuestionsSelectors'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { EmptyResult } from '@oceane/ui'
import * as shapes from '../../../config/shapes'
import QuestionNouvelEtatForm from './form/QuestionNouvelEtatForm'

const OngletEtatGlobal = ({ etatGlobal }) => (
	<>
		<Title value="Liste 20 dernières actions" />
		<StaticDataTable
			data={etatGlobal.listeDernieresActions}
			headers={[{
				key: 'dateAction',
				name: 'Date',
				sortable: false,
				render: row => moment(row.dateAction).format('DD/MM/YYYY')
			}, {
				key: 'typeAction',
				name: 'Action',
				sortable: false,
				render: row => <FormattedMessage id={`enums.bnqIdEtatGlobalQuestionTypeAction.${row.typeAction}`} />
			}, {
				key: 'nomPrenomUtilisateur',
				name: 'Utilisateur',
				sortable: false
			}, {
				key: 'commentaire',
				name: 'Commentaire',
				sortable: false
			}]}
			noResultFragment={<EmptyResult message="Aucune action réalisée sur cette question"/>}
			noPagination
		/>
		<QuestionNouvelEtatForm />
	</>
)

OngletEtatGlobal.propTypes = {
	etatGlobal: shapes.questionEtatGlobalShape,
}

const mapStateToProps = (state) => ({
	etatGlobal: selectors.getEtatGlobal(state)
})

export default compose(
	injectDonneesGenerales,
	injectEtatGlobal,
	connect(mapStateToProps)
)(OngletEtatGlobal)
