import { combineReducers } from 'redux'
import { OPEN_POPUP, CLOSE_POPUP } from './nouveauCompteClientPopupActions'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

export const nouveauCompteClient = combineReducers({
	open
})