import { StyledTypography } from '@oceane/ui'
import { ELS_EPREUVE_SESSION_ID_ETAT } from '../../../../config/enums'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const getFilArianeSujet = (result) => ({
	title: 'Sujet papier',
	subheader: {
		separator: () => '>',
		filArianeEntries: [{
			text: `Session : ${result.nomSession}`,
			render: text => <>
				<StyledTypography>{text} </StyledTypography>
				<StyledTypography primary><FormattedMessage id={`references.sessions.etatPapier.${result.etatSession}`} /></StyledTypography>
			</>
		}, {
			text: `Epreuve type : ${result.nomEpreuveType} `,
			render: text => <>
				<StyledTypography bold>{text} </StyledTypography>
				<StyledTypography bold primary>
					{ELS_EPREUVE_SESSION_ID_ETAT[result.etatEpreuveType]}
				</StyledTypography>
			</>
		}]
	}
})