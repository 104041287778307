import * as api from './centrePapierApi'

export const GET_CENTRES_PAPIER_REQUEST = 'GET_CENTRES_PAPIER_REQUEST'
export const GET_CENTRES_PAPIER_SUCCESS = 'GET_CENTRES_PAPIER_SUCCESS'
export const GET_CENTRES_PAPIER_ERROR = 'GET_CENTRES_PAPIER_ERROR'
export const getListeCentresPapier = () => dispatch => {
	dispatch({ type: GET_CENTRES_PAPIER_REQUEST })

	return api.getListeCentresPapier()
		.then((centres) => dispatch({ type: GET_CENTRES_PAPIER_SUCCESS, centres }))
		.catch(() => dispatch({ type: GET_CENTRES_PAPIER_ERROR }))
}

export const CREATE_CENTRES_PAPIER_REQUEST = 'CREATE_CENTRES_PAPIER_REQUEST'
export const CREATE_CENTRES_PAPIER_SUCCESS = 'CREATE_CENTRES_PAPIER_SUCCESS'
export const CREATE_CENTRES_PAPIER_ERROR = 'CREATE_CENTRES_PAPIER_ERROR'
export const createCentrePapier = (values) => dispatch => {
	dispatch({ type: CREATE_CENTRES_PAPIER_REQUEST })

	return api.createCentrePapier(values)
		.then((centre) => dispatch({ type: CREATE_CENTRES_PAPIER_SUCCESS, centre }))
		.catch(() => dispatch({ type: CREATE_CENTRES_PAPIER_ERROR }))
}

export const GET_CENTRE_PAPIER_REQUEST = 'GET_CENTRE_PAPIER_REQUEST'
export const GET_CENTRE_PAPIER_SUCCESS = 'GET_CENTRE_PAPIER_SUCCESS'
export const GET_CENTRE_PAPIER_ERROR = 'GET_CENTRE_PAPIER_ERROR'
export const getCentrePapier = (id) => dispatch => {
	dispatch({ type: GET_CENTRE_PAPIER_REQUEST })

	return api.getCentrePapier(id)
		.then((centre) => dispatch({ type: GET_CENTRE_PAPIER_SUCCESS, centre }))
		.catch(() => dispatch({ type: GET_CENTRE_PAPIER_ERROR }))
}

export const ENREGISTREMENT_CENTRE_PAPIER_REQUEST = 'ENREGISTREMENT_CENTRE_PAPIER_REQUEST'
export const ENREGISTREMENT_CENTRE_PAPIER_SUCCESS = 'ENREGISTREMENT_CENTRE_PAPIER_SUCCESS'
export const ENREGISTREMENT_CENTRE_PAPIER_ERROR = 'ENREGISTREMENT_CENTRE_PAPIER_ERROR'
export const enregistrerCentre = (values) => dispatch => {
	dispatch({ type: ENREGISTREMENT_CENTRE_PAPIER_REQUEST })

	return api.enregistrerCentre(values)
		.then((centre) => dispatch({ type: ENREGISTREMENT_CENTRE_PAPIER_SUCCESS, centre }))
		.catch(e => dispatch({ type: ENREGISTREMENT_CENTRE_PAPIER_ERROR, e }))
}

export const CREATE_SITE_PAPIER_REQUEST = 'CREATE_SITE_PAPIER_REQUEST'
export const CREATE_SITE_PAPIER_SUCCESS = 'CREATE_SITE_PAPIER_SUCCESS'
export const CREATE_SITE_PAPIER_ERROR = 'CREATE_SITE_PAPIER_ERROR'
export const createSitePapier = (values, id) => dispatch => {
	dispatch({ type: CREATE_SITE_PAPIER_REQUEST })

	return api.createSitePapier(values, id)
		.then(( site ) => dispatch({ type: CREATE_SITE_PAPIER_SUCCESS, site }))
		.catch(() => dispatch({ type: CREATE_SITE_PAPIER_ERROR }))
}

export const GET_SITE_PAPIER_REQUEST = 'GET_SITE_PAPIER_REQUEST'
export const GET_SITE_PAPIER_SUCCESS = 'GET_SITE_PAPIER_SUCCESS'
export const GET_SITE_PAPIER_ERROR = 'GET_SITE_PAPIER_ERROR'
export const getSitePapier = (id, idSitePapier) => dispatch => {
	dispatch({ type: GET_SITE_PAPIER_REQUEST })

	return api.getSitePapier(id, idSitePapier)
		.then((site) => dispatch({ type: GET_SITE_PAPIER_SUCCESS, site }))
		.catch(() => dispatch({ type: GET_SITE_PAPIER_ERROR }))
}

export const CHECK_DOWNLOAD_REQUEST = 'CHECK_DOWNLOAD_REQUEST'
export const CHECK_DOWNLOAD_SUCCESS = 'CHECK_DOWNLOAD_SUCCESS'
export const CHECK_DOWNLOAD_ERROR = 'CHECK_DOWNLOAD_ERROR'
export const checkFileExist = (id, idSitePapier, nomFichier) => dispatch => {
	dispatch({ type: CHECK_DOWNLOAD_REQUEST })

	return api.checkFileExist(id, idSitePapier, nomFichier)
		.then(() => dispatch({ type: CHECK_DOWNLOAD_SUCCESS }))
		.catch(e => {
			dispatch({ type: CHECK_DOWNLOAD_ERROR })
			throw e
		})
}

export const ENREGISTREMENT_SITE_PAPIER_REQUEST = 'ENREGISTREMENT_SITE_PAPIER_REQUEST'
export const ENREGISTREMENT_SITE_PAPIER_SUCCESS = 'ENREGISTREMENT_SITE_PAPIER_SUCCESS'
export const ENREGISTREMENT_SITE_PAPIER_ERROR = 'ENREGISTREMENT_SITESITEE_PAPIER_ERROR'
export const modificationSitePapier = (values, id) => dispatch => {
	dispatch({ type: ENREGISTREMENT_SITE_PAPIER_REQUEST })

	return api.modificationSitePapier(values, id)
		.then(() => dispatch({ type: ENREGISTREMENT_SITE_PAPIER_SUCCESS }))
		.catch(e => {
			dispatch({ type: ENREGISTREMENT_SITE_PAPIER_ERROR, e })
			throw e
		})
}