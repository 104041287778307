import * as api from './correctionCopiesApi'

export const GET_SESSION_EPREUVES_REQUEST = 'GET_SESSION_EPREUVES_REQUEST'
export const GET_SESSION_EPREUVES_SUCCESS = 'GET_SESSION_EPREUVES_SUCCESS'
export const GET_SESSION_EPREUVES_ERROR = 'GET_SESSION_EPREUVES_ERROR'
export const getEpreuves = idSession => dispatch => {
	dispatch({ type: GET_SESSION_EPREUVES_REQUEST })
	return api.getEpreuves(idSession)
		.then(result => dispatch({
			type: GET_SESSION_EPREUVES_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_SESSION_EPREUVES_ERROR })
			throw e
		})
}

export const GET_EPREUVE_SESSION_REQUEST = 'GET_EPREUVE_SESSION_REQUEST'
export const GET_EPREUVE_SESSION_SUCCESS = 'GET_EPREUVE_SESSION_SUCCESS'
export const GET_EPREUVE_SESSION_ERROR = 'GET_EPREUVE_SESSION_ERROR'
export const getEpreuveSession = idEpreuveSession => dispatch => {
	dispatch({ type: GET_EPREUVE_SESSION_REQUEST })
	return api.getEpreuveSession(idEpreuveSession)
		.then(result => dispatch({
			type: GET_EPREUVE_SESSION_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_EPREUVE_SESSION_ERROR })
			throw e
		})
}

export const CREATION_COPIES_REQUEST = 'CREATION_COPIES_REQUEST'
export const CREATION_COPIES_SUCCESS = 'CREATION_COPIES_SUCCESS'
export const CREATION_COPIES_ERROR = 'CREATION_COPIES_ERROR'
export const suppressionEtCreationCopies = (idSession, idEpreuveSession, fichierCopies) => dispatch => {
	dispatch({ type: CREATION_COPIES_REQUEST })
	return api.suppressionEtCreationCopies(idSession, idEpreuveSession, fichierCopies)
		.then(result => dispatch({
			type: CREATION_COPIES_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: CREATION_COPIES_ERROR })
			throw e
		})
}

export const CONTROLE_ANOMALIES_REQUEST = 'CONTROLE_ANOMALIES_REQUEST'
export const CONTROLE_ANOMALIES_SUCCESS = 'CONTROLE_ANOMALIES_SUCCESS'
export const CONTROLE_ANOMALIES_ERROR = 'CONTROLE_ANOMALIES_ERROR'
export const controleAnomalies = (idEpreuveSession, isFichierCharge) => dispatch => {
	dispatch({ type: CONTROLE_ANOMALIES_REQUEST })
	return api.controleAnomalies(idEpreuveSession, isFichierCharge)
		.then(result => dispatch({
			type: CONTROLE_ANOMALIES_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: CONTROLE_ANOMALIES_ERROR })
			throw e
		})
}

export const DECLARER_ABSENCE_REQUEST = 'DECLARER_ABSENCE_REQUEST'
export const DECLARER_ABSENCE_SUCCESS = 'DECLARER_ABSENCE_SUCCESS'
export const DECLARER_ABSENCE_ERROR = 'DECLARER_ABSENCE_ERROR'
export const declarerAbsence = (idEpreuveSession, idEpreuveInscriptionPapier, numeroInscription) => dispatch => {
	dispatch({ type: DECLARER_ABSENCE_REQUEST })
	return api.declarerAbsence(idEpreuveSession, idEpreuveInscriptionPapier)
		.then(() => dispatch({
			type: DECLARER_ABSENCE_SUCCESS,
			numeroInscription
		}))
		.catch(e => {
			dispatch({ type: DECLARER_ABSENCE_ERROR })
			throw e
		})
}

export const CORRIGER_COPIE_REQUEST = 'CORRIGER_COPIE_REQUEST'
export const CORRIGER_COPIE_SUCCESS = 'CORRIGER_COPIE_SUCCESS'
export const CORRIGER_COPIE_ERROR = 'CORRIGER_COPIE_ERROR'
export const corrigerCopie = (idEpreuveSession, isFichierCharge) => dispatch => {
	dispatch({ type: CORRIGER_COPIE_REQUEST })
	return api.corrigerCopie(idEpreuveSession, isFichierCharge)
		.then(result => dispatch({
			type: CORRIGER_COPIE_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: CORRIGER_COPIE_ERROR })
			throw e
		})
}

export const DECLARER_PRESENCE_REQUEST = 'DECLARER_PRESENCE_REQUEST'
export const DECLARER_PRESENCE_SUCCESS = 'DECLARER_PRESENCE_SUCCESS'
export const DECLARER_PRESENCE_ERROR = 'DECLARER_PRESENCE_ERROR'
export const declarerPresence = (idEpreuveSession, idEpreuveInscriptionPapier, idCopiePapier) => dispatch => {
	dispatch({ type: DECLARER_PRESENCE_REQUEST })
	return api.declarerPresence(idEpreuveSession, idEpreuveInscriptionPapier)
		.then(() => dispatch({
			type: DECLARER_PRESENCE_SUCCESS,
			idCopiePapier
		}))
		.catch(e => {
			dispatch({ type: DECLARER_PRESENCE_ERROR })
			throw e
		})
}

export const MODIFIER_REPONSE_QUESTION_REQUEST = 'MODIFIER_REPONSE_QUESTION_REQUEST'
export const MODIFIER_REPONSE_QUESTION_SUCCESS = 'MODIFIER_REPONSE_QUESTION_SUCCESS'
export const MODIFIER_REPONSE_QUESTION_ERROR = 'MODIFIER_REPONSE_QUESTION_ERROR'
export const modifierReponseQuestion = (idEpreuveSession, idReponseCopiePapier, reponse, idCopiePapier) => dispatch => {
	dispatch({ type: MODIFIER_REPONSE_QUESTION_REQUEST })
	return api.modifierReponseQuestion(idEpreuveSession, idReponseCopiePapier, reponse)
		.then(result => dispatch({
			type: MODIFIER_REPONSE_QUESTION_SUCCESS,
			result,
			idCopiePapier
		}))
		.catch(e => {
			dispatch({ type: MODIFIER_REPONSE_QUESTION_ERROR })
			throw e
		})
}

export const ENREGISTRER_TRAITEMENT_ANOMALIES_REQUEST = 'ENREGISTRER_TRAITEMENT_ANOMALIES_REQUEST'
export const ENREGISTRER_TRAITEMENT_ANOMALIES_SUCCESS = 'ENREGISTRER_TRAITEMENT_ANOMALIES_SUCCESS'
export const ENREGISTRER_TRAITEMENT_ANOMALIES_ERROR = 'ENREGISTRER_TRAITEMENT_ANOMALIES_ERROR'
export const enregistrerTraitementAnomalies = (idEpreuveSession, idCopiePapier, form) => dispatch => {
	dispatch({ type: ENREGISTRER_TRAITEMENT_ANOMALIES_REQUEST })
	return api.enregistrerTraitementAnomalies(idEpreuveSession, idCopiePapier, form)
		.then(result => dispatch({
			type: ENREGISTRER_TRAITEMENT_ANOMALIES_SUCCESS,
			result,
			idCopiePapier
		}))
		.catch(e => {
			dispatch({ type: ENREGISTRER_TRAITEMENT_ANOMALIES_ERROR })
			throw e
		})
}

export const CLEAN_SESSION_EPREUVES = 'CLEAN_SESSION_EPREUVES'
export const cleanEpreuves = () => dispatch => dispatch({
	type: CLEAN_SESSION_EPREUVES
})
