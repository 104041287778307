import * as api from './familleApi'

export const POST_FAMILLE_REQUEST = 'POST_FAMILLE_REQUEST'
export const POST_FAMILLE_SUCCESS = 'POST_FAMILLE_SUCCESS'
export const POST_FAMILLE_ERROR = 'POST_FAMILLE_ERROR'

export const postFamille = (famille, idMatiere) => dispatch => {
	dispatch({ type : POST_FAMILLE_REQUEST })

	return api.postFamille(famille, idMatiere)
		.then(() => dispatch({ type: POST_FAMILLE_SUCCESS }))
		.catch(e => {
			dispatch({ type: POST_FAMILLE_ERROR })
			throw e
		})
}

export const GET_FAMILLE_REQUEST = 'GET_FAMILLE_REQUEST'
export const GET_FAMILLE_SUCCESS = 'GET_FAMILLE_SUCCESS'
export const GET_FAMILLE_ERROR = 'GET_FAMILLE_ERROR'

export const getFamille = (idFamille, idMatiere) => dispatch => {
	dispatch({ type : GET_FAMILLE_REQUEST })

	return api.getFamille(idFamille, idMatiere)
		.then((famille) => dispatch({ type: GET_FAMILLE_SUCCESS, famille }))
		.catch(() => dispatch({ type: GET_FAMILLE_ERROR }))
}

export const PUT_FAMILLE_REQUEST = 'PUT_FAMILLE_REQUEST'
export const PUT_FAMILLE_SUCCESS = 'PUT_FAMILLE_SUCCESS'
export const PUT_FAMILLE_ERROR = 'PUT_FAMILLE_ERROR'

export const putFamille = (famille, idMatiere) => dispatch => {
	dispatch({ type : PUT_FAMILLE_REQUEST })

	return api.putFamille(famille, idMatiere)
		.then((famille) => dispatch({ type: PUT_FAMILLE_SUCCESS, famille }))
		.catch(e => {
			dispatch({ type: PUT_FAMILLE_ERROR })
			throw e
		})
}

export const DELETE_FAMILLE_REQUEST = 'DELETE_FAMILLE_REQUEST'
export const DELETE_FAMILLE_SUCCESS = 'DELETE_FAMILLE_SUCCESS'
export const DELETE_FAMILLE_ERROR = 'DELETE_FAMILLE_ERROR'

export const deleteFamille = (famille, idMatiere) => dispatch => {
	dispatch({ type : DELETE_FAMILLE_REQUEST })

	return api.deleteFamille(famille, idMatiere)
		.then(() => dispatch({ type: DELETE_FAMILLE_SUCCESS }))
		.catch(e => {
			dispatch({ type: DELETE_FAMILLE_ERROR })
			throw e
		})
}

export const CLEAR_FAMILLE = 'CLEAR_FAMILLE'
export const clearFamille = () => dispatch => dispatch({ type: CLEAR_FAMILLE })

export const HANDLE_QUERY_LISTE_FAMILLES = 'HANDLE_QUERY_LISTE_FAMILLES'
export const RESET_QUERY_LISTE_FAMILLES = "RESET_QUERY_LISTE_FAMILLES"
export const handleQuery = (key, value) => dispatch => {
	dispatch({
		type: HANDLE_QUERY_LISTE_FAMILLES,
		key,
		value
	})
}
export const resetQuery = () => dispatch => {
	dispatch({
		type: RESET_QUERY_LISTE_FAMILLES
	})
}