import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getStyles, getToolbarData, setToolbarData, Title } from 'isotope-client'
import Grid from '@material-ui/core/Grid'
import { reduxForm, SubmissionError } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import * as selectors from './services/scriptSelectors'
import ListeTopics from './topics/ListeTopics'
import withStyles from '@material-ui/core/styles/withStyles'
import EditionNoeudPopup from './topics/EditionNoeudPopup'
import { ELS_SCRIPT_ETAT } from '../../../config/enums'
import { Actions, Button, Error, ResponsiveForm, injectFormSnackbar } from '@oceane/ui'
import { devaliderScript, validerScript } from './services/scriptActions'
import * as epreuveTypeSelectors from '../epreuveType/services/epreuveTypeSelectors'
import * as examensTypeSelectors from '../examensType/services/examensTypesSelectors'
import * as shapes from '../../../config/shapes'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { getFilArianeScript } from './services/filAriane'


const styles = () => getStyles({
	matiereContainer: {
		width: '100%'
	},
	legendContainer: {
		marginTop: 60
	}
})

export const VALIDATION_SCRIPT_FORM_NAME = 'validationScript'

const ListeMatieres = ({
	                       matieres,
	                       etat,
	                       idScript,
	                       idEpreuveType,
	                       libelleEpreuveType,
	                       etatEpreuveType,
	                       idExamenType,
	                       libelleExamenType,
	                       etatExamenType,
	                       isExploitation,
	                       handleSubmit,
	                       submitting,
	                       error,
	                       classes,
	                       validerScript,
	                       devaliderScript,
	                       setToolbarData,
	                       toolbarData
                       }) => {

	const [open, setOpen] = React.useState(false)
	const openPopup = () => setOpen(true)
	const closePopup = () => setOpen(false)

	const handleValider = (values) => validerScript(values)
		.then(() => setFilAriane(ELS_SCRIPT_ETAT.VALIDE))
		.catch(e => {
			throw new SubmissionError(e)
		})

	const handleDevalider = (values) => devaliderScript(values)
		.then(() => setFilAriane(ELS_SCRIPT_ETAT.CREE))
		.catch(e => {
			throw new SubmissionError(e)
		})

	const editable = etat === ELS_SCRIPT_ETAT.CREE

	const setFilAriane = (etatScript) => setToolbarData(toolbarData.id, getFilArianeScript(
		libelleExamenType,
		etatExamenType,
		libelleEpreuveType,
		etatEpreuveType,
		etatScript
	))

	return (
		<ResponsiveForm>
			<Error error={error} />
			<Grid container direction="row" alignItems="flex-start" justify="space-between">
				<Grid item xs={editable ? 5 : 8} container direction="column" alignItems="flex-start">
					{React.Children.toArray(matieres.map(matiere => <Grid item className={classes.matiereContainer}>
						<Title value={`Matière ${matiere.code}`} />
						<ListeTopics topics={matiere.listeTopics} editable={editable} openPopup={openPopup} />
					</Grid>))}
				</Grid>
				{editable && <Grid item xs={6} className={classes.legendContainer}>
					<Typography variant="caption">
						( A - B - C) = répartition des questions à tirer
					</Typography>
					<Typography variant="caption">
						A = nombre de questions nouvelles à tirer
					</Typography>
					<Typography variant="caption">
						B = nombre de questions jeunes à tirer
					</Typography>
					<Typography variant="caption">
						C = nombre de questions matures à tirer
					</Typography>
				</Grid>}
				{editable && <EditionNoeudPopup open={open} closePopup={closePopup} />}
			</Grid>
			<Actions>
				<Button
					color="primary"
					onClick={() => download(`/files/examensTypes/${idExamenType}/epreuvesTypes/${idEpreuveType}/scripts/${idScript}?isExploitation=${isExploitation}`)}
				>
					Afficher script
				</Button>
				{etat === ELS_SCRIPT_ETAT.CREE && <Button
					loading={submitting}
					color="primary"
					variant="contained"
					onClick={handleSubmit(handleValider)}
				>
					Valider
				</Button>}
				{etat === ELS_SCRIPT_ETAT.VALIDE && <Button
					loading={submitting}
					color="primary"
					variant="contained"
					onClick={handleSubmit(handleDevalider)}
				>
					Dévalider
				</Button>}
			</Actions>
		</ResponsiveForm>
	)
}


ListeMatieres.propTypes = {
	matieres: PropTypes.arrayOf(shapes.matiereShape),
	etat: PropTypes.number,
	idScript: PropTypes.number,
	idEpreuveType: PropTypes.number,
	libelleEpreuveType: PropTypes.string,
	etatEpreuveType: PropTypes.number,
	idExamenType: PropTypes.number,
	libelleExamenType: PropTypes.string,
	etatExamenType: PropTypes.number,
	isExploitation: PropTypes.bool,
	initialValues: PropTypes.shape({
		id: PropTypes.number,
		idEpreuveType: PropTypes.number
	}),
	validerScript: PropTypes.func,
	devaliderScript: PropTypes.func,
	classes: PropTypes.object,
	toolbarData: PropTypes.object,
	setToolbarData: PropTypes.func
}

const mapStateToProps = (state) => ({
	matieres: selectors.getMatieres(state),
	etat: selectors.getEtat(state),
	idScript: selectors.getId(state),
	idEpreuveType: epreuveTypeSelectors.getIdEpreuveType(state),
	libelleEpreuveType: epreuveTypeSelectors.getLibelleEpreuveType(state),
	etatEpreuveType: epreuveTypeSelectors.getEtatEpreuveType(state),
	idExamenType: examensTypeSelectors.getIdExamenType(state),
	libelleExamenType: examensTypeSelectors.getLibelleExamenType(state),
	etatExamenType: examensTypeSelectors.getEtatExamenType(state),
	isExploitation: selectors.isExploitation(state),
	initialValues: {
		id: selectors.getId(state),
		idEpreuveType: epreuveTypeSelectors.getIdEpreuveType(state)
	},
	toolbarData: getToolbarData(state)
})

const actions = {
	validerScript,
	devaliderScript,
	setToolbarData
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	reduxForm({
		form: VALIDATION_SCRIPT_FORM_NAME
	}),
	injectFormSnackbar()
)(ListeMatieres)
