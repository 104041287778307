import { Input, Popup, Button } from '@oceane/ui'
import { push } from 'connected-react-router'
import { dataTableActions } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { closePopup, createOrganisme } from './services/popup/nouvelOrganismePopupActions'
import * as selectors from './services/popup/nouvelOrganismePopupSelectors'

class NouvelOrganismePopup extends Component {

	submitCreateOrganisme = (values) => {
		const { closePopup, createOrganisme, snackError, refreshTable, goToDetails } = this.props

		return createOrganisme(values)
			.then((nouvelOrganisme) => {
				refreshTable()
				closePopup()
				goToDetails(nouvelOrganisme.payload.id)
			})
			.catch(e => {
				e.bodyError ? snackError(e.bodyError) : snackError()
			})
	}

	closePopup = () => {
		const {
			closePopup,
			reset
		} = this.props

		reset()
		closePopup()
	}

	render() {
		const {
			open,
			submitting,
			handleSubmit
		} = this.props

		const actions = [
			<Button
				color="primary"
				key="fermer"
				onClick={this.closePopup}
			>
				<FormattedMessage id="global.btn.annuler" />
			</Button>,
			<Button
				loading={submitting}
				key="valider"
				color="primary"
				variant="contained"
				onClick={() => handleSubmit(this.submitCreateOrganisme)()}
			>
				<FormattedMessage id="global.btn.creer" />
			</Button>
		]

		return (
			<Popup
				open={open}
				actions={actions}
				title={<FormattedMessage id='references.organismes.popup.title' />}
				content={<form>
					<Field
						id="libelleCourt"
						name="libelleCourt"
						label={<FormattedMessage id="references.organismes.popup.inputLabel.court" />}
						component={Input}
					/>
					<Field
						id="libelleLong"
						name="libelleLong"
						label={<FormattedMessage id="references.organismes.popup.inputLabel.long" />}
						component={Input}
					/>
				</form>}
			/>
		)
	}
}

const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state),
	organismeActif: selectors.getOrganismeActif(state)
})

const actions = {
	closePopup,
	createOrganisme,
	refreshTable: () => dataTableActions.refresh('organismes'),
	goToDetails: organismeId => dispatch => dispatch(push(`/organismes/${organismeId}`))
}

NouvelOrganismePopup.propTypes = {
	open: PropTypes.bool,
	closePopup: PropTypes.func,
	createOrganisme: PropTypes.func,
	goToDetails: PropTypes.func.isRequired
}

export default compose(
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'nouvelOrganismePopup',
		initialValues: {
			libelleCourt: '',
			libelleLong: ''
		}
	}),
	injectSnackActions
)(NouvelOrganismePopup)
