import React  from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getListeCentresPapier } from '../../centresPapiers/services/centrePapierActions'
import { Loader } from '@oceane/ui'

export const injectListeCentresPapier = WrappedComponent => {
	const ListeCentresPapierInjector = ({ getListeCentresPapier, ...props}) => {

		const [listeCentres, setListeCentres] = React.useState([])
		const [loading, setLoading] = React.useState(true)

		React.useEffect(() => {
			getListeCentresPapier()
				.then(({ centres }) => {
					setListeCentres(centres)
					setLoading(false)
				})
		}, [])

		return (
			loading ? <Loader /> : <WrappedComponent listeCentres={listeCentres} {...props} />
		)
	}


	ListeCentresPapierInjector.propTypes = {
		getListeCentresPapier: PropTypes.func
	}

	const actions = {
		getListeCentresPapier
	}

	return compose(
		connect(undefined, actions)
	)(ListeCentresPapierInjector)
}